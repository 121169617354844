import React, { useEffect, useState } from 'react'
import { Col, Container, Row, Offcanvas, Modal, Accordion } from "react-bootstrap";
import Sidenav from "../../../Componet/Sidenav/Sidenav";
import menu from '../../../Photo/menu.svg'
import profile from '../../../Photo/profile.svg';
import Input from '../../../Componet/InputFild/Input';
import Table from '../../../Componet/DataTable/Table';
import {
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
} from "@mui/material";
import closeButton from "../../../Photo/milk-union/close button.svg";
import './Supplier.css'
import { MdOutlineVpnKey } from "react-icons/md";
import { GoSignOut } from "react-icons/go";
import { useNavigate } from 'react-router-dom';
import remove from "../../../Photo/master/remove.svg";

import { API_URLS, serviceUrl } from '../../../API/Api';
import { STORAGE_KEY } from '../../../constant/common';



const SemenSupplier = () => {
    const { DistributionAPi, SemenSupplierApi, SupplieStockApi } = API_URLS
    const added_by = localStorage.getItem(STORAGE_KEY.jwtTOKEN);

    async function getStaffData(credentials) {
        return fetch(DistributionAPi.GetSupplier, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`,
            },
            body: JSON.stringify(credentials)
        })
            .then(data => data.json());
    }

    async function getSireData(credentials) {
        return fetch(SupplieStockApi.GetSire, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`,
            },
            body: JSON.stringify(credentials)
        })
            .then(data => data.json());
    }

    async function getItemData(credentials) {
        return fetch(SemenSupplierApi.GetItemData, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`,
            },
            body: JSON.stringify(credentials)
        })
            .then(data => data.json());
    }
    const API_KEY = serviceUrl;
    const lastWeek = new Date();
    const selectedFromDate = lastWeek.toISOString().slice(0, 10);

    const [date, setDate] = useState(selectedFromDate)
    const [staff, setStaff] = useState("")
    const [staff1, setStaff1] = useState("")
    const [editId, setEditId] = useState("")
    const [isLoading, setIsLoading] = useState("")
    const [bench, setBench] = useState("")
    const [qty, setQty] = useState("")
    const [sire, setSire] = useState("")
    const [semen, setSemen] = useState("")
    const [itemCode, setItemCode] = useState("")
    const [item, setItem] = useState("")
    const [supplied, setSupplied] = useState("")
    const navigate = useNavigate()
    const logOut = () => { navigate("/") }
    const [progressData, setProgressData] = useState("0%");

    const [show, setShow] = useState(false);
    const [headerShow, setHeaderShow] = useState(true);
    const handleClose = () => { setShow(false); };
    const handleShow = () => { setHeaderShow(!headerShow); }

    const [modalShow, setModalShow] = useState(false);

    const [supplierList, setSupplierList] = useState([])
    const [staffList, setStaffList] = useState([])
    const [staffList1, setStaffList1] = useState([])
    const [sireList, setSireList] = useState([])
    const [semenList, setSemenList] = useState([])
    const [itemList, setItemList] = useState([])
    const [strawList, setStrawList] = useState([])
    const [sireName, setSireName] = useState([])
    const [otherItemList, setOtherItemList] = useState([])

    const AddData = async () => {
        setIsLoading(true)
        await fetch(SemenSupplierApi.SaveStraw, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`,
            },
            body: JSON.stringify({ RequestDate: null, RequestQuantity: null, SireVoucherType:2, Sires: strawList, Staff: staff, VoucherDate: date, createdAt: "", createdByUser: "", dcs: "", isEdit: 0, lastUpdatedByUser: "", supplierId: 0, updatedAt: "" })
        })
            .then(data => data.json())
            .then(response => {
                const contentLength = response.headers.get('content-length');
                let loaded = 0;
                return new Response(
                    new ReadableStream({
                        start(controller) {
                            const reader = response.body.getReader();
                            read();
                            function read() {
                                reader.read()
                                    .then((progressEvent) => {
                                        if (progressEvent.done) { controller.close(); return; }
                                        loaded += progressEvent.value.byteLength;
                                        const percentageComplete = Math.round(loaded / contentLength * 100) + '%';
                                        setProgressData(percentageComplete)
                                        controller.enqueue(progressEvent.value);
                                        read();
                                    })
                            }
                        }
                    })
                );
            })
            .then((result) => { setIsLoading(false); })
            .catch((error) => setIsLoading(false))

        await fetch(SemenSupplierApi.SaveItem, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`,
            },
            body: JSON.stringify({ RequestDate: "", RequestQuantity: "", SireVoucherType: "", Items: otherItemList, Staff: staff, VoucherDate: date, createdAt: "", createdByUser: "", dcs: "", isEdit: 0, lastUpdatedByUser: "", supplierId: "", updatedAt: "" })
        })
            .then(data => data.json())
            .then(response => {
                const contentLength = response.headers.get('content-length');
                let loaded = 0;
                return new Response(
                    new ReadableStream({
                        start(controller) {
                            const reader = response.body.getReader();
                            read();
                            function read() {
                                reader.read()
                                    .then((progressEvent) => {
                                        if (progressEvent.done) { controller.close(); return; }
                                        loaded += progressEvent.value.byteLength;
                                        const percentageComplete = Math.round(loaded / contentLength * 100) + '%';
                                        setProgressData(percentageComplete)
                                        controller.enqueue(progressEvent.value);
                                        read();
                                    })
                            }
                        }
                    })
                );
            })
            .then((result) => { setIsLoading(false); })
            .catch((error) => setIsLoading(false))
    }
    const Add = () => {
        setStaffList([])
        setModalShow(true)
        openData()
    }

    const openData = async () => {
        const res = await getSireData()
        if (res) { setSireList(res) }
    }
    const AddStraw = () => {
        setStrawList((prev) => {
            const newIndex = prev.length + 1;
            return [...prev, { index: newIndex, staff, sire, semen, bench, qty }]
        })
    }
    const itemSave = () => {
        setOtherItemList((prev) => {
            const newIndex = prev.length + 1;
            return [...prev, { index: newIndex, item, itemCode, supplied }]
        })
    }

    const selectStaff = (e) => {
        setStaff(e)
        tableData(e)
    }

    const tableData = async (e) => {
        setIsLoading(true)
        await fetch(`${SemenSupplierApi.GetSemenData}?DataType=2&staff=${e}&date=${date}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`
            },
        })
            .then(response => {
                const contentLength = response.headers.get('content-length');
                let loaded = 0;
                return new Response(
                    new ReadableStream({
                        start(controller) {
                            const reader = response.body.getReader();
                            read();
                            function read() {
                                reader.read()
                                    .then((progressEvent) => {
                                        if (progressEvent.done) { controller.close(); return; }
                                        loaded += progressEvent.value.byteLength;
                                        const percentageComplete = Math.round(loaded / contentLength * 100) + '%';
                                        setProgressData(percentageComplete)
                                        controller.enqueue(progressEvent.value);
                                        read();
                                    })
                            }
                        }
                    })
                );
            })
            .then(res => res.json())
            .then((result) => { setSupplierList(result); setIsLoading(false); })
            .catch((error) => setIsLoading(false))
    }

    const fetchData = async () => {
        const res = await getStaffData()
        const res1 = await getItemData()

        if (res) { setStaffList(res); setStaffList1(res); }
        if (res1) { setItemList(res1); }
    }

    const delateHandle = (e) => {
        setStrawList(strawList.filter(item => item.index !== e.index))
    }

    const delateHandle1 = (e) => {
        setOtherItemList(otherItemList.filter(item => item.index !== e.index))
    }

    const colum = [
        {
            name: "Action",
            cell: (e) => (
                <>
                    <img
                        src={remove}
                        onClick={() => delateHandle(e)}
                        style={{ width: "15px" }}
                    />
                </>
            ),
        },
        {
            name: "Sire",
            selector: "sire",
        },
        {
            name: "Semen Type",
            selector: "semen",
        },
        {
            name: "Batch No",
            selector: "bench",
        },
        {
            name: "Qty",
            selector: "qty",
        },
    ]

    const colum1 = [
        {
            name: "Action",
            cell: (e) => (
                <>
                    <img
                        src={remove}
                        onClick={() => delateHandle1(e)}
                        style={{ width: "15px" }}
                    />
                </>
            ),
        },
        {
            name: "Item name",
            selector: "item",
        },
        {
            name: "Item Code",
            selector: "itemCode",
        },
        {
            name: "Supplied",
            selector: "supplied",
        },
    ]

    useEffect(() => {
        fetchData()
    }, [])

    const selectItem = (e) => {
        setItem(e)
        setItemCode(e)
    }
    return (
        <>
            <Container fluid>
                <Row>
                    {headerShow && (<Col lg="2" className="hideNavbar"> <Sidenav /></Col>)}
                    <Col className="header" lg={headerShow ? "10" : "12"}>
                        <Container fluid>
                            <Row className="pt-1 sub-header align-items-center justify-content-between">
                                <Col md="11" sm="10" xs="10" className="py-2 d-flex align-items-center">
                                    <button type="button" id="navToggle" class="navbar-toggler d-block" for="opensiderMenu" onClick={handleShow} style={{ border: "none", cursor: "pointer" }}><img src={menu} width={20} /></button>
                                    <h5 className="ms-4 mt-2">Straw / Semen Supplier / Semen Supplier</h5>
                                </Col>
                                <Col md="1" sm="1" xs="2" className="text-end profile_dropdown">
                                    <img src={profile} width={40} />
                                    <div className="dropdpwn_Open">
                                        <Row className="text-center align-items-center">
                                            <Col xs="12" className="menuHover mb-3">
                                                <Row >
                                                    <Col className="" xs="3">
                                                        <span className=""><MdOutlineVpnKey size={25} />
                                                        </span>
                                                    </Col>
                                                    <Col className="text-start" xs="9">
                                                        Change Password
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col xs="12" className="menuHover" onClick={logOut}>
                                                <Row>
                                                    <Col className="" xs="3">
                                                        <span className=""><GoSignOut size={25} />
                                                        </span>
                                                    </Col>
                                                    <Col className="text-start" xs="9">
                                                        Sign Out
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            </Row>

                            <Row className="align-items-center justify-content-start p-2 d-flex">
                                <Col xs="3" sm="2" md="2" xl="1" className="mt-2 d-flex align-items-center justify-content-end">
                                    <button className="btn-blue-Animal mt-1 mb-1" onClick={Add}>Add</button>
                                </Col>
                                <Col md="2" sm="2" xs="4" className='ms-auto'>
                                    <FormControl variant="standard" fullWidth>
                                        <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>Staff</InputLabel>
                                        <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={staff} onChange={(e) => { selectStaff(e.target.value) }}>
                                            {staffList.map((e) => (
                                                <MenuItem className="drop_value" key={e.id} value={e.id}>
                                                    {e.code}-{e.Name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Col>
                                <Col md="2" sm="2" xs="4" className='mt-3'>
                                    <TextField type="date" value={date} onChange={(e) => { setDate(e.target.value)}} id="standard-basic" fullWidth variant="standard" />
                                </Col>
                                <Col xs="4" sm="2" md="2" xl="1" className="mt-2 align-items-center justify-content-end">
                                    <button className="btn-blue-Animal mt-1 mb-1">Export</button>
                                </Col>
                            </Row>

                            <Row className="mt-4">
                                <Col>
                                    <Table columns={colum} height="70vh" pagination />
                                </Col>
                            </Row>

                        </Container>
                    </Col>
                </Row>
            </Container>

            <Modal size="lg" aria-labelledby="contained-modal-title-vcenter" centered show={modalShow} onHide={() => setModalShow(false)}>
                <Modal.Header style={{ background: "#DFDFDF" }}>
                    <Modal.Title id="contained-modal-title-vcenter" className=''>Add Semen Supplier</Modal.Title>
                    <img src={closeButton} onClick={() => setModalShow(false)} />
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Row>
                            <Col>
                                <FormControl variant="standard" fullWidth>
                                    <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>Staff</InputLabel>
                                    <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={staff1} onChange={(e) => { setStaff1(e.target.value) }}>
                                        {staffList1.map((e) => (
                                            <MenuItem className="drop_value" key={e.id} value={e.id}>
                                                {e.code}-{e.Name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Col>
                        </Row>
                        <Row className='mt-3'>
                            <Col>
                                <Accordion>
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>Add Staw</Accordion.Header>
                                        <Accordion.Body>
                                            <Row className='align-tems-center justify-content-start'>
                                                <Col md="4" sm="3">
                                                    <FormControl variant="standard" fullWidth>
                                                        <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>Sire</InputLabel>
                                                        <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={sire} onChange={(e) => { setSire(e.target.value) }}>
                                                            {sireList.map((e) => (
                                                                <MenuItem className="drop_value" key={e.id} value={e.id}>
                                                                    {e.Name}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                </Col>
                                                <Col md="4">
                                                    <FormControl variant="standard" fullWidth>
                                                        <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>Semen</InputLabel>
                                                        <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={semen} onChange={(e) => { setSemen(e.target.value) }}>
                                                            <MenuItem className="drop_value" value="0">Normal Semen</MenuItem>
                                                            <MenuItem className="drop_value" value="1">Sex Semen</MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </Col>
                                                <Col md="4">
                                                    <TextField label="Bench No" id="standard-basic" value={bench} onChange={(e) => { setBench(e.target.value); }} fullWidth variant="standard" />
                                                </Col>
                                                <Col md="4">
                                                    <TextField label="Qty" id="standard-basic" value={qty} onChange={(e) => { setQty(e.target.value); }} fullWidth variant="standard" />
                                                </Col>
                                                <Col md="4">
                                                </Col>
                                                <Col xs="4" sm="4" md="3" className="mt-2 d-flex align-items-center text-end justify-content-end">
                                                    <button className="btn-blue-Animal mt-1 mb-1" onClick={AddStraw}>Add Straw</button>
                                                </Col>
                                            </Row>
                                            <Row className="mt-4">
                                                <Col>
                                                    <Table columns={colum} data={strawList} />
                                                </Col>
                                            </Row>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="1">
                                        <Accordion.Header >Add Other Item</Accordion.Header>
                                        <Accordion.Body>
                                            <Row className='align-tems-center justify-content-end'>
                                                <Col md="4">
                                                    <FormControl variant="standard" fullWidth>
                                                        <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>Item Name</InputLabel>
                                                        <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={item} onChange={(e) => { selectItem(e.target.value) }}>
                                                            {itemList.map((e) => (
                                                                <MenuItem className="drop_value" key={e.id} value={e.id}>
                                                                    {e.Name}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                </Col>
                                                <Col md="4">
                                                    <TextField label="Item Code" id="standard-basic" value={itemCode} disabled onChange={(e) => { setItemCode(e.target.value); }} fullWidth variant="standard" />
                                                </Col>
                                                <Col md="4">
                                                    <TextField label="Supplied" id="standard-basic" value={supplied} onChange={(e) => { setSupplied(e.target.value); }} fullWidth variant="standard" />
                                                </Col>
                                                <Col xs="4" sm="2" md="3" className="mt-2 d-flex align-items-center text-end justify-content-end">
                                                    <button className="btn-blue-Animal mt-1 mb-1" onClick={itemSave}>Add item</button>
                                                </Col>
                                            </Row>
                                            <Row className="mt-4">
                                                <Col>
                                                    <Table columns={colum1} data={otherItemList} />
                                                </Col>
                                            </Row>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Container>
                        <Row className="justify-content-end">
                            <Col sm="2">
                                <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }} onClick={AddData}>Save</button>
                            </Col>
                            <Col sm="2">
                                <button className="btn-blue-Animal mt-1" onClick={() => setModalShow(false)} style={{ background: "#E16659" }}> Cancel </button>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Footer>
            </Modal >


            {
                isLoading ?
                    <div className="circleText">
                        < div className="progressText" > {progressData}</div >
                        <div className="">
                            <span className="loader-circle-13"></span>
                        </div>
                    </div >
                    : false
            }
            {show && <Offcanvas className="hide_Navbar" show={show} onHide={handleClose}><Container><Row><Col><Sidenav /></Col></Row></Container></Offcanvas>}
        </>
    )
}
export default SemenSupplier    
