import { Switch } from '@mui/material'
import React, { useState } from 'react'
import {
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
} from "@mui/material";
import {
    Col,
    Container,
    Row,
    Offcanvas,
    Accordion,
    Modal,
} from "react-bootstrap";
import Table from '../../Componet/DataTable/Table'
import Sidenav from '../../Componet/Sidenav/Sidenav';
import edit from "../../Photo/master/edit.svg";
import remove from "../../Photo/master/remove.svg";
import menu from "../../Photo/menu.svg";
import profile from "../../Photo/profile.svg";
import closeButton from "../../Photo/milk-union/close button.svg";
import Input from "../../Componet/InputFild/Input";
import { MdOutlineVpnKey } from "react-icons/md";
import { GoSignOut } from "react-icons/go";

import { STORAGE_KEY } from "../../constant/common";
import { API_URLS, serviceUrl } from "../../API/Api";
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import BackBtn from '../../Componet/Button/BackBtn';
const { ShiftApi } = API_URLS;
const added_by = localStorage.getItem(STORAGE_KEY.jwtTOKEN);


const label = { inputProps: { "aria-label": "Switch demo" } };

const Shift = () => {
    const API_KEY = serviceUrl;

    async function getData(credentials) {
        return fetch(ShiftApi.GetDetails, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`,
            },
            body: JSON.stringify(credentials)
        })
            .then(res => res.json());
    }

    async function addData(credentials) {
        return fetch(ShiftApi.AddDetails, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`,
            },
            body: JSON.stringify(credentials)
        })
            .then(res => res.json());
    }


    async function updateData(credentials) {
        return fetch(ShiftApi.UpdateDetails, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`,
            },
            body: JSON.stringify(credentials)
        })
            .then(res => res.json());
    }

    const [name, setName] = useState("")
    const [search, setSearch] = useState("")
    const [editId, setEditId] = useState("")
    const [user, setUser] = useState("")
    const [searchFilter, setSearchFilter] = useState("");
    const [progressData, setProgressData] = useState("0%");


    const [isLoading, setIsLoading] = useState(false);
    const [heightOffSet, setHeight] = useState(0);
    const [show, setShow] = useState(false);
    const [headerShow, setHeaderShow] = useState(true);
    const handleClose = () => { setShow(false); };
    const handleShow = () => { setHeaderShow(!headerShow); }
    const [modalShow, setModalShow] = useState(false);
    const [editToggle, setEditToggle] = useState(false);
    const [deleteShow, setDeleteShow] = useState(false)
    const navigate = useNavigate()
    const logOut = () => { navigate("/") }

    const [shiftList, setShiftList] = useState([])


    const shiftGetData = async () => {
        setIsLoading(true)
        const res = await getData();
        if (res) {
            setShiftList(res.data);
            setIsLoading(false)
        }
    }

    useEffect(() => {
        let divHeight = document.getElementById("heightOffsetStock")?.offsetHeight;
        setHeight(divHeight);
        shiftGetData()
    }, [])


    const AddData = async () => {
        if (editId) {
            // const res = await updateData({ id: editId, Name:name });
            // if (res) {
            //     setShiftList(res.data);
            //     setModalShow(false)
            //     shiftGetData()
            // }

            fetch(`${API_KEY}/master/shift/` + editId, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    "Accept": "application/json",
                    "Authorization": `Bearer ${added_by}`,
                },
                body: JSON.stringify({ id: editId, Name: name })
            })
                .then(res => res.json())
                .then((res) => {
                    setModalShow(false)
                    shiftGetData()
                }
                );
        }
        else {
            // const res = await getData({ Name:name,id:0 });
            // if (res) {
            //     setShiftList(res.data);
            //     setModalShow(false)
            //     shiftGetData()
            // }
            fetch(`${API_KEY}/master/shift`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    "Accept": "application/json",
                    "Authorization": `Bearer ${added_by}`,
                },
                body: JSON.stringify({ Name: name, id: 0 })
            })
                .then(res => res.json())
                .then((res) => {
                    setModalShow(false)
                    shiftGetData()
                }
                );

        }
    }

    const Add = () => {
        setModalShow(true);
        setEditId("")
        setEditToggle("");
        setEditId("");
        setName("");
    }

    const updateFn = (e) => {
        setEditId(e.id)
        setEditToggle(true);
        setName(e.Name);
        setModalShow(true);
    };

    const colum = [
        {
            name: "ID",
            selector: "id",
        },
        {
            name: "Name",
            selector: "Name",
        },
        {
            name: "Actions",
            cell: (e) => (
                <>
                    <img
                        src={edit}
                        onClick={() => updateFn(e)}
                        style={{ width: "15px", margin: "10px" }}
                    />
                </>
            ),
        },
    ]
    const SearchFn = (e) => {
        const data = e.target.value;
        if (data !== '') {
            const results = shiftList.filter((d) => {
                return d.Name.toLowerCase().startsWith(data.toLowerCase());
            });
            setSearchFilter(results);
        } else {
            setSearchFilter(shiftList);
        }
        setSearch(data);
    }
    
    return (
        <>
            <Container fluid>
                <Row> {headerShow && (<Col lg="2" className="hideNavbar"> <Sidenav /></Col>)}
                    <Col className="header" lg={headerShow ? "10" : "12"}>
                        <Container fluid>
                            <Row className="pt-1 sub-header align-items-center justify-content-between">
                                <Col md="10" sm="10" xs="10" className="py-2 d-flex align-items-center">
                                    <button style={{ border: "none" }} onClick={handleShow}> <img src={menu} width={20} /> </button>
                                    <h5 className="ms-4 mt-2">Master / Shift</h5>
                                </Col>
                                <Col md="1" sm="1" xs="10" className="py-2 d-flex align-items-center text-end ms-auto">
                                    <BackBtn style={{ border: "none" }} className="hideNavbar1" lable="Back" onClick={() => { navigate('/') }} />
                                </Col>
                                <Col md="1" sm="1" xs="2" className="text-end profile_dropdown">
                                    <img src={profile} width={40} />
                                    <div className="dropdpwn_Open">
                                        <Row className="text-center align-items-center">
                                            <Col xs="12" className="menuHover mb-3">
                                                <Row >
                                                    <Col className="" xs="3">
                                                        <span className=""><MdOutlineVpnKey size={25} />
                                                        </span>
                                                    </Col>
                                                    <Col className="text-start" xs="9">
                                                        Change Password
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col xs="12" className="menuHover" onClick={logOut}>
                                                <Row>
                                                    <Col className="" xs="3">
                                                        <span className=""><GoSignOut size={25} />
                                                        </span>
                                                    </Col>
                                                    <Col className="text-start" xs="9">
                                                        Sign Out
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="align-items-center justify-content-between p-2 d-flex" id='heightOffsetStock'>
                                <Col sm="4" lg="1" className="mt-2 d-flex align-items-center justify-content-start">
                                    <button className="btn-blue-Animal mt-1 mb-1" onClick={() => Add()}>Add Shift</button>
                                </Col>
                                <Col xxl="2" lg="3" md="4" sm="5">
                                    <Input label="Search" value={search} onChange={e => SearchFn(e)} />
                                </Col>
                            </Row>
                            {/* <Table columns={colum} data={searchFilter ? searchFilter : shiftList} height={`calc(100vh - (${heightOffSet}px + 124px))`} pagination /> */}
                            <Row className="mt-2" id='heightOffsetStock'>
                                {shiftList.length > 0 ?
                                    <div className="blue_bg DataTableBorder mt-1">
                                        <Table columns={colum} data={searchFilter ? searchFilter : shiftList} height={`calc(100vh - (${heightOffSet}px + 140px))`} pagination/>
                                    </div>
                                    :
                                    <Row>
                                        <Col>
                                            <div className="NoTableBorder ms-2" >
                                                <div className="d-flex tabledata colmBreed">{
                                                    colum.map((i) => {
                                                        return (
                                                            <Col className={`blankColumns ${i.name.replace(' ', '_')}`} style={{ backgroundColor: "rgb(169,169,169)" }} height={`calc(100vh - (${heightOffSet}px + 20px))`} >{i.name}</Col>
                                                        )
                                                    })
                                                }
                                                </div>
                                                <h6 className="text-center align-items-center mt-5 noData ms-5">No Data</h6>
                                            </div>
                                        </Col>
                                    </Row>
                                }
                            </Row>
                        </Container>
                    </Col>
                </Row>
            </Container>
            <Modal size="lg" aria-labelledby="contained-modal-title-vcenter" centered show={modalShow} onHide={() => setModalShow(false)}>
                <Modal.Header style={{ background: "#DFDFDF" }}>
                    <Modal.Title id="contained-modal-title-vcenter">Add new Shift</Modal.Title>
                    <img src={closeButton} onClick={() => setModalShow(false)} />
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Row>
                            <Col xl="12">
                                <TextField label="Name" value={name} onChange={(e) => { setName(e.target.value); }} id="standard-basic" data={shiftList} fullWidth variant="standard" />
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Container>
                        <Row className="justify-content-end">
                            <Col xl="2">
                                <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }} onClick={(e) => AddData(e)}>{editId ? "Update" : "Save"}</button>
                            </Col>
                            <Col xl="2">
                                <button className="btn-blue-Animal mt-1" onClick={() => setModalShow(false)} style={{ background: "#E16659" }}> Cancel </button>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Footer>
            </Modal>
            {show && <Offcanvas className="hide_Navbar" show={show} onHide={handleClose}><Container><Row><Col><Sidenav /></Col></Row></Container></Offcanvas>}


        </>

    )
}

export default Shift;