import React, { useState, useEffect } from 'react';
import { Col, Container, Row, Offcanvas } from "react-bootstrap";
import {
    TextField, FormControl, InputLabel,
    //  Select,
    MenuItem, Switch
} from "@mui/material";

import menu from '../../../Photo/menu.svg';
import profile from '../../../Photo/profile.svg';

import Sidenav from "../../../Componet/Sidenav/Sidenav";
import Input from '../../../Componet/InputFild/Input';
import Table from '../../../Componet/DataTable/Table';
import { MdOutlineVpnKey } from "react-icons/md";
import { GoSignOut } from "react-icons/go";
import Select from 'react-select';

import { STORAGE_KEY } from "../../../constant/common";
import { API_URLS, serviceUrl } from "../../../API/Api";
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import BackBtn from '../../../Componet/Button/BackBtn';
const { CampReport } = API_URLS;
const added_by = localStorage.getItem(STORAGE_KEY.jwtTOKEN);
const label = { inputProps: { "aria-label": "Switch demo" } };


export default function Animalreport() {

    async function getYearData(credentials) {
        return fetch(CampReport.GetYear, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`,
            },
            body: JSON.stringify(credentials)
        })
            .then(res => res.json());
    }
    async function getProjectData(credentials) {
        return fetch(CampReport.GetProject, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`,
            },
            body: JSON.stringify(credentials)
        })
            .then(res => res.json());
    }
    async function getFipData(credentials) {
        return fetch(CampReport.GetFip, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`,
            },
            body: JSON.stringify(credentials)
        })
            .then(res => res.json());
    }
    async function getCampData(credentials) {
        return fetch(CampReport.GetCamp, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`,
            },
            body: JSON.stringify(credentials)
        })
            .then(res => res.json());
    }
    async function getCampPlanning(credentials) {
        return fetch(CampReport.GetCampGrid, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`,
            },
            body: JSON.stringify(credentials)
        })
            .then(res => res.json());
    }

    const API_KEY = serviceUrl;
    // const API_KEY = "https://hais.hap.in";
    const selectedToDate = new Date().toISOString().slice(0, 10);

    const navigate = useNavigate();
    const logOut = () => { navigate("/"); }

    const [search, setSearch] = useState("");
    const [group, setGroup] = useState("");
    const [userName, setUserName] = useState("");
    const [contactPerson, setContactPerson] = useState("");
    const [mobile, setMobile] = useState("");
    const [appType, setAppType] = useState("");
    const [qr, setQr] = useState("");
    const [zone, setZone] = useState(0);
    const [editId, setEditId] = useState("");
    const [tableId, setTableId] = useState("");
    const [route, setRoute] = useState("");
    const [searchFilter, setSearchFilter] = useState("");
    const [progressData, setProgressData] = useState("0%");
    const [date, setDate] = useState(selectedToDate);
    const [campNo, setCampNo] = useState("");
    const [year, setYear] = useState("");
    const [project, setProject] = useState("");
    const [fip, setFip] = useState("");
    const [camp, setCamp] = useState("");
    const [tagId, setTagId] = useState("")

    const [heightOffSet, setHeight] = useState(0);
    const [profile, setProfile] = useState(false)
    const [isLoading, setIsLoading] = useState(false);
    const [headerShow, setHeaderShow] = useState(true);
    const [show, setShow] = useState(false);
    const handleClose = () => { setShow(false); }
    const handleShow = () => { setHeaderShow(!headerShow); }
    const [modalShow, setModalShow] = useState(false);

    const [fipList, setFipList] = useState([])
    const [yearList, setYearList] = useState([])
    const [projectList, setProjectList] = useState([])
    const [campList, setCampList] = useState([])
    const [campPlanning, setCampPlanning] = useState([])
    const [planning, setPlanning] = useState([])
    const [tagIdList, setTagIdList] = useState([])

    const fetchData = async () => {
        const res = await getYearData();
        const res1 = await getProjectData();
        const res2 = await getFipData({ id: 5 });
        const res3 = await getCampData();

        if (res) { setYearList(res) }
        if (res1) { setProjectList(res1) }
        if (res2) { setFipList(res2) }
        if (res3) { setCampList(res3) }
    }

    useEffect(() => {
        fetchData();
        let divHeight = document.getElementById("heightOffsetStock")?.offsetHeight;
        setHeight(divHeight);
    }, []);

    const refreshFn = () => {
        setModalShow(false);
        setGroup("");
        setAppType("");
        setUserName("");
        setEditId("");
        setMobile("");
        setQr("");
        setZone("");
        setContactPerson("");
    }

    const AddData = async () => {
        console.log({ AppType: appType, CountryCode: null, Email: null, Mobile: mobile, MobileDetail: null, NotificationId: null, Photo: null, QRCode: qr, SyancPendingData: 1, Username: userName, group, is_deleted: null, staff: null, zone: zone })
        // fetch(`${API_KEY}/user/add`, {
        //     method: 'POST',
        //     headers: {
        //         'Content-Type': 'application/json',
        //         "Accept": "application/json",
        //         "Authorization": `Bearer ${added_by}`
        //     },
        //     body: JSON.stringify({ AppType: appType, CountryCode: null, Email: null, Mobile: mobile, MobileDetail: null, NotificationId: null, Photo: null, QRCode: qr, SyancPendingData: 1, Username: userName, group, is_deleted: null, staff: null, zone: zone }),
        // })
        //     .then(res => res.json())
        //     .then((result) => { if (result) { refreshFn();  setUserDetails(result.data);} })
    }

    const UpdateData = () => {
        console.log({ id: editId, AppType: appType, CountryCode: null, Email: null, Mobile: mobile, MobileDetail: null, NotificationId: null, Photo: null, QRCode: qr, SyancPendingData: 1, Username: userName, group, is_deleted: null, staff: null, zone: zone })
        // fetch(`${API_KEY}/herd/edit`, {
        //   method: "POST",
        //   headers: {
        //     "Content-Type": "application/json",
        //     "Accept": "application/json",
        //     "Authorization": `Bearer ${added_by}`,
        //   },
        //   body: JSON.stringify({ AppType: appType, CountryCode: null, Email: null, Mobile: mobile, MobileDetail: null, NotificationId: null, Photo: null, QRCode: qr, SyancPendingData: 1, Username: userName, group, is_deleted: null, staff: null, zone: zone })
        //   .then((res) => res.json())
        //   .then((result) => { if (result) { refreshFn();  setUserDetails(result.data);} })
    };

    const Add = async () => {
        refreshFn();
        setModalShow(true);
        fetchData();
    }

    const AddAnimal = () => {

    }

    const View = async () => {
        setIsLoading(true)
        await fetch(`${API_KEY}/project/getCampReport?year=${year}&projectId=${project}&staff=${fip}&inputDate=${date}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`,
            },
        })
            .then(res => res.json())
            .then((res) => { setIsLoading(false); setCampPlanning(res) })
    }

    const SearchFn = (e) => {
        const data = e.target.value;
        if (data !== '') {
            const results = fipList.filter((user) => {
                return user.TagId.toLowerCase().startsWith(data.toLowerCase());
            });
            setSearchFilter(results);
        } else {
            setSearchFilter(fipList);
        }
        setSearch(data);
    }

    const Save = () => {
        fetch(`${API_KEY}/user/assignment`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`,
            },
            body: JSON.stringify({ farmerOnly: false, herdId: route || null, herdOnly: false, lotIdAssigned: [], lotIdUnAssign: [], lotOnly: true, searchTerm: "", userId: tableId })
        })
            .then(response => {
                const contentLength = response.headers.get('content-length');
                let loaded = 0;
                return new Response(
                    new ReadableStream({
                        start(controller) {
                            const reader = response.body.getReader();
                            read();
                            function read() {
                                reader.read()
                                    .then((progressEvent) => {
                                        if (progressEvent.done) {
                                            controller.close();
                                            return;
                                        }
                                        loaded += progressEvent.value.byteLength;
                                        const percentageComplete = Math.round(loaded / contentLength * 100) + '%';
                                        setProgressData(percentageComplete)
                                        controller.enqueue(progressEvent.value);
                                        read();
                                    })
                            }
                        }
                    })
                );
            })
            .then((res) => res.json())
            .then((result) => { setIsLoading(false); setProgressData("0%"); })
    }

    const editDetails = (e) => {
        fetchData()
        setEditId(e.id)
        setModalShow(true)
        setAppType(e.AppType)
        setUserName(e.Username)
        setQr(e.QRCode)
        setMobile(e.Mobile)
        setGroup(e.group.id)
        setZone(e.zone.id)
    };

    const colum1 = [
        {
            name: "id",
            selector: "id"
        },
        {
            name: "Event Type",
            ceil: (e) => (<p>{e["Event Type"]}</p>)
        },
        {
            name: "Project",
            selector: "project"
        },
        {
            name: "DCS SAP",
            ceil: (e) => (<p>{e["DCS SAP"]}</p>)
        },
        {
            name: "Village",
            selector: "Village"
        },
        {
            name: "Date",
            selector: "Date"
        },
        {
            name: "Staffid",
            selector: "Staffid"
        },
        {
            name: "Staff Name",
            ceil: (e) => (<p>{e["Staff Name"]}</p>)
        },
        {
            name: "From Time",
            ceil: (e) => (<p>{e["From Time"]}</p>)
        },
        {
            name: "To Time",
            ceil: (e) => (<p>{e["To Time"]}</p>)
        },
        {
            name: "Status",
            selector: "Status"
        },

    ]

    return (
        <>
            <Container fluid>
                <Row >
                    {headerShow && (<Col lg="2" className="hideNavbar"> <Sidenav /></Col>)}
                    <Col className="header" lg={headerShow ? "10" : "12"}>
                        <Container fluid>
                            <Row className="pt-1 sub-header align-items-center justify-content-between">
                                <Col md="11" sm="10" xs="10" className="py-2 d-flex align-items-center">
                                    <button style={{ border: "none" }} className="hideNavbar1" onClick={handleShow}><img src={menu} width={20} /></button>
                                    <button style={{ border: "none" }} className="hide_Navbar" onClick={() => setShow(true)}><img src={menu} width={20} /></button>
                                    <h5 className="ms-4 mt-2">Project / AnimalWise Report</h5>
                                </Col>
                                <Col md="1" sm="1" xs="10" className="py-2 d-flex align-items-center text-end ms-auto">
                                    <BackBtn style={{ border: "none" }} className="hideNavbar1" lable="Back" onClick={() => { navigate('/') }} />
                                </Col>
                                <Col md="1" sm="1" xs="2" className="text-end profile_dropdown">
                                    <img src={profile} width={40} />
                                    <div className="dropdpwn_Open">
                                        <Row className="text-center align-items-center">
                                            <Col xs="12" className="menuHover mb-3">
                                                <Row >
                                                    <Col className="" xs="3">
                                                        <span className=""><MdOutlineVpnKey size={25} />
                                                        </span>
                                                    </Col>
                                                    <Col className="text-start" xs="9">
                                                        Change Password
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col xs="12" className="menuHover" onClick={logOut}>
                                                <Row>
                                                    <Col className="" xs="3">
                                                        <span className=""><GoSignOut size={25} />
                                                        </span>
                                                    </Col>
                                                    <Col className="text-start" xs="9">
                                                        Sign Out
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="align-items-end justify-content-start" id='heightOffsetStock'>
                                <Col md="3" xl="2" xs="6">
                                    <Select
                                        placeholder="Animal TagId"
                                        onChange={(selectedOption) => setTagId(selectedOption.value)}
                                        options={tagIdList.map((item) => ({ value: item.id, label: item.Name }))}
                                    />
                                </Col>
                                <Col md="3" xl="2" xs="6">
                                    <Select
                                        placeholder="Project"
                                        onChange={(selectedOption) => setProject(selectedOption.value)}
                                        options={projectList.map((item) => ({ value: item.id, label: item.Name }))}
                                    />
                                </Col>
                                <Col md="3" xl="2" xs="6">
                                    <Select
                                        placeholder="FIP Consult"
                                        onChange={(selectedOption) => setYear(selectedOption.value)}
                                        options={fipList.map((item) => ({ value: item.ID, label: item.name }))}
                                    />
                                </Col>
                                <Col md="3" xl="1" xs="6">
                                    <Select
                                        placeholder="Year"
                                        onChange={(selectedOption) => setYear(selectedOption.value)}
                                        options={yearList.map((item) => ({ value: item.id, label: item.YearName }))}
                                    />
                                </Col>
                                <Col md="3" xl="1" xs="6">
                                    <Select
                                        placeholder="Camp"
                                        onChange={(selectedOption) => setCamp(selectedOption.value)}
                                        options={campList.map((item) => ({ value: item.ID, label: item.CampNo }))}
                                    />
                                </Col>
                                <Col xl="1">
                                    <div>
                                        <sub className="mb-0 pb-0">Date</sub>
                                        <input type="date" className="dateSelect_bg" id="date-1" value={date} onChange={(e) => { setDate(e.target.value) }} />
                                    </div>
                                </Col>
                                <Col md="3" xl="2" xs="6" className='align-items-center'>
                                    <Input label="Search" value={search} onChange={e => SearchFn(e)} />
                                </Col>
                                <Col md="3" xs="6" xl="1" className="mt-4 justify-content-start">
                                    <button className="btn-blue-Animal" onClick={View}>View</button>
                                </Col>
                            </Row>
                            <Row className="mt-2">
                                <Col>
                                    <Table columns={colum1} data={searchFilter ? searchFilter : campPlanning} height={`calc(100vh - (${heightOffSet}px + 124px))`} pagination />
                                </Col>
                            </Row>
                        </Container>
                    </Col>
                </Row>
            </Container>


            {show && (<Offcanvas className="hide_Navbar" show={show} onHide={handleClose}><Container><Row><Col><Sidenav /></Col></Row></Container></Offcanvas>)}

            {
                isLoading ?
                    <div className="circleText">
                        <div className="progressText">{progressData}</div>
                        <div className="">
                            <span className="loader-circle-13"></span>
                        </div>
                    </div>
                    : false
            }
        </>
    )
};
