import React, { useEffect, useState } from 'react'
import { STORAGE_KEY } from '../../constant/common';
import { Col, Container, Row, Offcanvas } from "react-bootstrap";
import Sidenav from "../../Componet/Sidenav/Sidenav";
import menu from '../../Photo/menu.svg'
import profile from '../../Photo/profile.svg';
import Input from '../../Componet/InputFild/Input';
import Table from '../../Componet/DataTable/Table';
import { MdOutlineVpnKey } from "react-icons/md";
import { GoSignOut } from "react-icons/go";
import { useNavigate } from 'react-router-dom';
import { API_URLS, serviceUrl } from "../../API/Api";
const { CattleReport } = API_URLS;
const added_by = localStorage.getItem(STORAGE_KEY.jwtTOKEN);

const Reproduction = () => {
    const navigate = useNavigate()

    const API_KEY = serviceUrl;
    // const API_KEY = "https://hais.hap.in";

    const [search, setSearch] = useState("")

    const [reproductionList, setReproductionList] = useState([]);

    const [show, setShow] = useState(false);
    const [headerShow, setHeaderShow] = useState(true);
    const handleClose = () => { setShow(false); };
    const handleShow = () => { setHeaderShow(!headerShow); };
    const logOut = () => { navigate("/") }

    const addUser = async ()=> {
        // const res = await getData({offset:0,limit:15,column_name:null,sort_order:null,search_term:null,company_filter:null,user:null,lot_filter:null})
        // console.log(res);
        fetch(`${API_KEY}/reproduction/get-all`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`,
            }
        })
            .then(res => res.json());
    }
    
    useEffect(()=>{
        addUser();
    },[])

    const colum = [
        {
            name: "TagId",
            selector: "Username",
        },
        {
            name: "Date",
            selector: "MobileDetails",
        },
        {
            name: "Batch No",
            selector: "",
        },
        {
            name: "Dose",
            selector: "",
        },
        {
            name: "Cost",
            selector: "",
        },
        {
            name: "Deworming Type",
            selector: "",
        },
        {
            name: "Deworming Medicine",
            selector: "",
        },
        {
            name: "Medicine Route",
            selector: "",
        },
        {
            name: "Done By",
            selector: "",
        },
    ]
    

    return (
        <>
            <Container fluid>
                <Row>
                    {headerShow && (<Col lg="2" className="hideNavbar"> <Sidenav /></Col>)}
                    <Col className="header" lg={headerShow ? "10" : "12"}>
                        <Container fluid>
                            <Row className="pt-1 sub-header align-items-center justify-content-between">
                                <Col md="11" sm="10" xs="10" className="py-2 d-flex align-items-center">
                                    <button style={{ border: "none" }} className="hideNavbar1" onClick={handleShow}><img src={menu} width={20} /></button>
                                    <button style={{ border: "none" }} className="hide_Navbar" onClick={() => setShow(true)}><img src={menu} width={20} /></button>
                                    <h5 className="ms-4 mt-2">Cattle Details / Reproduction </h5>
                                </Col>
                                <Col md="1" sm="1" xs="2" className="text-end profile_dropdown">
                                    <img src={profile} width={40} />
                                    <div className="dropdpwn_Open">
                                        <Row className="text-center align-items-center">
                                            <Col xs="12" className="menuHover mb-3">
                                                <Row >
                                                    <Col className="" xs="3">
                                                        <span className=""><MdOutlineVpnKey size={25} />
                                                        </span>
                                                    </Col>
                                                    <Col className="text-start" xs="9">
                                                        Change Password
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col xs="12" className="menuHover" onClick={logOut}>
                                                <Row>
                                                    <Col className="" xs="3">
                                                        <span className=""><GoSignOut size={25} />
                                                        </span>
                                                    </Col>
                                                    <Col className="text-start" xs="9">
                                                        Sign Out
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="align-items-center justify-content-end p-2 d-flex">
                                <Col xxl="2" md="3" sm="3" xs="6">
                                    <Input label="Search" value={search} onChange={e => setSearch(e.target.value)} />
                                </Col>
                            </Row>
                            <Row className="mt-4">
                                <Col>
                                    <Table columns={colum} data={reproductionList} height="70vh" pagination />
                                </Col>
                            </Row>
                        </Container>
                    </Col>
                </Row>
            </Container>


            {show && <Offcanvas className="hide_Navbar" show={show} onHide={handleClose}><Container><Row><Col><Sidenav /></Col></Row></Container></Offcanvas>}
        </>
    )
}
export default Reproduction    
