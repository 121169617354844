import * as React from 'react';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import './Date.css'

const Date = (props) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer components={['DatePicker']}>
        <DatePicker label={props && props.label} disabled={props && props.disabled} value={props && props.value} onClick={props && props.onClick} onChange={props && props.onChange} format="DD-MM-YYYY"/>
      </DemoContainer>
    </LocalizationProvider>
  )
}

export default Date