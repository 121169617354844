import React, { useState, useRef } from 'react'
import { Col, Container, Row, Offcanvas } from "react-bootstrap";
import Sidenav from "../../Componet/Sidenav/Sidenav";
import menu from '../../Photo/menu.svg'
import profile from '../../Photo/profile.svg';
import Input from '../../Componet/InputFild/Input';
import Table from '../../Componet/DataTable/Table';
import { MdOutlineVpnKey } from "react-icons/md";
import { GoSignOut } from "react-icons/go";
import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  colors,
} from "@mui/material";

import { STORAGE_KEY } from "../../constant/common";
import { API_URLS, serviceUrl } from "../../API/Api";
import { useEffect } from 'react';
import { borderRadius, margin } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import Searcable from '../../Componet/Dropdown/Searcable';
const { BalanceApi } = API_URLS;
const added_by = localStorage.getItem(STORAGE_KEY.jwtTOKEN);

async function getZone(credentials) {
  return fetch(BalanceApi.GetZone, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      "Accept": "application/json",
      "Authorization": `Bearer ${added_by}`,
    },
    body: JSON.stringify(credentials)
  })
    .then(res => res.json());
}

async function getAit(credentials) {
  return fetch(BalanceApi.GetAit, {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      "Accept": "application/json",
      "Authorization": `Bearer ${added_by}`,
    },
    body: JSON.stringify(credentials)
  })
    .then(res => res.json());
}

async function getData(credentials) {
  return fetch(BalanceApi.GetData, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      "Accept": "application/json",
      "Authorization": `Bearer ${added_by}`,
    },
    body: JSON.stringify(credentials)
  })
    .then(res => res.json());
}

async function saveData(credentials) {
  return fetch(BalanceApi.SaveData, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      "Accept": "application/json",
      "Authorization": `Bearer ${added_by}`,
    },
    body: JSON.stringify(credentials)
  })
    .then(res => res.json());
}


const OpeningBalance = () => {
  const API_KEY = serviceUrl;

  const selectedToDate = new Date().toISOString().slice(0, 10);

  const [center, setCenter] = useState("");
  const [ait, setAit] = useState("");
  const [NormalBatchno, setNormalBatchno] = useState("");
  const [NormalSemen, setNormalSemen] = useState("");
  const [sortedBatchno, setSortedBatchno] = useState("");
  const [SortedSemen, setSortedSemen] = useState("");
  const [textId, setTextId] = useState("");
  const navigate = useNavigate()
  const logOut = () => { navigate("/") }

  const [heightOffSet, setHeight] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [date, setDate] = useState(selectedToDate)
  const [show, setShow] = useState(false);
  const [headerShow, setHeaderShow] = useState(true);
  const handleClose = () => { setShow(false); }
  const handleShow = () => { setHeaderShow(!headerShow); }
  const [progressData, setProgressData] = useState("0%");

  const [balanceList, setBalanceList] = useState([]);
  const [centerList, setCenterList] = useState([]);
  const [aitList, setAitList] = useState([]);

  const View = async () => {
    setIsLoading(true)
    // const res = await getData({ dataType: 1, inputDate: date, staff: ait.value })
    // if (res) {
    //   setBalanceList(res)
    //   setIsLoading(false)
    // }

    await fetch(BalanceApi.GetData, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        "Accept": "application/json",
        "Authorization": `Bearer ${added_by}`,
      },
      body: JSON.stringify({ dataType: 1, inputDate: date, staff: ait.value })
    })
    .then(response => {
      const contentLength = response.headers.get('content-length');
      let loaded = 0;
      return new Response(
          new ReadableStream({
              start(controller) {
                  const reader = response.body.getReader();
                  read();
                  function read() {
                      reader.read()
                          .then((progressEvent) => {
                              if (progressEvent.done) {
                                  controller.close();
                                  return;
                              }
                              loaded += progressEvent.value.byteLength;
                              const percentageComplete = Math.round(loaded / contentLength * 100) + '%';
                              setProgressData(percentageComplete)
                              controller.enqueue(progressEvent.value);
                              read();
                          })
                  }
              }
          })
      );
  })
      .then(res => res.json())
      .then((result) => { setBalanceList(result);setIsLoading(false); setProgressData("0%"); })
  }

  const Save = async () => {
    const res = await saveData({ batchno: null, createdAt: "", quantity: 2, requestDate: "", requestQuantity: 4, sire: 1, sireVoucherType: 3, sorted: "", staff: 4432, suppliedBy: 1, updatedAt: "", voucherDate: "" });
    if (res) {
      setNormalBatchno(NormalBatchno)
      setNormalSemen(NormalSemen)
      setSortedSemen(SortedSemen)
      setSortedBatchno(sortedBatchno)
    }
  }

  const animalGetData = async () => {
    const res = await getZone();
    const res1 = await getAit();
    if (res) {
      setCenterList(res);
    }
    if (res1) {
      setAitList(res1)
    }
  }

  useEffect(() => {
    let divHeight = document.getElementById("heightOffsetStock")?.offsetHeight;
    setHeight(divHeight);
    animalGetData()
  }, [])

  const textHandle = (e) => {
    setNormalBatchno(e.target.value);
  }
  const textHandle1 = (e) => {
    setNormalSemen(e.target.value)
  }
  const textHandle2 = (e) => {
    setSortedSemen(e.target.value)
  }
  const textHandle3 = (e) => {
    setSortedBatchno(e.target.value)
  }


  const colum = [
    {
      name: "ID",
      selector: "ID",
      width: "100px",
    },
    {
      name: "Code",
      selector: "Code",
    },
    {
      name: "Name",
      selector: "Name",
    },
    {
      name: "Normal Batchno",
      selector: "Normal Batchno",
      cell: (e) => (<input type='text' className="inpField" value={e.NormalBatchno} onChange={(e) => textHandle(e)} />),
    },
    {
      name: "Normal Semen",
      selector: "Normal Semen",
      cell: (e) => (<input type='text' className="inpField" value={e.NormalSemen} onChange={(e) => textHandle1(e)} />),
    },
    {
      name: "Sorted Semen",
      selector: "Sorted Semen",
      cell: (e) => (<input type='text' className="inpField" value={e.SortedSemen} onChange={(e) => textHandle2(e)} />),
    },
    {
      name: "Sorted Batchno",
      selector: "sorted Batchno",
      cell: (e) => (<input type='text' className="inpField" value={e.SortedBatchno} onChange={(e) => textHandle3(e)} />),
    },
    {
      name: "Action",
      cell: (e) => (<button type="button" style={{ border: "none", boxShadow: "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)", backgroundColor: "#43668E", color: "white", borderRadius: "5px" }} onClick={() => Save(e)} >Save</button>),
      width: "100px",
    },
  ]

  return (
    <>
      <Container fluid>
        <Row>
          {headerShow && (<Col lg="2" className="hideNavbar"> <Sidenav /></Col>)}
          <Col className="header" lg={headerShow ? "10" : "12"}>
            <Container fluid>
              <Row className="pt-1 sub-header align-items-center justify-content-between">
                <Col md="11" sm="10" xs="10" className="py-2 d-flex align-items-center">
                  <button style={{ border: "none" }} className="hideNavbar1" onClick={handleShow}><img src={menu} width={20} /></button>
                  <button style={{ border: "none" }} className="hide_Navbar" onClick={() => setShow(true)}><img src={menu} width={20} /></button>
                  <h5 className="ms-4 mt-2">Straw / Opening Balance</h5>
                </Col>
                <Col md="1" sm="1" xs="2" className="text-end">
                  <img src={profile} width={40} />
                </Col>
              </Row>

              <Row className="align-items-end justify-content-between p-2" id='heightOffsetStock'>
                  <Col xxl="2" md="2" sm="2" xs="3" className='justify-content-start'>
                    <FormControl variant="standard" fullWidth>
                      {/* <InputLabel>Center</InputLabel>
                      <Select value={center} onChange={(e) => { setCenter(e.target.value) }}>
                        {centerList.map((e) => (
                          <MenuItem className="drop_value" key={e.id} value={e.Name}>
                            {e.Name}
                          </MenuItem>
                        ))}
                      </Select> */}
                      <Searcable
                        placeholder="Center"
                        options={centerList}
                        onChange={(selectedOption) => setCenter(selectedOption)}
                        value={center}
                        labelKey="Name"
                        valueKey="Name"
                      />
                    </FormControl>
                  </Col>
                  <Col xxl="2" md="3" sm="2" xs="3" className='justify-content-start'>
                    <FormControl variant="standard" fullWidth>
                      {/* <InputLabel>AIT</InputLabel>
                      <Select value={ait} onChange={(e) => { setAit(e.target.value) }}>
                        {aitList.map((e) => (
                          <MenuItem className="drop_value" key={e.id} value={e.ID}>
                            {e.Paravet_Name}
                          </MenuItem>
                        ))}
                      </Select> */}
                      <Searcable
                        placeholder="AIT"
                        options={aitList}
                        onChange={(selectedOption) => setAit(selectedOption)}
                        value={ait}
                        labelKey="Paravet_Name"
                        valueKey="ID"
                      />
                    </FormControl>
                  </Col>
                  <Col md="1" sm="2" xs="4">
                    <sub className="mb-0 pb-0">Date</sub>
                    <input type="date" className="dateSelect_bg" id="date-1" value={date} onChange={(e) => { setDate(e.target.value) }} />
                  </Col>
                <Col xs="3" sm="2" md="2" xl="1" className="mt-2 ms-auto">
                  <button className="btn-blue-Animal mt-1 mb-1" onClick={View}>View</button>
                </Col>
              </Row>
                  {/* <Table columns={colum} data={balanceList} height={`calc(100vh - (${heightOffSet}px + 124px))`} pagination /> */}

                  <Row className="mt-2" id='heightOffsetStock'>
                                {balanceList.length > 0 ?
                                    <div className="blue_bg DataTableBorder mt-1">
                                        <Table columns={colum} data={balanceList} height={`calc(100vh - (${heightOffSet}px + 150px))`} pagination />
                                    </div>
                                    :
                                    <Row>
                                        <Col>
                                            <div className="NoTableBorder ms-2" >
                                                <div className="d-flex tabledata colmBreed">{
                                                    colum.map((i) => {
                                                        return (
                                                            <Col className={`blankColumns ${i.name.replace(' ', '_')}`} style={{ backgroundColor: "rgb(169,169,169)" }} height={`calc(100vh - (${heightOffSet}px + 20px))`} >{i.name}</Col>
                                                        )
                                                    })
                                                }
                                                </div>
                                                <h6 className="text-center align-items-center mt-5 noData ms-5">No Data</h6>
                                            </div>
                                        </Col>
                                    </Row>
                                }
                            </Row>
            </Container>
          </Col>
        </Row>
      </Container>
      {show && <Offcanvas className="hide_Navbar" show={show} onHide={handleClose}><Container><Row><Col><Sidenav /></Col></Row></Container></Offcanvas>}


      {
                isLoading ?
                    <div class="center-body">
                        <div class="loader-square-28">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                    </div> : false
            }
    </>
  )
}
export default OpeningBalance    
