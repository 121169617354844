import React, { useState, useEffect } from 'react'
import { Col, Container, Row, Offcanvas } from "react-bootstrap";
import Sidenav from "../../Componet/Sidenav/Sidenav";
import menu from '../../Photo/menu.svg'
import profile from '../../Photo/profile.svg';
import Input from '../../Componet/InputFild/Input';
import Table from '../../Componet/DataTable/Table';
import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import './Transfer.css';
import { MdOutlineVpnKey } from "react-icons/md";
import { GoSignOut } from "react-icons/go";

import { STORAGE_KEY } from "../../constant/common";
import { API_URLS, serviceUrl } from "../../API/Api";
import { useNavigate } from 'react-router-dom';
import Searcable from '../../Componet/Dropdown/Searcable';
const { TransferAnimal } = API_URLS;
const added_by = localStorage.getItem(STORAGE_KEY.jwtTOKEN);

async function getZone(credentials) {
    return fetch(TransferAnimal.GetZone, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            "Accept": "application/json",
            "Authorization": `Bearer ${added_by}`,
        },
        body: JSON.stringify(credentials)
    })
        .then(res => res.json());
}

async function getProjectData(credentials) {
    return fetch(TransferAnimal.ProjectData, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            "Accept": "application/json",
            "Authorization": `Bearer ${added_by}`,
        },
        body: JSON.stringify(credentials)
    })
        .then(res => res.json());
}

async function getData(credentials) {
    return fetch(TransferAnimal.GetData, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            "Accept": "application/json",
            "Authorization": `Bearer ${added_by}`,
        },
        body: JSON.stringify(credentials)
    })
        .then(res => res.json());
}

async function projectData(credentials) {
    return fetch(TransferAnimal.projectData, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            "Accept": "application/json",
            "Authorization": `Bearer ${added_by}`,
        },
        body: JSON.stringify(credentials)
    })
        .then(res => res.json());
}

async function Transferanimal(credentials) {
    return fetch(TransferAnimal.TransferData, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            "Accept": "application/json",
            "Authorization": `Bearer ${added_by}`,
        },
        body: JSON.stringify(credentials)
    })
        .then(res => res.json());
}

const Animal = () => {

    const API_KEY = serviceUrl;

    const [search, setSearch] = useState("");
    const [center, setCenter] = useState("");
    const [route, setRoute] = useState("");
    const [dcs, setDcs] = useState("");
    const [farmer, setFarmer] = useState("");
    const [lot, setLot] = useState("");
    const [routeVisit, setRouteVisit] = useState("");
    const [farmerName, setFarmerName] = useState("");
    const [searchFilter, setSearchFilter] = useState("")
    const navigate = useNavigate()
    const logOut = () => { navigate("/") }
    const [progressData, setProgressData] = useState("0%");

    const [heightOffSet, setHeight] = useState(0);

    const [animalList, setAnimalList] = useState([]);
    const [centerList, setCenterList] = useState([]);
    const [routeList, setRouteList] = useState([]);
    const [dcsList, setDcsList] = useState([]);
    const [farmerList, setFarmerList] = useState([]);
    const [routeVisitList, setRouteVisitList] = useState([]);
    const [lotList, setLotList] = useState([]);
    const [farmerNameList, setFarmerNameList] = useState([]);
    const [rowsList, setRowsList] = useState([]);

    const [isLoading, setIsLoading] = useState(false);
    const [show, setShow] = useState(false);
    const [headerShow, setHeaderShow] = useState(false);
    const handleClose = () => { setShow(false); }
    const handleShow = () => { setHeaderShow(!headerShow); }


    const fatchAnimalData = async () => {
        const res = await getZone();
        const res1 = await getProjectData();
        if (res) {
            setCenterList(res);
        }
        if (res1) {
            setRouteVisitList(res1);
        }
    }

    useEffect(() => {
        fatchAnimalData();
        let divHeight = document.getElementById("heightOffsetStock")?.offsetHeight;
        setHeight(divHeight);
    }, []);

    let ids = (rowsList.map(i => i.ID));

    const Transfer = async () => {
        setIsLoading(true)
        await fetch(TransferAnimal.GetData, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`,
            },
            body: JSON.stringify({ ahc: center, dataType: "animal", farmer, lot: dcs, project: null, route })
        })
            .then(res => res.json())
            .then(res => {
                setAnimalList(res);
                setIsLoading(false);
                setProgressData("0%")
            })
            .catch((error) => { setIsLoading(false); setProgressData("0%") })
    }

    const selectCenter = (e) => {
        console.log(e);
        setCenter(e);
        fatchRouteData(e);
    }
    const fatchRouteData = async (e) => {
        if (e.value) {
            fetch(`${API_KEY}/cattle-detail/getVisitRoutes/` + e.value, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    "Accept": "application/json",
                    "Authorization": `Bearer ${added_by}`
                },
            })
                .then(res => res.json())
                .then((result) => { setRouteList(result) });
        }
    }

    const selectRoute = (e) => {
        setRoute(e);
        fatchDcsData(e);
    }
    const fatchDcsData = async (e) => {
        if (e.value) {
            fetch(`${API_KEY}/cattle-detail/getVisitLot/` + e.value, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    "Accept": "application/json",
                    "Authorization": `Bearer ${added_by}`
                },
            })
                .then(res => res.json())
                .then((result) => { setDcsList(result) })
        }
    }

    const selectDsc = (e) => {
        setDcs(e);
        fatchFarmerData(e);
    }
    const fatchFarmerData = async (e) => {
        if (e.value) {
            fetch(`${API_KEY}/cattle-detail/getVisitFarmer/` + e.value, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    "Accept": "application/json",
                    "Authorization": `Bearer ${added_by}`
                },
            })
                .then(res => res.json())
                .then((result) => { setFarmerList(result) });

        }
    }

    const selectRoute1 = (e) => {
        setRouteVisit(e);
        fatchLotDate(e);
    }
    const fatchLotDate = async (e) => {
        if (e.value) {
            fetch(`${API_KEY}/cattle-detail/getVisitLot/` + e.value, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    "Accept": "application/json",
                    "Authorization": `Bearer ${added_by}`
                },
            })
                .then(res => res.json())
                .then((result) => { setLotList(result) });

        }
    }

    const selectLot = (e) => {
        setLot(e);
        fatchFarmer1Data(e);
    }
    const fatchFarmer1Data = async (e) => {
        if (e) {
            fetch(`${API_KEY}/cattle-detail/getVisitFarmer/` + e, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    "Accept": "application/json",
                    "Authorization": `Bearer ${added_by}`
                },
            })
                .then(res => res.json())
                .then((result) => { setFarmerNameList(result) })

        }
    }

    const View = async () => {
        setIsLoading(true)
        const res2 = await getData({ ahc: center, dataType: "animal", farmer, lot: dcs, project: null, route });
        if (res2) {
            setAnimalList(res2);
            setIsLoading(false);
            setProgressData("0%")
        }
        else {
            setIsLoading(false);
            setProgressData("0%")
        }
    }
    const SelectedRowFn = (rows) => {
        setRowsList(rows.selectedRows);
    }

    const SearchFn = (e) => {
        const data = e.target.value;
        if (data !== '') {
            const results = animalList.filter((user) => {
                return user.ID.toLowerCase().startsWith(data.toLowerCase())
            });
            setSearchFilter(results);
        } else {
            setSearchFilter(animalList);
        }
        setSearch(data);
    }

    const colum = [
        {
            name: "ID",
            selector: "ID",
        },
        {
            name: "Idno",
            selector: "",
        },
        {
            name: "Status",
            selector: "Status",
        },
        {
            name: "Age",
            selector: "Age",
        },
        {
            name: "Lot Code",
            selector: "Lot Code",
        },
        {
            name: "DCS NAme",
            selector: "DCS NAme",
        },
        {
            name: "Farmer Code",
            selector: "Farmer Code",
        },
        {
            name: "Farmer Name",
            selector: "Farmer Name",
        },
        {
            name: "Farmer MobileNumber",
            selector: "Farmer MobileNumber",
        },
        {
            name: "Suspended",
            selector: "Suspended",
        },
    ]

    return (
        <>
            <Container fluid>
                <Row>
                    {headerShow && (<Col lg="2" className="hideNavbar"> <Sidenav /></Col>)}
                    <Col className="header" lg={headerShow ? "10" : "12"}>
                        <Container fluid>
                            <Row className="pt-1 sub-header align-items-center justify-content-between">
                                <Col md="11" sm="10" xs="10" className="py-2 d-flex align-items-center">
                                    <button style={{ border: "none" }} className="hideNavbar1" onClick={handleShow}><img src={menu} width={20} /></button>
                                    <button style={{ border: "none" }} className="hide_Navbar" onClick={() => setShow(true)}><img src={menu} width={20} /></button>
                                    <h5 className="ms-4 mt-2">Transfer / Animal Transfer</h5>
                                </Col>
                                <Col md="1" sm="1" xs="2" className="text-end profile_dropdown">
                                    <img src={profile} width={40} />
                                    <div className="dropdpwn_Open">
                                        <Row className="text-center align-items-center">
                                            <Col xs="12" className="menuHover mb-3">
                                                <Row >
                                                    <Col className="" xs="3">
                                                        <span className=""><MdOutlineVpnKey size={25} />
                                                        </span>
                                                    </Col>
                                                    <Col className="text-start" xs="9">
                                                        Change Password
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col xs="12" className="menuHover" onClick={logOut}>
                                                <Row>
                                                    <Col className="" xs="3">
                                                        <span className=""><GoSignOut size={25} />
                                                        </span>
                                                    </Col>
                                                    <Col className="text-start" xs="9">
                                                        Sign Out
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            </Row>

                            <Row className="align-items-center justify-content-end p-2" id='heightOffsetStock'>
                                <Col xl="9" md="8" className='justify-content-start'>
                                    <Row className='justify-content-start'>
                                        <Col xl="2" md="3" sm="3" xs="6" className='justify-content-start'>
                                            <FormControl variant="standard" fullWidth>
                                                {/* <InputLabel id="demo-simple-select-standard-label">Center</InputLabel>
                                                <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={center} onChange={(e) => selectCenter(e.target.value)}>
                                                    {centerList.map((e) => (
                                                        <MenuItem className="drop_value" key={e.id} value={e.Id}>
                                                            {e.name}
                                                        </MenuItem>
                                                    ))}
                                                </Select> */}
                                                <Searcable
                                                    placeholder="Center"
                                                    options={centerList}
                                                    onChange={(selectedOption) => selectCenter(selectedOption)}
                                                    value={center}
                                                    labelKey="name"
                                                    valueKey="Id"
                                                />
                                            </FormControl>
                                        </Col>
                                        <Col xl="2" md="3" sm="3" xs="6" className='justify-content-start'>
                                            <FormControl variant="standard" fullWidth>
                                                {/* <InputLabel id="demo-simple-select-standard-label">Route</InputLabel>
                                                <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={route} onChange={(e) => selectRoute(e.target.value)}>
                                                    {routeList.map((e) => (
                                                        <MenuItem className="drop_value" key={e.id} value={e.Id}>
                                                            {e.name}
                                                        </MenuItem>
                                                    ))}
                                                </Select> */}
                                                <Searcable
                                                    placeholder="Route"
                                                    options={routeList}
                                                    onChange={(selectedOption) => selectRoute(selectedOption)}
                                                    value={route}
                                                    labelKey="name"
                                                    valueKey="Id"
                                                />
                                            </FormControl>
                                        </Col>
                                        <Col xl="2" md="3" sm="3" xs="6" className='justify-content-start'>
                                            <FormControl variant="standard" fullWidth>
                                                {/* <InputLabel id="demo-simple-select-standard-label">Dcs</InputLabel>
                                                <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={dcs} onChange={(e) => selectDsc(e.target.value)}>
                                                    {dcsList.map((e) => (
                                                        <MenuItem className="drop_value" key={e.id} value={e.Id}>
                                                            {e.name}
                                                        </MenuItem>
                                                    ))}
                                                </Select> */}
                                                <Searcable
                                                    placeholder="Dcs"
                                                    options={dcsList}
                                                    onChange={(selectedOption) => selectDsc(selectedOption)}
                                                    value={dcs}
                                                    labelKey="name"
                                                    valueKey="Id"
                                                />
                                            </FormControl>
                                        </Col>
                                        <Col xl="2" md="3" sm="3" xs="6" className='justify-content-start'>
                                            <FormControl variant="standard" fullWidth>
                                                {/* <InputLabel id="demo-simple-select-standard-label">Farmer</InputLabel>
                                                <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={farmer} onChange={(e) => setFarmer(e.target.value)}>
                                                    {farmerList.map((e) => (
                                                        <MenuItem className="drop_value" key={e.id} value={e.Id}>
                                                            {e.name}
                                                        </MenuItem>
                                                    ))}
                                                </Select> */}
                                                <Searcable
                                                    placeholder="Farmer"
                                                    options={farmerList}
                                                    onChange={(selectedOption) => setFarmer(selectedOption)}
                                                    value={farmer}
                                                    labelKey="name"
                                                    valueKey="Id"
                                                />
                                            </FormControl>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xl="2" md="2" sm="3" xs="6" className="ps-2 align-items-center justify-content-end">
                                    <Input label="Search" value={search} onChange={e => SearchFn(e)} />
                                </Col>
                                <Col xl="1" md="2" sm="3" xs="6" className="mt-2 align-items-center justify-content-end">
                                    <button className="btn-blue-Animal mt-1 mb-1" onClick={View}>View</button>
                                </Col>
                            </Row>

                            <Row className="mt-2">
                                <Col xl="9">
                                    <Table columns={colum} data={searchFilter ? searchFilter : animalList} height={`calc(100vh - (${heightOffSet}px + 120px))`} pagination selectableRows onSelectedRowsChange={SelectedRowFn} />
                                </Col>
                                <Col xl="3">
                                    <div className='mainCard'>
                                        <Row className='align-items-center justify-content-center'>
                                            <Col className='mt-2' xs="10">
                                                <div className='boxBg text-center p-2 align-items-center'>
                                                    <h6 className='fw-bold m-0'>Select the Destination Herd</h6>
                                                </div>
                                            </Col>
                                            <Col className='mt-1' xs="10">
                                                <div className='boxBg1 px-2 pb-2'>
                                                    <FormControl variant="standard" fullWidth>
                                                        {/* <InputLabel>Project</InputLabel>
                                                        <Select value={routeVisit} onChange={(e) => selectRoute1(e.target.value)}>
                                                            {routeVisitList.map((e) => (
                                                                <MenuItem className="drop_value" key={e.id} value={e.id}>{e.Name}</MenuItem>
                                                            ))}
                                                        </Select> */}

                                                        <Searcable
                                                            placeholder="Project"
                                                            options={routeVisitList}
                                                            onChange={(selectedOption) => selectRoute1(selectedOption)}
                                                            value={routeVisit}
                                                            labelKey="Name"
                                                            valueKey="id"
                                                        />
                                                    </FormControl>
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className='align-items-center mt-4 text-center'>
                                            <Col>
                                                Click on the below to make changes
                                            </Col>
                                        </Row>
                                        <Row className='align-items-center justify-content-center'>
                                            <Col sm="2" xs="4" xl="4" className="mt-2 d-flex align-items-center">
                                                <button className="btn-blue-Animal mt-1 mb-3" onClick={Transfer}>Transfer</button>
                                            </Col>
                                        </Row>
                                    </div>

                                </Col>
                            </Row>


                        </Container>
                    </Col>
                </Row>
            </Container >
            {show && <Offcanvas className="hide_Navbar" show={show} onHide={handleClose}><Container><Row><Col><Sidenav /></Col></Row></Container></Offcanvas>
            }

            {isLoading ?
                <div className="circleText">
                    <div className="progressText">{progressData}</div>
                    <div>
                        <span className="loader-circle-13"></span>
                    </div>
                </div>
                : false
            }


        </>
    )
}
export default Animal;