import React, { useEffect, useRef, useState } from "react";
import { Col, Container, Modal, Offcanvas, Row } from "react-bootstrap";
import Sidenav from "../../Componet/Sidenav/Sidenav";
import menu from '../../Photo/menu.svg';
import profile from '../../Photo/profile.svg';
import { MdOutlineVpnKey } from "react-icons/md";
import { GoSignOut } from "react-icons/go";
import { useNavigate } from 'react-router-dom';
import { TbReport } from "react-icons/tb";
import Searcable from '../../Componet/Dropdown/Searcable';
import { FormControl } from "@mui/material";
import closeButton from "../../Photo/milk-union/close button.svg";
import { FaFilter } from "react-icons/fa";
import { API_URLS } from "../../API/Api";
import { STORAGE_KEY } from "../../constant/common";
import { downloadExcel } from "react-export-table-to-excel";
import moment from "moment/moment";
const { Ahc_Report, ZoneApi, SireApi, visitRegistration, visitCancellation, OpeningBalanceApi } = API_URLS;
const added_by = localStorage.getItem(STORAGE_KEY.jwtTOKEN);

async function getAhc(credentials) {
    return fetch(Ahc_Report.SelectAhc, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            "Accept": "application/json",
            "Authorization": `Bearer ${added_by}`,
        },
        body: JSON.stringify(credentials)
    })
        .then(res => res.json());
}

async function filterAhcFn(bodydata) {
    return fetch(Ahc_Report.FilterAhc + bodydata, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            "Accept": "application/json",
            "Authorization": `Bearer ${added_by}`,
        },
        // body: JSON.stringify(credentials)
    })
        .then(res => res.json());
}

async function getZone(credentials) {
    return fetch(OpeningBalanceApi.CenterDetails, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            "Accept": "application/json",
            "Authorization": `Bearer ${added_by}`,
        },
        body: JSON.stringify(credentials)
    })
        .then(res => res.json());
}

async function getBreed(credentials) {
    return fetch(SireApi.GetBreed, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            "Accept": "application/json",
            "Authorization": `Bearer ${added_by}`,
        },
        body: JSON.stringify(credentials)
    })
        .then(res => res.json());
}

async function getSpecies(credentials) {
    return fetch(visitRegistration.GetAllDataofModalAddVisit, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            "Accept": "application/json",
            "Authorization": `Bearer ${added_by}`,
        },
        body: JSON.stringify(credentials)
    })
        .then(res => res.json());
}

async function getRoute(id) {
    return fetch(visitCancellation.GetFilterUser + `?company_filter=1&zone=${id}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            "Accept": "application/json",
            "Authorization": `Bearer ${added_by}`,
        },
        // body: JSON.stringify(credentials)
    })
        .then(res => res.json());
}

async function getVo(id) {
    return fetch(visitCancellation.GetVONameFilter + `?VOFlag=1,zone:${id}`, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            "Accept": "application/json",
            "Authorization": `Bearer ${added_by}`,
        },
        // body: JSON.stringify(credentials)
    })
        .then(res => res.json());
}

function AhcReport() {

    const navigate = useNavigate();

    const [show, setShow] = useState(false);
    const [headerShow, setHeaderShow] = useState(false);
    const handleClose = () => { setShow(false); }
    const handleShow = () => { setHeaderShow(!headerShow); }

    const logOut = () => { navigate("/") }

    const [ahcData, setAhcData] = useState([]);
    const [zoneData, setZoneData] = useState([]);
    const [breedData, setBreedData] = useState([]);
    const [speciesData, setSpeciesData] = useState([]);
    const [routesData, setRoutesData] = useState([]);
    const [vo, setVo] = useState([]);
    const [filterData, setFilterData] = useState([]);

    const [column, setColumn] = useState([]);

    const [ahcId, setAhcId] = useState("");
    const [routeId, setRouteId] = useState("");
    const [voId, setVoId] = useState("");
    const [reportTypeId, setReportTypeId] = useState("");
    const [toDate, setToDate] = useState("");
    const [fromDate, setFromDate] = useState("");

    const [modalShow, setModalShow] = useState(false);

    const fetchReportData = async () => {
        try {
            const data = await getAhc();
            setAhcData(data)

        } catch (error) {
            console.log(error);
        }
    };

    const fetchZoneData = async () => {
        try {
            const data = await getZone();
            setZoneData(data)
            console.log(data);
        } catch (error) {
            console.log(error);
        }
    }

    const fetchBreedData = async () => {
        try {
            const data = await getBreed();
            setBreedData(data)

        } catch (error) {
            console.log(error);
        }
    }

    const fetchSpeciesData = async () => {
        try {
            const data = await getSpecies();
            setSpeciesData(data)

        } catch (error) {
            console.log(error);
        }
    }

    const zoneChange = async (id) => {
        try {
            const data = await getRoute(id);
            const data1 = await getVo(id);

            setRoutesData(data)
            setVo(data1.data)
            console.log(data1.data);

        } catch (error) {
            console.log(error);
        }
    }

    const applyFn = async () => {
        var from = moment(fromDate).format("MM/DD/YYYY");
        var to = moment(toDate).format("MM/DD/YYYY");

        console.log(fromDate);
        console.log(toDate);

        const data = await filterAhcFn(`?ahc=${ahcId}&route=${routeId}&vo=${voId}&reportType=${reportTypeId}&toDate=${to}&fromDate=${from}`);
        setFilterData(data.data)
        const column1 = Object.keys(data?.data[0]);
        setColumn(column1);
    }

    const ThData = () => {
        console.log("yes")
        return column.map((data) => {
            return <th key={data} className={""} data-value={data}>  {data} </th >
        })
    }

    const tdData = () => {
        return filterData.map((data) => {
            return (
                <tr>
                    {
                        column.map((v, columnIndex) => {
                            console.log(v)
                            return <td sty >{data[v]}</td>
                        })
                    }
                </tr>
            )
        })
    }

    const handleDownloadExcel = () => {
        const header = []
        const opt = `AHC Report`;
        column.map((e, index) => {
            header.push(e)
        })

        downloadExcel({
            fileName: opt,
            sheet: "data",
            tablePayload: {
                header,
                body: filterData
            }
        });
    }

    useEffect(() => {
        fetchReportData();
        fetchZoneData();
        fetchBreedData();
        fetchSpeciesData();
    }, []);

    return (
        <>
            <Container fluid>
                <Row>
                    {headerShow && (<Col lg="2" className="hideNavbar"> <Sidenav /></Col>)}
                    <Col className="header" lg={headerShow ? "10" : "12"}>
                        <Container fluid>
                            <Row className="pt-1 sub-header align-items-center justify-content-between">
                                <Col md="11" sm="10" xs="10" className="py-2 d-flex align-items-center">
                                    <button style={{ border: "none" }} className="hideNavbar1" onClick={handleShow}><img src={menu} width={20} /></button>
                                    <button style={{ border: "none" }} className="hide_Navbar" onClick={() => setShow(true)}><img src={menu} width={20} /></button>
                                    <h5 className="ms-4 mt-2">AHC Report</h5>
                                </Col>
                                <Col md="1" sm="1" xs="2" className="text-end profile_dropdown">
                                    <img src={profile} width={40} />
                                    <div className="dropdpwn_Open">
                                        <Row className="text-center align-items-center">
                                            <Col xs="12" className="menuHover mb-3">
                                                <Row>
                                                    <Col className="" xs="3">
                                                        <span className=""><MdOutlineVpnKey size={25} /></span>
                                                    </Col>
                                                    <Col className="text-start" xs="9">
                                                        Change Password
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col xs="12" className="menuHover" onClick={logOut}>
                                                <Row>
                                                    <Col className="" xs="3">
                                                        <span className=""><GoSignOut size={25} /></span>
                                                    </Col>
                                                    <Col className="text-start" xs="9">
                                                        Sign Out
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            </Row>

                            <Row className='d-flex justify-content-between InputArea py-2'>
                                <Col xxl="6" xl="6" lg="6" md="6" sm="12" xs="12">
                                    <Row>
                                        <Col xxl="4" xl="4" lg="4" md="4" sm="6" xs="12">
                                            <FormControl variant="standard" fullWidth className=" mt-3">
                                                <Searcable
                                                    placeholder="Reports"
                                                    options={ahcData}
                                                    onChange={(selectedOption) => { setReportTypeId(selectedOption.value) }}
                                                    labelKey="AHname"
                                                    valueKey="ID"
                                                />
                                            </FormControl>
                                        </Col>
                                        <Col xxl="4" xl="4" lg="4" md="4" sm="6" xs="12">
                                            <sub className="mb-0 pb-0">From Date</sub>
                                            <input
                                                type="date"
                                                className="dateSelect_bg"
                                                onChange={(e) => { setFromDate(e.target.value) }}
                                            />
                                        </Col>
                                        <Col xxl="4" xl="4" lg="4" md="4" sm="6" xs="12">
                                            <sub className="mb-0 pb-0">To Date</sub>
                                            <input
                                                type="date"
                                                className="dateSelect_bg"
                                                onChange={(e) => { setToDate(e.target.value) }}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xxl="6" xl="6" lg="6" md="6" sm="6" xs="12">
                                    <Row className="d-flex justify-content-end">
                                        <Col xxl="2" xl="2" lg="2" md="4" sm="6" xs="12" className="mt-2 align-items-center justify-content-end">
                                            <button className="btn-blue-Animal mt-1 mb-1" onClick={() => { setModalShow(true) }}>Filter <FaFilter color="#fffff" className="fs-4 ps-2" /></button>
                                        </Col>
                                        <Col xxl="2" xl="2" lg="2" md="4" sm="6" xs="12" className="mt-2 align-items-center justify-content-end">
                                            <button className="btn-blue-Animal mt-1 mb-1" onClick={handleDownloadExcel}>Export</button>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>

                            <Row>
                                <Col xxl="12" xl="12" lg="12" md="12" sm="12" xs="12">
                                    {/* <Table columns={columns} data={filterData} /> */}
                                    <Row>
                                        <Col className="mx-2 mainTable" style={{ overflow: "auto", maxHeight: "80vh" }}>
                                            <table className="overTable" border={1}>

                                                <>
                                                    <thead border={1}>
                                                        <tr className="herdHeader1">{ThData()}</tr>
                                                    </thead>
                                                    <tbody border={1}>
                                                        {tdData()}
                                                    </tbody>
                                                </>
                                            </table>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Container>
                    </Col>
                </Row>
            </Container>
            {show && <Offcanvas className="hide_Navbar" show={show} onHide={handleClose}><Container><Row><Col><Sidenav /></Col></Row></Container></Offcanvas>}

            <Modal size="sm" aria-labelledby="contained-modal-title-vcenter" centered show={modalShow} onHide={() => setModalShow(false)}>
                <Modal.Header style={{ background: "#DFDFDF" }}>
                    <Modal.Title id="contained-modal-title-vcenter">Filter</Modal.Title>
                    <img src={closeButton} onClick={() => setModalShow(false)} />
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Row>
                            <Col xxl="12" xl="12" lg="12" md="12" sm="12" xs="12">
                                <FormControl variant="standard" fullWidth className="mt-3">
                                    <Searcable
                                        placeholder="Ahc"
                                        options={zoneData}
                                        onChange={(selectedOption) => { zoneChange(selectedOption.value); setAhcId(selectedOption.value) }}
                                        labelKey="Name"
                                        valueKey="id"
                                    />
                                </FormControl>

                            </Col>
                            <Col xxl="12" xl="12" lg="12" md="12" sm="12" xs="12">
                                <FormControl variant="standard" fullWidth className="mt-3">
                                    <Searcable
                                        placeholder="Routes"
                                        options={routesData}
                                        onChange={(selectedOption) => { setRouteId(selectedOption.value) }}
                                        labelKey="Name"
                                        valueKey="id"
                                    />
                                </FormControl>

                            </Col>
                            <Col xxl="12" xl="12" lg="12" md="12" sm="12" xs="12">
                                <FormControl variant="standard" fullWidth className="mt-3">
                                    <Searcable
                                        placeholder="Vo"
                                        options={vo}
                                        onChange={(selectedOption) => { setVoId(selectedOption.value) }}
                                        labelKey="Name"
                                        valueKey="id"
                                    />
                                </FormControl>

                            </Col>
                            <Col xxl="12" xl="12" lg="12" md="12" sm="12" xs="12">
                                <FormControl variant="standard" fullWidth className=" mt-3">
                                    <Searcable
                                        placeholder="Species"
                                        options={speciesData}
                                        labelKey="Name"
                                        valueKey="id"
                                    />
                                </FormControl>
                            </Col>
                            <Col xxl="12" xl="12" lg="12" md="12" sm="12" xs="12">
                                <FormControl variant="standard" fullWidth className=" mt-3">
                                    <Searcable
                                        placeholder="Breed"
                                        options={breedData}
                                        labelKey="Name"
                                        valueKey="id"
                                    />
                                </FormControl>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Container>
                        <Row className="d-flex justify-content-end">
                            <Col xxl="6" xl="6" lg="6" md="6" sm="6" xs="6" className="mt-2 align-items-center justify-content-end">
                                <button className="btn-blue-Animal mt-1 mb-1" onClick={() => { applyFn(); setModalShow(false) }}>Apply</button>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Footer>
            </Modal>
        </>
    )
}
export default AhcReport;