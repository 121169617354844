import React, { useState, useEffect, useCallback, useRef, Fragment, useMemo } from 'react'
import { Col, Container, Row, Offcanvas } from "react-bootstrap";
import Sidenav from "../Componet/Sidenav/Sidenav";
import menu from '../Photo/menu.svg'
import profile from '../Photo/profile.svg';
import './Map.css';
import { GoogleMap, Rectangle } from "react-google-maps"
import "leaflet/dist/leaflet.css"
import markerIconPng from "leaflet/dist/images/marker-icon.png"
import { Icon } from 'leaflet'
import { MdOutlineVpnKey } from "react-icons/md";
import { GoSignOut } from "react-icons/go";

import { STORAGE_KEY } from "../constant/common";
import { API_URLS, serviceUrl } from "../API/Api";
import {
    FormControl,
    InputLabel,
    Select,
    MenuItem,
} from "@mui/material";
import moment from 'moment';
import { Circle, FeatureGroup, LayerGroup, MapContainer, Marker, Popup, TileLayer, Tooltip } from 'react-leaflet';
import { useNavigate } from 'react-router-dom';
import Searcable from '../Componet/Dropdown/Searcable';



const { MapAnalyticsApi } = API_URLS;
const added_by = localStorage.getItem(STORAGE_KEY.jwtTOKEN);


const MapAnalytics = () => {
    const API_KEY = serviceUrl;
    // const API_KEY = "https://hais.hap.in";


    async function getAnimalData(credentials) {
        return fetch(MapAnalyticsApi.AnimalGet, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`,
            },
            body: JSON.stringify(credentials)
        })
            .then(res => res.json());
    }
    async function getProjectData(credentials) {
        return fetch(MapAnalyticsApi.ProjectGetData, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`,
            },
            body: JSON.stringify(credentials)
        })
            .then(res => res.json());
    }


    const [animal, setAnimal] = useState("")
    const [condition, setCondition] = useState("")
    const [project, setProject] = useState("")
    const [animalCount, setAnimalCount] = useState("")

    const [isLoading, setIsLoading] = useState(false);
    const [show, setShow] = useState(false);
    const [headerShow, setHeaderShow] = useState(false);
    const handleClose = () => { setShow(false); }
    const handleShow = () => { setHeaderShow(!headerShow); }
    const [progressData, setProgressData] = useState("0%");
    const navigate = useNavigate()
    const logOut = () => { navigate("/") }

    const [animalList, setAnimalList] = useState([])
    const [dateList, setDateList] = useState([])
    const [conditionList, setConditionList] = useState([])
    const [projectList, setProjectList] = useState([])
    const [mapData, setMapData] = useState([])
    // const [headCount, setHeadCount] = useState([])

    const lastWeek = new Date();
    const selectedToDate = new Date().toISOString().slice(0, 10);
    lastWeek.setDate(lastWeek.getDate() - 7);

    const selectedFromDate = lastWeek.toISOString().slice(0, 10);

    console.log(selectedFromDate)

    const animalType = [{ value: "Herd Profile" },
    { value: "Breed Profile" },
    { value: "Calf Profile" },
    { value: "Dry Profile" },
    { value: "Open Unbred Profile" },
    { value: "Open Animal Profile" },
    { value: "Days From AI" },
    { value: "Calving to Conception Animal Profile" },
    { value: "Pragnent Animal Profile" },
    { value: "No. of AI Effciency for Pregnent Animals" },
    { value: "No. of AI Effciency for Open Animals" },
    { value: "Heifers Profile" },
    { value: "Average Milk Yield Profile" },
    { value: "Peak Milk Yield Profile" },
    { value: "Total Milk Yield Profile" },
    { value: "Disease Record(s)" },
    { value: "Animals Treated" },
    { value: "Medicine Used" },
    { value: "Antibiotic Used" },
    { value: "Vaccination Done" },
    { value: "Deworming Entry" },
    { value: "Animal(s) Purchased" },
    { value: "Disposed Animal(s)" },
    { value: "AI Done" },
    { value: "PD Done" },
    { value: "Calving Done" },
    { value: "Dry Done" }
]


    const DateFn = () => {
        const date1 = new Date(document.getElementById("date-1").value);
        const date2 = new Date(document.getElementById("date-2").value);

        if (date1.getTime() && date2.getTime()) {

            function dateRange(date1, date2, steps = 1) {
                const dateArray = [];
                let currentDate = date1;
                while (currentDate <= date2) {
                    dateArray.push(moment(currentDate).format("L"));
                    currentDate.setUTCDate(currentDate.getUTCDate() + steps);
                }
                return dateArray
            }
            const dates = dateRange(date1, date2);
            setDateList(dates);
        }
    }

    const selectAnimal = async (e) => {
        setAnimal(e)
        await fetch(`${API_KEY}/animal-analytics/get_condition_by_datatype?data_type=${e}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`,
            },
        })
            .then(response => {
                const contentLength = response.headers.get('content-length');
                let loaded = 0;
                return new Response(
                    new ReadableStream({
                        start(controller) {
                            const reader = response.body.getReader();
                            read();
                            function read() {
                                reader.read()
                                    .then((progressEvent) => {
                                        if (progressEvent.done) { controller.close(); return; }
                                        loaded += progressEvent.value.byteLength;
                                        const percentageComplete = Math.round(loaded / contentLength * 100) + '%';
                                        setProgressData(percentageComplete)
                                        controller.enqueue(progressEvent.value);
                                        read();
                                    })
                            }
                        }
                    })
                );
            })
            .then((res) => res.json())
            .then((result) => { setConditionList(result); setIsLoading(false); setProgressData("0%") })
            .catch((error) => setIsLoading(false))
    }


    const vertices = [];
    for (let index = 0; index < mapData.length; index++) {
        const lattitude = mapData[index]["lattitude"];
        const Longitude = mapData[index]["Longitude"];
        const headCount = mapData[index]["headCount"];
        vertices.push({ lat: lattitude, lng: Longitude, headCount: headCount })
    }

    const getMapData = async () => {
        const res = await getProjectData()
        if (res) { setProjectList(res) }
    }

    useEffect(() => {
        getMapData()
    }, [project, condition, animal])


    const center = { lat: 22.309425, lng: 72.136230 }

    const fillBlueOptions = { fillColor: 'blue' }
    const purpleOptions = { color: 'purple' }
    const greenOptions = { color: 'green', fillColor: 'green' }
    const fillRedOptions = { fillColor: 'red' }
    const skyBlueFill = { color: 'rgba(0, 255, 255, 1)' }
    const skyBlueLight = { color: 'rgba(0, 191, 255, 1)' }
    const skyBlueLight1 = { color: 'rgba(0, 63, 255, 1)' }
    const skyBlueLight2 = { color: 'rgba(0, 0, 255, 1)' }
    const skyBlueLight3 = { color: 'rgba(0, 0, 255, 1)' }
    const skyBlueLight4 = { color: 'rgba(0, 0, 223, 1)' }
    const skyBlueLight5 = { color: 'rgba(0, 0, 191, 1)' }
    const skyBlueLight6 = { color: 'rgba(0, 0, 127, 1)' }
    const skyBlueLight7 = { color: 'rgba(63, 0, 91, 1)' }
    const skyBlueLight8 = { color: 'rgba(127, 0, 63, 1)' }
    const skyBlueLight9 = { color: 'rgba(191, 0, 31, 1)' }
    const skyBlueLight10 = { color: 'rgba(255, 0, 0, 1)' }

    const ViewFn = async (e) => {
        const dateFilter = `'${selectedFromDate}' AND '${selectedToDate}'`;
        await fetch(`${API_KEY}/animal-analytics/get_heatmap_data?data_type=${animal}&user_code=201&date_filter=%27${selectedFromDate}%27%20AND%20%27${selectedToDate}%27&block_filter=0&district_filter=0&state_filter=0&condition_filter=${condition.value ? condition.value : 0}&virtual_lot_filter=${project ? project : 0}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`,
            },
        })
            .then(response => {
                const contentLength = response.headers.get('content-length');
                let loaded = 0;
                return new Response(
                    new ReadableStream({
                        start(controller) {
                            const reader = response.body.getReader();
                            read();
                            function read() {
                                reader.read()
                                    .then((progressEvent) => {
                                        if (progressEvent.done) { controller.close(); return; }
                                        loaded += progressEvent.value.byteLength;
                                        const percentageComplete = Math.round(loaded / contentLength * 100) + '%';
                                        setProgressData(percentageComplete)
                                        controller.enqueue(progressEvent.value);
                                        read();
                                    })
                            }
                        }
                    })
                );
            })
            .then((res) => res.json())
            .then((result) => {setAnimalCount(result.length); setMapData(result);  setIsLoading(false); setProgressData("0%"); })
            .catch((error) => setIsLoading(false))
    }


    const centerSelect = (e) => {
        console.log("hello")
    }
    const position = [22.309425, 72.136230]

    console.log(animalCount)
    return (
        <>
            <Container fluid>
                <Row>
                    {headerShow && (<Col lg="2" className="hideNavbar"> <Sidenav /></Col>)}
                    <Col className="header" lg={headerShow ? "10" : "12"}>
                        <Container fluid>
                            <Row className="pt-1 sub-header align-items-center justify-content-between">
                                <Col md="11" sm="10" xs="10" className="py-2 d-flex align-items-center">
                                    <button style={{ border: "none" }} className="hideNavbar1" onClick={handleShow}><img src={menu} width={20} /></button>
                                    <button style={{ border: "none" }} className="hide_Navbar" onClick={() => setShow(true)}><img src={menu} width={20} /></button>
                                    <h5 className="ms-4 mt-2"> Map Analytics</h5>
                                </Col>
                                <Col md="1" sm="1" xs="2" className="text-end profile_dropdown">
                                    <img src={profile} width={40} />
                                    <div className="dropdpwn_Open">
                                        <Row className="text-center align-items-center">
                                            <Col xs="12" className="menuHover mb-3">
                                                <Row >
                                                    <Col className="" xs="3">
                                                        <span className=""><MdOutlineVpnKey size={25} />
                                                        </span>
                                                    </Col>
                                                    <Col className="text-start" xs="9">
                                                        Change Password
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col xs="12" className="menuHover" onClick={logOut}>
                                                <Row>
                                                    <Col className="" xs="3">
                                                        <span className=""><GoSignOut size={25} />
                                                        </span>
                                                    </Col>
                                                    <Col className="text-start" xs="9">
                                                        Sign Out
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            </Row>
                            <Row className='align-items-center mt-3'>
                                <Col>
                                    <div className='mapHeader align-items-end d-flex'>
                                        <Col xl="2">
                                            <h5>Total Animal : {animalCount} </h5>
                                        </Col>
                                        <Col xl="2" className='ms-auto'>
                                            <FormControl variant="standard" fullWidth>
                                                {/* <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>Report Type</InputLabel>
                                                <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard"
                                                    value={animal} onChange={(e) => { selectAnimal(e.target.value); }}>
                                                    {animalType.map((e) => (
                                                        <MenuItem className="drop_value" key={e} value={e}>
                                                            {e}
                                                        </MenuItem>
                                                    ))}
                                                </Select> */}
                                                <Searcable
                                                    placeholder="Report Type"
                                                    onChange={(selectedOption) => selectAnimal(selectedOption.value)}
                                                    options={animalType}
                                                    labelKey="value"
                                                    valueKey="value"
                                                />

                                            </FormControl>
                                        </Col>
                                        <Col xl="2">
                                            <FormControl variant="standard" fullWidth>
                                                {/* <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>Condition</InputLabel>
                                                <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard"
                                                    value={condition} onChange={(e) => { setCondition(e.target.value); }}>
                                                    {conditionList.map((e) => (
                                                        <MenuItem className="drop_value" key={e.id} value={e.id}>
                                                            {e.name}
                                                        </MenuItem>
                                                    ))}
                                                </Select> */}
                                                <Searcable
                                                    placeholder="Condition"
                                                    options={conditionList}
                                                    onChange={(selectedOption) => setCondition(selectedOption)}
                                                    value={condition}
                                                    labelKey="name"
                                                    valueKey="id"
                                                />
                                            </FormControl>
                                        </Col>
                                        <Col xl="1">
                                            <div>
                                                <sub className="mb-0 pb-0">From Date</sub>
                                                <input type="date" className="dateSelect_bg" id="date-1" defaultValue={selectedFromDate} onChange={() => DateFn()} />
                                            </div>
                                        </Col>
                                        <Col xl="1">
                                            <div className="mt-1">
                                                <sub className="mb-0 pb-0">To Date</sub>
                                                <input type="date" className="dateSelect_bg" id="date-2" defaultValue={selectedToDate} onChange={() => DateFn()} />
                                            </div>
                                        </Col>
                                        <Col xl="2">
                                            <FormControl variant="standard" fullWidth>
                                                {/* <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>Project</InputLabel>
                                                <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard"
                                                    value={project} onChange={(e) => { setProject(e.target.value); }}>
                                                    {
                                                        projectList.map((e) => (
                                                            <MenuItem className="drop_value" key={e.id} value={e.id}>
                                                                {e.Name}
                                                            </MenuItem>
                                                        ))
                                                    }
                                                </Select> */}
                                                <Searcable
                                                    placeholder="Project"
                                                    options={projectList}
                                                    onChange={(selectedOption) => setProject(selectedOption)}
                                                    value={project}
                                                    labelKey="Name"
                                                    valueKey="id"
                                                />
                                            </FormControl>
                                        </Col>
                                        <Col xxl="1" >
                                            <button className="btn-blue-Animal" onClick={ViewFn}>View</button>
                                        </Col>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col >
                                    <MapContainer center={center} zoom={8} className="mainMap">
                                        <TileLayer
                                            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                                            className='harsh'
                                        />
                                        {
                                            vertices.map((e) => {
                                                if (e["headCount"] == 1 || e["headCount"] == 2) {
                                                    console.log("jhj")
                                                    return (
                                                        <>
                                                            <div className="harsh">
                                                                <FeatureGroup pathOptions={skyBlueFill} >
                                                                    {/* <Marker position={[e["lat"], e["lng"]]} icon={new Icon({ iconUrl: markerIconPng, iconSize: [25, 41], iconAnchor: [12, 41] })} onClick={centerSelect}>
                                                                        <Popup>
                                                                            <p>{e.lat}</p>
                                                                            <p>{e.lng}</p>
                                                                        </Popup>
                                                                    </Marker> */}
                                                                    <Circle center={e} radius={900} stroke={true} />
                                                                </FeatureGroup>
                                                            </div >
                                                        </>

                                                    )
                                                }
                                                else if (e["headCount"] == 2 || e["headCount"] == 3 || e["headCount"] == 4 || e["headCount"] == 5) {
                                                    return (
                                                        <FeatureGroup pathOptions={skyBlueLight}>
                                                            <Circle center={e} radius={100} stroke={true} />
                                                        </FeatureGroup>
                                                    )
                                                }
                                                else if (e["headCount"] == 6 || e["headCount"] == 7 || e["headCount"] == 8 || e["headCount"] == 9) {
                                                    return (
                                                        <FeatureGroup pathOptions={skyBlueLight1}>
                                                            <Circle center={e} radius={10} stroke={false} />
                                                        </FeatureGroup>
                                                    )
                                                }
                                                else if (e["headCount"] == 10 || e["headCount"] == 11 || e["headCount"] == 12 || e["headCount"] == 13 || e["headCount"] == 14) {
                                                    return (
                                                        <FeatureGroup pathOptions={skyBlueLight2}>
                                                            <Circle center={e} radius={10} stroke={false} />
                                                        </FeatureGroup>
                                                    )
                                                }
                                                else if (e["headCount"] == 15 || e["headCount"] == 16 || e["headCount"] == 17 || e["headCount"] == 18 || e["headCount"] == 19 || e["headCount"] == 20) {
                                                    return (
                                                        <FeatureGroup pathOptions={skyBlueLight3}>
                                                            <Circle center={e} radius={10} stroke={false} />
                                                        </FeatureGroup>
                                                    )
                                                }
                                                else if (e["headCount"] == 21 || e["headCount"] == 22 || e["headCount"] == 23 || e["headCount"] == 24 || e["headCount"] == 25 || e["headCount"] == 26 || e["headCount"] == 27) {
                                                    return (
                                                        <FeatureGroup pathOptions={skyBlueLight4}>
                                                            <Circle center={e} radius={10} stroke={false} />
                                                        </FeatureGroup>
                                                    )
                                                }
                                                else if (e["headCount"] == 28 || e["headCount"] == 29 || e["headCount"] == 30 || e["headCount"] == 31 || e["headCount"] == 32 || e["headCount"] == 33 || e["headCount"] == 34 || e["headCount"] == 35) {
                                                    return (
                                                        <FeatureGroup pathOptions={skyBlueLight5}>
                                                            <Circle center={e} radius={10} stroke={false} />
                                                        </FeatureGroup>
                                                    )
                                                }
                                                else if (e["headCount"] == 36 || e["headCount"] == 37 || e["headCount"] == 38 || e["headCount"] == 39 || e["headCount"] == 40 || e["headCount"] == 41 || e["headCount"] == 42 || e["headCount"] == 44) {
                                                    return (
                                                        <FeatureGroup pathOptions={skyBlueLight6}>
                                                            <Circle center={e} radius={10} stroke={false} />
                                                        </FeatureGroup>
                                                    )
                                                }
                                                else if (e["headCount"] == 45 || e["headCount"] == 46 || e["headCount"] == 47 || e["headCount"] == 48 || e["headCount"] == 49 || e["headCount"] == 50 || e["headCount"] == 51 || e["headCount"] == 52 || e["headCount"] == 53 || e["headCount"] == 54) {
                                                    return (
                                                        <FeatureGroup pathOptions={skyBlueLight7}>
                                                            <Circle center={e} radius={10} stroke={false} />
                                                        </FeatureGroup>
                                                    )
                                                }
                                                else if (e["headCount"] == 55 || e["headCount"] == 56 || e["headCount"] == 57 || e["headCount"] == 58 || e["headCount"] == 59 || e["headCount"] == 60 || e["headCount"] == 61 || e["headCount"] == 62 || e["headCount"] == 63 || e["headCount"] == 64 || e["headCount"] == 65) {
                                                    return (
                                                        <FeatureGroup pathOptions={skyBlueLight8}>
                                                            <Circle center={e} radius={10} stroke={false} />
                                                        </FeatureGroup>
                                                    )
                                                }
                                                else if (e["headCount"] == 66 || e["headCount"] == 67 || e["headCount"] == 68 || e["headCount"] == 69 || e["headCount"] == 70 || e["headCount"] == 71 || e["headCount"] == 72 || e["headCount"] == 73 || e["headCount"] == 74 || e["headCount"] == 75 || e["headCount"] == 76 || e["headCount"] == 77) {
                                                    return (
                                                        <FeatureGroup pathOptions={skyBlueLight9}>
                                                            <Circle center={e} radius={10} stroke={false} />
                                                        </FeatureGroup>
                                                    )
                                                }
                                                else if (e["headCount"] == 78 || e["headCount"] == 79 || e["headCount"] == 80 || e["headCount"] == 81 || e["headCount"] == 82 || e["headCount"] == 83 || e["headCount"] == 84 || e["headCount"] == 85 || e["headCount"] == 86 || e["headCount"] == 87 || e["headCount"] == 88 || e["headCount"] == 89 || e["headCount"] == 90) {
                                                    return (
                                                        <FeatureGroup pathOptions={skyBlueLight10}>
                                                            <Circle center={e} radius={10} stroke={false} />
                                                        </FeatureGroup>
                                                    )
                                                }
                                                else {
                                                    return (
                                                        <FeatureGroup pathOptions={fillRedOptions}>
                                                            <Circle center={e} radius={10} stroke={false} />
                                                        </FeatureGroup>
                                                    )
                                                }
                                            })
                                        }
                                    </MapContainer>
                                </Col>
                                <Col className='mapTable' xxl="2">
                                    <table className='mapTable'>
                                        <tbody>
                                            <tr>
                                                <th>Color</th>
                                                <th>Density</th>
                                            </tr>
                                            <tr className='m-2'>
                                                <td style={{ backgroundColor: "rgba(0, 255, 255, 1)", width: "40px", height: "10px" }}></td>
                                                <td className='ms-2'>1-2</td>
                                            </tr>
                                            <tr className='mt-1'>
                                                <td style={{ backgroundColor: "rgba(0, 191, 255, 1)", width: "40px", height: "10px" }}></td>
                                                <td className='ms-2'>2-5</td>
                                            </tr>
                                            <tr className='mt-1'>
                                                <td style={{ backgroundColor: "rgba(0, 127, 255, 1)", width: "40px", height: "10px" }}></td>
                                                <td className='ms-2'>5-9</td>
                                            </tr>
                                            <tr className='mt-1'>
                                                <td style={{ backgroundColor: "rgba(0, 63, 255, 1)", width: "40px", height: "10px" }}></td>
                                                <td className='ms-2'>9-14</td>
                                            </tr>
                                            <tr className='mt-1'>
                                                <td style={{ backgroundColor: "rgba(0, 0, 255, 1)", width: "40px", height: "10px" }}></td>
                                                <td className='ms-2'>14-20</td>
                                            </tr>
                                            <tr className='mt-1'>
                                                <td style={{ backgroundColor: "rgba(0, 0, 223, 1)", width: "40px", height: "10px" }}></td>
                                                <td className='ms-2'>20-27</td>
                                            </tr>
                                            <tr className='mt-1'>
                                                <td style={{ backgroundColor: "rgba(0, 0, 191, 1)", width: "40px", height: "10px" }}></td>
                                                <td className='ms-2'>27-35</td>
                                            </tr>
                                            <tr className='mt-1'>
                                                <td style={{ backgroundColor: "rgba(0, 0, 127, 1)", width: "40px", height: "10px" }}></td>
                                                <td className='ms-2'>44-54</td>
                                            </tr>
                                            <tr className='mt-1'>
                                                <td style={{ backgroundColor: "rgba(63, 0, 91, 1)", width: "40px", height: "10px" }}></td>
                                                <td className='ms-2'>54-65</td>
                                            </tr>
                                            <tr className='mt-1'>
                                                <td style={{ backgroundColor: "rgba(127, 0, 63, 1)", width: "40px", height: "10px" }}></td>
                                                <td className='ms-2'>65-77</td>
                                            </tr>
                                            <tr className='mt-1'>
                                                <td style={{ backgroundColor: "rgba(191, 0, 31, 1)", width: "40px", height: "10px" }}></td>
                                                <td className='ms-2'>77-90</td>
                                            </tr>
                                            <tr className='mt-1'>
                                                <td style={{ backgroundColor: "rgba(255, 0, 0, 1)", width: "40px", height: "10px" }}></td>
                                                <td className='ms-2'>100</td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </Col>
                            </Row>
                        </Container>
                    </Col>
                </Row>
            </Container >
            {show && <Offcanvas className="hide_Navbar" show={show} onHide={handleClose}><Container><Row><Col><Sidenav /></Col></Row></Container></Offcanvas>
            }

            {
                isLoading ?
                    <div className="circleText">
                        <div className="progressText">{progressData}</div>
                        <div className="">
                            <span className="loader-circle-13"></span>
                        </div>
                    </div>
                    : false
            }
        </>
    )
}
export default MapAnalytics;