import React, { useState, useEffect } from "react";
import './dashboard.css';
import { Col, Container, Row, Accordion, Offcanvas, Modal } from "react-bootstrap";
import { ResponsiveContainer, LineChart, Line, XAxis, YAxis, Tooltip, Legend, CartesianGrid, BarChart, Bar, PieChart, Pie, Cell, LabelList, Label } from "recharts";
import { FormControl, FormControlLabel, InputLabel, MenuItem, Radio, RadioGroup, Select, TextField } from '@mui/material';
import { CanvasJSChart } from 'canvasjs-react-charts';

import menu from '../../Photo/menu.svg';
import profile from '../../Photo/profile.svg';
import breeding from '../../Photo/breeding.svg';
import cow from '../../Photo/cow.svg';
import face1 from '../../Photo/animalFace1.svg';
import cattle1 from '../../Photo/cattle1.svg';
import cattle2 from '../../Photo/cattle2.svg';
import cattle3 from '../../Photo/cattle3.svg';
import cattle4 from '../../Photo/cattle4.svg';
import cattle5 from '../../Photo/cattle5.svg';
import farmer1 from '../../Photo/farmer1.svg';
import farmer2 from '../../Photo/farmer2.svg';

import Table from "../../Componet/DataTable/Table";
import Dropdown from "../../Componet/Dropdown/Dropdown";
import Input from "../../Componet/InputFild/Input";
import LightBtn from "../../Componet/Button/LightBtn";
import Sidenav from "../../Componet/Sidenav/Sidenav";
import { MdEscalator, MdOutlineVpnKey } from "react-icons/md";
import { GoSignOut } from "react-icons/go";
import './CattleRegistration.css'

import Axios from "axios";
import moment from "moment";
import { Navigate, useNavigate } from 'react-router-dom';
import { STORAGE_KEY } from "../../constant/common";
import { API_URLS, serviceUrl } from "../../API/Api";
import Addanimal from "../Project/Addanimal";
import closeButton from "../../Photo/milk-union/close button.svg";
import { ToastContainer, toast } from "react-toastify";
import { ResponsivePie } from "@nivo/pie";
import Chart from "react-google-charts";
import ReactApexChart from "react-apexcharts";
import BackBtn from "../../Componet/Button/BackBtn";
const added_by = localStorage.getItem(STORAGE_KEY.jwtTOKEN);
const { CattleReport, Cattleregistration, FIPTreatment, DisposalApi } = API_URLS;

async function getDisposalType(credentials) {
    return fetch(DisposalApi.DisposalTypeApi, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            "Accept": "application/json",
            "Authorization": `Bearer ${added_by}`,
        },
        body: JSON.stringify(credentials)
    })
        .then(data => data.json());
}


export default function CattleRegistration() {
    const added_by = localStorage.getItem(STORAGE_KEY.jwtTOKEN);
    const lastWeek = new Date();
    const selectedToDate = new Date().toISOString().slice(0, 10);

    const navigate = useNavigate();
    const API_KEY = serviceUrl;
    // const API_KEY = "https://hais.hap.in";

    const [id, setId] = useState("");
    const [id1, setId1] = useState("");
    const [id2, setId2] = useState("");
    const [id3, setId3] = useState("");

    const [tagid, setTagId] = useState("");
    const [tagidFind, setTagIdFind] = useState("");
    const [center, setCenter] = useState("");
    const [dcs, setDCS] = useState("");
    const [router, setRouter] = useState("");
    const [farmer, setFarmer] = useState("");
    const [species, setSpecies] = useState("");
    const [breed, setBreed] = useState("");
    const [status, setStatus] = useState("");
    const [b_status, setB_Status] = useState("");
    const [age, setAge] = useState("");
    const [current1, setCurrent1] = useState("");
    const [current2, setCurrent2] = useState("");
    const [current3, setCurrent3] = useState("");
    const [current4, setCurrent4] = useState("");
    const [current5, setCurrent5] = useState("");
    const [current6, setCurrent6] = useState("");
    const [current7, setCurrent7] = useState("");
    const [current8, setCurrent8] = useState("");
    const [current9, setCurrent9] = useState("");
    const [current10, setCurrent10] = useState("");
    const [current11, setCurrent11] = useState("");
    const [current12, setCurrent12] = useState("");
    const [current13, setCurrent13] = useState("");
    const [current14, setCurrent14] = useState("");
    const [current15, setCurrent15] = useState("");
    const [current16, setCurrent16] = useState("");
    const [current17, setCurrent17] = useState("");
    const [current18, setCurrent18] = useState("");
    const [parityno, setParityNo] = useState("");
    const [statusImage, setStatusImage] = useState("")
    const [statusImageShow, setStatusImageShow] = useState("")
    const [progressData, setProgressData] = useState("0%");
    const [parity, setParity] = useState("")
    const [image, setImage] = useState("")
    const [imageUrl, setImageUrl] = useState(null)
    const logOut = () => { navigate("/") }
    const [herdId, setHerdId] = useState("")
    const [lotId, setLotId] = useState("")
    const [farmerId, setFarmerId] = useState("")
    const [selectArea, setSelectArea] = useState("Herdwise")
    const [selectSpecies, setSelectSpecies] = useState("Cow")
    const [date, setDate] = useState(selectedToDate)
    const [reason, setReason] = useState("")
    const [disposalType, setDisposalType] = useState("")
    const [system, setSystem] = useState("")
    const [soldTo, setSoldTo] = useState("")
    const [enterPrice, setEnterPrice] = useState("")
    const [dispoalTagId, setDispoalTagId] = useState("")
    const [deHerdId, setDeHerdId] = useState("")

    const [isLoading, setIsLoading] = useState(false);
    const [heightOffSet, setHeight] = useState(0);
    const [main_modal, setMainModal] = useState(false);
    const [sub_modal, setSubModal] = useState(false);
    const [general, setGeneral] = useState(true);
    const [lifeTime, setLifeTime] = useState(false);
    const [lactation, setLactation] = useState(false);
    const [parent, setParent] = useState(false);
    const [details, setDetails] = useState(true);
    const [summary, setSummary] = useState(false);
    const [compoShow, setCompoShow] = useState(false)
    const [assign, setAssign] = useState(true);
    const [isExpandable, setIsExpandable] = useState(true)
    const [isExpandable1, setIsExpandable1] = useState(true)
    const [isExpandable2, setIsExpandable2] = useState(true)
    const [isExpandable3, setIsExpandable3] = useState(true)
    const [isExpandable4, setIsExpandable4] = useState(false)
    const [buttonShow, setButtonShow] = useState(false)
    const [modalShow, setModalShow] = useState(false);
    const [mainGraph, setMainGraph] = useState(true)

    const [show, setShow] = useState(false);
    const [headerShow, setHeaderShow] = useState(true);
    const handleClose = () => { setShow(false); }
    const handleShow = () => { setHeaderShow(!headerShow); }


    const [animaltree, setAnimalTree] = useState([]);
    const [animalList, setAnimalList] = useState([]);
    const [sub1, setSub1] = useState([]);
    const [sub2, setSub2] = useState([]);
    const [sub3, setSub3] = useState([]);
    const [parityId, setParityId] = useState([]);
    const [parityList, setParityList] = useState([]);
    const [animallifeList, setAnimalLifeList] = useState([]);
    const [SummeryList, setSummeryList] = useState([]);
    const [lifetimeDetails, setLifetimeDetails] = useState([]);

    const [Linecharts, setLineCharts] = useState([]);
    const [Piecharts, setPieCharts] = useState([]);
    const [Barcharts1, setBarCharts1] = useState([]);
    const [Barcharts2, setBarCharts2] = useState([]);
    const [Barcharts3, setBarCharts3] = useState([]);
    const [animalList1, setAnimalList1] = useState([])
    const [animalDataList, setAnimalDataList] = useState([])
    const [addAnimal, setAddAnimal] = useState([])
    const [lotList, setLotList] = useState([])
    const [farmerList, setFarmerList] = useState([])
    const [disposalTypeList, setDisposalTypeList] = useState([])
    const [reasonTypeList, setReasonTypeList] = useState([])
    const [systemEffectdList, setSystemEffectdList] = useState([])
    const [specFn1, setSpecFn1] = useState([])

    const dataGet = async () => {
        const res = await getDisposalType()
        if (res) { setDisposalTypeList(res.master_disposal); setReasonTypeList(res.master_disposalSubOptions); setSystemEffectdList(res.master_systemAffected) }
    }

    const defaultDataShow = async (e) => {
        await fetch(`${Cattleregistration.TreeByArea}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`,
            },
            body: JSON.stringify({ Herdcode: e, Herdtype: "1Herd", userCode: "1" })
        })
            .then(res => res.json())
            .then((result) => {
                setBarCharts1(result);
                setFilteredData1(result)
                setMainModal(true);
            })
        await fetch(`${Cattleregistration.TreeByArea}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`,
            },
            body: JSON.stringify({ Herdcode: e, Herdtype: "2Herd", userCode: "1" })
        })
            .then(res => res.json())
            .then((result) => {
                setPieCharts(result);
                setMainModal(true);
            })

        await fetch(`${Cattleregistration.TreeByArea}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`,
            },
            body: JSON.stringify({ Herdcode: e, Herdtype: "3Herd", userCode: "1" })
        })
            .then(res => res.json())
            .then((result) => {
                setBarCharts2(result);
                setFilteredData3(result)
                setMainModal(true);
            })

        await fetch(`${Cattleregistration.TreeByArea}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`,
            },
            body: JSON.stringify({ Herdcode: e, Herdtype: "4Herd", userCode: "1" })
        })
            .then(res => res.json())
            .then((result) => {
                setBarCharts3(result);
                setFilteredData4(result)
                setMainModal(true);
            })
        await fetch(`${Cattleregistration.TreeByArea}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`,
            },
            body: JSON.stringify({ Herdcode: e, Herdtype: "5Herd", userCode: "1" })
        })
            .then(res => res.json())
            .then((result) => {
                setAnimalList(result);
                setMainModal(true);
            })
    }

    const handleCheckboxChange = (value) => {
        if (lifetimeDetails.includes(value)) {
            setLifetimeDetails(lifetimeDetails.filter(item => item !== value));
        } else {
            setLifetimeDetails([...lifetimeDetails, value]);
        }
    };



    useEffect(() => {
        let divHeight = document.getElementById("heightOffsetStock")?.offsetHeight;
        setHeight(divHeight);
        setMainModal(true);
        dataGet()
        defaultDataShow()

        setIsLoading(true);
        fetch(`${Cattleregistration.TreeByArea}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`,
            },
            body: JSON.stringify({ userCode: "1", Herdtype: selectArea, Herdcode: 1 })
        })
            .then(response => {
                const contentLength = response.headers.get('content-length');
                let loaded = 0;
                return new Response(
                    new ReadableStream({
                        start(controller) {
                            const reader = response.body.getReader();
                            read();
                            function read() {
                                reader.read()
                                    .then((progressEvent) => {
                                        if (progressEvent.done) { controller.close(); return; }
                                        loaded += progressEvent.value.byteLength;
                                        const percentageComplete = Math.round(loaded / contentLength * 100) + '%';
                                        setProgressData(percentageComplete)
                                        controller.enqueue(progressEvent.value);
                                        read();
                                    })
                            }
                        }
                    })
                );
            })
            .then(data => data.json())
            .then((result) => {
                setAnimalTree(result);
                const id = result[0].herdid
                defaultDataShow(id)
                setIsLoading(false)
            }
            )
            .catch((error) => { setIsLoading(false) })
    }, []);


    const AddAi = async () => {
        navigate("/resgistraion/add");
    }

    const getIdFn = async (e) => {
        setMainGraph(true)
        setIsExpandable((prev) => !prev)
        let herdId1 = e.herdid;
        let herdKey = e.Key;

        setId1(herdId1);
        setIsLoading(true);

        if (isExpandable == true) {
            await fetch(`${Cattleregistration.TreeByArea}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    "Accept": "application/json",
                    "Authorization": `Bearer ${added_by}`,
                },
                body: JSON.stringify({ userCode: "1", Herdtype: herdKey ? herdKey : selectArea, Herdcode: herdId1 ? herdId1 : 1 })
            })
                .then(response => {
                    const contentLength = response.headers.get('content-length');
                    let loaded = 0;
                    return new Response(
                        new ReadableStream({
                            start(controller) {
                                const reader = response.body.getReader();
                                read();
                                function read() {
                                    reader.read()
                                        .then((progressEvent) => {
                                            if (progressEvent.done) { controller.close(); return; }
                                            loaded += progressEvent.value.byteLength;
                                            const percentageComplete = Math.round(loaded / contentLength * 100) + '%';
                                            setProgressData(percentageComplete)
                                            controller.enqueue(progressEvent.value);
                                            read();
                                        })
                                }
                            }
                        })
                    );
                })
                .then(data => data.json())
                .then((result) => { setLotList(result); setIsLoading(false) })
                .catch((error) => { setIsLoading(false) })
        }
        else {
            setIsLoading(false)
            setLotList([])
        }

        fetch(`${Cattleregistration.TreeByArea}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`,
            },
            body: JSON.stringify({ Herdcode: herdId1 ? herdId1 : 7, Herdtype: herdKey ? `1${herdKey}` : "1Herd", userCode: "1" })
        })
            .then(res => res.json())
            .then((result) => {
                setBarCharts1(result);
                setMainModal(true);
            })

        fetch(`${Cattleregistration.TreeByArea}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`,
            },
            body: JSON.stringify({ Herdcode: herdId1, Herdtype: herdKey ? `2${herdKey}` : selectArea, userCode: "1" })
        })
            .then(res => res.json())
            .then((result) => {
                setPieCharts(result);
                setMainModal(true);
            })

        fetch(`${Cattleregistration.TreeByArea}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`,
            },
            body: JSON.stringify({ Herdcode: herdId1, Herdtype: herdKey ? `3${herdKey}` : selectArea, userCode: "1" })
        })
            .then(res => res.json())
            .then((result) => {
                setBarCharts2(result);
                setMainModal(true);
            })

        fetch(`${Cattleregistration.TreeByArea}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`,
            },
            body: JSON.stringify({ Herdcode: herdId1, Herdtype: herdKey ? `4${herdKey}` : selectArea, userCode: "1" })
        })
            .then(res => res.json())
            .then((result) => {
                setBarCharts3(result);
                setMainModal(true);
            })

        fetch(`${Cattleregistration.TreeByArea}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`,
            },
            body: JSON.stringify({ Herdcode: herdId1, Herdtype: herdKey ? `5${herdKey}` : selectArea, userCode: "1" })
        })
            .then(res => res.json())
            .then((result) => {
                setAnimalList(result);
                setMainModal(true);
            })


        // fetch(`${Cattleregistration.CustomReport}?herdId=` + herdId1, {
        //     method: 'GET',
        //     headers: {
        //         'Content-Type': 'application/json',
        //         "Accept": "application/json",
        //         "Authorization": `Bearer ${added_by}`,
        //     },
        // })
        //     .then(res => res.json())
        //     .then((result) => {
        //         setMainModal(true);
        //         setPieCharts(result.statusGroupingPieChartInfo);
        //         setBarCharts1(result.speciesGroupingBarGraphInfo);
        //         setBarCharts2(result.milkingDryGroupingBarGraph);
        //         setBarCharts3(result.breedingStatusGroupingBarGraph);
        //     })

        // fetch(`${CattleReport.GetTreebyId}?herdId=` + herdId1, {
        //     method: 'GET',
        //     headers: {
        //         'Content-Type': 'application/json',
        //         "Accept": "application/json",
        //         "Authorization": `Bearer ${added_by}`,
        //     },
        // })
        //     .then(res => res.json())
        //     .then((result) => { setSub1(result); },
        //         (err) => {
        //             console.log(err);
        //         }
        //     )

        // fetch(`${Cattleregistration.CustomReportByTable}?offset=0&limit=15&column_name=id&sort_order=desc&search_term=&level=0&id=` + herdId1, {
        //     method: 'GET',
        //     headers: {
        //         'Content-Type': 'application/json',
        //         "Accept": "application/json",
        //         "Authorization": `Bearer ${added_by}`,
        //     },
        // })
        //     .then(res => res.json())
        //     .then((result) => { setAnimalList(result.data); })

    }

    const getIdFn1 = async (e) => {
        setMainGraph(true)
        setIsExpandable2((prev) => !prev)
        let herdId1 = e.lotid ? e.lotid : e.id;
        setId2(herdId1)
        let herdKey = e.Key;
        setIsLoading(true)

        if (isExpandable2 == true) {
            await fetch(`${Cattleregistration.TreeByArea}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    "Accept": "application/json",
                    "Authorization": `Bearer ${added_by}`,
                },
                body: JSON.stringify({ userCode: "1", Herdtype: herdKey ? herdKey : selectArea, Herdcode: herdId1 ? herdId1 : 1 })
            })
                .then(response => {
                    const contentLength = response.headers.get('content-length');
                    let loaded = 0;
                    return new Response(
                        new ReadableStream({
                            start(controller) {
                                const reader = response.body.getReader();
                                read();
                                function read() {
                                    reader.read()
                                        .then((progressEvent) => {
                                            if (progressEvent.done) { controller.close(); return; }
                                            loaded += progressEvent.value.byteLength;
                                            const percentageComplete = Math.round(loaded / contentLength * 100) + '%';
                                            setProgressData(percentageComplete)
                                            controller.enqueue(progressEvent.value);
                                            read();
                                        })
                                }
                            }
                        })
                    );
                })
                .then(data => data.json())
                .then((result) => { setFarmerList(result); setIsLoading(false) })
                .catch((error) => { setIsLoading(false) })
        }
        else {
            setIsLoading(false)
            setFarmerList([])
        }



        fetch(`${Cattleregistration.TreeByArea}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`,
            },
            body: JSON.stringify({ Herdcode: herdId1, Herdtype: herdKey ? `1${herdKey}` : selectArea, userCode: "1" })
        })
            .then(res => res.json())
            .then((result) => {
                setBarCharts1(result);
                setMainModal(true);
            })

        fetch(`${Cattleregistration.TreeByArea}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`,
            },
            body: JSON.stringify({ Herdcode: herdId1, Herdtype: herdKey ? `2${herdKey}` : selectArea, userCode: "1" })
        })
            .then(res => res.json())
            .then((result) => {
                setPieCharts(result);
                setMainModal(true);
            })

        fetch(`${Cattleregistration.TreeByArea}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`,
            },
            body: JSON.stringify({ Herdcode: herdId1, Herdtype: herdKey ? `3${herdKey}` : selectArea, userCode: "1" })
        })
            .then(res => res.json())
            .then((result) => {
                setBarCharts2(result);
                setMainModal(true);
            })

        fetch(`${Cattleregistration.TreeByArea}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`,
            },
            body: JSON.stringify({ Herdcode: herdId1, Herdtype: herdKey ? `4${herdKey}` : selectArea, userCode: "1" })
        })
            .then(res => res.json())
            .then((result) => {
                setBarCharts3(result);
                setMainModal(true);
            })


        // fetch(`${Cattleregistration.CustomReportByLot}?lotId=` + herdId1, {
        //     method: 'GET',
        //     headers: {
        //         'Content-Type': 'application/json',
        //         "Accept": "application/json",
        //         "Authorization": `Bearer ${added_by}`
        //     },
        // })
        //     .then(res => res.json())
        //     .then((result) => {
        //         setMainModal(true);
        //         setPieCharts(result.statusGroupingPieChartInfo);
        //         setBarCharts1(result.speciesGroupingBarGraphInfo);
        //         setBarCharts2(result.milkingDryGroupingBarGraph);
        //         setBarCharts3(result.breedingStatusGroupingBarGraph);
        //     })

        // fetch(`${Cattleregistration.CustomReportByFarmer}?lotId=` + herdId1, {
        //     method: 'GET',
        //     headers: {
        //         'Content-Type': 'application/json',
        //         "Accept": "application/json",
        //         "Authorization": `Bearer ${added_by}`,
        //     },
        // })
        //     .then(res => res.json())
        //     .then((result) => {
        //     });

        // fetch(`${Cattleregistration.CustomReportByTable}?offset=0&limit=15&column_name=id&sort_order=desc&search_term=&level=1&id=` + herdId1, {
        //     method: 'GET',
        //     headers: {
        //         'Content-Type': 'application/json',
        //         "Accept": "application/json",
        //         "Authorization": `Bearer ${added_by}`
        //     },
        // })
        //     .then(res => res.json())
        //     .then((result) => { setAnimalList(result.data); })
    }


    const getIdFn2 = async (e) => {
        setMainGraph(true)
        setIsExpandable3(prev => !prev)

        let herdId1 = e.farmerid;
        setId3(herdId1)
        let herdKey = e.Key;
        setIsLoading(true)
        if (isExpandable3 == true) {
            await fetch(`${Cattleregistration.TreeByArea}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    "Accept": "application/json",
                    "Authorization": `Bearer ${added_by}`,
                },
                body: JSON.stringify({ userCode: "1", Herdtype: herdKey ? herdKey : selectArea, Herdcode: herdId1 ? herdId1 : 1 })
            })
                .then(response => {
                    const contentLength = response.headers.get('content-length');
                    let loaded = 0;
                    return new Response(
                        new ReadableStream({
                            start(controller) {
                                const reader = response.body.getReader();
                                read();
                                function read() {
                                    reader.read()
                                        .then((progressEvent) => {
                                            if (progressEvent.done) { controller.close(); return; }
                                            loaded += progressEvent.value.byteLength;
                                            const percentageComplete = Math.round(loaded / contentLength * 100) + '%';
                                            setProgressData(percentageComplete)
                                            controller.enqueue(progressEvent.value);
                                            read();
                                        })
                                }
                            }
                        })
                    );
                })
                .then(data => data.json())
                .then((result) => { setAnimalDataList(result); setIsLoading(false) })
                .catch((error) => { setIsLoading(false) })
        }
        else {
            setIsLoading(false)
            setAnimalDataList([])
        }


        fetch(`${Cattleregistration.TreeByArea}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`,
            },
            body: JSON.stringify({ Herdcode: herdId1, Herdtype: herdKey ? `1${herdKey}` : selectArea, userCode: "1" })
        })
            .then(res => res.json())
            .then((result) => {
                setBarCharts1(result);
                setMainModal(true);
            })

        fetch(`${Cattleregistration.TreeByArea}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`,
            },
            body: JSON.stringify({ Herdcode: herdId1, Herdtype: herdKey ? `2${herdKey}` : selectArea, userCode: "1" })
        })
            .then(res => res.json())
            .then((result) => {
                setPieCharts(result);
                setMainModal(true);
            })

        fetch(`${Cattleregistration.TreeByArea}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`,
            },
            body: JSON.stringify({ Herdcode: herdId1, Herdtype: herdKey ? `3${herdKey}` : selectArea, userCode: "1" })
        })
            .then(res => res.json())
            .then((result) => {
                setBarCharts2(result);
                setMainModal(true);
            })

        fetch(`${Cattleregistration.TreeByArea}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`,
            },
            body: JSON.stringify({ Herdcode: herdId1, Herdtype: herdKey ? `4${herdKey}` : selectArea, userCode: "1" })
        })
            .then(res => res.json())
            .then((result) => {
                setBarCharts3(result);
                setMainModal(true);
            })

        // fetch(`${Cattleregistration.FarmerById}?farmerId=` + herdId1, {
        //     method: 'GET',
        //     headers: {
        //         'Content-Type': 'application/json',
        //         "Accept": "application/json",
        //         "Authorization": `Bearer ${added_by}`
        //     },
        // })
        //     .then(res => res.json())
        //     .then((result) => {
        //         setMainModal(true);
        //         setPieCharts(result.statusGroupingPieChartInfo);
        //         setBarCharts1(result.speciesGroupingBarGraphInfo);
        //         setBarCharts2(result.milkingDryGroupingBarGraph);
        //         setBarCharts3(result.breedingStatusGroupingBarGraph);
        //     })

        // fetch(`${Cattleregistration.TreeByFarmer}?farmerId=` + herdId1, {
        //     method: 'GET',
        //     headers: {
        //         'Content-Type': 'application/json',
        //         "Accept": "application/json",
        //         "Authorization": `Bearer ${added_by}`
        //     },
        // })
        //     .then(res => res.json())
        //     .then((result) => {
        //         // setSub3(result);
        //     });

        // fetch(`${Cattleregistration.CustomReportByTable}?offset=0&limit=15&column_name=id&sort_order=desc&search_term=&level=2&id=` + herdId1, {
        //     method: 'GET',
        //     headers: {
        //         'Content-Type': 'application/json',
        //         "Accept": "application/json",
        //         "Authorization": `Bearer ${added_by}`
        //     },
        // })
        //     .then(res => res.json())
        //     .then((result) => { setAnimalList(result.data); })
    }

    const getDatils = async (e) => {
        setMainGraph(false)
        setButtonShow(true)
        let id = e.Animalid;
        setId(e.Animal);
        if (id) {
            setIsLoading(true)
            fetch(`${Cattleregistration.AnimalData}?id=` + id, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    "Accept": "application/json",
                    "Authorization": `Bearer ${added_by}`
                },
            })
                .then(response => {
                    const contentLength = response.headers.get('content-length');
                    let loaded = 0;
                    return new Response(
                        new ReadableStream({
                            start(controller) {
                                const reader = response.body.getReader();
                                read();
                                function read() {
                                    reader.read()
                                        .then((progressEvent) => {
                                            if (progressEvent.done) { controller.close(); return; }
                                            loaded += progressEvent.value.byteLength;
                                            const percentageComplete = Math.round(loaded / contentLength * 100) + '%';
                                            setProgressData(percentageComplete)
                                            controller.enqueue(progressEvent.value);
                                            read();
                                        })
                                }
                            }
                        })
                    );
                })
                .then(res => res.json())
                .then((result) => {
                    setDispoalTagId(result.animalData.TagId)
                    setAddAnimal(result.animalData)
                    setSubModal(true);
                    setMainModal(false);
                    setParityId(result.parityFilter);
                    setAnimalLifeList(result.animalLifeEvents);
                    setIsLoading(false);
                    setProgressData("0%")
                    setImage(result.lactationCurve);
                    setHerdId(result.animalData.herdId)
                    setLotId(result.animalData.lotId)
                    setFarmerId(result.animalData.farmerId)

                    const blob = new Blob([image], { type: 'image/jpeg' });
                    const imageUrl = URL.createObjectURL(blob);
                    setImageUrl(imageUrl)
                })
                .catch((error) => setIsLoading(false))
        }
        if (id) {
            setIsLoading(true)
            fetch(`${Cattleregistration.AnimalGet}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    "Accept": "application/json",
                    "Authorization": `Bearer ${added_by}`
                },
                body: JSON.stringify({ tagId: e.Animal })
            })
                .then(res => res.json())
                .then((result) => {
                    const imageUrl = result[result?.length - 1].Fld;
                    setStatusImage(imageUrl)
                    setIsLoading(false);
                    setProgressData("0%");
                    setTagId(result[0].Fld);
                    setCenter(result[1].Fld);
                    setDCS(result[2].Fld);
                    setRouter(result[3].Fld);
                    setFarmer(result[4].Fld);
                    setSpecies(result[5].Fld);
                    setBreed(result[6].Fld);
                    setStatus(result[7].Fld);
                    setB_Status(result[8].Fld);
                    setAge(result[9].Fld);
                    setParity(result[13].Fld);
                    localStorage.setItem(STORAGE_KEY.Parity, result[14].Fld);
                });
        }
        if (id) {
            fetch(`${Cattleregistration.AnimalDetailsCurv}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    "Accept": "application/json",
                    "Authorization": `Bearer ${added_by}`
                },
                body: JSON.stringify({ tagId: e.Animal })
            })
                .then(res => res.json())
                .then((result) => { setLineCharts(result); });
        }
        if (id) {
            fetch(`${FIPTreatment.GetCurrentData}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    "Accept": "application/json",
                    "Authorization": `Bearer ${added_by}`
                },
                body: JSON.stringify({ tagId: e.Animal })
            })
                .then(res => res.json())
                .then((result) => {
                    setCurrent1(result[0][1]); setCurrent2(result[0][2]); setCurrent3(result[0][3]); setCurrent4(result[0][4]); setCurrent5(result[0][5]); setCurrent6(result[0][6]);
                    setCurrent7(result[1][1]); setCurrent8(result[1][2]); setCurrent9(result[1][3]); setCurrent10(result[1][4]); setCurrent11(result[1][5]); setCurrent12(result[1][6]);
                    setCurrent13(result[2][1]); setCurrent14(result[2][2]); setCurrent15(result[2][3]); setCurrent16(result[2][4]); setCurrent17(result[2][5]); setCurrent18(result[2][6]);
                });
        }
    }

    const imageSHow = async () => {
        // await fetch(`${statusImage}`, {
        //     method: 'GET',
        //     headers: {
        //         'Content-Type': 'application/json',
        //         "Accept": "application/json",
        //         "Authorization": `Bearer ${added_by}`
        //     },
        //     body: JSON.stringify({ tagId: e.Animal })
        // })
        //     .then(res => res.arrayBuffer())
        //     .then((buffer) => {
        //         const bytes = new Uint8Array(buffer);
        //         setS(bytes)
        //     });
    }

    const generalBtn = () => {
        setGeneral(true);
        setLifeTime(false);
        setLactation(false);
        setParent(false);
    }

    function lifeTimeBtn() {
        setLifeTime(true);
        setGeneral(false);
        setLactation(false);
        setParent(false);
    }

    const lactationBtn = () => {
        setLactation(true);
        setLifeTime(false);
        setGeneral(false);
        setParent(false);
    }

    const parentBtn = () => {
        setParent(true);
        setLactation(false);
        setLifeTime(false);
        setGeneral(false);
    }

    var dataMin = 0;
    var dataMax = 1000;

    const lifePreviewFn = async () => {
        if (id) {
            const apiUrl = `${Cattleregistration.LifeTimeDetails}`;

            const response = await fetch(apiUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/pdf',
                    'Authorization': `Bearer ${added_by}`,
                },
                body: JSON.stringify({
                    id, lifetime_details: lifetimeDetails
                })
            });

            const pdfBlob = await response.blob();

            const pdfUrl = URL.createObjectURL(pdfBlob);

            const link = document.createElement('a');
            window.open(pdfUrl, '_blank');
            // link.href = pdfUrl;
            // link.target = '_blank';
            link.download = 'document.pdf';

            document.body.appendChild(link);

            link.click();

            URL.revokeObjectURL(pdfUrl);
        }
    }

    const ParitySelectFn = (e) => {
        setParityNo(e);
        if (e == "Parity" || e == "") { }
        else {
            fetch(`${Cattleregistration.ReproductionDetails}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    "Accept": "application/json",
                    "Authorization": `Bearer ${added_by}`
                },
                body: JSON.stringify({ parity: e, tagid })
            })
                .then(res => res.json())
                .then((result) => {
                    setParityList(result);
                });
        }
        if (e == "Parity" || e == "") { }
        else {
            fetch(`${Cattleregistration.CattleByParity}?id=` + id + "&parity=" + e, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    "Accept": "application/json",
                    "Authorization": `Bearer ${added_by}`

                },
            })
                .then(res => res.json())
                .then((result) => {
                    setSummeryList(result.breedingEvents);
                });
        }
    }

    const menuitm = ["1", "2", "3", "4", "5"];

    const columns = [
        {
            name: "Date",
            selector: "HeatDate",
            cell: (e) => <p className="mb-0">{moment(e.HeatDate).format("L")}</p>
        },
        {
            name: "Age",
            selector: "Age",
        },
    ]

    const animalcol = [
        {
            name: "Tag ID",
            selector: "Tag ID",
        },
        {
            name: "Species",
            selector: "Species",
        },
        {
            name: "Status",
            selector: "Status",
        },
        {
            name: "Breeding Status",
            selector: "Breeding Status",
        },
    ]

    const paritycol = [
        {
            name: "Heat Date",
            cell: (e) => <p className="mb-0">{moment(e.Heat_Date).format("L")}</p>,
            width: "110px",
        },
        {
            name: "Sire",
            selector: "Sire",
        },
        {
            name: "AIT",
            selector: "AIT",
            width: "270px",
        },
        {
            name: "PD Date",
            cell: (e) => <p className="mb-0">{moment(e.PD_Date).format("L")}</p>,
            width: "110px",
        },
        {
            name: "PD Result",
            selector: "PD Result",
        },
        {
            name: "Calving Date",
            cell: (e) => <p className="mb-0">{moment(e.Calving_Date).format("L")}</p>,
            width: "110px",
        },
    ]

    const sidenavFn = () => {
        handleShow();
    }

    const getCowData = (status) => {
        Barcharts1
            .filter((e) => e.Species === 'Cow' && e.Status === status)
            .map((e) => e.Animal || 0);
    }
    const getBuffaloData = (status) => {
        Barcharts1
            .filter((e) => e.Species === 'Buffalo' && e.Status === status)
            .map((e) => e.Animal || 0);
    }

    const SearchTagidFn = () => {
        if (tagidFind) {
            fetch(`${Cattleregistration.AnimalData}?id=` + tagidFind, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    "Accept": "application/json",
                    "Authorization": `Bearer ${added_by}`
                },
            })
                .then(res => res.json())
                .then((result) => {
                    setAddAnimal(result.animalData)
                    setSubModal(true);
                    setMainModal(false);
                    setParityId(result.parityFilter);
                    setAnimalLifeList(result.animalLifeEvents);
                });

            fetch(`${Cattleregistration.AnimalGet}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    "Accept": "application/json",
                    "Authorization": `Bearer ${added_by}`
                },
                body: JSON.stringify({ tagId: tagidFind })
            })
                .then(res => res.json())
                .then((result) => {
                    setTagId(result[0].Fld);
                    setCenter(result[1].Fld);
                    setDCS(result[2].Fld);
                    setRouter(result[3].Fld);
                    setFarmer(result[4].Fld);
                    setSpecies(result[5].Fld);
                    setBreed(result[6].Fld);
                    setStatus(result[7].Fld);
                    setB_Status(result[8].Fld);
                    setAge(result[9].Fld);
                    setParity(result[13].Fld);
                    localStorage.setItem(STORAGE_KEY.Parity, result[14].Fld);
                });

            fetch(`${Cattleregistration.AnimalDetailsCurv}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    "Accept": "application/json",
                    "Authorization": `Bearer ${added_by}`
                },
                body: JSON.stringify({ tagId: tagidFind })
            })
                .then(res => res.json())
                .then((result) => { setLineCharts(result); });

            fetch(`${FIPTreatment.GetCurrentData}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    "Accept": "application/json",
                    "Authorization": `Bearer ${added_by}`
                },
                body: JSON.stringify({ tagId: tagidFind })
            })
                .then(res => res.json())
                .then((result) => {
                    setCurrent1(result[0][1]);
                    setCurrent2(result[0][2]);
                    setCurrent3(result[0][3]);
                    setCurrent4(result[0][4]);
                    setCurrent5(result[0][5]);
                    setCurrent6(result[0][6]);
                    setCurrent7(result[1][1]);
                    setCurrent8(result[1][2]);
                    setCurrent9(result[1][3]);
                    setCurrent10(result[1][4]);
                    setCurrent11(result[1][5]);
                    setCurrent12(result[1][6]);
                    setCurrent13(result[2][1]);
                    setCurrent14(result[2][2]);
                    setCurrent15(result[2][3]);
                    setCurrent16(result[2][4]);
                    setCurrent17(result[2][5]);
                    setCurrent18(result[2][6]);
                });
        }
    }

    const breedingPageFn = () => {
        navigate('/BreedingDetails');
        const parityFilter = parity === null ? "1" : parity
        localStorage.setItem("Fld", tagid)
        localStorage.setItem("Parity", parityFilter)
        localStorage.setItem("HerdId", herdId)
        localStorage.setItem("LotId", lotId)
        localStorage.setItem("FarmerId", farmerId)
    }

    const COLORS = ["#F9D57A", '#DF3E52', '#CC6868', '#A9CF57', '#E0DA3A', '#55E1C0', '#577EE1', '#8A45E2', '#E77C71'];
    const barColors = ["#8FA9F5", "#89DEBF", "#F9D57A", "#E77C71", '#55E1C0', '#577EE1', '#8A45E2', '#E77C71', '#A9CF57', '#DF3E52', '#E0DA3A'];

    const EditAi = () => {
        navigate(`/resgistraion/add`)
        localStorage.setItem("editAnimal", JSON.stringify(addAnimal))
    }

    const productionHandler = () => {
        navigate("/Production")
        localStorage.setItem("Fld", tagid);
        localStorage.setItem("editAnimal", JSON.stringify(addAnimal));
    }

    const treatmenthandle = () => {
        navigate("/Treatment")
    }

    const angle = 90;


    const disposalHandle = () => {
        setModalShow(true)
    }

    const SaveDisposal = async () => {
        setIsLoading(true)
        await fetch(`${DisposalApi.SaveDisposal}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`,
            },
            body: JSON.stringify({ OldTagId: dispoalTagId, TagId: dispoalTagId, Date: date, SoldTo: soldTo, SoldPrice: enterPrice ? enterPrice : "0.0", herd: herdId, lot: lotId, farmer: farmerId, oldDetails: 1, disposalReason: reason ? reason : 1, diedReason: system ? system : 0, createdAt: date, createdByUser: 1, Staff: "1", Disposaltype: disposalType ? disposalType : 1 })
        })
            .then(response => {
                const contentLength = response.headers.get('content-length');
                let loaded = 0;
                return new Response(
                    new ReadableStream({
                        start(controller) {
                            const reader = response.body.getReader();
                            read();
                            function read() {
                                reader.read()
                                    .then((progressEvent) => {
                                        if (progressEvent.done) { controller.close(); return; }
                                        loaded += progressEvent.value.byteLength;
                                        const percentageComplete = Math.round(loaded / contentLength * 100) + '%';
                                        setProgressData(percentageComplete)
                                        controller.enqueue(progressEvent.value);
                                        read();
                                    })
                            }
                        }
                    })
                );
            })
            .then(data => data.json())
            .then((result) => { setModalShow(false); toast.success("Disposed Animal Successfully"); setIsLoading(false); setProgressData("0%") })
            .catch((error) => { setIsLoading(false) })
    }

    const refreshHandler = async () => {
        setIsLoading(true)
        await fetch(`${DisposalApi.RefreshApi}/${dispoalTagId}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`,
            },
        })
            .then(response => {
                const contentLength = response.headers.get('content-length');
                let loaded = 0;
                return new Response(
                    new ReadableStream({
                        start(controller) {
                            const reader = response.body.getReader();
                            read();
                            function read() {
                                reader.read()
                                    .then((progressEvent) => {
                                        if (progressEvent.done) { controller.close(); return; }
                                        loaded += progressEvent.value.byteLength;
                                        const percentageComplete = Math.round(loaded / contentLength * 100) + '%';
                                        setProgressData(percentageComplete)
                                        controller.enqueue(progressEvent.value);
                                        read();
                                    })
                            }
                        }
                    })
                );
            })
            .then(data => data.json())
            .then((result) => { setModalShow(false); toast.success("Disposed Animal Successfully"); setIsLoading(false); setProgressData("0%") })
            .catch((error) => { setIsLoading(false) })
    }

    const [filteredData1, setFilteredData1] = useState(Barcharts1);
    const [filteredData3, setFilteredData3] = useState(Barcharts2);
    const [filteredData4, setFilteredData4] = useState(Barcharts3);

    // const [selectLable, setSelectLabel] = useState("All");
    const [selectedSpecies, setSelectedSpecies] = useState('Cow');
    const [selectPiechart, setSelectPiechart] = useState([]);

    const handleSpeciesChange = (e) => {
        const selectedSpecies = e.target.value;
        setSelectSpecies(selectedSpecies);
        const Barcharts1_B = Barcharts1
        const Barcharts1_C = Barcharts1
        const Barcharts2_B = Piecharts
        const Barcharts2_C = Piecharts
        const Barcharts3_B = Barcharts2
        const Barcharts3_C = Barcharts2
        const Barcharts4_B = Barcharts3
        const Barcharts4_C = Barcharts3
        if (selectedSpecies == 'Buffalo') {
            const filter1 = Barcharts1_B.filter((i) => i.Species == "Buffalo")
            const filter2 = Barcharts2_B.filter((i) => i.Species == "Buffalo")
            const filter3 = Barcharts3_B.filter((i) => i.Species == "Buffalo")
            const filter4 = Barcharts4_B.filter((i) => i.Species == "Buffalo")
            setFilteredData1(filter1);
            setSelectPiechart(filter2);
            setFilteredData3(filter3)
            setFilteredData4(filter4)
            // setBarCharts3([])
        } else if (selectedSpecies == 'Cow') {
            const filter1 = Barcharts1_C.filter((i) => i.Species == "Cow")
            const filter2 = Barcharts2_C.filter((i) => i.Species == "Cow")
            const filter3 = Barcharts3_C.filter((i) => i.Species == "Cow")
            const filter4 = Barcharts4_C.filter((i) => i.Species == "Cow")
            setFilteredData1(filter1);
            setSelectPiechart(filter2);
            setFilteredData3(filter3)
            setFilteredData4(filter4)
        } else if (selectedSpecies == 'Both') {
            setFilteredData1(Barcharts1);
            setSelectPiechart(Piecharts);
            setFilteredData3(Barcharts2)
            setFilteredData4(Barcharts3)
            // defaultDataShow()
        }
        else {
            defaultDataShow()
        }
    };


    const getRandomColor = () => {
        return '#' + (Math.random().toString(16) + '000000').substring(2, 8);
    };
    const colors = ['#FF5733', '#5733FF', '#57FF33', '#3357FF', '#FF33A2', '#33FF57'];

    useEffect(() => {
        if (selectedSpecies == 'Cow') {
            const filteredData = Piecharts.filter((i) => i.Species === 'Cow');
            setSelectPiechart(filteredData);
        } else if (selectedSpecies == 'Buffalo') {
            const filteredData = Piecharts.filter((i) => i.Species === 'Buffalo');
            setSelectPiechart(filteredData);
        }
    }, [statusImage, selectedSpecies, Piecharts]);



    return (
        <>
            <Container fluid className="max-width">
                <Row>
                    {headerShow && (
                        <Col lg="2" className="hideNavbar">
                            <Sidenav />
                        </Col>
                    )}
                    <Col className="header">
                        <Container fluid>
                            <Row className="pt-1 sub-header align-items-center justify-content-between">
                                <Col md="10" sm="10" xs="10" className="py-2 d-flex align-items-center">
                                    <button style={{ border: "none" }} className="hideNavbar1" onClick={handleShow}><img src={menu} width={20} /></button>
                                    <button style={{ border: "none" }} className="hide_Navbar" onClick={() => setShow(true)}><img src={menu} width={20} /></button>
                                    <h5 className="ms-4 mt-2">Cattle Details / Registration</h5>
                                </Col>
                                <Col md="1" sm="1" xs="10" className="py-2 d-flex align-items-center text-end ms-auto">
                                    <BackBtn style={{ border: "none" }} className="hideNavbar1" lable="Back" onClick={() => { navigate('/') }} />
                                </Col>
                                <Col md="1" sm="1" xs="2" className="text-end profile_dropdown">
                                    <img src={profile} width={40} />
                                    <div className="dropdpwn_Open">
                                        <Row className="text-center align-items-center">
                                            <Col xs="12" className="menuHover mb-3">
                                                <Row >
                                                    <Col className="" xs="3">
                                                        <span className=""><MdOutlineVpnKey size={25} />
                                                        </span>
                                                    </Col>
                                                    <Col className="text-start" xs="9">
                                                        Change Password
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col xs="12" className="menuHover" onClick={logOut}>
                                                <Row>
                                                    <Col className="" xs="3">
                                                        <span className=""><GoSignOut size={25} />
                                                        </span>
                                                    </Col>
                                                    <Col className="text-start" xs="9">
                                                        Sign Out
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="sub-header1 align-items-center justify-content-between">
                                <Col className="d-flex align-items-center justify-content-start">
                                    <div className="ms-2">
                                        <img src={breeding} width={40} />
                                    </div>
                                    <div className="ms-4">
                                        <h5 className="mb-0 mt-1">Cattle Details</h5>
                                        <p className="mb-1 mt-1">File all the information about animal and its purchase, parent details</p>
                                    </div>
                                </Col>
                                <Col xxl="2" lg="3" md="4" sm="5">
                                    <Input label="Enter Animal Tagld" value={tagidFind} onChange={e => setTagIdFind(e.target.value)} />
                                </Col>
                                <Col xxl="1" lg="2">
                                    <button className="btn-blue-Animal" onClick={() => SearchTagidFn()}>Search</button>
                                </Col>
                                <Col xxl="1" lg="2"><button className="btn-perot" onClick={AddAi}>Add</button></Col>
                                <Col xxl="1" lg="2"><button className="btn-blue-Animal" onClick={disposalHandle}>Disposal</button></Col>
                                {/* <Col md="1" sm="1" xs="1">
                                    <button className="btn-blue-Animal" onClick={() => refreshHandler()}>Refresh</button>
                                </Col> */}
                            </Row>

                            <Row>
                                <Col xs="4" className="">
                                    <RadioGroup row aria-labelledby="demo-row-radio-buttons-group-label" value={selectArea} name="row-radio-buttons-group">
                                        <FormControlLabel value="Herdwise" control={<Radio className="p-1" />} onChange={e => setSelectArea(e.target.value)} label="Herd" className="mt-1 me-2" />
                                        <FormControlLabel value="Districtwise" control={<Radio className="p-1" />} onChange={e => setSelectArea(e.target.value)} label="District" className="mt-1 me-2" />
                                        <FormControlLabel value="Categorywise" control={<Radio className="p-1" />} onChange={e => setSelectArea(e.target.value)} label="Category" className="mt-1" />
                                    </RadioGroup>
                                </Col>
                                {
                                    mainGraph === true ? <Col xs="8" className="justify-content-end">
                                        <RadioGroup row aria-labelledby="demo-row-radio-buttons-group-label" value={selectSpecies} name="row-radio-buttons-group">
                                            <FormControlLabel value="Cow" control={<Radio className="p-1" />} onChange={e => handleSpeciesChange(e)} label="Cow" className="mt-1 me-2 ms-auto" />
                                            <FormControlLabel value="Buffalo" control={<Radio className="p-1" />} onChange={e => handleSpeciesChange(e)} label="Buffalo" className="mt-1 me-2" />
                                            <FormControlLabel value="Both" control={<Radio className="p-1" />} onChange={e => handleSpeciesChange(e)} label="Both" className="mt-1 me-2" />
                                        </RadioGroup>
                                    </Col> :
                                        buttonShow &&
                                        <>
                                            <Col lg="2" sm="3" xl="1" className="ms-auto"><button className="btn-blue mt-1" onClick={generalBtn}>General</button></Col>
                                            <Col lg="2" sm="3" xl="1"><button className="btn-yellow mt-1" onClick={lifeTimeBtn}>Life-Time Details</button></Col>
                                            <Col lg="2" sm="3" xl="1"><button className="btn-perot mt-1" onClick={lactationBtn}>Lactation Profile</button></Col>
                                            <Col lg="2" sm="3" xl="1"><button className="btn-red mt-1" onClick={parentBtn}>Parent Details</button></Col>
                                        </>
                                }
                            </Row>

                            <Row className="align-items-start justify-content-start">
                                {
                                    selectArea == "Herdwise" &&
                                    <Col lg="3" md="3" sm="3" xs="4" xl="3" xxl="2" className="animal-tree text-center mt-1 pt-3" >
                                        {animaltree.map(e => {
                                            if (isExpandable1 == false) {
                                                return (
                                                    <Row className="mt-1 text-start animal-data align-items-center" onClick={() => getIdFn(e)}>
                                                        <Col xs="1" className="ms-2"><img src={cow} width="20" /></Col>
                                                        <Col className="ms-4"><p>{e.herdname}</p></Col>
                                                    </Row>
                                                )
                                            }
                                            else {
                                                return (
                                                    <>
                                                        <Row className="mt-1 text-start animal-data align-items-center" onClick={() => getIdFn(e)}>
                                                            {id1 == e.herdid && isExpandable == false ?
                                                                <Col xs="1" className="ms-3">-</Col>
                                                                :
                                                                <Col xs="1" className="ms-3">+</Col>
                                                            }
                                                            <Col className="ms-0"><p>{e.herdname}</p></Col>
                                                        </Row>
                                                        {id1 == e.herdid ?
                                                            (lotList).map(data =>
                                                                <>
                                                                    <Row className="mt-1 ms-2 text-start animal-data align-items-center" onClick={() => getIdFn1(data)}>
                                                                        {id2 == data.lotid && isExpandable2 == false ?
                                                                            <Col xs="1" className="d-flex ms-4">-<img src={farmer1} className="ms-1" /></Col>
                                                                            :
                                                                            <Col xs="1" className="d-flex ms-4">+<img src={farmer1} className="ms-1" /></Col>
                                                                        }
                                                                        <Col className="ms-1"><p className="ms-3">{data.lotname ? data.lotname : data.name}</p></Col>
                                                                    </Row>
                                                                    {id2 === data.lotid ?
                                                                        (farmerList).map(e1 =>
                                                                            <>
                                                                                <Row className="mt-1 ms-4 text-start animal-data align-items-center" onClick={() => getIdFn2(e1)}>
                                                                                    {id3 == e1.farmerid && isExpandable3 == false ?
                                                                                        < Col xs="2" className="d-flex ms-4">-<img src={farmer2} className="ms-1" /></Col>
                                                                                        :
                                                                                        <Col xs="2" className="d-flex ms-4">+<img src={farmer2} className="ms-1" /></Col>
                                                                                    }
                                                                                    <Col className="ms-1"><p className="ms-1">{e1.Farmer}</p></Col>
                                                                                </Row >
                                                                                {id3 == e1.farmerid ?
                                                                                    (animalDataList).map(e2 =>
                                                                                        <>
                                                                                            <Row className="mt-1 ms-5 text-start animal-data align-items-center flex-nowrap animalIcon" onClick={() => getDatils(e2)}>
                                                                                                {isExpandable4 == false ?
                                                                                                    <>
                                                                                                        <Col xs="1" className="ms-5"><img src={face1} width="16" /></Col>
                                                                                                        <Col className="ms-1"><p style={{ fontSize: "10px" }}>{e2.Animal}</p></Col>
                                                                                                    </>
                                                                                                    : false}
                                                                                            </Row>
                                                                                        </>
                                                                                    )
                                                                                    :
                                                                                    <></>
                                                                                }
                                                                            </>
                                                                        )
                                                                        :
                                                                        id2 === data.id ?
                                                                            farmerList.map(e2 =>
                                                                                <>
                                                                                    <Row className="mt-1 ms-5 text-start animal-data align-items-center flex-nowrap animalIcon" onClick={() => getDatils(e2)}>
                                                                                        {isExpandable4 === false ?
                                                                                            <>
                                                                                                <Col xs="1" className="ms-5"><img src={face1} width="16" /></Col>
                                                                                                <Col className="ms-1"><p style={{ fontSize: "10px" }}>{e2.Animal}</p></Col>
                                                                                            </>
                                                                                            : false}
                                                                                    </Row>
                                                                                </>
                                                                            )
                                                                            : <></>
                                                                    }
                                                                </>
                                                            )
                                                            :
                                                            <></>
                                                        }
                                                    </>
                                                )
                                            }
                                        }
                                        )}
                                    </Col>
                                }

                                {main_modal && (
                                    <Col lg="9" md="9" sm="9" xs="8" xl="9" xxl="10">
                                        <Row>
                                            <Col xl="6" className="text-center mt-2">
                                                <div className="blue_bg chartBorder pt-2" id="chart">
                                                    <p style={{ fontSize: "14px" }}>Animals by Species</p>
                                                    {/* <ResponsiveContainer aspect={3}>
                                                        <BarChart data={Barcharts1} margin={{ right: 50, left: 50, top: 0 }}>
                                                            <CartesianGrid />
                                                            <XAxis dataKey="Status" style={{ fontSize: "13px" }} />
                                                            <YAxis label="" />
                                                            <Tooltip contentStyle={{ fontSize: "14px", backgroundColor: "white" }} cursor={{ fill: 'transparent' }} />
                                                            <Bar dataKey="Animal" fill="#8FA9F5">
                                                                {Barcharts1.map((entry, index) => (
                                                                    <Cell key={`cell-${index}`} fill={barColors[index % 20]} />
                                                                ))}
                                                            </Bar>
                                                        </BarChart>
                                                    </ResponsiveContainer> */}
                                                    {/* <ReactApexChart options={{
                                                        chart: {
                                                            height: 350,
                                                            type: 'bar',
                                                        },
                                                        colors: colors,
                                                        plotOptions: {
                                                            bar: {
                                                                columnWidth: '45%',
                                                                distributed: true,
                                                            }
                                                        },
                                                        dataLabels: {
                                                            enabled: true,
                                                            style: {
                                                                fontSize: '12px',
                                                                colors: "#FFFFFF",
                                                            },
                                                        },
                                                        legend: {
                                                            show: true
                                                        },
                                                        xaxis: {
                                                            categories: Barcharts1.map((e) => `${e.Species}`),
                                                            labels: {
                                                                style: {
                                                                    colors: colors,
                                                                    fontSize: '12px'
                                                                }
                                                            }
                                                        }
                                                    }}
                                                        series={[
                                                            {
                                                                name: "Animal",
                                                                data: Barcharts1.map((entry) => entry.Animal),
                                                                colors: colors,
                                                            },
                                                        ]} type="bar" width={500} /> */}
                                                    <ReactApexChart
                                                        options={{
                                                            chart: {
                                                                type: 'bar',
                                                                height: 350,
                                                                stacked: true,
                                                                // stackType: '100%',
                                                            },
                                                            plotOptions: {
                                                                bar: {
                                                                    horizontal: false,
                                                                    columnWidth: '35%',
                                                                    endingShape: 'rounded',
                                                                },
                                                            },
                                                            dataLabels: {
                                                                enabled: true,
                                                            },
                                                            stroke: {
                                                                show: true,
                                                                width: 2,
                                                                colors: ['transparent'],
                                                            },
                                                            xaxis: {
                                                                categories: ['Heifer', 'Calf', 'Adult'],
                                                            },
                                                            yaxis: {
                                                                title: {
                                                                    text: '% AI',
                                                                },
                                                                max: Math.max(
                                                                    ...filteredData1?.map((e) => e.Animal || 0)
                                                                ),
                                                            },
                                                            fill: {
                                                                opacity: 1,
                                                            },
                                                            tooltip: {
                                                                y: {
                                                                    formatter: function (val) {
                                                                        return val;
                                                                    },
                                                                },
                                                            },
                                                            colors: ['#21aaff', '#ff0000']
                                                        }}
                                                        series={[
                                                            {
                                                                name: 'Cow',
                                                                data: [
                                                                    filteredData1.find((e) => e.Species === 'Cow' && e.Status === 'Heifer')?.Animal || 0,
                                                                    filteredData1.find((e) => e.Species === 'Cow' && e.Status === 'Calf')?.Animal || 0,
                                                                    filteredData1.find((e) => e.Species === 'Cow' && e.Status === 'Adult')?.Animal || 0,
                                                                ],
                                                            },
                                                            {
                                                                name: 'Buffalo',
                                                                data: [
                                                                    filteredData1.find((e) => e.Species === 'Buffalo' && e.Status === 'Heifer')?.Animal || 0,
                                                                    filteredData1.find((e) => e.Species === 'Buffalo' && e.Status === 'Calf')?.Animal || 0,
                                                                    filteredData1.find((e) => e.Species === 'Buffalo' && e.Status === 'Adult')?.Animal || 0,
                                                                ],
                                                            },
                                                        ]}
                                                        type="bar"
                                                        height={350}
                                                    />
                                                </div>
                                            </Col>

                                            <Col xl="6" className="text-center mt-2">
                                                <div className="pt-2 blue_bg chartBorder p-3" id="chart">
                                                    <p style={{ fontSize: '14px' }}>Status Grouping</p>
                                                    <ReactApexChart
                                                        options={{
                                                            chart: {
                                                                type: 'pie',
                                                                height: 250,
                                                            },
                                                            responsive: [
                                                                {
                                                                    breakpoint: 480,
                                                                    options: {
                                                                        chart: {},
                                                                        legend: {
                                                                            position: 'bottom',
                                                                        },
                                                                    },
                                                                },
                                                            ],
                                                            plotOptions: {
                                                                bar: {
                                                                    dataLabels: {
                                                                        position: 'top',
                                                                        offsetY: -100,
                                                                    },
                                                                },
                                                            },
                                                            labels: selectPiechart.map((e) => `${e.Species} : ${e.Animal}-${e.Status}`),
                                                        }}
                                                        series={selectPiechart.map((e) => e.Animal)}
                                                        type="pie"
                                                        width={500}
                                                    />
                                                    <div className="custom-label d-flex justify-content-center" style={{ cursor: 'pointer' }}>
                                                        <p>
                                                            <input
                                                                type="radio"
                                                                name="piechart"
                                                                value="Cow"
                                                                checked={selectSpecies == 'Both' || selectSpecies == 'Cow' || selectedSpecies == 'Cow'}
                                                                onChange={() => setSelectedSpecies('Cow')}
                                                            />{' '}
                                                            Cow
                                                        </p>
                                                        <p className="ps-3">
                                                            <input
                                                                type="radio"
                                                                name="piechart"
                                                                value="Buffalo"
                                                                checked={selectSpecies == 'Both' || selectSpecies == 'Buffalo' || selectedSpecies === 'Buffalo'}
                                                                onChange={() => setSelectedSpecies('Buffalo')}
                                                            />{' '}
                                                            Buffalo
                                                        </p>
                                                    </div>
                                                </div>
                                            </Col>

                                        </Row>
                                        <Row>
                                            <Col xl="6" className="text-center mt-2">
                                                <div className="blue_bg pt-2 chartBorder" id="chart">
                                                    <p style={{ fontSize: "14px" }}>Animals by Milking and Dry</p>
                                                    {/* <ResponsiveContainer aspect={3} id="chart">
                                                        <BarChart data={Barcharts2} margin={{ right: 50, left: 50, top: 0 }} >
                                                            <CartesianGrid />
                                                            <XAxis dataKey="Status" style={{ fontSize: "14px" }} />
                                                            <YAxis />
                                                            <Tooltip contentStyle={{ fontSize: "14px", backgroundColor: "white" }} cursor={{ fill: 'transparent' }} />
                                                            <Bar dataKey="Animal" fill="#8FA9F5">
                                                                {Barcharts2.map((entry, index) => (
                                                                    <Cell key={`cell-${index}`} fill={barColors[index % 20]} />
                                                                ))}
                                                            </Bar>
                                                        </BarChart>
                                                    </ResponsiveContainer> */}
                                                    {/* <ReactApexChart options={{
                                                        chart: {
                                                            type: 'bar',
                                                            height: 250,
                                                        },
                                                        labels: Barcharts2.map((e) => `${e.Status}`),
                                                        plotOptions: {
                                                            bar: {
                                                                dataLabels: {
                                                                    position: 'top',
                                                                    offsetY: -100,
                                                                },
                                                            },
                                                        },
                                                    }} series={[
                                                        {
                                                            name: "Animal",
                                                            data: Barcharts2.map((entry) => entry.Animal),
                                                            colors: Barcharts2.map(() => getRandomColor())
                                                        },
                                                    ]} type="bar" width={500} /> */}
                                                    <ReactApexChart
                                                        options={{
                                                            chart: {
                                                                type: 'bar',
                                                                height: 350,
                                                                stacked: true,
                                                                // stackType: '100%',
                                                            },
                                                            plotOptions: {
                                                                bar: {
                                                                    horizontal: false,
                                                                    columnWidth: '35%',
                                                                    endingShape: 'rounded',
                                                                },
                                                            },
                                                            dataLabels: {
                                                                enabled: true,
                                                            },
                                                            stroke: {
                                                                show: true,
                                                                width: 2,
                                                                colors: ['transparent'],
                                                            },
                                                            xaxis: {
                                                                categories: ['Milking', 'Dry'],
                                                            },
                                                            yaxis: {
                                                                title: {
                                                                    text: '% AI',
                                                                },
                                                                max: Math.max(
                                                                    ...filteredData3.map((e) => e.Animal + 100 || 0)
                                                                )
                                                            },
                                                            fill: {
                                                                opacity: 1,
                                                            },
                                                            tooltip: {
                                                                y: {
                                                                    formatter: function (val) {
                                                                        return val;
                                                                    },
                                                                },
                                                            },
                                                            colors: ['#21aaff', '#ff0000']
                                                        }}
                                                        series={[
                                                            {
                                                                name: 'Cow',
                                                                data: [
                                                                    filteredData3.find((e) => e.Species === 'Cow' && e.Status === 'Milking')?.Animal || 0,
                                                                    filteredData3.find((e) => e.Species === 'Cow' && e.Status === 'Dry')?.Animal || 0,
                                                                ],
                                                            },
                                                            {
                                                                name: 'Buffalo',
                                                                data: [
                                                                    filteredData3.find((e) => e.Species === 'Buffalo' && e.Status === 'Milking')?.Animal || 0,
                                                                    filteredData3.find((e) => e.Species === 'Buffalo' && e.Status === 'Dry')?.Animal || 0,
                                                                ],
                                                            },
                                                        ]}
                                                        type="bar"
                                                        height={350}
                                                    />
                                                    {/* <ReactApexChart options={{
                                                        chart: {
                                                            height: 350,
                                                            type: 'bar',
                                                        },
                                                        colors: colors,
                                                        plotOptions: {
                                                            bar: {
                                                                columnWidth: '45%',
                                                                distributed: true,
                                                            }
                                                        },
                                                        dataLabels: {
                                                            enabled: true,
                                                            style: {
                                                                fontSize: '12px',
                                                                colors: "#FFFFFF",
                                                            },
                                                        },
                                                        legend: {
                                                            show: true
                                                        },
                                                        xaxis: {
                                                            categories: Barcharts2.map((e) => `${e.Status}`),
                                                            labels: {
                                                                style: {
                                                                    colors: colors,
                                                                    fontSize: '12px'
                                                                }
                                                            }
                                                        }
                                                    }}
                                                        series={[
                                                            {
                                                                name: "Animal",
                                                                data: Barcharts2.map((entry) => entry.Animal),
                                                                colors: colors,
                                                            },
                                                        ]} type="bar" width={500} /> */}
                                                </div>
                                            </Col>
                                            <Col xl="6" className="text-center mt-2">
                                                <div className="blue_bg pt-2 chartBorder" id="chart">
                                                    <p style={{ fontSize: "14px" }}>Animals by Breeding Status</p>
                                                    {/* <ResponsiveContainer aspect={3}>
                                                        <BarChart data={Barcharts3} margin={{ right: 50, left: 50, top: 0 }}>
                                                            <CartesianGrid />
                                                            <XAxis dataKey="Breeding Status" style={{ fontSize: "14px" }} />
                                                            <YAxis />
                                                            <Tooltip contentStyle={{ fontSize: "14px", backgroundColor: "white" }} cursor={{ fill: 'transparent' }} />
                                                            <Bar dataKey="Animal" fill="#8FA9F5">
                                                                {Barcharts3.map((entry, index) => (
                                                                    <Cell key={`cell-${index}`} fill={barColors[index % 20]} />
                                                                ))}
                                                            </Bar>
                                                        </BarChart>
                                                    </ResponsiveContainer> */}
                                                    <ReactApexChart
                                                        options={{
                                                            chart: {
                                                                type: 'bar',
                                                                height: 250,
                                                                stacked: true,  // Enable stacking
                                                            },
                                                            labels: filteredData4.map((e) => `${e["Breeding Status"]}`),
                                                            plotOptions: {
                                                                bar: {
                                                                    dataLabels: {
                                                                        position: 'top',
                                                                        offsetY: -10,
                                                                    },
                                                                },
                                                            },
                                                            colors: ['#21aaff', '#ff0000']
                                                        }}
                                                        series={[
                                                            {
                                                                name: 'Cow',
                                                                data: filteredData4.filter((entry) => entry.Species === 'Cow').map((entry) => entry.Animal),

                                                            },
                                                            {
                                                                name: 'Buffalo',
                                                                data: filteredData4.filter((entry) => entry.Species === 'Buffalo').map((entry) => entry.Animal),

                                                            },
                                                        ]}
                                                        type="bar"
                                                        width={500}
                                                    />


                                                    {/* <ReactApexChart options={{
                                                        chart: {
                                                            height: 350,
                                                            type: 'bar',
                                                        },
                                                        colors: colors,
                                                        plotOptions: {
                                                            bar: {
                                                                columnWidth: '45%',
                                                                distributed: true,
                                                            }
                                                        },
                                                        dataLabels: {
                                                            enabled: true,
                                                            style: {
                                                                fontSize: '12px',
                                                                colors: "#FFFFFF",
                                                            },
                                                        },
                                                        legend: {
                                                            show: true
                                                        },
                                                        xaxis: {
                                                            categories: Barcharts3.map((e) => `${e["Breeding Status"]}`),
                                                            labels: {
                                                                style: {
                                                                    colors: colors,
                                                                    fontSize: '12px'
                                                                }
                                                            }
                                                        }
                                                    }}
                                                        series={[
                                                            {
                                                                name: "Animal",
                                                                data: Barcharts3.map((entry) => entry.Animal),
                                                                colors: colors,
                                                            },
                                                        ]} type="bar" width={500} /> */}
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col className="mt-3 mb-3">
                                                <div className="blue_bg p-3">
                                                    <p>List Of Animals</p>
                                                    <Table columns={animalcol} data={animalList} paginationPerPage />
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                )}

                                {sub_modal && (
                                    <Col lg="9" md="9" sm="9" xs="8" xl="9" xxl="10">
                                        {general && (
                                            <Row className="mt-2 ms-1" id="cattleReg">
                                                <Col lg="2" md="4" sm="12" >
                                                    <Row className="align-items-start animal_dark_bg animal_dark_bg_text ms-0">
                                                        <Col sm="4" xs="5" xxl="3" className="text-start my-2"><p>Tag&nbsp;Id&nbsp;No&nbsp;:</p></Col>
                                                        <Col sm="8" xs="7" xxl="8" className="my-2 subText"><p className="ms-3">{tagid}</p></Col>
                                                    </Row>
                                                    <Row className="mt-1 align-items-start animal_dark_bg animal_dark_bg_text ms-0">
                                                        <Col sm="4" xs="5" xxl="3" className="text-start my-2"><p>Center&nbsp;:</p></Col>
                                                        <Col sm="8" xs="7" xxl="9" className="my-2 subText"><p className="ms-1">{center}</p></Col>
                                                    </Row>
                                                    <Row className="mt-1 align-items-start animal_dark_bg animal_dark_bg_text ms-0">
                                                        <Col sm="4" xs="5" xxl="3" className="text-start my-2"><p>DCS&nbsp;:</p></Col>
                                                        <Col sm="8" xs="7" xxl="9" className="my-2 subText"><p className="ms-1">{dcs}</p></Col>
                                                    </Row>
                                                    <Row className="mt-1 align-items-start animal_dark_bg animal_dark_bg_text ms-0">
                                                        <Col sm="4" xs="5" xxl="3" className="text-start my-2"><p>Route&nbsp;:</p></Col>
                                                        <Col sm="8" xs="7" xxl="9" className="my-2 subText"><p className="ms-1">{router}</p></Col>
                                                    </Row>
                                                    <Row className="mt-1 align-items-start animal_dark_bg animal_dark_bg_text ms-0">
                                                        <Col sm="4" xs="5" xxl="3" className="text-start my-2"><p>Farmer&nbsp;:</p></Col>
                                                        <Col sm="8" xs="7" xxl="9" className="my-2 subText "><p className="ms-1">{farmer}</p></Col>
                                                    </Row>
                                                    <Row className="mt-1 align-items-start animal_dark_bg animal_dark_bg_text ms-0">
                                                        <Col sm="4" xs="5" xxl="3" className="text-start my-2"><p>Species&nbsp;:</p></Col>
                                                        <Col sm="8" xs="7" xxl="9" className="my-2 subText"><p className="ms-1">{species}</p></Col>
                                                    </Row>
                                                    <Row className="mt-1 align-items-start animal_dark_bg animal_dark_bg_text ms-0">
                                                        <Col sm="4" xs="5" xxl="3" className="text-start my-2"><p>Breed&nbsp;:</p></Col>
                                                        <Col sm="8" xs="7" xxl="9" className="my-2 subText"><p className="ms-1">{breed}</p></Col>
                                                    </Row>
                                                    <Row className="mt-1 align-items-start animal_dark_bg animal_dark_bg_text ms-0">
                                                        <Col xl="5" md="4" xs="5" xxl="5" className="text-start my-2"><p>Breeding&nbsp;Status&nbsp;:</p></Col>
                                                        <Col xl="7" md="8" xs="8" xxl="7" className="my-2 subText"><p className="ms-1">{b_status}</p></Col>
                                                    </Row>
                                                    <Row className="mt-1 align-items-start animal_dark_bg animal_dark_bg_text ms-0">
                                                        <Col sm="4" xs="5" xxl="3" className="text-start my-2"><p>Age&nbsp;:</p></Col>
                                                        <Col sm="8" xs="7" xxl="9" className="my-2 subText"><p className="ms-1">{age}</p></Col>
                                                    </Row>
                                                    <Row className="mt-2 text-center align-items-start animal_dark_bg_text justify-content-center">
                                                        <Col className="mt-1">
                                                            <div className="animal_dark_bg py-1 d-flex justify-content-center">
                                                                <p>Status&nbsp;:</p>
                                                                <p className="ms-1">{status}</p>
                                                            </div>
                                                            <div className="animal_border">
                                                                <img src={statusImage} width="100%" height="65px" />
                                                            </div>
                                                        </Col>
                                                        <Col className="mt-1">
                                                            <div className="animal_dark_bg py-1">
                                                                <p>Cow</p>
                                                            </div>
                                                            <div className="py-2 animal_border">
                                                                <img width="70px" alt="Photo" />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        {/* <Col><button className="btn-perot mt-3" onClick={AddAi}>Add</button></Col> */}
                                                        <Col>
                                                            <button className="btn-blue-Animal mt-3" onClick={() => refreshHandler()}>Refresh</button>
                                                        </Col>
                                                        <Col><button className="btn-blue mt-3" onClick={EditAi}>Edit</button></Col>
                                                        <Col><button className="btn-red mt-3" onClick={AddAi}>Remove</button></Col>
                                                    </Row>
                                                    <Row className="justify-content-center">
                                                        <Col className="mt-2" md="10"><LightBtn lable="Go To Breedng" onClick={breedingPageFn} /></Col>
                                                    </Row>
                                                    <Row className="justify-content-center">
                                                        <Col className="mt-2" md="10"><LightBtn lable="Go To Production" onClick={productionHandler} /></Col>
                                                    </Row>
                                                    <Row className="justify-content-center">
                                                        <Col className="mt-2" md="10"><LightBtn lable="Go To Treatment" onClick={treatmenthandle} /></Col>
                                                    </Row>
                                                    {/* <Row className="justify-content-center">
                                                        <Col className="mt-2" md="10"><LightBtn lable="Disposal" onClick={disposalHandle} /></Col>
                                                    </Row> */}
                                                </Col>
                                                <Col lg="10" md="8" sm="12">
                                                    <Accordion defaultActiveKey={['0']} flush>
                                                        <Accordion.Item eventKey="0">
                                                            <Accordion.Header className="Acc_Header"><img src={cattle1} width="25" className="me-3" />Increase current</Accordion.Header>
                                                            <Accordion.Body className="px-0">
                                                                <Row className="mainChart pe-0">
                                                                    <Col >
                                                                        <ResponsiveContainer aspect={4} id="chartLine">
                                                                            <LineChart data={Linecharts} margin={{ right: 30 }} >
                                                                                <CartesianGrid strokeDasharray="5 5" />
                                                                                <XAxis
                                                                                    className="mt-2"
                                                                                    type="number"
                                                                                    dataKey="Day"
                                                                                    interval={4}
                                                                                    textAnchor="center"
                                                                                    domain={[0, Linecharts.length]}
                                                                                    tickMargin={25}
                                                                                    tickCount={Linecharts.length}
                                                                                    label={{ value: `Days In Milk (${current4})`, dy: 20, position: 'insideBottom', fill: '#666' }}
                                                                                    tick={({ x, y, payload }) => (
                                                                                        <g transform={`translate(${x},${y}) rotate(${angle})`}>
                                                                                            <text className="chartText" dy={4} textAnchor="end" fill="#666">
                                                                                                {payload.value}
                                                                                            </text>
                                                                                        </g>
                                                                                    )}
                                                                                />
                                                                                <YAxis label={{ value: `Milk (kg) (${current8})`, dy: -80, angle: -90, position: 'insideBottom', fill: '#666' }} />
                                                                                <Tooltip contentStyle={{ fontSize: "8px" }} />
                                                                                <Legend />
                                                                                <Line dataKey="Standard Milk" type="monotone" stroke="#82ca9d" activeDot={{ r: 5 }} dot={false} />
                                                                                <Line dataKey="Milk(kg)" type="monotone" stroke="#E77C71" activeDot={{ r: 5 }} dot={false} />
                                                                                <Line dataKey="MilkLBL" type="monotone" stroke="#8FA9F5" activeDot={{ r: 5 }} dot={false} />
                                                                                <Line dataKey="Exp Milk" type="monotone" stroke="#F9D57A" activeDot={{ r: 5 }} dot={false} />
                                                                                <Line dataKey="ExpMilkLBL" type="monotone" stroke="#E77C71" activeDot={{ r: 5 }} dot={false} />
                                                                                <Line dataKey="A.I" type="linear" stroke="#89DEBF" activeDot={{ r: 5 }} dot={false} />
                                                                                <Line dataKey="AITip" type="monotone" stroke="#E77C71" activeDot={{ r: 5 }} dot={false} />
                                                                            </LineChart>
                                                                        </ResponsiveContainer>
                                                                    </Col>
                                                                </Row>
                                                                <Row className="align-items-center justify-content-center px-3">
                                                                    <Col lg="4" md="11" className="d-flex align-items-center">
                                                                        <input type="checkbox" id="vehicle1" value="Standard" className="me-2" />
                                                                        <label className="chartlable mb-0" for="vehicle1">Standard</label>
                                                                        <input type="checkbox" id="vehicle1" value="Standard" className="ms-3 me-2" />
                                                                        <label className="chartlable mb-0" for="vehicle1">Breed</label>
                                                                        <input type="checkbox" id="vehicle1" value="Standard" className="ms-3 me-2" />
                                                                        <label className="chartlable mb-0" for="vehicle1">Herd</label>
                                                                    </Col>
                                                                    <Col lg="2" md="4" xxl="1" className="ms-auto"><button className="btn-perot mt-2">Week</button></Col>
                                                                    <Col lg="2" md="4" xxl="1"><button className="btn-blue  mt-2">Month</button></Col>
                                                                    <Col lg="2" md="4" xxl="1"><button className="btn-red  mt-2">All</button></Col>
                                                                </Row>
                                                                <hr className="mb-0" />
                                                                <Row className="increase_text text-start align-items-center justify-content-start">
                                                                    <Col xl="4" md="4"><p className="mt-3 mb-0 ps-2">{current1}: <b>{current2}</b></p></Col>
                                                                    <Col xl="4" md="4"><p className="mt-3 mb-0 ps-2">{current3}: <b>{current4}</b></p></Col>
                                                                    <Col xl="4" md="4"><p className="mt-3 mb-0 ps-2">{current5}: <b>{current6}</b></p></Col>
                                                                </Row>
                                                                <Row className="increase_text text-start align-items-center justify-content-start">
                                                                    <Col xl="4" md="4"><p className="mt-2 mb-0 ps-2">{current7}: <b>{current8}</b></p></Col>
                                                                    <Col xl="4" md="4"><p className="mt-2 mb-0 ps-2">{current9}: <b>{current10}</b></p></Col>
                                                                    <Col xl="4" md="4"><p className="mt-2 mb-0 ps-2">{current11}: <b>{current12}</b></p></Col>
                                                                </Row>
                                                                <Row className="increase_text text-start align-items-center justify-content-start">
                                                                    <Col xl="4" md="4"><p className="mt-2 mb-0 ps-2">{current13} <b>{current14}</b></p></Col>
                                                                    <Col xl="4" md="4"><p className="mt-2 mb-0 ps-2">{current15} <b>{current16}</b></p></Col>
                                                                    <Col xl="4" md="4"><p className="mt-2 mb-0 ps-2">{current17} <b>{current18}</b></p></Col>
                                                                </Row>
                                                            </Accordion.Body>
                                                        </Accordion.Item>

                                                        <Accordion.Item eventKey="1">
                                                            <Accordion.Header><img src={cattle2} width="25" className="me-3" />Other Details</Accordion.Header>
                                                            <Accordion.Body>
                                                                <h4>Other Details</h4>
                                                            </Accordion.Body>
                                                        </Accordion.Item>

                                                        <Accordion.Item eventKey="2">
                                                            <Accordion.Header><img src={cattle3} width="25" className="me-3" />Reproduction Details</Accordion.Header>
                                                            <Accordion.Body>
                                                                <Row>
                                                                    <Col xl="4">
                                                                        <select className="resproducion_dropdown ps-3" value={parityno} onChange={(e) => ParitySelectFn(e.target.value)}>
                                                                            <option>Parity</option>
                                                                            {parityId.map((e) =>
                                                                                <option value={e.parity}>{e.parity}</option>
                                                                            )}
                                                                        </select>
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col xl="2" className="mt-3"><LightBtn lable="Details" onClick={() => { setDetails(true); setSummary(false) }} /></Col>
                                                                    <Col xl="2" className="mt-3"><LightBtn lable="Summary" onClick={() => { setDetails(false); setSummary(true) }} /></Col>
                                                                </Row>
                                                                {details && (
                                                                    <>
                                                                        <Row>
                                                                            <Col className="mt-3">
                                                                                <Table columns={paritycol} data={parityList} />
                                                                            </Col>
                                                                        </Row>
                                                                    </>
                                                                )}
                                                                {summary && (
                                                                    <>
                                                                        <Row>
                                                                            <Col><p className="mt-3 mb-1">Label</p></Col>
                                                                            <Col><p className="mt-3 mb-1">Value</p></Col>
                                                                        </Row>
                                                                        <hr className="m-0" />
                                                                        {SummeryList.map((e) =>
                                                                            <>
                                                                                <Row className="mt-1">
                                                                                    <Col><p className="mb-0">{e.Label}</p></Col>
                                                                                    <Col><p className="mb-0">{e.Value}</p></Col>
                                                                                </Row>
                                                                                <hr className="m-0" />
                                                                            </>
                                                                        )}
                                                                    </>
                                                                )}
                                                            </Accordion.Body>
                                                        </Accordion.Item>

                                                        <Accordion.Item eventKey="3">
                                                            <Accordion.Header><img src={cattle4} width="25" className="me-3" />Production Details</Accordion.Header>
                                                            <Accordion.Body>
                                                                <Row>
                                                                    <Col xl="4">
                                                                        <select className="resproducion_dropdown ps-3">
                                                                            <option>Loction No</option>
                                                                        </select>
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col xl="2" className="mt-3"><LightBtn lable="Type" /></Col>
                                                                    <Col xl="2" className="mt-3"><LightBtn lable="Value" /></Col>
                                                                </Row>
                                                            </Accordion.Body>
                                                        </Accordion.Item>

                                                        <Accordion.Item eventKey="4">
                                                            <Accordion.Header><img src={cattle5} width="25" className="me-3" />Event Status</Accordion.Header>
                                                            <Accordion.Body>
                                                                <Row className="text-center">
                                                                    <Col>
                                                                        <Table columns={columns} data={animallifeList} />
                                                                    </Col>
                                                                </Row>
                                                            </Accordion.Body>
                                                        </Accordion.Item>
                                                    </Accordion>
                                                </Col>
                                            </Row>
                                        )
                                        }

                                        {lifeTime && (
                                            <>
                                                <Row className="mt-3 ms-1">
                                                    <Col className="animal_dark_bg">
                                                        <h5 className="py-3  mb-0"><b style={{ fontSize: "14px" }}>Life-Time Details</b></h5>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col className="ms-3">
                                                        <div className="mt-3">
                                                            <input type="checkbox" id="vehicle1" className="me-2" value="reproduction"
                                                                checked={lifetimeDetails.includes('reproduction')}
                                                                onChange={() => handleCheckboxChange('reproduction')} />Reproduction
                                                        </div>
                                                        <div className="mt-3">
                                                            <input type="checkbox" id="vehicle1" className="me-2" value="production"
                                                                checked={lifetimeDetails.includes('production')}
                                                                onChange={() => handleCheckboxChange('production')} />Production
                                                        </div>
                                                        <div className="mt-3">
                                                            <input type="checkbox" id="vehicle1" className="me-2" value="deworming"
                                                                checked={lifetimeDetails.includes('deworming')}
                                                                onChange={() => handleCheckboxChange('deworming')} />Deworming
                                                        </div>
                                                        <div className="mt-3">
                                                            <input type="checkbox" id="vehicle1" className="me-2" value="vaccination"
                                                                checked={lifetimeDetails.includes('vaccination')}
                                                                onChange={() => handleCheckboxChange('vaccination')} />Vaccination
                                                        </div>
                                                        <div className="mt-3">
                                                            <input type="checkbox" id="vehicle1" className="me-2" value="treatment"
                                                                checked={lifetimeDetails.includes('treatment')}
                                                                onChange={() => handleCheckboxChange('treatment')} />Treatment
                                                        </div>
                                                    </Col>
                                                </Row>
                                                <Row className="ms-1">
                                                    <Col xl="1" className="mt-3"><button className="btn-blue mt-1" onClick={lifePreviewFn} >Preview</button></Col>
                                                </Row>
                                            </>
                                        )}

                                        {lactation && (
                                            <Row className="mt-3 ms-1">
                                                <Col>
                                                    <ResponsiveContainer aspect={3}>
                                                        <LineChart data={Linecharts} width={300} height={250} margin={{ right: 30 }}>
                                                            <CartesianGrid strokeDasharray="0" />
                                                            <XAxis dataKey="Day" />
                                                            <YAxis />
                                                            <Tooltip contentStyle={{ fontSize: "14px" }} />
                                                            <Legend />
                                                            <Line dataKey="Standard Milk" type="monotone" stroke="#82ca9d" activeDot={{ r: 5 }} />
                                                        </LineChart>
                                                    </ResponsiveContainer>
                                                </Col>
                                            </Row>
                                        )}

                                        {parent && (
                                            <>
                                                <Row className="ms-1 justify-content-center">
                                                    <Col xl="6">
                                                        <Row className="mt-3">
                                                            <Col className="animal_dark_bg text-center">
                                                                <h6 className="py-2 mb-0"><b>Sire</b></h6>
                                                            </Col>
                                                        </Row>
                                                        <Row className="blue_bg">
                                                            <Col className="mt-2">
                                                                <Dropdown label="Sire ID" menuList={menuitm} placeholder="Sire ID" />
                                                            </Col>
                                                            <Col className="mt-2">
                                                                <TextField label="Sire Name" id="standard-basic" fullWidth variant="standard" />
                                                            </Col>
                                                        </Row>
                                                        <Row className="blue_bg">
                                                            <Col className="mt-2">
                                                                <Dropdown label="Breed" menuList={menuitm} placeholder="Sire ID" />
                                                            </Col>
                                                            <Col className="mt-2">
                                                                <TextField label="Sire Index" id="standard-basic" fullWidth variant="standard" />
                                                            </Col>
                                                        </Row>
                                                        <Row className="blue_bg">
                                                            <Col xl="6" className="mt-2">
                                                                <sub className="mb-0 pb-0">Birth Date</sub>
                                                                <input type="date" className="dateSelect" />
                                                            </Col>
                                                        </Row>
                                                        <Row className="blue_bg align-items-end justify-content-center">
                                                            <Col xl="3" className="mt-2 text-end"><p className="mb-0 mt-2">EBV (Kg) :</p></Col>
                                                            <Col className="mt-2"><TextField label="Milk" id="standard-basic" fullWidth variant="standard" /></Col>
                                                            <Col className="mt-2"><TextField label="Fat" id="standard-basic" fullWidth variant="standard" /></Col>
                                                            <Col className="mt-2"><TextField label="Protein" id="standard-basic" fullWidth variant="standard" /></Col>
                                                            <Col className="mt-2"><TextField label="Real" id="standard-basic" fullWidth variant="standard" /></Col>
                                                        </Row>
                                                        <Row className="blue_bg pb-3 align-items-end justify-content-center">
                                                            <Col xl="3" className="mt-2 text-end"><p className="mb-0 mt-2">Type Traits :</p></Col>
                                                            <Col className="mt-2"><TextField label="Body" fullWidth variant="standard" /></Col>
                                                            <Col className="mt-2"><TextField label="Legs" fullWidth variant="standard" /></Col>
                                                            <Col className="mt-2"><TextField label="Udder" fullWidth variant="standard" /></Col>
                                                            <Col className="mt-2"><TextField label="Total" fullWidth variant="standard" /></Col>
                                                        </Row>
                                                    </Col>
                                                    <Col xl="6">
                                                        <Row className="mt-3 mx-1">
                                                            <Col className="animal_dark_bg text-center">
                                                                <h6 className="py-2 mb-0"><b>Paternal Sire</b></h6>
                                                            </Col>
                                                        </Row>
                                                        <Row className="blue_bg mx-1">
                                                            <Col className="mt-2">
                                                                <Dropdown label="Sire ID" menuList={menuitm} placeholder="Sire ID" />
                                                            </Col>
                                                            <Col className="mt-2">
                                                                <TextField label="Sire Name" id="standard-basic" fullWidth variant="standard" />
                                                            </Col>
                                                        </Row>
                                                        <Row className="blue_bg align-items-end mx-1">
                                                            <Col xl="6" className="mt-2">
                                                                <sub className="mb-0 pb-0">Birth Date</sub>
                                                                <input type="date" className="dateSelect" />
                                                            </Col>
                                                            <Col className="mt-2">
                                                                <TextField label="Sire Index" id="standard-basic" fullWidth variant="standard" />
                                                            </Col>
                                                        </Row>
                                                        <Row className="top_space blue_bg mx-1 align-items-end justify-content-center">
                                                            <Col xl="3" className="mt-2 text-end"><p className="mb-0 mt-2">EBV (Kg) :</p></Col>
                                                            <Col className="mt-2"><TextField label="Milk" id="standard-basic" fullWidth variant="standard" /></Col>
                                                            <Col className="mt-2"><TextField label="Fat" id="standard-basic" fullWidth variant="standard" /></Col>
                                                            <Col className="mt-2"><TextField label="Protein" id="standard-basic" fullWidth variant="standard" /></Col>
                                                            <Col className="mt-2"><TextField label="Real" id="standard-basic" fullWidth variant="standard" /></Col>
                                                        </Row>
                                                        <Row className="blue_bg pb-3 mx-1 align-items-end justify-content-center">
                                                            <Col xl="3" className="mt-2 text-end"><p className="mb-0 mt-2">Type Traits :</p></Col>
                                                            <Col className="mt-2"><TextField label="Body" fullWidth variant="standard" /></Col>
                                                            <Col className="mt-2"><TextField label="Legs" fullWidth variant="standard" /></Col>
                                                            <Col className="mt-2"><TextField label="Udder" fullWidth variant="standard" /></Col>
                                                            <Col className="mt-2"><TextField label="Total" fullWidth variant="standard" /></Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                                <Row className="ms-1 align-items-start justify-content-center">
                                                    <Col xl="6">
                                                        <Row className="mt-2">
                                                            <Col className="animal_dark_bg text-center">
                                                                <h6 className="py-2 mb-0"><b>Dam Sire</b></h6>
                                                            </Col>
                                                        </Row>
                                                        <Row className="blue_bg">
                                                            <Col className="mt-2">
                                                                <Dropdown label="Sire ID" menuList={menuitm} placeholder="Sire ID" />
                                                            </Col>
                                                            <Col className="mt-2">
                                                                <TextField label="Sire Name" id="standard-basic" fullWidth variant="standard" />
                                                            </Col>
                                                        </Row>
                                                        <Row className="blue_bg">
                                                            <Col className="mt-2">
                                                                <Dropdown label="Breed" menuList={menuitm} />
                                                            </Col>
                                                            <Col className="mt-2">
                                                                <TextField label="Sire Index" id="standard-basic" fullWidth variant="standard" />
                                                            </Col>
                                                        </Row>
                                                        <Row className="blue_bg">
                                                            <Col xl="6" className="mt-2">
                                                                <sub className="mb-0 pb-0">Birth Date</sub>
                                                                <input type="date" className="dateSelect" />
                                                            </Col>
                                                        </Row>
                                                        <Row className="blue_bg align-items-end justify-content-center">
                                                            <Col xl="3" className="mt-2 text-end"><p className="mb-0 mt-2">EBV (Kg) :</p></Col>
                                                            <Col className="mt-2"><TextField label="Milk" id="standard-basic" fullWidth variant="standard" /></Col>
                                                            <Col className="mt-2"><TextField label="Fat" id="standard-basic" fullWidth variant="standard" /></Col>
                                                            <Col className="mt-2"><TextField label="Protein" id="standard-basic" fullWidth variant="standard" /></Col>
                                                            <Col className="mt-2"><TextField label="Real" id="standard-basic" fullWidth variant="standard" /></Col>
                                                        </Row>
                                                        <Row className="blue_bg pb-3 align-items-end justify-content-center">
                                                            <Col xl="3" className="mt-2 text-end"><p className="mb-0 mt-2">Type Traits :</p></Col>
                                                            <Col className="mt-2"><TextField label="Body" fullWidth variant="standard" /></Col>
                                                            <Col className="mt-2"><TextField label="Legs" fullWidth variant="standard" /></Col>
                                                            <Col className="mt-2"><TextField label="Udder" fullWidth variant="standard" /></Col>
                                                            <Col className="mt-2"><TextField label="Total" fullWidth variant="standard" /></Col>
                                                        </Row>
                                                    </Col>
                                                    <Col xl="6">
                                                        <Row className="mt-2 mx-1">
                                                            <Col className="animal_dark_bg text-center">
                                                                <h6 className="py-2 mb-0"><b>Paternal Dam</b></h6>
                                                            </Col>
                                                        </Row>
                                                        <Row className="blue_bg mx-1">
                                                            <Col className="mt-2">
                                                                <Dropdown label="Sire ID" menuList={menuitm} placeholder="Sire ID" />
                                                            </Col>
                                                            <Col className="mt-2">
                                                                <TextField label="Sire Name" id="standard-basic" fullWidth variant="standard" />
                                                            </Col>
                                                        </Row>
                                                        <Row className="mx-1 blue_bg align-items-end">
                                                            <Col xl="6" className="mt-2">
                                                                <sub className="mb-0 pb-0">Birth Date</sub>
                                                                <input type="date" className="dateSelect" />
                                                            </Col>
                                                            <Col className="mt-2">
                                                                <TextField label="Sire Index" id="standard-basic" fullWidth variant="standard" />
                                                            </Col>
                                                        </Row>
                                                        <Row className="mx-1 top_space blue_bg align-items-end justify-content-center">
                                                            <Col xl="3" className="mt-2 text-end"><p className="mb-0 mt-2">EBV (Kg) :</p></Col>
                                                            <Col className="mt-2"><TextField label="Milk" id="standard-basic" fullWidth variant="standard" /></Col>
                                                            <Col className="mt-2"><TextField label="Fat" id="standard-basic" fullWidth variant="standard" /></Col>
                                                            <Col className="mt-2"><TextField label="Protein" id="standard-basic" fullWidth variant="standard" /></Col>
                                                            <Col className="mt-2"><TextField label="Real" id="standard-basic" fullWidth variant="standard" /></Col>
                                                        </Row>
                                                        <Row className="mx-1 blue_bg pb-3 align-items-end justify-content-center">
                                                            <Col xl="3" className="mt-2 text-end"><p className="mb-0 mt-2">Type Traits :</p></Col>
                                                            <Col className="mt-2"><TextField label="Body" fullWidth variant="standard" /></Col>
                                                            <Col className="mt-2"><TextField label="Legs" fullWidth variant="standard" /></Col>
                                                            <Col className="mt-2"><TextField label="Udder" fullWidth variant="standard" /></Col>
                                                            <Col className="mt-2"><TextField label="Total" fullWidth variant="standard" /></Col>
                                                        </Row>
                                                    </Col>
                                                </Row>
                                            </>
                                        )}
                                    </Col>
                                )}
                            </Row>
                        </Container>
                    </Col >
                </Row >
            </Container >

            <Modal size="lg" aria-labelledby="contained-modal-title-vcenter" centered show={modalShow} onHide={() => setModalShow(false)}>
                <Modal.Header style={{ background: "#DFDFDF" }}>
                    <Modal.Title id="contained-modal-title-vcenter">Disposal Animal</Modal.Title>
                    <img src={closeButton} onClick={() => setModalShow(false)} />
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Row className="align-items-end">
                            <Col sm="6" className="mb-3">
                                <div>
                                    <sub className="mb-0 pb-0">Date</sub>
                                    <input type="date" className="dateSelect_bg" id="date-1" value={date} onChange={(e) => { setDate(e.target.value) }} />
                                </div>
                            </Col>
                            <Col sm="6" className="mb-3">
                                <FormControl variant="standard" fullWidth>
                                    <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>Disposal Type</InputLabel>
                                    <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={disposalType} onChange={(e) => { setDisposalType(e.target.value) }}>
                                        {disposalTypeList.map((e) => (
                                            <MenuItem className="drop_value" key={e.id} value={e.ID}>
                                                {e.Name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Col>
                            {
                                disposalType === 1 && <Col sm="6" className="mb-3">
                                    <FormControl variant="standard" fullWidth>
                                        <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>Reason</InputLabel>
                                        <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={reason} onChange={(e) => { setReason(e.target.value) }}>
                                            {reasonTypeList.map((e) => (
                                                <MenuItem className="drop_value" key={e.id} value={e.ID}>
                                                    {e.Name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Col>
                            }
                            {
                                disposalType === 2 &&
                                <>
                                    <Col sm="6" className="mb-3">
                                        <FormControl variant="standard" fullWidth>
                                            <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>System Effected</InputLabel>
                                            <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={system} onChange={(e) => { setSystem(e.target.value) }}>
                                                {systemEffectdList.map((e) => (
                                                    <MenuItem className="drop_value" key={e.id} value={e.ID}>
                                                        {e.Name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Col>
                                    <Col sm="6" className="mb-3">
                                        <FormControl variant="standard" fullWidth>
                                            <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>Reason</InputLabel>
                                            <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={reason} onChange={(e) => { setReason(e.target.value) }}>
                                                {reasonTypeList.map((e) => (
                                                    <MenuItem className="drop_value" key={e.id} value={e.ID}>
                                                        {e.Name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Col>
                                </>
                            }
                            {
                                disposalType === 3 &&
                                <>
                                    <Col sm="6" className="mb-2">
                                        <TextField label="Sold To" placeholder="" id="standard-basic" fullWidth variant="standard" value={soldTo} onChange={(e) => { setSoldTo(e.target.value) }} />
                                    </Col>
                                    <Col sm="6" className="mb-2">
                                        <TextField label="Sold Price" placeholder="" id="standard-basic" fullWidth variant="standard" value={enterPrice} onChange={(e) => { setEnterPrice(e.target.value) }} />
                                    </Col>
                                    <Col sm="6" className="mb-3">
                                        <FormControl variant="standard" fullWidth>
                                            <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>Reason</InputLabel>
                                            <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={reason} onChange={(e) => { setReason(e.target.value) }}>
                                                {reasonTypeList.map((e) => (
                                                    <MenuItem className="drop_value" key={e.id} value={e.ID}>
                                                        {e.Name}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Col>
                                </>
                            }

                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Container>
                        <Row className="justify-content-between">
                            <Col xl="2">
                                <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }} onClick={SaveDisposal}>Save</button>
                            </Col>
                            <Col xl="2">
                                <button className="btn-blue-Animal mt-1" onClick={() => setModalShow(false)} style={{ background: "#E16659" }}> Cancel </button>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Footer>
            </Modal>

            <ToastContainer
                position="top-center"
                autoClose={2000}
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
                style={{ fontSize: "14px" }}
            />

            {
                compoShow && (
                    <Navigate to="/resgistraion/add" replace={true} name="Harsh" />
                )
            }

            {
                show && (
                    <Offcanvas className="hide_Navbar" show={show} onHide={handleClose}><Container><Row><Col><Sidenav /></Col></Row></Container></Offcanvas>
                )
            }

            {
                isLoading ?
                    <div className="circleText">
                        <div className="progressText">{progressData}</div>
                        <div className="">
                            <span className="loader-circle-13"></span>
                        </div>
                    </div>
                    : false
            }
        </>
    )
};
