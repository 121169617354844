import React, { useState, useEffect } from 'react'
import { Col, Container, Row, Offcanvas, Modal, Accordion } from "react-bootstrap";
import {
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    FormControlLabel,
    Radio,
    RadioGroup,
    Checkbox,
    ListItemText,
} from "@mui/material";
import Sidenav from "../../Componet/Sidenav/Sidenav";
import menu from '../../Photo/menu.svg'
import profile from '../../Photo/profile.svg';
import Input from '../../Componet/InputFild/Input';
import Table from '../../Componet/DataTable/Table';
import edit from "../../Photo/master/edit.svg";
import remove from "../../Photo/master/remove.svg";
import closeButton from "../../Photo/milk-union/close button.svg";
import './Transfer.css'
import { MdOutlineVpnKey } from "react-icons/md";
import { GoSignOut } from "react-icons/go";

import { STORAGE_KEY } from "../../constant/common";
import { API_URLS, serviceUrl } from "../../API/Api";
import { Link, useNavigate } from 'react-router-dom';
const { CampMaster } = API_URLS;
const added_by = localStorage.getItem(STORAGE_KEY.jwtTOKEN);

async function getZoneData(credentials) {
    return fetch(CampMaster.GetZone, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            "Accept": "application/json",
            "Authorization": `Bearer ${added_by}`,
        },
        body: JSON.stringify(credentials)
    })
        .then(res => res.json());
}


const Vapawernesss = () => {
    const API_KEY = serviceUrl;
    const selectedToDate = new Date().toISOString().slice(0, 10);

    const navigate = useNavigate()
    const [search, setSearch] = useState("")
    const [group, setGroup] = useState("")
    const [userName, setUserName] = useState("")
    const [contactPerson, setContactPerson] = useState("")
    const [mobile, setMobile] = useState("")
    const [appType, setAppType] = useState("")
    const [qr, setQr] = useState("")
    const [zone, setZone] = useState(0)
    const [editId, setEditId] = useState("")
    const [tableId, setTableId] = useState("")
    const [route, setRoute] = useState("")
    const [searchFilter, setSearchFilter] = useState("")
    const [progressData, setProgressData] = useState("0%");
    const [startDate, setStartDate] = useState("");
    const [society, setSociety] = useState("")
    const [union, setUnion] = useState("")
    const [societyName, setSocietyName] = useState("")
    const [endDate, setEndDate] = useState(selectedToDate)
    const [campNo, setCampNo] = useState("")
    const logOut = () => { navigate("/") }

    const [heightOffSet, setHeight] = useState(0);
    const [details, setDetails] = useState(true)
    const [profile, setProfile] = useState(false)
    const [isLoading, setIsLoading] = useState(false);
    const [headerShow, setHeaderShow] = useState(true);
    const [show, setShow] = useState(false);
    const handleClose = () => { setShow(false); }
    const handleShow = () => { setHeaderShow(!headerShow); }
    const [modalShow, setModalShow] = useState(false);

    const [fipList, setFipList] = useState([])
    const [unionList, setUnionList] = useState([])
    const [societyNameList, setSocietyNameList] = useState([])
    const [societyCode, setSocietyCode] = useState([])
    const [campNoList, setCampNoList] = useState([])


    const refreshFn = () => {
        setModalShow(false)
        setGroup("")
        setAppType("")
        setUserName("")
        setEditId("")
        setMobile("")
        setQr("")
        setZone("")
        setContactPerson("")
    }

    const AddData = async () => {
        console.log({ AppType: appType, CountryCode: null, Email: null, Mobile: mobile, MobileDetail: null, NotificationId: null, Photo: null, QRCode: qr, SyancPendingData: 1, Username: userName, group, is_deleted: null, staff: null, zone: zone })
        // fetch(`${API_KEY}/user/add`, {
        //     method: 'POST',
        //     headers: {
        //         'Content-Type': 'application/json',
        //         "Accept": "application/json",
        //         "Authorization": `Bearer ${added_by}`
        //     },
        //     body: JSON.stringify({ AppType: appType, CountryCode: null, Email: null, Mobile: mobile, MobileDetail: null, NotificationId: null, Photo: null, QRCode: qr, SyancPendingData: 1, Username: userName, group, is_deleted: null, staff: null, zone: zone }),
        // })
        //     .then(res => res.json())
        //     .then((result) => { if (result) { refreshFn();  setUserDetails(result.data);} })
    }

    const UpdateData = () => {
        console.log({ id: editId, AppType: appType, CountryCode: null, Email: null, Mobile: mobile, MobileDetail: null, NotificationId: null, Photo: null, QRCode: qr, SyancPendingData: 1, Username: userName, group, is_deleted: null, staff: null, zone: zone })
        // fetch(`${API_KEY}/herd/edit`, {
        //   method: "POST",
        //   headers: {
        //     "Content-Type": "application/json",
        //     "Accept": "application/json",
        //     "Authorization": `Bearer ${added_by}`,
        //   },
        //   body: JSON.stringify({ AppType: appType, CountryCode: null, Email: null, Mobile: mobile, MobileDetail: null, NotificationId: null, Photo: null, QRCode: qr, SyancPendingData: 1, Username: userName, group, is_deleted: null, staff: null, zone: zone })
        //   .then((res) => res.json())
        //   .then((result) => { if (result) { refreshFn();  setUserDetails(result.data);} })
    };


    const fetchData = async () => {
        const res = await getZoneData()
        if (res) { setUnionList(res) }
    }


    const Add = async () => {
        refreshFn()
        setModalShow(true)
        setUnion("")
        setSocietyName("")
        fetchData()
    }

    useEffect(() => {
        let divHeight = document.getElementById("heightOffsetStock")?.offsetHeight;
        setHeight(divHeight);
        fetchData()
    }, [])

    const AddAnimal = () => {

    }

    const SearchFn = (e) => {
        const data = e.target.value;
        if (data !== '') {
            const results = fipList.filter((user) => {
                return user.TagId.toLowerCase().startsWith(data.toLowerCase());
            });
            setSearchFilter(results);
        } else {
            setSearchFilter(fipList);
        }
        setSearch(data);
    }

    const Save = () => {
        fetch(`${API_KEY}/user/assignment`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`,
            },
            body: JSON.stringify({ farmerOnly: false, herdId: route || null, herdOnly: false, lotIdAssigned: [], lotIdUnAssign: [], lotOnly: true, searchTerm: "", userId: tableId })
        })
            .then(response => {
                const contentLength = response.headers.get('content-length');
                let loaded = 0;
                return new Response(
                    new ReadableStream({
                        start(controller) {
                            const reader = response.body.getReader();
                            read();
                            function read() {
                                reader.read()
                                    .then((progressEvent) => {
                                        if (progressEvent.done) {
                                            controller.close();
                                            return;
                                        }
                                        loaded += progressEvent.value.byteLength;
                                        const percentageComplete = Math.round(loaded / contentLength * 100) + '%';
                                        setProgressData(percentageComplete)
                                        controller.enqueue(progressEvent.value);
                                        read();
                                    })
                            }
                        }
                    })
                );
            })
            .then((res) => res.json())
            .then((result) => { setIsLoading(false); setProgressData("0%"); })
    }

    const editDetails = (e) => {
        fetchData()
        setEditId(e.id)
        setModalShow(true)
        setAppType(e.AppType)
        setUserName(e.Username)
        setQr(e.QRCode)
        setMobile(e.Mobile)
        setGroup(e.group.id)
        setZone(e.zone.id)
    };
    const colum = [
        {
            name: "id",
            selector: "id",
        },
        {
            name: "Camp",
            selector: "Camp",
        },
        {
            name: "TagId",
            selector: "TagId",
        },
        {
            name: "Date",
            selector: "Date",
        },
        {
            name: "Staff",
            selector: "Staff",
        },
    ]

    // const handleChange = (event) => {
    //     const { target: { value }, } = event;
    //     setTags(typeof value === "number" ? value.split(',') : value,);
    // };


    return (
        <>
            <Container fluid>
                <Row >
                    {headerShow && (<Col lg="2" className="hideNavbar"> <Sidenav /></Col>)}
                    <Col className="header" lg={headerShow ? "10" : "12"}>
                        <Container fluid>
                            <Row className="pt-1 sub-header align-items-center justify-content-between">
                                <Col md="11" sm="10" xs="10" className="py-2 d-flex align-items-center">
                                    <button style={{ border: "none" }} className="hideNavbar1" onClick={handleShow}><img src={menu} width={20} /></button>
                                    <button style={{ border: "none" }} className="hide_Navbar" onClick={() => setShow(true)}><img src={menu} width={20} /></button>
                                    <h5 className="ms-4 mt-2">Dudhsagar/ VAPawerness</h5>
                                </Col>
                                <Col md="1" sm="1" xs="2" className="text-end">
                                    <img src={profile} width={40} />
                                </Col>
                            </Row>
                            <Row className="align-items-end justify-content-start" id='heightOffsetStock'>
                                <Col md="3" xs="6" xl="1" className="mt-4 justify-content-start">
                                    <button className="btn-blue-Animal" onClick={Add}>Add Camp</button>
                                </Col>
                                <Col md="3" xl="2" xs="6">
                                    <FormControl variant="standard" fullWidth>
                                        <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>Union Name</InputLabel>
                                        <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={union} onChange={(e) => { setUnion(e.target.value); }}>
                                            {unionList.map((e) => (
                                                <MenuItem className="drop_value" key={e.id} value={e.id}>
                                                    {e.Name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Col>
                                <Col md="3" xl="2" xs="6" className='align-items-center'>
                                    <Input label="Society Code" value={societyCode} onChange={e => setSocietyCode(e)} />
                                </Col>
                                <Col md="3" xl="2" xs="6" className='align-items-center'>
                                    <Input label="Society Name" value={societyName} onChange={e => setSocietyName(e)} />
                                </Col>
                                <Col md="3" xl="2" xs="6" className='align-items-center'>
                                    <Input label="Search" value={search} onChange={e => SearchFn(e)} />
                                </Col>
                            </Row>
                            <Row className="mt-2">
                                <Col>
                                    <Table columns={colum} data={searchFilter ? searchFilter : fipList} height={`calc(100vh - (${heightOffSet}px + 124px))`} pagination />
                                </Col>
                            </Row>
                        </Container>
                    </Col>
                </Row>
            </Container>

            <Modal size="lg" aria-labelledby="contained-modal-title-vcenter" centered show={modalShow} onHide={() => setModalShow(false)}>
                <Modal.Header style={{ background: "#DFDFDF" }}>
                    <Modal.Title id="contained-modal-title-vcenter">Camp Visit Information</Modal.Title>
                    <img src={closeButton} onClick={() => setModalShow(false)} />
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Row>
                            <Col md="3" xl="6" xs="6" className='mb-2'>
                                <FormControl variant="standard" fullWidth>
                                    <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>Union Name</InputLabel>
                                    <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={union} onChange={(e) => { setUnion(e.target.value); }}>
                                        {unionList.map((e) => (
                                            <MenuItem className="drop_value" key={e.id} value={e.id}>
                                                {e.Name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Col>
                            <Col md="3" xl="6" xs="6" className='align-items-center'>
                                <Input label="Society Code" value={societyCode} onChange={e => setSocietyCode(e)} />
                            </Col>
                            <Col md="3" xl="6" xs="6">
                                <FormControl variant="standard" fullWidth>
                                    <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>Society Name</InputLabel>
                                    <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={societyName} onChange={(e) => { setSocietyName(e.target.value); }}>
                                        {societyNameList.map((e) => (
                                            <MenuItem className="drop_value" key={e.id} value={e.id}>
                                                {e.Name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Col>
                            <Col md="3" xl="6" xs="6">
                                <FormControl variant="standard" fullWidth>
                                    <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>Village Name</InputLabel>
                                    <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={societyName} onChange={(e) => { setSocietyName(e.target.value); }}>
                                        {societyNameList.map((e) => (
                                            <MenuItem className="drop_value" key={e.id} value={e.id}>
                                                {e.Name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Col>
                            <Col xl="6">
                                <div>
                                    <sub className="mb-0 pb-0">Start Date</sub>
                                    <input type="date" className="dateSelect_bg" id="date-1" value={startDate} onChange={(e) => { setStartDate(e.target.value) }} />
                                </div>
                            </Col>
                            <Col xl="6">
                                <div className="mt-1">
                                    <sub className="mb-0 pb-0">End Date</sub>
                                    <input type="date" className="dateSelect_bg" id="date-2" value={endDate} onChange={(e) => { setEndDate(e.target.value) }} />
                                </div>
                            </Col>
                            <Col md="3" xl="6" xs="6">
                                <FormControl variant="standard" fullWidth>
                                    <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>Camp No</InputLabel>
                                    <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={campNo} onChange={(e) => { setCampNo(e.target.value); }}>
                                        {campNoList.map((e) => (
                                            <MenuItem className="drop_value" key={e.id} value={e.id}>
                                                {e.Name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Container>
                        <Row className="justify-content-end ">
                            <Col xl="1">
                                <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }} onClick={editId ? (e) => UpdateData(e) : (e) => AddData(e)}>{editId ? "Update" : "Save"}</button>
                            </Col>
                            <Col xl="1">
                                <button className="btn-blue-Animal mt-1" onClick={() => setModalShow(false)} style={{ background: "#E16659" }}> Cancel </button>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Footer>
            </Modal>

            {show && (<Offcanvas className="hide_Navbar" show={show} onHide={handleClose}><Container><Row><Col><Sidenav /></Col></Row></Container></Offcanvas>)}

            {
                isLoading ?
                    <div className="circleText">
                        <div className="progressText">{progressData}</div>
                        <div className="">
                            <span className="loader-circle-13"></span>
                        </div>
                    </div>
                    : false
            }
        </>
    )
}
export default Vapawernesss
