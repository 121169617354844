import { Switch } from '@mui/material'
import React, { useState } from 'react'
import {
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
} from "@mui/material";
import {
    Col,
    Container,
    Row,
    Offcanvas,
    Accordion,
    Modal,
} from "react-bootstrap";
import Table from '../../Componet/DataTable/Table'
import Sidenav from '../../Componet/Sidenav/Sidenav';
import edit from "../../Photo/master/edit.svg";
import remove from "../../Photo/master/remove.svg";
import menu from "../../Photo/menu.svg";
import profile from "../../Photo/profile.svg";
import closeButton from "../../Photo/milk-union/close button.svg";
import Input from "../../Componet/InputFild/Input";
import { downloadExcel } from 'react-export-table-to-excel';
import { MdOutlineVpnKey } from "react-icons/md";
import { GoSignOut } from "react-icons/go";

import { STORAGE_KEY } from "../../constant/common";
import { API_URLS, serviceUrl } from "../../API/Api";
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import moment from 'moment';
import Date from '../../Componet/Datepicker/Date';
import dayjs from 'dayjs';
import BackBtn from '../../Componet/Button/BackBtn';
const { StaffApi, VocategoryApi, VopostApi, UserLoginDetail, SmsApi, ZoneApi } = API_URLS;


const label = { inputProps: { "aria-label": "Switch demo" } };

const Staff = () => {
    const added_by = localStorage.getItem(STORAGE_KEY.jwtTOKEN);
    const userName = localStorage.getItem("USERNAME");

    async function addData(credentials) {
        return fetch(StaffApi.AddDetails, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`,
            },
            body: JSON.stringify(credentials)
        })
            .then(res => res.json());
    }


    async function updateData(credentials) {
        return fetch(StaffApi.UpdateDetails, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`,
            },
            body: JSON.stringify(credentials)
        })
            .then(res => res.json());
    }
    const API_KEY = serviceUrl;
    const [code, setCode] = useState("")
    const [name, setName] = useState("")
    const [mobile, setMobile] = useState("")
    const [staffMobile, setStaffMobile] = useState("")
    const [search, setSearch] = useState("")
    const [basic, setBasic] = useState("")
    const [breed, setBreed] = useState("")
    const [sms, setSms] = useState("")
    const [group, setGroup] = useState("")
    const [category, setCategory] = useState("")
    const [post, setPost] = useState("")
    const [employeeNo, setEmployeeNo] = useState("")
    const [localName, setLocalName] = useState("")
    const [date, setDate] = useState("")
    const [weight, setWeight] = useState("")
    const [address, setAddress] = useState("")
    const [editId, setEditId] = useState("")
    const [deleteId, setDeleteId] = useState()
    const [user, setUser] = useState("")
    const [allowUser, setAllowUser] = useState("")
    const [gvcDetails, setGvcDetails] = useState("")
    const [maxBalance, setMaxBalance] = useState("")
    const [paymentType, setPaymentType] = useState("")
    const [center, setCenter] = useState("")
    const [qualification, setQualification] = useState("")
    const [flag, setFlag] = useState("")
    const [spCode, setSpCode] = useState("")
    const [searchFilter, setSearchFilter] = useState("")
    const [progressData, setProgressData] = useState("0%");
    const navigate = useNavigate()
    const logOut = () => { navigate("/") }

    const [isLoading, setIsLoading] = useState(false);
    const [heightOffSet, setHeight] = useState(0);
    const [isSendSMS, setIsSendSMS] = useState(false)
    const [deleteShow, setDeleteShow] = useState(false)
    const [show, setShow] = useState(false);
    const [headerShow, setHeaderShow] = useState(true);
    const handleClose = () => { setShow(false); };
    const handleShow = () => { setHeaderShow(!headerShow); }
    const [modalShow, setModalShow] = useState(false);
    const [isSuspended, setIsSuspended] = useState(false)
    const [editToggle, setEditToggle] = useState(false);
    const [voFlag, setVoFlag] = useState(false)


    const [staffList, setStaffList] = useState([])
    const [paymentList, setPaymentList] = useState([{ id: 1, Name: "Monthly" }, { id: 2, Name: "Daily Wages" }])
    const [smsList, setSmsList] = useState([])
    const [groupList, setGroupList] = useState([])
    const [categoryList, setCategoryList] = useState([])
    const [postList, setPostList] = useState([])
    const [centerList, setCenterList] = useState([])

    const saffGetData = async () => {
        setIsLoading(true)
        fetch(StaffApi.GetDetails, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`,
            },
        })
            .then(response => {
                const contentLength = response.headers.get('content-length');
                let loaded = 0;
                return new Response(
                    new ReadableStream({
                        start(controller) {
                            const reader = response.body.getReader();
                            read();
                            function read() {
                                reader.read()
                                    .then((progressEvent) => {
                                        if (progressEvent.done) {
                                            controller.close();
                                            return;
                                        }
                                        loaded += progressEvent.value.byteLength;
                                        const percentageComplete = Math.round(loaded / contentLength * 100) + '%';
                                        setProgressData(percentageComplete)
                                        controller.enqueue(progressEvent.value);
                                        read();
                                    })
                            }
                        }
                    })
                );
            })
            .then((res) => res.json())
            .then((result) => { setIsLoading(false); setStaffList(result.data); setProgressData("0%"); })

        // const res = await getData();
        // if (res) {
        //     setStaffList(res.data);
        //     setIsLoading(false)
        // }
    }
    const formApi = () => {
        //voCategory:
        fetch(`${VocategoryApi.GetDetails}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorization: `Bearer ${added_by}`,
            },
        })
            .then((res) => res.json())
            .then((result) => {
                setCategoryList(result.data);
            });

        //post:
        fetch(`${VopostApi.GetDetails}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorization: `Bearer ${added_by}`,
            },
        })
            .then((res) => res.json())
            .then((result) => {
                setPostList(result.data);
            });

        //group:
        fetch(`${UserLoginDetail.GetGroup}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorization: `Bearer ${added_by}`,
            },
        })
            .then((res) => res.json())
            .then((result) => {
                setGroupList(result);
            });

        //sms:
        fetch(`${SmsApi.SmsAllData}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorization: `Bearer ${added_by}`,
            },
        })
            .then((res) => res.json())
            .then((result) => {
                setSmsList(result);
            });

        //subCenter:
        fetch(`${ZoneApi.GetAllZone}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorization: `Bearer ${added_by}`,
            },
        })
            .then((res) => res.json())
            .then((result) => {
                setCenterList(result);
            });

    }

    useEffect(() => {
        let divHeight = document.getElementById("heightOffsetStock")?.offsetHeight;
        setHeight(divHeight);
        saffGetData()
        formApi()
    }, [])

    const SearchFn = (e) => {
        const data = e.target.value.toLowerCase();

        if (data !== '') {
            const results = staffList.filter((user) => {
                if (user && user.Name && user.id && user.employeeNo) {
                    return (
                        user.Name.toLowerCase().startsWith(data) ||
                        user.employeeNo.toString().toLowerCase().startsWith(data) ||
                        user.id.toString().toLowerCase().startsWith(data)
                    );
                }
                return false;
            });
            setSearchFilter(results);
        } else {
            setSearchFilter(staffList);
        }
        setSearch(data);
    }



    const AddData = async () => {
        if (editId) {
            if (userName === "haismanager") {
                const res = await updateData({ id: editId, address, allowUser, basic, mcode: spCode, code, name, company: "1", employeeNo, gvcDetails, isSendSMS, isSuspended, joiningDate: null, localName, maxBalance, mobile, paymentType, qualification, smsLanguage: sms, userGroup: group, voCategory: category, voFlag: flag, voPost: post, zone: center, staff: staffMobile, user: "201" });
                if (res) {
                    setModalShow(false)
                    saffGetData()
                }
            }
            else {
                const res = await updateData({ id: editId, address, allowUser, basic, code, name, company: "1", employeeNo, gvcDetails, isSendSMS, isSuspended, joiningDate: null, localName, maxBalance, mobile, paymentType, qualification, smsLanguage: sms, userGroup: group, voCategory: category, voFlag: flag, voPost: post, zone: center, staff: staffMobile, user: "201" });
                if (res) {
                    setModalShow(false)
                    saffGetData()
                }
            }
        }
        else {
            const res = await addData({
                address,
                allowUser,
                basic,
                code,
                company: "1",
                user: "201",
                employeeNo,
                gvcDetails,
                isSendSMS,
                isSuspended,
                joiningDate: date,
                localName,
                maxBalance,
                mobile,
                name,
                paymentType,
                qualification,
                smsLanguage: sms,
                userGroup: group,
                voCategory: category,
                voFlag: flag,
                voPost: post,
                zone: center,
                mcode: spCode
            });
            if (res) {
                setModalShow(false)
                saffGetData()
            }
        }
        setEditId("")
    }

    const ref = () => {
        setEditId("")
        setEditToggle("");
        setCode("");
        setName("");
        setAddress("")
        setAllowUser("")
        setBasic("")
        setEmployeeNo("")
        setGvcDetails("")
        setIsSendSMS("")
        isSuspended("")
        setDate("")
        setLocalName("")
        setMaxBalance("")
        setMobile("");
        setPaymentType("")
        setQualification("")
        setSms("")
        setCategory("")
        setFlag("")
        setPost("")
        setCenter("")
        setGroup("")
        setSpCode("")
        setLocalName("")
        setIsSuspended(false)
    }

    const Add = () => {
        setModalShow(true);
        ref()
    }

    const updateFn = (e) => {
        console.log(e)
        setModalShow(true)
        setEditId(e.id)
        setEditToggle(true);
        setEditId(e.id);
        setCode(e.code);
        setName(e.Name);
        setAddress(e.Address)
        setAllowUser(e.AllowUser)
        setBasic(e.Basic)
        setEmployeeNo(e.employeeNo)
        setGvcDetails(e.gvcDetails)
        setIsSuspended(e.IsSuspended)
        setDate(e.date)
        setLocalName(e.localName)
        setMaxBalance(e.MaxBalance)
        setMobile(e.Mobile);
        setPaymentType(e.PaymentType)
        setQualification(e.Qualification)
        setSms(e.smsLanguage.id)
        setIsSendSMS(e.IsSendSMS)
        setCategory(e.category)
        setFlag(e.flag)
        setPost(e.post)
        setGroup(e.group.id)
        setCenter(e.zoneObj.id)
        setCategory(e.voCategoryObj.id)
        setPost(e.voPostObj.id)
        setDate(dayjs(e["joiningDate"]))
        setSpCode(e.mcode)
    };


    const deleteData = (id) => {
        fetch(`${StaffApi.DelateStaff}?id=` + id, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`,
            },
        })
            .then((res) => res.json())
            .then((result) => {
                saffGetData();
                setDeleteShow(false)
                setModalShow(false);
            });

    }

    const handleChange = async (e, d) => {
        setIsSuspended(e.target.checked)
        setIsLoading(true)

        await fetch(`${StaffApi.SuspendApi}?id=${d.id}&isSuspended=${e.target.checked}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`,
            },
        })
            .then(response => {
                const contentLength = response.headers.get('content-length');
                let loaded = 0;
                return new Response(
                    new ReadableStream({
                        start(controller) {
                            const reader = response.body.getReader();
                            read();
                            function read() {
                                reader.read()
                                    .then((progressEvent) => {
                                        if (progressEvent.done) {
                                            controller.close();
                                            return;
                                        }
                                        loaded += progressEvent.value.byteLength;
                                        const percentageComplete = Math.round(loaded / contentLength * 100) + '%';
                                        setProgressData(percentageComplete)
                                        controller.enqueue(progressEvent.value);
                                        read();
                                    })
                            }
                        }
                    })
                );
            })
            .then((res) => res.json())
            .then((result) => { toast.success("Success suspended status changed. "); setIsLoading(false); setProgressData("0%"); })
    }


    const colum = [
        {
            name: "id",
            selector: "id",
        },
        {
            name: "Name",
            selector: "Name",
            width: "280px"
        },
        {
            name: "Mobile",
            selector: "Mobile",
            width: "120px"
        },
        {
            name: "group",
            cell: (e) => (<div>{e.group.Name}</div>),
            width: "120px"
        },
        {
            name: "Qualification",
            selector: "Qualification",
        },
        {
            name: "employeeNo",
            selector: "employeeNo",
        },
        {
            name: "localName",
            selector: "localName",
            width: "220px"
        },
        {
            name: "joiningDate",
            cell: (e) => {
                if (moment(e["joiningDate"]).isValid()) {
                    return <div>{moment(e["joiningDate"]).format("DD-MM-YYYY")}</div>;
                } else {
                    return null;
                }
            },
            width: "120px"
        },
        {
            name: "gvcDetails",
            selector: "gvcDetails",
        },
        {
            name: "zoneName",
            selector: "zoneName",
            width: "140px"
        },
        {
            name: "voCategoryName",
            selector: "voCategoryName",
        },
        {
            name: "voPostName",
            selector: "voPostName",
            width: "180px"
        },
        userName === "haismanager" ?
            {
                name: "SAP Code",
                selector: "mcode",
            } : null,
        {
            name: "IsSuspended",
            selector: "IsSuspended",
            cell: (d) => <Switch {...label} defaultChecked={d.IsSuspended} onChange={(e) => { handleChange(e, d) }} />,
        },
        {
            name: "Actions",
            cell: (e) => (
                <>
                    <img
                        src={edit}
                        onClick={() => updateFn(e)}
                        style={{ width: "15px", margin: "10px" }}
                    />
                    <img
                        src={remove}
                        onClick={() => { setDeleteShow(true); setDeleteId(e.id); setUser(e.Name); }}
                        style={{ width: "15px" }}
                    />
                </>
            ),
        },
    ].filter(Boolean);





    const handleDownloadExcel = () => {
        const header = []
        const opt = `BulkWise MilkEntry ${date}`;
        colum.forEach((e) => {
            if (e.name === "SAP Code") {
                header.push("mcode");
            } else {
                header.push(e.name);
            }
        });

        const body = staffList.map((item) => {
            const rowData = {};
            header.forEach((key) => {
                rowData[key] = item[key] || "";
            });
            return rowData;
        });

        downloadExcel({
            fileName: opt,
            sheet: "data",
            tablePayload: {
                header: header,
                body: body
            }
        });
        console.log(body);
    }
    return (
        <>
            <Container fluid>
                <Row> {headerShow && (<Col lg="2" className="hideNavbar"> <Sidenav /></Col>)}
                    <Col className="header" lg={headerShow ? "10" : "12"}>
                        <Container fluid>
                            <Row className="pt-1 sub-header align-items-center justify-content-between">
                                <Col md="10" sm="10" xs="10" className="py-2 d-flex align-items-center">
                                    <button style={{ border: "none" }} onClick={handleShow}> <img src={menu} width={20} /> </button>
                                    <h5 className="ms-4 mt-2">Master / Staff</h5>
                                </Col>
                                <Col md="1" sm="1" xs="10" className="py-2 d-flex align-items-center text-end ms-auto">
                                    <BackBtn style={{ border: "none" }} className="hideNavbar1" lable="Back" onClick={() => { navigate('/') }} />
                                </Col>
                                <Col md="1" sm="1" xs="2" className="text-end profile_dropdown">
                                    <img src={profile} width={40} />
                                    <div className="dropdpwn_Open">
                                        <Row className="text-center align-items-center">
                                            <Col xs="12" className="menuHover mb-3">
                                                <Row >
                                                    <Col className="" xs="3">
                                                        <span className=""><MdOutlineVpnKey size={25} />
                                                        </span>
                                                    </Col>
                                                    <Col className="text-start" xs="9">
                                                        Change Password
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col xs="12" className="menuHover" onClick={logOut}>
                                                <Row>
                                                    <Col className="" xs="3">
                                                        <span className=""><GoSignOut size={25} />
                                                        </span>
                                                    </Col>
                                                    <Col className="text-start" xs="9">
                                                        Sign Out
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="align-items-center p-2" id='heightOffsetStock'>
                                <Col sm="4" lg="2" xl="1" className="mt-2align-items-center justify-content-start">
                                    <button className="btn-blue-Animal mt-1 mb-1" onClick={Add}>Add Satff</button>
                                </Col>
                                <Col xxl="2" lg="3" md="4" sm="5" className='ms-auto'>
                                    <Input label="Search" value={search} onChange={(e) => SearchFn(e)} />
                                </Col>
                                <Col sm="4" lg="1" xl="1" className="mt-2 align-items-center ">
                                    <button className="btn-blue-Animal mt-1 mb-1" onClick={handleDownloadExcel}>Export</button>
                                </Col>
                            </Row>
                            <Row className="mt-2">
                                <Col>
                                    <Table columns={colum} data={searchFilter ? searchFilter : staffList} height={`calc(100vh - (${heightOffSet}px + 124px))`} pagination />
                                </Col>
                            </Row>
                        </Container>
                    </Col>
                </Row>
            </Container>

            <Modal aria-labelledby="contained-modal-title-vcenter" centered show={deleteShow} onHide={() => setModalShow(false)}>
                <Modal.Header style={{ background: "#DFDFDF" }}>
                    <Modal.Title id="contained-modal-title-vcenter">Confirm Delete ?</Modal.Title>
                    <img src={closeButton} onClick={() => setModalShow(false)} />
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        Delete {user} ?
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Container>
                        <Row className="justify-content-end">
                            <Col xl="3">
                                <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }} onClick={(e) => deleteData(deleteId)}>Delete</button>
                            </Col>
                            <Col xl="3">
                                <button className="btn-blue-Animal mt-1" onClick={() => setDeleteShow(false)} style={{ background: "#E16659" }}> Cancel </button>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Footer>
            </Modal>

            <Modal size="lg" aria-labelledby="contained-modal-title-vcenter" centered show={modalShow} onHide={() => setModalShow(false)}>
                <Modal.Header style={{ background: "#DFDFDF" }}>
                    <Modal.Title id="contained-modal-title-vcenter">{editId ? "Update Satff" : "Add new Satff"}</Modal.Title>
                    <img src={closeButton} onClick={() => setModalShow(false)} />
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Row>
                            <Col xl="6">
                                <TextField label="Code" id="standard-basic" value={code} onChange={(e) => { setCode(e.target.value); }} data={staffList} fullWidth variant="standard" />
                            </Col>
                            <Col xl="6">
                                <TextField label="Name" value={name} onChange={(e) => { setName(e.target.value); }} id="standard-basic" data={staffList} fullWidth variant="standard" />
                            </Col>
                        </Row>
                        <Row className="mt-4">
                            <Col xl="6">
                                <TextField label="Mobile" value={mobile} onChange={(e) => { setMobile(e.target.value) }} id="standard-basic" data={staffList} fullWidth variant="standard" />
                            </Col>
                            <Col xl="6">
                                <TextField label="Max Balance" value={maxBalance} onChange={(e) => { setMaxBalance(e.target.value); }} id="standard-basic" data={staffList} fullWidth variant="standard" />
                            </Col>
                        </Row>
                        <Row className="mt-4">
                            <Col xl="6">
                                <TextField label="Basic" value={basic} onChange={(e) => { setBasic(e.target.value); }} id="standard-basic" data={staffList} fullWidth variant="standard" />
                            </Col>
                            <Col xl="6">
                                <FormControl variant="standard" style={{ border: "1px soild black" }} fullWidth>
                                    <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>
                                        Payment Type
                                    </InputLabel>
                                    <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={paymentType} onChange={(e) => { setPaymentType(e.target.value); }}>
                                        {paymentList.map((e) => (
                                            <MenuItem className="drop_value" key={e.id} value={e.id} >
                                                {e.Name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Col>
                        </Row>
                        <Row className="mt-4">
                            <Col xl="6">
                                <FormControl variant="standard" style={{ border: "1px soild black" }} fullWidth>
                                    <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>
                                        SMS Language
                                    </InputLabel>
                                    <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={sms} onChange={(e) => { setSms(e.target.value); }}>
                                        {smsList.map((e) => (
                                            <MenuItem className="drop_value" key={e.id} value={e.id} >
                                                {e.Name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Col>
                            <Col xl="6">
                                <FormControl variant="standard" style={{ border: "1px soild black" }} fullWidth>
                                    <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>
                                        User Group
                                    </InputLabel>
                                    <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={group} onChange={(e) => { setGroup(e.target.value); }}>
                                        {groupList.map((e) => (
                                            <MenuItem className="drop_value" key={e.id} value={e.id} >
                                                {e.Name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Col>
                        </Row>
                        <Row className="mt-4">
                            <Col xl="6">
                                <TextField label="Qualification" value={qualification} onChange={(e) => { setQualification(e.target.value) }} id="standard-basic" data={staffList} fullWidth variant="standard" />
                            </Col>
                            <Col xl="6">
                                <FormControl variant="standard" style={{ border: "1px soild black" }} fullWidth>
                                    <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>
                                        Vo Category/ Staff Designation
                                    </InputLabel>
                                    <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={category} onChange={(e) => { setCategory(e.target.value); }}>
                                        {categoryList.map((e) => (
                                            <MenuItem className="drop_value" key={e.id} value={e.id} >
                                                {e.Name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Col>
                        </Row>
                        <Row className="mt-4">
                            <Col xl="6">
                                <FormControl variant="standard" style={{ border: "1px soild black" }} fullWidth>
                                    <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>
                                        Vo Post/ Typep of Work
                                    </InputLabel>
                                    <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={post} onChange={(e) => { setPost(e.target.value); }}>
                                        {postList.map((e) => (
                                            <MenuItem className="drop_value" key={e.id} value={e.id} >
                                                {e.Name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Col>
                            <Col xl="6">
                                <FormControl variant="standard" style={{ border: "1px soild black" }} fullWidth>
                                    <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>
                                        Sub Ceneter
                                    </InputLabel>
                                    <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={center} onChange={(e) => { setCenter(e.target.value); }}>
                                        {centerList.map((e) => (
                                            <MenuItem className="drop_value" key={e.id} value={e.id} >
                                                {e.Name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Col>
                        </Row>
                        <Row className="mt-4">
                            <Col xl="6">
                                <TextField type='number' label="Employee No" value={employeeNo} onChange={(e) => { setEmployeeNo(e.target.value) }} id="standard-basic" data={staffList} fullWidth variant="standard" />
                            </Col>
                            <Col xl="6">
                                <TextField label="Local Name" value={localName} onChange={(e) => { setLocalName(e.target.value); }} id="standard-basic" data={staffList} fullWidth variant="standard" />
                            </Col>
                        </Row>
                        <Row className="mt-2 align-items-end">
                            <Col xl="6">
                                {/* <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "14px" }}>
                                    Birth Name
                                </InputLabel>
                                <TextField type="date" value={date} onChange={(e) => { setDate(e.target.value); }} id="standard-basic" data={staffList} fullWidth variant="standard" /> */}
                                <Date label="Joining Date" value={date} onChange={(e) => { setDate(e); }} />
                            </Col>
                            <Col xl="6">
                                <TextField label="GVC Details" value={gvcDetails} onChange={(e) => { setGvcDetails(e.target.value); }} id="standard-basic" data={staffList} fullWidth variant="standard" />
                            </Col>
                        </Row>
                        {
                            userName === "haismanager" && <Row className="mt-2 align-items-end">
                                <Col xl="6">
                                    <TextField label="SAP Code" value={spCode} onChange={(e) => { setSpCode(e.target.value); }} id="standard-basic" data={staffList} fullWidth variant="standard" />
                                </Col>
                            </Row>
                        }
                        {/* <Row className="mt-2 align-items-end">
                            <Col xl="6">
                                <TextField label="SAP Code" value={spCode} onChange={(e) => { setSpCode(e.target.value); }} id="standard-basic" data={staffList} fullWidth variant="standard" />
                            </Col>
                        </Row> */}
                        <Row className='mt-4'>
                            <Col xl="12">
                                <TextField label="Address" value={address} onChange={(e) => { setAddress(e.target.value); }} id="standard-basic" data={staffList} fullWidth variant="standard" />
                            </Col>
                        </Row>
                        <Row className="mt-4">
                            <Col xl="6" className='d-flex align-items-center'>
                                <Col className='d-flex align-items-center'>
                                    <input type="checkbox" className='mr-2' defaultChecked={isSendSMS} onChange={(e) => { setIsSendSMS(e.target.checked) }} />
                                    <h6 className='m-0 mr-1'>Send SMS</h6>
                                </Col>
                                <Col className='d-flex align-items-center'>
                                    <input type="checkbox" className='mr-2' defaultChecked={allowUser} onChange={(e) => { setAllowUser(e.target.checked) }} />
                                    <h6 className='m-0 mr-1'>Allow User</h6>
                                </Col>
                            </Col>
                            <Col xl="6" className='d-flex align-items-center'>
                                <Col className='d-flex align-items-center'>
                                    <input type="checkbox" className='mr-2' defaultChecked={voFlag} onChange={(e) => { setVoFlag(e.target.checked) }} />
                                    <h6 className='m-0 mr-1'>Vo Flag</h6>
                                </Col>
                                <Col className='justify-content-start'>
                                    <Switch {...label} onChange={(e) => { setIsSuspended(e.target.checked) }} defaultChecked={isSuspended} />Is Suspended
                                </Col>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Container>
                        <Row className="justify-content-end">
                            <Col xl="2">
                                <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }} onClick={AddData}>{editId ? "Update" : "Save"}</button>
                            </Col>
                            <Col xl="2">
                                <button className="btn-blue-Animal mt-1" onClick={() => setModalShow(false)} style={{ background: "#E16659" }}> Cancel </button>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Footer>
            </Modal>
            <ToastContainer
                position="top-center"
                autoClose={2000}
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
                style={{ fontSize: "14px" }}
            />

            {show && <Offcanvas className="hide_Navbar" show={show} onHide={handleClose}><Container><Row><Col><Sidenav /></Col></Row></Container></Offcanvas>}


            {
                isLoading ?
                    <div className="circleText">
                        <div className="progressText">{progressData}</div>
                        <div className="">
                            <span className="loader-circle-13"></span>
                        </div>
                    </div>
                    : false
            }
        </>

    )
}

export default Staff;