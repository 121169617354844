import React, { useState, useEffect } from 'react';
import { Col, Container, Row, Offcanvas, Modal } from "react-bootstrap";

import menu from '../../Photo/menu.svg';
import profile from '../../Photo/profile.svg';

import Sidenav from "../../Componet/Sidenav/Sidenav";

import { STORAGE_KEY } from "../../constant/common";
import { API_URLS, serviceUrl } from "../../API/Api";
import { MdOutlineVpnKey } from "react-icons/md";
import { GoSignOut } from "react-icons/go";
import { useNavigate } from 'react-router-dom';
import closeButton from "../../Photo/milk-union/close button.svg";
import Input from '../../Componet/InputFild/Input';
import {
    InputLabel, MenuItem,
    //  Select, 
    TextField, FormControl
} from '@mui/material';
import Select from 'react-select';
import { ToastContainer, toast } from 'react-toastify';
import Table from '../../Componet/DataTable/Table';
import moment from 'moment';
const { Hemato_cytology, ZoneApi, StaffApi } = API_URLS;


export default function LabRegistration() {

    async function getDataLab(credentials) {
        return fetch(Hemato_cytology.getRegisterData, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`,
            },
            body: JSON.stringify(credentials)
        })
            .then(data => data.json());
    }
    async function getVillageData(credentials) {
        return fetch(ZoneApi.VillageData, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`,
            },
            body: JSON.stringify(credentials)
        })
            .then(data => data.json());
    }
    async function getTestedData(credentials) {
        return fetch(StaffApi.GetDetails, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`,
            },
            body: JSON.stringify(credentials)
        })
            .then(data => data.json());
    }
    async function getDropData(credentials) {
        return fetch(Hemato_cytology.allDropData, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`,
            },
            body: JSON.stringify(credentials)
        })
            .then(data => data.json());
    }
    async function getRefByData(credentials) {
        return fetch(Hemato_cytology.refBydataApi, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`,
            },
            body: JSON.stringify(credentials)
        })
            .then(data => data.json());
    }

    const API_KEY = serviceUrl;
    // const API_KEY = "http://localhost:2000";
    const added_by = localStorage.getItem(STORAGE_KEY.jwtTOKEN);
    const selectedToDate = new Date().toISOString().slice(0, 10);

    const [search, setSearch] = useState("")
    const [id, setId] = useState("")
    const [sample, setSample] = useState("")
    const [patId, setPatId] = useState("")
    const [labName, setLabName] = useState("")
    const [refBy, setRefBy] = useState("")
    const [refInstitute, setRefInstitute] = useState("")
    const [date, setDate] = useState(selectedToDate)
    const [owner, setOwner] = useState("")
    const [village, setVillage] = useState("")
    const [animalSpeices, setAnimalSpeices] = useState("")
    const [animal, setAnimal] = useState("")
    const [heightOffSet, setHeight] = useState(0);
    const [taluka, setTaluka] = useState("")
    const [tagIdNo, setTagIdNo] = useState("")
    const [district, setDistrict] = useState("")
    const [test, setTest] = useState("")
    const [unfitSample, setUnfitSample] = useState("")
    const [refCellNo, setRefCellNo] = useState("")
    const [testedBy, setTestedBy] = useState("")
    const [progressData, setProgressData] = useState("0%")

    const [modalShow, setModalShow] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [show, setShow] = useState(false);
    const [headerShow, setHeaderShow] = useState(true);
    const handleClose = () => { setShow(false); }
    const handleShow = () => { setHeaderShow(!headerShow); }
    const navigate = useNavigate()
    const logOut = () => { navigate("/") }

    const [refList, setRefList] = useState([])
    const [labList, setLabList] = useState([])
    const [refInstituteList, setRefInstituteList] = useState([])
    const [villageList, setVillageList] = useState([])
    const [animalSpeicesList, setAnimalSpeicesList] = useState([])
    const [talukaList, setTalukaList] = useState([])
    const [districtList, setDistrictList] = useState([])
    const [sampleList, setSampleList] = useState([])
    const [testList, setTestList] = useState([])
    const [unfitSampleList, setUnfitSampleList] = useState([])
    const [testedByList, setTestedByList] = useState([])
    const [labDataList, setLabDataList] = useState([])
    const [filterList, setFilterList] = useState()

    const Add = () => {
        setModalShow(true)
    }

    const SearchFn = (e) => {
        const data = e.target.value
        const abc = labDataList;

        const filterdata = abc.filter((i) => { return i.TagIdNo.includes(data) })
        setFilterList(filterdata)

    }

    const fatchData = async () => {
        setIsLoading(true)
        const res = await getDataLab()
        const res1 = await getVillageData()
        const res2 = await getTestedData()
        const res3 = await getDropData({ QTYPE: "SAMPLE" })
        const res4 = await getDropData({ QTYPE: "UNFIT_SAMPLE" })
        const res5 = await getDropData({ QTYPE: "DIST_DIST_TALUKA" })
        const res6 = await getDropData({ QTYPE: "INSTITUTION" })
        const res7 = await getDropData({ QTYPE: "DISTRICT_TALUKA" })
        const res8 = await getDropData({ QTYPE: "LAB_LIST" })
        const res9 = await getDropData({ QTYPE: "LAB_TESTED" })
        const res10 = await getRefByData({ Userid: "201", TBLSTR: "Master_staff:0" })

        if (res) { setLabDataList(res.data); setIsLoading(false); setProgressData("0%") }
        if (res1) { setVillageList(res1); setIsLoading(false); setProgressData("0%") }
        if (res2) { setTestedByList(res2.data); setIsLoading(false); setProgressData("0%") }

        if (res3) { setSampleList(res3.data); setIsLoading(false); setProgressData("0%") }
        if (res4) { setUnfitSampleList(res4.data); setIsLoading(false); setProgressData("0%") }
        if (res5) { setDistrictList(res5.data); setIsLoading(false); setProgressData("0%") }
        if (res6) { setRefInstituteList(res6.data); setIsLoading(false); setProgressData("0%") }
        if (res7) { setTalukaList(res7.data); setIsLoading(false); setProgressData("0%") }
        if (res8) { setLabList(res8.data); setIsLoading(false); setProgressData("0%") }
        if (res9) { setTestList(res9.data); setIsLoading(false); setProgressData("0%") }
        if (res10) { setRefList(res10[0]); setIsLoading(false); setProgressData("0%") }
    }

    const Save = async () => {
        setIsLoading(true)
        await fetch(`${API_KEY}/lab/Register/Save_LabData`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`
            },
            body: JSON.stringify({
                ID: "0",
                PatIdNo: patId,
                LabName: labName,
                RefBy: refBy,
                RefInstitute: refInstitute,
                Date: date,
                Owner: owner,
                Village: village,
                AnimalSpecies: animalSpeices,
                Animal: animal,
                Taluka: taluka,
                TagIdNo: tagIdNo,
                District: district,
                Sample: sample,
                Test: test,
                UnfitSample: unfitSample,
                RefCellNo: refCellNo,
                TestedBy: testedBy
            })
        })
            .then(response => {
                const contentLength = response.headers.get('content-length');
                let loaded = 0;
                return new Response(
                    new ReadableStream({
                        start(controller) {
                            const reader = response.body.getReader();
                            read();
                            function read() {
                                reader.read()
                                    .then((progressEvent) => {
                                        if (progressEvent.done) {
                                            controller.close();
                                            return;
                                        }
                                        loaded += progressEvent.value.byteLength;
                                        const percentageComplete = Math.round(loaded / contentLength * 100) + '%';
                                        setProgressData(percentageComplete)
                                        controller.enqueue(progressEvent.value);
                                        read();
                                    })
                            }
                        }
                    })
                );
            })
            .then(res => res.json())
            .then((res) => { fatchData(); setModalShow(false); toast.success("Save Data Successful"); setIsLoading(false); setProgressData("0%") })
    }

    const colums = [
        {
            name: "ID",
            selector: "ID",
            cell: (e) => (<div className='ms-auto'>{e["ID"]}</div>)
        },
        {
            name: "TagIdNo",
            selector: "TagIdNo",
            cell: (e) => (<div className='ms-auto'>{e["TagIdNo"]}</div>),
            width: "140px"
        },
        {
            name: "PatId No",
            selector: "PatIdNo",
            cell: (e) => (<div className='ms-auto'>{e["PatIdNo"]}</div>)
        },
        {
            name: "Lab Name",
            selector: "lab_list_name",
            cell: (e) => (<div className='text-start'>{e["lab_list_name"]}</div>),
            width: "140px"
        },
        {
            name: "Ref By",
            selector: "RefBy",
            cell: (e) => (<div className='ms-auto'>{e["RefBy"]}</div>)
        },
        {
            name: "Ref Institute",
            selector: "lab_institution_name",
            cell: (e) => (<div className='text-start'>{e["lab_institution_name"]}</div>),
            width: "140px"
        },
        {
            name: "Date",
            selector: "Date",
            cell: (e) => {
                const formattedDate = moment(e["Date"]).format('DD-MM-YYYY');
                return (
                    <div className='ms-auto'>{formattedDate}</div>
                );
            },
            width: "110px"
        },
        {
            name: "Owner",
            selector: "Owner",
            cell: (e) => (<div className='ms-auto'>{e["Owner"]}</div>)
        },
        {
            name: "Village",
            selector: "Village",
            cell: (e) => (<div className='ms-auto'>{e["Village"]}</div>)
        },
        {
            name: "Animal Species",
            selector: "AnimalSpecies",
            cell: (e) => (<div className='ms-auto'>{e["AnimalSpecies"]}</div>)
        },
        {
            name: "Animal",
            selector: "Animal",
            cell: (e) => (<div className='ms-auto'>{e["Animal"]}</div>)
        },
        {
            name: "Taluka",
            selector: "lab_taluka_name",
            cell: (e) => (<div className='text-start'>{e["lab_taluka_name"]}</div>),
            width: "140px"
        },
        {
            name: "District",
            selector: "lab_district_name",
            cell: (e) => (<div className='ms-auto'>{e["lab_district_name"]}</div>)
        },
        {
            name: "Sample",
            selector: "lab_sample_name",
            cell: (e) => (<div className='text-start'>{e["lab_sample_name"]}</div>),
            width: "140px"
        },
        {
            name: "Test",
            selector: "lab_Tested_name",
            cell: (e) => (<div className='text-start'>{e["lab_Tested_name"]}</div>)
        },
        {
            name: "Unfit sample",
            selector: "lab_Unfit_Sample_name",
            cell: (e) => (<div className='text-start'>{e["lab_Unfit_Sample_name"]}</div>),
            width: "180px"
        },
        {
            name: "Ref CellNo",
            selector: "RefCellNo",
            cell: (e) => (<div className='ms-auto'>{e["RefCellNo"]}</div>),
            width: "140px"
        },
        {
            name: "Tested By",
            selector: "TestedBy",
            cell: (e) => (<div className='ms-auto'>{e["TestedBy"]}</div>)
        },
    ]


    const animalSpeicesData = [
        {
            ID: "1",
            Name: "Cow"
        },
        {
            ID: "1",
            Name: "Buffelo"
        },
    ]


    useEffect(() => {
        let divHeight = document.getElementById("heightOffsetStock")?.offsetHeight;
        setHeight(divHeight);
        fatchData()
    }, [])

    const [showfields, setShowFields] = useState(false)
    const [modaladd, setmodaladd] = useState(false)

    const fillData = (e) => {
        console.log(e);
        if (e) {
            const findrecord = labDataList.find((i) => { return i.TagIdNo == e })

            if (findrecord) {
                setShowFields(true)
                setTagIdNo(findrecord?.TagIdNo)
                setPatId(findrecord?.PatIdNo)
                setLabName(findrecord?.lab_list_name)
                setRefBy(findrecord?.RefBy)
                setRefInstitute(findrecord?.RefInstitute)
                setDate(findrecord?.Date)
                setDistrict(findrecord?.District)
                setTaluka(findrecord?.TagIdNo)
                setVillage(findrecord?.Village)
                setOwner(findrecord?.Owner)
                setAnimalSpeices(findrecord?.AnimalSpecies)
                setAnimal(findrecord?.Animal)
                setSample(findrecord?.lab_sample_name)
                setTest(findrecord?.lab_Tested_name)
                setUnfitSample(findrecord?.lab_Unfit_Sample_name)
                setRefCellNo(findrecord?.RefCellNo)
                setTestedBy(findrecord?.TestedBy)

                console.log(findrecord);
            } else {
                setShowFields(false)
                toast.error("Register Id not found, Please register.")
                // setTagIdNo("")
                setPatId("")
                setLabName("")
                setRefBy("")
                setRefInstitute("")
                setDate("")
                setDistrict("")
                setTaluka("")
                setVillage("")
                setOwner("")
                setAnimalSpeices("")
                setAnimal("")
                setSample("")
                setTest("")
                setUnfitSample("")
                setRefCellNo("")
                setTestedBy("")
            }
        }
    }
    const resetFn = () => {
        setTagIdNo("")
        setPatId("")
        setLabName("")
        setRefBy("")
        setRefInstitute("")
        setDate("")
        setDistrict("")
        setTaluka("")
        setVillage("")
        setOwner("")
        setAnimalSpeices("")
        setAnimal("")
        setSample("")
        setTest("")
        setUnfitSample("")
        setRefCellNo("")
        setTestedBy("")
    }

    const handleTagIdNoChange = (e, f) => {
        const numericValue = e.target.value.replace(/[^0-9]/g, '');
        if (f == "tagIdNo") {
            setTagIdNo(numericValue);
        }
        else if (f == "patId") {
            setPatId(numericValue);
        }
        else if (f == "refCellNo") {
            setRefCellNo(numericValue);
        }
    };

    const handleAlphabetChange = (e, f) => {
        const inputValue = e.target.value;
        const updatedValue = inputValue.replace(/[^a-zA-Z]/g, '');

        if (f === 'village') {
            setVillage(updatedValue);
        } else if (f === 'owner') {
            setOwner(updatedValue);
        } else if (f === 'animal') {
            setAnimal(updatedValue);
        }
    };

    return (
        <>
            <Container fluid>
                <Row>
                    {headerShow && (<Col lg="2" className="hideNavbar"> <Sidenav /></Col>)}
                    <Col className="header" lg={headerShow ? "10" : "12"}>
                        <Container fluid>
                            <Row className="pt-1 sub-header align-items-center justify-content-between">
                                <Col md="11" sm="10" xs="10" className="py-2 d-flex align-items-center">
                                    <button style={{ border: "none" }} className="hideNavbar1" onClick={handleShow}><img src={menu} width={20} /></button>
                                    <button style={{ border: "none" }} className="hide_Navbar" onClick={() => setShow(true)}><img src={menu} width={20} /></button>
                                    <h5 className="ms-4 mt-2">Lab Management / Lab Registration</h5>
                                </Col>
                                <Col md="1" sm="1" xs="2" className="text-end profile_dropdown">
                                    <img src={profile} width={40} />
                                    <div className="dropdpwn_Open">
                                        <Row className="text-center align-items-center">
                                            <Col xs="12" className="menuHover mb-3">
                                                <Row >
                                                    <Col className="" xs="3">
                                                        <span className=""><MdOutlineVpnKey size={25} />
                                                        </span>
                                                    </Col>
                                                    <Col className="text-start" xs="9">
                                                        Change Password
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col xs="12" className="menuHover" onClick={logOut}>
                                                <Row>
                                                    <Col className="" xs="3">
                                                        <span className=""><GoSignOut size={25} />
                                                        </span>
                                                    </Col>
                                                    <Col className="text-start" xs="9">
                                                        Sign Out
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="align-items-center justify-content-start" id='heightOffsetStock'>
                                <Col sm="4" lg="2" xl="1" className="mt-2 align-items-center justify-content-start">
                                    <button className="btn-blue-Animal mt-1 mb-1" onClick={() => { Add(); resetFn() }}>Add Registration</button>
                                </Col>
                                <Col xxl="2" lg="3" md="4" sm="5" className='ms-auto'>
                                    <Input label="Search" value={search} onChange={e => SearchFn(e)} />
                                </Col>
                            </Row>
                            {/* <Row className='mt-2'>
                                <Col><Table columns={colums} data={labDataList} height="72vh" /></Col>
                            </Row> */}

                            <Row className="mt-2" >
                                {labDataList.length > 0 ?
                                    <div className="blue_bg DataTableBorder mt-1">
                                        <Table columns={colums} data={filterList ? filterList : labDataList} height={`calc(100vh - (${heightOffSet}px + 140px))`} pagination />
                                    </div>
                                    :
                                    <Row>
                                        <Col>
                                            <div className="NoTableBorder ms-2" >
                                                <div className="d-flex tabledata colmBreed">{
                                                    colums.map((i) => {
                                                        return (
                                                            <Col className={`blankColumns ${i.name.replace(' ', '_')}`} style={{ backgroundColor: "rgb(169,169,169)" }} height={`calc(100vh - (${heightOffSet}px + 20px))`} >{i.name}</Col>
                                                        )
                                                    })
                                                }
                                                </div>
                                                <h6 className="text-center align-items-center mt-5 noData ms-5">No Data</h6>
                                            </div>
                                        </Col>
                                    </Row>
                                }
                            </Row>
                        </Container>
                    </Col>
                </Row>
            </Container>
            <Modal size="lg" aria-labelledby="contained-modal-title-vcenter" centered show={modalShow} onHide={() => setModalShow(false)}>
                <Modal.Header style={{ background: "#DFDFDF" }}>
                    <Modal.Title id="contained-modal-title-vcenter">Add New Registration</Modal.Title>
                    <img src={closeButton} onClick={() => setModalShow(false)} />
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Row className='align-items-end'>
                            <Col sm="4">
                                <TextField label="Tag ID No" id="standard-basic" value={tagIdNo} onChange={(e) => { setTagIdNo(e.target.value); fillData(e.target.value) }} fullWidth variant="standard" />
                            </Col>
                            {/* <Col sm="4">
                                <TextField label="ID" id="standard-basic" value={id} onChange={(e) => { setId(e.target.value); }} fullWidth variant="standard" />
                            </Col> */}
                            {
                                showfields && (
                                    <>
                                        <Col sm="4">
                                            <TextField label="Pat ID No" id="standard-basic" value={patId} onChange={(e) => { setPatId(e.target.value); }} fullWidth variant="standard" />
                                        </Col>
                                        <Col sm="4">
                                            <Select
                                                placeholder="Lab Name"
                                                onChange={(selectedOption) => { setLabName(selectedOption.value); }}
                                                options={labList.map((item) => ({ value: item.id, label: item.name }))}
                                            />
                                        </Col>
                                        <Col sm="4">
                                            <Select
                                                placeholder="Ref By"
                                                onChange={(selectedOption) => { setRefBy(selectedOption.value); }}
                                                options={refList.map((item) => ({ value: item.id, label: item.Name }))}
                                            />
                                        </Col>
                                        <Col sm="4">
                                            <Select
                                                placeholder="Ref Institute"
                                                onChange={(selectedOption) => { setRefInstitute(selectedOption.value); }}
                                                options={refInstituteList.map((item) => ({ value: item.id, label: item.name }))}
                                            />
                                        </Col>
                                        <Col sm="4">
                                            <div>
                                                <sub className="mb-0 pb-0">Date</sub>
                                                <input type="date" className="dateSelect_bg" id="date-1" value={date} onChange={(e) => { setDate(e.target.value); }} />
                                            </div>
                                            {/* <TextField label="Date" id="standard-basic" value={date} onChange={(e) => { setDate(e.target.value); }} fullWidth variant="standard" /> */}
                                        </Col>
                                        <Col sm="4">
                                            <Select
                                                placeholder="District"
                                                onChange={(selectedOption) => { setDistrict(selectedOption.value); }}
                                                options={districtList.map((item) => ({ value: item.DIST, label: item.DIST }))}
                                            />
                                        </Col>
                                        <Col sm="4">
                                            <Select
                                                placeholder="Taluka"
                                                onChange={(selectedOption) => { setTaluka(selectedOption.value); }}
                                                options={talukaList.map((item) => ({ value: item.id, label: item.taluka }))}
                                            />
                                        </Col>
                                        {/* <Col sm="4">
                                <Select
                                    placeholder="Village"
                                    onChange={(selectedOption) => { setVillage(selectedOption.value); }}
                                    options={villageList.map((item) => ({ value: item.ID, label: item.Name }))}
                                />
                            </Col> */}
                                        <Col sm="4">
                                            <TextField label="Village" id="standard-basic" value={village} onChange={(e) => { setVillage(e.target.value); }} fullWidth variant="standard" />
                                        </Col>
                                        <Col sm="4">
                                            <TextField label="Owner" id="standard-basic" value={owner} onChange={(e) => { setOwner(e.target.value); }} fullWidth variant="standard" />
                                        </Col>
                                        <Col sm="4">
                                            <Select
                                                placeholder="Animal Species"
                                                onChange={(selectedOption) => { setAnimalSpeices(selectedOption.value); }}
                                                options={animalSpeicesData.map((item) => ({ value: item.ID, label: item.Name }))}
                                            />
                                        </Col>

                                        <Col sm="4">
                                            <TextField label="Animal" id="standard-basic" value={animal} onChange={(e) => { setAnimal(e.target.value); }} fullWidth variant="standard" />
                                        </Col>
                                        <Col sm="4">
                                            <Select
                                                placeholder="Sample"
                                                onChange={(selectedOption) => { setSample(selectedOption.value); }}
                                                options={sampleList.map((item) => ({ value: item.id, label: item.name }))}
                                            />
                                        </Col>
                                        <Col sm="4">
                                            <Select
                                                placeholder="Test"
                                                onChange={(selectedOption) => { setTest(selectedOption.value); }}
                                                options={testList.map((item) => ({ value: item.id, label: item.name }))}
                                            />
                                        </Col>
                                        <Col sm="4">
                                            <Select
                                                placeholder="Unfit sample"
                                                onChange={(selectedOption) => { setUnfitSample(selectedOption.value); }}
                                                options={unfitSampleList.map((item) => ({ value: item.id, label: item.name }))}
                                            />
                                        </Col>
                                        <Col sm="4">
                                            <TextField label="Ref Cell No" id="standard-basic" value={refCellNo} onChange={(e) => { setRefCellNo(e.target.value); }} fullWidth variant="standard" />
                                        </Col>
                                        <Col sm="4">
                                            <Select
                                                placeholder="Tested By"
                                                onChange={(selectedOption) => { setTestedBy(selectedOption.value); }}
                                                options={testedByList.map((item) => ({ value: item.id, label: item.Name }))}
                                            />
                                        </Col>
                                    </>
                                )
                            }

                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Container>
                        <Row className="justify-content-end">
                            <Col xl="2">
                                <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }} onClick={() => { setmodaladd(true); resetFn(); }}>Add</button>
                            </Col>
                            {/* <Col xl="2">
                                <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }} onClick={Save}>Save</button>
                            </Col> */}
                            <Col xl="2">
                                <button className="btn-blue-Animal mt-1" onClick={() => setModalShow(false)} style={{ background: "#E16659" }}> Cancel </button>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Footer>
            </Modal>

            <Modal size="lg" aria-labelledby="contained-modal-title-vcenter" centered show={modaladd} onHide={() => setmodaladd(false)}>
                <Modal.Header style={{ background: "#DFDFDF" }}>
                    <Modal.Title id="contained-modal-title-vcenter">Add New Registration</Modal.Title>
                    <img src={closeButton} onClick={() => setmodaladd(false)} />
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Row className='align-items-end'>
                            <Col sm="4">
                                <TextField label="Tag ID No" id="standard-basic" value={tagIdNo} onChange={(e) => { handleTagIdNoChange(e, "tagIdNo") }} fullWidth variant="standard" />
                            </Col>
                            <>
                                <Col sm="4">
                                    <TextField label="Pat ID No" id="standard-basic" value={patId} onChange={(e) => { handleTagIdNoChange(e, "patId") }} fullWidth variant="standard" />
                                </Col>
                                <Col sm="4">
                                    <Select
                                        placeholder="Lab Name"
                                        onChange={(selectedOption) => { setLabName(selectedOption.value); }}
                                        options={labList.map((item) => ({ value: item.id, label: item.name }))}
                                    />
                                </Col>
                                <Col sm="4">
                                    <Select
                                        placeholder="Ref By"
                                        onChange={(selectedOption) => { setRefBy(selectedOption.value); }}
                                        options={refList.map((item) => ({ value: item.id, label: item.Name }))}
                                    />
                                </Col>
                                <Col sm="4">
                                    <Select
                                        placeholder="Ref Institute"
                                        onChange={(selectedOption) => { setRefInstitute(selectedOption.value); }}
                                        options={refInstituteList.map((item) => ({ value: item.id, label: item.name }))}
                                    />
                                </Col>
                                <Col sm="4">
                                    <div>
                                        <sub className="mb-0 pb-0">Date</sub>
                                        <input type="date" className="dateSelect_bg" id="date-1" value={date} onChange={(e) => { setDate(e.target.value); }} />
                                    </div>
                                    {/* <TextField label="Date" id="standard-basic" value={date} onChange={(e) => { setDate(e.target.value); }} fullWidth variant="standard" /> */}
                                </Col>
                                <Col sm="4">
                                    <Select
                                        placeholder="District"
                                        onChange={(selectedOption) => { setDistrict(selectedOption.value); }}
                                        options={districtList.map((item) => ({ value: item.DIST, label: item.DIST }))}
                                    />
                                </Col>
                                <Col sm="4">
                                    <Select
                                        placeholder="Taluka"
                                        onChange={(selectedOption) => { setTaluka(selectedOption.value); }}
                                        options={talukaList.map((item) => ({ value: item.id, label: item.taluka }))}
                                    />
                                </Col>
                                {/* <Col sm="4">
                                <Select
                                    placeholder="Village"
                                    onChange={(selectedOption) => { setVillage(selectedOption.value); }}
                                    options={villageList.map((item) => ({ value: item.ID, label: item.Name }))}
                                />
                            </Col> */}
                                <Col sm="4">
                                    <TextField label="Village" id="standard-basic" value={village} onChange={(e) => { handleAlphabetChange(e, "village") }} fullWidth variant="standard" />
                                </Col>
                                <Col sm="4">
                                    <TextField label="Owner" id="standard-basic" value={owner} onChange={(e) => { handleAlphabetChange(e, "owner") }} fullWidth variant="standard" />
                                </Col>
                                <Col sm="4">
                                    <Select
                                        placeholder="Animal Species"
                                        onChange={(selectedOption) => { setAnimalSpeices(selectedOption.value); }}
                                        options={animalSpeicesData.map((item) => ({ value: item.ID, label: item.Name }))}
                                    />
                                </Col>

                                <Col sm="4">
                                    <TextField label="Animal" id="standard-basic" value={animal} onChange={(e) => { handleAlphabetChange(e, "animal") }} fullWidth variant="standard" />
                                </Col>
                                <Col sm="4">
                                    <Select
                                        placeholder="Sample"
                                        onChange={(selectedOption) => { setSample(selectedOption.value); }}
                                        options={sampleList.map((item) => ({ value: item.id, label: item.name }))}
                                    />
                                </Col>
                                <Col sm="4">
                                    <Select
                                        placeholder="Test"
                                        onChange={(selectedOption) => { setTest(selectedOption.value); }}
                                        options={testList.map((item) => ({ value: item.id, label: item.name }))}
                                    />
                                </Col>
                                <Col sm="4">
                                    <Select
                                        placeholder="Unfit sample"
                                        onChange={(selectedOption) => { setUnfitSample(selectedOption.value); }}
                                        options={unfitSampleList.map((item) => ({ value: item.id, label: item.name }))}
                                    />
                                </Col>
                                <Col sm="4">
                                    <TextField label="Ref Cell No" id="standard-basic" value={refCellNo} onChange={(e) => { handleTagIdNoChange(e, "refCellNo") }} fullWidth variant="standard" />
                                </Col>
                                <Col sm="4">
                                    <Select
                                        placeholder="Tested By"
                                        onChange={(selectedOption) => { setTestedBy(selectedOption.value); }}
                                        options={testedByList.map((item) => ({ value: item.id, label: item.Name }))}
                                    />
                                </Col>
                            </>
                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Container>
                        <Row className="justify-content-end">
                            <Col xl="2">
                                <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }} onClick={() => { Save(); setmodaladd(false) }}>Save</button>
                            </Col>
                            <Col xl="2">
                                <button className="btn-blue-Animal mt-1" onClick={() => setmodaladd(false)} style={{ background: "#E16659" }}> Cancel </button>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Footer>
            </Modal>

            {show && <Offcanvas className="hide_Navbar" show={show} onHide={handleClose}><Container><Row><Col><Sidenav /></Col></Row></Container></Offcanvas>}

            {isLoading ?
                <div className="circleText">
                    <div className="progressText">{progressData}</div>
                    <div className="">
                        <span className="loader-circle-13"></span>
                    </div>
                </div>
                : false
            }

            <ToastContainer
                position="top-center"
                autoClose={2000}
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
                style={{ fontSize: "14px" }}
            />

        </>
    )
};
