import React, { useEffect } from "react";
import { useState } from "react";
import { Col, Container, Offcanvas, Row, Modal } from "react-bootstrap";
import Sidenav from "../../Componet/Sidenav/Sidenav";
import menu from "../../Photo/menu.svg";
import profile from "../../Photo/profile.svg";

import { STORAGE_KEY } from "../../constant/common";
import { API_URLS, serviceUrl } from "../../API/Api";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Switch,
} from "@mui/material";
import Input from "../../Componet/InputFild/Input";
import Table from "../../Componet/DataTable/Table";
import { MdOutlineVpnKey } from "react-icons/md";
import { GoSignOut } from "react-icons/go";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { downloadExcel } from "react-export-table-to-excel";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Searcable from "../../Componet/Dropdown/Searcable";
const {
  DoctorApi,
  MilkTestApi,
  AddAnimal,
  MilKPdCheck,
  BreedingDetailsApi,
  StaffApi,
} = API_URLS;
const added_by = localStorage.getItem(STORAGE_KEY.jwtTOKEN);

async function getRouteData(credentials) {
  return fetch(AddAnimal.RoutData, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${added_by}`,
    },
    body: JSON.stringify(credentials),
  }).then((res) => res.json());
}

async function getZone(credentials) {
  return fetch(DoctorApi.getZone, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${added_by}`,
    },
    body: JSON.stringify(credentials),
  }).then((res) => res.json());
}

async function getAllPdData(credentials) {
  return fetch(MilKPdCheck.getApi, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${added_by}`,
    },
    body: JSON.stringify(credentials),
  }).then((res) => res.json());
}

async function getStaffData(credentials) {
  return fetch(StaffApi.GetDetails, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: `Bearer ${added_by}`,
    },
    body: JSON.stringify(credentials),
  }).then((res) => res.json());
}

const MilkPdTest = () => {
  const label = { inputProps: { "aria-label": "Switch demo" } };
  const API_KEY = serviceUrl;
  const selectedToDate = new Date().toISOString().slice(0, 10);
  const [center, setCenter] = useState("");
  const [ait, setAit] = useState("");
  const [sort, setSort] = useState("");
  const [order, setOrder] = useState("");
  const [society, setSociety] = useState("");
  const [farmer, setFarmer] = useState("");
  const [route, setRoute] = useState("");
  const [search, setSearch] = useState("");
  const [progressData, setProgressData] = useState("0%");
  const [searchFilter, setSearchFilter] = useState("");
  const [Fat, setFat] = useState("");
  const [Snf, setSnf] = useState("");
  const [Lactose, setLactose] = useState();
  const [staff, setStaff] = useState("");
  const [PDResult, setPdResult] = useState();
  const navigate = useNavigate();
  const logOut = () => {
    navigate("/");
  };

  const [date, setDate] = useState(selectedToDate);

  const [isLoading, setIsLoading] = useState(false);
  const [heightOffSet, setHeight] = useState(0);
  const [milkPdData, setMilkPdData] = useState("");
  const [show, setShow] = useState(false);
  const [headerShow, setHeaderShow] = useState(true);
  const handleClose = () => {
    setShow(false);
  };
  const handleShow = () => {
    setHeaderShow(!headerShow);
  };

  const [doctorList, setDoctorList] = useState([]);
  const [centerList, setCenterList] = useState([]);
  const [trackingList, setTrackingList] = useState([]);
  const [routeList, setRouteList] = useState([]);
  const [farmerList, setFarmerList] = useState([]);
  const [societyList, setSocietyList] = useState([]);
  const [milkList, setMilkList] = useState([]);
  const [milkTestDate, setMilkTestDate] = useState([]);
  const [staffList, setStaffList] = useState([]);

  const getDoctorData = async () => {
    const res = await getZone();
    const res2 = await getRouteData();
    const res3 = await getStaffData();

    if (res) {
      setCenterList(res);
    }
    if (res2) {
      setRouteList(res2);
    }
    if (res3) {
      setStaffList(res3.data);
    }
  };

  const handleDownloadExcel = () => {
    const header = [];
    const opt = `Milk PD Test ${date}`;
    colum.map((e) => {
      header.push(e.name);
    });

    downloadExcel({
      fileName: opt,
      sheet: "data",
      tablePayload: {
        header,
        body: milkPdData,
      },
    });
  };

  // const fatchData = (e, e1, e2) => {
  //     setIsLoading(true)
  //     fetch(`${MilkTestApi.getAll}`, {
  //         method: 'POST',
  //         headers: {
  //             'Content-Type': 'application/json',
  //             "Accept": "application/json",
  //             "Authorization": `Bearer ${added_by}`,
  //         },
  //         body: JSON.stringify({ ahc: e, date, dcs: society, farmer: e2, route: e1 })
  //     })
  //         .then(response => {
  //             const contentLength = response.headers.get('content-length');
  //             let loaded = 0;
  //             return new Response(
  //                 new ReadableStream({
  //                     start(controller) {
  //                         const reader = response.body.getReader();
  //                         read();
  //                         function read() {
  //                             reader.read()
  //                                 .then((progressEvent) => {
  //                                     if (progressEvent.done) {
  //                                         controller.close();
  //                                         return;
  //                                     }
  //                                     loaded += progressEvent.value.byteLength;
  //                                     const percentageComplete = Math.round(loaded / contentLength * 100) + '%';
  //                                     setProgressData(percentageComplete)
  //                                     controller.enqueue(progressEvent.value);
  //                                     read();
  //                                 })
  //                         }
  //                     }
  //                 })
  //             );
  //         })
  //         .then((res) => res.json())
  //         .then((result) => { setMilkList(result); setIsLoading(false); setProgressData("0%"); })
  // }

  useEffect(() => {
    getDoctorData();
  }, []);

  const SearchFn = (e) => {
    const data = e.target.value;
    if (data !== "") {
      const results = milkPdData.filter((user) => {
        return (
          user["Tag ID"].toLowerCase().startsWith(data.toLowerCase()) ||
          user.QRCodeResult.toLowerCase().startsWith(data.toLowerCase())
        );
      });
      setSearchFilter(results);
    } else {
      setSearchFilter(milkPdData);
    }
    setSearch(data);
  };

  // const SearchQr = (code) => {
  //   const data = code;
  //   console.log(data);
  //   if (data !== "") {
  //     const results = milkPdData.filter((user) => {
  //       return user.QRCodeResult.toLowerCase().startsWith(data.toLowerCase());
  //     });
  //     setSearchFilter(results);
  //   } else {
  //     setSearchFilter(milkPdData);
  //   }
  //   setSearch(data);
  // };

  // const textHandle = (e) => {
  //     setFat(e.target.value)
  // }
  // const textHandle1 = (e) => {
  //     setLactose(e.target.value)
  // }
  // const textHandle2 = (e) => {
  //     setSnf(e.target.value)
  // }


  const switchHandle = (e, value, v) => {
    console.log(v)
    setPdResult(v);
  };

  const saveFn = async (e, value, v) => {
    console.log(e["PD Result"])
    if (milkTestDate === "" || milkTestDate === null) {
      toast.error("Please Select MilkTestDate")
    }
    else if (staff === "" || staff === null) {
      toast.error("Please Select Milktestedby")
    }
    else {
      const processedPDResult =
        e["PD Result"] === "EMPTY(NOT PREG)"
          ? 1
          : e["PD Result"] === "DOUBTFUL"
            ? 2
            : 3;
      await fetch(`${BreedingDetailsApi.saveMilkPdTest}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: `Bearer ${added_by}`,
        },
        body: JSON.stringify({
          Syncid: e.ID,
          PDResult: PDResult ? PDResult : processedPDResult,
          Milktestdate: milkTestDate,
          note: e["Note"],
          Milktestedby: staff.value,
          UID: 1,
        }),
      })
      toast.success("Data Save Successfully.")
        .then((res) => res.json())
        .then((result) => {
          toast.success("Data Save Successfully.");
        });
    }

  };

  const colum = [
    {
      name: "ID",
      cell: (e) => <p>{e["ID"]}</p>,
      width: "80px",
    },
    {
      name: "Tagid",
      selector: "Tagid",
      cell: (e) => <p>{e["Tag ID"]}</p>,
      width: "140px",
    },
    // {
    //     name: "Breeding ID",
    //     cell: (e) => (<p>{e["Breeding ID"]}</p>),
    //     width: "150px"
    // },
    {
      name: "Heat Date",
      cell: (e) => <p>{e["Heat Date"]}</p>,
      width: "170px",
    },
    // {
    //     name: "Milk Tested By",
    //     cell: (e) => (<p>{e["Milk Tested By"]}</p>),
    //     width: "80px"
    // },
    // {
    //     name: "Milk note",
    //     cell: (e) => (<p>{e["Milk Note"]}</p>),
    //     width: "80px"
    // },
    {
      name: "Note",
      selector: "Note",
      width: "80px",
    },
    {
      name: "QR Code Result",
      selector: "QRCodeResult",
      cell: (e) => <p>{e["QRCodeResult"]}</p>,
      width: "130px",
    },
    // {
    //     name: "SrNo",
    //     cell: (e) => (<p>{e["SrNo"]}</p>),
    //     width: "130px"
    // },
    {
      name: "Test Date",
      cell: (e) => <p>{e["Test Date"]}</p>,
      width: "130px",
    },
    // {
    //     name: "UID",
    //     cell: (e) => (<p>{e["UID"]}</p>),
    //     width: "130px"
    // },
    // {
    //     name: "breedsyncid",
    //     cell: (e) => (<p>{e["breedsyncid"]}</p>),
    //     width: "130px"
    // },
    // {
    //     name: "Milk Test Date",
    //     cell: (e) => (<p>{e["milk test date"]}</p>),
    //     width: "130px"
    // },
    // {
    //     name: "Milk Tested By",
    //     cell: (e) => (<p>{e["Milk Tested By"]}</p>),
    //     width: "130px"
    // },
    {
      name: "PD Result",
      cell: (d) => (
        <>
          <div className="psResult">
            <label>Empty</label>
            <Switch
              defaultChecked={d["PD Result"] === "EMPTY(NOT PREG)"}
              onChange={(e) => { switchHandle(e, d, 1);}} // Pass 1 for Empty
            />
          </div>
          <div>
            <label>Doubtful</label>
            <Switch
              defaultChecked={d["PD Result"] === "DOUBTFUL"}
              onChange={(e) => switchHandle(e, d, 2)} // Pass 2 for Default
            />
          </div>
          <div>
            <label>Pregnant</label>
            <Switch
              defaultChecked={d["PD Result"] === "PREGNANT"}
              onChange={(e) => switchHandle(e, d, 3)} // Pass 3 for Pregnant
            />
          </div>
        </>
      ),
      width: "300px",
    },
    {
      name: "Actions",
      cell: (e) => (
        <>
          <button className="saveMilk" onClick={() => saveFn(e)}>
            Save
          </button>
        </>
      ),
      width: "220px",
    },
  ];

  const View = async () => {
    setIsLoading(true)
    // const res1 = await getAllPdData({ date: date, route: route.value, Pending: 1 });
    // if (res1) {
    //   // console.log(res1);
    //   setMilkPdData(res1);
    // }

    await fetch(`${MilKPdCheck.getApi}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        "Accept": "application/json",
        "Authorization": `Bearer ${added_by}`,
      },
      body: JSON.stringify({ date: date, route: route.value, Pending: 1 })
    })
      .then(response => {
        const contentLength = response.headers.get('content-length');
        let loaded = 0;
        return new Response(
          new ReadableStream({
            start(controller) {
              const reader = response.body.getReader();
              read();
              function read() {
                reader.read()
                  .then((progressEvent) => {
                    if (progressEvent.done) {
                      controller.close();
                      return;
                    }
                    loaded += progressEvent.value.byteLength;
                    const percentageComplete = Math.round(loaded / contentLength * 100) + '%';
                    setProgressData(percentageComplete)
                    controller.enqueue(progressEvent.value);
                    read();
                  })
              }
            }
          })
        );
      })
      .then((res) => res.json())
      .then((result) => { setMilkPdData(result); setIsLoading(false); setProgressData("0%"); })
  };

  return (
    <>
      <Container fluid>
        <Row>
          {headerShow && (
            <Col lg="2" className="hideNavbar">
              {" "}
              <Sidenav />
            </Col>
          )}
          <Col className="header" lg={headerShow ? "10" : "12"}>
            <Container fluid>
              <Row className="pt-1 sub-header align-items-center justify-content-between">
                <Col
                  md="11"
                  sm="10"
                  xs="10"
                  className="py-2 d-flex align-items-center"
                >
                  <button
                    style={{ border: "none" }}
                    className="hideNavbar1"
                    onClick={handleShow}
                  >
                    <img src={menu} width={20} />
                  </button>
                  <button
                    style={{ border: "none" }}
                    className="hide_Navbar"
                    onClick={() => setShow(true)}
                  >
                    <img src={menu} width={20} />
                  </button>
                  <h5 className="ms-4 mt-2">
                    Lab Test /Milk PD Test
                  </h5>
                </Col>
                <Col md="1" sm="1" xs="2" className="text-end">
                  <img src={profile} width={40} />
                </Col>
              </Row>
              <Row className="align-items-end div-2" id="heightOffsetStock">
                <Col xs="3" sm="2" md="2" className="justify-content-start">
                  <FormControl variant="standard" fullWidth>
                    {/* <InputLabel>Route</InputLabel>
                    <Select
                      value={route}
                      onChange={(e) => setRoute(e.target.value)}
                    >
                      {routeList.map((e) => (
                        <MenuItem
                          className="drop_value"
                          key={e.Id}
                          value={e.ID}
                        >
                          {e.Name}
                        </MenuItem>
                      ))}
                    </Select> */}
                    <Searcable
                      placeholder="Route"
                      options={routeList}
                      onChange={(selectedOption) => setRoute(selectedOption)}
                      value={route}
                      labelKey="Name"
                      otherKey="ID"
                      valueKey="ID"
                    />
                  </FormControl>
                </Col>
                <Col xs="3" xxl="1" sm="2" md="2">
                  <sub className="mb-0 pb-0">Date</sub>
                  <input
                    type="date"
                    className="dateSelect_bg"
                    value={date}
                    onChange={(e) => {
                      setDate(e.target.value);
                    }}
                  />
                </Col>
                <Col xxl="1" sm="1">
                  <button className="btn-blue-Animal" onClick={View}>
                    View
                  </button>
                </Col>
                <Col
                  xs="3"
                  xxl="1"
                  sm="3"
                  md="2"
                  className="ms-auto align-items-center"
                >
                  <sub className="mb-0 pb-0">Milk Test Date</sub>
                  <input
                    type="date"
                    className="dateSelect_bg"
                    value={milkTestDate}
                    onChange={(e) => {
                      setMilkTestDate(e.target.value);
                    }}
                  />
                </Col>
                <Col xs="3" sm="2" md="2" className="justify-content-start">
                  <FormControl variant="standard" fullWidth>
                    {/* <InputLabel>Milk Tested By</InputLabel>
                    <Select
                      value={staff}
                      onChange={(e) => setStaff(e.target.value)}
                    >
                      {staffList.map((e) => (
                        <MenuItem
                          className="drop_value"
                          key={e.id}
                          value={e.id}
                        >
                          {e.Name}
                        </MenuItem>
                      ))}
                    </Select> */}
                    <Searcable
                      placeholder="Milk Tested By"
                      options={staffList}
                      onChange={(selectedOption) => setStaff(selectedOption)}
                      value={staff}
                      labelKey="Name"
                      valueKey="id"
                    />
                  </FormControl>
                </Col>
                <Col
                  md="3"
                  xxl="2"
                  xl="2"
                  xs="3"
                  className=" align-items-center"
                >
                  <Input
                    label="Search"
                    value={search}
                    onChange={(e) => SearchFn(e)}
                  />
                </Col>
                <Col xxl="1" sm="1">
                  <button
                    className="btn-blue-Animal mt-1 mb-1"
                    onClick={handleDownloadExcel}
                  >
                    Export
                  </button>
                </Col>
              </Row>
              {/* <Row className="mt-2 justify-content-center text-center">
                <Col className="mt-4">
                  <Table
                    columns={colum}
                    data={searchFilter ? searchFilter : milkPdData}
                    height={`calc(100vh - (${heightOffSet}px + 215px))`}
                    pagination
                  />
                </Col>
              </Row> */}
              <Row className="mt-2" id='heightOffsetStock'>
                {milkPdData.length > 0 ?
                  <div className="blue_bg DataTableBorder mt-1">
                    <Table columns={colum} data={searchFilter ? searchFilter : milkPdData} height={`calc(100vh - (${heightOffSet}px + 220px))`} pagination />
                  </div>
                  :
                  <Row>
                    <Col>
                      <div className="NoTableBorder ms-2" >
                        <div className="d-flex tabledata colmBreed">{
                          colum.map((i) => {
                            return (
                              <Col className={`blankColumns ${i.name.replace(' ', '_')}`} style={{ backgroundColor: "rgb(169,169,169)" }} height={`calc(100vh - (${heightOffSet}px + 20px))`} >{i.name}</Col>
                            )
                          })
                        }
                        </div>
                        <h6 className="text-center align-items-center mt-5 noData ms-5">No Data</h6>
                      </div>
                    </Col>
                  </Row>
                }
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
      {show && (
        <Offcanvas className="hide_Navbar" show={show} onHide={handleClose}>
          <Container>
            <Row>
              <Col>
                <Sidenav />
              </Col>
            </Row>
          </Container>
        </Offcanvas>
      )}
      {isLoading ? (
        <div className="circleText">
          <div className="progressText">{progressData}</div>
          <div className="">
            <span className="loader-circle-13"></span>
          </div>
        </div>
      ) : (
        false
      )}

      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        style={{ fontSize: "14px" }}
      />
    </>
  );
};

export default MilkPdTest;
