import React, { useEffect, useState } from "react";
import "./breedingdetails.css";
import { Col, Container, Row, Offcanvas, Modal } from "react-bootstrap";
import { FormControl, FormControlLabel, InputLabel, MenuItem, Radio, RadioGroup, TextField } from '@mui/material';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import remove from "../../Photo/master/remove.svg";
import edit from "../../Photo/master/edit.svg";

import Sidenav from "../../Componet/Sidenav/Sidenav";
import menu from '../../Photo/menu.svg'
import profile from '../../Photo/profile.svg';
import breeding from '../../Photo/breeding.svg';
import closeButton from "../../Photo/milk-union/close button.svg";
import Select from 'react-select';

import moment from "moment";
import Dropdown from "../../Componet/Dropdown/Dropdown";
import Table from "../../Componet/DataTable/Table";
import { STORAGE_KEY } from "../../constant/common";
import { API_URLS } from "../../API/Api";
import { useNavigate, useParams } from "react-router-dom";
import { MdOutlineVpnKey } from "react-icons/md";
import { GoSignOut } from "react-icons/go";
import Input from "../../Componet/InputFild/Input";
import dayjs from 'dayjs';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { BsTriangleFill } from "react-icons/bs";
import { BiChevronsRight } from "react-icons/bi";
import BackBtn from "../../Componet/Button/BackBtn";

const { BreedingDetailsApi, AddAnimal, Cattleregistration, DisposalApi } = API_URLS;


export default function BreedingDetails() {

    const added_by = localStorage.getItem(STORAGE_KEY.jwtTOKEN);

    async function getUserData(credentials) {
        return fetch(BreedingDetailsApi.fillGrid, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`,
            },
            body: JSON.stringify(credentials)
        })
            .then(data => data.json());
    }
    async function getLifeTimeData(credentials) {
        return fetch(BreedingDetailsApi.getLifeTimeProduction, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`,
            },
            body: JSON.stringify(credentials)
        })
            .then(data => data.json());
    }
    async function getDryoffData(credentials) {
        return fetch(BreedingDetailsApi.dryOff, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`,
            },
            body: JSON.stringify(credentials)
        })
            .then(data => data.json());
    }
    async function saveAiData(credentials) {
        return fetch(BreedingDetailsApi.saveAi, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`,
            },
            body: JSON.stringify(credentials)
        })
            .then(data => data.json());
    }
    async function calvingDataBreed(credentials) {
        return fetch(BreedingDetailsApi.calvingSaveparity, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`,
            },
            body: JSON.stringify(credentials)
        })
            .then(data => data.json());
    }
    async function getPdData(credentials) {
        return fetch(BreedingDetailsApi.saveParitySave, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`,
            },
            body: JSON.stringify(credentials)
        })
            .then(data => data.json());
    }
    async function getSireData(credentials) {
        return fetch(AddAnimal.SireData, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`,
            },
            body: JSON.stringify(credentials)
        })
            .then(data => data.json());
    }
    async function getStaffData(credentials) {
        return fetch(AddAnimal.StaffData, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`,
            },
            body: JSON.stringify(credentials)
        })
            .then(data => data.json());
    }
    async function getTreeData(credentials) {
        return fetch(Cattleregistration.TreeByArea, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`,
            },
            body: JSON.stringify(credentials)
        })
            .then(data => data.json());
    }
    async function calfDataSave(credentials) {
        return fetch(BreedingDetailsApi.calfSave, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`,
            },
            body: JSON.stringify(credentials)
        })
            .then(data => data.json());
    }
    async function dryReasonData(credentials) {
        return fetch(BreedingDetailsApi.dryResonsApi, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`,
            },
            body: JSON.stringify(credentials)
        })
            .then(data => data.json());
    }
    async function EditAI(credentials) {
        return fetch(BreedingDetailsApi.editAi, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`,
            },
            body: JSON.stringify(credentials)
        })
            .then(data => data.json());
    }

    const TagId = localStorage.getItem("Fld") && localStorage.getItem("Fld")
    const Parity = localStorage.getItem("Parity")
    const HerdId = localStorage.getItem("HerdId")
    const LotId = localStorage.getItem("LotId")
    const FarmerId = localStorage.getItem("FarmerId")

    const lastWeek = new Date();
    const selectedToDate = new Date().toISOString().slice(0, 10);
    const navigate = useNavigate()

    const [idNo, setIdNo] = useState(TagId)
    const [parity, setParity] = useState(Parity)
    const [search, setSearch] = useState("")
    const [dateOfDry, setDateOfDry] = useState("")
    const [heatDate, setHeatDate] = useState("")
    const [dryTreatment, setDryTreatment] = useState("1")
    const [dryReason, setDryReason] = useState("")
    const [sire, setSire] = useState("")
    const [service, setService] = useState("")
    const [editId, setEditId] = useState("")
    const [inseminator, setInseminator] = useState("")
    const [aiDate, setAiDate] = useState("")
    const [Dose, setDose] = useState("")
    const [pdDate, setPdDate] = useState("")
    const [pdDone, setPdDone] = useState("")
    const [pdResult, setPdResult] = useState("")
    const [calvingDate, setCalvingDate] = useState(selectedToDate)
    const [calvingType, setCalvingType] = useState("")
    const [calfSex, setCalfSex] = useState("")
    const [calfId, setCalfId] = useState("")
    const [reproductionProblemNote, setReproductionProblemNote] = useState("")
    const [AIT, setAIT] = useState("")
    const [herdCalvin, setHerdCalvin] = useState("")
    const [progressData, setProgressData] = useState("0%");
    const [userLastData, setUserLastData] = useState("")
    const [lotCalvin, setLotCalvin] = useState("")
    const [farmerCalvin, setFarmerCalvin] = useState("")
    const [calfId1, setCalfId1] = useState("")
    const [parityIdNo, setParityIdNo] = useState(Parity)
    const [index, setIndex] = useState(1)
    const [calfSex2, setCalfSex2] = useState("")
    const [herdName, setHerdName] = useState("")
    const [lotName, setLotName] = useState("")
    const [farmerName, setFarmerName] = useState("")
    const [herdSelectId, setHerdSelectId] = useState("")
    const [lotSelectId, setLotSelectId] = useState("")
    const [farmerSelectId, setFarmerSelectId] = useState("")
    const [deleteId, setDeleteId] = useState("")
    const [pdResultId, setPdResultId] = useState("")
    const [sireId, setSireId] = useState("")
    const [tableParity, setTableParity] = useState("")

    const [heat, setHeat] = useState(true);
    const [calving, setCalving] = useState(false);
    const [dry, setDry] = useState(false);
    const [modalShow, setModalShow] = useState(false);
    const [deleteShow, setDeleteShow] = useState(false);
    const [pdModalShow, setPdModalShow] = useState(false);
    const [editModalShow, setEditModalShow] = useState("")
    const [refFnAct, setRefFnAct] = useState(false)

    const [heightOffSet, setHeight] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [show, setShow] = useState(false);
    const [headerShow, setHeaderShow] = useState(true);
    const handleClose = () => { setShow(false); };
    const handleShow = () => { setHeaderShow(!headerShow); }
    const [update, setUpdate] = useState(false)
    const [aiSave, setAiSave] = useState(true)
    const [parityStatus, setParityStatus] = useState(false)

    const [userDataList, setUserDataList] = useState([])
    const [lifeDataList, setLifeDataList] = useState([])
    const [pdDataList, setPdDataList] = useState([])
    const [serviceList, setServiceList] = useState([])
    const [sireList, setSireList] = useState([])
    const [inseminatorList, setInseminatorList] = useState([])
    const [columnData, setColumnData] = useState([])
    const [dataList, setDataList] = useState([])
    const [disposalType, setDisposalType] = useState([])
    const [dryReasonList, setDryReasonList] = useState([])
    const [calfSexList, setCalfSexList] = useState([
        { value: "1", label: "Male" },
        { value: "2", label: "FeMale" }
    ])


    const menuitm = ["1", "2", "3"]

    const manualOptions = [
        { value: '1', label: 'A.I' },
        { value: '2', label: 'NATURAL' },
        { value: '3', label: 'SKIP' },
        { value: '4', label: 'E.T. (Embryo Transfer)' },
        { value: '5', label: 'Natural and A.I' },
    ];
    const doseOption = [
        { value: '1', label: '1' },
        { value: '2', label: '2' },
    ];
    const pdResultList = [
        { value: '1', label: 'EMPTY' },
        { value: '2', label: 'DOUBTFUL' },
        { value: '3', label: 'PREGNANT' },
        { value: '4', label: 'PDRESULTLIST' },
    ];

    const logOut = () => { navigate("/") }

    const custMe = () => {
        setHeat(true);
        setCalving(false);
        setDry(false);
    }
    const sum = () => {
        setCalving(true);
        setHeat(false);
        setDry(false);
    }
    const sum_ = () => {
        setDry(true);
        setHeat(false);
        setCalving(false);
    }

    const parirtSelectFn = async (e) => {
        console.log(e)
        setCalvingDate(e["Calving Date"])
        setParityStatus(true)
        setDateOfDry(e["Dry Date"])
        setTableParity(e.parity)
        await fetch(BreedingDetailsApi.fillGrid, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`,
            },
            body: JSON.stringify({ parity: e.parity, tagId: idNo })
        })
            .then(response => {
                const contentLength = response.headers.get('content-length');
                let loaded = 0;
                return new Response(
                    new ReadableStream({
                        start(controller) {
                            const reader = response.body.getReader();
                            read();
                            function read() {
                                reader.read()
                                    .then((progressEvent) => {
                                        if (progressEvent.done) {
                                            controller.close();
                                            return;
                                        }
                                        loaded += progressEvent.value.byteLength;
                                        const percentageComplete = Math.round(loaded / contentLength * 100) + '%';
                                        setProgressData(percentageComplete)
                                        controller.enqueue(progressEvent.value);
                                        read();
                                    })
                            }
                        }
                    })
                );
            })
            .then(data => data.json())
            .then((result) => {
                setUserDataList(result);
                setIsLoading(false);
                setProgressData("0%");
            })
    }

    const deleteFn = async () => {
        await fetch(`${BreedingDetailsApi.deleteTagId}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`,
            },
            body: JSON.stringify({ id: deleteId })
        })
            .then(data => data.json())
            .then((result) => {
                setDeleteShow(!deleteShow)
                setIsLoading(false);
                setProgressData("0%");
                getData()
            })
    };
    const editFn = (e) => {
        setEditId(e.ID);
        setEditModalShow(true);
        setAiDate(dayjs(e["A.IDate"]));
        setService(e.Service);
        setInseminator(e.Inseminator);
        // setSire(e.Sire);
        setDose(e.Dose);
        setPdDone(e["PD Done"])
        // setPdResult(e["PD2 Result"])
        setPdDate(dayjs(e["PD Date"]))

        const selectedSireLabel = e["Sire"];
        const selectedSireValue = sireList.find(item => item.Name === selectedSireLabel)?.id;

        setSire(e.Sire);
        setSireId(selectedSireValue);

        const selectedPdResultLabel = e["PD2 Result"];
        const selectedPdResultValue = pdResultList.find(item => item.label === selectedPdResultLabel)?.value;
        setPdResult(e["PD2 Result"]);
        setPdResultId(selectedPdResultValue)
    };

    const deleteItem = (e) => {
        setDeleteId(e.ID)
        setDeleteShow(!deleteShow)
    }
    const column = [
        {
            name: "No.",
            selector: "Sl No",
            width: "55px"
        },
        {
            name: "A.I Date",
            selector: "A.IDate",
            cell: (e) => (<p className='m-0'>{dayjs(e["A.IDate"]).format("DD-MM-YYYY")}</p>),
            width: "120px"
        },
        {
            name: "Service",
            selector: "Service",
            width: "100px"
        },
        {
            name: "Insemniator",
            selector: "Inseminator",
            width: "180px"
        },
        {
            name: "Sire",
            selector: "Sire",
            width: "180px"
        },
        {
            name: "Dose",
            selector: "Dose",
            width: "70px"
        },
        {
            name: "PD Date",
            selector: "PD Date",
            cell: (e) => {
                const formattedDate = moment(e["PD Date"]).format("DD-MM-YYYY");
                return (
                    <p className='m-0'>
                        {moment(e["PD Date"]).isValid() ? formattedDate : ""}
                    </p>
                );
            },
            width: "112px"
        },
        {
            name: "PD Done",
            selector: "PD Done",
            width: "140px"
        },
        {
            name: "PD2 Result",
            selector: "PD2 Result",
            width: "140px"
        },
        {
            name: "PD Result",
            selector: "PD Result",
            width: "100px"
        },
        {
            name: "H.I",
            selector: "HI",
            width: "55px"
        },
        {
            name: "Action",
            cell: (e) => (<div><img src={remove} className="ms-2" width={18} onClick={() => deleteItem(e)} /><img src={edit} className="ms-2" width={18} onClick={() => editFn(e)} /></div>),
            width: "120px",
        },

    ]


    const column2 = [
        {
            name: "No.",
            selector: "",
            width: "30px"
        },
        {
            name: "Treatment",
            cell: (e) => <Dropdown label="" menuList={menuitm} placeholder="Heat Treaement" className="mt-2" />
        },
        {
            name: "Observation.",
            selector: row => row.Observation,
        },
    ]

    const column3 = [
        {
            name: "Parity",
            selector: "parity",
            cell: (e) => <div className='align-items-center d-flex rightArrow' onClick={() => parirtSelectFn(e)} style={{ cursor: 'pointer' }}><BiChevronsRight size="25" color="green" className="me-2" /><div>{e.parity}</div></div>,
            width: "100px",
        },
        {
            name: "Calving Date",
            selector: "Calving Date",
            width: "111px",
        },
        {
            name: "Sire",
            selector: "Sire",
            cell: (e) => <div className='ms-auto'>{e["Sire"]}</div>,
            width: "180px",
        },
        {
            name: "Heat Seq",
            selector: "Heat Seq",
            cell: (e) => <div className='ms-auto'>{e["Heat Seq"]}</div>,
            width: "70px",
        },
        {
            name: "Calving Type",
            selector: "Calving Type",
            width: "115px",
        },
        {
            name: "Dry Date",
            selector: "Dry Date",
            width: "120px",
        },
        {
            name: "SP",
            selector: "SP",
            cell: (e) => <div className='ms-auto'>{e["SP"]}</div>,
            width: "80px",
        },
        {
            name: "CC",
            selector: "CC",
            cell: (e) => <div className='ms-auto'>{e["CC"]}</div>,
            width: "70px",
        },
        {
            name: "DP",
            selector: "DP",
            cell: (e) => <div className='ms-auto'>{e["DP"]}</div>,
            width: "70px",
        },
        {
            name: "CI",
            selector: "CI",
            cell: (e) => <div className='ms-auto'>{e["CI"]}</div>,
            width: "70px",
        },
        {
            name: "Avg Yield",
            selector: "Avg Yield",
            cell: (e) => <div className='ms-auto'>{e["Avg Yield"]}</div>,
            width: "90px",
        },

        {
            name: "MI",
            selector: "MI",
            cell: (e) => <div className='ms-auto'>{e["MI"]}</div>,
            width: "70px",
        },
        {
            name: "Milk Yield",
            selector: "Milk Yield",
            cell: (e) => <div className='ms-auto'>{e["Milk Yield"]}</div>,
            width: "100px",
        },
        {
            name: "Peak Days",
            selector: "Peak Days",
            cell: (e) => <div className='ms-auto'>{e["Peak Days"]}</div>,
            width: "100px",
        },
        {
            name: "Peak Yield",
            selector: "Peak Yield",
            cell: (e) => <div className='ms-auto'>{e["Peak Yield"]}</div>,
            width: "100px",
        },
        {
            name: "Action",
            cell: (e) => (<div><img src={remove} className="ms-2" width={18} onClick={() => deleteItem(e)} /><img src={edit} className="ms-2" width={18} onClick={() => editFn(e)} /></div>),
            width: "120px",
        },

    ]

    const subDetails = async (e) => {
        await fetch(`${Cattleregistration.AnimalData}?id=` + e, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`
            },
        })
            .then(res => res.json())
            .then((result) => {
                setHerdName(result.animalData.herdName)
                setHerdSelectId(result.animalData.herdId)
                setLotSelectId(result.animalData.lotId)
                setFarmerSelectId(result.animalData.farmerId)
                setFarmerName(result.animalData.farmerName)
                setLotName(result.animalData.lotName)
                const lastParity = result.parityFilter[result.parityFilter.length - 1].parity;
                setHerdCalvin(result.animalData.herdId)
                setLotCalvin(result.animalData.lotId)
                setFarmerCalvin(result.animalData.farmerId)
                setParityIdNo(lastParity)
            });

    }

    const selectIdNo = async (e) => {
        subDetails(e.label)
        setIdNo(e.label)
        setIsLoading(true)
        const res1 = await getLifeTimeData({ detailsId: e.label })
        const res3 = await getSireData()
        const res4 = await getStaffData()

        if (res1) { setLifeDataList(res1.reproduction); setIsLoading(false) }
        if (res3) { setSireList(res3); setIsLoading(false) }
        if (res4) { setInseminatorList(res4); setIsLoading(false) }

        await fetch(Cattleregistration.AnimalGet, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`,
            },
            body: JSON.stringify({ tagId: e.label })
        })
            .then(response => {
                const contentLength = response.headers.get('content-length');
                let loaded = 0;
                return new Response(
                    new ReadableStream({
                        start(controller) {
                            const reader = response.body.getReader();
                            read();
                            function read() {
                                reader.read()
                                    .then((progressEvent) => {
                                        if (progressEvent.done) {
                                            controller.close();
                                            return;
                                        }
                                        loaded += progressEvent.value.byteLength;
                                        const percentageComplete = Math.round(loaded / contentLength * 100) + '%';
                                        setProgressData(percentageComplete)
                                        controller.enqueue(progressEvent.value);
                                        read();
                                    })
                            }
                        }
                    })
                );
            })
            .then(res => res.json())
            .then((result) => {
                setParity(result[13]["Fld"]); setIsLoading(false); setProgressData("0%");
            })


        await fetch(BreedingDetailsApi.fillGrid, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`,
            },
            body: JSON.stringify({ parity: parity, tagId: e.label })
        })
            .then(response => {
                const contentLength = response.headers.get('content-length');
                let loaded = 0;
                return new Response(
                    new ReadableStream({
                        start(controller) {
                            const reader = response.body.getReader();
                            read();
                            function read() {
                                reader.read()
                                    .then((progressEvent) => {
                                        if (progressEvent.done) {
                                            controller.close();
                                            return;
                                        }
                                        loaded += progressEvent.value.byteLength;
                                        const percentageComplete = Math.round(loaded / contentLength * 100) + '%';
                                        setProgressData(percentageComplete)
                                        controller.enqueue(progressEvent.value);
                                        read();
                                    })
                            }
                        }
                    })
                );
            })
            .then(data => data.json())
            .then((result) => {
                setUserDataList(result);
                // let lastRecord = result.slice(-1)[0];
                // let pregnantValue = lastRecord["PD Result"];
                // if (pregnantValue.includes("PREGNANT")) {
                //     setAiSave(false)
                // }
                // else {
                //     setAiSave(true)
                // }
                setIsLoading(false);
                setProgressData("0%");
            })
    }

    const animalDataGet = async () => {
        await fetch(Cattleregistration.AnimalGet, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`,
            },
            body: JSON.stringify({ tagId: TagId ? TagId : idNo })
        })
            .then(response => {
                const contentLength = response.headers.get('content-length');
                let loaded = 0;
                return new Response(
                    new ReadableStream({
                        start(controller) {
                            const reader = response.body.getReader();
                            read();
                            function read() {
                                reader.read()
                                    .then((progressEvent) => {
                                        if (progressEvent.done) {
                                            controller.close();
                                            return;
                                        }
                                        loaded += progressEvent.value.byteLength;
                                        const percentageComplete = Math.round(loaded / contentLength * 100) + '%';
                                        setProgressData(percentageComplete)
                                        controller.enqueue(progressEvent.value);
                                        read();
                                    })
                            }
                        }
                    })
                );
            })
            .then(res => res.json())
            .then((result) => {
                setParity(result[13]["Fld"]); setIsLoading(false); setProgressData("0%");
            })
    }


    const getData = async () => {
        setParityStatus(false);
        animalDataGet();

        const res3 = await getSireData();
        const res4 = await getStaffData();
        const res = await getUserData({ parity: parity ? parity : Parity, tagId: idNo ? idNo : TagId });
        const res1 = await getLifeTimeData({ detailsId: TagId ? TagId : idNo });
        const res5 = await dryReasonData();

        if (res3) { setSireList(res3); }
        if (res4) { setInseminatorList(res4); }
        if (res) { setUserDataList(res); }
        if (res1) { setLifeDataList(res1.reproduction); }
        if (res5) { setDryReasonList(res5.master_dryOffReason); }
    };


    const getRefreshAPi = async () => {
        setRefFnAct(true)
        setTableParity("")
        setParityStatus(false)
        const res3 = await getSireData()
        const res4 = await getStaffData()
        const res = await getUserData({ parity: parity, tagId: idNo ? idNo : TagId })
        const res1 = await getLifeTimeData({ detailsId: TagId ? TagId : idNo })
        const res5 = await dryReasonData()

        if (res3) { setSireList(res3) }
        if (res4) { setInseminatorList(res4) }
        if (res) { setUserDataList(res); }
        if (res1) { setLifeDataList(res1.reproduction) }
        if (res5) { setDryReasonList(res5.master_dryOffReason) }
    }

    const lastRecord = userDataList[userDataList.length - 1];
    const isLastRecordPregnant = lastRecord && lastRecord["PD2 Result"] === "PREGNANT";


    const getDataById = async () => {
        const res = await getTreeData({ Herdcode: "1", Herdtype: "Animal", userCode: "" })
        if (res) { setDataList(res) }
    }


    useEffect(() => {
        getData()
        let divHeight = document.getElementById("heightOffsetStock")?.offsetHeight;
        setHeight(divHeight);
        getDataById()
        setIdNo(TagId ? TagId : idNo)
    }, [parity])

    const dryOff = async () => {
        if (idNo === null) {
            toast.error("Please Select TagId")
        }
        if (dateOfDry === "") {
            toast.error("Please Select Dry Date")
        }
        else {
            const res2 = await getDryoffData({ dateOfDry, dryReason, dryTreatment: dryTreatment, heatDate, tagId: TagId ? TagId : idNo })
            if (res2) { animalDataGet(); custMe(); refFn(); getData(); toast.success("Dryoff  Successful"); }
        }
    }

    const Add = () => {
        setModalShow(true)
        setAiDate("")
        setService("")
        setInseminator("")
        setSire("")
        setDose("")
        setPdDone("")
        setPdResult("")
    }
    const pdUpdate = () => {
        setPdModalShow(true)
        setAiDate("")
        setService("")
        setInseminator("")
        setSire("")
        setDose("")
        setPdDone("")
        setPdResult("")
    }
    const refFn = () => {
        setCalvingType("")
        setCalfSex("")
        setCalfId("")
    }
    const refFn1 = () => {
        getRefreshAPi()
    }

    const AddData = async () => {
        const adjustedDate = new Date(aiDate);
        adjustedDate.setHours(adjustedDate.getHours() - 6);
        adjustedDate.setDate(adjustedDate.getDate() + 1);
        const formattedDate = adjustedDate.toISOString();

        const res = await saveAiData({ service, AIT: inseminator, sire: sire, HeatDate: formattedDate, createdByUser: "1", TagId: TagId ? TagId : idNo, TotalAIDose: Dose, InseminationTicketNumber: "0", Lat: "0", Long: "0", herd: HerdId ? HerdId : herdSelectId, lot: LotId, farmer: FarmerId, StrawImage: "0", SortedSemon: "0", BatchNo: "0", ENTRY: "0" })
        if (res) { refFn(); getData(); toast.success("Add A.I Successful"); setModalShow(false) }
    }

    const EditData = async () => {
        try {
            const pdDateFormate = dayjs(pdDate).format('DD-MM-YYYY');

            const isNumericPdResult = !isNaN(pdResult);
            const pd2Value = isNumericPdResult ? pdResult : pdResultId;

            const isNumericPdResult1 = !isNaN(sire);
            const sireValue = isNumericPdResult1 ? sire : sireId;

            let formattedDate = null;

            if (pdDate && !isNaN(new Date(pdDate))) {
                console.log("pd date cmp");
                const adjustedDate = new Date(pdDate);
                adjustedDate.setHours(adjustedDate.getHours() - 6);
                adjustedDate.setDate(adjustedDate.getDate() + 1);
                formattedDate = adjustedDate.toISOString();
            }

            const res = await EditAI({
                id: editId,
                HeatDate: aiDate,
                Sire: sireId,
                AIDone: "2",
                Dose: Dose,
                PDDoneby: formattedDate ? "2" : null,
                PDDate: formattedDate,
                PD2: pdResultId
            });

            if (res) {
                refFn();
                getData();
                toast.success("Edit Record Successful");
                setEditModalShow(false);
            }
        } catch (error) {
            console.error(error);
        }
    }


    const updatePd = async () => {
        const res1 = await getPdData({ Parity: parity, PDDate: pdDate, Result: pdResult, ENTRY: pdDone, AIT: pdDone, createdByUser: "1", lastUpdatedByUser: "1", TagId: TagId ? TagId : idNo, Lat: "0", Long: "0", herd: 1, lot: 1, farmer: 1, createdAt: selectedToDate, updatedAt: selectedToDate })
        if (res1) { refFn(); getData(); toast.success("P.D Update  Successful"); setPdModalShow(false) }
    }
    const problem = (e) => {

    }

    const paritySelect = (e) => {
        if (e.target.value.length <= 2) {
            setParity(e.target.value)
        }
    }

    const saveBreeding = async () => {
        if (calvingDate !== "" && calvingType !== "") {
            const res = await calvingDataBreed({ Parity: tableParity ? tableParity : parity, TagId: TagId ? TagId : idNo, CalvingDate: calvingDate, CalvingType: calvingType, CalfSex: calfSex ? calfSex : 0, ReproductionProblemNote: "", Comments: "", ENTRY: "1", Lat: 1, Long: 1, Sex: 1, calvingTypeOption: 1, staff: 1, createdByUser: 1, herd: HerdId !== "" ? HerdId : herdCalvin, lot: LotId !== "" ? LotId : lotCalvin, farmer: FarmerId !== "" ? FarmerId : farmerCalvin, CalfID: "", Calf2Sex: 1, CalfID: calfId !== "" ? calfId : "0", Calf2ID: calfId1 !== "" ? calfId1 : "0", image1: "", image2: "", CalvingTicketNumber: 1, createdAt: selectedToDate })
            if (res) { setHeat(true); setCalving(false); setDry(false); refFn(); getData(); toast.success("Calving Add Successful"); }
        }
    }

    const handleDateChange = (e) => {
        const selectedDate = e.$d;
        setAiDate(e.$d);
    };

    const formatDate = (date) => {
        const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
        return date.toLocaleDateString('en-IN', options).replace(/\//g, '-');
    };

    const handleDateChangePdDate = (e) => {
        const selectedDate = e.$d;
        setPdDate(e.$d);
    };

    console.log(sireList)
    console.log(inseminatorList)

    return (
        <>
            <Container fluid id="breed">
                <Row >
                    {headerShow && (
                        <Col lg="2" className="hideNavbar">
                            <Sidenav />
                        </Col>
                    )}
                    <Col lg={headerShow ? "10" : "12"} className="header">
                        <Container fluid>
                            <Row className="pt-1 sub-header align-items-center justify-content-between">
                                <Col className="py-2 d-flex align-items-center">
                                    <button style={{ border: "none" }} className="hideNavbar1" onClick={handleShow}><img src={menu} width={20} /></button>
                                    <button style={{ border: "none" }} className="hide_Navbar" onClick={() => setShow(true)}><img src={menu} width={20} /></button>
                                    <h5 className="ms-4 mt-2">Cattle Details / Breeding Details</h5>
                                </Col>
                                <Col md="1" sm="1" xs="10" className="py-2 d-flex align-items-center text-end ms-auto">
                                    <BackBtn style={{ border: "none" }} className="hideNavbar1" lable="Back" onClick={() => { navigate('/') }} />
                                </Col>
                                <Col md="1" sm="1" xs="2" className="text-end profile_dropdown">
                                    <img src={profile} width={40} />
                                    <div className="dropdpwn_Open">
                                        <Row className="text-center align-items-center">
                                            <Col xs="12" className="menuHover mb-3">
                                                <Row >
                                                    <Col className="" xs="3">
                                                        <span className=""><MdOutlineVpnKey size={25} />
                                                        </span>
                                                    </Col>
                                                    <Col className="text-start" xs="9">
                                                        Change Password
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col xs="12" className="menuHover" onClick={logOut}>
                                                <Row>
                                                    <Col className="" xs="3">
                                                        <span className=""><GoSignOut size={25} />
                                                        </span>
                                                    </Col>
                                                    <Col className="text-start" xs="9">
                                                        Sign Out
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            </Row>
                            <Row className='pt-1 sub-header1 align-items-center justify-content-between' style={{ background: "white" }}>
                                <Col xl="10" className="d-flex justify-content-start">
                                    <div className="ms-2 mt-2">
                                        <img src={breeding} width={40} />
                                    </div>
                                    <div className="ms-4">
                                        <h5 className="mb-0">Breeding Details</h5>
                                        <p className="mb-1 mt-1">Individual animal Al and PD entry: In case you want to enter Al and subsequently PD of the individual animal can be done by selecting animals it can be done by selecting animals and file all information.</p>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="align-items-center justify-content-start mb-2 mt-2">
                                <Col xl="2" md="3" sm="4" xs="12" className="align-items-center">
                                    <Select
                                        placeholder={TagId ? TagId : "Tag Id"}
                                        onChange={(selectedOption) => {
                                            selectIdNo(selectedOption);
                                        }}
                                        options={dataList.map((item) => ({
                                            value: item.Animalid,
                                            label: item.Animal,
                                        }))}
                                    />
                                </Col>
                                <Col xl="2" md="2" sm="4" xs="12" className="align-items-center">
                                    {/* <TextField label="Parity" placeholder="01" id="standard-basic" fullWidth variant="standard" value={parity} onChange={(e) => paritySelect(e)} /> */}
                                    <div className="d-flex ms-0 align-items-center idNo par">
                                        <h6 className="mb-0">Parity.:-</h6>
                                        <input type="text" value={parityStatus === true ? tableParity : (parity === "null" ? "1" : parity)} disabled onChange={(e) => { setParity(e.target.value) }} />
                                    </div>
                                </Col>
                                <Col xl="2" md="2" sm="4" xs="12" className="align-items-center">
                                    {/* <TextField label="Parity" placeholder="01" id="standard-basic" fullWidth variant="standard" value={parity} onChange={(e) => paritySelect(e)} /> */}
                                    <div className="d-flex ms-0 align-items-center idNo">
                                        <h6 className="mb-0">Herd.:-</h6>
                                        <input type="text" value={herdName !== "" ? herdName : HerdId} disabled onChange={(e) => { setHerdName(e.target.value) }} />
                                    </div>
                                </Col>
                                <Col xl="2" md="2" sm="4" xs="12" className="align-items-center">
                                    {/* <TextField label="Parity" placeholder="01" id="standard-basic" fullWidth variant="standard" value={parity} onChange={(e) => paritySelect(e)} /> */}
                                    <div className="d-flex ms-0 align-items-center idNo">
                                        <h6 className="mb-0">Lot.:-</h6>
                                        <input type="text" value={lotName !== "" ? lotName : LotId} disabled onChange={(e) => { setLotName(e.target.value) }} className="ms-1" />
                                    </div>
                                </Col>
                                <Col xl="2" md="3" sm="4" xs="12" className="align-items-center">
                                    {/* <TextField label="Parity" placeholder="01" id="standard-basic" fullWidth variant="standard" value={parity} onChange={(e) => paritySelect(e)} /> */}
                                    <div className="d-flex ms-0 align-items-center idNo">
                                        <h6 className="mb-0">Farmer.:-</h6>
                                        <input type="text" value={farmerName !== "" ? farmerName : FarmerId} disabledonChange={(e) => { setFarmerName(e.target.value) }} />
                                    </div>
                                </Col>
                                <Col xl="2" md="3" sm="4" xs="12" className="ms-auto">
                                    <Input label="Search" value={search} onChange={e => setSearch(e.target.value)} />
                                </Col>
                            </Row>
                            <Row className="align-items-center ">
                                <Col xs="3" lg="2" xxl="1" className="">
                                    <button onClick={custMe} className="btn-blue">Heat & P.D Details</button>
                                </Col>
                                <Col xs="3" lg="2" xxl="1">
                                    <button onClick={sum} className="btn-yellow">Calving Details</button>
                                </Col>
                                <Col xs="3" lg="2" xxl="1">
                                    <button onClick={sum_} className="btn-perot">Dry Details</button>
                                </Col>
                            </Row>
                            {heat && (
                                <>
                                    <Row className="justify-content-between">
                                        <Col xl="8" lg="8" xxl="9" id="aiTable">
                                            {userDataList.length ?
                                                <div className="blue_bg tableBorder1 mt-1">
                                                    <Table columns={column} data={userDataList} />
                                                </div>
                                                :
                                                <Row className="align-items-center mt-1" >
                                                    <Col>
                                                        <div className="tableBorder tableBorder1 ms-2">
                                                            <div className="d-flex colmBreed">{
                                                                column.map((i) => {
                                                                    return (
                                                                        <Col className={`blankColumns ${i.name.replace(' ', '_')}`} style={{ backgroundColor: "rgb(169,169,169)" }}>{i.name}</Col>
                                                                    )
                                                                })
                                                            }
                                                            </div>
                                                            <h6 className="text-center mt-4 noData">No Data</h6>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            }
                                        </Col>
                                        <Col xl="4" lg="4" xxl="3" className="ms-auto" id="aiTable1">
                                            {columnData.length ?
                                                <div className="blue_bg tableBorder1 mt-1">
                                                    <Table columns={column2} data={columnData} />
                                                </div>
                                                :
                                                <Row className="align-items-center gx-2 mt-1" >
                                                    <div className="tableBorder">
                                                        <div className="d-flex colmBreed">{
                                                            column2.map((i) => {
                                                                return (
                                                                    <Col className={`blankColumns ${i.name.replace(' ', '_')}`} style={{ backgroundColor: "rgb(169,169,169)", width: "10px" }}>{i.name}</Col>
                                                                )
                                                            })
                                                        }
                                                        </div>
                                                        <h6 className="text-center mt-4 noData">No Data</h6>
                                                    </div>
                                                </Row>
                                            }
                                        </Col>
                                    </Row>
                                </>
                            )}
                            {
                                heat && <Row className="mt-2">
                                    {/* {
                                        aiSave == true ? <> <Col xs="2" xl="1" className="mt-1">
                                            <button onClick={Add} className="btn-blue-Animal">Add A.I</button>
                                        </Col>
                                            <Col xl="1" xs="2" className="mt-1">
                                                <button onClick={pdUpdate} className="btn-blue-Animal">Update P.D</button>
                                            </Col></> : <Col xl="1" xs="2" className="mt-1">
                                            <button onClick={pdUpdate} className="btn-blue-Animal">Update P.D</button>
                                        </Col>
                                    } */}
                                    {
                                        isLastRecordPregnant ? <Col xl="2" xxl="1" xs="2" lg="2" className="mt-1">
                                            <button onClick={pdUpdate} className="btn-blue-Animal">Update P.D</button>
                                        </Col> : <><Col xs="2" xl="1" className="mt-1">
                                            <button onClick={Add} className="btn-blue-Animal">Add A.I</button>
                                        </Col>
                                            <Col xl="1" xs="2" className="mt-1">
                                                <button onClick={pdUpdate} className="btn-blue-Animal">Update P.D</button>
                                            </Col></>
                                    }
                                    {/* <Col xs="2" xl="1" className="mt-1">
                                        <button onClick={Add} className="btn-blue-Animal">Add A.I</button>
                                    </Col>
                                    <Col xl="1" xs="2" className="mt-1">
                                        <button onClick={pdUpdate} className="btn-blue-Animal">Update P.D</button>
                                    </Col> */}
                                </Row>
                            }

                            {calving && (
                                <Row>
                                    <Col xl="3" lg="3" md="3" sm="6">
                                        <div className="blue_bg p-3 my-3">
                                            <sub className="mb-0 pb-0">Calving Date</sub>
                                            <input type="date" className="dateSelect" value={calvingDate} onChange={(e) => setCalvingDate(e.target.value)} />
                                            <p className="mt-4 mb-0">Type of Calving :</p>

                                            <RadioGroup aria-labelledby="demo-row-radio-buttons-group-label" name="row-radio-buttons-group">
                                                <FormControlLabel value="1" onChange={(e) => setCalvingType(e.target.value)} control={<Radio />} label="Normal" />
                                                <FormControlLabel value="2" onChange={(e) => setCalvingType(e.target.value)} control={<Radio />} label="Abnormal" />
                                            </RadioGroup>
                                            <Row className="align-items-end">
                                                <Col>
                                                    <Select
                                                        placeholder="Calf Sex 1"
                                                        // className="mb-3"
                                                        onChange={(selectedOption) => {
                                                            setCalfSex(selectedOption.value);
                                                        }}
                                                        options={calfSexList.map((item) => ({
                                                            value: item.value,
                                                            label: item.label,
                                                        }))}
                                                    />
                                                </Col>
                                                <Col>
                                                    <TextField className="" label="Claf Id 1" placeholder="Enter Calf Id" id="standard-basic" fullWidth variant="standard" value={calfId} onChange={(e) => { setCalfId(e.target.value) }} />
                                                </Col>
                                            </Row>
                                            <Row className="align-items-end">
                                                <Col>
                                                    <Select
                                                        placeholder="Calf Sex 2"
                                                        classNames="mt-2"
                                                        onChange={(selectedOption) => {
                                                            setCalfSex2(selectedOption.value);
                                                        }}
                                                        options={calfSexList.map((item) => ({
                                                            value: item.value,
                                                            label: item.label,
                                                        }))}
                                                    />
                                                </Col>
                                                <Col>
                                                    <TextField className="mt-1" label="Claf Id 2" placeholder="Enter Calf Id 2" id="standard-basic" fullWidth variant="standard" value={calfId1} onChange={(e) => { setCalfId1(e.target.value) }} />
                                                </Col>
                                            </Row>
                                        </div>
                                    </Col>
                                    <Col xl="4" lg="5" md="5" sm="6">
                                        <div className="blue_bg overSize p-3 my-3">
                                            <div className="mt-1">
                                                <h6 className="mb-0"><b>Reproductive Problems</b></h6>
                                            </div>
                                            <hr />
                                            <div className="mt-1">
                                                <input type="checkbox" id="vehicle1" value="infection" className="me-2" onChange={(e) => { problem(e) }} />infection
                                            </div>
                                            <div className="mt-1">
                                                <input type="checkbox" id="vehicle1" value="pre proapse" className="me-2" onChange={(e) => { problem(e) }} />pre proapse
                                            </div>
                                            <div className="mt-1">
                                                <input type="checkbox" id="vehicle1" value="abortion" className="me-2" onChange={(e) => { problem(e.ta) }} />abortion
                                            </div>
                                            <div className="mt-1">
                                                <input type="checkbox" id="vehicle1" value="calving" className="me-2" onChange={(e) => { problem(e.target.checked) }} />calving
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xl="5" lg="4" md="4">
                                        <div className="blue_bg p-3 my-3">
                                            <h6 className="mb-0"><b>Calving Note</b></h6>
                                            <hr />
                                            <textarea placeholder="Calving Note" rows="5" className="mt-2 text_area p-2" />
                                            <Row className="justify-content-end">
                                                {/* <Col xxl="2" md="2" sm="3" xs="6">
                                                    <button type="button" className="mt-1 btn-blue" onClick={saveBreeding}>Calf Save</button>
                                                </Col> */}
                                                <Col xxl="2" md="2" sm="3" xs="6">
                                                    <button type="button" className="mt-1 btn-blue" onClick={saveBreeding}>Save</button>
                                                </Col>
                                                <Col xl="4" md="2" sm="3" xs="6"><button type="button" className="mt-1 btn-blue">Production Details</button></Col>
                                            </Row>
                                        </div>
                                    </Col>
                                </Row>
                            )}

                            {dry && (
                                <Row id='heightOffsetStock'>
                                    <Col xxl="9" xl="10" lg="12">
                                        <div className="blue_bg p-3 my-3">
                                            <Row className="align-items-end justify-content-start">
                                                <Col xl="2" lg="2" md="3" sm="3">
                                                    <sub className="mb-0 pb-0">Drying Date</sub>
                                                    <input type="date" className="dateSelect" value={dateOfDry} onChange={(e) => { setDateOfDry(e.target.value) }} />
                                                </Col>
                                                {/* <Col xl="2" lg="2" md="3" sm="3">
                                                    <sub className="mb-0 pb-0">Heat Date</sub>
                                                    <input type="date" className="dateSelect" value={heatDate} onChange={(e) => { setHeatDate(e.target.value) }} />
                                                </Col> */}
                                                {/* <Col xl="3" lg="3" md="3" sm="4">
                                                    <TextField label="Dry off Reasons" placeholder="Sex " id="standard-basic" fullWidth variant="standard" value={dryReason} onChange={(e) => { setDryReason(e.target.value) }} />
                                                </Col> */}
                                                <Col xl="2" lg="2" md="3" sm="3">
                                                    <Select
                                                        placeholder="Dry off Reasons"
                                                        onChange={(selectedOption) => {
                                                            setDryReason(selectedOption.value);
                                                        }}
                                                        options={dryReasonList.map((item) => ({
                                                            value: item.ID,
                                                            label: item.Name,
                                                        }))}
                                                    />
                                                </Col>
                                                <Col xl="3" lg="3" md="3" sm="4">
                                                    <TextField label="Dry Off treatment" placeholder="" id="standard-basic" fullWidth variant="standard" value={dryTreatment} onChange={(e) => { setDryTreatment(e.target.value) }} />
                                                </Col>
                                                <Col xl="2" lg="2" md="3" sm="3" xs="6" xxl="1">
                                                    <button type="button" className="mt-3 btn-blue" onClick={dryOff}>Dry Off</button>
                                                </Col>
                                            </Row>
                                        </div>
                                    </Col>
                                </Row>
                            )}

                            <Row className={dry ? "tableBreed1" : "tableBreed"}>
                                <Col id="breedFixTable">
                                    {lifeDataList.length ?
                                        <div className="blue_bg tableBorder2 mt-1">
                                            <Table columns={column3} data={lifeDataList} height={`calc(100vh - (${heightOffSet}px + 100px))`} />
                                        </div>
                                        :
                                        <Row className={dry ? "tableBreed1 align-items-center" : "tableBreed align-items-center"}>
                                            <Col>
                                                <div className="tableBorder ms-2">
                                                    <div className="d-flex tabledata colmBreed">{
                                                        column3.map((i) => {
                                                            return (
                                                                <Col className={`blankColumns ${i.name.replace(' ', '_')}`} style={{ backgroundColor: "rgb(169,169,169)" }}>{i.name}</Col>
                                                            )
                                                        })
                                                    }
                                                    </div>
                                                    <h6 className="text-center align-items-center mt-5 noData">No Data</h6>
                                                </div>
                                            </Col>
                                        </Row>
                                    }
                                    {/* 
                                    <div className="blue_bg p-3 mt-1">
                                        <Table columns={column3} data={lifeDataList} height={`calc(100vh - (${heightOffSet}px + 100px))`} />
                                    </div> */}
                                </Col>
                            </Row>
                            <Row className="justify-content-end">
                                <Col xxl="1" md="2" sm="3" xs="6">
                                    <button type="button" className="mt-3 btn-perot" onClick={refFn1}>Refresh</button>
                                </Col>
                                <Col xxl="1" md="2" sm="3" xs="6">
                                    <button type="button" className="mt-3 btn-yellow">Close</button>
                                </Col>
                                <Col xxl="1" md="2" sm="3" xs="6">
                                    <button type="button" className="mt-3 btn-red">Delete</button>
                                </Col>
                            </Row>
                        </Container>
                    </Col>
                </Row>
            </Container >


            <Modal size="lg" aria-labelledby="contained-modal-title-vcenter" centered show={modalShow} onHide={() => setModalShow(false)} id="breed1">
                <Modal.Header style={{ background: "#DFDFDF" }}>
                    <Modal.Title id="contained-modal-title-vcenter">Add New A.I</Modal.Title>
                    <img src={closeButton} onClick={() => setModalShow(false)} />
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Row className="align-items-end">
                            <Col xl="6" className="mb-2">
                                <div>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            label="A.I Date"
                                            value={aiDate}
                                            onChange={handleDateChange}
                                            format="DD-MM-YYYY"
                                        />
                                    </LocalizationProvider>
                                </div>
                            </Col>
                            <Col xl="6" className="mb-2">
                                <Select
                                    placeholder={service !== "" ? service : "Service"}
                                    onChange={(selectedOption) => {
                                        setService(selectedOption.value);
                                    }}
                                    options={manualOptions.map((item) => ({
                                        value: item.value,
                                        label: item.label,
                                    }))}
                                />
                            </Col>
                            <Col xl="6" className="mb-2">
                                <Select
                                    placeholder={inseminator !== "" ? inseminator : "Inseminator"}
                                    onChange={(selectedOption) => {
                                        setInseminator(selectedOption.value);
                                    }}
                                    options={inseminatorList.map((item) => ({
                                        value: item.ID,
                                        label: item.Name,
                                    }))}
                                />
                            </Col>
                            <Col xl="6" className="mb-2">
                                <Select
                                    placeholder={sire !== "" ? sire : "Sire"}
                                    onChange={(selectedOption) => {
                                        setSire(selectedOption.value);
                                    }}
                                    options={sireList.map((item) => ({
                                        value: item.id,
                                        label: item.Name,
                                    }))}
                                />
                            </Col>
                            <Col xl="6">
                                <Select
                                    placeholder={Dose !== "" ? Dose : "Dose"}
                                    onChange={(selectedOption) => {
                                        setDose(selectedOption.value);
                                    }}
                                    options={doseOption.map((item) => ({
                                        value: item.value,
                                        label: item.label,
                                    }))}
                                />
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Container>
                        <Row className="justify-content-start">
                            <Col xl="2">
                                <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }} onClick={AddData}>A.I Save</button>
                            </Col>
                            <Col xl="2" className="ms-auto">
                                <button className="btn-blue-Animal mt-1" onClick={() => setModalShow(false)} style={{ background: "#E16659" }}> Cancel </button>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Footer>
            </Modal>

            <Modal size="lg" aria-labelledby="contained-modal-title-vcenter" centered show={editModalShow} onHide={() => setEditModalShow(false)} id="breed1">
                <Modal.Header style={{ background: "#DFDFDF" }}>
                    <Modal.Title id="contained-modal-title-vcenter">Edit A.I && PD</Modal.Title>
                    <img src={closeButton} onClick={() => setModalShow(false)} />
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Row className="align-items-end">
                            {/* <Col xl="6" className="mb-2">
                                <div>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DemoContainer components={['DatePicker']}>
                                            <DatePicker label="A.I Date" value={aiDate} onChange={(e) => { setAiDate(e.target.value) }} />
                                        </DemoContainer>
                                    </LocalizationProvider>
                                </div>
                            </Col> */}
                            <Col xl="6" className="mb-2">
                                <div>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            label="A.I Date"
                                            value={aiDate}
                                            onChange={handleDateChange}
                                            format="DD-MM-YYYY"
                                        />
                                    </LocalizationProvider>
                                </div>
                            </Col>
                            <Col xl="6" className="mb-2">
                                <Select
                                    placeholder={(service !== "" || service !== null) ? service : "Service"}
                                    onChange={(selectedOption) => {
                                        setService(selectedOption.value);
                                    }}
                                    options={manualOptions.map((item) => ({
                                        value: item.value,
                                        label: item.label,
                                    }))}
                                />
                            </Col>
                            <Col xl="6" className="mb-2">
                                <Select
                                    placeholder={(inseminator !== "" || inseminator !== null) ? inseminator : "Inseminator"}
                                    onChange={(selectedOption) => {
                                        setInseminator(selectedOption.value);
                                    }}
                                    options={inseminatorList.map((item) => ({
                                        value: item.ID,
                                        label: item.Name,
                                    }))}
                                />
                            </Col>
                            <Col xl="6" className="mb-2">
                                <Select
                                    placeholder={(sire !== "" || sire !== null) ? sire : "Sire"}
                                    onChange={(selectedOption) => {
                                        setSireId(selectedOption.value);
                                    }}
                                    options={sireList.map((item) => ({
                                        value: item.id,
                                        label: item.Name,
                                    }))}
                                />
                            </Col>
                            <Col xl="6" className="mb-2">
                                <Select
                                    placeholder={(Dose !== "" || Dose !== null) ? Dose : "Dose"}
                                    onChange={(selectedOption) => {
                                        setDose(selectedOption.value);
                                    }}
                                    options={doseOption.map((item) => ({
                                        value: item.value,
                                        label: item.label,
                                    }))}
                                />
                            </Col>
                            {/* <Col xl="6" className="mb-2">
                                <div>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DemoContainer components={['DatePicker']}>
                                            <DatePicker label="P.D Date" value={pdDate} onChange={(e) => { setPdDate(e) }} />
                                        </DemoContainer>
                                    </LocalizationProvider>
                                </div>
                            </Col> */}
                            <Col xl="6" className="mb-2">
                                <div>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker
                                            label="P.D Date"
                                            value={pdDate}
                                            onChange={handleDateChangePdDate}
                                            format="DD-MM-YYYY"
                                        />
                                    </LocalizationProvider>
                                </div>
                            </Col>
                            <Col xl="6" className="mb-2">
                                <Select
                                    placeholder={(pdDone !== "" && pdDone !== null) ? pdDone : "P.D Done"}
                                    onChange={(selectedOption) => {
                                        setPdDone(selectedOption.value);
                                    }}
                                    options={inseminatorList.map((item) => ({
                                        value: item.ID,
                                        label: item.Name,
                                    }))}
                                />
                            </Col>
                            <Col xl="6" className="mb-2">
                                <Select
                                    placeholder={(pdResult !== "" || pdResult !== null) ? pdResult : "P.D Result"}
                                    onChange={(selectedOption) => {
                                        setPdResultId(selectedOption.value);
                                    }}
                                    options={pdResultList.map((item) => ({
                                        value: item.value,
                                        label: item.label,
                                    }))}
                                />
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Container>
                        <Row className="justify-content-start">
                            <Col xl="2">
                                <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }} onClick={EditData}>Save</button>
                            </Col>
                            <Col xl="2" className="ms-auto">
                                <button className="btn-blue-Animal mt-1" onClick={() => setEditModalShow(false)} style={{ background: "#E16659" }}> Cancel </button>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Footer>
            </Modal>

            <Modal size="lg" aria-labelledby="contained-modal-title-vcenter" centered show={pdModalShow} onHide={() => setPdModalShow(false)}>
                <Modal.Header style={{ background: "#DFDFDF" }}>
                    <Modal.Title id="contained-modal-title-vcenter">Update PD</Modal.Title>
                    <img src={closeButton} onClick={() => setPdModalShow(false)} />
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Row className="align-items-end">
                            <Col xl="6" className="mb-2">
                                <div>
                                    <sub className="mb-0 pb-0">P.D Date</sub>
                                    <input type="date" className="dateSelect_bg" value={pdDate} onChange={(e) => { setPdDate(e.target.value) }} />
                                </div>
                            </Col>
                            <Col xl="6" className="mb-2">
                                <Select
                                    placeholder="P.D Done"
                                    onChange={(selectedOption) => {
                                        setPdDone(selectedOption.value);
                                    }}
                                    options={inseminatorList.map((item) => ({
                                        value: item.ID,
                                        label: item.Name,
                                    }))}
                                />
                            </Col>
                            <Col xl="6" className="mb-2">
                                <Select
                                    placeholder="P.D Result"
                                    onChange={(selectedOption) => {
                                        setPdResult(selectedOption.value);
                                    }}
                                    options={pdResultList.map((item) => ({
                                        value: item.value,
                                        label: item.label,
                                    }))}
                                />
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Container>
                        <Row className="justify-content-start">
                            <Col xl="2">
                                <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }} onClick={updatePd}>PD Save</button>
                            </Col>
                            <Col xl="2" className="ms-auto">
                                <button className="btn-blue-Animal mt-1" onClick={() => setPdModalShow(false)} style={{ background: "#E16659" }}> Cancel </button>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Footer>
            </Modal>


            <Modal size="" aria-labelledby="contained-modal-title-vcenter" centered show={deleteShow} onHide={() => setDeleteShow(false)}>
                <Modal.Header style={{ background: "#DFDFDF" }}>
                    <Modal.Title id="contained-modal-title-vcenter">Delete</Modal.Title>
                    <img src={closeButton} onClick={() => setDeleteShow(false)} />
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Row className="my-3">
                            <Col sm="12">
                                <h4>Are you sure want to delete this item ?</h4>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer className="py-0">
                    <Container>
                        <Row className="justify-content-end">
                            <Col xxl="2" md="3">
                                <button className="btn-blue-Animal mt-2 mb-1" onClick={() => deleteFn()}>Delete</button>
                            </Col>
                            <Col xxl="2" md="3">
                                <button className="btn-blue-Animal mt-2 mb-1" onClick={() => setDeleteShow(false)} style={{ background: "#E16659" }}>Cancel</button>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Footer>
            </Modal>

            <ToastContainer
                position="top-center"
                autoClose={2000}
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
                style={{ fontSize: "14px" }}
            />


            {
                show && (<Offcanvas className="hide_Navbar" show={show} onHide={handleClose}><Container><Row><Col><Sidenav /></Col></Row></Container></Offcanvas>)
            }
            {
                isLoading ?
                    <div className="circleText">
                        <div className="progressText">{progressData}</div>
                        <div className="">
                            <span className="loader-circle-13"></span>
                        </div>
                    </div>
                    : false
            }
        </>
    )
}



