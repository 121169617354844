import React, { useState, useEffect } from 'react'
import { Col, Container, Row, Offcanvas, Modal } from "react-bootstrap";
import {
    TextField, FormControl, InputLabel,
    // Select,
    MenuItem
} from "@mui/material";
import { MdOutlineVpnKey } from "react-icons/md";
import { GoSignOut } from "react-icons/go";
import { useNavigate } from 'react-router-dom';

import menu from '../../Photo/menu.svg';
import profile1 from '../../Photo/profile.svg';
import edit from "../../Photo/master/edit.svg";
import remove from "../../Photo/master/remove.svg";
import closeButton from "../../Photo/milk-union/close button.svg";
import { ToastContainer, toast } from "react-toastify";

import Sidenav from "../../Componet/Sidenav/Sidenav";
import Input from '../../Componet/InputFild/Input';
import Table from '../../Componet/DataTable/Table';
import "react-toastify/dist/ReactToastify.css";
import moment from 'moment';
import Select from 'react-select';

import { STORAGE_KEY } from "../../constant/common";
import { API_URLS, serviceUrl } from "../../API/Api";
import Searcable from '../../Componet/Dropdown/Searcable';
import BackBtn from '../../Componet/Button/BackBtn';
const { CampMaster, AllocationApi, BreedingDetailsApi, CampPlanning, CampReport } = API_URLS;
const added_by = localStorage.getItem(STORAGE_KEY.jwtTOKEN);

async function getZoneData(credentials) {
    return fetch(CampMaster.GetZone, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            "Accept": "application/json",
            "Authorization": `Bearer ${added_by}`,
        },
        body: JSON.stringify(credentials)
    })
        .then(res => res.json());
}

async function campDataSave(credentials) {
    return fetch(AllocationApi.SaveDcsTest, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            "Accept": "application/json",
            "Authorization": `Bearer ${added_by}`,
        },
        body: JSON.stringify(credentials)
    })
        .then(res => res.json());
}
async function getMasterData(credentials) {
    return fetch(CampMaster.getMasterTable, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            "Accept": "application/json",
            "Authorization": `Bearer ${added_by}`,
        },
        body: JSON.stringify(credentials)
    })
        .then(res => res.json());
}
async function getDcsData(credentials) {
    return fetch(CampPlanning.GetCampGrid, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            "Accept": "application/json",
            "Authorization": `Bearer ${added_by}`,
        },
        body: JSON.stringify(credentials)
    })
        .then(res => res.json());
}
async function getFipData(credentials) {
    return fetch(CampReport.GetFip, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            "Accept": "application/json",
            "Authorization": `Bearer ${added_by}`,
        },
        body: JSON.stringify(credentials)
    })
        .then(res => res.json());
}

export default function Campmasters() {

    const API_KEY = serviceUrl;
    // const API_KEY = "https://hais.hap.in";
    const selectedToDate = new Date().toISOString().slice(0, 10);

    const navigate = useNavigate();
    const [search, setSearch] = useState("");
    const [group, setGroup] = useState("");
    const [userName, setUserName] = useState("");
    const [contactPerson, setContactPerson] = useState("");
    const [mobile, setMobile] = useState("");
    const [appType, setAppType] = useState("");
    const [qr, setQr] = useState("");
    const [zone, setZone] = useState(0);
    const [editId, setEditId] = useState("");
    const [tableId, setTableId] = useState("");
    const [route, setRoute] = useState("");
    const [searchFilter, setSearchFilter] = useState("");
    const [progressData, setProgressData] = useState("0%");
    const [startDate, setStartDate] = useState("");
    const [society, setSociety] = useState("");
    const [union, setUnion] = useState("");
    const [modalunion, setModalUnion] = useState("");
    const [societyName, setSocietyName] = useState("");
    const [modalsocietyName, setModalSocietyName] = useState("");
    const [villageName, setVillageName] = useState("");
    const [endDate, setEndDate] = useState(selectedToDate);
    const [campNo, setCampNo] = useState("");
    const logOut = () => { navigate("/") };
    const [societyCode, setSocietyCode] = useState();
    const [items, setItems] = useState("");
    const [staff, setStaff] = useState("")

    const [heightOffSet, setHeight] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [modalShow, setModalShow] = useState(false);
    const [headerShow, setHeaderShow] = useState(true);
    const [show, setShow] = useState(false);
    const handleClose = () => { setShow(false); }
    const handleShow = () => { setHeaderShow(!headerShow); }

    const [fipList, setFipList] = useState([]);
    const [unionList, setUnionList] = useState([]);
    const [societyNameList, setSocietyNameList] = useState([]);
    const [campNoList, setCampNoList] = useState(["1", "2", "3", "4"]);
    const [villageList, setVillageList] = useState([])
    const [staffList, setStaffList] = useState([])

    const itemsList = [100, 200, 500, 1000, 2000, 5000];

    const fetchData = async () => {
        const res = await getZoneData();
        // const res1 = await getDcsData({ year: 2023, projectId: 1, staff: null, inputDate: selectedToDate });
        const res2 = await getFipData({ id: 5 });

        if (res) { setUnionList(res); }
        // if (res1) { setFipList(res1) }
        if (res2) { setStaffList(res2) }
    }

    const tableData = async () => {
        const res = await getMasterData({ Year: 2023, Projectid: 1, Staff: null })
        if (res) { setFipList(res) }
    }

    useEffect(() => {
        tableData()
        fetchData();
        let divHeight = document.getElementById("heightOffsetStock")?.offsetHeight;
        setHeight(divHeight);
    }, []);

    const refreshFn = () => {
        setModalShow(false);
        setGroup("");
        setAppType("");
        setUserName("");
        setEditId("");
        setMobile("");
        setQr("");
        setZone("");
        setContactPerson("");
    }

    const AddData = async () => {
        await fetch(`${AllocationApi.SaveDcsTest}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`
            },
            body: JSON.stringify({ id: 0, ProjectID: 1, YearMaster: 2023, Fromtime: startDate, Totime: endDate, Date: selectedToDate, Staff: staff, lot: parseInt(societyCode), Camp: campNo ? campNo : 1 }),
        })
            .then(res => res.json())
            .then((result) => { toast.success("Dcs Add Successfully"); tableData(); fetchData(); refreshFn() })
            .catch((error) => console.log(error))
    }


    const Add = async () => {
        refreshFn();
        setModalShow(true);
        setUnion("");
        setSocietyName("");
        fetchData();
    }

    const SearchFn = (e) => {
        const data = e.target.value;
        if (data !== '') {
            const results = fipList.filter((user) => {
                return user["DCS Name"].toLowerCase().startsWith(data.toLowerCase())
            });
            setSearchFilter(results);
        } else {
            setSearchFilter(fipList);
        }
        setSearch(data);
    }

    const Save = () => {
        fetch(`${API_KEY} / user / assignment`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`,
            },
            body: JSON.stringify({ farmerOnly: false, herdId: route || null, herdOnly: false, lotIdAssigned: [], lotIdUnAssign: [], lotOnly: true, searchTerm: "", userId: tableId })
        })
            .then(response => {
                const contentLength = response.headers.get('content-length');
                let loaded = 0;
                return new Response(
                    new ReadableStream({
                        start(controller) {
                            const reader = response.body.getReader();
                            read();
                            function read() {
                                reader.read()
                                    .then((progressEvent) => {
                                        if (progressEvent.done) {
                                            controller.close();
                                            return;
                                        }
                                        loaded += progressEvent.value.byteLength;
                                        const percentageComplete = Math.round(loaded / contentLength * 100) + '%';
                                        setProgressData(percentageComplete)
                                        controller.enqueue(progressEvent.value);
                                        read();
                                    })
                            }
                        }
                    })
                );
            })
            .then((res) => res.json())
            .then((result) => { setIsLoading(false); setProgressData("0%"); })
    }

    const editFn = (e) => {
        setModalShow(true);
    };

    const colum = [
        // {
        //     name: "Actions",
        //     cell: (e) => (<><img src={edit} width={18} onClick={() => editFn(e)} /><img src={remove} className="ms-2" width={18} /></>),
        //     width: "80px"
        // },
        {
            name: "Date",
            selector: "Date",
        },
        {
            name: "DCS Name",
            selector: "DCS Name",
        },
        {
            name: "Status",
            selector: "Status",
        },
        // {
        //     name: "DCS SAP",
        //     selector: "DCS SAP",
        // },
        {
            name: "CampNo",
            selector: "Camp",
        },
        {
            name: "Year",
            selector: "YearMaster",
        },
        {
            name: "Start Date",
            cell: (e) => (<div>{moment(e["FromTime"]).format('DD-MM-YYYY')}</div>),
            selector: "FromTime",
        },
        {
            name: "End Date",
            cell: (e) => (<div>{moment(e["ToTime"]).format('DD-MM-YYYY')}</div>),
            selector: "ToTime",
        },
    ]

    // const handleChange = (event) => {
    //     const { target: { value }, } = event;
    //     setTags(typeof value === "number" ? value.split(',') : value,);
    // };


    const selectSocietyCode = async (e) => {
        setSocietyCode(e)
        await fetch(`${CampMaster.getVillageName}?search_term=${e}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`,
            }
        })
            .then(res => res.json())
            .then((res) => setSocietyNameList(res))

        await fetch(`${BreedingDetailsApi.getVillageData}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`,
            },
            body: JSON.stringify({ Code: e })
        })
            .then(res => res.json())
            .then((res) => setVillageList(res.data))
    }

    const defaultOption = { value: "1", label: "1" };

    const getSelectedOption = (value) => {
        return campNoList.includes(value) ? { value, label: value } : defaultOption;
    };

    return (
        <>
            <Container fluid>
                <Row>
                    {headerShow && (<Col lg="2" className="hideNavbar"> <Sidenav /></Col>)}
                    <Col className="header" lg={headerShow ? "10" : "12"}>
                        <Container fluid>
                            <Row className="pt-1 sub-header align-items-center justify-content-between">
                                <Col md="10" sm="10" xs="10" className="py-2 d-flex align-items-center">
                                    <button style={{ border: "none" }} className="hideNavbar1" onClick={handleShow}><img src={menu} width={20} /></button>
                                    <button style={{ border: "none" }} className="hide_Navbar" onClick={() => setShow(true)}><img src={menu} width={20} /></button>
                                    <h5 className="ms-4 mt-2">Project / MPP / Camp Master</h5>
                                </Col>
                                <Col md="1" sm="1" xs="10" className="py-2 d-flex align-items-center text-end ms-auto">
                                    <BackBtn style={{ border: "none" }} className="hideNavbar1" lable="Back" onClick={() => { navigate('/') }} />
                                </Col>
                                <Col md="1" sm="1" xs="2" className="text-end profile_dropdown">
                                    <img src={profile1} width={40} />
                                    <div className="dropdpwn_Open">
                                        <Row className="text-center align-items-center">
                                            <Col xs="12" className="menuHover mb-3">
                                                <Row>
                                                    <Col className="" xs="3">
                                                        <span className=""><MdOutlineVpnKey size={25} /></span>
                                                    </Col>
                                                    <Col className="text-start" xs="9">
                                                        Change Password
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col xs="12" className="menuHover" onClick={logOut}>
                                                <Row>
                                                    <Col className="" xs="3">
                                                        <span className=""><GoSignOut size={25} /></span>
                                                    </Col>
                                                    <Col className="text-start" xs="9">
                                                        Sign Out
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="align-items-end justify-content-start" id='heightOffsetStock'>
                                <Col md="3" xs="6" xl="1" className="mt-4 justify-content-start">
                                    <button className="btn-blue-Animal" onClick={Add}>Add Dcs Master</button>
                                </Col>
                                {/* <Col xl="1" className='ms-auto'>
                                    <Select
                                        placeholder="Items"
                                        onChange={(selectedOption) => setItems(selectedOption.value)}
                                        options={itemsList.map((item) => ({ value: item, label: item }))}
                                    />
                                </Col> */}
                                {/* <Col md="3" xl="2" xs="6" className='ms-auto'>
                                    <Select
                                        placeholder="Union Name"
                                        onChange={(selectedOption) => setModalUnion(selectedOption.value)}
                                        options={unionList.map((item) => ({ value: item.id, label: item.Name }))}
                                    />
                                </Col> */}
                                <Col xl="2" md="3" xs="6" className='ms-auto align-items-center'>
                                    <Input label="Dcs Name" value={societyCode} onChange={(e) => { SearchFn(e) }} fullWidth variant="standard" />
                                </Col>
                                {/* <Col xl="2" md="3" xs="6" className='align-items-center'>
                                    <Input label="" value={societyName} onChange={e => SearchFn(e)} fullWidth variant="standard" />
                                </Col> */}
                                {/* <Col xl="2" md="3" xs="6" className='align-items-center'>
                                    <Input label="Camp No" value={search} onChange={(e) => { SearchFn(e) }} />
                                </Col> */}
                            </Row>
                            <Row className="mt-2">
                                <Col xs="12" sm="12" className={fipList.length > 0 ? 'MainTableBorder' : ""}>
                                    <Table columns={colum} data={searchFilter ? searchFilter : fipList} height={`calc(100vh - (${heightOffSet}px + 135px))`} pagination />
                                </Col>
                            </Row>
                        </Container>
                    </Col>
                </Row>
            </Container>

            <Modal size="lg" aria-labelledby="contained-modal-title-vcenter" centered show={modalShow} onHide={() => setModalShow(false)}>
                <Modal.Header style={{ background: "#DFDFDF" }}>
                    <Modal.Title id="contained-modal-title-vcenter">Add Dcs Master</Modal.Title>
                    <img src={closeButton} onClick={() => setModalShow(false)} />
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Row className='align-items-end'>
                            <Col md="3" xl="6" xs="6" className='mb-2'>
                                <Select
                                    placeholder="AHC Name"
                                    onChange={(selectedOption) => setModalUnion(selectedOption.value)}
                                    options={unionList.map((item) => ({ value: item.id, label: item.Name }))}
                                    defaultValue={{ value: 16, label: unionList.find(item => item.id === 16)?.Name }}
                                />
                                {/* <Searcable
                                    placeholder="Village"
                                    value={{ value: 16, label: unionList.find(item => item.id === 16)?.Name }} // Set the initial value
                                    options={unionList}
                                    onChange={(e) => {
                                        setModalUnion(e); 
                                    }}
                                    labelKey="Name"
                                /> */}

                            </Col>
                            <Col md="3" xl="6" xs="6" className='align-items-center mb-2'>
                                <TextField label="Society Code" value={societyCode} onChange={e => selectSocietyCode(e.target.value)} fullWidth variant="standard" />
                            </Col>
                            <Col md="3" xl="6" xs="6" className='mb-2'>
                                <Select
                                    placeholder="Society Name"
                                    isDisabled
                                    onChange={(selectedOption) => setModalSocietyName(selectedOption.value)}
                                    options={societyNameList.map((item) => ({ value: item.id, label: item.Name }))}
                                    value={societyNameList.map((item) => ({ value: item.id, label: item.Name }))}
                                />
                            </Col>
                            <Col md="3" xl="6" xs="6" className='mb-2'>
                                <Select
                                    placeholder="Village Name"
                                    isDisabled
                                    onChange={(selectedOption) => setVillageName(selectedOption.value)}
                                    options={villageList.map((item) => ({ value: item.ID, label: item.Name }))}
                                    value={villageList.map((item) => ({ value: item.ID, label: item.Name }))}
                                />
                            </Col>
                            <Col xl="6">
                                <div className="mt-1 mb-2">
                                    <sub className="mb-0 pb-0">Start Date</sub>
                                    <input type="date" className="dateSelect_bg" id="date-1" value={startDate} onChange={(e) => { setStartDate(e.target.value) }} />
                                </div>
                            </Col>
                            <Col xl="6">
                                <div className="mt-1 mb-2">
                                    <sub className="mb-0 pb-0">End Date</sub>
                                    <input type="date" className="dateSelect_bg" id="date-2" value={endDate} onChange={(e) => { setEndDate(e.target.value) }} />
                                </div>
                            </Col>
                            <Col xl="6">
                                <Select
                                    placeholder="Round No"
                                    onChange={(selectedOption) => setCampNo(selectedOption.value)}
                                    options={campNoList.map((item) => ({ value: item, label: item }))}
                                    value={getSelectedOption(campNo)} />

                            </Col>
                            <Col xl="6">
                                <Select
                                    placeholder="Staff Name"
                                    onChange={(selectedOption) => setStaff(selectedOption.value)}
                                    options={staffList.map((item) => ({ value: item.ID, label: item.name }))}
                                />
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Container>
                        <Row className="justify-content-end ">
                            <Col xl="1">
                                <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }} onClick={AddData}>Save</button>
                            </Col>
                            <Col xl="1">
                                <button className="btn-blue-Animal mt-1" onClick={() => setModalShow(false)} style={{ background: "#E16659" }}> Cancel </button>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Footer>
            </Modal>

            {show && (<Offcanvas className="hide_Navbar" show={show} onHide={handleClose}><Container><Row><Col><Sidenav /></Col></Row></Container></Offcanvas>)}

            {isLoading ?
                <div className="circleText">
                    <div className="progressText">{progressData}</div>
                    <div className="">
                        <span className="loader-circle-13"></span>
                    </div>
                </div>
                : false
            }

            <ToastContainer
                position="top-center"
                autoClose={2000}
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
                style={{ fontSize: "14px" }}
            />

        </>
    )
}
// export default Campmasters
