import React, { useState, useEffect } from "react";
import './animalWise.css';
import { Col, Container, Row, Offcanvas, Accordion, Modal } from "react-bootstrap";
import { FormControlLabel, Radio, RadioGroup, TextField } from '@mui/material';

import Sidenav from "../../Componet/Sidenav/Sidenav";
import menu from '../../Photo/menu.svg'
import profile from '../../Photo/profile.svg';
import breeding from '../../Photo/breeding.svg';
import filter from '../../Photo/milk-union/filter.svg';
import newimg from '../../Photo/milk-union/new.svg';
import save from '../../Photo/milk-union/save.svg';
import deleteimg from '../../Photo/milk-union/delete.svg';
import refresh from '../../Photo/milk-union/refresh.svg';
import preview from '../../Photo/milk-union/preview.svg';
import close from '../../Photo/milk-union/close.svg';
import { MdOutlineVpnKey } from "react-icons/md";
import { GoSignOut } from "react-icons/go";

import moment from "moment";
import Dropdown from "../../Componet/Dropdown/Dropdown";
import Table from "../../Componet/DataTable/Table";
import { useNavigate } from "react-router-dom";

export default function AnimalWise() {

    const [select, setSelect] = useState("");

    const [tabList, setTabList] = useState([]);

    const menuitm = ["Village", "Farmer", "Paravet, Doctor", "Field Staff", "Animals", "TM", "CC", "Virtual Herd", "EO", "MU"];
    const menuitm1 = [];
    const Property = ["Name", "Alignment", "Sorting", "Width", "No of Decimal", "Visible", "Formula"];

    const [headerShow, setHeaderShow] = useState(true);
    const [show, setShow] = useState(false);
    const handleClose = () => { setShow(false); }
    const handleShow = () => { setHeaderShow(!headerShow); }
    const navigate = useNavigate()
    const logOut = () => { navigate("/") }

    const [modalShow, setModalShow] = useState(false);

    const selectFn = (e) => {
        setSelect(e);
        // if (e) {
        //     fetch("https://dudhsagar.herdman.in/reportUnion/fillTab", {
        //         method: 'POST',
        //         headers: {
        //             'Content-Type': 'application/json',
        //             "Accept": "application/json",
        //             "Authorization": "Bearer eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCJ9.eyJjb21wYW55IjoiRHVkaHNhZ2FyIiwiY2lkIjoxLCJ1c2VySUQiOjIwMSwiZ3JvdXBJZCI6MSwidXNlcm5hbWUiOiJEdWRoc2FnYXIiLCJhZG1pbiI6dHJ1ZSwiem9uZUlkIjoxOCwiaWF0IjoxNjc0NzM3MTg3LCJleHAiOjE2NzczMjkxODcsImF1ZCI6IkhlcmRtYW4iLCJpc3MiOiJBcHBCZWUgVGVjaCIsInN1YiI6InNvbWVAdXNlci5jb20ifQ.X96RrXdIKVhlzvy8e7opKk1ecsyNzfXTYUqVmrcKNoNRLwAnZusBZhQII8s9P_oVi7cZtooLT7qn9LC529u-q48nsFEu318tD_caCzEOXLCiO0yaXDb-ijVZk1Awm_HCO6jrQSycCaGa7Tz0ehPe7-uie-s6j38vES66NkEJvcQ"
        //         },
        //         body: JSON.stringify({ Lot: 0, Route: 0, Zone: 0, tab: e })
        //     })
        //         .then(res => res.json())
        //         .then((result) => { setTabList(result); })
        // }
    }

    const colums = [
        {
            name: "Select",
            cell: (e) => <input type="checkbox" className="ms-1" />,
            width: "70px"
        },
        {
            name: "Center Name",
            selector: "Center Name",
        },
        {
            name: "ID",
            selector: "ID",
            width: "50px",
        },
    ]

    useEffect(() => {
        setHeaderShow(false);
    }, []);

    return (
        <>
            <Container fluid>
                <Row>
                    {headerShow && (
                        <Col lg="2" className="hideNavbar">
                            <Sidenav />
                        </Col>
                    )}
                    <Col className="header" lg={headerShow ? "10" : "12"}>
                        <Container fluid>
                            <Row className="pt-1 sub-header align-items-center justify-content-between">
                                <Col md="11" sm="10" xs="10" className="py-2 d-flex align-items-center">
                                    <button style={{ border: "none" }} onClick={handleShow}><img src={menu} width={20} /></button>
                                    <h5 className="ms-4 mt-2">Milk Union Reports / Animal Wise</h5>
                                </Col>
                                <Col md="1" sm="1" xs="2" className="text-end">
                                    <img src={profile} width={40} />
                                </Col>
                            </Row>
                            <Row className="mt-2">
                                <Col xl="2">
                                    <Row className="p-2 blue_bg justify-content-center">
                                        <Col xl="11">
                                            <Dropdown label="Center" menuList={menuitm1} placeholder="Service" className="mb-3" />
                                        </Col>
                                    </Row>
                                    <Row className="p-2 blue_bg justify-content-center">
                                        <Col xl="11">
                                            <Dropdown label="A.l Center" menuList={menuitm} value={select} onChange={(e) => selectFn(e.target.value)} placeholder="Service" className="mb-3" />
                                            {/* <Table columns={colums} data={tabList} /> */}
                                        </Col>
                                    </Row>
                                    <Row className="p-2 blue_bg justify-content-center">
                                        <Col xl="11">
                                            <TextField label="AM Name" id="standard-basic" fullWidth variant="standard" />
                                        </Col>
                                    </Row>
                                    <Row className="p-2 blue_bg justify-content-center">
                                        <Col xl="8"><button className="btn-blue-Animal mt-1">Apply</button></Col>
                                    </Row>
                                </Col>
                                <Col xl="8">
                                    <Row>
                                        <Col>
                                            <div className="blue_bg_Animal p-2 align-items-end justify-content-center"></div>
                                        </Col>
                                    </Row>
                                    <Row className="align-items-center justify-content-end">
                                        <Col xl="2"></Col>
                                        <Col xl="2"><button className="btn-blue-Animal mt-2">Export to Excel</button></Col>
                                        <Col><button className="btn-blue-Animal mt-2"><img src={newimg} className="me-2 mb-0" width="15" />New</button></Col>
                                        <Col><button className="btn-blue-Animal mt-2"><img src={save} className="me-2 mb-0" width="15" />Save</button></Col>
                                        <Col><button className="btn-blue-Animal mt-2"><img src={deleteimg} className="me-2 mb-0" width="15" />Delete</button></Col>
                                        <Col><button className="btn-blue-Animal mt-2"><img src={refresh} className="me-2 mb-0" width="15" />Refresh</button></Col>
                                        <Col><button className="btn-blue-Animal mt-2"><img src={preview} className="me-2 mb-0" width="15" />Preview</button></Col>
                                        <Col><button className="btn-blue-Animal mt-2"><img src={close} className="me-2 mb-0" width="15" />Close</button></Col>
                                    </Row>
                                </Col>
                                <Col xl="2">
                                    <Row className="align-items-end justify-content-end">
                                        <Col xl="6"><button className="btn-blue-Animal mt-1 mb-1" onClick={() => setModalShow(true)}>Filter<img src={filter} className="ms-2" width="13" /></button></Col>
                                    </Row>
                                    <div className="blue_bg p-2">
                                        <Accordion defaultActiveKey={['1']} flush className="mt-2">
                                            <Accordion.Item eventKey="0">
                                                <Accordion.Header>Explore</Accordion.Header>
                                                <Accordion.Body>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="1">
                                                <Accordion.Header>Properties</Accordion.Header>
                                                <Accordion.Body>
                                                    <Row className="mb-2">
                                                        <Col className="animal_dark_bg text-start">
                                                            <h6 className="py-2 mb-0"><b>Property</b></h6>
                                                        </Col>
                                                        <Col className="animal_dark_bg text-start">
                                                            <h6 className="py-2 mb-0"><b>Value</b></h6>
                                                        </Col>
                                                    </Row>

                                                    {Property.map((e) =>
                                                        <Row>
                                                            <Col><h7>{e}</h7></Col>
                                                        </Row>
                                                    )}
                                                    <Row>
                                                        <Col>
                                                            <Dropdown label="Condition" menuList={menuitm1} placeholder="Service" className="mb-3" />
                                                            <TextField label="Value" id="standard-basic" fullWidth variant="standard" />
                                                            <RadioGroup row aria-labelledby="demo-row-radio-buttons-group-label" name="row-radio-buttons-group">
                                                                <FormControlLabel value="Portrait" control={<Radio />} label="Portrait" className="mt-2" />
                                                                <FormControlLabel value="Landscape" control={<Radio />} label="Landscape" className="mt-2" />
                                                            </RadioGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row className="justify-content-center">
                                                        <Col xl="6"><button className="btn-blue-Animal mt-1">Apply</button></Col>
                                                    </Row>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="2">
                                                <Accordion.Header>Group Footer</Accordion.Header>
                                                <Accordion.Body>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </Accordion>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </Col>
                </Row>
            </Container>

            {show && (<Offcanvas className="hide_Navbar" show={show} onHide={handleClose}><Container><Row><Col><Sidenav /></Col></Row></Container></Offcanvas>)}

            <Modal
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={modalShow}
                onHide={() => setModalShow(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">Filter</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Row className="align-items-center justify-content-start">
                            <Col xl="2" sm="2"><h5 className="mb-1 text-start">Gender&nbsp;:</h5></Col>
                            <Col>
                                <RadioGroup row aria-labelledby="demo-row-radio-buttons-group-label" name="row-radio-buttons-group">
                                    <FormControlLabel value="Female" control={<Radio />} label="Female" className="my-0" />
                                    <FormControlLabel value="Male" control={<Radio />} label="Male" className="my-0" />
                                </RadioGroup>
                            </Col>
                        </Row>
                        <Row className="align-items-center justify-content-start">
                            <Col xl="2" sm="2"><h5 className="mb-1 text-start">Type&nbsp;:</h5></Col>
                            <Col>
                                <RadioGroup row aria-labelledby="demo-row-radio-buttons-group-label" name="row-radio-buttons-group">
                                    <FormControlLabel value="Adults" control={<Radio />} label="Adults" className="my-0" />
                                    <FormControlLabel value="Heifers" control={<Radio />} label="Heifers" className="my-0" />
                                    <FormControlLabel value="Calf" control={<Radio />} label="Calf" className="my-0" />
                                    <FormControlLabel value="Both" control={<Radio />} label="Both" className="my-0" />
                                </RadioGroup>
                            </Col>
                        </Row>
                        <Row className="align-items-center justify-content-start">
                            <Col xl="2" md="2"><h5 className="mb-1 text-start">Status&nbsp;:</h5></Col>
                            <Col>
                                <RadioGroup row aria-labelledby="demo-row-radio-buttons-group-label" name="row-radio-buttons-group">
                                    <FormControlLabel value="All" control={<Radio />} label="All" className="my-0" />
                                    <FormControlLabel value="Open" control={<Radio />} label="Open" className="my-0" />
                                    <FormControlLabel value="Preg" control={<Radio />} label="Preg" className="my-0" />
                                    <FormControlLabel value="Milk" control={<Radio />} label="Milk" className="my-0" />
                                    <FormControlLabel value="Dry" control={<Radio />} label="Dry" className="my-0" />
                                </RadioGroup>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Container>
                        <Row className="justify-content-end">
                            <Col xl="2"><button className="btn-blue-Animal mt-1">Apply</button></Col>
                        </Row>
                    </Container>
                </Modal.Footer>
            </Modal>

        </>
    )
};
