import React, { useState, useEffect } from "react";
import { Col, Container, Row, Offcanvas, Accordion, Modal } from "react-bootstrap";
import {
    FormControlLabel, Radio, RadioGroup, TextField, FormControl, InputLabel,
    // Select,
    MenuItem
} from '@mui/material';
import { ResponsiveContainer, XAxis, YAxis, Tooltip, CartesianGrid, BarChart, Bar } from "recharts";
import { downloadExcel } from "react-export-table-to-excel";
import Select from 'react-select';

import Sidenav from "../../Componet/Sidenav/Sidenav";
import menu from '../../Photo/menu.svg'
import profile from '../../Photo/profile.svg';
import save from '../../Photo/milk-union/save.svg';
import preview from '../../Photo/milk-union/preview.svg';
import close from '../../Photo/milk-union/close.svg';
import filter from '../../Photo/milk-union/filter.svg';
import { MdOutlineVpnKey } from "react-icons/md";
import { GoSignOut } from "react-icons/go";

import moment from "moment";
import Dropdown from "../../Componet/Dropdown/Dropdown";
import Table from "../../Componet/DataTable/Table";
import { STORAGE_KEY } from "../../constant/common";
import { API_URLS, serviceUrl } from "../../API/Api";
// import Select from 'react-select';
import { useNavigate } from "react-router-dom";
import BackBtn from "../../Componet/Button/BackBtn";
const { DATEWISE } = API_URLS;
const added_by = localStorage.getItem(STORAGE_KEY.jwtTOKEN);

async function SelectReport(credentials) {
    return fetch(DATEWISE.Select_Report, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            "Accept": "application/json",
            "Authorization": `Bearer ${added_by}`,
        },
        body: JSON.stringify(credentials)
    })
        .then(data => data.json());
}
async function SelectTab(credentials) {
    return fetch(DATEWISE.Select_Tab, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            "Accept": "application/json",
            "Authorization": `Bearer ${added_by}`,
        },
        body: JSON.stringify(credentials)
    })
        .then(data => data.json());
}
async function ApplyReport(credentials) {
    return fetch(DATEWISE.ReportDatewise, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            "Accept": "application/json",
            "Authorization": `Bearer ${added_by}`,
        },
        body: JSON.stringify(credentials)
    })
        .then(data => data.json());
}

export default function Datewise() {

    const API_KEY = serviceUrl;
    // const API_KEY = "https://hais.hap.in";
    const added_by = localStorage.getItem(STORAGE_KEY.jwtTOKEN);

    const [selectTab, selectTabFn] = useState("");
    const [reportName, setReportName] = useState();
    const [supervisorSearch, setSupervisorSearch] = useState("");
    const [supervisor1Search, setSupervisor1Search] = useState("");
    const [Sddmmyyyy, setSDdMmYyyy] = useState("Date");
    const [sari, setSari] = useState("");
    const [search, setSearch] = useState("");
    const [editName, setEditName] = useState("");
    const [bene1, setBene1] = useState("");
    const [searchSari, setSearchSari] = useState("");
    const [progressData, setProgressData] = useState("0%");
    const [species, setSpecies] = useState("")
    const [breed, setBreed] = useState("")
    const [status, setStatus] = useState("")
    const [type, setType] = useState("")
    const [menuName, setMenuName] = useState("")
    const navigate = useNavigate()
    const logOut = () => { navigate("/") }
    const [fld, setFld] = useState(false)
    const [dateModal, setDateModal] = useState("")
    const [reportExcelName, setReportExcelName] = useState("")
    const [reportExcelTab, setReportExcelTab] = useState("")
    const [excelReportName, setExcelReportName] = useState("")
    const [total, setTotal] = useState(false)

    const [chartValue, setchartValue] = useState([])
    const [reportList, setReportList] = useState([]);
    const [fatchTabList, setFatchTabList] = useState([]);
    const [tabList, setTabList] = useState([]);
    const [speciesList, setSpeciesList] = useState([]);
    const [breedList, setBreedList] = useState([]);
    const [sariList, setSariList] = useState([]);
    const [sariIDList, setSariIDList] = useState([]);
    const [rowsDateList, setRowsDateList] = useState([]);
    const [rowsList, setRowsList] = useState([]);
    const [dateList, setDateList] = useState([]);
    const [herdList, setHerdList] = useState([]);
    const [herdData, setHerdData] = useState([]);
    const [jashList, setJashList] = useState([]);
    const [monthList, setMonthList] = useState([]);
    const [subDateWise, setSubDateWise] = useState([])
    const [yearList, setYearList] = useState([]);
    const [subValue, setSubValue] = useState([])
    const [herdlistcolumn, setDynamicList] = useState([]);
    const [chartData, setChartData] = useState([])
    const [chartDataList, setChartDataList] = useState([])
    const [subFatchList, setSubFatchList] = useState([])
    const [filterListData, setFilterListData] = useState([])

    const [filterList, setFilterList] = useState([]);
    const [searchValue, setSeachValue] = useState();

    const [FldCode, setFldCode] = useState("")

    const [showGraph, setShowGraph] = useState(true)
    const [isLoading, setIsLoading] = useState(false);
    const [herdModal, setHerdModal] = useState(false);
    const [centerShow, setCenterShow] = useState(false);
    const [headerShow, setHeaderShow] = useState(false);
    const [modalShow, setModalShow] = useState(false);
    const [isCheckAll, setIsCheckAll] = useState(false);
    const [datashow, setDataShow] = useState(true);
    const [monthshow, setMonthShow] = useState(false);
    const [yearshow, setYearShow] = useState(false);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => { setHeaderShow(!headerShow); }

    const lastWeek = new Date();
    lastWeek.setDate(lastWeek.getDate() - 7);

    const selectedFromDate = lastWeek.toISOString().slice(0, 10);
    const selectedToDate = new Date().toISOString().slice(0, 10);
    console.log(rowsDateList)

    const menuitm = ['Average', 'Average ± Std.Dev', 'Average ± Std.Err', 'Count', 'Max', 'Min', 'Precent', 'Std.Dev', 'Std.Err', 'Total', 'Median', 'Mode'];
    const menuitm1 = ['Male', 'Female'];

    const fatchSelectFn = async () => {
        const res = await SelectReport();
        if (res) {
            setReportList(res);
        }

        const res1 = await SelectTab();
        if (res1) {
            setFatchTabList(res1);
        }

        fetch(`${API_KEY}/species/getall`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`
            },
        })
            .then(res => res.json())
            .then((result) => { setSpeciesList(result); })


        fetch(`${API_KEY}/breed/getall`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`
            },
        })
            .then(res => res.json())
            .then((result) => { setBreedList(result); })

        fetch(`${API_KEY}/straw-management/getSire`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`
            },
        })
            .then(res => res.json())
            .then((result) => { setSariList(result); })
    }

    useEffect(() => {
        setSDdMmYyyy("Date");
        setDataShow(true);
        setMonthShow(false);
        setYearShow(false);
        setHeaderShow(false);
        fatchSelectFn();
        DateFn();
    }, []);


    const selectFn = (e) => {
        selectTabFn(e);
        if (e) {
            fetch(`${API_KEY}/reportUnion/fillTab`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    "Accept": "application/json",
                    "Authorization": `Bearer ${added_by}`
                },
                body: JSON.stringify({ Lot: 0, Route: 0, Zone: 0, tab: e })
            })
                .then(res => res.json())
                .then((result) => { setTabList(result); setFilterList(result) })

            fetch(`${API_KEY}/reportUnion/fillTab`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    "Accept": "application/json",
                    "Authorization": `Bearer ${added_by}`,
                },
                body: JSON.stringify({ Lot: 0, Route: 0, Zone: 0, tab: "Supervisor" })
            })
                .then(res => res.json())
                .then((result) => { setSubFatchList(result); })

        }
    }

    const colums = [
        {
            name: "Center Name",
            selector: "Center Name",
        },
        {
            name: "ID",
            selector: "ID",
            width: "40px",
        },
    ]

    const paravetColums = [
        {
            name: "ID",
            selector: "ID",
            width: "70px"
        },
        {
            name: "Code",
            selector: "code",
            width: "100px",
        },
        {
            name: "Paravet Name",
            selector: "Paravet Name",
        },
    ]

    const districtColums = [
        {
            name: "Taluka Name",
            selector: "Taluka Name",
        },
        {
            name: "ID",
            selector: "ID",
            width: "50px",
        }
    ];

    const doctorColms = [
        {
            name: "Doctor Name",
            selector: "Doctor Name",
        },
        {
            name: "ID",
            selector: "ID",
            width: "50px",
        }
    ];

    const monthColms = [
        {
            name: "Month",
            cell: (e) => <p className="m-0">{moment(e).format("MMM-YYYY")}</p>,
        },
    ];

    const yearColms = [
        {
            name: "Year",
            cell: (e) => <p className="m-0">{moment(e).format("YYYY")}</p>,
        },
    ];

    const dscColms = [
        {
            name: "Lot Name",
            selector: "Lot Name",
        },
        {
            name: "Lot No",
            selector: "Lot No",
            width: "50px",
        },
        {
            name: "ID",
            selector: "ID",
            width: "50px",
        }
    ];

    const ProjectColms = [
        {
            name: "ID",
            selector: "ID",
            width: "40px",
        },
        {
            name: "Project Name",
            selector: "Project Name",
        },
    ];
    const callCenterColms = [
        {
            name: "ID",
            selector: "ID",
            width: "80px",
        },
        {
            name: "Agent Name",
            selector: "Agent Name",
        },
    ];

    const SariColms = [
        {
            name: "Name",
            selector: "Name",
        },
        {
            name: "ID",
            selector: "id",
            width: "40px",
        },
    ]
    const dateColms = [
        {
            name: "Date",
            cell: (e) => <p className="m-0">{moment(e).format("MM-DD-YYYY")}</p>,
        },
    ];

    const handledExcel = () => {
        //     const opt = `Report Detail ${selectedFromDate}`;
        //     const header = Object.keys(herdData).map((key, e) => (key));

        //     downloadExcel({
        //         fileName: opt,
        //         sheet: "data",
        //         tablePayload: {
        //             header,
        //             body: herdList
        //         }
        //     });
        // }
        // else {
        //     alert("Not Data Found");
        // }
        console.log(excelReportName)
        const dateShare = rowsDateList === "" ? selectedFromDate : rowsDateList;
        const opt = `Datewise Detail ${dateShare}`;
        const ReportName = `Report Name:- ${excelReportName}`;
        const ReportTab = `Report Tab:- ${selectTab}`;
        if (centerShow === true && herdList.length > 0) {
            const header = Object.keys(herdList[0]);

            const csvContent = `${opt}\n${ReportName}\n${ReportTab}\n${header.join(",")}\n${herdList.map(row => Object.values(row).join(",")).join("\n")}`;

            const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
            const link = document.createElement("a");
            const url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", `${opt}.csv`);
            link.style.visibility = "hidden";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } else {
            alert("No Data Found");
        }
    }

    const SelectedRowFn = (rows) => {
        setRowsList(rows.selectedRows);
    }
    const SelectedRowFn1 = (rows) => {
        setRowsDateList(rows.selectedRows);
    }
    const SelectedRowFn2 = (rows) => {
        setSariIDList(rows.selectedRows);
    }
    const DateFn = () => {

        const date1 = new Date(document.getElementById("date-1").value);
        const date2 = new Date(document.getElementById("date-2").value);

        if (date1.getTime() && date2.getTime()) {

            function dateRange(date1, date2, steps = 1) {
                const dateArray = [];
                let currentDate = date1;

                while (currentDate <= date2) {
                    dateArray.push(moment(currentDate).format("L"));
                    currentDate.setUTCDate(currentDate.getUTCDate() + steps);
                }
                return dateArray
            }
            const dates = dateRange(date1, date2);
            setDateList(dates);

            // start year - - 
            var startDate = document.getElementById("date-1").value;
            var endDate = document.getElementById("date-2").value;
            var startDate1 = moment(startDate).format("YYYY");
            var endDate1 = moment(endDate).format("YYYY");
            const Year = [];

            for (let i = startDate1; i <= endDate1; i++) {
                Year.push(moment(`${i}`).format("MM-DD-YYYY"));
            }
            setYearList(Year);
            // end year - - 

            // Start Month - -
            var jash1 = document.getElementById("date-1").value;
            var jash2 = document.getElementById("date-2").value;
            var startDate = jash1;
            var endDate = jash2;
            var month = [];
            var d0 = startDate.split('-');
            var d1 = endDate.split('-');

            for (var y = d0[0]; y <= d1[0]; y++) {
                for (var m = d0[1]; m <= 12; m++) {
                    month.push(moment(y + "-" + m + "-1").format("MM-DD-YYYY"));
                    if (y >= d1[0] && m >= d1[1]) break;
                };
                d0[1] = 1;
            };
            setMonthList(month);
            // End Month - -

        }
    }


    // const handleDownloadExcel = () => {
    //     const opt = editName == "Eligible A.I" ? `Eligible AI ${selectedFromDate}` : `${editName} ${selectedFromDate}`;
    //     // const header = ["Sl No", "Tag ID", "Old Tag ID", "Entry Type", "A.I Date", "Service Time", "HI", "No of A.I", "Calving Date", "Sire Name", "Sire Deduction Code", "A.I Cost",
    //     //     "Total A.I Cost", "Sire SAP Code", "A.I Dose", "Repeat", "Breeding Status", "Species", "Breed", "Farmer Name", "Farmer Code", "SAP Code", "Mobile Number", "Route Name",
    //     //     "DCS Name", "DCS Code", "AIT Code", "AIT Name", "Center Name", "Disposal Type", "Owner Suspended", "DCS Closed", "SAP Sending Date", "SAP Status", "SAP Message",
    //     //     "SAP Transaction No", "SAP Order Number"
    //     // ];
    //     var header = []
    //     ExcelColums.map((e) => {
    //         header.push(e.name)
    //     })

    //     downloadExcel({
    //         fileName: opt,
    //         sheet: "data",
    //         tablePayload: {
    //             header,
    //             body: subDateWise
    //         }
    //     });
    // }

    // const handleDownloadExcel = () => {
    //     const opt = editName === "Eligible A.I" ? `Eligible AI ${selectedFromDate}` : `${editName} ${selectedFromDate}`;

    //     const header = ExcelColums.map((column) => column.name);

    //     downloadExcel({
    //         fileName: opt,
    //         sheet: "data",
    //         tablePayload: {
    //             header,
    //             body: subDateWise
    //         }
    //     });
    // }
    const handleDownloadExcel = () => {
        const dateShare = rowsDateList === "" ? selectedFromDate : rowsDateList;
        const opt = `Datewise Detail ${dateShare}`;
        const ReportName = `Report Name:- ${excelReportName}`;
        const ReportTab = `Report Tab:- ${reportExcelTab}`;
        if (centerShow === true && herdList.length > 0) {
            const header = Object.keys(subDateWise[0]);

            const csvContent = `${opt}\n${ReportName}\n${ReportTab}\n${header.join(",")}\n${subDateWise.map(row => Object.values(row).join(",")).join("\n")}`;

            const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
            const link = document.createElement("a");
            const url = URL.createObjectURL(blob);
            link.setAttribute("href", url);
            link.setAttribute("download", `${opt}.csv`);
            link.style.visibility = "hidden";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } else {
            alert("No Data Found");
        }
    };

    const ApplyFn = async () => {
        setFld(false)
        console.log(rowsList);
        let ids = (rowsList.map(i => i.ID)).join(',');
        let sariids = (sariIDList.map(i => i.id)).join(',');
        const formattedDateList = rowsDateList === "" ? selectedFromDate : rowsDateList;
        const moment = require('moment');

        const dateShare = formattedDateList.map(dateString => {
            const formattedDate = moment(dateString, 'MM/DD/YYYY').format('MM-DD-YYYY');
            return formattedDate;
        });

        if (ids) {
            console.log("abc");
            setIsLoading(true)
            fetch(DATEWISE.ReportDatewise, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    "Accept": "application/json",
                    "Authorization": `Bearer ${added_by}`,
                },
                body: JSON.stringify({
                    Breed: breed.toString(), CalculateType: "", DT: dateShare.toString(), DateType: Sddmmyyyy, Report_by: selectTab, Reportcode: parseInt(reportName), SQL: "", Sire: sariids ? sariids : "",
                    Species: species ? species : "", Status: status ? status : " ", TMWhereSQL: ids, TotCalculateType: "", UnknownSireFlag: "", UserID: "201", semenType: sari ? sari : 2
                })
            })
                .then(response => {
                    const contentLength = response.headers.get('content-length');
                    let loaded = 0;
                    return new Response(
                        new ReadableStream({
                            start(controller) {
                                const reader = response.body.getReader();
                                read();
                                function read() {
                                    reader.read()
                                        .then((progressEvent) => {
                                            if (progressEvent.done) {
                                                controller.close();
                                                return;
                                            }
                                            loaded += progressEvent.value.byteLength;
                                            const percentageComplete = Math.round(loaded / contentLength * 100) + '%';
                                            setProgressData(percentageComplete)
                                            controller.enqueue(progressEvent.value);
                                            read();
                                        })
                                }
                            }
                        })
                    );
                })
                .then(data => data.json())
                .then(result => {
                    console.log(result[result.length - 1]);
                    setModalShow(false)
                    setHerdData(result[0]);
                    const column = Object.keys(result[0]);
                    var last = result[result.length - 1];
                    last["Route Code"] = "Total";
                    setDynamicList(column);
                    setHerdList(result);
                    setIsLoading(false);
                    setCenterShow(true);
                    setProgressData("0%");
                    var last = result[result.length - 1];
                    const data = Object.values(last)
                    subValue.push(data)
                    setBene1(last['Total']);
                    chartData.push(result[0])
                })
                .catch((err) => setIsLoading(false))

        }
    }

    const selectReport = (e) => {
        console.log(e)
        setReportName(e)
        const filteredReport = reportList.find(report => report.Code === e);
        if (filteredReport) {
            setEditName(filteredReport.Name);
            setFldCode(filteredReport.FldCode);
        }
    }


    const editFn = (e, val) => {
        console.log(val)
        var keys = Object.values(e);
        var dte = keys[keys.length - 1];

        if (dte === val) {
            setTotal(true)
        }
        else {
            setTotal(false)
        }
        const date = new Date(val);
        setDateModal(val)

        const formattedDate = date.toLocaleDateString('en-US', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit'
        });

        const date1 = formattedDate;
        const parts = date1.split("/");
        const formattedDate1 = `${parts[0]}-${parts[1]}-${parts[2]}`;
        setIsLoading(true)
        let id = e.ID;
        const dateShare = rowsDateList == "" ? selectedFromDate : rowsDateList;
        let ids = (rowsList.map(i => i.ID)).join(',');

        if (val == "Total") {
            const formattedDateList = rowsDateList == "" ? selectedFromDate : rowsDateList;
            const dateShare = formattedDateList.map(dateString => {
                const formattedDate = moment(dateString, 'MM/DD/YYYY').format('MM-DD-YYYY');
                return formattedDate;
            });

            let ids = (rowsList.map(i => i.ID)).join(',');
            fetch(`${API_KEY}/reportUnion/MilkUnionReportDetails`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    "Accept": "application/json",
                    "Authorization": `Bearer ${added_by}`
                },
                body: JSON.stringify({
                    Breed: breed.toString(),
                    CalculateType: "",
                    DT: dateShare.toString(),
                    DateType: Sddmmyyyy,
                    FieldValue: FldCode,
                    RegFlag: "",
                    Report_by: selectTab,
                    Reportcode: reportName,
                    Sire: "",
                    Species: "",
                    Status: "",
                    TMWhereSQL: ids,
                    TotCalculateType: "",
                    UnknownSireFlag: "",
                    UserID: 201,
                    requestType: "DATEWISE",
                    semenType: sari
                })
            })
                .then(res => res.json())
                .then((result) => { setSubDateWise(result); setHerdModal(true); setIsLoading(false) })
                .catch((err) => setIsLoading(false))
        }
        else {
            const dateS123 = moment(val, 'DD-MM-YY').format('MM-DD-YYYY');
            fetch(`${API_KEY}/reportUnion/MilkUnionReportDetails`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    "Accept": "application/json",
                    "Authorization": `Bearer ${added_by}`
                },
                body: JSON.stringify({
                    Breed: breed.toString(),
                    CalculateType: "",
                    DT: val ? dateS123 : dateShare.toString(),
                    // DT: dateS123,
                    DateType: Sddmmyyyy,
                    FieldValue: FldCode,
                    RegFlag: "",
                    Report_by: selectTab,
                    Reportcode: reportName,
                    Sire: "",
                    Species: "",
                    Status: "",
                    TMWhereSQL: id == null ? ids : id,
                    TotCalculateType: "",
                    UnknownSireFlag: "",
                    UserID: 201,
                    requestType: "DATEWISE",
                    semenType: sari
                })
            })
                .then(res => res.json())
                .then((result) => { setSubDateWise(result); setHerdModal(true); setIsLoading(false) })
                .catch((err) => setIsLoading(false))
        }
    }

    const herdColum = [
        {
            name: rowsDateList.map((date) => (date)),
            rowsDateList: subValue.map((e) => Object.values(e).map((e1) => e1)),
        },
    ]

    const ExcelColums = Object.keys(subDateWise.length > 0 && subDateWise[0]).map(key => ({
        name: key,
        selector: key,
        cell: (e) => {
            if (e) {
                return <div className=''>{e[key]}</div>;
            }
            return null;
        },
        width: "140px",
    }));

    // const ExcelColums = [
    //     {
    //         name: "TAGID/Date",
    //         selector: "TAGID/Date",
    //         width: "120px"
    //     },
    //     {
    //         name: "	1-M",
    //         selector: "1-M",
    //         cell: (e) => <div className='ms-auto'>{e["1-M"]}</div>,
    //         width: "60px",
    //     },
    //     {
    //         name: "1-E",
    //         selector: "1-E",
    //         cell: (e) => <div className='ms-auto'>{e["1-E"]}</div>,
    //         width: "60px",
    //     },
    //     {
    //         name: "2-M",
    //         selector: "2-M",
    //         cell: (e) => <div className='ms-auto'>{e["2-M"]}</div>,
    //         width: "60px",
    //     },
    //     {
    //         name: "2-E",
    //         selector: "2-E",
    //         cell: (e) => <div className='ms-auto'>{e["2-E"]}</div>,
    //         width: "60px",
    //     },
    //     {
    //         name: "3-M",
    //         selector: "3-M",
    //         cell: (e) => <div className='ms-auto'>{e["3-M"]}</div>,
    //         width: "60px",
    //     },
    //     {
    //         name: "3-E",
    //         selector: "3-E",
    //         cell: (e) => <div className='ms-auto'>{e["3-E"]}</div>,
    //         width: "60px",
    //     },
    //     {
    //         name: "4-E",
    //         selector: "4-E",
    //         cell: (e) => <div className='ms-auto'>{e["4-E"]}</div>,
    //         width: "60px",
    //     },
    //     {
    //         name: "4-M",
    //         selector: "4-M",
    //         cell: (e) => <div className='ms-auto'>{e["4-M"]}</div>,
    //         width: "60px",
    //     },
    //     {
    //         name: "	5-E",
    //         selector: "5-E",
    //         cell: (e) => <div className='ms-auto'>{e["5-E"]}</div>,
    //         width: "60px",
    //     },
    //     {
    //         name: "	5-M",
    //         selector: "5-M",
    //         cell: (e) => <div className='ms-auto'>{e["5-M"]}</div>,
    //         width: "60px",
    //     },
    //     {
    //         name: "	6-E",
    //         selector: "6-E",
    //         cell: (e) => <div className='ms-auto'>{e["6-E"]}</div>,
    //         width: "60px",
    //     },
    //     {
    //         name: "	6-M",
    //         selector: "6-M",
    //         cell: (e) => <div className='ms-auto'>{e["6-M"]}</div>,
    //         width: "60px",
    //     },
    //     {
    //         name: "	7-E",
    //         selector: "7-E",
    //         cell: (e) => <div className='ms-auto'>{e["7-E"]}</div>,
    //         width: "60px",
    //     },
    //     {
    //         name: "	7-M",
    //         selector: "7-M",
    //         cell: (e) => <div className='ms-auto'>{e["7-M"]}</div>,
    //         width: "60px",
    //     },
    //     {
    //         name: "	8-E",
    //         selector: "8-E",
    //         cell: (e) => <div className='ms-auto'>{e["8-E"]}</div>,
    //         width: "60px",
    //     },
    //     {
    //         name: "	8-M",
    //         selector: "8-M",
    //         cell: (e) => <div className='ms-auto'>{e["8-M"]}</div>,
    //         width: "60px",
    //     },
    //     {
    //         name: "	9-E",
    //         selector: "9-E",
    //         cell: (e) => <div className='ms-auto'>{e["9-E"]}</div>,
    //         width: "60px",
    //     },
    //     {
    //         name: "	9-M",
    //         selector: "9-M",
    //         cell: (e) => <div className='ms-auto'>{e["9-M"]}</div>,
    //         width: "70px",
    //     },
    //     {
    //         name: "	10-E",
    //         selector: "10-E",
    //         cell: (e) => <div className='ms-auto'>{e["10-E"]}</div>,
    //         width: "70px",
    //     },
    //     {
    //         name: "	10-M",
    //         selector: "10-M",
    //         cell: (e) => <div className='ms-auto'>{e["10-M"]}</div>,
    //         width: "70px",
    //     },
    //     {
    //         name: "	11-M",
    //         selector: "11-M",
    //         cell: (e) => <div className='ms-auto'>{e["11-M"]}</div>,
    //         width: "70px",
    //     },
    //     {
    //         name: "11-E",
    //         selector: "11-E",
    //         cell: (e) => <div className='ms-auto'>{e["11-E"]}</div>,
    //         width: "70px",
    //     },
    //     {
    //         name: "12-M",
    //         selector: "12-M",
    //         cell: (e) => <div className='ms-auto'>{e["12-M"]}</div>,
    //         width: "70px",
    //     },
    //     {
    //         name: "	12-E",
    //         selector: "12-E",
    //         cell: (e) => <div className='ms-auto'>{e["12-E"]}</div>,
    //         width: "70px",
    //     },
    //     {
    //         name: "	13-M",
    //         selector: "13-M",
    //         cell: (e) => <div className='ms-auto'>{e["13-M"]}</div>,
    //         width: "70px",
    //     },
    //     {
    //         name: "	13-E",
    //         selector: "13-E",
    //         cell: (e) => <div className='ms-auto'>{e["13-E"]}</div>,
    //         width: "70px",
    //     },
    //     {
    //         name: "	14-E",
    //         selector: "14-E",
    //         cell: (e) => <div className='ms-auto'>{e["14-E"]}</div>,
    //         width: "70px",
    //     },
    //     {
    //         name: "	14-M",
    //         selector: "14-M",
    //         cell: (e) => <div className='ms-auto'>{e["14-M"]}</div>,
    //         width: "70px",
    //     },
    //     {
    //         name: "	15-E",
    //         selector: "15-E",
    //         cell: (e) => <div className='ms-auto'>{e["15-E"]}</div>,
    //         width: "60px",
    //     },
    //     {
    //         name: "	15-M",
    //         selector: "15-M",
    //         cell: (e) => <div className='ms-auto'>{e["15-M"]}</div>,
    //         width: "70px",
    //     },
    //     {
    //         name: "	16-E",
    //         selector: "16-E",
    //         cell: (e) => <div className='ms-auto'>{e["16-E"]}</div>,
    //         width: "70px",
    //     },
    //     {
    //         name: "	16-M",
    //         selector: "16-M",
    //         cell: (e) => <div className='ms-auto'>{e["16-M"]}</div>,
    //         width: "70px",
    //     },
    //     {
    //         name: "	17-E",
    //         selector: "17-E",
    //         cell: (e) => <div className='ms-auto'>{e["17-E"]}</div>,
    //         width: "70px",
    //     },
    //     {
    //         name: "	17-M",
    //         selector: "17-M",
    //         cell: (e) => <div className='ms-auto'>{e["17-M"]}</div>,
    //         width: "70px",
    //     },
    //     {
    //         name: "	18-E",
    //         selector: "18-E",
    //         cell: (e) => <div className='ms-auto'>{e["18-E"]}</div>,
    //         width: "70px",
    //     },
    //     {
    //         name: "18-M",
    //         selector: "18-M",
    //         cell: (e) => <div className='ms-auto'>{e["18-M"]}</div>,
    //         width: "70px",
    //     },
    //     {
    //         name: "	19-E",
    //         selector: "9-E",
    //         cell: (e) => <div className='ms-auto'>{e["19-E"]}</div>,
    //         width: "70px",
    //     },
    //     {
    //         name: "	19-M",
    //         selector: "19-M",
    //         cell: (e) => <div className='ms-auto'>{e["19-M"]}</div>,
    //         width: "70px",
    //     },
    //     {
    //         name: "	20-E",
    //         selector: "20-E",
    //         cell: (e) => <div className='ms-auto'>{e["20-E"]}</div>,
    //         width: "70px",
    //     },
    //     {
    //         name: "	20-M",
    //         selector: "20-M",
    //         cell: (e) => <div className='ms-auto'>{e["20-M"]}</div>,
    //         width: "70px",
    //     },
    //     {
    //         name: "	21-M",
    //         selector: "21-M",
    //         cell: (e) => <div className='ms-auto'>{e["21-M"]}</div>,
    //         width: "70px",
    //     },
    //     {
    //         name: "21-E",
    //         selector: "21-E",
    //         cell: (e) => <div className='ms-auto'>{e["21-E"]}</div>,
    //         width: "70px",
    //     },
    //     {
    //         name: "22-M",
    //         selector: "22-M",
    //         cell: (e) => <div className='ms-auto'>{e["22-M"]}</div>,
    //         width: "60px",
    //     },
    //     {
    //         name: "	22-E",
    //         selector: "22-E",
    //         cell: (e) => <div className='ms-auto'>{e["22-E"]}</div>,
    //         width: "60px",
    //     },
    //     {
    //         name: "	23-M",
    //         selector: "23-M",
    //         cell: (e) => <div className='ms-auto'>{e["23-M"]}</div>,
    //         width: "70px",
    //     },
    //     {
    //         name: "	23-E",
    //         selector: "23-E",
    //         cell: (e) => <div className='ms-auto'>{e["23-E"]}</div>,
    //         width: "70px",
    //     },
    //     {
    //         name: "	24-E",
    //         selector: "24-E",
    //         cell: (e) => <div className='ms-auto'>{e["24-E"]}</div>,
    //         width: "70px",
    //     },
    //     {
    //         name: "	24-M",
    //         selector: "24-M",
    //         cell: (e) => <div className='ms-auto'>{e["24-M"]}</div>,
    //         width: "70px",
    //     },
    //     {
    //         name: "	25-E",
    //         selector: "25-E",
    //         cell: (e) => <div className='ms-auto'>{e["25-E"]}</div>,
    //         width: "70px",
    //     },
    //     {
    //         name: "	25-M",
    //         selector: "25-M",
    //         cell: (e) => <div className='ms-auto'>{e["25-M"]}</div>,
    //         width: "70px",
    //     },
    //     {
    //         name: "	26-E",
    //         selector: "26-E",
    //         cell: (e) => <div className='ms-auto'>{e["26-E"]}</div>,
    //         width: "70px",
    //     },
    //     {
    //         name: "	26-M",
    //         selector: "26-M",
    //         cell: (e) => <div className='ms-auto'>{e["26-M"]}</div>,
    //         width: "70px",
    //     },
    //     {
    //         name: "	27-E",
    //         selector: "27-E",
    //         cell: (e) => <div className='ms-auto'>{e["27-E"]}</div>,
    //         width: "70px",
    //     },
    //     {
    //         name: "	27-M",
    //         selector: "27-M",
    //         cell: (e) => <div className='ms-auto'>{e["27-M"]}</div>,
    //         width: "70px",
    //     },
    //     {
    //         name: "	28-E",
    //         selector: "28-E",
    //         cell: (e) => <div className='ms-auto'>{e["28-E"]}</div>,
    //         width: "70px",
    //     },
    //     {
    //         name: "28-M",
    //         selector: "28-M",
    //         cell: (e) => <div className='ms-auto'>{e["28-M"]}</div>,
    //         width: "70px",
    //     },
    //     {
    //         name: "	29-E",
    //         selector: "29-E",
    //         cell: (e) => <div className='ms-auto'>{e["29-E"]}</div>,
    //         width: "70px",
    //     },
    //     {
    //         name: "	29-M",
    //         selector: "29-M",
    //         cell: (e) => <div className='ms-auto'>{e["29-M"]}</div>,
    //         width: "70px",
    //     },
    //     {
    //         name: "	30-E",
    //         selector: "30-E",
    //         cell: (e) => <div className='ms-auto'>{e["30-E"]}</div>,
    //         width: "70px",
    //     },
    //     {
    //         name: "	30-M",
    //         selector: "30-M",
    //         cell: (e) => <div className='ms-auto'>{e["30-E"]}</div>,
    //         width: "70px",
    //     },
    //     {
    //         name: "total",
    //         selector: "total"
    //     },

    // ]

    const graphHandle = () => {
        setShowGraph(prev => !prev)
    }

    const Selectdmy = (e) => {
        setSDdMmYyyy(e);

        if (e == "Month") {
            setMonthShow(true); setDataShow(false); setYearShow(false);
        }
        else if (e == "Year") {
            setYearShow(true); setDataShow(false); setMonthShow(false);
        }
        else if (e == "Data") {
            setDataShow(true); setMonthShow(false); setYearShow(false);
        }
    }

    // const ThData = () => {
    //     return herdlistcolumn.map((data) => {
    //         return <th key={data} >{data}</th>
    //     })
    // }

    // const tdData = () => {
    //     return herdList.map((data) => {
    //         return (
    //             <tr >
    //                 {
    //                     herdlistcolumn.map((v) => {
    //                         if (v === "Total") {
    //                             return <td onClick={() => editFn(data, v)}>{data[v]}</td>;
    //                         } else {
    //                             return <td onClick={() => editFn(data, v)}>{data[v]}</td>;
    //                         }
    //                     })
    //                 }
    //             </tr>
    //         );
    //     });
    // };


    const getColumnWidth = (column, data) => {
        const numColumns = herdlistcolumn.length;
        const remainingWidth = 100;

        if (numColumns > 1) {
            const columnWidth = remainingWidth / numColumns;
            return `${columnWidth}%`;
        } else {
            return `${remainingWidth}%`;
        }
    };


    const ThData = () => {
        return herdlistcolumn.map((data) => {
            const columnWidth = getColumnWidth(data, herdList);
            return <th key={data} style={{ width: columnWidth }}>{data}</th>;
        });
    }

    console.log(filterList)
    const tdData = () => {
        return (filterListData.length > 0 ? filterListData : herdList).map((data) => {
            console.log(data)
            return (
                <tr key={data.ID}>
                    {herdlistcolumn.map((v, index) => {
                        const columnWidth = getColumnWidth(v, herdList);
                        let textAlign = "left";
                        if (v.match(/^(Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec)-\d{4}$/) ||
                            v > 2000 ||
                            v == "Total" ||
                            v.match(/^\d{2}-\d{2}-\d{2}$/)) {
                            textAlign = "right";
                        }

                        return (
                            <td
                                key={index}
                                style={{ width: columnWidth, textAlign: textAlign }}
                                onClick={() => editFn(data, v)}
                            >
                                {data[v]}
                            </td>
                        );
                    })}
                </tr>
            );
        });
    };


    const selectSupervisor = (e) => {
        if (e) {
            fetch(`${API_KEY}/reportUnion/fillTab`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    "Accept": "application/json",
                    "Authorization": `Bearer ${added_by}`,
                },
                body: JSON.stringify({ Lot: 0, Route: 0, Zone: e, tab: "Paravet" })
            })
                .then(res => res.json())
                .then((result) => { setTabList(result); })
        }
    }
    if (!chartDataList.length) {
        let lastrecord = herdList[herdList.length - 1];
        chartData.forEach(e => {
            // const { ID, "Paravet Code": paravetCode, "Paravet Name": paravetName, "Distric Name": districtName, "Distric Code": districtCode, "Route Code": routeCode, "Route Name": routeName, "Total": total, ...data } = e;
            // Object.entries(data).forEach(([name, chartNum]) => {
            //     chartDataList.push({ name, chartNum });
            // });

            for (const [name, value] of Object.entries(lastrecord)) {
                console.log(name)
                if (["ID", "Paravet Name", "Paravet Code", "Total", "Route Code", "Total", "Route Name"].includes(name)) {
                    continue;
                }

                const chartNum = value;
                chartDataList.push({ name, chartNum });
            }
        });
    }

    console.log(chartDataList)

    const searchFn = (e, key) => {
        const data = e.target.value;
        const abc = tabList;
        let filteredData = [];

        if (data != "" || data != null) {
            if (selectTab === "Center" || selectTab === "Route") {
                filteredData = abc.filter((item) => {
                    return (
                        item["Center Name"].toLowerCase().includes(data.toLowerCase())
                    );
                });

            } else if (selectTab === "District" || selectTab === "Taluka" || selectTab === "Village" || selectTab === "Hamlet") {
                filteredData = abc.filter((item) => {
                    return item["Taluka Name"].toLowerCase().includes(data.toLowerCase());
                });
            } else if (selectTab === "Doctor/VO") {
                filteredData = abc.filter((item) => {
                    return item["Doctor Name"].toLowerCase().includes(data.toLowerCase());
                });
            } else if (selectTab === "DCS/Lot") {
                filteredData = abc.filter((item) => {
                    return item["DCS/Lot Name"].toLowerCase().includes(data.toLowerCase());
                });
            } else if (selectTab === "Paravet") {
                filteredData = abc.filter((item) => {
                    return item["Paravet Name"].toLowerCase().includes(data.toLowerCase()) || item["code"].toLowerCase().includes(data.toLowerCase() || item["ID"].includes(data));
                });
            }
            else if (selectTab === "Project Wise") {
                filteredData = abc.filter((item) => {
                    return item["Project Name"].toLowerCase().includes(data.toLowerCase());
                });
            }
            else if (selectTab === "Call Center") {
                filteredData = abc.filter((item) => {
                    return item["Agent Name"].toLowerCase().includes(data.toLowerCase());
                });
            }
        }
        else {
            filteredData = tabList
        }

        setFilterList(filteredData)

        if (key == "superwiser") {
            setSupervisorSearch(data)
        } else {
            setSeachValue(data);
        }
    };


    const SearchTableData = (e) => {
        const data = e.toLowerCase();
        setSearch(data);
        const abc = herdList

        let filteredData = [];

        if (data !== "") {
            if (selectTab === "Center" || selectTab === "Route") {
                filteredData = abc.filter((item) => item["Route Name"].toLowerCase().includes(data));
            }
            else if (selectTab === "District" || selectTab === "Taluka" || selectTab === "Village" || selectTab === "Hamlet") {
                filteredData = abc.filter((item) => item["Taluka Name"].toLowerCase().includes(data));
            }
            else if (selectTab === "Doctor/VO") {
                filteredData = abc.filter((item) => item["Doctor Name"].toLowerCase().includes(data));
            } else if (selectTab === "DCS/Lot") {
                filteredData = abc.filter((item) => item["DCS/Lot Name"].toLowerCase().includes(data));
            }
            else if (selectTab === "Paravet") {
                filteredData = abc.filter((item) =>
                (item["Paravet Name"]?.toLowerCase().startsWith(data.toLowerCase()) ||
                    item["Paravet Code"]?.toLowerCase().includes(data) ||
                    String(item["ID"])?.includes(data))
                );
            }

            else if (selectTab === "Project Wise") {
                filteredData = abc.filter((item) => item["Project Name"].toLowerCase().includes(data));
            }

            setFilterListData(filteredData);
        }
        else {
            setFilterListData(herdList)
        }
    };
    return (
        <>
            <Container fluid id="DateWise">
                <Row>
                    {headerShow && (
                        <Col lg="2" className="hideNavbar">
                            <Sidenav />
                        </Col>
                    )}
                    <Col className="header" lg={headerShow ? "10" : "12"}>
                        <Container fluid>
                            <Row className="pt-1 sub-header align-items-center justify-content-between">
                                <Col md="10" sm="10" xs="10" className="py-2 d-flex align-items-center">
                                    <button style={{ border: "none" }} className="hideNavbar1" onClick={handleShow}><img src={menu} width={20} /></button>
                                    <button style={{ border: "none" }} className="hide_Navbar" onClick={() => setShow(true)}><img src={menu} width={20} /></button>
                                    <h5 className="ms-4 mt-2">Milk Union Reports / Date Wise</h5>
                                </Col>
                                <Col md="1" sm="1" xs="10" className="py-2 d-flex align-items-center text-end ms-auto">
                                    <BackBtn style={{ border: "none" }} className="hideNavbar1" lable="Back" onClick={() => { navigate('/') }} />
                                </Col>
                                <Col md="1" sm="1" xs="2" className="text-end profile_dropdown">
                                    <img src={profile} width={40} />
                                    <div className="dropdpwn_Open">
                                        <Row className="text-center align-items-center">
                                            <Col xs="12" className="menuHover mb-3">
                                                <Row >
                                                    <Col className="" xs="3">
                                                        <span className=""><MdOutlineVpnKey size={25} />
                                                        </span>
                                                    </Col>
                                                    <Col className="text-start" xs="9">
                                                        Change Password
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col xs="12" className="menuHover" onClick={logOut}>
                                                <Row>
                                                    <Col className="" xs="3">
                                                        <span className=""><GoSignOut size={25} />
                                                        </span>
                                                    </Col>
                                                    <Col className="text-start" xs="9">
                                                        Sign Out
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="">
                                <Col xl="2" md="3" lg="2">
                                    <div className="blue_bg_Animal p-1 align-items-end justify-content-center">
                                        <Row className="justify-content-center">
                                            <Col xl="11" className="mt-3">
                                                <Select
                                                    placeholder="Report Name"
                                                    onChange={(selectedOption) => { selectReport(selectedOption.value); setExcelReportName(selectedOption.label) }}
                                                    options={reportList.map((item) => ({ value: item.Code, label: item.Name }))}
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="pt-3 justify-content-center">
                                            <Col xl="11">
                                                <Select
                                                    placeholder="Tabs"
                                                    onChange={(selectedOption) => selectFn(selectedOption.value)}
                                                    options={fatchTabList.map((item) => ({ value: item.Name, label: item.Name, onClick: () => setReportExcelTab(item.Name) }))}
                                                />
                                            </Col>
                                        </Row>
                                        {
                                            selectTab == "Paravet" && <Row className="justify-content-center">
                                                <Col xl="11" className="mt-3">
                                                    <Select
                                                        placeholder="Supervisor Name"
                                                        onChange={(selectedOption) => selectSupervisor(selectedOption.value)}
                                                        options={subFatchList.map((item) => ({ value: item.ID, label: item["Paravet Name"] }))}
                                                    />
                                                </Col>
                                            </Row>
                                        }
                                        {selectTab === "Paravet" ?
                                            <Row className="pt-3 justify-content-center">
                                                <Col xl="11">
                                                    <TextField label="Search Supervisor" value={supervisorSearch} onChange={(e) => searchFn(e, "superwiser")} fullWidth variant="standard" />
                                                </Col>
                                            </Row>
                                            : ""}

                                        {selectTab ?
                                            <Row className="justify-content-center">
                                                <Col xl="11">
                                                    <TextField label={`Search ${selectTab}`} value={searchValue} onChange={(e) => { searchFn(e, "paravet"); }} fullWidth variant="standard" />
                                                </Col>
                                            </Row>
                                            : ""}


                                        <Row className="pt-3 justify-content-center">
                                            <Col>
                                                {selectTab === "Center" || selectTab === "Route" ?
                                                    <Table columns={colums} data={filterList ? filterList : tabList} height="72vh" selectableRows onSelectedRowsChange={SelectedRowFn} />
                                                    :
                                                    <>
                                                        {selectTab === "District" || selectTab === "Taluka" || selectTab === "Village" || selectTab === "Hamlet" ?
                                                            <Table columns={districtColums} data={filterList ? filterList : tabList} height="72vh" selectableRows onSelectedRowsChange={SelectedRowFn} /> : ""}
                                                        {selectTab === "Doctor/VO" ? <Table columns={doctorColms} data={filterList ? filterList : tabList} height="72vh" selectableRows onSelectedRowsChange={SelectedRowFn} /> : ""}
                                                        {selectTab === "DCS/Lot" ? <Table columns={dscColms} data={filterList ? filterList : tabList} height="72vh" selectableRows onSelectedRowsChange={SelectedRowFn} /> : ""}
                                                        {selectTab === "Paravet" ? <Table columns={paravetColums} data={filterList ? filterList : tabList} height="67vh" selectableRows onSelectedRowsChange={SelectedRowFn} /> : ""}
                                                        {selectTab === "Project Wise" ? <Table columns={ProjectColms} data={filterList ? filterList : tabList} height="72vh" selectableRows onSelectedRowsChange={SelectedRowFn} /> : ""}
                                                        {selectTab === "Call Center" ? <Table columns={callCenterColms} data={filterList ? filterList : tabList} height="72vh" selectableRows onSelectedRowsChange={SelectedRowFn} /> : ""}
                                                    </>
                                                }
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                                <Col xl="8" md="9" lg="7" className="p-0">
                                    <Row>
                                        <Col xl="12">
                                            <div className="blue_bg_Animal align-items-end justify-content-center" id={centerShow ? "" : "center"}>
                                                <>
                                                    {centerShow && (
                                                        <>
                                                            <Row className="text-start align-items-end justify-content-between ms-2 mb-2">
                                                                <Col xl="2"><TextField label="Search Paravet Name" value={search} onChange={e => SearchTableData(e.target.value)} id="standard-basic" fullWidth variant="standard" /></Col>
                                                                <Col xl="8" className="d-flex justify-content-end">
                                                                    <Col xl="2"><button className="btn-blue-Animal" onClick={() => setModalShow(true)}><img src={filter} className="me-2" width="15" />Filter</button></Col>
                                                                    <Col xl="3"><button className="btn-blue-Animal" onClick={graphHandle}><img src={save} className="me-2" width="15" />Graph</button></Col>
                                                                </Col>
                                                            </Row>
                                                            <Row>
                                                                <Col className="mx-2 mainTable" style={{ overflow: "auto", maxHeight: "46vh" }}>
                                                                    {/* <table className="overTable" border={1}>
                                                                        <thead>
                                                                            <tr className="herdHeader">
                                                                                {Object.keys(herdData).map((key, e) => {
                                                                                    return (
                                                                                        <>
                                                                                            <td className="herdTable-td ps-2 text-center"> {key}</td>
                                                                                        </>
                                                                                    )
                                                                                })}
                                                                            </tr>
                                                                        </thead>
                                                                        {(() => {
                                                                            const options = [];
                                                                            for (let i = 0; i < herdList.length; i++) {
                                                                                options.push(
                                                                                    <tr className="overTableBody">
                                                                                        {Object.values(herdList[i]).map((e) => {
                                                                                            options.push(
                                                                                                <td className="herdTable-td ps-2 text-center" onClick={() => editFn(herdList[i], e)}>{e == "0" ? null : e}</td>
                                                                                            );
                                                                                        })}
                                                                                    </tr>
                                                                                )
                                                                            }
                                                                            return options;
                                                                        })()}
                                                                    </table> */}
                                                                    <table className="overTable" border={1}>
                                                                        <thead border={1}>
                                                                            <tr className="herdHeader1">{ThData()}</tr>
                                                                        </thead>
                                                                        <tbody border={1} className="mainDatewiseTable">
                                                                            {tdData()}
                                                                        </tbody>
                                                                    </table>
                                                                </Col>
                                                            </Row>
                                                            <hr className="mb-1 mt-4" />
                                                            {
                                                                showGraph ? <Row>
                                                                    <Col>
                                                                        <ResponsiveContainer aspect={4}>
                                                                            <BarChart data={chartDataList} margin={{ right: 0, left: 0, top: 0, bottom: 65 }}>
                                                                                <CartesianGrid />
                                                                                <XAxis dataKey="name" style={{ fontSize: "10px" }} interval={0} textAnchor="end" angle={-25} />
                                                                                <YAxis type="number" />
                                                                                <Tooltip contentStyle={{ fontSize: "12px", backgroundColor: "white" }} cursor={{ fill: 'transparent' }} />
                                                                                <Bar dataKey="chartNum" fill="#8FA9F5" />
                                                                            </BarChart>
                                                                        </ResponsiveContainer>
                                                                    </Col>
                                                                </Row> : false
                                                            }

                                                        </>
                                                    )}
                                                </>
                                            </div>
                                        </Col>
                                        <Row className="align-items-end justify-content-end" id='heightOffsetStock'>
                                            <Col xs="4" xl="1" lg="3" md="4" className="p-0 justify-content-end me-0"><button className="btn-blue-Animal mt-2 justify-content-end" onClick={ApplyFn}>Apply</button></Col>
                                            <Col xs="4" xxl="2" xl="2" lg="2" md="4" className="p-0 justify-content-end"><button className="btn-blue-Animal mt-2" onClick={() => handledExcel()}>Export to Excel</button></Col>
                                            <Col xs="4" xxl="2" xl="2" lg="2" className="p-0 justify-content-end"><button className="btn-blue-Animal mt-2"><img src={preview} className="me-2" width="15" />Preview</button></Col>
                                            <Col xs="4" xxl="2" xl="2" lg="2" className="p-0 justify-content-end"><button className="btn-blue-Animal mt-2 justify-content-end"><img src={close} className="me-2" width="15" />Close</button></Col>
                                        </Row>
                                    </Row>
                                </Col>
                                <Col xl="2" lg="3" md="">
                                    <div className="blue_bg_Animal p-2" id="TableHeight">
                                        <Accordion defaultActiveKey={['0']} flush>
                                            <Accordion.Item eventKey="0">
                                                <Accordion.Header><h6 className="align-items-center mt-2 accordHeading">Select Date</h6></Accordion.Header>
                                                <Accordion.Body className="p-2">
                                                    <Row>
                                                        <Col>
                                                            <RadioGroup row aria-labelledby="demo-row-radio-buttons-group-label" value={Sddmmyyyy} name="row-radio-buttons-group">
                                                                <FormControlLabel value="Date" control={<Radio className="p-1" />} onChange={e => Selectdmy(e.target.value)} label="Date" className="mt-1" />
                                                                <FormControlLabel value="Month" control={<Radio className="p-1" />} onChange={e => Selectdmy(e.target.value)} label="Month" className="mt-1" />
                                                                <FormControlLabel value="Year" control={<Radio className="p-1" />} onChange={e => Selectdmy(e.target.value)} label="Year" className="mt-1" />
                                                            </RadioGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <div>
                                                                <sub className="mb-0 pb-0">From Date</sub>
                                                                <input type="date" className="dateSelect_bg" id="date-1" defaultValue={selectedFromDate} onChange={() => DateFn()} />
                                                            </div>
                                                            <div className="mt-1">
                                                                <sub className="mb-0 pb-0">To Date</sub>
                                                                <input type="date" className="dateSelect_bg" id="date-2" defaultValue={selectedToDate} onChange={() => DateFn()} />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    {datashow && (
                                                        <Row>
                                                            <Col className="mt-2">
                                                                <Table columns={dateColms} data={dateList} height="59vh" selectableRows onSelectedRowsChange={SelectedRowFn1} />
                                                            </Col>
                                                        </Row>
                                                    )}
                                                    {monthshow && (
                                                        <Row>
                                                            <Col className="mt-2">
                                                                <Table columns={monthColms} data={monthList} height="59vh" selectableRows onSelectedRowsChange={SelectedRowFn1} />
                                                            </Col>
                                                        </Row>
                                                    )}
                                                    {yearshow && (
                                                        <Row>
                                                            <Col className="mt-2">
                                                                <Table columns={yearColms} data={yearList} height="59vh" selectableRows onSelectedRowsChange={SelectedRowFn1} />
                                                            </Col>
                                                        </Row>
                                                    )}

                                                </Accordion.Body>
                                            </Accordion.Item>
                                            <Accordion.Item eventKey="1">
                                                <Accordion.Header><h6 className="align-items-center mt-2 accordHeading">Select Sire</h6></Accordion.Header>
                                                <Accordion.Body className="p-2">
                                                    <Row>
                                                        <Col>
                                                            <TextField label="Search Sire" value={searchSari} onChange={e => setSearchSari(e.target.value)} id="standard-basic" fullWidth variant="standard" />
                                                            <RadioGroup row aria-labelledby="demo-row-radio-buttons-group-label" value={sari} name="row-radio-buttons-group">
                                                                <FormControlLabel value="0" control={<Radio className="p-1" />} onChange={e => setSari(e.target.value)} label="Normal" className="mt-2" style={{ fontSize: "12px" }} />
                                                                <FormControlLabel value="1" control={<Radio className="p-1" />} onChange={e => setSari(e.target.value)} label="Sorted" className="mt-2" />
                                                                <FormControlLabel value="2" control={<Radio className="p-1" />} onChange={e => setSari(e.target.value)} label="Both" className="mt-2" />
                                                            </RadioGroup>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col className="mt-2 px-1">
                                                            <Table columns={SariColms}
                                                                data={sariList.filter((item) => {
                                                                    if (searchSari === "") {
                                                                        return item;
                                                                    } else if (item.id == searchSari) {
                                                                        return item;
                                                                    }
                                                                })} height="66vh" selectableRows onSelectedRowsChange={SelectedRowFn2} />
                                                        </Col>
                                                    </Row>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </Accordion>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </Col>
                </Row >
            </Container >

            {show && (<Offcanvas className="hide_Navbar" show={show} onHide={handleClose}><Container><Row><Col><Sidenav /></Col></Row></Container></Offcanvas>)
            }

            <Modal
                size="md"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={modalShow}
                onHide={() => setModalShow(false)}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">Filter</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Row className="align-items-center justify-content-start">
                            {/* <Col xl="6">
                <Dropdown label="Sex" menuList={menuitm1} />
              </Col> */}
                            <Col xl="6">
                                <FormControl variant="standard" fullWidth>
                                    <InputLabel id="demo-simple-select-standard-label">Species</InputLabel>
                                    <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={species} onChange={(e) => setSpecies(e.target.value)}>
                                        {speciesList.map((e) =>
                                            <MenuItem className='drop_value' key={e.id} value={e.id}>{e.Name}</MenuItem>
                                        )}
                                    </Select>
                                </FormControl>
                            </Col>
                            <Col xl="6">
                                <FormControl variant="standard" fullWidth>
                                    <InputLabel id="demo-simple-select-standard-label">Breed</InputLabel>
                                    <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={breed} onChange={(e) => setBreed(e.target.value)}>
                                        {breedList.map((e) =>
                                            <MenuItem className='drop_value' key={e.id} value={e.id} >{e.Name}</MenuItem>
                                        )}
                                    </Select>
                                </FormControl>
                            </Col>
                            <Col xl="12">
                                <Dropdown label="Field Type" menuList={menuitm} />
                            </Col>
                        </Row>
                        <Row className="mt-1 align-items-center justify-content-start">
                            <Col xl="2"><h6 className="mb-0 text-end">Type :</h6></Col>
                            <Col>
                                <RadioGroup row aria-labelledby="demo-row-radio-buttons-group-label" name="row-radio-buttons-group">
                                    <FormControlLabel value="Adults" control={<Radio className="pe-1" />} label="Adults" className="mt-1" onChange={(e) => setType(e.target.value)} />
                                    <FormControlLabel value="Heifers" control={<Radio className="pe-1" />} label="Heifers" className="mt-1" onChange={(e) => setType(e.target.value)} />
                                    <FormControlLabel value="Calf" control={<Radio className="pe-1" />} label="Calf" className="mt-1" onChange={(e) => setType(e.target.value)} />
                                    <FormControlLabel value="Both" control={<Radio className="pe-1" />} label="Both" className="mt-1" onChange={(e) => setType(e.target.value)} />
                                </RadioGroup>
                            </Col>
                        </Row>
                        <Row className="align-items-start justify-content-start">
                            <Col xl="2"><h6 className="mb-0 text-end">Status&nbsp;:</h6></Col>
                            <Col>
                                <RadioGroup row aria-labelledby="demo-row-radio-buttons-group-label" name="row-radio-buttons-group">
                                    <FormControlLabel value="All" control={<Radio className="pe-1 py-0" />} label="All" className="mt-1" onChange={(e) => setStatus(e.target.value)} />
                                    <FormControlLabel value="Open" control={<Radio className="pe-1 py-0" />} label="Open" className="mt-1" onChange={(e) => setStatus(e.target.value)} />
                                    <FormControlLabel value="Preg" control={<Radio className="pe-1 py-0" />} label="Preg" className="mt-1" onChange={(e) => setStatus(e.target.value)} />
                                    <FormControlLabel value="Milk" control={<Radio className="pe-1 py-0" />} label="Milk" className="mt-1" onChange={(e) => setStatus(e.target.value)} />
                                    <FormControlLabel value="Dry" control={<Radio className="pe-1 py-0" />} label="Dry" className="mt-1" onChange={(e) => setStatus(e.target.value)} />
                                </RadioGroup>
                            </Col>
                        </Row>
                        <Row className="mt-2">
                            <Col className="mt-1">
                                <input type="checkbox" className="me-2 mt-1" />
                                <label>Show Animal(s) Count</label>
                            </Col>
                            <Col className="mt-1">
                                <input type="checkbox" className="me-2 mt-1" />
                                <label>Hide Blank</label>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="mt-1">
                                <input type="checkbox" className="me-2 mt-1" />
                                <label>Show Percentage</label>
                            </Col>
                            <Col className="mt-1">
                                <input type="checkbox" className="me-2 mt-1" />
                                <label>Show Both</label>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="mt-1">
                                <input type="checkbox" className="me-2 mt-1" />
                                <label>Hide SAP Synced Data</label>
                            </Col>
                            <Col className="mt-1">
                                <input type="checkbox" className="me-2 mt-1" />
                                <label>Include Unknown Sire</label>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Container>
                        <Row className="justify-content-end">
                            <Col xs="2" xl="3"><button className="btn-blue-Animal mt-1" onClick={ApplyFn}>Apply</button></Col>
                        </Row>
                    </Container>
                </Modal.Footer>
            </Modal>



            <Modal
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                fullscreen
                show={herdModal}
                onHide={() => setHerdModal(false)}
            >
                <Modal.Header closeButton className="py-2">
                    <Modal.Title id="contained-modal-title-vcenter">{editName} ({dateModal})</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container fluid>
                        <Row>
                            <Col>
                                {subDateWise.length > 25 ? <Table columns={ExcelColums} data={subDateWise} pagination height="69vh" />
                                    :
                                    <Table columns={ExcelColums} data={subDateWise} height="55vh" />}
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer className="py-2">
                    <Container fluid>
                        <Row className="justify-content-between">
                            <Col xs="2" xl="1"><button className="btn-blue-Animal" onClick={handleDownloadExcel}>Export</button></Col>
                            <Col xs="2" xl="1"><button className="btn-blue-Animal" onClick={() => setHerdModal(false)}>Cancel</button></Col>
                        </Row>
                    </Container>
                </Modal.Footer>
            </Modal>
            {
                isLoading ?
                    <div className="circleText">
                        <div className="progressText">{progressData}</div>
                        <div className="">
                            <span className="loader-circle-13"></span>
                        </div>
                    </div>
                    : false
            }
        </>
    )
}