import { Switch } from '@mui/material'
import React, { useState } from 'react'
import {
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
} from "@mui/material";
import {
    Col,
    Container,
    Row,
    Offcanvas,
    Accordion,
    Modal,
} from "react-bootstrap";
import Table from '../../Componet/DataTable/Table'
import Sidenav from '../../Componet/Sidenav/Sidenav';
import edit from "../../Photo/master/edit.svg";
import remove from "../../Photo/master/remove.svg";
import menu from "../../Photo/menu.svg";
import profile from "../../Photo/profile.svg";
import closeButton from "../../Photo/milk-union/close button.svg";
import Input from "../../Componet/InputFild/Input";
import { MdOutlineVpnKey } from "react-icons/md";
import { GoSignOut } from "react-icons/go";


import { STORAGE_KEY } from "../../constant/common";
import { API_URLS, serviceUrl } from "../../API/Api";
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import BackBtn from '../../Componet/Button/BackBtn';
const { SourceApi } = API_URLS;
const added_by = localStorage.getItem(STORAGE_KEY.jwtTOKEN);

async function addData(credentials) {
    return fetch(SourceApi.AddDetails, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            "Accept": "application/json",
            "Authorization": `Bearer ${added_by}`,
        },
        body: JSON.stringify(credentials)
    })
        .then(res => res.json());
}


async function updateData(credentials) {
    return fetch(SourceApi.UpdateDetails, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            "Accept": "application/json",
            "Authorization": `Bearer ${added_by}`,
        },
        body: JSON.stringify(credentials)
    })
        .then(res => res.json());
}



const label = { inputProps: { "aria-label": "Switch demo" } };

const Source = () => {
    const API_KEY = serviceUrl;
    const [code, setCode] = useState("")
    const [name, setName] = useState("")
    const [mobile, setMobile] = useState("")
    const [staffMobile, setStaffMobile] = useState("")
    const [search, setSearch] = useState("")
    const [address, setAddress] = useState("")
    const [progressData, setProgressData] = useState("0%");
    const [deleteId, setDeleteId] = useState("0%");
    const navigate = useNavigate()
    const logOut = () => { navigate("/") }

    const [isLoading, setIsLoading] = useState(false);
    const [heightOffSet, setHeight] = useState(0);
    const [show, setShow] = useState(false);
    const [headerShow, setHeaderShow] = useState(true);
    const handleClose = () => { setShow(false); };
    const handleShow = () => { setHeaderShow(!headerShow); }
    const [modalShow, setModalShow] = useState(false);
    const [isSuspended, setIsSuspended] = useState(false)
    const [editToggle, setEditToggle] = useState(false);
    const [deleteShow, setDeleteShow] = useState(false)

    const [editId, setEditId] = useState("")
    const [user, setUser] = useState("")


    const [sourceList, setSourceList] = useState([])

    const sourceGetData = async () => {
        setIsLoading(true)
        fetch(SourceApi.GetDetails, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`,
            },
        })
            .then(response => {
                const contentLength = response.headers.get('content-length');
                let loaded = 0;
                return new Response(
                    new ReadableStream({
                        start(controller) {
                            const reader = response.body.getReader();
                            read();
                            function read() {
                                reader.read()
                                    .then((progressEvent) => {
                                        if (progressEvent.done) {
                                            controller.close();
                                            return;
                                        }
                                        loaded += progressEvent.value.byteLength;
                                        const percentageComplete = Math.round(loaded / contentLength * 100) + '%';
                                        setProgressData(percentageComplete)
                                        controller.enqueue(progressEvent.value);
                                        read();
                                    })
                            }
                        }
                    })
                );
            })
            .then((res) => res.json())
            .then((result) => { setSourceList(result.data);setIsLoading(false);setProgressData("0%"); })
            .catch((error)=>{setIsLoading(false);setProgressData("0%");})
        // const res = await getData();
        // if (res) {
        //     setSourceList(res.data);
        //     setIsLoading(false)
        // }
    }

    useEffect(() => {
        let divHeight = document.getElementById("heightOffsetStock")?.offsetHeight;
        setHeight(divHeight);
        sourceGetData()
    }, [])

    const AddData = async () => {
        if (editId) {
            const res = await updateData({ id: editId, code, Name: name, isSuspended, ContactNumber: mobile, Address: address });
            if (res) {
                setSourceList(res.data);
                setModalShow(false)
                sourceGetData()
            }
        }
        else {
            const res = await addData({ code, Name: name, isSuspended, ContactNumber: mobile, Address: address });
            if (res) {
                setSourceList(res.data);
                setModalShow(false)
                sourceGetData()
            }

        }
    }
    const Add = () => {
        setModalShow(true);
        setEditId("")
        setEditToggle("");
        setEditId("");
        setCode("");
        setName("");
        setMobile("");
        setIsSuspended("")
    }

    const updateFn = (e) => {
        setEditId(e.id)
        setEditToggle(true);
        setEditId(e.id);
        setCode(e.code);
        setName(e.Name);
        setMobile(e.ContactNumber);
        setIsSuspended(e.IsSuspended)
        setModalShow(true);
    };


    const deleteData = (id) => {
        fetch(`${API_KEY}/source/delete?id=` + id, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`,
            },
        })
            .then((res) => res.json())
            .then((result) => {
                sourceGetData();
                setDeleteShow(false)
                setModalShow(false);
            });

    }

    const colum = [
        {
            name: "Code",
            selector: "code",
        },
        {
            name: "Name",
            selector: "Name",
        },
        {
            name: "Contact Number",
            selector: "ContactNumber",
        },
        {
            name: "Is Suspended",
            // selector: "IsSuspended",
            cell: (e) => <Switch {...label} defaultChecked={e.IsSuspended} />,
        },
        {
            name: "Actions",
            cell: (e) => (
                <>
                    <img
                        src={edit}
                        onClick={() => updateFn(e)}
                        style={{ width: "15px", margin: "10px" }}
                    />
                    <img
                        src={remove}
                        onClick={() => { setDeleteShow(true); setDeleteId(e.id); setUser(e.Name); }}
                        style={{ width: "15px" }}
                    />
                </>
            ),
        },
    ]
    return (
        <>
            <Container fluid>
                <Row> {headerShow && (<Col lg="2" className="hideNavbar"> <Sidenav /></Col>)}
                    <Col className="header" lg={headerShow ? "10" : "12"}>
                        <Container fluid>
                            <Row className="pt-1 sub-header align-items-center justify-content-between">
                                <Col md="10" sm="10" xs="10" className="py-2 d-flex align-items-center">
                                    <button style={{ border: "none" }} onClick={handleShow}> <img src={menu} width={20} /> </button>
                                    <h5 className="ms-4 mt-2">Master / Source</h5>
                                </Col>
                                <Col md="1" sm="1" xs="10" className="py-2 d-flex align-items-center text-end ms-auto">
                                    <BackBtn style={{ border: "none" }} className="hideNavbar1" lable="Back" onClick={() => { navigate('/') }} />
                                </Col>
                                <Col md="1" sm="1" xs="2" className="text-end profile_dropdown">
                                    <img src={profile} width={40} />
                                    <div className="dropdpwn_Open">
                                        <Row className="text-center align-items-center">
                                            <Col xs="12" className="menuHover mb-3">
                                                <Row >
                                                    <Col className="" xs="3">
                                                        <span className=""><MdOutlineVpnKey size={25} />
                                                        </span>
                                                    </Col>
                                                    <Col className="text-start" xs="9">
                                                        Change Password
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col xs="12" className="menuHover" onClick={logOut}>
                                                <Row>
                                                    <Col className="" xs="3">
                                                        <span className=""><GoSignOut size={25} />
                                                        </span>
                                                    </Col>
                                                    <Col className="text-start" xs="9">
                                                        Sign Out
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="align-items-center justify-content-between p-2 d-flex" id='heightOffsetStock'>
                                <Col sm="4" lg="2" xl="1" className="mt-2 d-flex align-items-center justify-content-start" >
                                    <button className="btn-blue-Animal mt-1 mb-1" onClick={Add}>Add Source</button>
                                </Col>
                                <Col xxl="2" lg="3" md="4" sm="5">
                                    <Input label="Search" value={search} onChange={e => setSearch(e.target.value)} />
                                </Col>
                            </Row>
                            {/* <Row className="mt-2">
                                <Col>
                                    <Table columns={colum} data={sourceList} height={`calc(100vh - (${heightOffSet}px + 124px))`} pagination />
                                </Col>
                            </Row> */}
                            <Row className="mt-2" id='heightOffsetStock'>
                                {sourceList.length > 0 ?
                                    <div className="blue_bg DataTableBorder mt-1">
                                        <Table columns={colum} data={sourceList} height={`calc(100vh - (${heightOffSet}px + 140px))`} pagination/>
                                    </div>
                                    :
                                    <Row>
                                        <Col>
                                            <div className="NoTableBorder ms-2" >
                                                <div className="d-flex tabledata colmBreed">{
                                                    colum.map((i) => {
                                                        return (
                                                            <Col className={`blankColumns ${i.name.replace(' ', '_')}`} style={{ backgroundColor: "rgb(169,169,169)" }} height={`calc(100vh - (${heightOffSet}px + 20px))`} >{i.name}</Col>
                                                        )
                                                    })
                                                }
                                                </div>
                                                <h6 className="text-center align-items-center mt-5 noData ms-5">No Data</h6>
                                            </div>
                                        </Col>
                                    </Row>
                                }
                            </Row>
                        </Container>
                    </Col>
                </Row>
            </Container>

            <Modal aria-labelledby="contained-modal-title-vcenter" centered show={deleteShow} onHide={() => setModalShow(false)}>
                <Modal.Header style={{ background: "#DFDFDF" }}>
                    <Modal.Title id="contained-modal-title-vcenter">Confirm Delete ?</Modal.Title>
                    <img src={closeButton} onClick={() => setModalShow(false)} />
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        Delete {user} ?
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Container>
                        <Row className="justify-content-end">
                            <Col xl="3">
                                <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }} onClick={() => deleteData(deleteId)}>Delete</button>
                            </Col>
                            <Col xl="3">
                                <button className="btn-blue-Animal mt-1" onClick={() => setDeleteShow(false)} style={{ background: "#E16659" }}> Cancel </button>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Footer>
            </Modal>

            <Modal size="lg" aria-labelledby="contained-modal-title-vcenter" centered show={modalShow} onHide={() => setModalShow(false)}>
                <Modal.Header style={{ background: "#DFDFDF" }}>
                    <Modal.Title id="contained-modal-title-vcenter">{editId ? "Update Source" : "Add new Source"}Add new Source</Modal.Title>
                    <img src={closeButton} onClick={() => setModalShow(false)} />
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Row>
                            <Col xl="6">
                                <TextField label="Code" id="standard-basic" value={code} onChange={(e) => { setCode(e.target.value); }} data={sourceList} fullWidth variant="standard" />
                            </Col>
                            <Col xl="6">
                                <TextField label="Name" value={name} onChange={(e) => { setName(e.target.value); }} id="standard-basic" data={sourceList} fullWidth variant="standard" />
                            </Col>
                        </Row>
                        <Row className="mt-4">
                            <Col xl="6">
                                <TextField label="Contact" name={mobile} onChange={(e) => { setMobile(e.target.value) }} id="standard-basic" data={sourceList} fullWidth variant="standard" />
                            </Col>
                        </Row>
                        <Row className="mt-4">
                            <Col xl="12">
                                <TextField label="Address" id="standard-basic" value={address} onChange={(e) => { setAddress(e.target.value); }} data={sourceList} fullWidth variant="standard" />
                            </Col>
                        </Row>
                        <Row className="mt-4">
                            <Col>
                                <Switch {...label} />
                                Is Suspended : No
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Container>
                        <Row className="justify-content-end">
                            <Col xl="2">
                                <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }} onClick={(e) => AddData(e)}>{editId ? "Update" : "Save"}</button>
                            </Col>
                            <Col xl="2">
                                <button className="btn-blue-Animal mt-1" onClick={() => setModalShow(false)} style={{ background: "#E16659" }}> Cancel </button>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Footer>
            </Modal>
            {show && <Offcanvas className="hide_Navbar" show={show} onHide={handleClose}><Container><Row><Col><Sidenav /></Col></Row></Container></Offcanvas>}

            {
                isLoading ?
                    <div className="circleText">
                        <div className="progressText">{progressData}</div>
                        <div className="">
                            <span className="loader-circle-13"></span>
                        </div>
                    </div>
                    : false
            }
        </>

    )
}

export default Source;