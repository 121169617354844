import React, { useState, useEffect } from "react";
import { Col, Container, Row, Offcanvas, Modal } from "react-bootstrap";
import { TextField, FormControl, InputLabel, Select, MenuItem } from "@mui/material";

import menu from "../../Photo/menu.svg";
import profile from "../../Photo/profile.svg";
import edit from "../../Photo/master/edit.svg";
import remove from "../../Photo/master/remove.svg";
import closeButton from "../../Photo/milk-union/close button.svg";

import Sidenav from "../../Componet/Sidenav/Sidenav";
import Input from "../../Componet/InputFild/Input";
import Table from "../../Componet/DataTable/Table";

import { STORAGE_KEY } from "../../constant/common";
import { API_URLS, serviceUrl } from "../../API/Api";
import { MdOutlineVpnKey } from "react-icons/md";
import { GoSignOut } from "react-icons/go";
import { useNavigate } from "react-router-dom";
import BackBtn from "../../Componet/Button/BackBtn";
const { SYSTEMAFFECTED } = API_URLS;


export default function SystemAffected() {
    const added_by = localStorage.getItem(STORAGE_KEY.jwtTOKEN);
    const navigate = useNavigate()

    async function updateData(credentials) {
        return fetch(SYSTEMAFFECTED.Update, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`,
            },
            body: JSON.stringify(credentials)
        })
            .then(res => res.json());
    }
    async function AddData(credentials) {
        return fetch(SYSTEMAFFECTED.Insert, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`,
            },
            body: JSON.stringify(credentials)
        })
            .then(res => res.json());
    }

    const API_KEY = serviceUrl;
    // const API_KEY = "https://hais.hap.in";
    const [heightOffSet, setHeight] = useState(0);

    const [name, setName] = useState("");
    const [id, setID] = useState("");
    const [systemid, setSystemID] = useState("");
    const [search, setSearch] = useState("");
    const [progressData, setProgressData] = useState("0%");
    const logOut = () => { navigate("/") }

    const [daignosisList, setDaignosisList] = useState([]);

    const [isLoading, setIsLoading] = useState(false);
    const [modalShow, setModalShow] = useState(false);
    const [show, setShow] = useState(false);

    const [headerShow, setHeaderShow] = useState(true);
    const handleClose = () => { setShow(false); };
    const handleShow = () => { setHeaderShow(!headerShow); }


    const fatchData = async() => {
        setIsLoading(true);
        await fetch(`${API_KEY}/system-affected/get_table_data?offset=0&limit=50000&column_name=Name&sort_order=asc&search_term=&company_filter=1`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`,
            },
        })
            .then(response => {
                const contentLength = response.headers.get('content-length');
                let loaded = 0;
                return new Response(
                    new ReadableStream({
                        start(controller) {
                            const reader = response.body.getReader();
                            read();
                            function read() {
                                reader.read()
                                    .then((progressEvent) => {
                                        if (progressEvent.done) {
                                            controller.close();
                                            return;
                                        }
                                        loaded += progressEvent.value.byteLength;
                                        const percentageComplete = Math.round(loaded / contentLength * 100) + '%';
                                        setProgressData(percentageComplete)
                                        controller.enqueue(progressEvent.value);
                                        read();
                                    })
                            }
                        }
                    })
                );
            })
            .then((res) => res.json())
            .then((result) => { setDaignosisList(result.data);setIsLoading(false); setProgressData("0%") })
            .catch((error) => { setIsLoading(false) })
    };

    useEffect(() => {
        fatchData();
        setModalShow(false);
        let divHeight = document.getElementById("heightOffsetStock")?.offsetHeight;
        setHeight(divHeight);
    }, []);

    const refreshFn = () => {
        setModalShow(false);
        fatchData();
        resetFn();
    }

    const resetFn = () => {
        setID("");
        setName("");
        setSystemID("");
    }

    const addFn = () => {
        resetFn();
        setModalShow(true);
    }

    const colums = [
        {
            name: "Name",
            selector: "Name",
        },
        {
            name: "Actions",
            cell: (e) => (<><img src={edit} width={18} onClick={() => editFn(e)} /><img src={remove} className="ms-2" width={18} onClick={() => deleteFn(e)} /></>),
        },
    ];

    const editFn = (e) => {
        setModalShow(true);
        setID(e.id);
        setName(e.Name);
        setSystemID(e.systemAffected);
    }

    const saveFn = async () => {
        if (id) {
            const obj = { Name: name, id: id };
            const res = await updateData(obj);
            if (res) {
                refreshFn();
            }
        }
        else {
            const obj = { Name: name, id: 0 };
            const res = await AddData(obj);
            if (res) {
                refreshFn();
            }
        }
    }

    const deleteFn = async (e) => {
        if (e.id) {
            fetch(`${API_KEY}/system-affected/delete?id=${e.id}`, {
                method: "GET",
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                    "Authorization": `Bearer ${added_by}`,
                },
            })
                .then((res) => res.json())
                .then((result) => { if (result) { refreshFn(); } })
        }
    }

    const SearchFn = (e) => {
        setSearch(e);

        fetch(`${API_KEY}/system-affected/get_table_data?offset=0&limit=50000&column_name=Name&sort_order=asc&search_term=${e}&company_filter=1`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`,
            },
        })
            .then((res) => res.json())
            .then((result) => { setDaignosisList(result.data); })

    }

    return (
        <>
            <Container fluid>
                <Row>
                    {headerShow && (
                        <Col lg="2" className="hideNavbar">
                            <Sidenav />
                        </Col>
                    )}
                    <Col lg={headerShow ? "10" : "12"} className="header">
                        <Container fluid>
                            <Row className="pt-1 sub-header align-items-center justify-content-between">
                                <Col className="py-2 d-flex align-items-center">
                                    <button style={{ border: "none" }} className="hideNavbar1" onClick={handleShow}><img src={menu} width={20} /></button>
                                    <button style={{ border: "none" }} className="hide_Navbar" onClick={() => setShow(true)}><img src={menu} width={20} /></button>
                                    <h5 className="ms-4 mt-2">Health / System Affected</h5>
                                </Col>
                                <Col md="1" sm="1" xs="10" className="py-2 d-flex align-items-center text-end ms-auto">
                                    <BackBtn style={{ border: "none" }} className="hideNavbar1" lable="Back" onClick={() => { navigate('/') }} />
                                </Col>
                                <Col md="1" sm="1" xs="2" className="text-end profile_dropdown">
                                    <img src={profile} width={40} />
                                    <div className="dropdpwn_Open">
                                        <Row className="text-center align-items-center">
                                            <Col xs="12" className="menuHover mb-3">
                                                <Row >
                                                    <Col className="" xs="3">
                                                        <span className=""><MdOutlineVpnKey size={25} />
                                                        </span>
                                                    </Col>
                                                    <Col className="text-start" xs="9">
                                                        Change Password
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col xs="12" className="menuHover" onClick={logOut}>
                                                <Row>
                                                    <Col className="" xs="3">
                                                        <span className=""><GoSignOut size={25} />
                                                        </span>
                                                    </Col>
                                                    <Col className="text-start" xs="9">
                                                        Sign Out
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="align-items-center justify-content-between" id='heightOffsetStock'>
                                <Col xxl="2" md="3" xl="2" sm="3" xs="6" className="mt-2 d-flex">
                                    <button className="btn-blue-Animal" onClick={() => addFn()}>Add System Affected</button>
                                </Col>
                                <Col xxl="2" md="3" sm="3" xs="6" className="mt-2">
                                    <Input label="Search" value={search} onChange={e => SearchFn(e.target.value)} />
                                </Col>
                            </Row>
                            {/* <Row>
                                <Col className="mt-2">
                                    <Table columns={colums} data={daignosisList} height={`calc(100vh - (${heightOffSet}px + 124px))`} pagination />
                                </Col>
                            </Row> */}
                            <Row className="mt-2" id='heightOffsetStock'>
                                {daignosisList.length > 0 ?
                                    <div className="blue_bg DataTableBorder mt-1">
                                        <Table columns={colums} data={daignosisList} height={`calc(100vh - (${heightOffSet}px + 140px))`} pagination />
                                    </div>
                                    :
                                    <Row>
                                        <Col>
                                            <div className="NoTableBorder ms-2" >
                                                <div className="d-flex tabledata colmBreed">{
                                                    colums.map((i) => {
                                                        return (
                                                            <Col className={`blankColumns ${i.name.replace(' ', '_')}`} style={{ backgroundColor: "rgb(169,169,169)" }} height={`calc(100vh - (${heightOffSet}px + 20px))`} >{i.name}</Col>
                                                        )
                                                    })
                                                }
                                                </div>
                                                <h6 className="text-center align-items-center mt-5 noData ms-5">No Data</h6>
                                            </div>
                                        </Col>
                                    </Row>
                                }
                            </Row>
                        </Container>
                    </Col>
                </Row>
            </Container>

            <Modal size="lg" aria-labelledby="contained-modal-title-vcenter" centered show={modalShow} onHide={() => setModalShow(false)}>
                <Modal.Header style={{ background: "#DFDFDF" }}>
                    <Modal.Title id="contained-modal-title-vcenter">{id ? "Update System Affected" : "Add New System Affected"}</Modal.Title>
                    <img src={closeButton} onClick={() => setModalShow(false)} />
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Row className="my-3">
                            <Col xxl="6" md="6">
                                <TextField label="Name" value={name} onChange={e => setName(e.target.value)} fullWidth variant="standard" />
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer className="py-0">
                    <Container>
                        <Row className="justify-content-between">
                            <Col xxl="2" md="3">
                                <button className="btn-blue-Animal mt-2 mb-1" onClick={() => saveFn()}>{id ? "Update" : "Save"}</button>
                            </Col>
                            <Col xxl="2" md="3">
                                <button className="btn-blue-Animal mt-2 mb-1" onClick={() => setModalShow(false)} style={{ background: "#E16659" }}>Cancel</button>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Footer>
            </Modal>

            {show && (<Offcanvas className="hide_Navbar" show={show} onHide={handleClose}><Container><Row><Col><Sidenav /></Col></Row></Container></Offcanvas>)}
            {isLoading ?
                <div className="circleText">
                    <div className="progressText">{progressData}</div>
                    <div className="">
                        <span className="loader-circle-13"></span>
                    </div>
                </div>
                : false
            }

        </>
    )
};
