import { Switch } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { TextField, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { Col, Container, Row, Offcanvas, Accordion, Modal } from "react-bootstrap";

import Input from "../../Componet/InputFild/Input";
import Sidenav from '../../Componet/Sidenav/Sidenav';
import edit from "../../Photo/master/edit.svg";
import remove from "../../Photo/master/remove.svg";
import menu from "../../Photo/menu.svg";
import profile from "../../Photo/profile.svg";
import closeButton from "../../Photo/milk-union/close button.svg";
import Table from '../../Componet/DataTable/Table';
import { MdOutlineVpnKey } from "react-icons/md";
import { GoSignOut } from "react-icons/go";

import { STORAGE_KEY } from "../../constant/common";
import { API_URLS, serviceUrl } from "../../API/Api";
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import Searcable from '../../Componet/Dropdown/Searcable';
import BackBtn from '../../Componet/Button/BackBtn';
const { CC, ZoneApi } = API_URLS;

const label = { inputProps: { "aria-label": "Switch demo" } };

const CcCenter = () => {
    const added_by = localStorage.getItem(STORAGE_KEY.jwtTOKEN);
    const navigate = useNavigate()
    const logOut = () => { navigate("/") }

    async function addData(credentials) {
        return fetch(CC.AddDetails, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`,
            },
            body: JSON.stringify(credentials)
        })
            .then(res => res.json());
    }

    async function updateData(credentials) {
        return fetch(CC.UpdateDetails, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`,
            },
            body: JSON.stringify(credentials)
        })
            .then(res => res.json());
    }
    async function getAll(credentials) {
        return fetch(CC.AllCc, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`,
            },
            body: JSON.stringify(credentials)
        })
            .then(res => res.json());
    }
    // await fetch(`${ZoneApi.GetZoneByName}?name=`, {
    //     method: "GET",
    //     headers: {
    //       "Content-Type": "application/json",
    //       Accept: "application/json",
    //       Authorization: `Bearer ${added_by}`,
    //     },
    //   })
    //     .then((res) => res.json())
    //     .then((result) => {
    //       setZoneList(result);
    //     });

    const API_KEY = serviceUrl;
    // const API_KEY = "https://hais.hap.in";

    const [code, setCode] = useState("")
    const [name, setName] = useState("")
    const [mobile, setMobile] = useState("")
    const [staffMobile, setStaffMobile] = useState("")
    const [search, setSearch] = useState("")
    const [user, setUser] = useState("")
    const [editId, setEditId] = useState("")
    const [searchFilter, setSearchFilter] = useState("");
    const [progressData, setProgressData] = useState("0%");

    const [heightOffSet, setHeight] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [headerShow, setHeaderShow] = useState(true);
    const [show, setShow] = useState(false);
    const handleClose = () => { setShow(false); }
    const handleShow = () => { setHeaderShow(!headerShow); }
    const [modalShow, setModalShow] = useState(false);
    const [isSuspended, setIsSuspended] = useState(false)
    const [editToggle, setEditToggle] = useState(false);
    const [deleteShow, setDeleteShow] = useState(false)
    const [deleteId, setDeleteId] = useState()
    const [zone,setZone]=useState("");

    const [ccList, setCcList] = useState([]);
    const [ZoneList, setZoneList] = useState([]);

    const ccGetData = async () => {
        setIsLoading(true)
        // fetch(CC.GetDetails, {
        fetch(CC.AllCc, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`,
            },
        })
            .then(response => {
                const contentLength = response.headers.get('content-length');
                let loaded = 0;
                return new Response(
                    new ReadableStream({
                        start(controller) {
                            const reader = response.body.getReader();
                            read();
                            function read() {
                                reader.read()
                                    .then((progressEvent) => {
                                        if (progressEvent.done) {
                                            controller.close();
                                            return;
                                        }
                                        loaded += progressEvent.value.byteLength;
                                        const percentageComplete = Math.round(loaded / contentLength * 100) + '%';
                                        setProgressData(percentageComplete)
                                        controller.enqueue(progressEvent.value);
                                        read();
                                    })
                            }
                        }
                    })
                );
            })
            .then((res) => res.json())
            .then((result) => { setIsLoading(false); setCcList(result); setProgressData("0%"); })
            // const res = await getData();
            // if (res) {
            //     setIsLoading(false)
            //     setCcList(res.data);
            // }
            .then((result) => { setIsLoading(false); setCcList(result.data); setProgressData("0%"); })

        await fetch(`${ZoneApi.GetZoneByName}?name=`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorization: `Bearer ${added_by}`,
            },
        })
            .then((res) => res.json())
            .then((result) => {
                setZoneList(result);
            });
    }

    useEffect(() => {
        let divHeight = document.getElementById("heightOffsetStock")?.offsetHeight;
        setHeight(divHeight);
        ccGetData();
    }, [])

    const handleChange = async (e, d) => {
        setIsSuspended(e.target.checked)
        setIsLoading(true)

        await fetch(`${CC.SuspendApi}?id=${d.id}&isSuspended=${e.target.checked}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`,
            },
        })
            .then(response => {
                const contentLength = response.headers.get('content-length');
                let loaded = 0;
                return new Response(
                    new ReadableStream({
                        start(controller) {
                            const reader = response.body.getReader();
                            read();
                            function read() {
                                reader.read()
                                    .then((progressEvent) => {
                                        if (progressEvent.done) {
                                            controller.close();
                                            return;
                                        }
                                        loaded += progressEvent.value.byteLength;
                                        const percentageComplete = Math.round(loaded / contentLength * 100) + '%';
                                        setProgressData(percentageComplete)
                                        controller.enqueue(progressEvent.value);
                                        read();
                                    })
                            }
                        }
                    })
                );
            })
            .then((res) => res.json())
            .then((result) => { toast.success("Success suspended status changed. "); setIsLoading(false); setProgressData("0%"); })
    }

    const AddData = async () => {
        if (editId) {
            const res = await updateData({ id: editId, code, name, company: "1", isSuspended, mobile, staff: staffMobile ? staffMobile : "0", user: "201" });
            if (res) {
                setCcList(res.data);
                setModalShow(false)
                ccGetData()
                setEditId("");
            }
        }
        else {
            const res = await addData({ code, name, company: "1", IsSuspended: isSuspended, mobile, staff: staffMobile ? staffMobile : "0", user: "201" });
            if (res) {
                setCcList(res.data);
                setModalShow(false)
                ccGetData()
            }

        }
    }

    const Add = () => {
        setModalShow(true)
        setEditId("")
        setCode("")
        setName("")
        setMobile("")
        setStaffMobile("")
    }

    const updateFn = (e) => {
        setModalShow(true);
        setEditId(e.id)
        setCode(e.code);
        setName(e.Name);
        setMobile(e.Mobile);
        setStaffMobile(e.staff.Mobile);
        setIsSuspended(e.IsSuspended)

    };

    const deleteData = (id) => {
        setDeleteShow(true)

        fetch(`${CC.DelateDetails}?id=` + id, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`,
            },
        })
            .then((res) => res.json())
            .then((result) => {
                setDeleteShow(false);
                ccGetData();
            });

    }

    const colum = [
        {
            name: "Code",
            selector: "code",
        },
        {
            name: "Name",
            selector: "Name",
        },
        {
            name: "Mobile",
            selector: "Mobile",
        },
        {
            name: "Contact person",
            selector: "staffMobile",
        },
        {
            name: "Is Suspended",
            selector: "IsSuspended",
            cell: (d) => <Switch {...label} defaultChecked={d.IsSuspended} onChange={(e) => { handleChange(e, d) }} />,
        },
        {
            name: "Actions",
            cell: (e) => (
                <>
                    <img
                        src={edit}
                        onClick={() => updateFn(e)}
                        style={{ width: "15px", margin: "10px" }}
                    />
                    <img
                        src={remove}
                        onClick={() => { setDeleteShow(true); setDeleteId(e.id); setUser(e.Name) }}
                        style={{ width: "15px" }}
                    />
                </>
            ),
        },
    ];

    const SearchFn = (e) => {
        const data = e.target.value;
        if (data !== '') {
            const results = ccList.filter((d) => {
                return d.Name.toLowerCase().startsWith(data.toLowerCase());
            });
            setSearchFilter(results);
        } else {
            setSearchFilter(ccList);
        }
        setSearch(data);
    }


    return (
        <>
            <Container fluid>
                <Row> {headerShow && (<Col lg="2" className="hideNavbar"> <Sidenav /></Col>)}
                    <Col className="header" lg={headerShow ? "10" : "12"}>
                        <Container fluid>
                            <Row className="pt-1 sub-header align-items-center justify-content-between">
                                <Col md="10" sm="10" xs="10" className="py-2 d-flex align-items-center">
                                    <button style={{ border: "none" }} className="hideNavbar1" onClick={handleShow}><img src={menu} width={20} /></button>
                                    <button style={{ border: "none" }} className="hide_Navbar" onClick={() => setShow(true)}><img src={menu} width={20} /></button>
                                    <h5 className="ms-4 mt-2">Master / cc</h5>
                                </Col>
                                <Col md="1" sm="1" xs="10" className="py-2 d-flex align-items-center text-end ms-auto">
                                    <BackBtn style={{ border: "none" }} className="hideNavbar1" lable="Back" onClick={() => { navigate('/') }} />
                                </Col>
                                <Col md="1" sm="1" xs="2" className="text-end profile_dropdown">
                                    <img src={profile} width={40} />
                                    <div className="dropdpwn_Open">
                                        <Row className="text-center align-items-center">
                                            <Col xs="12" className="menuHover mb-3">
                                                <Row >
                                                    <Col className="" xs="3">
                                                        <span className=""><MdOutlineVpnKey size={25} />
                                                        </span>
                                                    </Col>
                                                    <Col className="text-start" xs="9">
                                                        Change Password
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col xs="12" className="menuHover" onClick={logOut}>
                                                <Row>
                                                    <Col className="" xs="3">
                                                        <span className=""><GoSignOut size={25} />
                                                        </span>
                                                    </Col>
                                                    <Col className="text-start" xs="9">
                                                        Sign Out
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="align-items-center justify-content-between p-2 d-flex" id='heightOffsetStock'>
                                <Col sm="4" lg="2" xl="1" className="mt-2 d-flex align-items-center justify-content-start">
                                    <button className="btn-blue-Animal mt-1 mb-1" onClick={Add}>Add cc</button>
                                </Col>
                                <Col xxl="2" lg="3" md="4" sm="5">
                                    <Input label="Search" value={search} onChange={e => SearchFn(e)} />
                                </Col>
                            </Row>
                            <Row className="mt-2" id='heightOffsetStock'>
                                {ccList.length > 0 ?
                                    <div className="blue_bg DataTableBorder mt-1">
                                        <Table columns={colum} data={searchFilter ? searchFilter : ccList} height={`calc(100vh - (${heightOffSet}px + 140px))`} pagination />
                                    </div>
                                    :
                                    <Row>
                                        <Col>
                                            <div className="NoTableBorder ms-2" >
                                                <div className="d-flex tabledata colmBreed">{
                                                    colum.map((i) => {
                                                        return (
                                                            <Col className={`blankColumns ${i.name.replace(' ', '_')}`} style={{ backgroundColor: "rgb(169,169,169)" }} height={`calc(100vh - (${heightOffSet}px + 20px))`} >{i.name}</Col>
                                                        )
                                                    })
                                                }
                                                </div>
                                                <h6 className="text-center align-items-center mt-5 noData">No Data</h6>
                                            </div>
                                        </Col>
                                    </Row>
                                }
                            </Row>
                        </Container>
                    </Col>
                </Row>
            </Container>

            <Modal aria-labelledby="contained-modal-title-vcenter" centered show={deleteShow} onHide={() => setDeleteShow(false)}>
                <Modal.Header style={{ background: "#DFDFDF" }}>
                    <Modal.Title id="contained-modal-title-vcenter">Confirm Delete ?</Modal.Title>
                    <img src={closeButton} onClick={() => deleteShow(false)} />
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        Delete {user} ?
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Container>
                        <Row className="justify-content-end">
                            <Col xl="3">
                                <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }} onClick={() => deleteData(deleteId)}>Delete</button>
                            </Col>
                            <Col xl="3">
                                <button className="btn-blue-Animal mt-1" onClick={() => setDeleteShow(false)} style={{ background: "#E16659" }}> Cancel </button>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Footer>
            </Modal>

            <Modal size="lg" aria-labelledby="contained-modal-title-vcenter" centered show={modalShow} onHide={() => setModalShow(false)}>
                <Modal.Header style={{ background: "#DFDFDF" }}>
                    <Modal.Title id="contained-modal-title-vcenter">Add new cc</Modal.Title>
                    <img src={closeButton} onClick={() => setModalShow(false)} />
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Row>
                            <Col xl="6">
                                <TextField label="Code" id="standard-basic" value={code} onChange={(e) => { setCode(e.target.value); }} data={ccList} fullWidth variant="standard" />
                            </Col>
                            <Col xl="6">
                                <TextField label="Name" value={name} onChange={(e) => { setName(e.target.value); }} id="standard-basic" data={ccList} fullWidth variant="standard" />
                            </Col>
                        </Row>
                        <Row className="mt-4">
                            <Col xl="6">
                                <TextField label="Mobile" value={mobile} onChange={(e) => { setMobile(e.target.value) }} id="standard-basic" data={ccList} fullWidth variant="standard" />
                            </Col>
                            <Col xl="6">
                                <TextField label="Contact Person (Staff)" name={staffMobile} value={staffMobile} onChange={(e) => { setStaffMobile(e.target.value); }} id="standard-basic" data={ccList} fullWidth variant="standard" />
                            </Col>
                        </Row>
                        <Row className="mt-4">
                            <Col xl="6">
                                <Searcable
                                    placeholder="Zone"
                                    options={ZoneList}
                                    onChange={(selectedOption) => { setZone(selectedOption); }}
                                    value={zone}
                                    labelKey="Name"
                                    otherKey="code"
                                    valueKey="id"
                                />
                            </Col>
                        </Row>
                        <Row className="mt-4">
                            <Col>
                                <Switch {...label} onChange={(e) => { handleChange(e) }} defaultChecked={isSuspended} />Is Suspended : No
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Container>
                        <Row className="justify-content-between">
                            <Col xl="2">
                                <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }} onClick={(e) => AddData(e)}>{editId ? "Update" : "Save"}</button>
                            </Col>
                            <Col xl="2">
                                <button className="btn-blue-Animal mt-1" onClick={() => setModalShow(false)} style={{ background: "#E16659" }}> Cancel </button>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Footer>
            </Modal>

            {show && <Offcanvas className="hide_Navbar" show={show} onHide={handleClose}><Container><Row><Col><Sidenav /></Col></Row></Container></Offcanvas>}
            <ToastContainer
                position="top-center"
                autoClose={2000}
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
                style={{ fontSize: "14px" }}
            />

            {
                isLoading ?
                    <div class="center-body">
                        <div class="loader-square-28">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                    </div> : false
            }
        </>

    )
}

export default CcCenter