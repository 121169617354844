import React, { useState, useEffect } from 'react';
import { Col, Container, Row, Offcanvas, Modal } from "react-bootstrap";

import menu from '../../Photo/menu.svg';
import profile from '../../Photo/profile.svg';

import Sidenav from "../../Componet/Sidenav/Sidenav";

import { STORAGE_KEY } from "../../constant/common";
import { API_URLS, serviceUrl } from "../../API/Api";
import { MdOutlineVpnKey } from "react-icons/md";
import { GoSignOut } from "react-icons/go";
import { useNavigate } from 'react-router-dom';
import Input from '../../Componet/InputFild/Input';
import closeButton from "../../Photo/milk-union/close button.svg";
import { FormControl, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import './Labmangement.css'
import { ToastContainer, toast } from 'react-toastify';
import moment from 'moment/moment';
import Searcable from '../../Componet/Dropdown/Searcable';
import Table from '../../Componet/DataTable/Table';
import { FamilyRestroomTwoTone } from '@mui/icons-material';
const { Hemato_cytology, Cattleregistration, } = API_URLS;

export default function TestRequest() {
    const API_KEY = serviceUrl;
    // const API_KEY = "http://localhost:2000"
    const added_by = localStorage.getItem(STORAGE_KEY.jwtTOKEN);

    const selectedToDate = new Date().toISOString().slice(0, 10);

    const [search, setSearch] = useState("")
    const [searchid, setSearchId] = useState("")
    const [date, setDate] = useState(selectedToDate)
    const [index, setIndex] = useState(1)
    const [testing, setTesting] = useState("")
    const formattedDate = moment(date).format('DD/MM/YYYY');
    const localDate = moment(date).format('YYYY-MM-DDTHH:mm:ss.SSSZ');

    const [id, setId] = useState("")
    const [patId, setPatId] = useState("")
    const [hematoCytology, setHematoCytology] = useState("")
    const [hcs, setHcs] = useState("")
    const [clrChange, setClrChange] = useState(false)
    const [clrChange2, setClrChange2] = useState(false)
    const [clrChange3, setClrChange3] = useState(false)
    const [clrChange4, setClrChange4] = useState(false)
    const [clrChange5, setClrChange5] = useState(false)
    const [clrChange6, setClrChange6] = useState(false)
    const [clrChange7, setClrChange7] = useState(false)
    const [clrChange8, setClrChange8] = useState(false)
    const [clrChange9, setClrChange9] = useState(false)
    const [clrChange10, setClrChange10] = useState(false)
    const [clrChange11, setClrChange11] = useState(false)
    const [clrChange12, setClrChange12] = useState(false)
    const [allCheck, setAllCheck] = useState(false)

    const allCheck2 = (e) => {
        if (e == true) {
            setCalchecked(true);
            setPhoschecked(true);
            setBioRbptchecked(true);
            setTBTESTchecked(true);
            setJONHSTESTPPDchecked(true);
            setBunchecked(true);
            setGluchecked(true);
            setBioOthersChecked(true)
        }
        else {
            setCalchecked(false);
            setPhoschecked(false);
            setBioRbptchecked(false);
            setTBTESTchecked(false);
            setJONHSTESTPPDchecked(false);
            setBunchecked(false);
            setGluchecked(false);
            setBioOthersChecked(false)
        }
    }

    const allCheck1 = (e) => {
        if (e == true) {
            setHbGmchecked(true);
            setPCVchecked(true);
            setPLTchecked(true);
            setRBCchecked(true);
            setWBCchecked(true);
            setMCHchecked(true);
            setHCTchecked(true);
            setMCVchecked(true);
            setMCVchecked(true);
            setMCHCchecked(true);
            setNEUTROPHILSchecked(true);
            setLYMPHOCYTESchecked(true);
            setMIDchecked(true);
            setNEUTROPHILSBANDchecked(true);
            setHemotoOthersChecked(true);
        }
        else {
            setHbGmchecked(false);
            setPCVchecked(false);
            setPLTchecked(false);
            setRBCchecked(false);
            setWBCchecked(false);
            setMCHchecked(false);
            setHCTchecked(false);
            setMCVchecked(false);
            setMCVchecked(false);
            setMCHCchecked(false);
            setNEUTROPHILSchecked(false);
            setLYMPHOCYTESchecked(false);
            setMIDchecked(false);
            setNEUTROPHILSBANDchecked(false);
            setHemotoOthersChecked(false);
        }
    }

    const [HemotoOthersChecked, setHemotoOthersChecked] = useState(false);
    const [HemotoOthers, setHemotoOthers] = useState("");

    const [hbGm, setHbGm] = useState("")
    const [hbGmchecked, setHbGmchecked] = useState(false)

    const [PCV, setPCV] = useState("")
    const [PCVchecked, setPCVchecked] = useState(false)

    const [PLT, setPLT] = useState("")
    const [PLTchecked, setPLTchecked] = useState(false)

    const [RBC, setRBC] = useState("")
    const [RBCchecked, setRBCchecked] = useState(false)

    const [WBC, setWBC] = useState("")
    const [WBCchecked, setWBCchecked] = useState(false)

    const [MCH, setMCH] = useState("")
    const [MCHchecked, setMCHchecked] = useState(false)

    const [HCT, setHCT] = useState("")
    const [HCTchecked, setHCTchecked] = useState(false)

    const [MCV, setMCV] = useState("")
    const [MCVchecked, setMCVchecked] = useState(false)

    const [MCHC, setMCHC] = useState("")
    const [MCHCchecked, setMCHCchecked] = useState(false)

    const [NEUTROPHILS, setNEUTROPHILS] = useState("")
    const [NEUTROPHILSchecked, setNEUTROPHILSchecked] = useState(false)

    const [NEUTROPHILSBAND, setNEUTROPHILSBAND] = useState("")
    const [NEUTROPHILSBANDchecked, setNEUTROPHILSBANDchecked] = useState(false)

    const [LYMPHOCYTES, setLYMPHOCYTES] = useState("")
    const [LYMPHOCYTESchecked, setLYMPHOCYTESchecked] = useState(false)

    const [MID, setMID] = useState("")
    const [MIDchecked, setMIDchecked] = useState(false)

    // ----------------biochemestry------------------------

    const [BioOthersChecked, setBioOthersChecked] = useState(false);
    const [BioOthers, setBioOthers] = useState("");

    const [Cal, setCal] = useState("")
    const [Calchecked, setCalchecked] = useState(false)

    const [TBTEST, setTBTEST] = useState("")
    const [TBTESTchecked, setTBTESTchecked] = useState(false)

    const [Phos, setPhos] = useState("")
    const [Phoschecked, setPhoschecked] = useState(false)

    const [bioRbpt, setBioRbpt] = useState("")
    const [bioRbptchecked, setBioRbptchecked] = useState(false)

    const [JONHSTESTPPD, setJONHSTESTPPD] = useState("")
    const [JONHSTESTPPDchecked, setJONHSTESTPPDchecked] = useState(false)

    const [bun, setBun] = useState("")
    const [bunchecked, setBunchecked] = useState(false)

    const [glu, setGlu] = useState("")
    const [gluchecked, setGluchecked] = useState(false)


    // -------------------GI -------------------------

    const [glParatism, setGlParatism] = useState("")
    const [glParatismchecked, setGlParatismchecked] = useState(false)

    const [fsPositiveFor, setFsPositiveFor] = useState("")
    const [fsPositiveForchecked, setFsPositiveForchecked] = useState(false)

    const [facelSample, setFacelSample] = useState("")
    const [facelSamplechecked, setFacelSamplechecked] = useState(false)

    const [nematodes, setNematodes] = useState("")
    const [nematodeschecked, setNematodeschecked] = useState(false)

    const [tramatodes, setTramatodes] = useState("")
    const [tramatodeschecked, setTramatodeschecked] = useState(false)

    const [cystodes, setCystodes] = useState("")
    const [cystodeschecked, setCystodeschecked] = useState(false)

    const [epgGt, setEpgGt] = useState("")
    const [epgGtchecked, setEpgGtchecked] = useState(false)


    const allCheck3 = (e) => {
        if (e == true) {
            setGlParatismchecked(true);
            setFsPositiveForchecked(true);
            setFacelSamplechecked(true);
            setNematodeschecked(true);
            setTramatodeschecked(true);
            setCystodeschecked(true);
            setEpgGtchecked(true);
        }
        else {
            setGlParatismchecked(false);
            setFsPositiveForchecked(false);
            setFacelSamplechecked(false);
            setNematodeschecked(false);
            setTramatodeschecked(false);
            setCystodeschecked(false);
            setEpgGtchecked(false);
        }
    }

    // ----------------------------------------------------

    const [tecm, setTecm] = useState("")
    const [tlc, setTlc] = useState("")
    const [n, setN] = useState("")
    const [nUl, setNul] = useState("")
    const [l, setL] = useState("")
    const [lUl, setLul] = useState("")
    const [m, setM] = useState("")
    const [mUl, setMul] = useState("")
    const [e, setE] = useState("")
    const [eUl, setEul] = useState("")
    const [b, setB] = useState("")
    const [bc, setBc] = useState("")
    const [p1pro, setP1pro] = useState("")
    const [fibrinogen, setFibrinogen] = useState("")
    const [ppFib, setPpFib] = useState("")
    const [se, setSe] = useState("")
    const [seCell, setSeCell] = useState("")

    const [biochemistryid, setBiochemistryId] = useState("")
    const [bioPatId, setBioPatId] = useState("")
    const [biochemistry, setBiochemistry] = useState("")
    const [bioBcs, setBioBcs] = useState("")
    const [caMg, setCamg] = useState("")
    const [proG, setProG] = useState("")
    const [cholM, setCholM] = useState("")
    const [pMg, setPMg] = useState("")
    const [albG, setAlbG] = useState("")
    const [asl, setAsl] = useState("")
    const [mg, setMg] = useState("")
    const [flgG, setFlgG] = useState("")
    const [sdh, setSdh] = useState("")
    const [na, setNa] = useState("")
    const [albGlo, setAlbGlo] = useState("")
    const [ggt, setGgt] = useState("")
    const [k, setK] = useState("")
    const [bioPpFib, setBioPpFib] = useState("")
    const [akp, setAkp] = useState("")
    const [c1, setC1] = useState("")

    const [tBill, setTBill] = useState("")

    const [cr, setCr] = useState("")
    const [dBill, setDBill] = useState("")
    const [randum, setRandum] = useState("")
    const [seCellMorphology, setSECellMorphology] = useState("")

    const [glId, setGlId] = useState("")
    const [glPatId, setGlPatId] = useState("")

    const [urinalysisId, setUrinalysisId] = useState("")
    const [urinalysisPatId, setUrinalysisPatId] = useState("")
    const [urinalysis, setUrinalysis] = useState("")
    const [glucose, setGlucose] = useState("")
    const [ketan, setKetan] = useState("")
    const [spGravity, setSpGravity] = useState("")
    const [blood, setBlood] = useState("")
    const [ph, setPh] = useState("")
    const [bilirubin, setBilirubin] = useState("")
    const [usExam, setUsExam] = useState("")
    const [protien, setProtien] = useState("")
    const [urobillinogen, setUrobillinogen] = useState("")
    const [nitrite, setNitrite] = useState("")
    const [leukocytes, setLeukocytes] = useState("")

    const [immunologyId, setImmunologyId] = useState("")
    const [immunologyPatId, setImmunologyPatId] = useState("")
    const [immunolTest, setImmunolTest] = useState("")
    const [brMrt, setBrMrt] = useState("")
    const [brElisa, setBrElisa] = useState("")
    const [rbpt, setRbpt] = useState("")
    const [irbElisa, setIrbElisa] = useState("")
    const [stat, setStat] = useState("")
    const [jidElisa, setJidElisa] = useState("")
    const [idTuberuline, setIdTuberuline] = useState("")
    const [idJohnine, setIdJohnine] = useState("")
    const [ibrFat, setIbrFat] = useState("")

    const [scmId, setScmId] = useState("")
    const [scmPatIdNo, setScmPatIdNo] = useState("")
    const [scmSampleType, setScmSampleType] = useState("")
    const [mastrip, setMastrip] = useState("")
    const [cmt, setCmt] = useState("")
    const [ec, setEc] = useState("")
    const [sscTh, setSscTh] = useState("")

    const [antigramId, setAntigramId] = useState("")
    const [antigramPatId, setAntigramPatId] = useState("")
    const [absSample, setAbsSample] = useState("")
    const [absSampleQuality, setAbsSampleQuality] = useState("")
    const [pathogenId, setPathogenId] = useState("")
    const [antibiotics, setAntibiotics] = useState("")
    const [bkMethod, setBkMethod] = useState("")

    const [spId, setSpId] = useState("")
    const [spPatIdNo, setSpPatIdNo] = useState("")
    const [specialPath, setSpecialPath] = useState("")
    const [sps2, setSps2] = useState("")
    const [sps3, setSps3] = useState("")
    const [sps4, setSps4] = useState("")
    const [sps5, setSps5] = useState("")
    const [sps6, setSps6] = useState("")
    const [spResu, setSpResu] = useState("")
    const [spr2, setSpr2] = useState("")
    const [spr3, setSpr3] = useState("")
    const [spr4, setSpr4] = useState("")
    const [spr5, setSpr5] = useState("")
    const [spr6, setSpr6] = useState("")

    const [toxicologyId, setToxicologyId] = useState("")
    const [toxicologyPatientId, setToxicologyPatientId] = useState("")
    const [txSample1, setTxSample1] = useState("")
    const [txSample2, setTxSample2] = useState("")
    const [txTest1, setTxTest1] = useState("")
    const [txTest2, setTxTest2] = useState("")
    const [txResult1, setTxResult1] = useState("")
    const [txResult2, setTxResult2] = useState("")

    const [milkId, setMilkId] = useState("")
    const [milkPatientId, setMilkPatientId] = useState("")
    const [milkSampleType, setMilkSampleType] = useState("")
    const [fat, setFat] = useState("")
    const [snf, setSnf] = useState("")
    const [ts, setTs] = useState("")
    const [sampleCondition, setSampleCondition] = useState("")
    const [density, setDensity] = useState("")
    const [mSpGravity, setMSpGravity] = useState("")
    const [milkProtein, setMilkProtein] = useState("")
    const [fpc, setFpc] = useState("")
    const [ecMsCm, setEcMsCm] = useState("")
    const [mph, setMph] = useState("")
    const [temperatureC, setTemperatureC] = useState("")
    const [Lactose, setLactose] = useState("")
    const [mun, setMun] = useState("")
    const [mCitrate, setMCitrate] = useState("")

    const [modTechId, setModTechId] = useState("")
    const [modPatientId, setModPatientId] = useState("")
    const [modTechniques, setModTechniques] = useState("")
    const [mtResult, setMtResult] = useState("")

    const [remarkId, setRemarkId] = useState("")
    const [remarkPatientId, setRemarkPatientId] = useState("")
    const [remarkModTechnniques, setRemarkTechniques] = useState("")
    const [progressData, setProgressData] = useState("0%")

    const [modalShow, setModalShow] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [show, setShow] = useState(false);
    const [headerShow, setHeaderShow] = useState(true);
    const handleClose = () => { setShow(false); }
    const handleShow = () => { setHeaderShow(!headerShow); }
    const navigate = useNavigate()
    const logOut = () => { navigate("/") }


    const [hcsList, setHcsList] = useState([])
    const [hematoCytologyList, setHematoCytologyList] = useState([])
    const [seList, setSeList] = useState([])
    const [seCellList, setSeCellList] = useState([])

    const [biochemistryList, setBiochemistryList] = useState([])
    const [bcsList, setBcsList] = useState([])
    const [randumList, setRandumList] = useState([])

    const [glParatismList, setGlParatismList] = useState([])
    const [fsPositiveForList, setFsPositiveForList] = useState([])
    const [facelSampleList, setFacelSampleList] = useState([])

    const [urinalysisList, setUrinalysisList] = useState([])
    const [glucoseList, setGlucoseList] = useState([])
    const [ketanList, setKetanList] = useState([])
    const [bloodList, setBloodList] = useState([])
    const [billirubinList, setBillirubinList] = useState([])
    const [protienList, setProtienList] = useState([])
    const [urobillinogenList, setUrobillinogenList] = useState([])
    const [nitriteList, setNitriteList] = useState([])
    const [leukocytesList, setLeukocytesList] = useState([])

    const [immunolTestList, setImmunolTestList] = useState([])
    const [brMrtList, setBrMrtList] = useState([])
    const [brElisaList, setBrElisaList] = useState([])
    const [rbptList, setRbptList] = useState([])
    const [irbElisaList, setIrbElisaList] = useState([])
    const [statList, setStatList] = useState([])
    const [jidElisaList, setJidElisaList] = useState([])
    const [idTuberulineList, setIdTuberulineList] = useState([])
    const [idJohninelist, setIdJohnineList] = useState([])
    const [ibrFatList, setIbrFatList] = useState([])

    const [scmList, setScmList] = useState([])
    const [mastripList, setMastripList] = useState([])
    const [cmtList, setCmtList] = useState([])
    const [ecList, setEcList] = useState([])
    const [sscThList, setSscThList] = useState([])

    const [absSampleList, setAbsSampleList] = useState([])
    const [absSampleQualityList, setAbsSampleQualityList] = useState([])
    const [pathogenList, setPathogenList] = useState([])
    const [antibioticsList, setAntibioticsList] = useState([])
    const [bkMethodList, setBkMethodList] = useState([])

    const [specialPathList, setSpecialPathList] = useState([])
    const [sps2List, setSps2List] = useState([])
    const [sps3list, setSps3List] = useState([])
    const [sps4List, setSps4List] = useState([])
    const [sps5List, setSps5List] = useState([])
    const [sps6List, setSps6List] = useState([])
    const [spResuList, setSpResuList] = useState([])
    const [spr2List, setSpr2List] = useState([])
    const [spr3List, setSpr3List] = useState([])
    const [spr4List, setSpr4List] = useState([])
    const [spr5List, setSpr5List] = useState([])
    const [spr6List, setSpr6List] = useState([])

    const [txSample1List, setTxSample1List] = useState([])
    const [txSample2List, setTxSample2List] = useState([])
    const [txTest1List, setTxTest1List] = useState([])
    const [txTest2List, setTxTest2List] = useState([])
    const [txTestResult1List, setTxTestResult1List] = useState([])
    const [txTestResult2list, setTxTestResult2list] = useState([])

    const [milktypeList, setMilktypeList] = useState([])
    const [milksSampleList, setMilksSampleList] = useState([])
    const [sampleConditionList, setSampleConditionList] = useState([])

    const [modTechniquesList, setModTechniquesList] = useState([])
    const [mtResultList, setMtResultList] = useState([])
    const [remarkModtechniquesList, setRemarkModtechniquesList] = useState([]);

    const [HcsQualityD, setHCSQualityD] = useState([]);
    const [SeD, setSeD] = useState([])
    const [seCellListD, setSeCellListD] = useState([]);
    const [fsPositiveForListD, setFsPositiveForListD] = useState([]);
    const [facelSampleListD, setFacelSampleListD] = useState([]);
    const [PosNegD, setPosNegD] = useState([]);
    const [hemotoList, setHemotoList] = useState([])
    const [bioChemistryList, setBioChemistryList] = useState([])
    const [glPList, setGlPList] = useState([])
    const [tagIdList, setTagIdList] = useState([])
    const [distintDate, setDistintDate] = useState([])
    const [ketoneList, setKetoneList] = useState([])

    const Add = () => {
        setId("")
        setPatId("")
        setHematoCytology("")
        setHcs("")
        setHbGm("")
        setPCV("")
        setTecm("")
        setTlc("")
        setN("")
        setNul("")
        setL("")
        setLul("")
        setM("")
        setMul("")
        setE("")
        setEul("")
        setB("")
        setBc("")
        setP1pro("")
        setFibrinogen("")
        setPpFib("")
        setSe("")
        setSeCell("")
        setPLT("")
        setWBC("")
        setHCT("")
        setMCV("")
        setMCH("")
        setMCHC("")
        setRBC("")
        setNEUTROPHILS("")
        setLYMPHOCYTES("")
        setMID("")
        setNEUTROPHILSBAND("")

        setBiochemistryId("")
        setBioPatId("")
        setBiochemistry("")
        setBioBcs("")
        setCamg("")
        setProG("")
        setCholM("")
        setPMg("")
        setAlbG("")
        setAsl("")
        setMg("")
        setFlgG("")
        setSdh("")
        setNa("")
        setAlbGlo("")
        setGgt("")
        setK("")
        setPpFib("")
        setAkp("")
        setC1("")
        setBun("")
        setTBill("")
        setGlu("")
        setCr("")
        setDBill("")
        setRandum("")

        setGlId("")
        setGlPatId("")
        setGlParatism("")
        setFsPositiveFor("")
        setFacelSample("")
        setNematodes("")
        setTramatodes("")
        setCystodes("")
        setEpgGt("")

        setUrinalysisId("")
        setUrinalysisPatId("")
        setUrinalysis("")
        setGlucose("")
        setKetan("")
        setSpGravity("")
        setBlood("")
        setPh("")
        setBilirubin("")
        setUsExam("")
        setProtien("")
        setUrobillinogen("")
        setNitrite("")
        setLeukocytes("")

        setImmunologyId("")
        setImmunologyPatId("")
        setImmunolTest("")
        setBrMrt("")
        setBrElisa("")
        setRbpt("")
        setIrbElisa("")
        setStat("")
        setJidElisa("")
        setIdTuberuline("")
        setIdJohnine("")
        setIbrFat("")

        setScmId("")
        setPatId("")
        setScmSampleType("")
        setMastrip("")
        setCmt("")
        setEc("")
        setSscTh("")

        setAntigramId("")
        setAntigramPatId("")
        setAbsSample("")
        setAbsSampleQuality("")
        setPathogenId("")
        setAntibiotics("")
        setBkMethod("")

        setSpId("")
        setSpPatIdNo("")
        setSpecialPath("")
        setSps2("")
        setSps3("")
        setSps4("")
        setSps5("")
        setSps6("")
        setSpResu("")
        setSpr2("")
        setSpr3("")
        setSpr4("")
        setSpr5("")
        setSpr6("")

        setToxicologyId("")
        setToxicologyPatientId("")
        setTxSample1("")
        setTxSample2("")
        setTxTest1("")
        setTxTest2("")
        setTxResult1("")
        setTxResult2("")

        setMilkId("")
        setMilkPatientId("")
        setMilkSampleType("")
        setFat("")
        setSnf("")
        setSampleCondition("")
        setDensity("")
        setMSpGravity("")
        setMilkProtein("")
        setEcMsCm("")
        setMph("")
        setTemperatureC("")
        setLactose("")
        setMun("")
        setMCitrate("")

        setModTechId("")
        setModPatientId("")
        setModTechniques("")
        setMtResult("")

        setRemarkId("")
        setRemarkPatientId("")
        setRemarkTechniques("")
    }

    const SearchFn = () => {
    }

    const SaveHeamtoCytology = async () => {

        if (searchid !== "") {
            // console.log("searchid == " + JSON.stringify(searchid));
            console.log(PLT, WBC);
            await fetch(`${API_KEY}/lab/Save_Heamto_Cytology`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    "Accept": "application/json",
                    "Authorization": `Bearer ${added_by}`
                },
                body: JSON.stringify({
                    ID: "0",
                    PatIdNo: searchid.value ? searchid.value : "",
                    HeamtoCytology: hematoCytology.value ? hematoCytology.value : "",
                    HcsQuality: hcs.value ? hcs.value : "",
                    Hbgm: hbGm ? hbGm : "",
                    Pcv: PCV ? PCV : "",
                    Tec: tecm ? tecm : "",
                    Tlc: tlc ? tlc : "",
                    N: n ? n : "",
                    Nu1: nUl ? nUl : "",
                    L: l ? l : "",
                    Lu1: lUl ? lUl : "",
                    M: m ? m : "",
                    Mu1: mUl ? mUl : "",
                    E: e ? e : "",
                    Eu1: eUl ? eUl : "",
                    B: b ? b : "",
                    Bc: bc ? bc : "",
                    P1pro: p1pro ? p1pro : "",
                    Fibrinogen: fibrinogen ? fibrinogen : "",
                    Ppfib: ppFib ? ppFib : "",
                    SePositiveForInfectionOf: se.value ? se.value : "",
                    SeCellMorphology: seCell.value ? seCell.value : "",
                    Plt: PLT ? PLT : "",
                    Wbc: WBC ? WBC : "",
                    Hct: HCT ? HCT : "",
                    Mcv: MCV ? MCV : "",
                    Mch: MCH ? MCH : "",
                    Mchc: MCHC ? MCHC : "",
                    Rbc: RBC ? RBC : "",
                    Neutrophils: NEUTROPHILS ? NEUTROPHILS : "",
                    Lymphocytes: LYMPHOCYTES ? LYMPHOCYTES : "",
                    Mid: MID ? MID : "",
                    NeutrophilsBand: NEUTROPHILSBAND ? NEUTROPHILSBAND : "",
                    TestDate: dateFormate,
                })

            })
                .then(response => {

                    const contentLength = response.headers.get('content-length');
                    let loaded = 0;
                    return new Response(
                        new ReadableStream({
                            start(controller) {
                                const reader = response.body.getReader();
                                read();
                                function read() {
                                    reader.read()
                                        .then((progressEvent) => {
                                            if (progressEvent.done) {
                                                controller.close();
                                                return;
                                            }
                                            loaded += progressEvent.value.byteLength;
                                            const percentageComplete = Math.round(loaded / contentLength * 100) + '%';
                                            setProgressData(percentageComplete)
                                            controller.enqueue(progressEvent.value);
                                            read();
                                        })
                                }
                            }
                        })
                    );
                })
                .then(res => res.json())
                .then((res) => {
                    Add(); toast.success("Save Hemato cytology Data Successful"); setIsLoading(false); setProgressData("0%")
                    setClrChange(true)
                })


            await fetch(`${Hemato_cytology.saveTestingData}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    "Accept": "application/json",
                    "Authorization": `Bearer ${added_by}`
                },
                body: JSON.stringify({
                    TagID: searchid.value,
                    Date: date,
                    Remarks: "",
                    Createdby: 0,
                    Updatedby: 0,
                    Createdat: "",
                    Updatedat: ""
                })
            })
                .then(response => {
                    const contentLength = response.headers.get('content-length');
                    let loaded = 0;
                    return new Response(
                        new ReadableStream({
                            start(controller) {
                                const reader = response.body.getReader();
                                read();
                                function read() {
                                    reader.read()
                                        .then((progressEvent) => {
                                            if (progressEvent.done) {
                                                controller.close();
                                                return;
                                            }
                                            loaded += progressEvent.value.byteLength;
                                            const percentageComplete = Math.round(loaded / contentLength * 100) + '%';
                                            setProgressData(percentageComplete)
                                            controller.enqueue(progressEvent.value);
                                            read();
                                        })
                                }
                            }
                        })
                    );
                })
                .then(res => res.json())
                .then((res) => { console.log(res); setIsLoading(false); setProgressData("0%") })
        }
        else {
            toast.error("Please Select Animal TagId");
        }
    }

    const SaveBioChemistry = async () => {
        if (searchid !== "") {

            await fetch(`${API_KEY}/lab/Save_Biochemistry`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    "Accept": "application/json",
                    "Authorization": `Bearer ${added_by}`
                },
                body: JSON.stringify({
                    ID: "0",
                    PatIdNo: searchid.value ? searchid.value : "",
                    Biochemistry: biochemistry ? biochemistry : "",
                    Bcsquality: bioBcs ? bioBcs : "",
                    Camg: caMg ? caMg : "",
                    Prog: proG ? proG : "",
                    Cholm: cholM ? cholM : "",
                    Pmg: pMg ? pMg : "",
                    Albg: albG ? albG : "",
                    AsliUl: asl ? asl : "",
                    Mgmg: mg,
                    Figg: flgG,
                    SDHiul: sdh,
                    Nameql: na,
                    Albglo: albGlo,
                    GGTiul: ggt,
                    KmEql: k,
                    Ppfib: bioPpFib,
                    AKPiul: akp,
                    C1mEql: c1,
                    BUNmg: bun,
                    TBillmg: tBill,
                    Glumg: glu,
                    Crmg: cr,
                    DBilmg: dBill,
                    RandumRPT: randum,
                    SECellMorphology: seCellMorphology,
                    TestDate: dateFormate,
                    Cal: Cal,
                    Phos: Phos,
                    bioRbpt: bioRbpt,
                    TbTest: TBTEST,
                    JonhsTestTppd: JONHSTESTPPD,
                })
            })
                .then(response => {

                    const contentLength = response.headers.get('content-length');
                    let loaded = 0;
                    return new Response(
                        new ReadableStream({
                            start(controller) {
                                const reader = response.body.getReader();
                                read();
                                function read() {
                                    reader.read()
                                        .then((progressEvent) => {
                                            if (progressEvent.done) {
                                                controller.close();
                                                return;
                                            }
                                            loaded += progressEvent.value.byteLength;
                                            const percentageComplete = Math.round(loaded / contentLength * 100) + '%';
                                            setProgressData(percentageComplete)
                                            controller.enqueue(progressEvent.value);
                                            read();
                                        })
                                }
                            }
                        })
                    );
                })
                .then(res => res.json())
                .then((res) => {
                    setClrChange2(true)
                    Add(); toast.success("Save Bio Chemestry Data Successful"); setIsLoading(false); setProgressData("0%")
                    if (clrChange == false) {
                        SaveHeamtoCytology();
                    }

                })

            await fetch(`${Hemato_cytology.saveTestingData}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    "Accept": "application/json",
                    "Authorization": `Bearer ${added_by}`
                },
                body: JSON.stringify({
                    TagID: searchid.value,
                    Date: date,
                    Remarks: "",
                    Createdby: 0,
                    Updatedby: 0,
                    Createdat: "",
                    Updatedat: ""
                })
            })
                .then(response => {
                    const contentLength = response.headers.get('content-length');
                    let loaded = 0;
                    return new Response(
                        new ReadableStream({
                            start(controller) {
                                const reader = response.body.getReader();
                                read();
                                function read() {
                                    reader.read()
                                        .then((progressEvent) => {
                                            if (progressEvent.done) {
                                                controller.close();
                                                return;
                                            }
                                            loaded += progressEvent.value.byteLength;
                                            const percentageComplete = Math.round(loaded / contentLength * 100) + '%';
                                            setProgressData(percentageComplete)
                                            controller.enqueue(progressEvent.value);
                                            read();
                                        })
                                }
                            }
                        })
                    );
                })
                .then(res => res.json())
                .then((res) => { console.log(res); setIsLoading(false); setProgressData("0%") })
        }
        else {
            toast.error("Please Select Animal TagId");
        }

    }

    const SaveGiparasitism = async () => {
        if (searchid !== "") {
            if (clrChange == false) {
                SaveHeamtoCytology();
            }
            if (clrChange2 == false) {
                SaveBioChemistry();
            }
            await fetch(`${API_KEY}/lab/Save_GiParasitism`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    "Accept": "application/json",
                    "Authorization": `Bearer ${added_by}`
                },
                body: JSON.stringify({
                    ID: "0",
                    PatIdNo: searchid.value,
                    GIParasitism: glParatism,
                    FSPositiveFor: fsPositiveFor,
                    FecalSampleExa: facelSample,
                    Nematodes: nematodes,
                    Trematodes: tramatodes,
                    Cystodes: cystodes,
                    EPGGT: epgGt,
                    TestDate: dateFormate,
                })
            })
                .then(response => {

                    const contentLength = response.headers.get('content-length');
                    let loaded = 0;
                    return new Response(
                        new ReadableStream({
                            start(controller) {
                                const reader = response.body.getReader();
                                read();
                                function read() {
                                    reader.read()
                                        .then((progressEvent) => {
                                            if (progressEvent.done) {
                                                controller.close();
                                                return;
                                            }
                                            loaded += progressEvent.value.byteLength;
                                            const percentageComplete = Math.round(loaded / contentLength * 100) + '%';
                                            setProgressData(percentageComplete)
                                            controller.enqueue(progressEvent.value);
                                            read();
                                        })
                                }
                            }
                        })
                    );
                })
                .then(res => res.json())
                .then((res) => { Add(); setClrChange3(true); toast.success("Save Data Successful"); setIsLoading(false); setProgressData("0%") })

            await fetch(`${Hemato_cytology.saveTestingData}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    "Accept": "application/json",
                    "Authorization": `Bearer ${added_by}`
                },
                body: JSON.stringify({
                    TagID: searchid.value,
                    Date: date,
                    Remarks: "",
                    Createdby: 0,
                    Updatedby: 0,
                    Createdat: "",
                    Updatedat: ""
                })
            })
                .then(response => {
                    const contentLength = response.headers.get('content-length');
                    let loaded = 0;
                    return new Response(
                        new ReadableStream({
                            start(controller) {
                                const reader = response.body.getReader();
                                read();
                                function read() {
                                    reader.read()
                                        .then((progressEvent) => {
                                            if (progressEvent.done) {
                                                controller.close();
                                                return;
                                            }
                                            loaded += progressEvent.value.byteLength;
                                            const percentageComplete = Math.round(loaded / contentLength * 100) + '%';
                                            setProgressData(percentageComplete)
                                            controller.enqueue(progressEvent.value);
                                            read();
                                        })
                                }
                            }
                        })
                    );
                })
                .then(res => res.json())
                .then((res) => { console.log(res); setIsLoading(false); setProgressData("0%") })
        }
        else {
            toast.error("Please Select Animal TagId");
        }
    }

    const SaveUrinalysis = async () => {
        if (searchid !== "") {
            await fetch(`${API_KEY}/lab/Save_Urinalysis`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    "Accept": "application/json",
                    "Authorization": `Bearer ${added_by}`
                },
                body: JSON.stringify({
                    ID: "0",
                    PatIdNo: searchid.value ? searchid.value : "",
                    Urinalysis: urinalysis ? urinalysisId : "",
                    Glucose: glucose ? glucose : "",
                    Ketan: ketan ? ketan : "",
                    SpGravity: spGravity ? spGravity : "",
                    Blood: blood ? blood : "",
                    pH: ph ? ph : "",
                    Bilirubin: bilirubin ? bilirubin : "",
                    UsExam: usExam ? usExam : "",
                    Protein: protien ? protien : "",
                    Urobilinogen: urobillinogen ? urobillinogen : "",
                    Nitrite: nitrite ? nitrite : "",
                    Leukocytes: leukocytes ? leukocytes : "",
                    TestDate: dateFormate ? dateFormate : "",
                })
            })
                .then(response => {

                    const contentLength = response.headers.get('content-length');
                    let loaded = 0;
                    return new Response(
                        new ReadableStream({
                            start(controller) {
                                const reader = response.body.getReader();
                                read();
                                function read() {
                                    reader.read()
                                        .then((progressEvent) => {
                                            if (progressEvent.done) {
                                                controller.close();
                                                return;
                                            }
                                            loaded += progressEvent.value.byteLength;
                                            const percentageComplete = Math.round(loaded / contentLength * 100) + '%';
                                            setProgressData(percentageComplete)
                                            controller.enqueue(progressEvent.value);
                                            read();
                                        })
                                }
                            }
                        })
                    );
                })
                .then(res => res.json())
                .then((res) => { Add(); setClrChange4(true); toast.success("Save Data Successful"); setIsLoading(false); setProgressData("0%") })

            await fetch(`${Hemato_cytology.saveTestingData}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    "Accept": "application/json",
                    "Authorization": `Bearer ${added_by}`
                },
                body: JSON.stringify({
                    TagID: searchid.value,
                    Date: date,
                    Remarks: "",
                    Createdby: 0,
                    Updatedby: 0,
                    Createdat: "",
                    Updatedat: ""
                })
            })
                .then(response => {
                    const contentLength = response.headers.get('content-length');
                    let loaded = 0;
                    return new Response(
                        new ReadableStream({
                            start(controller) {
                                const reader = response.body.getReader();
                                read();
                                function read() {
                                    reader.read()
                                        .then((progressEvent) => {
                                            if (progressEvent.done) {
                                                controller.close();
                                                return;
                                            }
                                            loaded += progressEvent.value.byteLength;
                                            const percentageComplete = Math.round(loaded / contentLength * 100) + '%';
                                            setProgressData(percentageComplete)
                                            controller.enqueue(progressEvent.value);
                                            read();
                                        })
                                }
                            }
                        })
                    );
                })
                .then(res => res.json())
                .then((res) => { console.log(res); setIsLoading(false); setProgressData("0%") })
        }
        else {
            toast.error("Please Select Animal TagId");
        }
    }

    const SaveImmunology = async () => {
        if (searchid !== "") {
            await fetch(`${API_KEY}/lab/Save_Immunology`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    "Accept": "application/json",
                    "Authorization": `Bearer ${added_by}`
                },
                body: JSON.stringify({
                    ID: "0",
                    PatIdNo: searchid.value ? searchid.value : "",
                    Immuno1TestForDisease: immunolTest ? immunolTest : "",
                    BrMRT: brMrt ? brMrt : "",
                    BrELISA: brElisa ? brElisa : "",
                    RBPT: rbpt ? rbpt : "",
                    IRBELISA: irbElisa ? irbElisa : "",
                    STAT: stat ? stat : "",
                    JIDELISA: jidElisa ? jidElisa : "",
                    IDTuberuline: idTuberuline ? idTuberuline : "",
                    IDJohnie: idJohnine ? idJohnine : "",
                    IBRFAt: ibrFat ? ibrFat : "",
                    TestDate: dateFormate ? dateFormate : "",
                })
            })
                .then(response => {

                    const contentLength = response.headers.get('content-length');
                    let loaded = 0;
                    return new Response(
                        new ReadableStream({
                            start(controller) {
                                const reader = response.body.getReader();
                                read();
                                function read() {
                                    reader.read()
                                        .then((progressEvent) => {
                                            if (progressEvent.done) {
                                                controller.close();
                                                return;
                                            }
                                            loaded += progressEvent.value.byteLength;
                                            const percentageComplete = Math.round(loaded / contentLength * 100) + '%';
                                            setProgressData(percentageComplete)
                                            controller.enqueue(progressEvent.value);
                                            read();
                                        })
                                }
                            }
                        })
                    );
                })
                .then(res => res.json())
                .then((res) => { Add(); setClrChange5(true); toast.success("Save Data Successful"); setIsLoading(false); setProgressData("0%") })

            await fetch(`${Hemato_cytology.saveTestingData}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    "Accept": "application/json",
                    "Authorization": `Bearer ${added_by}`
                },
                body: JSON.stringify({
                    TagID: searchid.value,
                    Date: date,
                    Remarks: "",
                    Createdby: 0,
                    Updatedby: 0,
                    Createdat: "",
                    Updatedat: ""
                })
            })
                .then(response => {
                    const contentLength = response.headers.get('content-length');
                    let loaded = 0;
                    return new Response(
                        new ReadableStream({
                            start(controller) {
                                const reader = response.body.getReader();
                                read();
                                function read() {
                                    reader.read()
                                        .then((progressEvent) => {
                                            if (progressEvent.done) {
                                                controller.close();
                                                return;
                                            }
                                            loaded += progressEvent.value.byteLength;
                                            const percentageComplete = Math.round(loaded / contentLength * 100) + '%';
                                            setProgressData(percentageComplete)
                                            controller.enqueue(progressEvent.value);
                                            read();
                                        })
                                }
                            }
                        })
                    );
                })
                .then(res => res.json())
                .then((res) => { console.log(res); setIsLoading(false); setProgressData("0%") })
        }
        else {
            toast.error("Please Select Animal TagId");
        }
    }

    const SaveScm = async () => {
        if (searchid !== "") {
            await fetch(`${API_KEY}/lab/Save_SCM`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    "Accept": "application/json",
                    "Authorization": `Bearer ${added_by}`
                },
                body: JSON.stringify({
                    ID: 0,
                    PatIdNo: searchid.value,
                    SCMSampleType: scmSampleType,
                    Mastrip: mastrip,
                    CMT: cmt,
                    EC: ec,
                    SSCThml: sscTh,
                    TestDate: dateFormate,
                })
            })
                .then(response => {

                    const contentLength = response.headers.get('content-length');
                    let loaded = 0;
                    return new Response(
                        new ReadableStream({
                            start(controller) {
                                const reader = response.body.getReader();
                                read();
                                function read() {
                                    reader.read()
                                        .then((progressEvent) => {
                                            if (progressEvent.done) {
                                                controller.close();
                                                return;
                                            }
                                            loaded += progressEvent.value.byteLength;
                                            const percentageComplete = Math.round(loaded / contentLength * 100) + '%';
                                            setProgressData(percentageComplete)
                                            controller.enqueue(progressEvent.value);
                                            read();
                                        })
                                }
                            }
                        })
                    );
                })
                .then(res => res.json())
                .then((res) => { Add(); setClrChange6(true); toast.success("Save Data Successful"); setIsLoading(false); setProgressData("0%") })

            await fetch(`${Hemato_cytology.saveTestingData}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    "Accept": "application/json",
                    "Authorization": `Bearer ${added_by}`
                },
                body: JSON.stringify({
                    TagID: searchid.value,
                    Date: date,
                    Remarks: "",
                    Createdby: 0,
                    Updatedby: 0,
                    Createdat: "",
                    Updatedat: ""
                })
            })
                .then(response => {
                    const contentLength = response.headers.get('content-length');
                    let loaded = 0;
                    return new Response(
                        new ReadableStream({
                            start(controller) {
                                const reader = response.body.getReader();
                                read();
                                function read() {
                                    reader.read()
                                        .then((progressEvent) => {
                                            if (progressEvent.done) {
                                                controller.close();
                                                return;
                                            }
                                            loaded += progressEvent.value.byteLength;
                                            const percentageComplete = Math.round(loaded / contentLength * 100) + '%';
                                            setProgressData(percentageComplete)
                                            controller.enqueue(progressEvent.value);
                                            read();
                                        })
                                }
                            }
                        })
                    );
                })
                .then(res => res.json())
                .then((res) => { console.log(res); setIsLoading(false); setProgressData("0%") })
        }
        else {
            toast.error("Please Select Animal TagId");
        }
    }

    const SaveAntigram = async () => {
        if (searchid !== "") {
            await fetch(`${API_KEY}/lab/Save_Antigram`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    "Accept": "application/json",
                    "Authorization": `Bearer ${added_by}`
                },
                body: JSON.stringify({
                    ID: "0",
                    PatIdNo: searchid.value,
                    ABSSample: absSample,
                    ABSSampleQuality: absSampleQuality,
                    PathogenId: pathogenId,
                    Antibiotics: antibiotics,
                    BkDDMethod: bkMethod,
                    TestDate: dateFormate,
                })
            })
                .then(response => {

                    const contentLength = response.headers.get('content-length');
                    let loaded = 0;
                    return new Response(
                        new ReadableStream({
                            start(controller) {
                                const reader = response.body.getReader();
                                read();
                                function read() {
                                    reader.read()
                                        .then((progressEvent) => {
                                            if (progressEvent.done) {
                                                controller.close();
                                                return;
                                            }
                                            loaded += progressEvent.value.byteLength;
                                            const percentageComplete = Math.round(loaded / contentLength * 100) + '%';
                                            setProgressData(percentageComplete)
                                            controller.enqueue(progressEvent.value);
                                            read();
                                        })
                                }
                            }
                        })
                    );
                })
                .then(res => res.json())
                .then((res) => { Add(); setClrChange7(true); toast.success("Save Data Successful"); setIsLoading(false); setProgressData("0%") })

            await fetch(`${Hemato_cytology.saveTestingData}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    "Accept": "application/json",
                    "Authorization": `Bearer ${added_by}`
                },
                body: JSON.stringify({
                    TagID: searchid.value,
                    Date: date,
                    Remarks: "",
                    Createdby: 0,
                    Updatedby: 0,
                    Createdat: "",
                    Updatedat: ""
                })
            })
                .then(response => {
                    const contentLength = response.headers.get('content-length');
                    let loaded = 0;
                    return new Response(
                        new ReadableStream({
                            start(controller) {
                                const reader = response.body.getReader();
                                read();
                                function read() {
                                    reader.read()
                                        .then((progressEvent) => {
                                            if (progressEvent.done) {
                                                controller.close();
                                                return;
                                            }
                                            loaded += progressEvent.value.byteLength;
                                            const percentageComplete = Math.round(loaded / contentLength * 100) + '%';
                                            setProgressData(percentageComplete)
                                            controller.enqueue(progressEvent.value);
                                            read();
                                        })
                                }
                            }
                        })
                    );
                })
                .then(res => res.json())
                .then((res) => { console.log(res); setIsLoading(false); setProgressData("0%") })
        }
        else {
            toast.error("Please Select Animal TagId");
        }
    }

    const SaveSP = async () => {
        if (searchid !== "") {
            await fetch(`${API_KEY}/lab/Save_Sp`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    "Accept": "application/json",
                    "Authorization": `Bearer ${added_by}`
                },
                body: JSON.stringify({
                    ID: "0",
                    PatIdNo: searchid.value,
                    SpecialPath: specialPath,
                    SPS2: sps2,
                    SPS3: sps3,
                    SPS4: sps4,
                    SPS5: sps5,
                    SPS6: sps6,
                    SPResu: spResu,
                    SPR2: spr2,
                    SPR3: spr3,
                    SPR4: spr4,
                    SPR5: spr5,
                    SPR6: spr6,
                    TestDate: dateFormate,
                })
            })
                .then(response => {

                    const contentLength = response.headers.get('content-length');
                    let loaded = 0;
                    return new Response(
                        new ReadableStream({
                            start(controller) {
                                const reader = response.body.getReader();
                                read();
                                function read() {
                                    reader.read()
                                        .then((progressEvent) => {
                                            if (progressEvent.done) {
                                                controller.close();
                                                return;
                                            }
                                            loaded += progressEvent.value.byteLength;
                                            const percentageComplete = Math.round(loaded / contentLength * 100) + '%';
                                            setProgressData(percentageComplete)
                                            controller.enqueue(progressEvent.value);
                                            read();
                                        })
                                }
                            }
                        })
                    );
                })
                .then(res => res.json())
                .then((res) => { Add(); setClrChange8(true); toast.success("Save Data Successful"); setIsLoading(false); setProgressData("0%") })

            await fetch(`${Hemato_cytology.saveTestingData}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    "Accept": "application/json",
                    "Authorization": `Bearer ${added_by}`
                },
                body: JSON.stringify({
                    TagID: searchid.value,
                    Date: date,
                    Remarks: "",
                    Createdby: 0,
                    Updatedby: 0,
                    Createdat: "",
                    Updatedat: ""
                })
            })
                .then(response => {
                    const contentLength = response.headers.get('content-length');
                    let loaded = 0;
                    return new Response(
                        new ReadableStream({
                            start(controller) {
                                const reader = response.body.getReader();
                                read();
                                function read() {
                                    reader.read()
                                        .then((progressEvent) => {
                                            if (progressEvent.done) {
                                                controller.close();
                                                return;
                                            }
                                            loaded += progressEvent.value.byteLength;
                                            const percentageComplete = Math.round(loaded / contentLength * 100) + '%';
                                            setProgressData(percentageComplete)
                                            controller.enqueue(progressEvent.value);
                                            read();
                                        })
                                }
                            }
                        })
                    );
                })
                .then(res => res.json())
                .then((res) => { console.log(res); setIsLoading(false); setProgressData("0%") })
        }
        else {
            toast.error("Please Select Animal TagId");
        }
    }

    const SaveToxicology = async () => {
        if (searchid !== "") {
            await fetch(`${API_KEY}/lab/Save_Toxicology`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    "Accept": "application/json",
                    "Authorization": `Bearer ${added_by}`
                },
                body: JSON.stringify({
                    ID: "0",
                    PatIdNo: searchid.value ? searchid.value : "",
                    TxSample1: txSample1 ? txSample1 : "",
                    TxSample2: txSample2 ? txSample2 : "",
                    TxTest1: txTest1 ? txTest1 : "",
                    TxTest2: txTest2 ? txTest2 : "",
                    TxResult1: txResult1 ? txResult1 : "",
                    TxResult2: txResult2 ? txResult2 : "",
                    TestDate: dateFormate ? dateFormate : "",
                })
            })
                .then(response => {

                    const contentLength = response.headers.get('content-length');
                    let loaded = 0;
                    return new Response(
                        new ReadableStream({
                            start(controller) {
                                const reader = response.body.getReader();
                                read();
                                function read() {
                                    reader.read()
                                        .then((progressEvent) => {
                                            if (progressEvent.done) {
                                                controller.close();
                                                return;
                                            }
                                            loaded += progressEvent.value.byteLength;
                                            const percentageComplete = Math.round(loaded / contentLength * 100) + '%';
                                            setProgressData(percentageComplete)
                                            controller.enqueue(progressEvent.value);
                                            read();
                                        })
                                }
                            }
                        })
                    );
                })
                .then(res => res.json())
                .then((res) => { Add(); setClrChange9(true); toast.success("Save Data Successful"); setIsLoading(false); setProgressData("0%") })

            await fetch(`${Hemato_cytology.saveTestingData}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    "Accept": "application/json",
                    "Authorization": `Bearer ${added_by}`
                },
                body: JSON.stringify({
                    TagID: searchid.value,
                    Date: date,
                    Remarks: "",
                    Createdby: 0,
                    Updatedby: 0,
                    Createdat: "",
                    Updatedat: ""
                })
            })
                .then(response => {
                    const contentLength = response.headers.get('content-length');
                    let loaded = 0;
                    return new Response(
                        new ReadableStream({
                            start(controller) {
                                const reader = response.body.getReader();
                                read();
                                function read() {
                                    reader.read()
                                        .then((progressEvent) => {
                                            if (progressEvent.done) {
                                                controller.close();
                                                return;
                                            }
                                            loaded += progressEvent.value.byteLength;
                                            const percentageComplete = Math.round(loaded / contentLength * 100) + '%';
                                            setProgressData(percentageComplete)
                                            controller.enqueue(progressEvent.value);
                                            read();
                                        })
                                }
                            }
                        })
                    );
                })
                .then(res => res.json())
                .then((res) => { console.log(res); setIsLoading(false); setProgressData("0%") })
        }
        else {
            toast.error("Please Select Animal TagId");
        }
    }

    const SaveMilkChemistry = async () => {
        if (searchid !== "") {
            await fetch(`${API_KEY}/lab/Save_MilkChemistry`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    "Accept": "application/json",
                    "Authorization": `Bearer ${added_by}`
                },
                body: JSON.stringify({
                    ID: "0",
                    PatIdNo: searchid.value,
                    MilkSampleType: milkSampleType,
                    Fat: fat,
                    SNF: snf,
                    TS: ts,
                    SampleCondition: sampleCondition,
                    Density: density,
                    MSpGravity: mSpGravity,
                    Protein: protien,
                    Fpc: fpc,
                    Ecmscm: ecMsCm,
                    MPh: mph,
                    TemperatureC: temperatureC,
                    Lactose: Lactose,
                    Munmg: mun,
                    MCitratemg: mCitrate,
                    TestDate: dateFormate,
                })
            })
                .then(response => {

                    const contentLength = response.headers.get('content-length');
                    let loaded = 0;
                    return new Response(
                        new ReadableStream({
                            start(controller) {
                                const reader = response.body.getReader();
                                read();
                                function read() {
                                    reader.read()
                                        .then((progressEvent) => {
                                            if (progressEvent.done) {
                                                controller.close();
                                                return;
                                            }
                                            loaded += progressEvent.value.byteLength;
                                            const percentageComplete = Math.round(loaded / contentLength * 100) + '%';
                                            setProgressData(percentageComplete)
                                            controller.enqueue(progressEvent.value);
                                            read();
                                        })
                                }
                            }
                        })
                    );
                })
                .then(res => res.json())
                .then((res) => { Add(); setClrChange10(true); toast.success("Save Data Successful"); setIsLoading(false); setProgressData("0%") })

            await fetch(`${Hemato_cytology.saveTestingData}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    "Accept": "application/json",
                    "Authorization": `Bearer ${added_by}`
                },
                body: JSON.stringify({
                    TagID: searchid.value,
                    Date: date,
                    Remarks: "",
                    Createdby: 0,
                    Updatedby: 0,
                    Createdat: "",
                    Updatedat: ""
                })
            })
                .then(response => {
                    const contentLength = response.headers.get('content-length');
                    let loaded = 0;
                    return new Response(
                        new ReadableStream({
                            start(controller) {
                                const reader = response.body.getReader();
                                read();
                                function read() {
                                    reader.read()
                                        .then((progressEvent) => {
                                            if (progressEvent.done) {
                                                controller.close();
                                                return;
                                            }
                                            loaded += progressEvent.value.byteLength;
                                            const percentageComplete = Math.round(loaded / contentLength * 100) + '%';
                                            setProgressData(percentageComplete)
                                            controller.enqueue(progressEvent.value);
                                            read();
                                        })
                                }
                            }
                        })
                    );
                })
                .then(res => res.json())
                .then((res) => { console.log(res); setIsLoading(false); setProgressData("0%") })
        }
        else {
            toast.error("Please Select Animal TagId");
        }
    }

    const SaveModTech = async () => {
        if (searchid !== "") {
            await fetch(`${API_KEY}/lab/Save_ModTech`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    "Accept": "application/json",
                    "Authorization": `Bearer ${added_by}`
                },
                body: JSON.stringify({
                    ID: "0",
                    PatIdNo: searchid.value ? searchid.value : null,
                    ModTechniques: modTechniques ? modTechniques : "",
                    MtResult: mtResult ? mtResult : "",
                    TestDate: dateFormate ? dateFormate : "",
                })
            })
                .then(response => {

                    const contentLength = response.headers.get('content-length');
                    let loaded = 0;
                    return new Response(
                        new ReadableStream({
                            start(controller) {
                                const reader = response.body.getReader();
                                read();
                                function read() {
                                    reader.read()
                                        .then((progressEvent) => {
                                            if (progressEvent.done) {
                                                controller.close();
                                                return;
                                            }
                                            loaded += progressEvent.value.byteLength;
                                            const percentageComplete = Math.round(loaded / contentLength * 100) + '%';
                                            setProgressData(percentageComplete)
                                            controller.enqueue(progressEvent.value);
                                            read();
                                        })
                                }
                            }
                        })
                    );
                })
                .then(res => res.json())
                .then((res) => { Add(); setClrChange11(true); toast.success("Save Data Successful"); setIsLoading(false); setProgressData("0%") })

            await fetch(`${Hemato_cytology.saveTestingData}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    "Accept": "application/json",
                    "Authorization": `Bearer ${added_by}`
                },
                body: JSON.stringify({
                    TagID: searchid.value,
                    Date: date,
                    Remarks: "",
                    Createdby: 0,
                    Updatedby: 0,
                    Createdat: "",
                    Updatedat: ""
                })
            })
                .then(response => {
                    const contentLength = response.headers.get('content-length');
                    let loaded = 0;
                    return new Response(
                        new ReadableStream({
                            start(controller) {
                                const reader = response.body.getReader();
                                read();
                                function read() {
                                    reader.read()
                                        .then((progressEvent) => {
                                            if (progressEvent.done) {
                                                controller.close();
                                                return;
                                            }
                                            loaded += progressEvent.value.byteLength;
                                            const percentageComplete = Math.round(loaded / contentLength * 100) + '%';
                                            setProgressData(percentageComplete)
                                            controller.enqueue(progressEvent.value);
                                            read();
                                        })
                                }
                            }
                        })
                    );
                })
                .then(res => res.json())
                .then((res) => { console.log(res); setIsLoading(false); setProgressData("0%") })
        }
        else {
            toast.error("Please Select Animal TagId");
        }
    }

    const SaveRemarks = async () => {
        if (searchid !== "") {
            await fetch(`${API_KEY}/lab/Save_Remarks`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    "Accept": "application/json",
                    "Authorization": `Bearer ${added_by}`
                },
                body: JSON.stringify({
                    ID: "0",
                    PatIdNo: searchid.value,
                    Remarks: remarkModTechnniques,
                    TestDate: dateFormate,
                })
            })
                .then(response => {

                    const contentLength = response.headers.get('content-length');
                    let loaded = 0;
                    return new Response(
                        new ReadableStream({
                            start(controller) {
                                const reader = response.body.getReader();
                                read();
                                function read() {
                                    reader.read()
                                        .then((progressEvent) => {
                                            if (progressEvent.done) {
                                                controller.close();
                                                return;
                                            }
                                            loaded += progressEvent.value.byteLength;
                                            const percentageComplete = Math.round(loaded / contentLength * 100) + '%';
                                            setProgressData(percentageComplete)
                                            controller.enqueue(progressEvent.value);
                                            read();
                                        })
                                }
                            }
                        })
                    );
                })
                .then(res => res.json())
                .then((res) => { Add(); setClrChange12(true); toast.success("Save Data Successful"); setIsLoading(false); setProgressData("0%") })

            await fetch(`${Hemato_cytology.saveTestingData}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    "Accept": "application/json",
                    "Authorization": `Bearer ${added_by}`
                },
                body: JSON.stringify({
                    TagID: searchid.value,
                    Date: date,
                    Remarks: "",
                    Createdby: 0,
                    Updatedby: 0,
                    Createdat: "",
                    Updatedat: ""
                })
            })
                .then(response => {
                    const contentLength = response.headers.get('content-length');
                    let loaded = 0;
                    return new Response(
                        new ReadableStream({
                            start(controller) {
                                const reader = response.body.getReader();
                                read();
                                function read() {
                                    reader.read()
                                        .then((progressEvent) => {
                                            if (progressEvent.done) {
                                                controller.close();
                                                return;
                                            }
                                            loaded += progressEvent.value.byteLength;
                                            const percentageComplete = Math.round(loaded / contentLength * 100) + '%';
                                            setProgressData(percentageComplete)
                                            controller.enqueue(progressEvent.value);
                                            read();
                                        })
                                }
                            }
                        })
                    );
                })
                .then(res => res.json())
                .then((res) => { console.log(res); setIsLoading(false); setProgressData("0%") })
        }
        else {
            toast.error("Please Select Animal TagId");
        }
    }

    // const view = async () => {
    //     if (index === 1) {
    //         await fetch(`${Hemato_cytology.distinctDateApi}/${searchid.value}`, {
    //             method: 'GET',
    //             headers: {
    //                 'Content-Type': 'application/json',
    //                 "Accept": "application/json",
    //                 "Authorization": `Bearer ${added_by}`
    //             },
    //         })
    //             .then(response => {
    //                 const contentLength = response.headers.get('content-length');
    //                 let loaded = 0;
    //                 return new Response(
    //                     new ReadableStream({
    //                         start(controller) {
    //                             const reader = response.body.getReader();
    //                             read();
    //                             function read() {
    //                                 reader.read()
    //                                     .then((progressEvent) => {
    //                                         if (progressEvent.done) {
    //                                             controller.close();
    //                                             return;
    //                                         }
    //                                         loaded += progressEvent.value.byteLength;
    //                                         const percentageComplete = Math.round(loaded / contentLength * 100) + '%';
    //                                         setProgressData(percentageComplete)
    //                                         controller.enqueue(progressEvent.value);
    //                                         read();
    //                                     })
    //                             }
    //                         }
    //                     })
    //                 );
    //             })
    //             .then(res => res.json())
    //             .then((res) => {
    //                 setDistintDate(res.data)
    //                 setHematoCytologyList(res.data);
    //                 setId(res.data[0].ID)
    //                 setPatId(res.data[0].PatIdNo)
    //                 setHematoCytology(res.data[0].HeamtoCytology)
    //                 setHcs(res.data[0].HcsQuality)
    //                 setHbGm(res.data[0].Hbgm)
    //                 setPCV(res.data[0].Pcv)
    //                 setTecm(res.data[0].Tec)
    //                 setTlc(res.data[0].Tlc)
    //                 setN(res.data[0].N)
    //                 setNul(res.data[0].Nu1)
    //                 setL(res.data[0].L)
    //                 setLul(res.data[0].Lu1)
    //                 setM(res.data[0].M)
    //                 setMul(res.data[0].Mu1)
    //                 setE(res.data[0].E)
    //                 setEul(res.data[0].Eu1)
    //                 setB(res.data[0].B)
    //                 setBc(res.data[0].Bc)
    //                 setP1pro(res.data[0].P1pro)
    //                 setFibrinogen(res.data[0].Fibrinogen)
    //                 setPpFib(res.data[0].P1pro)
    //                 setSe(res.data[0].SePositiveForInfectionOf)
    //                 setSeCell(res.data[0].SeCellMorphology)
    //                 setIsLoading(false);
    //                 setProgressData("0%")
    //             })
    //     }
    //     else if (index === 2) {
    //         await fetch(`${Hemato_cytology.showBiochemistryData}`, {
    //             method: 'POST',
    //             headers: {
    //                 'Content-Type': 'application/json',
    //                 "Accept": "application/json",
    //                 "Authorization": `Bearer ${added_by}`
    //             },
    //             body: JSON.stringify({
    //                 Id: searchid,
    //                 Testing: testing,
    //                 Date: formattedDate,
    //             })
    //         })
    //             .then(response => {
    //                 const contentLength = response.headers.get('content-length');
    //                 let loaded = 0;
    //                 return new Response(
    //                     new ReadableStream({
    //                         start(controller) {
    //                             const reader = response.body.getReader();
    //                             read();
    //                             function read() {
    //                                 reader.read()
    //                                     .then((progressEvent) => {
    //                                         if (progressEvent.done) {
    //                                             controller.close();
    //                                             return;
    //                                         }
    //                                         loaded += progressEvent.value.byteLength;
    //                                         const percentageComplete = Math.round(loaded / contentLength * 100) + '%';
    //                                         setProgressData(percentageComplete)
    //                                         controller.enqueue(progressEvent.value);
    //                                         read();
    //                                     })
    //                             }
    //                         }
    //                     })
    //                 );
    //             })
    //             .then(res => res.json())
    //             .then((res) => {
    //                 setBiochemistryId(res.data[0].ID)
    //                 setBioPatId(res.data[0].PatIdNo)
    //                 setBiochemistry(res.data[0].Biochemistry)
    //                 setBioBcs(res.data[0].Bcsquality)
    //                 setProG(res.data[0].Prog)
    //                 setCholM(res.data[0].Cholm)
    //                 setCamg(res.data[0].Camg)
    //                 setPMg(res.data[0].Pmg)
    //                 setAlbG(res.data[0].Albg)
    //                 setAsl(res.data[0].AsliUl)
    //                 setMg(res.data[0].Mgmg)
    //                 setFlgG(res.data[0].Figg)
    //                 setSdh(res.data[0].SDHiul)
    //                 setNa(res.data[0].Nameql)
    //                 setAlbGlo(res.data[0].Albglo)
    //                 setGgt(res.data[0].GGTiul)
    //                 setK(res.data[0].KmEql)
    //                 setBioPpFib(res.data[0].Ppfib)
    //                 setAkp(res.data[0].AKPiul)
    //                 setC1(res.data[0].C1mEql)
    //                 setBun(res.data[0].BUNmg)
    //                 setTBill(res.data[0].TBillmg)
    //                 setGlu(res.data[0].Glumg)
    //                 setCr(res.data[0].Crmg)
    //                 setDBill(res.data[0].DBilmg)
    //                 setRandum(res.data[0].RandumRPT)
    //                 setSECellMorphology(res.data[0].SECellMorphology)
    //                 setIsLoading(false);
    //                 setProgressData("0%")
    //             })
    //     }
    //     else if (index === 3) {
    //         await fetch(`${Hemato_cytology.showGIParasitismData}`, {
    //             method: 'POST',
    //             headers: {
    //                 'Content-Type': 'application/json',
    //                 "Accept": "application/json",
    //                 "Authorization": `Bearer ${added_by}`
    //             },
    //             body: JSON.stringify({
    //                 Id: searchid,
    //                 Testing: testing,
    //                 Date: formattedDate,
    //             })
    //         })
    //             .then(response => {
    //                 const contentLength = response.headers.get('content-length');
    //                 let loaded = 0;
    //                 return new Response(
    //                     new ReadableStream({
    //                         start(controller) {
    //                             const reader = response.body.getReader();
    //                             read();
    //                             function read() {
    //                                 reader.read()
    //                                     .then((progressEvent) => {
    //                                         if (progressEvent.done) {
    //                                             controller.close();
    //                                             return;
    //                                         }
    //                                         loaded += progressEvent.value.byteLength;
    //                                         const percentageComplete = Math.round(loaded / contentLength * 100) + '%';
    //                                         setProgressData(percentageComplete)
    //                                         controller.enqueue(progressEvent.value);
    //                                         read();
    //                                     })
    //                             }
    //                         }
    //                     })
    //                 );
    //             })
    //             .then(res => res.json())
    //             .then((res) => {
    //                 setHematoCytologyList(res.data);
    //                 setGlId(res.data[0].ID)
    //                 setGlPatId(res.data[0].PatIdNo)
    //                 setGlParatism(res.data[0].GIParasitism)
    //                 setFsPositiveFor(res.data[0].FSPositiveFor)
    //                 setFacelSample(res.data[0].FecalSampleExa)
    //                 setTramatodes(res.data[0].Trematodes)
    //                 setCystodes(res.data[0].Cestodes)
    //                 setNematodes(res.data[0].Nematodes)
    //                 setEpgGt(res.data[0].EPGGT)
    //                 setIsLoading(false);
    //                 setProgressData("0%")
    //             })
    //     }
    //     else if (index === 4) {
    //         await fetch(`${Hemato_cytology.showUrinalysisData}`, {
    //             method: 'POST',
    //             headers: {
    //                 'Content-Type': 'application/json',
    //                 "Accept": "application/json",
    //                 "Authorization": `Bearer ${added_by}`
    //             },
    //             body: JSON.stringify({
    //                 Id: searchid,
    //                 Testing: testing,
    //                 Date: formattedDate,
    //             })
    //         })
    //             .then(response => {
    //                 const contentLength = response.headers.get('content-length');
    //                 let loaded = 0;
    //                 return new Response(
    //                     new ReadableStream({
    //                         start(controller) {
    //                             const reader = response.body.getReader();
    //                             read();
    //                             function read() {
    //                                 reader.read()
    //                                     .then((progressEvent) => {
    //                                         if (progressEvent.done) {
    //                                             controller.close();
    //                                             return;
    //                                         }
    //                                         loaded += progressEvent.value.byteLength;
    //                                         const percentageComplete = Math.round(loaded / contentLength * 100) + '%';
    //                                         setProgressData(percentageComplete)
    //                                         controller.enqueue(progressEvent.value);
    //                                         read();
    //                                     })
    //                             }
    //                         }
    //                     })
    //                 );
    //             })
    //             .then(res => res.json())
    //             .then((res) => {
    //                 setUrinalysisId(res.data[0].ID)
    //                 setUrinalysisPatId(res.data[0].PatIdNo)
    //                 setUrinalysis(res.data[0].Urinalysis)
    //                 setGlucose(res.data[0].Glucose)
    //                 setKetan(res.data[0].Ketan)
    //                 setSpGravity(res.data[0].SpGravity)
    //                 setBlood(res.data[0].Blood)
    //                 setPh(res.data[0].pH)
    //                 setBilirubin(res.data[0].Bilirubin)
    //                 setUsExam(res.data[0].UsExam)
    //                 setProtien(res.data[0].Protein)
    //                 setUrobillinogen(res.data[0].Urobilinogen)
    //                 setNitrite(res.data[0].Nitrite)
    //                 setLeukocytes(res.data[0].Leukocytes)
    //                 setIsLoading(false);
    //                 setProgressData("0%")
    //             })
    //     }
    //     else if (index === 5) {
    //         await fetch(`${Hemato_cytology.showImmunologyData}`, {
    //             method: 'POST',
    //             headers: {
    //                 'Content-Type': 'application/json',
    //                 "Accept": "application/json",
    //                 "Authorization": `Bearer ${added_by}`
    //             },
    //             body: JSON.stringify({
    //                 Id: searchid,
    //                 Testing: testing,
    //                 Date: formattedDate,
    //             })
    //         })
    //             .then(response => {
    //                 const contentLength = response.headers.get('content-length');
    //                 let loaded = 0;
    //                 return new Response(
    //                     new ReadableStream({
    //                         start(controller) {
    //                             const reader = response.body.getReader();
    //                             read();
    //                             function read() {
    //                                 reader.read()
    //                                     .then((progressEvent) => {
    //                                         if (progressEvent.done) {
    //                                             controller.close();
    //                                             return;
    //                                         }
    //                                         loaded += progressEvent.value.byteLength;
    //                                         const percentageComplete = Math.round(loaded / contentLength * 100) + '%';
    //                                         setProgressData(percentageComplete)
    //                                         controller.enqueue(progressEvent.value);
    //                                         read();
    //                                     })
    //                             }
    //                         }
    //                     })
    //                 );
    //             })
    //             .then(res => res.json())
    //             .then((res) => {
    //                 setImmunologyId(res.data[0].ID)
    //                 setImmunologyPatId(res.data[0].PatIdNo)
    //                 setImmunolTest(res.data[0].Immuno1TestForDisease)
    //                 setBrMrt(res.data[0].BrMRT)
    //                 setBrElisa(res.data[0].BrELISA)
    //                 setRbpt(res.data[0].RBPT)
    //                 setStat(res.data[0].STAT)
    //                 setJidElisa(res.data[0].JIDELISA)
    //                 setIdTuberuline(res.data[0].IDTuberuline)
    //                 setIdJohnine(res.data[0].IDJohnie)
    //                 setIbrFat(res.data[0].IBRFAt)
    //                 setIrbElisa(res.data[0].IRBELISA)
    //                 setIsLoading(false);
    //                 setProgressData("0%")
    //             })
    //     }
    //     else if (index === 6) {
    //         await fetch(`${Hemato_cytology.showSCMData}`, {
    //             method: 'POST',
    //             headers: {
    //                 'Content-Type': 'application/json',
    //                 "Accept": "application/json",
    //                 "Authorization": `Bearer ${added_by}`
    //             },
    //             body: JSON.stringify({
    //                 Id: searchid,
    //                 Testing: testing,
    //                 Date: formattedDate,
    //             })
    //         })
    //             .then(response => {
    //                 const contentLength = response.headers.get('content-length');
    //                 let loaded = 0;
    //                 return new Response(
    //                     new ReadableStream({
    //                         start(controller) {
    //                             const reader = response.body.getReader();
    //                             read();
    //                             function read() {
    //                                 reader.read()
    //                                     .then((progressEvent) => {
    //                                         if (progressEvent.done) {
    //                                             controller.close();
    //                                             return;
    //                                         }
    //                                         loaded += progressEvent.value.byteLength;
    //                                         const percentageComplete = Math.round(loaded / contentLength * 100) + '%';
    //                                         setProgressData(percentageComplete)
    //                                         controller.enqueue(progressEvent.value);
    //                                         read();
    //                                     })
    //                             }
    //                         }
    //                     })
    //                 );
    //             })
    //             .then(res => res.json())
    //             .then((res) => {
    //                 setScmId(res.data[0].ID)
    //                 setScmPatIdNo(res.data[0].PatIdNo)
    //                 setScmSampleType(res.data[0].SCMSampleType)
    //                 setMastrip(res.data[0].Mastrip)
    //                 setCmt(res.data[0].CMT)
    //                 setEc(res.data[0].EC)
    //                 setSscTh(res.data[0].SSCThml)
    //                 setIsLoading(false);
    //                 setProgressData("0%")
    //             })
    //     }
    //     else if (index === 7) {
    //         await fetch(`${Hemato_cytology.showAntigramData}`, {
    //             method: 'POST',
    //             headers: {
    //                 'Content-Type': 'application/json',
    //                 "Accept": "application/json",
    //                 "Authorization": `Bearer ${added_by}`
    //             },
    //             body: JSON.stringify({
    //                 Id: searchid,
    //                 Testing: testing,
    //                 Date: formattedDate,
    //             })
    //         })
    //             .then(response => {
    //                 const contentLength = response.headers.get('content-length');
    //                 let loaded = 0;
    //                 return new Response(
    //                     new ReadableStream({
    //                         start(controller) {
    //                             const reader = response.body.getReader();
    //                             read();
    //                             function read() {
    //                                 reader.read()
    //                                     .then((progressEvent) => {
    //                                         if (progressEvent.done) {
    //                                             controller.close();
    //                                             return;
    //                                         }
    //                                         loaded += progressEvent.value.byteLength;
    //                                         const percentageComplete = Math.round(loaded / contentLength * 100) + '%';
    //                                         setProgressData(percentageComplete)
    //                                         controller.enqueue(progressEvent.value);
    //                                         read();
    //                                     })
    //                             }
    //                         }
    //                     })
    //                 );
    //             })
    //             .then(res => res.json())
    //             .then((res) => {
    //                 setAntigramId(res.data[0].ID)
    //                 setAntigramPatId(res.data[0].PatIdNo)
    //                 setAbsSample(res.data[0].ABSSample)
    //                 setAbsSampleQuality(res.data[0].ABSSampleQuality)
    //                 setPathogenId(res.data[0].PathogenId)
    //                 setAntibiotics(res.data[0].Antibiotics)
    //                 setBkMethod(res.data[0].BkDDMethod)
    //                 setIsLoading(false);
    //                 setProgressData("0%")
    //             })
    //     }
    //     else if (index === 8) {
    //         await fetch(`${Hemato_cytology.showExecuteSpData}`, {
    //             method: 'POST',
    //             headers: {
    //                 'Content-Type': 'application/json',
    //                 "Accept": "application/json",
    //                 "Authorization": `Bearer ${added_by}`
    //             },
    //             body: JSON.stringify({
    //                 Id: searchid,
    //                 Testing: testing,
    //                 Date: formattedDate,
    //             })
    //         })
    //             .then(response => {
    //                 const contentLength = response.headers.get('content-length');
    //                 let loaded = 0;
    //                 return new Response(
    //                     new ReadableStream({
    //                         start(controller) {
    //                             const reader = response.body.getReader();
    //                             read();
    //                             function read() {
    //                                 reader.read()
    //                                     .then((progressEvent) => {
    //                                         if (progressEvent.done) {
    //                                             controller.close();
    //                                             return;
    //                                         }
    //                                         loaded += progressEvent.value.byteLength;
    //                                         const percentageComplete = Math.round(loaded / contentLength * 100) + '%';
    //                                         setProgressData(percentageComplete)
    //                                         controller.enqueue(progressEvent.value);
    //                                         read();
    //                                     })
    //                             }
    //                         }
    //                     })
    //                 );
    //             })
    //             .then(res => res.json())
    //             .then((res) => {
    //                 setSpId(res.data[0].ID)
    //                 setSpPatIdNo(res.data[0].PatIdNo)
    //                 setSpecialPath(res.data[0].SpecialPath)
    //                 setSps2(res.data[0].SPS2)
    //                 setSps3(res.data[0].SPS3)
    //                 setSps4(res.data[0].SPS4)
    //                 setSps5(res.data[0].SPS4)
    //                 setSps5(res.data[0].SPS5)
    //                 setSps6(res.data[0].SPS6)
    //                 setSpResu(res.data[0].SPResu)
    //                 setSpr2(res.data[0].SPR2)
    //                 setSpr3(res.data[0].SPR3)
    //                 setSpr4(res.data[0].SPR4)
    //                 setSpr5(res.data[0].SPR5)
    //                 setSpr6(res.data[0].SPR6)
    //                 setIsLoading(false);
    //                 setProgressData("0%")
    //             })
    //     }
    //     else if (index === 9) {
    //         await fetch(`${Hemato_cytology.showToxicologyData}`, {
    //             method: 'POST',
    //             headers: {
    //                 'Content-Type': 'application/json',
    //                 "Accept": "application/json",
    //                 "Authorization": `Bearer ${added_by}`
    //             },
    //             body: JSON.stringify({
    //                 Id: searchid,
    //                 Testing: testing,
    //                 Date: formattedDate,
    //             })
    //         })
    //             .then(response => {
    //                 const contentLength = response.headers.get('content-length');
    //                 let loaded = 0;
    //                 return new Response(
    //                     new ReadableStream({
    //                         start(controller) {
    //                             const reader = response.body.getReader();
    //                             read();
    //                             function read() {
    //                                 reader.read()
    //                                     .then((progressEvent) => {
    //                                         if (progressEvent.done) {
    //                                             controller.close();
    //                                             return;
    //                                         }
    //                                         loaded += progressEvent.value.byteLength;
    //                                         const percentageComplete = Math.round(loaded / contentLength * 100) + '%';
    //                                         setProgressData(percentageComplete)
    //                                         controller.enqueue(progressEvent.value);
    //                                         read();
    //                                     })
    //                             }
    //                         }
    //                     })
    //                 );
    //             })
    //             .then(res => res.json())
    //             .then((res) => {
    //                 setToxicologyId(res.data[0].ID)
    //                 setToxicologyPatientId(res.data[0].PatIdNo)
    //                 setTxSample1(res.data[0].TxSample1)
    //                 setTxSample2(res.data[0].TxSample2)
    //                 setTxTest1(res.data[0].TxTest1)
    //                 setTxTest2(res.data[0].TxTest2)
    //                 setTxResult1(res.data[0].TxResult1)
    //                 setTxResult2(res.data[0].TxResult2)
    //                 setIsLoading(false);
    //                 setProgressData("0%")
    //             })
    //     }
    //     else if (index === 10) {
    //         await fetch(`${Hemato_cytology.showExecuteMilkChemistryData}`, {
    //             method: 'POST',
    //             headers: {
    //                 'Content-Type': 'application/json',
    //                 "Accept": "application/json",
    //                 "Authorization": `Bearer ${added_by}`
    //             },
    //             body: JSON.stringify({
    //                 Id: searchid,
    //                 Testing: testing,
    //                 Date: formattedDate,
    //             })
    //         })
    //             .then(response => {
    //                 const contentLength = response.headers.get('content-length');
    //                 let loaded = 0;
    //                 return new Response(
    //                     new ReadableStream({
    //                         start(controller) {
    //                             const reader = response.body.getReader();
    //                             read();
    //                             function read() {
    //                                 reader.read()
    //                                     .then((progressEvent) => {
    //                                         if (progressEvent.done) {
    //                                             controller.close();
    //                                             return;
    //                                         }
    //                                         loaded += progressEvent.value.byteLength;
    //                                         const percentageComplete = Math.round(loaded / contentLength * 100) + '%';
    //                                         setProgressData(percentageComplete)
    //                                         controller.enqueue(progressEvent.value);
    //                                         read();
    //                                     })
    //                             }
    //                         }
    //                     })
    //                 );
    //             })
    //             .then(res => res.json())
    //             .then((res) => {
    //                 setMilkId(res.data[0].ID)
    //                 setMilkPatientId(res.data[0].PatIdNo)
    //                 setMilkSampleType(res.data[0].MilkSampleType)
    //                 setFat(res.data[0].Fat)
    //                 setSnf(res.data[0].SNF)
    //                 setTs(res.data[0].TS)
    //                 setSampleCondition(res.data[0].SampleCondition)
    //                 setDensity(res.data[0].Density)
    //                 setMSpGravity(res.data[0].MSpGravity)
    //                 setMilkProtein(res.data[0].Protein)
    //                 setFpc(res.data[0].Fpc)
    //                 setEcMsCm(res.data[0].Ecmscm)
    //                 setMph(res.data[0].MPh)
    //                 setTemperatureC(res.data[0].TemperatureC)
    //                 setLactose(res.data[0].Lactose)
    //                 setMun(res.data[0].Munmg)
    //                 setMCitrate(res.data[0].MCitratemg)
    //                 setIsLoading(false);
    //                 setProgressData("0%")
    //             })
    //     }
    //     else if (index === 11) {
    //         await fetch(`${Hemato_cytology.showExecuteModTechData}`, {
    //             method: 'POST',
    //             headers: {
    //                 'Content-Type': 'application/json',
    //                 "Accept": "application/json",
    //                 "Authorization": `Bearer ${added_by}`
    //             },
    //             body: JSON.stringify({
    //                 Id: searchid,
    //                 Testing: testing,
    //                 Date: formattedDate,
    //             })
    //         })
    //             .then(response => {
    //                 const contentLength = response.headers.get('content-length');
    //                 let loaded = 0;
    //                 return new Response(
    //                     new ReadableStream({
    //                         start(controller) {
    //                             const reader = response.body.getReader();
    //                             read();
    //                             function read() {
    //                                 reader.read()
    //                                     .then((progressEvent) => {
    //                                         if (progressEvent.done) {
    //                                             controller.close();
    //                                             return;
    //                                         }
    //                                         loaded += progressEvent.value.byteLength;
    //                                         const percentageComplete = Math.round(loaded / contentLength * 100) + '%';
    //                                         setProgressData(percentageComplete)
    //                                         controller.enqueue(progressEvent.value);
    //                                         read();
    //                                     })
    //                             }
    //                         }
    //                     })
    //                 );
    //             })
    //             .then(res => res.json())
    //             .then((res) => {
    //                 setModTechId(res.data[0].ID)
    //                 setModPatientId(res.data[0].PatIdNo)
    //                 setModTechniques(res.data[0].ModTechniques)
    //                 setMtResult(res.data[0].MtResult)
    //                 setIsLoading(false);
    //                 setProgressData("0%")
    //             })
    //     }
    //     else if (index === 12) {
    //         await fetch(`${Hemato_cytology.showExecuteRemarksData}`, {
    //             method: 'POST',
    //             headers: {
    //                 'Content-Type': 'application/json',
    //                 "Accept": "application/json",
    //                 "Authorization": `Bearer ${added_by}`
    //             },
    //             body: JSON.stringify({
    //                 Id: searchid,
    //                 Testing: testing,
    //                 Date: formattedDate,
    //             })
    //         })
    //             .then(response => {
    //                 const contentLength = response.headers.get('content-length');
    //                 let loaded = 0;
    //                 return new Response(
    //                     new ReadableStream({
    //                         start(controller) {
    //                             const reader = response.body.getReader();
    //                             read();
    //                             function read() {
    //                                 reader.read()
    //                                     .then((progressEvent) => {
    //                                         if (progressEvent.done) {
    //                                             controller.close();
    //                                             return;
    //                                         }
    //                                         loaded += progressEvent.value.byteLength;
    //                                         const percentageComplete = Math.round(loaded / contentLength * 100) + '%';
    //                                         setProgressData(percentageComplete)
    //                                         controller.enqueue(progressEvent.value);
    //                                         read();
    //                                     })
    //                             }
    //                         }
    //                     })
    //                 );
    //             })
    //             .then(res => res.json())
    //             .then((res) => {
    //                 setRemarkId(res.data[0].ID)
    //                 setRemarkPatientId(res.data[0].PatIdNo)
    //                 setRemarkTechniques(res.data[0].Remarks)
    //                 setIsLoading(false);
    //                 setProgressData("0%")
    //             })
    //     }
    // }

    const handleInput = (e, f) => {
        const inputValue = e.target.value;

        if (f == "id") {
            const Value = inputValue.replace(/[^0-9]/g, '');
            setId(Value);
        }
        else if (f == "patId") {
            const Value = inputValue.replace(/[^0-9]/g, '');
            setPatId(Value);
        } else if (f == "PCV") {
            const Value = inputValue.replace(/[^0-9.]/g, '');
            setPCV(Value);
        }
        else if (f == "tecm") {
            const Value = inputValue.replace(/[^0-9.]/g, '');
            setTecm(Value);
        }
        else if (f == "tlc") {
            const Value = inputValue.replace(/[^0-9.]/g, '');
            setTlc(Value);
        } else if (f == "M") {
            const Value = inputValue.replace(/[^0-9.]/g, '');
            setM(Value);
        } else if (f == "N") {
            const Value = inputValue.replace(/[^0-9.]/g, '');
            setN(Value);
        } else if (f == "E") {
            const Value = inputValue.replace(/[^0-9.]/g, '');
            setE(Value);
        } else if (f == "L") {
            const Value = inputValue.replace(/[^0-9.]/g, '');
            setL(Value);
        } else if (f == "B") {
            const Value = inputValue.replace(/[^0-9.]/g, '');
            setB(Value);
        } else if (f == "Bc") {
            const Value = inputValue.replace(/[^0-9.]/g, '');
            setBc(Value);
        }
        else if (f == "PLT") {
            const Value = inputValue.replace(/[^0-9.]/g, '');
            setPLT(Value);
        }
        else if (f == "RBC") {
            const Value = inputValue.replace(/[^0-9.]/g, '');
            setRBC(Value);
        }
        else if (f == "WBC") {
            const Value = inputValue.replace(/[^0-9.]/g, '');
            setWBC(Value);
        }
        else if (f == "HCT") {
            const Value = inputValue.replace(/[^0-9.]/g, '');
            setHCT(Value);
        }
        else if (f == "MCV") {
            const Value = inputValue.replace(/[^0-9.]/g, '');
            setMCV(Value);
        }
        else if (f == "MCH") {
            const Value = inputValue.replace(/[^0-9.]/g, '');
            setMCH(Value);
        }
        else if (f == "MCHC") {
            const Value = inputValue.replace(/[^0-9.]/g, '');
            setMCHC(Value);
        }
        else if (f == "NEUTROPHILS") {
            const Value = inputValue.replace(/[^0-9.]/g, '');
            setNEUTROPHILS(Value);
        }
        else if (f == "LYMPHOCYTES") {
            const Value = inputValue.replace(/[^0-9.]/g, '');
            setLYMPHOCYTES(Value);
        }
        else if (f == "MID") {
            const Value = inputValue.replace(/[^0-9.]/g, '');
            setMID(Value);
        }
        else if (f == "NEUTROPHILS BAND") {
            const Value = inputValue.replace(/[^0-9.]/g, '');
            setNEUTROPHILSBAND(Value);
        }
        else if (f == "N") {
            const Value = inputValue.replace(/[^0-9.]/g, '');
            setNEUTROPHILSBAND(Value);
        }
        else if (f == "HB gm%") {
            const Value = inputValue.replace(/[^0-9.]/g, '');
            setHbGm(Value);
        }
    };

    const handleInputBioChemistry = (e, f) => {
        const inputValue = e.target.value;

        if (f == "biochemistryid") {
            const Value = inputValue.replace(/[^0-9]/g, '');
            setBiochemistryId(Value);
        } else if (f == "bioPatId") {
            const Value = inputValue.replace(/[^0-9]/g, '');
            setBioPatId(Value);
        }
        else if (f == "caMg") {
            const Value = inputValue.replace(/[^0-9]/g, '');
            setCamg(Value);
        }
        else if (f == "proG") {
            const Value = inputValue.replace(/[^0-9]/g, '');
            setProG(Value);
        }
        else if (f == "tecm") {
            const Value = inputValue.replace(/[^0-9]/g, '');
            setTecm(Value);
        }
        else if (f == "pMg") {
            const Value = inputValue.replace(/[^0-9]/g, '');
            setPMg(Value);
        }
        else if (f == "albG") {
            const Value = inputValue.replace(/[^0-9]/g, '');
            setAlbG(Value);
        }
        else if (f == "asl") {
            const Value = inputValue.replace(/[^0-9]/g, '');
            setAsl(Value);
        }
        else if (f == "mg") {
            const Value = inputValue.replace(/[^0-9]/g, '');
            setMg(Value);
        }
        else if (f == "flgG") {
            const Value = inputValue.replace(/[^0-9]/g, '');
            setFlgG(Value);
        }
        else if (f == "sdh") {
            const Value = inputValue.replace(/[^0-9]/g, '');
            setSdh(Value);
        }
        else if (f == "na") {
            const Value = inputValue.replace(/[^0-9]/g, '');
            setNa(Value);
        }
        else if (f == "albGlo") {
            const Value = inputValue.replace(/[^0-9]/g, '');
            setAlbGlo(Value);
        }
        else if (f == "ggt") {
            const Value = inputValue.replace(/[^0-9]/g, '');
            setGgt(Value);
        }
        else if (f == "k") {
            const Value = inputValue.replace(/[^0-9]/g, '');
            setK(Value);
        }
        else if (f == "ppFib") {
            const Value = inputValue.replace(/[^0-9]/g, '');
            setPpFib(Value);
        }
        else if (f == "akp") {
            const Value = inputValue.replace(/[^0-9]/g, '');
            setAkp(Value);
        }
        else if (f == "c1") {
            const Value = inputValue.replace(/[^0-9]/g, '');
            setC1(Value);
        }
        else if (f == "bun") {
            const Value = inputValue.replace(/[^0-9]/g, '');
            setBun(Value);
        }
        else if (f == "tBill") {
            const Value = inputValue.replace(/[^0-9]/g, '');
            setTBill(Value);
        }
        else if (f == "cr") {
            const Value = inputValue.replace(/[^0-9]/g, '');
            setCr(Value);
        }
        else if (f == "dBill") {
            const Value = inputValue.replace(/[^0-9]/g, '');
            setDBill(Value);
        }
        else if (f == "glu") {
            const Value = inputValue.replace(/[^0-9]/g, '');
            setGlu(Value);
        }
        else if (f == "Cal") {
            const Value = inputValue.replace(/[^0-9]/g, '');
            setCal(Value);
        }
        else if (f == "Phos") {
            const Value = inputValue.replace(/[^0-9]/g, '');
            setPhos(Value);
        }
        else if (f == "bioRbpt") {
            const Value = inputValue.replace(/[^0-9]/g, '');
            setBioRbpt(Value);
        }
        else if (f == "JONHSTESTPPD") {
            const Value = inputValue.replace(/[^0-9]/g, '');
            setJONHSTESTPPD(Value);
        }
        else if (f == "TBTEST") {
            const Value = inputValue.replace(/[^0-9]/g, '');
            setTBTEST(Value);
        }

    };

    const handleInputGI = (e, f) => {
        const inputValue = e.target.value;

        if (f == "glId") {
            const Value = inputValue.replace(/[^0-9]/g, '');
            setGlId(Value);
        }
        else if (f == "glPatId") {
            const Value = inputValue.replace(/[^0-9]/g, '');
            setGlPatId(Value);
        }
    };

    const handleInputurinalysis = (e, f) => {
        const inputValue = e.target.value;

        if (f == "urinalysisId") {
            const Value = inputValue.replace(/[^0-9]/g, '');
            setUrinalysisId(Value);
        }
        else if (f == "urinalysisPatId") {
            const Value = inputValue.replace(/[^0-9]/g, '');
            urinalysisPatId(Value);
        }
    };

    const handleInputImmunology = (e, f) => {
        const inputValue = e.target.value;

        if (f == "immunologyId") {
            const Value = inputValue.replace(/[^0-9]/g, '');
            setImmunologyId(Value);
        }
        else if (f == "immunologyPatId") {
            const Value = inputValue.replace(/[^0-9]/g, '');
            setImmunologyPatId(Value);
        }
    };

    const DropdownOptionsHcs = async () => {

        await fetch(`${Hemato_cytology.getSampleData}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`
            },
            body: JSON.stringify({ Testtype: 1 })
        })
            .then(res => res.json())
            .then((res) => {
                setHCSQualityD(res.data)
            })

        await fetch(`${Hemato_cytology.getSampleData}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`
            },
            body: JSON.stringify({ Testtype: 2 })
        })
            .then(res => res.json())
            .then((res) => {
                setSeD(res.data)
            })

        await fetch(`${Hemato_cytology.getSampleData}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`
            },
            body: JSON.stringify({ Testtype: 3 })
        })
            .then(res => res.json())
            .then((res) => {
                setSeCellListD(res.data)
            })

        await fetch(`${Hemato_cytology.getSampleData}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`
            },
            body: JSON.stringify({ Testtype: 4 })
        })
            .then(res => res.json())
            .then((res) => {
                setFsPositiveForListD(res.data)
            })

        await fetch(`${Hemato_cytology.getSampleData}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`
            },
            body: JSON.stringify({ Testtype: 5 })
        })
            .then(res => res.json())
            .then((res) => {
                setFacelSampleListD(res.data)
            })

        await fetch(`${Hemato_cytology.getSampleData}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`
            },
            body: JSON.stringify({ Testtype: 12 })
        })
            .then(res => res.json())
            .then((res) => {
                // setPosNegD(res.data)
                const abc = [
                    { id: "1", sampletype: "+" },
                    { id: "2", sampletype: "++" },
                    { id: "3", sampletype: "+++" },
                    { id: "4", sampletype: "++++" },
                ]
                setPosNegD(abc)
                console.log(res.data);
            })

        await fetch(`${Hemato_cytology.getSampleData}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`
            },
            body: JSON.stringify({ Testtype: 13 })
        })
            .then(res => res.json())
            .then((res) => {
                setHemotoList(res.data)
            })

        await fetch(`${Hemato_cytology.getSampleData}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`
            },
            body: JSON.stringify({ Testtype: 14 })
        })
            .then(res => res.json())
            .then((res) => {
                setBioChemistryList(res.data)
            })
    };



    const getDataById = async () => {
        setIsLoading(true)
        await fetch(`${Cattleregistration.TreeByArea}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`
            },
            body: JSON.stringify({
                Herdcode: "1", Herdtype: "Animal", userCode: ""
            })
        })
            .then(response => {
                const contentLength = response.headers.get('content-length');
                let loaded = 0;
                return new Response(
                    new ReadableStream({
                        start(controller) {
                            const reader = response.body.getReader();
                            read();
                            function read() {
                                reader.read()
                                    .then((progressEvent) => {
                                        if (progressEvent.done) {
                                            controller.close();
                                            return;
                                        }
                                        loaded += progressEvent.value.byteLength;
                                        const percentageComplete = Math.round(loaded / contentLength * 100) + '%';
                                        setProgressData(percentageComplete)
                                        controller.enqueue(progressEvent.value);
                                        read();
                                    })
                            }
                        }
                    })
                );
            })
            .then(res => res.json())
            .then((res) => {
                console.log(res)
                const firstTenItems = res.slice(0, 50);
                setTagIdList(firstTenItems);; setIsLoading(false); setProgressData("0%")
            })
    }

    const view = async () => {
        await fetch(`${Hemato_cytology.distinctDateApi}/${searchid.value}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`
            },
        })
            .then(response => {
                const contentLength = response.headers.get('content-length');
                let loaded = 0;
                return new Response(
                    new ReadableStream({
                        start(controller) {
                            const reader = response.body.getReader();
                            read();
                            function read() {
                                reader.read()
                                    .then((progressEvent) => {
                                        if (progressEvent.done) {
                                            controller.close();
                                            return;
                                        }
                                        loaded += progressEvent.value.byteLength;
                                        const percentageComplete = Math.round(loaded / contentLength * 100) + '%';
                                        setProgressData(percentageComplete)
                                        controller.enqueue(progressEvent.value);
                                        read();
                                    })
                            }
                        }
                    })
                );
            })
            .then(res => res.json())
            .then((res) => {
                setDistintDate(res.data)
                setIsLoading(false);
                setProgressData("0%")
            })
    }


    useEffect(() => {
        DropdownOptionsHcs()
        getDataById()
        const ketone1 = [
            { id: "+", sampletype: "+" },
            { id: "++", sampletype: "++" },
            { id: "+++", sampletype: "+++" },
            { id: "++++", sampletype: "++++" },
            { id: "Absent", sampletype: "Absent" },
        ]
        setKetoneList(ketone1);
    }, [])

    const dateFormate = moment(date).format("MM/DD/YYYY")

    const dateDataFn = (e) => {
        // console.log(e.RegistrationDate)
        const regdate = moment(e.RegistrationDate).format("DD/MM/YYYY")
        fetch(`${Hemato_cytology.showHematoData}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`
            },
            body: JSON.stringify({
                Id: searchid.value,
                Testing: null,
                Date: regdate,
            })
        })
            .then(response => {
                const contentLength = response.headers.get('content-length');
                let loaded = 0;
                return new Response(
                    new ReadableStream({
                        start(controller) {
                            const reader = response.body.getReader();
                            read();
                            function read() {
                                reader.read()
                                    .then((progressEvent) => {
                                        if (progressEvent.done) {
                                            controller.close();
                                            return;
                                        }
                                        loaded += progressEvent.value.byteLength;
                                        const percentageComplete = Math.round(loaded / contentLength * 100) + '%';
                                        setProgressData(percentageComplete)
                                        controller.enqueue(progressEvent.value);
                                        read();
                                    })
                            }
                        }
                    })
                );
            })
            .then(res => res.json())
            .then((res) => {
                // setDistintDate(res.data)
                setHematoCytologyList(res.data);
                setId(res.data[0].ID)
                setPatId(res.data[0].PatIdNo)
                setHematoCytology(res.data[0].HeamtoCytology)
                setHcs(res.data[0].HcsQuality)
                setHbGm(res.data[0].Hbgm)
                setPCV(res.data[0].Pcv)
                setTecm(res.data[0].Tec)
                setTlc(res.data[0].Tlc)
                setN(res.data[0].N)
                setNul(res.data[0].Nu1)
                setL(res.data[0].L)
                setLul(res.data[0].Lu1)
                setM(res.data[0].M)
                setMul(res.data[0].Mu1)
                setE(res.data[0].E)
                setEul(res.data[0].Eu1)
                setB(res.data[0].B)
                setBc(res.data[0].Bc)
                setP1pro(res.data[0].P1pro)
                setFibrinogen(res.data[0].Fibrinogen)
                setPpFib(res.data[0].P1pro)
                setSe(res.data[0].SePositiveForInfectionOf)
                setSeCell(res.data[0].SeCellMorphology)
                setPLT(res.data[0].Plt)
                setWBC(res.data[0].Wbc)
                setHCT(res.data[0].Hct)
                setMCV(res.data[0].Mcv)
                setMCH(res.data[0].Mch)
                setMCHC(res.data[0].Mchc)
                setRBC(res.data[0].Rbc)
                setNEUTROPHILS(res.data[0].Neutrophils)
                setLYMPHOCYTES(res.data[0].Lymphocytes)
                setMID(res.data[0].Mid)
                setNEUTROPHILSBAND(res.data[0].NeutrophilsBand)
                setIsLoading(false);
                setProgressData("0%")
            })
        fetch(`${Hemato_cytology.showBiochemistryData}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`
            },
            body: JSON.stringify({
                Id: searchid.value,
                Testing: null,
                Date: regdate,
            })
        })
            .then(response => {
                const contentLength = response.headers.get('content-length');
                let loaded = 0;
                return new Response(
                    new ReadableStream({
                        start(controller) {
                            const reader = response.body.getReader();
                            read();
                            function read() {
                                reader.read()
                                    .then((progressEvent) => {
                                        if (progressEvent.done) {
                                            controller.close();
                                            return;
                                        }
                                        loaded += progressEvent.value.byteLength;
                                        const percentageComplete = Math.round(loaded / contentLength * 100) + '%';
                                        setProgressData(percentageComplete)
                                        controller.enqueue(progressEvent.value);
                                        read();
                                    })
                            }
                        }
                    })
                );
            })
            .then(res => res.json())
            .then((res) => {
                setBiochemistryId(res.data[0].ID)
                setBioPatId(res.data[0].PatIdNo)
                setBiochemistry(res.data[0].Biochemistry)
                setBioBcs(res.data[0].Bcsquality)
                setProG(res.data[0].Prog)
                setCholM(res.data[0].Cholm)
                setCamg(res.data[0].Camg)
                setPMg(res.data[0].Pmg)
                setAlbG(res.data[0].Albg)
                setAsl(res.data[0].AsliUl)
                setMg(res.data[0].Mgmg)
                setFlgG(res.data[0].Figg)
                setSdh(res.data[0].SDHiul)
                setNa(res.data[0].Nameql)
                setAlbGlo(res.data[0].Albglo)
                setGgt(res.data[0].GGTiul)
                setK(res.data[0].KmEql)
                setBioPpFib(res.data[0].Ppfib)
                setAkp(res.data[0].AKPiul)
                setC1(res.data[0].C1mEql)
                setBun(res.data[0].BUNmg)
                setTBill(res.data[0].TBillmg)
                setGlu(res.data[0].Glumg)
                setCr(res.data[0].Crmg)
                setDBill(res.data[0].DBilmg)
                setRandum(res.data[0].RandumRPT)
                setSECellMorphology(res.data[0].SECellMorphology)
                setCal(res.data[0].Cal)
                setPhos(res.data[0].Phos)
                setBioRbpt(res.data[0].bioRbpt)
                setTBTEST(res.data[0].TbTest)
                setJONHSTESTPPD(res.data[0].JonhsTestTppd)
                setIsLoading(false);
                setProgressData("0%")
            })
        fetch(`${Hemato_cytology.showGIParasitismData}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`
            },
            body: JSON.stringify({
                Id: searchid.value,
                Testing: null,
                Date: regdate,
            })
        })
            .then(response => {
                const contentLength = response.headers.get('content-length');
                let loaded = 0;
                return new Response(
                    new ReadableStream({
                        start(controller) {
                            const reader = response.body.getReader();
                            read();
                            function read() {
                                reader.read()
                                    .then((progressEvent) => {
                                        if (progressEvent.done) {
                                            controller.close();
                                            return;
                                        }
                                        loaded += progressEvent.value.byteLength;
                                        const percentageComplete = Math.round(loaded / contentLength * 100) + '%';
                                        setProgressData(percentageComplete)
                                        controller.enqueue(progressEvent.value);
                                        read();
                                    })
                            }
                        }
                    })
                );
            })
            .then(res => res.json())
            .then((res) => {
                setHematoCytologyList(res.data);
                setGlId(res.data[0].ID)
                setGlPatId(res.data[0].PatIdNo)
                setGlParatism(res.data[0].GIParasitism)
                setFsPositiveFor(res.data[0].FSPositiveFor)
                setFacelSample(res.data[0].FecalSampleExa)
                setTramatodes(res.data[0].Trematodes)
                setCystodes(res.data[0].Cestodes)
                setNematodes(res.data[0].Nematodes)
                setEpgGt(res.data[0].EPGGT)
                setIsLoading(false);
                setProgressData("0%")
            })
        fetch(`${Hemato_cytology.showUrinalysisData}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`
            },
            body: JSON.stringify({
                Id: searchid.value,
                Testing: null,
                Date: regdate,
            })
        })
            .then(response => {
                const contentLength = response.headers.get('content-length');
                let loaded = 0;
                return new Response(
                    new ReadableStream({
                        start(controller) {
                            const reader = response.body.getReader();
                            read();
                            function read() {
                                reader.read()
                                    .then((progressEvent) => {
                                        if (progressEvent.done) {
                                            controller.close();
                                            return;
                                        }
                                        loaded += progressEvent.value.byteLength;
                                        const percentageComplete = Math.round(loaded / contentLength * 100) + '%';
                                        setProgressData(percentageComplete)
                                        controller.enqueue(progressEvent.value);
                                        read();
                                    })
                            }
                        }
                    })
                );
            })
            .then(res => res.json())
            .then((res) => {
                setUrinalysisId(res.data[0].ID)
                setUrinalysisPatId(res.data[0].PatIdNo)
                setUrinalysis(res.data[0].Urinalysis)
                setGlucose(res.data[0].Glucose)
                setKetan(res.data[0].Ketan)
                setSpGravity(res.data[0].SpGravity)
                setBlood(res.data[0].Blood)
                setPh(res.data[0].pH)
                setBilirubin(res.data[0].Bilirubin)
                setUsExam(res.data[0].UsExam)
                setProtien(res.data[0].Protein)
                setUrobillinogen(res.data[0].Urobilinogen)
                setNitrite(res.data[0].Nitrite)
                setLeukocytes(res.data[0].Leukocytes)
                setIsLoading(false);
                setProgressData("0%")
            })
        fetch(`${Hemato_cytology.showImmunologyData}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`
            },
            body: JSON.stringify({
                Id: searchid.value,
                Testing: null,
                Date: regdate,
            })
        })
            .then(response => {
                const contentLength = response.headers.get('content-length');
                let loaded = 0;
                return new Response(
                    new ReadableStream({
                        start(controller) {
                            const reader = response.body.getReader();
                            read();
                            function read() {
                                reader.read()
                                    .then((progressEvent) => {
                                        if (progressEvent.done) {
                                            controller.close();
                                            return;
                                        }
                                        loaded += progressEvent.value.byteLength;
                                        const percentageComplete = Math.round(loaded / contentLength * 100) + '%';
                                        setProgressData(percentageComplete)
                                        controller.enqueue(progressEvent.value);
                                        read();
                                    })
                            }
                        }
                    })
                );
            })
            .then(res => res.json())
            .then((res) => {
                setImmunologyId(res.data[0].ID)
                setImmunologyPatId(res.data[0].PatIdNo)
                setImmunolTest(res.data[0].Immuno1TestForDisease)
                setBrMrt(res.data[0].BrMRT)
                setBrElisa(res.data[0].BrELISA)
                setRbpt(res.data[0].RBPT)
                setStat(res.data[0].STAT)
                setJidElisa(res.data[0].JIDELISA)
                setIdTuberuline(res.data[0].IDTuberuline)
                setIdJohnine(res.data[0].IDJohnie)
                setIbrFat(res.data[0].IBRFAt)
                setIrbElisa(res.data[0].IRBELISA)
                setIsLoading(false);
                setProgressData("0%")
            })
        fetch(`${Hemato_cytology.showSCMData}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`
            },
            body: JSON.stringify({
                Id: searchid.value,
                Testing: null,
                Date: regdate,
            })
        })
            .then(response => {
                const contentLength = response.headers.get('content-length');
                let loaded = 0;
                return new Response(
                    new ReadableStream({
                        start(controller) {
                            const reader = response.body.getReader();
                            read();
                            function read() {
                                reader.read()
                                    .then((progressEvent) => {
                                        if (progressEvent.done) {
                                            controller.close();
                                            return;
                                        }
                                        loaded += progressEvent.value.byteLength;
                                        const percentageComplete = Math.round(loaded / contentLength * 100) + '%';
                                        setProgressData(percentageComplete)
                                        controller.enqueue(progressEvent.value);
                                        read();
                                    })
                            }
                        }
                    })
                );
            })
            .then(res => res.json())
            .then((res) => {
                setScmId(res.data[0].ID)
                setScmPatIdNo(res.data[0].PatIdNo)
                setScmSampleType(res.data[0].SCMSampleType)
                setMastrip(res.data[0].Mastrip)
                setCmt(res.data[0].CMT)
                setEc(res.data[0].EC)
                setSscTh(res.data[0].SSCThml)
                setIsLoading(false);
                setProgressData("0%")
            })
        fetch(`${Hemato_cytology.showAntigramData}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`
            },
            body: JSON.stringify({
                Id: searchid.value,
                Testing: null,
                Date: regdate,
            })
        })
            .then(response => {
                const contentLength = response.headers.get('content-length');
                let loaded = 0;
                return new Response(
                    new ReadableStream({
                        start(controller) {
                            const reader = response.body.getReader();
                            read();
                            function read() {
                                reader.read()
                                    .then((progressEvent) => {
                                        if (progressEvent.done) {
                                            controller.close();
                                            return;
                                        }
                                        loaded += progressEvent.value.byteLength;
                                        const percentageComplete = Math.round(loaded / contentLength * 100) + '%';
                                        setProgressData(percentageComplete)
                                        controller.enqueue(progressEvent.value);
                                        read();
                                    })
                            }
                        }
                    })
                );
            })
            .then(res => res.json())
            .then((res) => {
                setAntigramId(res.data[0].ID)
                setAntigramPatId(res.data[0].PatIdNo)
                setAbsSample(res.data[0].ABSSample)
                setAbsSampleQuality(res.data[0].ABSSampleQuality)
                setPathogenId(res.data[0].PathogenId)
                setAntibiotics(res.data[0].Antibiotics)
                setBkMethod(res.data[0].BkDDMethod)
                setIsLoading(false);
                setProgressData("0%")
            })
        fetch(`${Hemato_cytology.showExecuteSpData}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`
            },
            body: JSON.stringify({
                Id: searchid.value,
                Testing: null,
                Date: regdate,
            })
        })
            .then(response => {
                const contentLength = response.headers.get('content-length');
                let loaded = 0;
                return new Response(
                    new ReadableStream({
                        start(controller) {
                            const reader = response.body.getReader();
                            read();
                            function read() {
                                reader.read()
                                    .then((progressEvent) => {
                                        if (progressEvent.done) {
                                            controller.close();
                                            return;
                                        }
                                        loaded += progressEvent.value.byteLength;
                                        const percentageComplete = Math.round(loaded / contentLength * 100) + '%';
                                        setProgressData(percentageComplete)
                                        controller.enqueue(progressEvent.value);
                                        read();
                                    })
                            }
                        }
                    })
                );
            })
            .then(res => res.json())
            .then((res) => {
                setSpId(res.data[0].ID)
                setSpPatIdNo(res.data[0].PatIdNo)
                setSpecialPath(res.data[0].SpecialPath)
                setSps2(res.data[0].SPS2)
                setSps3(res.data[0].SPS3)
                setSps4(res.data[0].SPS4)
                setSps5(res.data[0].SPS4)
                setSps5(res.data[0].SPS5)
                setSps6(res.data[0].SPS6)
                setSpResu(res.data[0].SPResu)
                setSpr2(res.data[0].SPR2)
                setSpr3(res.data[0].SPR3)
                setSpr4(res.data[0].SPR4)
                setSpr5(res.data[0].SPR5)
                setSpr6(res.data[0].SPR6)
                setIsLoading(false);
                setProgressData("0%")
            })
        fetch(`${Hemato_cytology.showToxicologyData}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`
            },
            body: JSON.stringify({
                Id: searchid.value,
                Testing: null,
                Date: regdate,
            })
        })
            .then(response => {
                const contentLength = response.headers.get('content-length');
                let loaded = 0;
                return new Response(
                    new ReadableStream({
                        start(controller) {
                            const reader = response.body.getReader();
                            read();
                            function read() {
                                reader.read()
                                    .then((progressEvent) => {
                                        if (progressEvent.done) {
                                            controller.close();
                                            return;
                                        }
                                        loaded += progressEvent.value.byteLength;
                                        const percentageComplete = Math.round(loaded / contentLength * 100) + '%';
                                        setProgressData(percentageComplete)
                                        controller.enqueue(progressEvent.value);
                                        read();
                                    })
                            }
                        }
                    })
                );
            })
            .then(res => res.json())
            .then((res) => {
                setToxicologyId(res.data[0].ID)
                setToxicologyPatientId(res.data[0].PatIdNo)
                setTxSample1(res.data[0].TxSample1)
                setTxSample2(res.data[0].TxSample2)
                setTxTest1(res.data[0].TxTest1)
                setTxTest2(res.data[0].TxTest2)
                setTxResult1(res.data[0].TxResult1)
                setTxResult2(res.data[0].TxResult2)
                setIsLoading(false);
                setProgressData("0%")
            })
        fetch(`${Hemato_cytology.showExecuteMilkChemistryData}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`
            },
            body: JSON.stringify({
                Id: searchid.value,
                Testing: null,
                Date: regdate,
            })
        })
            .then(response => {
                const contentLength = response.headers.get('content-length');
                let loaded = 0;
                return new Response(
                    new ReadableStream({
                        start(controller) {
                            const reader = response.body.getReader();
                            read();
                            function read() {
                                reader.read()
                                    .then((progressEvent) => {
                                        if (progressEvent.done) {
                                            controller.close();
                                            return;
                                        }
                                        loaded += progressEvent.value.byteLength;
                                        const percentageComplete = Math.round(loaded / contentLength * 100) + '%';
                                        setProgressData(percentageComplete)
                                        controller.enqueue(progressEvent.value);
                                        read();
                                    })
                            }
                        }
                    })
                );
            })
            .then(res => res.json())
            .then((res) => {
                setMilkId(res.data[0].ID)
                setMilkPatientId(res.data[0].PatIdNo)
                setMilkSampleType(res.data[0].MilkSampleType)
                setFat(res.data[0].Fat)
                setSnf(res.data[0].SNF)
                setTs(res.data[0].TS)
                setSampleCondition(res.data[0].SampleCondition)
                setDensity(res.data[0].Density)
                setMSpGravity(res.data[0].MSpGravity)
                setMilkProtein(res.data[0].Protein)
                setFpc(res.data[0].Fpc)
                setEcMsCm(res.data[0].Ecmscm)
                setMph(res.data[0].MPh)
                setTemperatureC(res.data[0].TemperatureC)
                setLactose(res.data[0].Lactose)
                setMun(res.data[0].Munmg)
                setMCitrate(res.data[0].MCitratemg)
                setIsLoading(false);
                setProgressData("0%")
            })
        fetch(`${Hemato_cytology.showExecuteModTechData}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`
            },
            body: JSON.stringify({
                Id: searchid.value,
                Testing: null,
                Date: regdate,
            })
        })
            .then(response => {
                const contentLength = response.headers.get('content-length');
                let loaded = 0;
                return new Response(
                    new ReadableStream({
                        start(controller) {
                            const reader = response.body.getReader();
                            read();
                            function read() {
                                reader.read()
                                    .then((progressEvent) => {
                                        if (progressEvent.done) {
                                            controller.close();
                                            return;
                                        }
                                        loaded += progressEvent.value.byteLength;
                                        const percentageComplete = Math.round(loaded / contentLength * 100) + '%';
                                        setProgressData(percentageComplete)
                                        controller.enqueue(progressEvent.value);
                                        read();
                                    })
                            }
                        }
                    })
                );
            })
            .then(res => res.json())
            .then((res) => {
                setModTechId(res.data[0].ID)
                setModPatientId(res.data[0].PatIdNo)
                setModTechniques(res.data[0].ModTechniques)
                setMtResult(res.data[0].MtResult)
                setIsLoading(false);
                setProgressData("0%")
            })
        fetch(`${Hemato_cytology.showExecuteRemarksData}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`
            },
            body: JSON.stringify({
                Id: searchid.value,
                Testing: null,
                Date: regdate,
            })
        })
            .then(response => {
                const contentLength = response.headers.get('content-length');
                let loaded = 0;
                return new Response(
                    new ReadableStream({
                        start(controller) {
                            const reader = response.body.getReader();
                            read();
                            function read() {
                                reader.read()
                                    .then((progressEvent) => {
                                        if (progressEvent.done) {
                                            controller.close();
                                            return;
                                        }
                                        loaded += progressEvent.value.byteLength;
                                        const percentageComplete = Math.round(loaded / contentLength * 100) + '%';
                                        setProgressData(percentageComplete)
                                        controller.enqueue(progressEvent.value);
                                        read();
                                    })
                            }
                        }
                    })
                );
            })
            .then(res => res.json())
            .then((res) => {
                setRemarkId(res.data[0].ID)
                setRemarkPatientId(res.data[0].PatIdNo)
                setRemarkTechniques(res.data[0].Remarks)
                setIsLoading(false);
                setProgressData("0%")
            })
    }


    const colum = [
        {
            name: "Date",
            cell: (e) => (<div onClick={() => { dateDataFn(e); allFieldsOpen(); }}>{moment(e.RegistrationDate).format('DD-MM-YYYY')}</div>),
        },
    ]

    const printReport = () => {

    }

    const allFieldsOpen = () => {
        allCheck2(true);
        allCheck1(true);
        allCheck3(true);
    }

    const handleInputChange = (e) => {
        const inputValue = e.target.value;

        if (/^[0-9]*$/.test(inputValue)) {
            setSpGravity(inputValue);
        }
    };

    return (
        <>
            <Container fluid>
                <Row>
                    {headerShow && (<Col lg="2" className="hideNavbar"> <Sidenav /></Col>)}
                    <Col className="header" lg={headerShow ? "10" : "12"}>
                        <Container fluid>
                            <Row className="pt-1 sub-header align-items-center justify-content-between">
                                <Col md="11" sm="10" xs="10" className="py-2 d-flex align-items-center">
                                    <button style={{ border: "none" }} className="hideNavbar1" onClick={handleShow}><img src={menu} width={20} /></button>
                                    <button style={{ border: "none" }} className="hide_Navbar" onClick={() => setShow(true)}><img src={menu} width={20} /></button>
                                    <h5 className="ms-4 mt-2">Lab Management / Test Request Master</h5>
                                </Col>
                                <Col md="1" sm="1" xs="2" className="text-end profile_dropdown">
                                    <img src={profile} width={40} />
                                    <div className="dropdpwn_Open">
                                        <Row className="text-center align-items-center">
                                            <Col xs="12" className="menuHover mb-3">
                                                <Row >
                                                    <Col className="" xs="3">
                                                        <span className=""><MdOutlineVpnKey size={25} />
                                                        </span>
                                                    </Col>
                                                    <Col className="text-start" xs="9">
                                                        Change Password
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col xs="12" className="menuHover" onClick={logOut}>
                                                <Row>
                                                    <Col className="" xs="3">
                                                        <span className=""><GoSignOut size={25} />
                                                        </span>
                                                    </Col>
                                                    <Col className="text-start" xs="9">
                                                        Sign Out
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="align-items-end justify-content-start mt-1">
                                <Col xs="4" sm="2">
                                    {/* <TextField label="ID" id="standard-basic" value={searchid} onChange={(e) => { setSearchId(e.target.value); }} fullWidth variant="standard" /> */}

                                    <FormControl variant="standard" fullWidth>
                                        <Searcable
                                            placeholder="Animal Tag Id"
                                            options={tagIdList}
                                            onChange={(selectedOption) => setSearchId(selectedOption)}
                                            value={searchid}
                                            labelKey="Animal"
                                            valueKey="Animal"
                                        />
                                    </FormControl>
                                </Col>
                                {/* <Col xs="4" sm="2">
                                    <TextField label="Testing" id="standard-basic" value={testing} onChange={(e) => { setTesting(e.target.value); }} fullWidth variant="standard" />
                                </Col> */}
                                <Col xs="4" sm="2">
                                    <sub className="mb-0 pb-0">Date</sub>
                                    <input type="date" className="dateSelect_bg" value={date} onChange={(e) => { setDate(e.target.value) }} />
                                </Col>
                                {/* <Col xs="4" sm="2" md="2" xl="1" lg="2" className="mt-2">
                                    <button className="btn-blue-Animal mt-1" onClick={Add}>Add New</button>
                                </Col> */}
                                <Col xs="4" sm="2" md="2" xl="1" lg="2" className="mt-2">
                                    <button className="btn-blue-Animal mt-1" onClick={view}>Testing View</button>
                                </Col>
                                <Col xs="4" sm="2" className='ms-auto' >
                                    <Input label="Search" value={search} onChange={e => SearchFn(e)} />
                                </Col>
                            </Row>
                            <Row className='mt-2'>
                                <Col xs="4" sm="4" md="3" lg="2" xl="2">
                                    <div className='leftPannel'>
                                        {/* <Row className='align-items-center justify-content-center'>
                                            <Col xs="10" lg="10" md="10" sm="10" xl="10">
                                                <TextField label="ID" id="standard-basic" value={searchid} onChange={(e) => { setSearchId(e.target.value); }} fullWidth variant="standard" />
                                            </Col>
                                        </Row>
                                        <Row className='align-items-center justify-content-center'>
                                            <Col xs="10" sm="10" md="10" xl="10" >
                                                <TextField label="Testing" id="standard-basic" value={testing} onChange={(e) => { setTesting(e.target.value); }} fullWidth variant="standard" />
                                            </Col>
                                        </Row>
                                        <Row className='align-items-center justify-content-center'>
                                            <Col xs="10" sm="10" md="10" xl="10">
                                                <sub className="mb-0 pb-0">Date</sub>
                                                <input type="date" className="dateSelect_bg" value={date} onChange={(e) => { setDate(e.target.value) }} />
                                            </Col>
                                        </Row>
                                        <Row className='mt-3 align-items-center justify-content-center'>
                                            <Col xs="12" sm="10" lg="2" md="4" xl="4" className="mt-2">
                                                <button className="btn-blue-Animal mt-1" onClick={Add}>Add New</button>
                                            </Col>
                                            <Col xs="12" sm="10" lg="2" md="4" xl="4" className="mt-2">
                                                <button className="btn-blue-Animal mt-1" onClick={view}>View</button>
                                            </Col>
                                        </Row> */}

                                        <Row className='align-items-center justify-content-center text-center'>
                                            <Col className='mt-2'>All Dates Of Testing</Col>
                                        </Row>
                                        <hr></hr>
                                        <Col className=''>
                                            <Table columns={colum} data={distintDate} />
                                        </Col>
                                    </div>
                                </Col>
                                <Col xs="8" sm="8" md="9" lg="10" xl="10">
                                    <div className='leftPannel'>
                                        <Row className='mainTabPanel p-4 pt-2'>
                                            <Col xs="12 " sm="4" lg="2" xl="2">
                                                <button className={`${index == 1 ? `${clrChange == true ? `clrchange` : `activeLabtab`} mt-1 mb-1` : ` ${clrChange == true ? `clrchange` : `btn-blue-Animal`} mt-1 mb-1`}`} onClick={() => setIndex(1)}>Heamto-Cytology</button>
                                            </Col>
                                            <Col xs="6" sm="4" lg="2" xl="2">
                                                <button className={`${index == 2 ? `${clrChange2 == true ? `clrchange` : `activeLabtab`} mt-1 mb-1` : ` ${clrChange2 == true ? `clrchange` : `btn-blue-Animal`} mt-1 mb-1`}`} onClick={() => setIndex(2)}>Biochemistry</button>
                                            </Col>
                                            <Col xs="6" sm="4" lg="2" xl="2">
                                                <button className={`${index == 3 ? `${clrChange3 == true ? `clrchange` : `activeLabtab`} mt-1 mb-1` : ` ${clrChange3 == true ? `clrchange` : `btn-blue-Animal`} mt-1 mb-1`}`} onClick={() => setIndex(3)}>GI Parasitism</button>
                                            </Col>
                                            <Col xs="6" sm="4" lg="2" xl="2">
                                                <button className={`${index == 4 ? `${clrChange4 == true ? `clrchange` : `activeLabtab`} mt-1 mb-1` : ` ${clrChange4 == true ? `clrchange` : `btn-blue-Animal`} mt-1 mb-1`}`} onClick={() => setIndex(4)}>Urinalysis</button>
                                            </Col>
                                            <Col xs="6" sm="4" lg="2" xl="2">
                                                <button className={`${index == 5 ? `${clrChange5 == true ? `clrchange` : `activeLabtab`} mt-1 mb-1` : ` ${clrChange5 == true ? `clrchange` : `btn-blue-Animal`} mt-1 mb-1`}`} onClick={() => setIndex(5)}>Immunology</button>
                                            </Col>
                                            <Col xs="6" sm="4" lg="2" xl="2">
                                                <button className={`${index == 6 ? `${clrChange6 == true ? `clrchange` : `activeLabtab`} mt-1 mb-1` : ` ${clrChange6 == true ? `clrchange` : `btn-blue-Animal`} mt-1 mb-1`}`} onClick={() => setIndex(6)}>SCM</button>
                                            </Col>
                                            <Col xs="6" sm="4" lg="2" xl="2">
                                                <button className={`${index == 7 ? `${clrChange7 == true ? `clrchange` : `activeLabtab`} mt-1 mb-1` : ` ${clrChange7 == true ? `clrchange` : `btn-blue-Animal`} mt-1 mb-1`}`} onClick={() => setIndex(7)}>Antigram</button>
                                            </Col>
                                            <Col xs="6" sm="4" lg="2" xl="2">
                                                <button className={`${index == 8 ? `${clrChange8 == true ? `clrchange` : `activeLabtab`} mt-1 mb-1` : ` ${clrChange8 == true ? `clrchange` : `btn-blue-Animal`} mt-1 mb-1`}`} onClick={() => setIndex(8)}>SP</button>
                                            </Col>
                                            <Col xs="6" sm="4" lg="2" xl="2">
                                                <button className={`${index == 9 ? `${clrChange9 == true ? `clrchange` : `activeLabtab`} mt-1 mb-1` : ` ${clrChange9 == true ? `clrchange` : `btn-blue-Animal`} mt-1 mb-1`}`} onClick={() => setIndex(9)}>Toxicology</button>
                                            </Col>
                                            <Col xs="6" sm="4" lg="2" xl="2">
                                                <button className={`${index == 10 ? `${clrChange10 == true ? `clrchange` : `activeLabtab`} mt-1 mb-1` : ` ${clrChange10 == true ? `clrchange` : `btn-blue-Animal`} mt-1 mb-1`}`} onClick={() => setIndex(10)}>Milk Chemistry</button>
                                            </Col>
                                            <Col xs="6" sm="4" lg="2" xl="2">
                                                <button className={`${index == 11 ? `${clrChange11 == true ? `clrchange` : `activeLabtab`} mt-1 mb-1` : ` ${clrChange11 == true ? `clrchange` : `btn-blue-Animal`} mt-1 mb-1`}`} onClick={() => setIndex(11)}>Mod Tech</button>
                                            </Col>
                                            <Col xs="6" sm="4" lg="2" xl="2">
                                                <button className={`${index == 12 ? `${clrChange12 == true ? `clrchange` : `activeLabtab`} mt-1 mb-1` : ` ${clrChange12 == true ? `clrchange` : `btn-blue-Animal`} mt-1 mb-1`}`} onClick={() => setIndex(12)}>Remarks</button>
                                            </Col>
                                        </Row>
                                        {
                                            index === 1 &&
                                            <Container>
                                                <Row className='mt-4 p-3 align-items-end'>
                                                    {/* <Col sm="4">
                                                        <TextField label="ID" id="standard-basic" value={id} onChange={(e) => { handleInput(e, "id") }} fullWidth variant="standard" />
                                                    </Col>
                                                    <Col sm="4">
                                                        <TextField label="Pat ID No" id="standard-basic" value={patId} onChange={(e) => { handleInput(e, "patId") }} fullWidth variant="standard" />
                                                    </Col> */}
                                                    {/* <Col sm="4" className='pt-2'>
                                                        <FormControl variant="standard" fullWidth>
                                                            <Searcable
                                                                placeholder="Heamto-Cytology"
                                                                options={hemotoList}
                                                                onChange={(selectedOption) => setHematoCytology(selectedOption)}
                                                                value={hematoCytology}
                                                                labelKey="sampletype"
                                                                valueKey="ID"
                                                            />
                                                        </FormControl>
                                                    </Col>
                                                    <Col sm="4" className='pt-2'>
                                                        <FormControl variant="standard" fullWidth>
                                                            <Searcable
                                                                placeholder="HCS-Quality"
                                                                options={HcsQualityD}
                                                                onChange={(selectedOption) => setHcs(selectedOption)}
                                                                value={hcs}
                                                                labelKey="sampletype"
                                                                valueKey="id"
                                                            />
                                                        </FormControl>
                                                    </Col> */}

                                                    <Col xxl="12" xl="12" lg="12" md="12" sm="12" xs="12" >
                                                        <input type='checkbox' value={allCheck} onClick={(e) => { allCheck1(e.target.checked); setAllCheck(e.target.checked) }} /> All
                                                        <Row>
                                                            <Col xxl="4" xl="2" lg="2" md="2" sm="2" xs="2">
                                                                <input type='checkbox' checked={hbGmchecked} onChange={(e) => { setHbGmchecked(e.target.checked) }} /> Haemoglobin
                                                            </Col>
                                                            <Col xxl="4" xl="2" lg="2" md="2" sm="2" xs="2">
                                                                <input type='checkbox' checked={PCVchecked} onChange={(e) => { setPCVchecked(e.target.checked) }} /> Packed Cell Volume
                                                            </Col>
                                                            <Col xxl="4" xl="2" lg="2" md="2" sm="2" xs="2">
                                                                <input type='checkbox' checked={PLTchecked} onChange={(e) => { setPLTchecked(e.target.checked) }} /> Platelet Cell count
                                                            </Col>
                                                            <Col xxl="4" xl="2" lg="2" md="2" sm="2" xs="2">
                                                                <input type='checkbox' checked={RBCchecked} onChange={(e) => { setRBCchecked(e.target.checked) }} /> Erythrocyte (RBC) Cell count
                                                            </Col>
                                                            <Col xxl="4" xl="2" lg="2" md="2" sm="2" xs="2">
                                                                <input type='checkbox' checked={WBCchecked} onChange={(e) => { setWBCchecked(e.target.checked) }} /> Leucocyte (WBC) Call Count
                                                            </Col>
                                                            <Col xxl="4" xl="2" lg="2" md="2" sm="2" xs="2">
                                                                <input type='checkbox' checked={HCTchecked} onChange={(e) => { setHCTchecked(e.target.checked) }} /> Haemoglobin cyanmethemoglobin test g %
                                                            </Col>
                                                            <Col xxl="4" xl="2" lg="2" md="2" sm="2" xs="2">
                                                                <input type='checkbox' checked={MCVchecked} onChange={(e) => { setMCVchecked(e.target.checked) }} /> MCV
                                                            </Col>
                                                            <Col xxl="4" xl="2" lg="2" md="2" sm="2" xs="2">
                                                                <input type='checkbox' checked={MCHchecked} onChange={(e) => { setMCHchecked(e.target.checked) }} /> MCH
                                                            </Col>
                                                            <Col xxl="4" xl="2" lg="2" md="2" sm="2" xs="2">
                                                                <input type='checkbox' checked={MCHCchecked} onChange={(e) => { setMCHCchecked(e.target.checked) }} /> MCHC
                                                            </Col>
                                                            <Col xxl="4" xl="2" lg="2" md="2" sm="2" xs="2">
                                                                <input type='checkbox' checked={NEUTROPHILSchecked} onChange={(e) => { setNEUTROPHILSchecked(e.target.checked) }} /> NEUTROPHILS
                                                            </Col>
                                                            <Col xxl="4" xl="2" lg="2" md="2" sm="2" xs="2">
                                                                <input type='checkbox' checked={LYMPHOCYTESchecked} onChange={(e) => { setLYMPHOCYTESchecked(e.target.checked) }} /> LYMPHOCYTES
                                                            </Col>
                                                            <Col xxl="4" xl="2" lg="2" md="2" sm="2" xs="2">
                                                                <input type='checkbox' checked={MIDchecked} onChange={(e) => { setMIDchecked(e.target.checked) }} /> MID
                                                            </Col>
                                                            <Col xxl="4" xl="2" lg="2" md="2" sm="2" xs="2">
                                                                <input type='checkbox' checked={NEUTROPHILSBANDchecked} onChange={(e) => { setNEUTROPHILSBANDchecked(e.target.checked) }} />NEUTROPHILS BAND
                                                            </Col>
                                                            <Col xxl="4" xl="2" lg="2" md="2" sm="2" xs="2">
                                                                <input type='checkbox' checked={HemotoOthersChecked} onChange={(e) => { setHemotoOthersChecked(e.target.checked) }} />Others
                                                            </Col>
                                                        </Row>
                                                    </Col>

                                                    {
                                                        hbGmchecked && (
                                                            <Col sm="4">
                                                                <TextField label="HB gm%" id="standard-basic" value={hbGm} onChange={(e) => { handleInput(e, "HB gm%"); }} fullWidth variant="standard" />
                                                            </Col>
                                                        )
                                                    }
                                                    {
                                                        PCVchecked && (
                                                            <Col sm="4">
                                                                <TextField label="PCV" id="standard-basic" value={PCV} onChange={(e) => { handleInput(e, "PCV"); }} fullWidth variant="standard" />
                                                            </Col>
                                                        )
                                                    }
                                                    {
                                                        PLTchecked && (
                                                            <Col sm="4">
                                                                <TextField label="PLT" id="standard-basic" value={PLT} onChange={(e) => { handleInput(e, "PLT"); }} fullWidth variant="standard" />
                                                            </Col>
                                                        )
                                                    }
                                                    {
                                                        RBCchecked && (
                                                            <Col sm="4">
                                                                <TextField label="RBC" id="standard-basic" value={RBC} onChange={(e) => { handleInput(e, "RBC"); }} fullWidth variant="standard" />
                                                            </Col>
                                                        )
                                                    }
                                                    {
                                                        WBCchecked && (
                                                            <Col sm="4">
                                                                <TextField label="WBC" id="standard-basic" value={WBC} onChange={(e) => { handleInput(e, "WBC"); }} fullWidth variant="standard" />
                                                            </Col>
                                                        )
                                                    }
                                                    {
                                                        HCTchecked && (
                                                            <Col sm="4">
                                                                <TextField label="HCT" id="standard-basic" value={HCT} onChange={(e) => { handleInput(e, "HCT"); }} fullWidth variant="standard" />
                                                            </Col>
                                                        )
                                                    }
                                                    {
                                                        MCVchecked && (
                                                            <Col sm="4">
                                                                <TextField label="MCV" id="standard-basic" value={MCV} onChange={(e) => { handleInput(e, "MCV"); }} fullWidth variant="standard" />
                                                            </Col>
                                                        )
                                                    }
                                                    {
                                                        MCHchecked && (
                                                            <Col sm="4">
                                                                <TextField label="MCH" id="standard-basic" value={MCH} onChange={(e) => { handleInput(e, "MCH"); }} fullWidth variant="standard" />
                                                            </Col>
                                                        )
                                                    }
                                                    {
                                                        MCHCchecked && (
                                                            <Col sm="4">
                                                                <TextField label="MCHC" id="standard-basic" value={MCHC} onChange={(e) => { handleInput(e, "MCHC"); }} fullWidth variant="standard" />
                                                            </Col>
                                                        )
                                                    }
                                                    {
                                                        NEUTROPHILSchecked && (
                                                            <Col sm="4">
                                                                <TextField label="NEUTROPHILS" id="standard-basic" value={NEUTROPHILS} onChange={(e) => { handleInput(e, "NEUTROPHILS"); }} fullWidth variant="standard" />
                                                            </Col>
                                                        )
                                                    }
                                                    {
                                                        LYMPHOCYTESchecked && (
                                                            <Col sm="4">
                                                                <TextField label="LYMPHOCYTES" id="standard-basic" value={LYMPHOCYTES} onChange={(e) => { handleInput(e, "LYMPHOCYTES"); }} fullWidth variant="standard" />
                                                            </Col>
                                                        )
                                                    }
                                                    {
                                                        MIDchecked && (
                                                            <Col sm="4">
                                                                <TextField label="MID" id="standard-basic" value={MID} onChange={(e) => { handleInput(e, "MID"); }} fullWidth variant="standard" />
                                                            </Col>
                                                        )
                                                    }
                                                    {
                                                        NEUTROPHILSBANDchecked && (
                                                            <Col sm="4">
                                                                <TextField label="NEUTROPHILS BAND" id="standard-basic" value={NEUTROPHILSBAND} onChange={(e) => { handleInput(e, "NEUTROPHILS BAND"); }} fullWidth variant="standard" />
                                                            </Col>
                                                        )
                                                    }
                                                    {
                                                        HemotoOthersChecked && (
                                                            <Col sm="4">
                                                                <TextField label="Others" id="standard-basic" value={HemotoOthers} onChange={(e) => { setHemotoOthers(e.target.value); }} fullWidth variant="standard" />
                                                            </Col>
                                                        )
                                                    }


                                                    {/* <Col sm="4">
                                                        <TextField label="TEC M/u1" id="standard-basic" value={tecm} onChange={(e) => { handleInput(e, "tecm"); }} fullWidth variant="standard" />
                                                    </Col>
                                                    <Col sm="4">
                                                        <TextField label="TLC T/u1" id="standard-basic" value={tlc} onChange={(e) => { handleInput(e, "tlc"); }} fullWidth variant="standard" />
                                                    </Col>
                                                    <Col sm="4">
                                                        <TextField label="N%" id="standard-basic" value={n} onChange={(e) => { handleInput(e, "N"); }} fullWidth variant="standard" />
                                                    </Col>
                                                    <Col sm="4">
                                                        <TextField label="N/ul" id="standard-basic" value={nUl} onChange={(e) => { setNul(e.target.value); }} fullWidth variant="standard" />
                                                    </Col>
                                                    <Col sm="4">
                                                        <TextField label="L%" id="standard-basic" value={l} onChange={(e) => { handleInput(e, "L"); }} fullWidth variant="standard" />
                                                    </Col>
                                                    <Col sm="4">
                                                        <TextField label="L/u1" id="standard-basic" value={lUl} onChange={(e) => { setLul(e.target.value); }} fullWidth variant="standard" />
                                                    </Col>
                                                    <Col sm="4">
                                                        <TextField label="M%" id="standard-basic" value={m} onChange={(e) => { handleInput(e, "M"); }} fullWidth variant="standard" />
                                                    </Col>
                                                    <Col sm="4">
                                                        <TextField label="M/u1" id="standard-basic" value={mUl} onChange={(e) => { setMul(e.target.value); }} fullWidth variant="standard" />
                                                    </Col>
                                                    <Col sm="4">
                                                        <TextField label="E%" id="standard-basic" value={e} onChange={(e) => { handleInput(e, "E"); }} fullWidth variant="standard" />
                                                    </Col>
                                                    <Col sm="4">
                                                        <TextField label="E/u1" id="standard-basic" value={eUl} onChange={(e) => { setEul(e.target.value); }} fullWidth variant="standard" />
                                                    </Col>
                                                    <Col sm="4">
                                                        <TextField label="B%" id="standard-basic" value={b} onChange={(e) => { handleInput(e, "B"); }} fullWidth variant="standard" />
                                                    </Col>
                                                    <Col sm="4">
                                                        <TextField label="BC%" id="standard-basic" value={bc} onChange={(e) => { handleInput(e, "Bc"); }} fullWidth variant="standard" />
                                                    </Col>
                                                    <Col sm="4">
                                                        <TextField label="P1 Pro" id="standard-basic" value={p1pro} onChange={(e) => { setP1pro(e.target.value); }} fullWidth variant="standard" />
                                                    </Col>
                                                    <Col sm="4">
                                                        <TextField label="Fibrinogen" id="standard-basic" value={fibrinogen} onChange={(e) => { setFibrinogen(e.target.value); }} fullWidth variant="standard" />
                                                    </Col>
                                                    <Col sm="4">
                                                        <TextField label="PP : Fib" id="standard-basic" value={ppFib} onChange={(e) => { setPpFib(e.target.value); }} fullWidth variant="standard" />
                                                    </Col> */}
                                                    {/* <Col sm="4" className='pt-2'>
                                                        <FormControl variant="standard" fullWidth>
                                                            <Searcable
                                                                placeholder="SE-Positive for Infection of"
                                                                options={SeD}
                                                                onChange={(selectedOption) => setSe(selectedOption)}
                                                                value={se}
                                                                labelKey="sampletype"
                                                                valueKey="id"
                                                            />
                                                        </FormControl>
                                                    </Col> */}
                                                    {/* <Col sm="4" className='pt-2'>                                                     
                                                        <FormControl variant="standard" fullWidth>
                                                            <Searcable
                                                                placeholder="SE-Positive for Infection of"
                                                                options={seCellListD}
                                                                onChange={(selectedOption) => setSeCell(selectedOption)}
                                                                value={seCell}
                                                                labelKey="sampletype"
                                                                valueKey="id"
                                                            />
                                                        </FormControl>
                                                    </Col> */}
                                                </Row>
                                                <Row className="text-center mt-5">
                                                    <Col xs="4" xl="1" className='ms-auto'>
                                                        <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }} onClick={() => { SaveHeamtoCytology(); }}>Save</button>
                                                    </Col>
                                                    <Col xs="4" xl="1" className=''>
                                                        <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }} onClick={printReport}>Print</button>
                                                    </Col>
                                                    <Col xs="4" xl="1" className=''>
                                                        <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }}>Edit</button>
                                                    </Col>
                                                    <Col xs="4" xl="1" className=''>
                                                        <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }}>View</button>
                                                    </Col>
                                                    <Col xs="4" xl="1" className=''>
                                                        <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }}>End</button>
                                                    </Col>
                                                    <Col xs="4" xl="1" className=''>
                                                        <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }} onClick={() => { setIndex(2) }}>Next</button>
                                                    </Col>
                                                    {/* <Col xl="1" className='me-auto'
                                                        <button className="btn-blue-Animal mt-1" onClick={() => setModalShow(false)} style={{ background: "#E16659" }}> Cancel </button>
                                                    </Col> */}
                                                </Row>
                                            </Container>
                                        }
                                        {
                                            index === 2 &&
                                            <Container>
                                                <Row className='mt-4 p-3'>
                                                    {/* <Col sm="4">
                                                        <TextField label="ID" id="standard-basic" value={biochemistryid} onChange={(e) => { handleInputBioChemistry(e, "biochemistryid"); }} fullWidth variant="standard" />
                                                    </Col>
                                                    <Col sm="4">
                                                        <TextField label="Pat ID No" id="standard-basic" value={bioPatId} onChange={(e) => { handleInputBioChemistry(e, "bioPatId"); }} fullWidth variant="standard" />
                                                    </Col> */}
                                                    {/* <Col sm="4" className='p-2'>
                                                        <FormControl variant="standard" fullWidth>
                                                            <Searcable
                                                                placeholder="Biochemistryy"
                                                                options={bioChemistryList}
                                                                onChange={(selectedOption) => setBiochemistry(selectedOption)}
                                                                value={biochemistry}
                                                                labelKey="sampletype"
                                                                valueKey="id"

                                                            />
                                                        </FormControl>
                                                    </Col>
                                                    <Col sm="4">
                                                        <FormControl variant="standard" style={{ border: "1px soild black" }} fullWidth>
                                                            <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>BCS-Quality</InputLabel>
                                                            <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={bioBcs} onChange={(e) => { setBioBcs(e.target.value); }}>

                                                                <MenuItem className="drop_value" value="1" >1</MenuItem>
                                                                <MenuItem className="drop_value" value="2" >2</MenuItem>
                                                                <MenuItem className="drop_value" value="3" >3</MenuItem>
                                                                <MenuItem className="drop_value" value="4" >4</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </Col> */}

                                                    <Col xxl="12" xl="12" lg="12" md="12" sm="12" xs="12" >
                                                        <input type='checkbox' onClick={(e) => { allCheck2(e.target.checked) }} /> All
                                                        <Row>
                                                            <Col xxl="2" xl="2" lg="2" md="2" sm="2" xs="2">
                                                                <input type='checkbox' checked={Calchecked} onChange={(e) => { setCalchecked(e.target.checked) }} /> Serum calcium
                                                            </Col>
                                                            <Col xxl="2" xl="2" lg="2" md="2" sm="2" xs="2">
                                                                <input type='checkbox' checked={Phoschecked} onChange={(e) => { setPhoschecked(e.target.checked) }} /> Serum phosphorus
                                                            </Col>
                                                            <Col xxl="2" xl="2" lg="2" md="2" sm="2" xs="2">
                                                                <input type='checkbox' checked={bioRbptchecked} onChange={(e) => { setBioRbptchecked(e.target.checked) }} /> Brucella RBPT
                                                            </Col>
                                                            <Col xxl="2" xl="2" lg="2" md="2" sm="2" xs="2">
                                                                <input type='checkbox' checked={TBTESTchecked} onChange={(e) => { setTBTESTchecked(e.target.checked) }} /> TBTEST
                                                            </Col>
                                                            <Col xxl="2" xl="2" lg="2" md="2" sm="2" xs="2">
                                                                <input type='checkbox' checked={JONHSTESTPPDchecked} onChange={(e) => { setJONHSTESTPPDchecked(e.target.checked) }} /> JonhsTestTppd
                                                            </Col>
                                                            <Col xxl="2" xl="2" lg="2" md="2" sm="2" xs="2">
                                                                <input type='checkbox' checked={bunchecked} onChange={(e) => { setBunchecked(e.target.checked) }} /> Blood Urea Nitrogen
                                                            </Col>
                                                            <Col xxl="2" xl="2" lg="2" md="2" sm="2" xs="2">
                                                                <input type='checkbox' checked={gluchecked} onChange={(e) => { setGluchecked(e.target.checked) }} /> Serum glucose
                                                            </Col>
                                                            <Col xxl="2" xl="2" lg="2" md="2" sm="2" xs="2">
                                                                <input type='checkbox' checked={BioOthersChecked} onChange={(e) => { setBioOthersChecked(e.target.checked) }} /> Others
                                                            </Col>
                                                        </Row>
                                                    </Col>

                                                    {
                                                        Calchecked && (
                                                            <Col sm="4">
                                                                <TextField label="Cal%" id="standard-basic" value={Cal} onChange={(e) => { handleInputBioChemistry(e, "Cal"); }} fullWidth variant="standard" />
                                                            </Col>
                                                        )
                                                    }
                                                    {
                                                        Phoschecked && (
                                                            <Col sm="4">
                                                                <TextField label="Phos%" id="standard-basic" value={Phos} onChange={(e) => { handleInputBioChemistry(e, "Phos"); }} fullWidth variant="standard" />
                                                            </Col>
                                                        )
                                                    }{
                                                        bioRbptchecked && (
                                                            <Col sm="4">
                                                                <TextField label="RBPT%" id="standard-basic" value={bioRbpt} onChange={(e) => { handleInputBioChemistry(e, "bioRbpt"); }} fullWidth variant="standard" />
                                                            </Col>
                                                        )
                                                    }
                                                    {
                                                        TBTESTchecked && (
                                                            <Col sm="4">
                                                                <TextField label="TBTEST%" id="standard-basic" value={TBTEST} onChange={(e) => { handleInputBioChemistry(e, "TBTEST"); }} fullWidth variant="standard" />
                                                            </Col>
                                                        )
                                                    }
                                                    {
                                                        JONHSTESTPPDchecked && (
                                                            <Col sm="4">
                                                                <TextField label="JONHSTESTPPD%" id="standard-basic" value={JONHSTESTPPD} onChange={(e) => { handleInputBioChemistry(e, "JONHSTESTPPD"); }} fullWidth variant="standard" />
                                                            </Col>
                                                        )
                                                    }
                                                    {
                                                        bunchecked && (
                                                            <Col sm="4">
                                                                <TextField label="BUN mg%" id="standard-basic" value={bun} onChange={(e) => { handleInputBioChemistry(e, "bun"); }} fullWidth variant="standard" />
                                                            </Col>
                                                        )
                                                    }
                                                    {
                                                        gluchecked && (
                                                            <Col sm="4">
                                                                <TextField label="Glu mg%" id="standard-basic" value={glu} onChange={(e) => { handleInputBioChemistry(e, "glu"); }} fullWidth variant="standard" />
                                                            </Col>
                                                        )
                                                    }

                                                    {
                                                        BioOthersChecked && (
                                                            <Col sm="4">
                                                                <TextField label="Others" id="standard-basic" value={BioOthers} onChange={(e) => { setBioOthers(e.target.value); }} fullWidth variant="standard" />
                                                            </Col>
                                                        )
                                                    }


                                                    {/* <Col sm="4">
                                                        <TextField label="T Bil mg%" id="standard-basic" value={tBill} onChange={(e) => { handleInputBioChemistry(e, "tBill"); }} fullWidth variant="standard" />
                                                    </Col> */}
                                                    {/* <Col sm="4">
                                                        <TextField label="Pro g%" id="standard-basic" value={proG} onChange={(e) => { handleInputBioChemistry(e, "proG"); }} fullWidth variant="standard" />
                                                    </Col>
                                                    <Col sm="4">
                                                        <TextField label="Chol m%" id="standard-basic" value={tecm} onChange={(e) => { handleInputBioChemistry(e, "tecm"); }} fullWidth variant="standard" />
                                                    </Col>
                                                    <Col sm="4">
                                                        <TextField label="P mg%" id="standard-basic" value={pMg} onChange={(e) => { handleInputBioChemistry(e, "pMg"); }} fullWidth variant="standard" />
                                                    </Col>
                                                    <Col sm="4">
                                                        <TextField label="Alb g%" id="standard-basic" value={albG} onChange={(e) => { handleInputBioChemistry(e, "albG"); }} fullWidth variant="standard" />
                                                    </Col>
                                                    <Col sm="4">
                                                        <TextField label="ASL IU/L" id="standard-basic" value={asl} onChange={(e) => { handleInputBioChemistry(e, "asl"); }} fullWidth variant="standard" />
                                                    </Col>
                                                    <Col sm="4">
                                                        <TextField label="Mg mg%" id="standard-basic" value={mg} onChange={(e) => { handleInputBioChemistry(e, "mg"); }} fullWidth variant="standard" />
                                                    </Col>
                                                    <Col sm="4">
                                                        <TextField label="Fig g%" id="standard-basic" value={flgG} onChange={(e) => { handleInputBioChemistry(e, "flgG"); }} fullWidth variant="standard" />
                                                    </Col>
                                                    <Col sm="4">
                                                        <TextField label="SDH IU/L" id="standard-basic" value={sdh} onChange={(e) => { handleInputBioChemistry(e, "sdh"); }} fullWidth variant="standard" />
                                                    </Col>
                                                    <Col sm="4">
                                                        <TextField label="Na mEq/L" id="standard-basic" value={na} onChange={(e) => { handleInputBioChemistry(e, "na"); }} fullWidth variant="standard" />
                                                    </Col>
                                                    <Col sm="4">
                                                        <TextField label="Alb : Glo" id="standard-basic" value={albGlo} onChange={(e) => { handleInputBioChemistry(e, "albGlo"); }} fullWidth variant="standard" />
                                                    </Col>
                                                    <Col sm="4">
                                                        <TextField label="GGT IU/L" id="standard-basic" value={ggt} onChange={(e) => { handleInputBioChemistry(e, "ggt"); }} fullWidth variant="standard" />
                                                    </Col>
                                                    <Col sm="4">
                                                        <TextField label="K mEq/L" id="standard-basic" value={k} onChange={(e) => { handleInputBioChemistry(e, "k"); }} fullWidth variant="standard" />
                                                    </Col>
                                                    <Col sm="4">
                                                        <TextField label="PP : Fib" id="standard-basic" value={ppFib} onChange={(e) => { handleInputBioChemistry(e, "ppFib"); }} fullWidth variant="standard" />
                                                    </Col>
                                                    <Col sm="4">
                                                        <TextField label="AKP IU/L" id="standard-basic" value={akp} onChange={(e) => { handleInputBioChemistry(e, "akp"); }} fullWidth variant="standard" />
                                                    </Col>
                                                    <Col sm="4">
                                                        <TextField label="C1 mEq/L" id="standard-basic" value={c1} onChange={(e) => { handleInputBioChemistry(e, "c1"); }} fullWidth variant="standard" />
                                                    </Col> */}

                                                    {/* <Col sm="4">
                                                        <TextField label="Cr mg%" id="standard-basic" value={cr} onChange={(e) => { handleInputBioChemistry(e, "cr"); }} fullWidth variant="standard" />
                                                    </Col>
                                                    <Col sm="4">
                                                        <TextField label="D Bil mg" id="standard-basic" value={dBill} onChange={(e) => { handleInputBioChemistry(e, "dBill"); }} fullWidth variant="standard" />
                                                    </Col> */}
                                                    {/* <Col sm="4" className='pt-2'>
                                                        <FormControl variant="standard" fullWidth>
                                                            <Searcable
                                                                placeholder="Br-MRT"
                                                                options={PosNegD}
                                                                onChange={(selectedOption) => setRandum(selectedOption.sampletype)}
                                                                value={randum}
                                                                labelKey="sampletype"
                                                                valueKey="id"
                                                            />
                                                        </FormControl>
                                                    </Col> */}
                                                    {/* <Col sm="4" className='pt-2'>
                                                        <FormControl variant="standard" fullWidth>
                                                            <Searcable
                                                                placeholder="SE-Cell Morphology"
                                                                options={seCellListD}
                                                                onChange={(selectedOption) => setSECellMorphology(selectedOption.sampletype)}
                                                                value={seCellMorphology}
                                                                labelKey="sampletype"
                                                                valueKey="id"
                                                            />
                                                        </FormControl>
                                                    </Col> */}
                                                </Row>
                                                <Row className="text-center mt-5">
                                                    <Col xs="4" xl="1" className='ms-auto'>
                                                        <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }} onClick={() => { setIndex(1) }}>Back</button>
                                                    </Col>
                                                    <Col xs="4" xl="1" className=''>
                                                        <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }} onClick={SaveBioChemistry}>Save</button>
                                                    </Col>
                                                    <Col xs="4" xl="1" className=''>
                                                        <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }}>Edit</button>
                                                    </Col>
                                                    <Col xs="4" xl="1" className=''>
                                                        <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }}>View</button>
                                                    </Col>
                                                    <Col xs="4" xl="1" className=''>
                                                        <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }}>Print</button>
                                                    </Col>
                                                    <Col xs="4" xl="1" className=''>
                                                        <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }}>End</button>
                                                    </Col>
                                                    <Col xs="4" xl="1" className=''>
                                                        <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }} onClick={() => { setIndex(3) }}>Next</button>
                                                    </Col>
                                                    {/* <Col xl="1" className='me-auto'>
                                                        <button className="btn-blue-Animal mt-1" onClick={() => setModalShow(false)} style={{ background: "#E16659" }}> Cancel </button>
                                                    </Col> */}
                                                </Row>
                                            </Container>
                                        }
                                        {
                                            index === 3 &&
                                            <Container>
                                                <Row className='mt-4 p-3'>
                                                    {/* <Col sm="4">
                                                        <TextField label="ID" id="standard-basic" value={glId} onChange={(e) => { handleInputGI(e, "glId"); }} fullWidth variant="standard" />
                                                    </Col>
                                                    <Col sm="4">
                                                        <TextField label="Pat ID No" id="standard-basic" value={glPatId} onChange={(e) => { handleInputGI(e, "glPatId"); }} fullWidth variant="standard" />
                                                    </Col> */}


                                                    <Col xxl="12" xl="12" lg="12" md="12" sm="12" xs="12" >
                                                        <input type='checkbox' onClick={(e) => { allCheck3(e.target.checked) }} /> All
                                                        <Row>
                                                            <Col xxl="2" xl="2" lg="2" md="2" sm="2" xs="2">
                                                                <input type='checkbox' checked={glParatismchecked} onChange={(e) => { setGlParatismchecked(e.target.checked) }} /> Gl Paratism
                                                            </Col>
                                                            <Col xxl="2" xl="2" lg="2" md="2" sm="2" xs="2">
                                                                <input type='checkbox' checked={fsPositiveForchecked} onChange={(e) => { setFsPositiveForchecked(e.target.checked) }} /> fs Positive For
                                                            </Col>
                                                            <Col xxl="2" xl="2" lg="2" md="2" sm="2" xs="2">
                                                                <input type='checkbox' checked={facelSamplechecked} onChange={(e) => { setFacelSamplechecked(e.target.checked) }} /> facel Sample
                                                            </Col>
                                                            <Col xxl="2" xl="2" lg="2" md="2" sm="2" xs="2">
                                                                <input type='checkbox' checked={nematodeschecked} onChange={(e) => { setNematodeschecked(e.target.checked) }} /> nema todes
                                                            </Col>
                                                            <Col xxl="2" xl="2" lg="2" md="2" sm="2" xs="2">
                                                                <input type='checkbox' checked={tramatodeschecked} onChange={(e) => { setTramatodeschecked(e.target.checked) }} /> trama todes
                                                            </Col>
                                                            <Col xxl="2" xl="2" lg="2" md="2" sm="2" xs="2">
                                                                <input type='checkbox' checked={cystodeschecked} onChange={(e) => { setCystodeschecked(e.target.checked) }} /> cystodes
                                                            </Col>
                                                            <Col xxl="2" xl="2" lg="2" md="2" sm="2" xs="2">
                                                                <input type='checkbox' checked={epgGtchecked} onChange={(e) => { setEpgGtchecked(e.target.checked) }} /> epg-Gt
                                                            </Col>
                                                        </Row>
                                                    </Col>

                                                    {
                                                        glParatismchecked && (
                                                            <Col sm="4">
                                                                <FormControl variant="standard" fullWidth>
                                                                    <Searcable
                                                                        placeholder="GI Parasitism"
                                                                        options={glParatismList}
                                                                        onChange={(selectedOption) => setGlParatism(selectedOption.sampletype)}
                                                                        value={glParatism}
                                                                        labelKey="sampletype"
                                                                        valueKey="id"

                                                                    />
                                                                </FormControl>
                                                            </Col>
                                                        )
                                                    }
                                                    {
                                                        fsPositiveForchecked && (
                                                            <Col sm="4" className='pt-2'>
                                                                <FormControl variant="standard" fullWidth>
                                                                    <Searcable
                                                                        placeholder="FS-Positive for"
                                                                        options={fsPositiveForListD}
                                                                        onChange={(selectedOption) => setFsPositiveFor(selectedOption)}
                                                                        value={fsPositiveFor}
                                                                        labelKey="sampletype"
                                                                        valueKey="id"
                                                                    />
                                                                </FormControl>
                                                            </Col>
                                                        )
                                                    }
                                                    {
                                                        facelSamplechecked && (
                                                            <Col sm="4" className='pt-2'>
                                                                <FormControl variant="standard" fullWidth>
                                                                    <Searcable
                                                                        placeholder="Fecal Sample Exa."
                                                                        options={facelSampleListD}
                                                                        onChange={(selectedOption) => setFacelSample(selectedOption)}
                                                                        value={facelSample}
                                                                        labelKey="sampletype"
                                                                        valueKey="id"
                                                                    />
                                                                </FormControl>
                                                            </Col>
                                                        )
                                                    }
                                                    {
                                                        nematodeschecked && (
                                                            <Col sm="4">
                                                                <TextField label="Nematodes" id="standard-basic" value={nematodes} onChange={(e) => { setNematodes(e.target.value); }} fullWidth variant="standard" />
                                                            </Col>
                                                        )
                                                    }
                                                    {
                                                        tramatodeschecked && (
                                                            <Col sm="4">
                                                                <TextField label="Trematodes" id="standard-basic" value={tramatodes} onChange={(e) => { setTramatodes(e.target.value); }} fullWidth variant="standard" />
                                                            </Col>
                                                        )
                                                    }
                                                    {
                                                        cystodeschecked && (
                                                            <Col sm="4">
                                                                <TextField label="Cystodes" id="standard-basic" value={cystodes} onChange={(e) => { setCystodes(e.target.value); }} fullWidth variant="standard" />
                                                            </Col>
                                                        )
                                                    }
                                                    {
                                                        epgGtchecked && (
                                                            <Col sm="4">
                                                                <TextField label="EPG-GT" id="standard-basic" value={epgGt} onChange={(e) => { setEpgGt(e.target.value); }} fullWidth variant="standard" />
                                                            </Col>
                                                        )
                                                    }

                                                </Row>
                                                <Row className="text-center mt-5">
                                                    <Col xs="4" xl="1" className='ms-auto'>
                                                        <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }} onClick={() => { setIndex(2) }}>Back</button>
                                                    </Col>
                                                    <Col xs="4" xl="1" className=''>
                                                        <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }} onClick={() => { SaveGiparasitism() }}>Save</button>
                                                    </Col>
                                                    {/* <Col xl="1" className='me-auto'>
                                                        <button className="btn-blue-Animal mt-1" onClick={() => setModalShow(false)} style={{ background: "#E16659" }}> Cancel </button>
                                                    </Col> */}

                                                    <Col xs="4" xl="1" className=''>
                                                        <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }}>Edit</button>
                                                    </Col>
                                                    <Col xs="4" xl="1" className=''>
                                                        <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }}>View</button>
                                                    </Col>
                                                    <Col xs="4" xl="1" className=''>
                                                        <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }}>End</button>
                                                    </Col>
                                                    <Col xs="4" xl="1" className=''>
                                                        <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }} onClick={() => { setIndex(4) }}>Next</button>
                                                    </Col>
                                                </Row>
                                            </Container>
                                        }
                                        {
                                            index === 4 &&
                                            <Container>
                                                <Row className='mt-4 p-3'>
                                                    {/* <Col sm="4">
                                                        <TextField label="ID" id="standard-basic" value={urinalysisId} onChange={(e) => { handleInputurinalysis(e, "urinalysisId"); }} fullWidth variant="standard" />
                                                    </Col>
                                                    <Col sm="4">
                                                        <TextField label="Pat ID No" id="standard-basic" value={urinalysisPatId} onChange={(e) => { handleInputurinalysis(e, "urinalysisPatId"); }} fullWidth variant="standard" />
                                                    </Col> */}
                                                    <Col sm="4">
                                                        <FormControl variant="standard" style={{ border: "1px soild black" }} fullWidth>
                                                            <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>Urinalysis</InputLabel>
                                                            <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={urinalysis} onChange={(e) => { setUrinalysis(e.target.value); }}>
                                                                {/* {urinalysisList.map((e) => (
                                                                    <MenuItem className="drop_value" key={e.id} value={e.Name} >
                                                                        {e.Name}
                                                                    </MenuItem>
                                                                ))} */}
                                                                <MenuItem className="drop_value" value="1" >1</MenuItem>
                                                                <MenuItem className="drop_value" value="2" >2</MenuItem>
                                                                <MenuItem className="drop_value" value="3" >3</MenuItem>
                                                                <MenuItem className="drop_value" value="4" >4</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </Col>
                                                    <Col sm="4" className='pt-2'>
                                                        {/* <FormControl variant="standard" style={{ border: "1px soild black" }} fullWidth>
                                                            <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>Glucose</InputLabel>
                                                            <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={glucose} onChange={(e) => { setGlucose(e.target.value); }}> */}
                                                        {/* {glucoseList.map((e) => (
                                                                    <MenuItem className="drop_value" key={e.id} value={e.Name} >
                                                                        {e.Name}
                                                                    </MenuItem>
                                                                ))} */}
                                                        {/* <MenuItem className="drop_value" value="1" >Positive</MenuItem>
                                                                <MenuItem className="drop_value" value="2" >Negative</MenuItem>
                                                            </Select>
                                                        </FormControl> */}
                                                        <FormControl variant="standard" fullWidth>
                                                            <Searcable
                                                                placeholder="Glucose"
                                                                options={PosNegD}
                                                                onChange={(selectedOption) => setGlucose(selectedOption.sampletype)}
                                                                value={glucose}
                                                                labelKey="sampletype"
                                                                valueKey="id"
                                                            />
                                                        </FormControl>
                                                    </Col>
                                                    <Col sm="4" className='pt-2'>
                                                        {/* <FormControl variant="standard" style={{ border: "1px soild black" }} fullWidth>
                                                            <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>Ketan</InputLabel>
                                                            <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={ketan} onChange={(e) => { setKetan(e.target.value); }}> */}
                                                        {/* {ketanList.map((e) => (
                                                                    <MenuItem className="drop_value" key={e.id} value={e.Name} >
                                                                        {e.Name}
                                                                    </MenuItem>
                                                                ))} */}
                                                        {/* <MenuItem className="drop_value" value="1" >Positive</MenuItem>
                                                                <MenuItem className="drop_value" value="2" >Negative</MenuItem>
                                                            </Select>
                                                        </FormControl> */}
                                                        <FormControl variant="standard" fullWidth>
                                                            <Searcable
                                                                placeholder="Ketan"
                                                                options={ketoneList}
                                                                onChange={(selectedOption) => setKetan(selectedOption.sampletype)}
                                                                value={ketan}
                                                                labelKey="sampletype"
                                                                valueKey="id"
                                                            />
                                                        </FormControl>
                                                    </Col>
                                                    <Col sm="4">
                                                        <TextField
                                                            label="Specific gravity"
                                                            id="standard-basic"
                                                            value={spGravity}
                                                            onChange={handleInputChange}
                                                            fullWidth
                                                            variant="standard"
                                                        />
                                                    </Col>
                                                    <Col sm="4" className='pt-2'>
                                                        {/* <FormControl variant="standard" style={{ border: "1px soild black" }} fullWidth>
                                                            <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>Blood</InputLabel>
                                                            <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={blood} onChange={(e) => { setBlood(e.target.value); }}> */}
                                                        {/* {bloodList.map((e) => (
                                                                    <MenuItem className="drop_value" key={e.id} value={e.Name} >
                                                                        {e.Name}
                                                                    </MenuItem>
                                                                ))} */}

                                                        {/* <MenuItem className="drop_value" value="1" >Positive</MenuItem>
                                                                <MenuItem className="drop_value" value="2" >Negative</MenuItem>
                                                            </Select>
                                                        </FormControl> */}

                                                        <FormControl variant="standard" fullWidth>
                                                            <Searcable
                                                                placeholder="Blood"
                                                                options={ketoneList}
                                                                onChange={(selectedOption) => setBlood(selectedOption.sampletype)}
                                                                value={blood}
                                                                labelKey="sampletype"
                                                                valueKey="id"
                                                            />
                                                        </FormControl>
                                                    </Col>
                                                    <Col sm="4">
                                                        <TextField label="pH" id="standard-basic" value={ph} onChange={(e) => { setPh(e.target.value); }} fullWidth variant="standard" />
                                                    </Col>
                                                    <Col sm="4" className='pt-2'>
                                                        {/* <FormControl variant="standard" style={{ border: "1px soild black" }} fullWidth>
                                                            <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>Bilirubin</InputLabel>
                                                            <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={bilirubin} onChange={(e) => { setBilirubin(e.target.value); }}> */}
                                                        {/* {billirubinList.map((e) => (
                                                                    <MenuItem className="drop_value" key={e.id} value={e.Name} >
                                                                        {e.Name}
                                                                    </MenuItem>
                                                                ))} */}
                                                        {/* <MenuItem className="drop_value" value="1" >Positive</MenuItem>
                                                                <MenuItem className="drop_value" value="2" >Negative</MenuItem>
                                                            </Select>
                                                        </FormControl> */}
                                                        <FormControl variant="standard" fullWidth>
                                                            <Searcable
                                                                placeholder="Bilirubin"
                                                                options={bilirubin}
                                                                onChange={(selectedOption) => setBilirubin(selectedOption.sampletype)}
                                                                value={blood}
                                                                labelKey="sampletype"
                                                                valueKey="id"
                                                            />
                                                        </FormControl>
                                                    </Col>
                                                    <Col sm="4">
                                                        <TextField label="US-Exam" id="standard-basic" value={usExam} onChange={(e) => { setUsExam(e.target.value); }} fullWidth variant="standard" />
                                                    </Col>
                                                    <Col sm="4" className='pt-2'>
                                                        {/* <FormControl variant="standard" style={{ border: "1px soild black" }} fullWidth>
                                                            <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>Protein</InputLabel>
                                                            <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={protien} onChange={(e) => { setProtien(e.target.value); }}> */}
                                                        {/* {protienList.map((e) => (
                                                                    <MenuItem className="drop_value" key={e.id} value={e.Name} >
                                                                        {e.Name}
                                                                    </MenuItem>
                                                                ))} */}
                                                        {/* <MenuItem className="drop_value" value="1" >Positive</MenuItem>
                                                                <MenuItem className="drop_value" value="2" >Negative</MenuItem>
                                                            </Select>
                                                        </FormControl> */}

                                                        <FormControl variant="standard" fullWidth>
                                                            <Searcable
                                                                placeholder="Protein"
                                                                options={ketoneList}
                                                                onChange={(selectedOption) => setProtien(selectedOption.sampletype)}
                                                                value={protien}
                                                                labelKey="sampletype"
                                                                valueKey="id"
                                                            />
                                                        </FormControl>
                                                    </Col>
                                                    <Col sm="4" className='pt-2'>
                                                        {/* <FormControl variant="standard" style={{ border: "1px soild black" }} fullWidth>
                                                            <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>Urobilinogen</InputLabel>
                                                            <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={urobillinogen} onChange={(e) => { setUrobillinogen(e.target.value); }}> */}
                                                        {/* {urobillinogenList.map((e) => (
                                                                    <MenuItem className="drop_value" key={e.id} value={e.Name} >
                                                                        {e.Name}
                                                                    </MenuItem>
                                                                ))} */}
                                                        {/* <MenuItem className="drop_value" value="1" >Positive</MenuItem>
                                                                <MenuItem className="drop_value" value="2" >Negative</MenuItem>
                                                            </Select>
                                                        </FormControl> */}
                                                        <FormControl variant="standard" fullWidth>
                                                            <Searcable
                                                                placeholder="Urobilinogen"
                                                                options={bilirubin}
                                                                onChange={(selectedOption) => setUrobillinogen(selectedOption.sampletype)}
                                                                value={urobillinogen}
                                                                labelKey="sampletype"
                                                                valueKey="id"
                                                            />
                                                        </FormControl>
                                                    </Col>
                                                    <Col sm="4" className='pt-2'>
                                                        {/* <FormControl variant="standard" style={{ border: "1px soild black" }} fullWidth>
                                                            <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>Nitrite</InputLabel>
                                                            <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={nitrite} onChange={(e) => { setNitrite(e.target.value); }}> */}
                                                        {/* {nitriteList.map((e) => (
                                                                    <MenuItem className="drop_value" key={e.id} value={e.Name} >
                                                                        {e.Name}
                                                                    </MenuItem>
                                                                ))} */}
                                                        {/* <MenuItem className="drop_value" value="1" >Positive</MenuItem>
                                                                <MenuItem className="drop_value" value="2" >Negative</MenuItem>
                                                            </Select>
                                                        </FormControl> */}
                                                        <FormControl variant="standard" fullWidth>
                                                            <Searcable
                                                                placeholder="Nitrite"
                                                                options={bilirubin}
                                                                onChange={(selectedOption) => setNitrite(selectedOption.sampletype)}
                                                                value={nitrite}
                                                                labelKey="sampletype"
                                                                valueKey="id"
                                                            />
                                                        </FormControl>
                                                    </Col>
                                                    <Col sm="4" className='pt-2'>
                                                        {/* <FormControl variant="standard" style={{ border: "1px soild black" }} fullWidth>
                                                            <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>Leukocytes</InputLabel>
                                                            <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={leukocytes} onChange={(e) => { setLeukocytes(e.target.value); }}> */}
                                                        {/* {leukocytesList.map((e) => (
                                                                    <MenuItem className="drop_value" key={e.id} value={e.Name} >
                                                                        {e.Name}
                                                                    </MenuItem>
                                                                ))} */}
                                                        {/* <MenuItem className="drop_value" value="1" >Positive</MenuItem>
                                                                <MenuItem className="drop_value" value="2" >Negative</MenuItem>
                                                            </Select>
                                                        </FormControl> */}
                                                        <FormControl variant="standard" fullWidth>
                                                            <Searcable
                                                                placeholder="Leukocytes"
                                                                options={bilirubin}
                                                                onChange={(selectedOption) => setLeukocytes(selectedOption.sampletype)}
                                                                value={leukocytes}
                                                                labelKey="sampletype"
                                                                valueKey="id"
                                                            />
                                                        </FormControl>
                                                    </Col>
                                                </Row>
                                                <Row className="text-center mt-5">
                                                    <Col xs="4" xl="1" className='ms-auto'>
                                                        <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }} onClick={() => { setIndex(3) }}>Back</button>
                                                    </Col>
                                                    <Col xs="4" xl="1" className=''>
                                                        <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }} onClick={SaveUrinalysis}>Save</button>
                                                    </Col>
                                                    <Col xs="4" xl="1" className=''>
                                                        <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }} onClick={printReport}>Print</button>
                                                    </Col>
                                                    <Col xs="4" xl="1" className=''>
                                                        <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }}>Edit</button>
                                                    </Col>
                                                    <Col xs="4" xl="1" className=''>
                                                        <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }}>View</button>
                                                    </Col>
                                                    <Col xs="4" xl="1" className=''>
                                                        <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }}>End</button>
                                                    </Col>
                                                    <Col xs="4" xl="1" className=''>
                                                        <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }} onClick={() => { setIndex(5) }}>Next</button>
                                                    </Col>
                                                    {/* <Col xl="1" className='me-auto'>
                                                        <button className="btn-blue-Animal mt-1" onClick={() => setModalShow(false)} style={{ background: "#E16659" }}> Cancel </button>
                                                    </Col> */}
                                                </Row>
                                            </Container>
                                        }
                                        {
                                            index === 5 &&
                                            <Container>
                                                <Row className='mt-4 p-3'>
                                                    {/* <Col sm="4">
                                                        <TextField label="ID" id="standard-basic" value={immunologyId} onChange={(e) => { handleInputImmunology(e, "immunologyId"); }} fullWidth variant="standard" />
                                                    </Col>
                                                    <Col sm="4">
                                                        <TextField label="Pat ID No" id="standard-basic" value={immunologyPatId} onChange={(e) => { handleInputImmunology(e, "immunologyPatId"); }} fullWidth variant="standard" />
                                                    </Col> */}
                                                    <Col sm="4">
                                                        <FormControl variant="standard" style={{ border: "1px soild black" }} fullWidth>
                                                            <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>Immuno1 Test For-Disease</InputLabel>
                                                            <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={immunolTest} onChange={(e) => { setImmunolTest(e.target.value); }}>
                                                                {/* {immunolTestList.map((e) => (
                                                                    <MenuItem className="drop_value" key={e.id} value={e.Name} >
                                                                        {e.Name}
                                                                    </MenuItem>
                                                                ))} */}
                                                                <MenuItem className="drop_value" value="1" >1</MenuItem>
                                                                <MenuItem className="drop_value" value="2" >2</MenuItem>
                                                                <MenuItem className="drop_value" value="3" >3</MenuItem>
                                                                <MenuItem className="drop_value" value="4" >4</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </Col>
                                                    <Col sm="4" className='pt-2'>
                                                        {/* <FormControl variant="standard" style={{ border: "1px soild black" }} fullWidth> */}
                                                        {/* <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>Br-MRT</InputLabel>
                                                            <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={brMrt} onChange={(e) => { setBrMrt(e.target.value); }}> */}
                                                        {/* {brMrtList.map((e) => (
                                                                    <MenuItem className="drop_value" key={e.id} value={e.Name} >
                                                                        {e.Name}
                                                                    </MenuItem>
                                                                ))} */}
                                                        {/* <MenuItem className="drop_value" value="1" >1</MenuItem>
                                                                <MenuItem className="drop_value" value="2" >2</MenuItem>
                                                                <MenuItem className="drop_value" value="3" >3</MenuItem>
                                                                <MenuItem className="drop_value" value="4" >4</MenuItem> */}
                                                        {/* </Select>
                                                        </FormControl> */}
                                                        <FormControl variant="standard" fullWidth>
                                                            <Searcable
                                                                placeholder="Br-MRT"
                                                                options={PosNegD}
                                                                onChange={(selectedOption) => setBrMrt(selectedOption.sampletype)}
                                                                value={brMrt}
                                                                labelKey="sampletype"
                                                                valueKey="id"
                                                            />
                                                        </FormControl>
                                                    </Col>
                                                    <Col sm="4" className='pt-2'>
                                                        {/* <FormControl variant="standard" style={{ border: "1px soild black" }} fullWidth>
                                                            <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>Br-ELISA</InputLabel>
                                                            <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={brElisa} onChange={(e) => { setBrElisa(e.target.value); }}> */}
                                                        {/* {brElisaList.map((e) => (
                                                                    <MenuItem className="drop_value" key={e.id} value={e.Name} >
                                                                        {e.Name}
                                                                    </MenuItem>
                                                                ))} */}
                                                        {/* <MenuItem className="drop_value" value="1" >1</MenuItem>
                                                                <MenuItem className="drop_value" value="2" >2</MenuItem>
                                                                <MenuItem className="drop_value" value="3" >3</MenuItem>
                                                                <MenuItem className="drop_value" value="4" >4</MenuItem>
                                                            </Select>
                                                        </FormControl> */}

                                                        <FormControl variant="standard" fullWidth>
                                                            <Searcable
                                                                placeholder="Br-ELISA"
                                                                options={PosNegD}
                                                                onChange={(selectedOption) => setBrElisa(selectedOption.sampletype)}
                                                                value={brElisa}
                                                                labelKey="sampletype"
                                                                valueKey="id"
                                                            />
                                                        </FormControl>
                                                    </Col>
                                                    <Col sm="4" className='pt-2'>
                                                        {/* <FormControl variant="standard" style={{ border: "1px soild black" }} fullWidth>
                                                            <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>RBPT</InputLabel>
                                                            <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={rbpt} onChange={(e) => { setRbpt(e.target.value); }}> */}
                                                        {/* {rbptList.map((e) => (
                                                                    <MenuItem className="drop_value" key={e.id} value={e.Name} >
                                                                        {e.Name}
                                                                    </MenuItem>
                                                                ))} */}
                                                        {/* <MenuItem className="drop_value" value="1" >1</MenuItem>
                                                                <MenuItem className="drop_value" value="2" >2</MenuItem>
                                                                <MenuItem className="drop_value" value="3" >3</MenuItem>
                                                                <MenuItem className="drop_value" value="4" >4</MenuItem>
                                                            </Select>
                                                        </FormControl> */}

                                                        <FormControl variant="standard" fullWidth>
                                                            <Searcable
                                                                placeholder="RBPT"
                                                                options={PosNegD}
                                                                onChange={(selectedOption) => setRbpt(selectedOption.sampletype)}
                                                                value={rbpt}
                                                                labelKey="sampletype"
                                                                valueKey="id"
                                                            />
                                                        </FormControl>
                                                    </Col>
                                                    <Col sm="4" className='pt-2'>
                                                        {/* <FormControl variant="standard" style={{ border: "1px soild black" }} fullWidth>
                                                            <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>IRB-ELISA</InputLabel>
                                                            <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={irbElisa} onChange={(e) => { setIrbElisa(e.target.value); }}> */}
                                                        {/* {irbElisaList.map((e) => (
                                                                    <MenuItem className="drop_value" key={e.id} value={e.Name} >
                                                                        {e.Name}
                                                                    </MenuItem>
                                                                ))} */}
                                                        {/* <MenuItem className="drop_value" value="1" >1</MenuItem>
                                                                <MenuItem className="drop_value" value="2" >2</MenuItem>
                                                                <MenuItem className="drop_value" value="3" >3</MenuItem>
                                                                <MenuItem className="drop_value" value="4" >4</MenuItem>
                                                            </Select>
                                                        </FormControl> */}

                                                        <FormControl variant="standard" fullWidth>
                                                            <Searcable
                                                                placeholder="IRB-ELISA"
                                                                options={PosNegD}
                                                                onChange={(selectedOption) => setIrbElisa(selectedOption.sampletype)}
                                                                value={irbElisa}
                                                                labelKey="sampletype"
                                                                valueKey="id"
                                                            />
                                                        </FormControl>
                                                    </Col>
                                                    <Col sm="4" className='pt-2'>
                                                        {/* <FormControl variant="standard" style={{ border: "1px soild black" }} fullWidth>
                                                            <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>STAT</InputLabel>
                                                            <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={stat} onChange={(e) => { setStat(e.target.value); }}> */}
                                                        {/* {statList.map((e) => (
                                                                    <MenuItem className="drop_value" key={e.id} value={e.Name} >
                                                                        {e.Name}
                                                                    </MenuItem>
                                                                ))} */}
                                                        {/* <MenuItem className="drop_value" value="1" >1</MenuItem>
                                                                <MenuItem className="drop_value" value="2" >2</MenuItem>
                                                                <MenuItem className="drop_value" value="3" >3</MenuItem>
                                                                <MenuItem className="drop_value" value="4" >4</MenuItem>
                                                            </Select>
                                                        </FormControl> */}
                                                        <FormControl variant="standard" fullWidth>
                                                            <Searcable
                                                                placeholder="STAT"
                                                                options={PosNegD}
                                                                onChange={(selectedOption) => setStat(selectedOption.sampletype)}
                                                                value={stat}
                                                                labelKey="sampletype"
                                                                valueKey="id"
                                                            />
                                                        </FormControl>
                                                    </Col>
                                                    <Col sm="4" className='pt-2'>
                                                        <FormControl variant="standard" style={{ border: "1px soild black" }} fullWidth>
                                                            <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>JID-ELISA</InputLabel>
                                                            <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={jidElisa} onChange={(e) => { setJidElisa(e.target.value); }}>
                                                                {/* {jidElisaList.map((e) => (
                                                                    <MenuItem className="drop_value" key={e.id} value={e.Name} >
                                                                        {e.Name}
                                                                    </MenuItem>
                                                                ))} */}
                                                                <MenuItem className="drop_value" value="1" >1</MenuItem>
                                                                <MenuItem className="drop_value" value="2" >2</MenuItem>
                                                                <MenuItem className="drop_value" value="3" >3</MenuItem>
                                                                <MenuItem className="drop_value" value="4" >4</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </Col>
                                                    <Col sm="4">
                                                        <FormControl variant="standard" style={{ border: "1px soild black" }} fullWidth>
                                                            <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>ID-Tuberuline</InputLabel>
                                                            <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={idTuberuline} onChange={(e) => { setIdTuberuline(e.target.value); }}>
                                                                {/* {idTuberulineList.map((e) => (
                                                                    <MenuItem className="drop_value" key={e.id} value={e.Name} >
                                                                        {e.Name}
                                                                    </MenuItem>
                                                                ))} */}
                                                                <MenuItem className="drop_value" value="1" >1</MenuItem>
                                                                <MenuItem className="drop_value" value="2" >2</MenuItem>
                                                                <MenuItem className="drop_value" value="3" >3</MenuItem>
                                                                <MenuItem className="drop_value" value="4" >4</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </Col>
                                                    <Col sm="4">
                                                        <FormControl variant="standard" style={{ border: "1px soild black" }} fullWidth>
                                                            <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>ID-Johnine</InputLabel>
                                                            <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={idJohnine} onChange={(e) => { setIdJohnine(e.target.value); }}>
                                                                {/* {idJohninelist.map((e) => (
                                                                    <MenuItem className="drop_value" key={e.id} value={e.Name} >
                                                                        {e.Name}
                                                                    </MenuItem>
                                                                ))} */}
                                                                <MenuItem className="drop_value" value="1" >1</MenuItem>
                                                                <MenuItem className="drop_value" value="2" >2</MenuItem>
                                                                <MenuItem className="drop_value" value="3" >3</MenuItem>
                                                                <MenuItem className="drop_value" value="4" >4</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </Col>
                                                    <Col sm="4">
                                                        <FormControl variant="standard" style={{ border: "1px soild black" }} fullWidth>
                                                            <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>IBR-FAT</InputLabel>
                                                            <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={ibrFat} onChange={(e) => { setIbrFat(e.target.value); }}>
                                                                {/* {ibrFatList.map((e) => (
                                                                    <MenuItem className="drop_value" key={e.id} value={e.Name} >
                                                                        {e.Name}
                                                                    </MenuItem>
                                                                ))} */}
                                                                <MenuItem className="drop_value" value="1" >1</MenuItem>
                                                                <MenuItem className="drop_value" value="2" >2</MenuItem>
                                                                <MenuItem className="drop_value" value="3" >3</MenuItem>
                                                                <MenuItem className="drop_value" value="4" >4</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </Col>
                                                </Row>
                                                <Row className="text-center mt-5">
                                                    <Col xs="4" xl="1" className='ms-auto'>
                                                        <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }} onClick={() => { setIndex(4) }}>Back</button>
                                                    </Col>
                                                    <Col xs="4" xl="1" className=''>
                                                        <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }} onClick={SaveImmunology}>Save</button>
                                                    </Col>
                                                    <Col xs="4" xl="1" className=''>
                                                        <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }} onClick={printReport}>Print</button>
                                                    </Col>
                                                    <Col xs="4" xl="1" className=''>
                                                        <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }}>Edit</button>
                                                    </Col>
                                                    <Col xs="4" xl="1" className=''>
                                                        <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }}>View</button>
                                                    </Col>
                                                    <Col xs="4" xl="1" className=''>
                                                        <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }}>End</button>
                                                    </Col>
                                                    <Col xs="4" xl="1" className=''>
                                                        <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }} onClick={() => { setIndex(6) }}>Next</button>
                                                    </Col>
                                                    {/* <Col xl="1" className='me-auto'>
                                                        <button className="btn-blue-Animal mt-1" onClick={() => setModalShow(false)} style={{ background: "#E16659" }}> Cancel </button>
                                                    </Col> */}
                                                </Row>
                                            </Container>
                                        }
                                        {
                                            index === 6 &&
                                            <Container>
                                                <Row className='mt-4 p-3'>
                                                    {/* <Col sm="4">
                                                        <TextField label="ID" id="standard-basic" value={scmId} onChange={(e) => { setScmId(e.target.value); }} fullWidth variant="standard" />
                                                    </Col>
                                                    <Col sm="4">
                                                        <TextField label="Pat ID No" id="standard-basic" value={scmPatIdNo} onChange={(e) => { setScmPatIdNo(e.target.value); }} fullWidth variant="standard" />
                                                    </Col> */}
                                                    <Col sm="4">
                                                        <FormControl variant="standard" style={{ border: "1px soild black" }} fullWidth>
                                                            <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>SCM sample type</InputLabel>
                                                            <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={scmSampleType} onChange={(e) => { setScmSampleType(e.target.value); }}>
                                                                {/* {scmList.map((e) => (
                                                                    <MenuItem className="drop_value" key={e.id} value={e.Name} >
                                                                        {e.Name}
                                                                    </MenuItem>
                                                                ))} */}
                                                                <MenuItem className="drop_value" value="1" >1</MenuItem>
                                                                <MenuItem className="drop_value" value="2" >2</MenuItem>
                                                                <MenuItem className="drop_value" value="3" >3</MenuItem>
                                                                <MenuItem className="drop_value" value="4" >4</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </Col>
                                                    <Col sm="4">
                                                        <FormControl variant="standard" style={{ border: "1px soild black" }} fullWidth>
                                                            <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>Mastrip</InputLabel>
                                                            <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={mastrip} onChange={(e) => { setMastrip(e.target.value); }}>
                                                                {/* {mastripList.map((e) => (
                                                                    <MenuItem className="drop_value" key={e.id} value={e.Name} >
                                                                        {e.Name}
                                                                    </MenuItem>
                                                                ))} */}
                                                                <MenuItem className="drop_value" value="1" >1</MenuItem>
                                                                <MenuItem className="drop_value" value="2" >2</MenuItem>
                                                                <MenuItem className="drop_value" value="3" >3</MenuItem>
                                                                <MenuItem className="drop_value" value="4" >4</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </Col>
                                                    <Col sm="4">
                                                        <FormControl variant="standard" style={{ border: "1px soild black" }} fullWidth>
                                                            <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>CMT</InputLabel>
                                                            <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={cmt} onChange={(e) => { setCmt(e.target.value); }}>
                                                                {/* {brElisaList.map((e) => (
                                                                    <MenuItem className="drop_value" key={e.id} value={e.Name} >
                                                                        {e.Name}
                                                                    </MenuItem>
                                                                ))} */}
                                                                <MenuItem className="drop_value" value="1" >1</MenuItem>
                                                                <MenuItem className="drop_value" value="2" >2</MenuItem>
                                                                <MenuItem className="drop_value" value="3" >3</MenuItem>
                                                                <MenuItem className="drop_value" value="4" >4</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </Col>
                                                    <Col sm="4">
                                                        <FormControl variant="standard" style={{ border: "1px soild black" }} fullWidth>
                                                            <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>EC</InputLabel>
                                                            <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={ec} onChange={(e) => { setEc(e.target.value); }}>
                                                                {/* {ecList.map((e) => (
                                                                    <MenuItem className="drop_value" key={e.id} value={e.Name} >
                                                                        {e.Name}
                                                                    </MenuItem>
                                                                ))} */}
                                                                <MenuItem className="drop_value" value="1" >1</MenuItem>
                                                                <MenuItem className="drop_value" value="2" >2</MenuItem>
                                                                <MenuItem className="drop_value" value="3" >3</MenuItem>
                                                                <MenuItem className="drop_value" value="4" >4</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </Col>
                                                    <Col sm="4">
                                                        <FormControl variant="standard" style={{ border: "1px soild black" }} fullWidth>
                                                            <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>SSC Th/ml</InputLabel>
                                                            <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={sscTh} onChange={(e) => { setSscTh(e.target.value); }}>
                                                                {sscThList.map((e) => (
                                                                    <MenuItem className="drop_value" key={e.id} value={e.Name} >
                                                                        {e.Name}
                                                                    </MenuItem>
                                                                ))}
                                                                <MenuItem className="drop_value" value="1" >1</MenuItem>
                                                                <MenuItem className="drop_value" value="2" >2</MenuItem>
                                                                <MenuItem className="drop_value" value="3" >3</MenuItem>
                                                                <MenuItem className="drop_value" value="4" >4</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </Col>
                                                </Row>
                                                <Row className="text-center mt-5">
                                                    <Col xs="4" xl="1" className='ms-auto'>
                                                        <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }} onClick={() => { setIndex(5) }}>Back</button>
                                                    </Col>
                                                    <Col xs="4" xl="1" className=''>
                                                        <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }} onClick={SaveScm}>Save</button>
                                                    </Col>
                                                    <Col xs="4" xl="1" className=''>
                                                        <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }} onClick={printReport}>Print</button>
                                                    </Col>
                                                    <Col xs="4" xl="1" className=''>
                                                        <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }}>Edit</button>
                                                    </Col>
                                                    <Col xs="4" xl="1" className=''>
                                                        <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }}>View</button>
                                                    </Col>
                                                    <Col xs="4" xl="1" className=''>
                                                        <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }}>End</button>
                                                    </Col>
                                                    <Col xs="4" xl="1" className=''>
                                                        <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }} onClick={() => { setIndex(7) }}>Next</button>
                                                    </Col>
                                                    {/* <Col xl="1" className='me-auto'>
                                                        <button className="btn-blue-Animal mt-1" onClick={() => setModalShow(false)} style={{ background: "#E16659" }}> Cancel </button>
                                                    </Col> */}
                                                </Row>
                                            </Container>
                                        }
                                        {
                                            index === 7 &&
                                            <Container>
                                                <Row className='mt-4 p-3'>
                                                    {/* <Col sm="4">
                                                        <TextField label="ID" id="standard-basic" value={antigramId} onChange={(e) => { setAntigramId(e.target.value); }} fullWidth variant="standard" />
                                                    </Col>
                                                    <Col sm="4">
                                                        <TextField label="Pat ID No" id="standard-basic" value={antigramPatId} onChange={(e) => { setAntigramPatId(e.target.value); }} fullWidth variant="standard" />
                                                    </Col> */}
                                                    <Col sm="4">
                                                        <FormControl variant="standard" style={{ border: "1px soild black" }} fullWidth>
                                                            <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>ABS-Sample</InputLabel>
                                                            <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={absSample} onChange={(e) => { setAbsSample(e.target.value); }}>
                                                                {/* {absSampleList.map((e) => (
                                                                    <MenuItem className="drop_value" key={e.id} value={e.Name} >
                                                                        {e.Name}
                                                                    </MenuItem>
                                                                ))} */}
                                                                <MenuItem className="drop_value" value="1" >1</MenuItem>
                                                                <MenuItem className="drop_value" value="2" >2</MenuItem>
                                                                <MenuItem className="drop_value" value="3" >3</MenuItem>
                                                                <MenuItem className="drop_value" value="4" >4</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </Col>
                                                    <Col sm="4">
                                                        <FormControl variant="standard" style={{ border: "1px soild black" }} fullWidth>
                                                            <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>ABS smaple quality</InputLabel>
                                                            <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={absSampleQuality} onChange={(e) => { setAbsSampleQuality(e.target.value); }}>
                                                                {/* {absSampleList.map((e) => (
                                                                    <MenuItem className="drop_value" key={e.id} value={e.Name} >
                                                                        {e.Name}
                                                                    </MenuItem>
                                                                ))} */}
                                                                <MenuItem className="drop_value" value="1" >1</MenuItem>
                                                                <MenuItem className="drop_value" value="2" >2</MenuItem>
                                                                <MenuItem className="drop_value" value="3" >3</MenuItem>
                                                                <MenuItem className="drop_value" value="4" >4</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </Col>
                                                    <Col sm="4">
                                                        <FormControl variant="standard" style={{ border: "1px soild black" }} fullWidth>
                                                            <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>Pathogen ID</InputLabel>
                                                            <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={pathogenId} onChange={(e) => { setPathogenId(e.target.value); }}>
                                                                {/* {pathogenList.map((e) => (
                                                                    <MenuItem className="drop_value" key={e.id} value={e.Name} >
                                                                        {e.Name}
                                                                    </MenuItem>
                                                                ))} */}
                                                                <MenuItem className="drop_value" value="1" >1</MenuItem>
                                                                <MenuItem className="drop_value" value="2" >2</MenuItem>
                                                                <MenuItem className="drop_value" value="3" >3</MenuItem>
                                                                <MenuItem className="drop_value" value="4" >4</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </Col>
                                                    <Col sm="4">
                                                        <FormControl variant="standard" style={{ border: "1px soild black" }} fullWidth>
                                                            <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>Antibiotics</InputLabel>
                                                            <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={antibiotics} onChange={(e) => { setAntibiotics(e.target.value); }}>
                                                                {/* {antibioticsList.map((e) => (
                                                                    <MenuItem className="drop_value" key={e.id} value={e.Name} >
                                                                        {e.Name}
                                                                    </MenuItem>
                                                                ))} */}
                                                                <MenuItem className="drop_value" value="1" >1</MenuItem>
                                                                <MenuItem className="drop_value" value="2" >2</MenuItem>
                                                                <MenuItem className="drop_value" value="3" >3</MenuItem>
                                                                <MenuItem className="drop_value" value="4" >4</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </Col>
                                                    <Col sm="4">
                                                        <FormControl variant="standard" style={{ border: "1px soild black" }} fullWidth>
                                                            <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>BK-DDMethod</InputLabel>
                                                            <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={bkMethod} onChange={(e) => { setBkMethod(e.target.value); }}>
                                                                {/* {bkMethodList.map((e) => (
                                                                    <MenuItem className="drop_value" key={e.id} value={e.Name} >
                                                                        {e.Name}
                                                                    </MenuItem>
                                                                ))} */}
                                                                <MenuItem className="drop_value" value="1" >1</MenuItem>
                                                                <MenuItem className="drop_value" value="2" >2</MenuItem>
                                                                <MenuItem className="drop_value" value="3" >3</MenuItem>
                                                                <MenuItem className="drop_value" value="4" >4</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </Col>
                                                </Row>
                                                <Row className="text-center mt-5">
                                                    <Col xs="4" xl="1" className='ms-auto'>
                                                        <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }} onClick={() => { setIndex(6) }}>Back</button>
                                                    </Col>
                                                    <Col xs="4" xl="1" className=''>
                                                        <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }} onClick={SaveAntigram}>Save</button>
                                                    </Col>
                                                    <Col xs="4" xl="1" className=''>
                                                        <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }} onClick={printReport}>Print</button>
                                                    </Col>
                                                    <Col xs="4" xl="1" className=''>
                                                        <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }}>Edit</button>
                                                    </Col>
                                                    <Col xs="4" xl="1" className=''>
                                                        <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }}>View</button>
                                                    </Col>
                                                    <Col xs="4" xl="1" className=''>
                                                        <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }}>End</button>
                                                    </Col>
                                                    <Col xs="4" xl="1" className=''>
                                                        <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }} onClick={() => { setIndex(8) }}>Next</button>
                                                    </Col>
                                                    {/* <Col xl="1" className='me-auto'>
                                                        <button className="btn-blue-Animal mt-1" onClick={() => setModalShow(false)} style={{ background: "#E16659" }}> Cancel </button>
                                                    </Col> */}
                                                </Row>
                                            </Container>
                                        }
                                        {
                                            index === 8 &&
                                            <Container>
                                                <Row className='mt-4 p-3'>
                                                    {/* <Col sm="4">
                                                        <TextField label="ID" id="standard-basic" value={spId} onChange={(e) => { setSpId(e.target.value); }} fullWidth variant="standard" />
                                                    </Col>
                                                    <Col sm="4">
                                                        <TextField label="Pat ID No" id="standard-basic" value={spPatIdNo} onChange={(e) => { setSpPatIdNo(e.target.value); }} fullWidth variant="standard" />
                                                    </Col> */}
                                                    <Col sm="4">
                                                        <FormControl variant="standard" style={{ border: "1px soild black" }} fullWidth>
                                                            <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>Special Path</InputLabel>
                                                            <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={specialPath} onChange={(e) => { setSpecialPath(e.target.value); }}>
                                                                {/* {specialPathList.map((e) => (
                                                                    <MenuItem className="drop_value" key={e.id} value={e.Name} >
                                                                        {e.Name}
                                                                    </MenuItem>
                                                                ))} */}
                                                                <MenuItem className="drop_value" value="1" >1</MenuItem>
                                                                <MenuItem className="drop_value" value="2" >2</MenuItem>
                                                                <MenuItem className="drop_value" value="3" >3</MenuItem>
                                                                <MenuItem className="drop_value" value="4" >4</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </Col>
                                                    <Col sm="4">
                                                        <FormControl variant="standard" style={{ border: "1px soild black" }} fullWidth>
                                                            <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>SPS-2</InputLabel>
                                                            <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={sps2} onChange={(e) => { setSps2(e.target.value); }}>
                                                                {/* {sps2List.map((e) => (
                                                                    <MenuItem className="drop_value" key={e.id} value={e.Name} >
                                                                        {e.Name}
                                                                    </MenuItem>
                                                                ))} */}
                                                                <MenuItem className="drop_value" value="1" >1</MenuItem>
                                                                <MenuItem className="drop_value" value="2" >2</MenuItem>
                                                                <MenuItem className="drop_value" value="3" >3</MenuItem>
                                                                <MenuItem className="drop_value" value="4" >4</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </Col>
                                                    <Col sm="4">
                                                        <FormControl variant="standard" style={{ border: "1px soild black" }} fullWidth>
                                                            <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>SPS-3</InputLabel>
                                                            <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={sps3} onChange={(e) => { setSps3(e.target.value); }}>
                                                                {/* {sps3list.map((e) => (
                                                                    <MenuItem className="drop_value" key={e.id} value={e.Name} >
                                                                        {e.Name}
                                                                    </MenuItem>
                                                                ))} */}
                                                                <MenuItem className="drop_value" value="1" >1</MenuItem>
                                                                <MenuItem className="drop_value" value="2" >2</MenuItem>
                                                                <MenuItem className="drop_value" value="3" >3</MenuItem>
                                                                <MenuItem className="drop_value" value="4" >4</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </Col>
                                                    <Col sm="4">
                                                        <FormControl variant="standard" style={{ border: "1px soild black" }} fullWidth>
                                                            <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>SPS-4</InputLabel>
                                                            <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={sps4} onChange={(e) => { setSps4(e.target.value); }}>
                                                                {/* {sps4List.map((e) => (
                                                                    <MenuItem className="drop_value" key={e.id} value={e.Name} >
                                                                        {e.Name}
                                                                    </MenuItem>
                                                                ))} */}
                                                                <MenuItem className="drop_value" value="1" >1</MenuItem>
                                                                <MenuItem className="drop_value" value="2" >2</MenuItem>
                                                                <MenuItem className="drop_value" value="3" >3</MenuItem>
                                                                <MenuItem className="drop_value" value="4" >4</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </Col>
                                                    <Col sm="4">
                                                        <FormControl variant="standard" style={{ border: "1px soild black" }} fullWidth>
                                                            <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>SPS-5</InputLabel>
                                                            <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={sps5} onChange={(e) => { setSps5(e.target.value); }}>
                                                                {/* {sps5List.map((e) => (
                                                                    <MenuItem className="drop_value" key={e.id} value={e.Name} >
                                                                        {e.Name}
                                                                    </MenuItem>
                                                                ))} */}
                                                                <MenuItem className="drop_value" value="1" >1</MenuItem>
                                                                <MenuItem className="drop_value" value="2" >2</MenuItem>
                                                                <MenuItem className="drop_value" value="3" >3</MenuItem>
                                                                <MenuItem className="drop_value" value="4" >4</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </Col>
                                                    <Col sm="4">
                                                        <FormControl variant="standard" style={{ border: "1px soild black" }} fullWidth>
                                                            <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>SPS-6</InputLabel>
                                                            <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={sps6} onChange={(e) => { setSps6(e.target.value); }}>
                                                                {/* {sps6List.map((e) => (
                                                                    <MenuItem className="drop_value" key={e.id} value={e.Name} >
                                                                        {e.Name}
                                                                    </MenuItem>
                                                                ))} */}
                                                                <MenuItem className="drop_value" value="1" >1</MenuItem>
                                                                <MenuItem className="drop_value" value="2" >2</MenuItem>
                                                                <MenuItem className="drop_value" value="3" >3</MenuItem>
                                                                <MenuItem className="drop_value" value="4" >4</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </Col>
                                                    <Col sm="4">
                                                        <FormControl variant="standard" style={{ border: "1px soild black" }} fullWidth>
                                                            <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>SP-Resu</InputLabel>
                                                            <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={spResu} onChange={(e) => { setSpResu(e.target.value); }}>
                                                                {/* {spResuList.map((e) => (
                                                                    <MenuItem className="drop_value" key={e.id} value={e.Name} >
                                                                        {e.Name}
                                                                    </MenuItem>
                                                                ))} */}
                                                                <MenuItem className="drop_value" value="1" >1</MenuItem>
                                                                <MenuItem className="drop_value" value="2" >2</MenuItem>
                                                                <MenuItem className="drop_value" value="3" >3</MenuItem>
                                                                <MenuItem className="drop_value" value="4" >4</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </Col>
                                                    <Col sm="4">
                                                        <FormControl variant="standard" style={{ border: "1px soild black" }} fullWidth>
                                                            <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>SPR-2</InputLabel>
                                                            <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={spr2} onChange={(e) => { setSpr2(e.target.value); }}>
                                                                {/* {spr2List.map((e) => (
                                                                    <MenuItem className="drop_value" key={e.id} value={e.Name} >
                                                                        {e.Name}
                                                                    </MenuItem>
                                                                ))} */}
                                                                <MenuItem className="drop_value" value="1" >1</MenuItem>
                                                                <MenuItem className="drop_value" value="2" >2</MenuItem>
                                                                <MenuItem className="drop_value" value="3" >3</MenuItem>
                                                                <MenuItem className="drop_value" value="4" >4</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </Col>
                                                    <Col sm="4">
                                                        <FormControl variant="standard" style={{ border: "1px soild black" }} fullWidth>
                                                            <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>SPR-3</InputLabel>
                                                            <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={spr3} onChange={(e) => { setSpr3(e.target.value); }}>
                                                                {/* {spr3List.map((e) => (
                                                                    <MenuItem className="drop_value" key={e.id} value={e.Name} >
                                                                        {e.Name}
                                                                    </MenuItem>
                                                                ))} */}
                                                                <MenuItem className="drop_value" value="1" >1</MenuItem>
                                                                <MenuItem className="drop_value" value="2" >2</MenuItem>
                                                                <MenuItem className="drop_value" value="3" >3</MenuItem>
                                                                <MenuItem className="drop_value" value="4" >4</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </Col>
                                                    <Col sm="4">
                                                        <FormControl variant="standard" style={{ border: "1px soild black" }} fullWidth>
                                                            <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>SPR-4</InputLabel>
                                                            <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={spr4} onChange={(e) => { setSpr4(e.target.value); }}>
                                                                {/* {spr4List.map((e) => (
                                                                    <MenuItem className="drop_value" key={e.id} value={e.Name} >
                                                                        {e.Name}
                                                                    </MenuItem>
                                                                ))} */}
                                                                <MenuItem className="drop_value" value="1" >1</MenuItem>
                                                                <MenuItem className="drop_value" value="2" >2</MenuItem>
                                                                <MenuItem className="drop_value" value="3" >3</MenuItem>
                                                                <MenuItem className="drop_value" value="4" >4</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </Col>
                                                    <Col sm="4">
                                                        <FormControl variant="standard" style={{ border: "1px soild black" }} fullWidth>
                                                            <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>SPR-5</InputLabel>
                                                            <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={spr5} onChange={(e) => { setSpr5(e.target.value); }}>
                                                                {/* {spr5List.map((e) => (
                                                                    <MenuItem className="drop_value" key={e.id} value={e.Name} >
                                                                        {e.Name}
                                                                    </MenuItem>
                                                                ))} */}
                                                                <MenuItem className="drop_value" value="1" >1</MenuItem>
                                                                <MenuItem className="drop_value" value="2" >2</MenuItem>
                                                                <MenuItem className="drop_value" value="3" >3</MenuItem>
                                                                <MenuItem className="drop_value" value="4" >4</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </Col>
                                                    <Col sm="4">
                                                        <FormControl variant="standard" style={{ border: "1px soild black" }} fullWidth>
                                                            <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>SPR-6</InputLabel>
                                                            <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={spr6} onChange={(e) => { setSpr6(e.target.value); }}>
                                                                <MenuItem className="drop_value" value="1" >1</MenuItem>
                                                                <MenuItem className="drop_value" value="2" >2</MenuItem>
                                                                <MenuItem className="drop_value" value="3" >3</MenuItem>
                                                                <MenuItem className="drop_value" value="4" >4</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </Col>
                                                </Row>
                                                <Row className="text-center mt-5">
                                                    <Col xs="4" xl="1" className='ms-auto'>
                                                        <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }} onClick={() => { setIndex(7) }}>Back</button>
                                                    </Col>
                                                    <Col xs="4" xl="1" className=''>
                                                        <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }} onClick={SaveSP}>Save</button>
                                                    </Col>
                                                    <Col xs="4" xl="1" className=''>
                                                        <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }} onClick={printReport}>Print</button>
                                                    </Col>
                                                    <Col xs="4" xl="1" className=''>
                                                        <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }}>Edit</button>
                                                    </Col>
                                                    <Col xs="4" xl="1" className=''>
                                                        <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }}>View</button>
                                                    </Col>
                                                    <Col xs="4" xl="1" className=''>
                                                        <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }}>End</button>
                                                    </Col>
                                                    <Col xs="4" xl="1" className=''>
                                                        <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }} onClick={() => { setIndex(9) }}>Next</button>
                                                    </Col>

                                                    {/* <Col xl="1" className='me-auto'>
                                                        <button className="btn-blue-Animal mt-1" onClick={() => setModalShow(false)} style={{ background: "#E16659" }}> Cancel </button>
                                                    </Col> */}
                                                </Row>
                                            </Container>
                                        }
                                        {
                                            index === 9 &&
                                            <Container>
                                                <Row className='mt-4 p-3'>
                                                    {/* <Col sm="4">
                                                        <TextField label="ID" id="standard-basic" value={toxicologyId} onChange={(e) => { setToxicologyId(e.target.value); }} fullWidth variant="standard" />
                                                    </Col>
                                                    <Col sm="4">
                                                        <TextField label="Pat ID No" id="standard-basic" value={toxicologyPatientId} onChange={(e) => { setToxicologyPatientId(e.target.value); }} fullWidth variant="standard" />
                                                    </Col> */}
                                                    <Col sm="4">
                                                        <FormControl variant="standard" style={{ border: "1px soild black" }} fullWidth>
                                                            <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>Tx-Sample-1</InputLabel>
                                                            <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={txSample1} onChange={(e) => { setTxSample1(e.target.value); }}>
                                                                {/* {txSample1List.map((e) => (
                                                                    <MenuItem className="drop_value" key={e.id} value={e.Name} >
                                                                        {e.Name}
                                                                    </MenuItem>
                                                                ))} */}
                                                                <MenuItem className="drop_value" value="1" >1</MenuItem>
                                                                <MenuItem className="drop_value" value="2" >2</MenuItem>
                                                                <MenuItem className="drop_value" value="3" >3</MenuItem>
                                                                <MenuItem className="drop_value" value="4" >4</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </Col>
                                                    <Col sm="4">
                                                        <FormControl variant="standard" style={{ border: "1px soild black" }} fullWidth>
                                                            <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>Tx-Sample-2</InputLabel>
                                                            <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={txSample2} onChange={(e) => { setTxSample2(e.target.value); }}>
                                                                {/* {txSample2List.map((e) => (
                                                                    <MenuItem className="drop_value" key={e.id} value={e.Name} >
                                                                        {e.Name}
                                                                    </MenuItem>
                                                                ))} */}
                                                                <MenuItem className="drop_value" value="1" >1</MenuItem>
                                                                <MenuItem className="drop_value" value="2" >2</MenuItem>
                                                                <MenuItem className="drop_value" value="3" >3</MenuItem>
                                                                <MenuItem className="drop_value" value="4" >4</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </Col>
                                                    <Col sm="4">
                                                        <FormControl variant="standard" style={{ border: "1px soild black" }} fullWidth>
                                                            <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>Tx-Test-1</InputLabel>
                                                            <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={txTest1} onChange={(e) => { setTxTest1(e.target.value); }}>
                                                                {/* {txTest1List.map((e) => (
                                                                    <MenuItem className="drop_value" key={e.id} value={e.Name} >
                                                                        {e.Name}
                                                                    </MenuItem>
                                                                ))} */}
                                                                <MenuItem className="drop_value" value="1" >1</MenuItem>
                                                                <MenuItem className="drop_value" value="2" >2</MenuItem>
                                                                <MenuItem className="drop_value" value="3" >3</MenuItem>
                                                                <MenuItem className="drop_value" value="4" >4</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </Col>
                                                    <Col sm="4">
                                                        <FormControl variant="standard" style={{ border: "1px soild black" }} fullWidth>
                                                            <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>Tx-Test-2</InputLabel>
                                                            <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={txTest2} onChange={(e) => { setTxTest2(e.target.value); }}>
                                                                {/* {txTest2List.map((e) => (
                                                                    <MenuItem className="drop_value" key={e.id} value={e.Name} >
                                                                        {e.Name}
                                                                    </MenuItem>
                                                                ))} */}
                                                                <MenuItem className="drop_value" value="1" >1</MenuItem>
                                                                <MenuItem className="drop_value" value="2" >2</MenuItem>
                                                                <MenuItem className="drop_value" value="3" >3</MenuItem>
                                                                <MenuItem className="drop_value" value="4" >4</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </Col>
                                                    <Col sm="4">
                                                        <FormControl variant="standard" style={{ border: "1px soild black" }} fullWidth>
                                                            <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>Tx-Result-1</InputLabel>
                                                            <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={txResult1} onChange={(e) => { setTxResult1(e.target.value); }}>
                                                                {/* {txTestResult1List.map((e) => (
                                                                    <MenuItem className="drop_value" key={e.id} value={e.Name} >
                                                                        {e.Name}
                                                                    </MenuItem>
                                                                ))} */}
                                                                <MenuItem className="drop_value" value="1" >1</MenuItem>
                                                                <MenuItem className="drop_value" value="2" >2</MenuItem>
                                                                <MenuItem className="drop_value" value="3" >3</MenuItem>
                                                                <MenuItem className="drop_value" value="4" >4</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </Col>
                                                    <Col sm="4">
                                                        <FormControl variant="standard" style={{ border: "1px soild black" }} fullWidth>
                                                            <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>Tx-Result-2</InputLabel>
                                                            <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={txResult2} onChange={(e) => { setTxResult2(e.target.value); }}>
                                                                {/* {txTestResult2list.map((e) => (
                                                                    <MenuItem className="drop_value" key={e.id} value={e.Name} >
                                                                        {e.Name}
                                                                    </MenuItem>
                                                                ))} */}
                                                                <MenuItem className="drop_value" value="1" >1</MenuItem>
                                                                <MenuItem className="drop_value" value="2" >2</MenuItem>
                                                                <MenuItem className="drop_value" value="3" >3</MenuItem>
                                                                <MenuItem className="drop_value" value="4" >4</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </Col>
                                                </Row>
                                                <Row className="text-center mt-5">
                                                    <Col xs="4" xl="1" className='ms-auto'>
                                                        <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }} onClick={() => { setIndex(8) }}>Back</button>
                                                    </Col>
                                                    <Col xs="4" xl="1" className=''>
                                                        <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }} onClick={SaveToxicology}>Save</button>
                                                    </Col>
                                                    <Col xs="4" xl="1" className=''>
                                                        <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }} onClick={printReport}>Print</button>
                                                    </Col>
                                                    <Col xs="4" xl="1" className=''>
                                                        <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }}>Edit</button>
                                                    </Col>
                                                    <Col xs="4" xl="1" className=''>
                                                        <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }}>View</button>
                                                    </Col>
                                                    <Col xs="4" xl="1" className=''>
                                                        <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }}>End</button>
                                                    </Col>
                                                    <Col xs="4" xl="1" className=''>
                                                        <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }} onClick={() => { setIndex(10) }}>Next</button>
                                                    </Col>
                                                    {/* <Col xl="1" className='me-auto'>
                                                        <button className="btn-blue-Animal mt-1" onClick={() => setModalShow(false)} style={{ background: "#E16659" }}> Cancel </button>
                                                    </Col> */}
                                                </Row>
                                            </Container>
                                        }
                                        {
                                            index === 10 &&
                                            <Container>
                                                <Row className='mt-4 p-3'>
                                                    {/* <Col sm="4">
                                                        <TextField label="ID" id="standard-basic" value={milkId} onChange={(e) => { setMilkId(e.target.value); }} fullWidth variant="standard" />
                                                    </Col>
                                                    <Col sm="4">
                                                        <TextField label="Pat ID No" id="standard-basic" value={milkPatientId} onChange={(e) => { setMilkPatientId(e.target.value); }} fullWidth variant="standard" />
                                                    </Col> */}
                                                    <Col sm="4">
                                                        <FormControl variant="standard" style={{ border: "1px soild black" }} fullWidth>
                                                            <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>Milk sample type</InputLabel>
                                                            <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={milkSampleType} onChange={(e) => { setMilkSampleType(e.target.value); }}>
                                                                {/* {milktypeList.map((e) => (
                                                                    <MenuItem className="drop_value" key={e.id} value={e.Name} >
                                                                        {e.Name}
                                                                    </MenuItem>
                                                                ))} */}
                                                                <MenuItem className="drop_value" value="1" >1</MenuItem>
                                                                <MenuItem className="drop_value" value="2" >2</MenuItem>
                                                                <MenuItem className="drop_value" value="3" >3</MenuItem>
                                                                <MenuItem className="drop_value" value="4" >4</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </Col>
                                                    <Col sm="4">
                                                        <TextField label="Fat%" id="standard-basic" value={fat} onChange={(e) => { setFat(e.target.value); }} fullWidth variant="standard" />
                                                    </Col>
                                                    <Col sm="4">
                                                        <TextField label="SNF%" id="standard-basic" value={snf} onChange={(e) => { setSnf(e.target.value); }} fullWidth variant="standard" />
                                                    </Col>
                                                    <Col sm="4">
                                                        <TextField label="TS%" id="standard-basic" value={ts} onChange={(e) => { setTs(e.target.value); }} fullWidth variant="standard" />
                                                    </Col>
                                                    <Col sm="4">
                                                        <FormControl variant="standard" style={{ border: "1px soild black" }} fullWidth>
                                                            <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>Sample condition</InputLabel>
                                                            <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={sampleCondition} onChange={(e) => { setSampleCondition(e.target.value); }}>
                                                                {/* {sampleConditionList.map((e) => (
                                                                    <MenuItem className="drop_value" key={e.id} value={e.Name} >
                                                                        {e.Name}
                                                                    </MenuItem>
                                                                ))} */}
                                                                <MenuItem className="drop_value" value="1" >1</MenuItem>
                                                                <MenuItem className="drop_value" value="2" >2</MenuItem>
                                                                <MenuItem className="drop_value" value="3" >3</MenuItem>
                                                                <MenuItem className="drop_value" value="4" >4</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </Col>
                                                    <Col sm="4">
                                                        <TextField label="Density%" id="standard-basic" value={density} onChange={(e) => { setDensity(e.target.value); }} fullWidth variant="standard" />
                                                    </Col>
                                                    <Col sm="4">
                                                        <TextField label="M-Sp Gravity" id="standard-basic" value={mSpGravity} onChange={(e) => { setDensity(e.target.value); }} fullWidth variant="standard" />
                                                    </Col>
                                                    <Col sm="4">
                                                        <TextField label="Protein%" id="standard-basic" value={milkProtein} onChange={(e) => { setMilkProtein(e.target.value); }} fullWidth variant="standard" />
                                                    </Col>
                                                    <Col sm="4">
                                                        <TextField label="FP-C" id="standard-basic" value={fpc} onChange={(e) => { setFpc(e.target.value); }} fullWidth variant="standard" />
                                                    </Col>
                                                    <Col sm="4">
                                                        <TextField label="EC ms/cm" id="standard-basic" value={ecMsCm} onChange={(e) => { setEcMsCm(e.target.value); }} fullWidth variant="standard" />
                                                    </Col>
                                                    <Col sm="4">
                                                        <TextField label="M-pH" id="standard-basic" value={mph} onChange={(e) => { setMph(e.target.value); }} fullWidth variant="standard" />
                                                    </Col>
                                                    <Col sm="4">
                                                        <TextField label="Temperature-C" id="standard-basic" value={temperatureC} onChange={(e) => { setTemperatureC(e.target.value); }} fullWidth variant="standard" />
                                                    </Col>
                                                    <Col sm="4">
                                                        <TextField label="Lactose%" id="standard-basic" value={Lactose} onChange={(e) => { setLactose(e.target.value); }} fullWidth variant="standard" />
                                                    </Col>
                                                    <Col sm="4">
                                                        <TextField label="MUN mg%" id="standard-basic" value={mun} onChange={(e) => { setMun(e.target.value); }} fullWidth variant="standard" />
                                                    </Col>
                                                    <Col sm="4">
                                                        <TextField label="M-Citrate mg%" id="standard-basic" value={mCitrate} onChange={(e) => { setMCitrate(e.target.value); }} fullWidth variant="standard" />
                                                    </Col>
                                                </Row>
                                                <Row className="text-center mt-5">
                                                    <Col xs="4" xl="1" className='ms-auto'>
                                                        <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }} onClick={() => { setIndex(9) }}>Back</button>
                                                    </Col>
                                                    <Col xs="4" xl="1" className=''>
                                                        <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }} onClick={SaveMilkChemistry}>Save</button>
                                                    </Col>
                                                    <Col xs="4" xl="1" className=''>
                                                        <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }} onClick={printReport}>Print</button>
                                                    </Col>
                                                    <Col xs="4" xl="1" className=''>
                                                        <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }}>Edit</button>
                                                    </Col>
                                                    <Col xs="4" xl="1" className=''>
                                                        <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }}>View</button>
                                                    </Col>
                                                    <Col xs="4" xl="1" className=''>
                                                        <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }}>End</button>
                                                    </Col>
                                                    <Col xs="4" xl="1" className=''>
                                                        <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }} onClick={() => { setIndex(11) }}>Next</button>
                                                    </Col>
                                                    {/* <Col xl="1" className='me-auto'>
                                                        <button className="btn-blue-Animal mt-1" onClick={() => setModalShow(false)} style={{ background: "#E16659" }}> Cancel </button>
                                                    </Col> */}
                                                </Row>
                                            </Container>
                                        }
                                        {
                                            index === 11 &&
                                            <Container>
                                                <Row className='mt-4 p-3'>
                                                    {/* <Col sm="4">
                                                        <TextField label="ID" id="standard-basic" value={modTechId} onChange={(e) => { setModTechId(e.target.value); }} fullWidth variant="standard" />
                                                    </Col>
                                                    <Col sm="4">
                                                        <TextField label="Pat ID No" id="standard-basic" value={modPatientId} onChange={(e) => { setModPatientId(e.target.value); }} fullWidth variant="standard" />
                                                    </Col> */}
                                                    <Col sm="4">
                                                        <FormControl variant="standard" style={{ border: "1px soild black" }} fullWidth>
                                                            <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>Mod Techniques</InputLabel>
                                                            <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={modTechniques} onChange={(e) => { setModTechniques(e.target.value); }}>
                                                                {/* {modTechniquesList.map((e) => (
                                                                    <MenuItem className="drop_value" key={e.id} value={e.Name} >
                                                                        {e.Name}
                                                                    </MenuItem>
                                                                ))} */}
                                                                <MenuItem className="drop_value" value="1" >1</MenuItem>
                                                                <MenuItem className="drop_value" value="2" >2</MenuItem>
                                                                <MenuItem className="drop_value" value="3" >3</MenuItem>
                                                                <MenuItem className="drop_value" value="4" >4</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </Col>
                                                    <Col sm="4">
                                                        <FormControl variant="standard" style={{ border: "1px soild black" }} fullWidth>
                                                            <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>MT-Result</InputLabel>
                                                            <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={mtResult} onChange={(e) => { setMtResult(e.target.value); }}>
                                                                {/* {mtResultList.map((e) => (
                                                                    <MenuItem className="drop_value" key={e.id} value={e.Name} >
                                                                        {e.Name}
                                                                    </MenuItem>
                                                                ))} */}
                                                                <MenuItem className="drop_value" value="1" >1</MenuItem>
                                                                <MenuItem className="drop_value" value="2" >2</MenuItem>
                                                                <MenuItem className="drop_value" value="3" >3</MenuItem>
                                                                <MenuItem className="drop_value" value="4" >4</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </Col>
                                                </Row>
                                                <Row className="text-center mt-5">
                                                    <Col xs="4" xl="1" className='ms-auto'>
                                                        <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }} onClick={() => { setIndex(10) }}>Back</button>
                                                    </Col>
                                                    <Col xs="4" xl="1" className=''>
                                                        <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }} onClick={SaveModTech}>Save</button>
                                                    </Col>
                                                    <Col xs="4" xl="1" className=''>
                                                        <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }} onClick={printReport}>Print</button>
                                                    </Col>
                                                    <Col xs="4" xl="1" className=''>
                                                        <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }}>Edit</button>
                                                    </Col>
                                                    <Col xs="4" xl="1" className=''>
                                                        <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }}>View</button>
                                                    </Col>
                                                    <Col xs="4" xl="1" className=''>
                                                        <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }}>End</button>
                                                    </Col>
                                                    <Col xs="4" xl="1" className=''>
                                                        <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }} onClick={() => { setIndex(12) }}>Next</button>
                                                    </Col>
                                                    {/* <Col xl="1" className='me-auto'>
                                                        <button className="btn-blue-Animal mt-1" onClick={() => setModalShow(false)} style={{ background: "#E16659" }}> Cancel </button>
                                                    </Col> */}
                                                </Row>
                                            </Container>
                                        }
                                        {
                                            index === 12 &&
                                            <Container>
                                                <Row className='mt-4 p-3'>
                                                    {/* <Col sm="4">
                                                        <TextField label="ID" id="standard-basic" value={remarkId} onChange={(e) => { setRemarkId(e.target.value); }} fullWidth variant="standard" />
                                                    </Col>
                                                    <Col sm="4">
                                                        <TextField label="Pat ID No" id="standard-basic" value={remarkPatientId} onChange={(e) => { setRemarkPatientId(e.target.value); }} fullWidth variant="standard" />
                                                    </Col> */}
                                                    <Col sm="4">
                                                        <FormControl variant="standard" style={{ border: "1px soild black" }} fullWidth>
                                                            <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>Remarks</InputLabel>
                                                            <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={remarkModTechnniques} onChange={(e) => { setRemarkTechniques(e.target.value); }}>
                                                                {/* {remarkModtechniquesList.map((e) => (
                                                                    <MenuItem className="drop_value" key={e.id} value={e.Name} >
                                                                        {e.Name}
                                                                    </MenuItem>
                                                                ))} */}
                                                                <MenuItem className="drop_value" value="1" >1</MenuItem>
                                                                <MenuItem className="drop_value" value="2" >2</MenuItem>
                                                                <MenuItem className="drop_value" value="3" >3</MenuItem>
                                                                <MenuItem className="drop_value" value="4" >4</MenuItem>
                                                            </Select>
                                                        </FormControl>
                                                    </Col>
                                                </Row>
                                                <Row className="text-center mt-5">
                                                    <Col xs="4" xl="1" className='ms-auto'>
                                                        <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }} onClick={() => { setIndex(11) }}>Back</button>
                                                    </Col>
                                                    <Col xs="4" className=''>
                                                        <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }} onClick={SaveRemarks}>Save</button>
                                                    </Col>
                                                    <Col xs="4" xl="1" className=''>
                                                        <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }} onClick={printReport}>Print</button>
                                                    </Col>
                                                    <Col xs="4" xl="1" className=''>
                                                        <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }}>Edit</button>
                                                    </Col>
                                                    <Col xs="4" xl="1" className=''>
                                                        <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }}>View</button>
                                                    </Col>
                                                    <Col xs="4" xl="1" className=''>
                                                        <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }}>End</button>
                                                    </Col>

                                                    {/* <Col xl="1" className='me-auto'>
                                                        <button className="btn-blue-Animal mt-1" onClick={() => setModalShow(false)} style={{ background: "#E16659" }}> Cancel </button>
                                                    </Col> */}
                                                </Row>
                                            </Container>
                                        }

                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </Col>
                </Row>
            </Container>
            <div>
                {/* {
                index === 1 &&
                <Modal size="lg" aria-labelledby="contained-modal-title-vcenter" centered show={modalShow} onHide={() => setModalShow(false)}>
                    <Modal.Header style={{ background: "#DFDFDF" }}>
                        <Modal.Title id="contained-modal-title-vcenter">Add Heamto-Cytology</Modal.Title>
                        <img src={closeButton} onClick={() => setModalShow(false)} />
                    </Modal.Header>
                    <Modal.Body>
                        <Container>
                            <Row>
                                <Col sm="4">
                                    <TextField label="ID" id="standard-basic" value={id} onChange={(e) => { setId(e.target.value); }} fullWidth variant="standard" />
                                </Col>
                                <Col sm="4">
                                    <TextField label="Pat ID No" id="standard-basic" value={patId} onChange={(e) => { setPatId(e.target.value); }} fullWidth variant="standard" />
                                </Col>
                                <Col sm="4">
                                    <FormControl variant="standard" style={{ border: "1px soild black" }} fullWidth>
                                        <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>Heamto-Cytology</InputLabel>
                                        <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={hematoCytology} onChange={(e) => { setHematoCytology(e.target.value); }}>
                                            {hematoCytologyList.map((e) => (
                                                <MenuItem className="drop_value" key={e.id} value={e.Name} >
                                                    {e.Name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Col>
                                <Col sm="4">
                                    <FormControl variant="standard" style={{ border: "1px soild black" }} fullWidth>
                                        <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>HCS-Quality</InputLabel>
                                        <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={hcs} onChange={(e) => { setHcs(e.target.value); }}>
                                            {hcsList.map((e) => (
                                                <MenuItem className="drop_value" key={e.id} value={e.Name} >
                                                    {e.Name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Col>
                                <Col sm="4">
                                    <TextField label="HB gm%" id="standard-basic" value={hbGm} onChange={(e) => { setHbGm(e.target.value); }} fullWidth variant="standard" />
                                </Col>
                                <Col sm="4">
                                    <TextField label="PCV" id="standard-basic" value={PCV} onChange={(e) => { setPCV(e.target.value); }} fullWidth variant="standard" />
                                </Col>
                                <Col sm="4">
                                    <TextField label="TEC M/u1" id="standard-basic" value={tecm} onChange={(e) => { setTecm(e.target.value); }} fullWidth variant="standard" />
                                </Col>
                                <Col sm="4">
                                    <TextField label="TLC T/u1" id="standard-basic" value={tlc} onChange={(e) => { setTlc(e.target.value); }} fullWidth variant="standard" />
                                </Col>
                                <Col sm="4">
                                    <TextField label="N%" id="standard-basic" value={n} onChange={(e) => { setN(e.target.value); }} fullWidth variant="standard" />
                                </Col>
                                <Col sm="4">
                                    <TextField label="N/ul" id="standard-basic" value={nUl} onChange={(e) => { setNul(e.target.value); }} fullWidth variant="standard" />
                                </Col>
                                <Col sm="4">
                                    <TextField label="L%" id="standard-basic" value={l} onChange={(e) => { setL(e.target.value); }} fullWidth variant="standard" />
                                </Col>
                                <Col sm="4">
                                    <TextField label="L/u1" id="standard-basic" value={tlc} onChange={(e) => { setTlc(e.target.value); }} fullWidth variant="standard" />
                                </Col>
                                <Col sm="4">
                                    <TextField label="M%" id="standard-basic" value={m} onChange={(e) => { setM(e.target.value); }} fullWidth variant="standard" />
                                </Col>
                                <Col sm="4">
                                    <TextField label="M/u1" id="standard-basic" value={mUl} onChange={(e) => { setMul(e.target.value); }} fullWidth variant="standard" />
                                </Col>
                                <Col sm="4">
                                    <TextField label="E%" id="standard-basic" value={e} onChange={(e) => { setE(e.target.value); }} fullWidth variant="standard" />
                                </Col>
                                <Col sm="4">
                                    <TextField label="E/u1" id="standard-basic" value={eUl} onChange={(e) => { setEul(e.target.value); }} fullWidth variant="standard" />
                                </Col>
                                <Col sm="4">
                                    <TextField label="B%" id="standard-basic" value={b} onChange={(e) => { setB(e.target.value); }} fullWidth variant="standard" />
                                </Col>
                                <Col sm="4">
                                    <TextField label="BC%" id="standard-basic" value={bc} onChange={(e) => { setBc(e.target.value); }} fullWidth variant="standard" />
                                </Col>
                                <Col sm="4">
                                    <TextField label="P1 Pro" id="standard-basic" value={p1pro} onChange={(e) => { setP1pro(e.target.value); }} fullWidth variant="standard" />
                                </Col>
                                <Col sm="4">
                                    <TextField label="Fibrinogen" id="standard-basic" value={fibrinogen} onChange={(e) => { setFibrinogen(e.target.value); }} fullWidth variant="standard" />
                                </Col>
                                <Col sm="4">
                                    <TextField label="PP : Fib" id="standard-basic" value={ppFib} onChange={(e) => { setPpFib(e.target.value); }} fullWidth variant="standard" />
                                </Col>
                                <Col sm="4">
                                    <FormControl variant="standard" style={{ border: "1px soild black" }} fullWidth>
                                        <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>SE-Positive for Infection of</InputLabel>
                                        <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={se} onChange={(e) => { setSe(e.target.value); }}>
                                            {seList.map((e) => (
                                                <MenuItem className="drop_value" key={e.id} value={e.Name} >
                                                    {e.Name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Col>
                                <Col sm="4">
                                    <FormControl variant="standard" style={{ border: "1px soild black" }} fullWidth>
                                        <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>SE-Cell Morphology</InputLabel>
                                        <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={seCell} onChange={(e) => { setSeCell(e.target.value); }}>
                                            {seCellList.map((e) => (
                                                <MenuItem className="drop_value" key={e.id} value={e.Name} >
                                                    {e.Name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Col>
                            </Row>
                        </Container>
                    </Modal.Body>
                    <Modal.Footer>
                        <Container>
                            <Row className="justify-content-center">
                                <Col xl="2">
                                    <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }} onClick={Save}>Save</button>
                                </Col>
                                <Col xl="2">
                                    <button className="btn-blue-Animal mt-1" onClick={() => setModalShow(false)} style={{ background: "#E16659" }}> Cancel </button>
                                </Col>
                            </Row>
                        </Container>
                    </Modal.Footer>
                </Modal>
            } */}
                {/* {
                index === 2 &&
                <Modal size="lg" aria-labelledby="contained-modal-title-vcenter" centered show={modalShow} onHide={() => setModalShow(false)}>
                    <Modal.Header style={{ background: "#DFDFDF" }}>
                        <Modal.Title id="contained-modal-title-vcenter">Add Biochemistry</Modal.Title>
                        <img src={closeButton} onClick={() => setModalShow(false)} />
                    </Modal.Header>
                    <Modal.Body>
                        <Container>
                            <Row>
                                <Col sm="4">
                                    <TextField label="ID" id="standard-basic" value={biochemistryid} onChange={(e) => { setBiochemistryId(e.target.value); }} fullWidth variant="standard" />
                                </Col>
                                <Col sm="4">
                                    <TextField label="Pat ID No" id="standard-basic" value={bioPatId} onChange={(e) => { setBioPatId(e.target.value); }} fullWidth variant="standard" />
                                </Col>
                                <Col sm="4">
                                    <FormControl variant="standard" style={{ border: "1px soild black" }} fullWidth>
                                        <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>Biochemistryy</InputLabel>
                                        <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={biochemistry} onChange={(e) => { setBiochemistry(e.target.value); }}>
                                            {biochemistryList.map((e) => (
                                                <MenuItem className="drop_value" key={e.id} value={e.Name} >
                                                    {e.Name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Col>
                                <Col sm="4">
                                    <FormControl variant="standard" style={{ border: "1px soild black" }} fullWidth>
                                        <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>BCS-Quality</InputLabel>
                                        <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={bioBcs} onChange={(e) => { setBioBcs(e.target.value); }}>
                                            {bcsList.map((e) => (
                                                <MenuItem className="drop_value" key={e.id} value={e.Name} >
                                                    {e.Name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Col>
                                <Col sm="4">
                                    <TextField label="Ca mg%" id="standard-basic" value={caMg} onChange={(e) => { setCamg(e.target.value); }} fullWidth variant="standard" />
                                </Col>
                                <Col sm="4">
                                    <TextField label="Pro g%" id="standard-basic" value={proG} onChange={(e) => { setProG(e.target.value); }} fullWidth variant="standard" />
                                </Col>
                                <Col sm="4">
                                    <TextField label="Chol m%" id="standard-basic" value={tecm} onChange={(e) => { setTecm(e.target.value); }} fullWidth variant="standard" />
                                </Col>
                                <Col sm="4">
                                    <TextField label="P mg%" id="standard-basic" value={pMg} onChange={(e) => { setMg(e.target.value); }} fullWidth variant="standard" />
                                </Col>
                                <Col sm="4">
                                    <TextField label="Alb g%" id="standard-basic" value={albG} onChange={(e) => { setAlbG(e.target.value); }} fullWidth variant="standard" />
                                </Col>
                                <Col sm="4">
                                    <TextField label="ASL IU/L" id="standard-basic" value={asl} onChange={(e) => { setAsl(e.target.value); }} fullWidth variant="standard" />
                                </Col>
                                <Col sm="4">
                                    <TextField label="Mg mg%" id="standard-basic" value={mg} onChange={(e) => { setMg(e.target.value); }} fullWidth variant="standard" />
                                </Col>
                                <Col sm="4">
                                    <TextField label="Fig g%" id="standard-basic" value={flgG} onChange={(e) => { setFlgG(e.target.value); }} fullWidth variant="standard" />
                                </Col>
                                <Col sm="4">
                                    <TextField label="SDH IU/L" id="standard-basic" value={sdh} onChange={(e) => { setSdh(e.target.value); }} fullWidth variant="standard" />
                                </Col>
                                <Col sm="4">
                                    <TextField label="Na mEq/L" id="standard-basic" value={na} onChange={(e) => { setNa(e.target.value); }} fullWidth variant="standard" />
                                </Col>
                                <Col sm="4">
                                    <TextField label="Alb : Glo" id="standard-basic" value={albGlo} onChange={(e) => { setAlbGlo(e.target.value); }} fullWidth variant="standard" />
                                </Col>
                                <Col sm="4">
                                    <TextField label="GGT IU/L" id="standard-basic" value={ggt} onChange={(e) => { setGgt(e.target.value); }} fullWidth variant="standard" />
                                </Col>
                                <Col sm="4">
                                    <TextField label="K mEq/L" id="standard-basic" value={k} onChange={(e) => { setK(e.target.value); }} fullWidth variant="standard" />
                                </Col>
                                <Col sm="4">
                                    <TextField label="PP : Fib" id="standard-basic" value={ppFib} onChange={(e) => { setPpFib(e.target.value); }} fullWidth variant="standard" />
                                </Col>
                                <Col sm="4">
                                    <TextField label="AKP IU/L" id="standard-basic" value={akp} onChange={(e) => { setAkp(e.target.value); }} fullWidth variant="standard" />
                                </Col>
                                <Col sm="4">
                                    <TextField label="C1 mEq/L" id="standard-basic" value={c1} onChange={(e) => { setC1(e.target.value); }} fullWidth variant="standard" />
                                </Col>
                                <Col sm="4">
                                    <TextField label="BUN mg%" id="standard-basic" value={bun} onChange={(e) => { setBun(e.target.value); }} fullWidth variant="standard" />
                                </Col>
                                <Col sm="4">
                                    <TextField label="T Bil mg%" id="standard-basic" value={tBill} onChange={(e) => { setTBill(e.target.value); }} fullWidth variant="standard" />
                                </Col>
                                <Col sm="4">
                                    <TextField label="Glu mg%" id="standard-basic" value={glu} onChange={(e) => { setGlu(e.target.value); }} fullWidth variant="standard" />
                                </Col>
                                <Col sm="4">
                                    <TextField label="Cr mg%" id="standard-basic" value={cr} onChange={(e) => { setCr(e.target.value); }} fullWidth variant="standard" />
                                </Col>
                                <Col sm="4">
                                    <TextField label="D Bil mg" id="standard-basic" value={dBill} onChange={(e) => { setDBill(e.target.value); }} fullWidth variant="standard" />
                                </Col>
                                <Col sm="4">
                                    <FormControl variant="standard" style={{ border: "1px soild black" }} fullWidth>
                                        <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>Randum_RBPT</InputLabel>
                                        <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={randum} onChange={(e) => { setRandum(e.target.value); }}>
                                            {randumList.map((e) => (
                                                <MenuItem className="drop_value" key={e.id} value={e.Name} >
                                                    {e.Name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Col>
                                <Col sm="4">
                                    <FormControl variant="standard" style={{ border: "1px soild black" }} fullWidth>
                                        <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>SE-Cell Morphology</InputLabel>
                                        <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={seCell} onChange={(e) => { setSeCell(e.target.value); }}>
                                            {seCellList.map((e) => (
                                                <MenuItem className="drop_value" key={e.id} value={e.Name} >
                                                    {e.Name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Col>
                            </Row>
                        </Container>
                    </Modal.Body>
                    <Modal.Footer>
                        <Container>
                            <Row className="justify-content-end">
                                <Col xl="2">
                                    <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }} onClick={Save}>Save</button>
                                </Col>
                                <Col xl="2">
                                    <button className="btn-blue-Animal mt-1" onClick={() => setModalShow(false)} style={{ background: "#E16659" }}> Cancel </button>
                                </Col>
                            </Row>
                        </Container>
                    </Modal.Footer>
                </Modal>
            } */}
                {/* {
                index === 3 &&
                <Modal size="lg" aria-labelledby="contained-modal-title-vcenter" centered show={modalShow} onHide={() => setModalShow(false)}>
                    <Modal.Header style={{ background: "#DFDFDF" }}>
                        <Modal.Title id="contained-modal-title-vcenter">Add GI Parasitism </Modal.Title>
                        <img src={closeButton} onClick={() => setModalShow(false)} />
                    </Modal.Header>
                    <Modal.Body>
                        <Container>
                            <Row>
                                <Col sm="4">
                                    <TextField label="ID" id="standard-basic" value={glId} onChange={(e) => { setGlId(e.target.value); }} fullWidth variant="standard" />
                                </Col>
                                <Col sm="4">
                                    <TextField label="Pat ID No" id="standard-basic" value={glPatId} onChange={(e) => { setGlPatId(e.target.value); }} fullWidth variant="standard" />
                                </Col>
                                <Col sm="4">
                                    <FormControl variant="standard" style={{ border: "1px soild black" }} fullWidth>
                                        <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>GI Parasitism</InputLabel>
                                        <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={glParatism} onChange={(e) => { setGlParatism(e.target.value); }}>
                                            {glParatismList.map((e) => (
                                                <MenuItem className="drop_value" key={e.id} value={e.Name} >
                                                    {e.Name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Col>
                                <Col sm="4">
                                    <FormControl variant="standard" style={{ border: "1px soild black" }} fullWidth>
                                        <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>FS-Positive for</InputLabel>
                                        <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={fsPositiveFor} onChange={(e) => { setFsPositiveFor(e.target.value); }}>
                                            {fsPositiveForList.map((e) => (
                                                <MenuItem className="drop_value" key={e.id} value={e.Name} >
                                                    {e.Name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Col>
                                <Col sm="4">
                                    <FormControl variant="standard" style={{ border: "1px soild black" }} fullWidth>
                                        <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>Fecal Sample Exa.</InputLabel>
                                        <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={facelSample} onChange={(e) => { setFacelSample(e.target.value); }}>
                                            {facelSampleList.map((e) => (
                                                <MenuItem className="drop_value" key={e.id} value={e.Name} >
                                                    {e.Name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Col>
                                <Col sm="4">
                                    <TextField label="Nematodes" id="standard-basic" value={nematodes} onChange={(e) => { setNematodes(e.target.value); }} fullWidth variant="standard" />
                                </Col>
                                <Col sm="4">
                                    <TextField label="Trematodes" id="standard-basic" value={tramatodes} onChange={(e) => { setTramatodes(e.target.value); }} fullWidth variant="standard" />
                                </Col>
                                <Col sm="4">
                                    <TextField label="Cystodes" id="standard-basic" value={cystodes} onChange={(e) => { setCystodes(e.target.value); }} fullWidth variant="standard" />
                                </Col>
                                <Col sm="4">
                                    <TextField label="EPG-GT" id="standard-basic" value={epgGt} onChange={(e) => { setEpgGt(e.target.value); }} fullWidth variant="standard" />
                                </Col>
                            </Row>
                        </Container>
                    </Modal.Body>
                    <Modal.Footer>
                        <Container>
                            <Row className="justify-content-end">
                                <Col xl="2">
                                    <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }} onClick={Save}>Save</button>
                                </Col>
                                <Col xl="2">
                                    <button className="btn-blue-Animal mt-1" onClick={() => setModalShow(false)} style={{ background: "#E16659" }}> Cancel </button>
                                </Col>
                            </Row>
                        </Container>
                    </Modal.Footer>
                </Modal>
            } */}
                {/* {
                index === 4 &&
                <Modal size="lg" aria-labelledby="contained-modal-title-vcenter" centered show={modalShow} onHide={() => setModalShow(false)}>
                    <Modal.Header style={{ background: "#DFDFDF" }}>
                        <Modal.Title id="contained-modal-title-vcenter">Add Urinalysis</Modal.Title>
                        <img src={closeButton} onClick={() => setModalShow(false)} />
                    </Modal.Header>
                    <Modal.Body>
                        <Container>
                            <Row>
                                <Col sm="4">
                                    <TextField label="ID" id="standard-basic" value={urinalysisId} onChange={(e) => { setUrinalysisId(e.target.value); }} fullWidth variant="standard" />
                                </Col>
                                <Col sm="4">
                                    <TextField label="Pat ID No" id="standard-basic" value={urinalysisPatId} onChange={(e) => { setUrinalysisPatId(e.target.value); }} fullWidth variant="standard" />
                                </Col>
                                <Col sm="4">
                                    <FormControl variant="standard" style={{ border: "1px soild black" }} fullWidth>
                                        <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>Urinalysis</InputLabel>
                                        <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={urinalysis} onChange={(e) => { setUrinalysis(e.target.value); }}>
                                            {urinalysisList.map((e) => (
                                                <MenuItem className="drop_value" key={e.id} value={e.Name} >
                                                    {e.Name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Col>
                                <Col sm="4">
                                    <FormControl variant="standard" style={{ border: "1px soild black" }} fullWidth>
                                        <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>Glucose</InputLabel>
                                        <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={glucose} onChange={(e) => { setGlucose(e.target.value); }}>
                                            {glucoseList.map((e) => (
                                                <MenuItem className="drop_value" key={e.id} value={e.Name} >
                                                    {e.Name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Col>
                                <Col sm="4">
                                    <FormControl variant="standard" style={{ border: "1px soild black" }} fullWidth>
                                        <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>Ketan</InputLabel>
                                        <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={ketan} onChange={(e) => { setKetan(e.target.value); }}>
                                            {ketanList.map((e) => (
                                                <MenuItem className="drop_value" key={e.id} value={e.Name} >
                                                    {e.Name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Col>
                                <Col sm="4">
                                    <TextField label="Sp Gravity" id="standard-basic" value={spGravity} onChange={(e) => { setSpGravity(e.target.value); }} fullWidth variant="standard" />
                                </Col>
                                <Col sm="4">
                                    <FormControl variant="standard" style={{ border: "1px soild black" }} fullWidth>
                                        <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>Blood</InputLabel>
                                        <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={blood} onChange={(e) => { setBlood(e.target.value); }}>
                                            {bloodList.map((e) => (
                                                <MenuItem className="drop_value" key={e.id} value={e.Name} >
                                                    {e.Name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Col>
                                <Col sm="4">
                                    <TextField label="pH" id="standard-basic" value={ph} onChange={(e) => { setPh(e.target.value); }} fullWidth variant="standard" />
                                </Col>
                                <Col sm="4">
                                    <FormControl variant="standard" style={{ border: "1px soild black" }} fullWidth>
                                        <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>Bilirubin</InputLabel>
                                        <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={bilirubin} onChange={(e) => { setBilirubin(e.target.value); }}>
                                            {billirubinList.map((e) => (
                                                <MenuItem className="drop_value" key={e.id} value={e.Name} >
                                                    {e.Name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Col>
                                <Col sm="4">
                                    <TextField label="US-Exam" id="standard-basic" value={usExam} onChange={(e) => { setUsExam(e.target.value); }} fullWidth variant="standard" />
                                </Col>
                                <Col sm="4">
                                    <FormControl variant="standard" style={{ border: "1px soild black" }} fullWidth>
                                        <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>Protein</InputLabel>
                                        <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={protien} onChange={(e) => { setProtien(e.target.value); }}>
                                            {protienList.map((e) => (
                                                <MenuItem className="drop_value" key={e.id} value={e.Name} >
                                                    {e.Name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Col>
                                <Col sm="4">
                                    <FormControl variant="standard" style={{ border: "1px soild black" }} fullWidth>
                                        <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>Urobilinogen</InputLabel>
                                        <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={urobillinogen} onChange={(e) => { setUrobillinogen(e.target.value); }}>
                                            {urobillinogenList.map((e) => (
                                                <MenuItem className="drop_value" key={e.id} value={e.Name} >
                                                    {e.Name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Col>
                                <Col sm="4">
                                    <FormControl variant="standard" style={{ border: "1px soild black" }} fullWidth>
                                        <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>Nitrite</InputLabel>
                                        <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={nitrite} onChange={(e) => { setNitrite(e.target.value); }}>
                                            {nitriteList.map((e) => (
                                                <MenuItem className="drop_value" key={e.id} value={e.Name} >
                                                    {e.Name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Col>
                                <Col sm="4">
                                    <FormControl variant="standard" style={{ border: "1px soild black" }} fullWidth>
                                        <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>Leukocytes</InputLabel>
                                        <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={leukocytes} onChange={(e) => { setLeukocytes(e.target.value); }}>
                                            {leukocytesList.map((e) => (
                                                <MenuItem className="drop_value" key={e.id} value={e.Name} >
                                                    {e.Name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Col>
                            </Row>
                        </Container>
                    </Modal.Body>
                    <Modal.Footer>
                        <Container>
                            <Row className="justify-content-end">
                                <Col xl="2">
                                    <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }} onClick={Save}>Save</button>
                                </Col>
                                <Col xl="2">
                                    <button className="btn-blue-Animal mt-1" onClick={() => setModalShow(false)} style={{ background: "#E16659" }}> Cancel </button>
                                </Col>
                            </Row>
                        </Container>
                    </Modal.Footer>
                </Modal>
            } */}
                {/* {
                index === 5 &&
                <Modal size="lg" aria-labelledby="contained-modal-title-vcenter" centered show={modalShow} onHide={() => setModalShow(false)}>
                    <Modal.Header style={{ background: "#DFDFDF" }}>
                        <Modal.Title id="contained-modal-title-vcenter">Add Immunology</Modal.Title>
                        <img src={closeButton} onClick={() => setModalShow(false)} />
                    </Modal.Header>
                    <Modal.Body>
                        <Container>
                            <Row>
                                <Col sm="4">
                                    <TextField label="ID" id="standard-basic" value={immunologyId} onChange={(e) => { setImmunologyId(e.target.value); }} fullWidth variant="standard" />
                                </Col>
                                <Col sm="4">
                                    <TextField label="Pat ID No" id="standard-basic" value={immunologyPatId} onChange={(e) => { setImmunologyPatId(e.target.value); }} fullWidth variant="standard" />
                                </Col>
                                <Col sm="4">
                                    <FormControl variant="standard" style={{ border: "1px soild black" }} fullWidth>
                                        <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>Immuno1 Test For-Disease</InputLabel>
                                        <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={immunolTest} onChange={(e) => { setImmunolTest(e.target.value); }}>
                                            {immunolTestList.map((e) => (
                                                <MenuItem className="drop_value" key={e.id} value={e.Name} >
                                                    {e.Name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Col>
                                <Col sm="4">
                                    <FormControl variant="standard" style={{ border: "1px soild black" }} fullWidth>
                                        <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>Br-MRT</InputLabel>
                                        <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={brMrt} onChange={(e) => { setBrMrt(e.target.value); }}>
                                            {brMrtList.map((e) => (
                                                <MenuItem className="drop_value" key={e.id} value={e.Name} >
                                                    {e.Name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Col>
                                <Col sm="4">
                                    <FormControl variant="standard" style={{ border: "1px soild black" }} fullWidth>
                                        <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>Br-ELISA</InputLabel>
                                        <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={brElisa} onChange={(e) => { setBrElisa(e.target.value); }}>
                                            {brElisaList.map((e) => (
                                                <MenuItem className="drop_value" key={e.id} value={e.Name} >
                                                    {e.Name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Col>
                                <Col sm="4">
                                    <FormControl variant="standard" style={{ border: "1px soild black" }} fullWidth>
                                        <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>RBPT</InputLabel>
                                        <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={rbpt} onChange={(e) => { setRbpt(e.target.value); }}>
                                            {rbptList.map((e) => (
                                                <MenuItem className="drop_value" key={e.id} value={e.Name} >
                                                    {e.Name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Col>
                                <Col sm="4">
                                    <FormControl variant="standard" style={{ border: "1px soild black" }} fullWidth>
                                        <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>IRB-ELISA</InputLabel>
                                        <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={irbElisa} onChange={(e) => { setIrbElisa(e.target.value); }}>
                                            {irbElisaList.map((e) => (
                                                <MenuItem className="drop_value" key={e.id} value={e.Name} >
                                                    {e.Name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Col>
                                <Col sm="4">
                                    <FormControl variant="standard" style={{ border: "1px soild black" }} fullWidth>
                                        <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>STAT</InputLabel>
                                        <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={stat} onChange={(e) => { setStat(e.target.value); }}>
                                            {statList.map((e) => (
                                                <MenuItem className="drop_value" key={e.id} value={e.Name} >
                                                    {e.Name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Col>
                                <Col sm="4">
                                    <FormControl variant="standard" style={{ border: "1px soild black" }} fullWidth>
                                        <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>JID-ELISA</InputLabel>
                                        <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={jidElisa} onChange={(e) => { setJidElisa(e.target.value); }}>
                                            {jidElisaList.map((e) => (
                                                <MenuItem className="drop_value" key={e.id} value={e.Name} >
                                                    {e.Name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Col>
                                <Col sm="4">
                                    <FormControl variant="standard" style={{ border: "1px soild black" }} fullWidth>
                                        <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>ID-Tuberuline</InputLabel>
                                        <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={idTuberuline} onChange={(e) => { setIdTuberuline(e.target.value); }}>
                                            {idTuberulineList.map((e) => (
                                                <MenuItem className="drop_value" key={e.id} value={e.Name} >
                                                    {e.Name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Col>
                                <Col sm="4">
                                    <FormControl variant="standard" style={{ border: "1px soild black" }} fullWidth>
                                        <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>ID-Johnine</InputLabel>
                                        <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={idJohnine} onChange={(e) => { setIdJohnine(e.target.value); }}>
                                            {idJohninelist.map((e) => (
                                                <MenuItem className="drop_value" key={e.id} value={e.Name} >
                                                    {e.Name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Col>
                                <Col sm="4">
                                    <FormControl variant="standard" style={{ border: "1px soild black" }} fullWidth>
                                        <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>IBR-FAT</InputLabel>
                                        <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={ibrFat} onChange={(e) => { setIbrFat(e.target.value); }}>
                                            {ibrFatList.map((e) => (
                                                <MenuItem className="drop_value" key={e.id} value={e.Name} >
                                                    {e.Name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Col>
                            </Row>
                        </Container>
                    </Modal.Body>
                    <Modal.Footer>
                        <Container>
                            <Row className="justify-content-end">
                                <Col xl="2">
                                    <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }} onClick={Save}>Save</button>
                                </Col>
                                <Col xl="2">
                                    <button className="btn-blue-Animal mt-1" onClick={() => setModalShow(false)} style={{ background: "#E16659" }}> Cancel </button>
                                </Col>
                            </Row>
                        </Container>
                    </Modal.Footer>
                </Modal>
            } */}
                {/* {
                index === 6 &&
                <Modal size="lg" aria-labelledby="contained-modal-title-vcenter" centered show={modalShow} onHide={() => setModalShow(false)}>
                    <Modal.Header style={{ background: "#DFDFDF" }}>
                        <Modal.Title id="contained-modal-title-vcenter">Add SCM </Modal.Title>
                        <img src={closeButton} onClick={() => setModalShow(false)} />
                    </Modal.Header>
                    <Modal.Body>
                        <Container>
                            <Row>
                                <Col sm="4">
                                    <TextField label="ID" id="standard-basic" value={immunologyId} onChange={(e) => { setImmunologyId(e.target.value); }} fullWidth variant="standard" />
                                </Col>
                                <Col sm="4">
                                    <TextField label="Pat ID No" id="standard-basic" value={immunologyPatId} onChange={(e) => { setImmunologyPatId(e.target.value); }} fullWidth variant="standard" />
                                </Col>
                                <Col sm="4">
                                    <FormControl variant="standard" style={{ border: "1px soild black" }} fullWidth>
                                        <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>SCM sample type</InputLabel>
                                        <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={scmSampleType} onChange={(e) => { setScmSampleType(e.target.value); }}>
                                            {scmList.map((e) => (
                                                <MenuItem className="drop_value" key={e.id} value={e.Name} >
                                                    {e.Name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Col>
                                <Col sm="4">
                                    <FormControl variant="standard" style={{ border: "1px soild black" }} fullWidth>
                                        <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>Mastrip</InputLabel>
                                        <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={mastrip} onChange={(e) => { setMastrip(e.target.value); }}>
                                            {mastripList.map((e) => (
                                                <MenuItem className="drop_value" key={e.id} value={e.Name} >
                                                    {e.Name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Col>
                                <Col sm="4">
                                    <FormControl variant="standard" style={{ border: "1px soild black" }} fullWidth>
                                        <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>CMT</InputLabel>
                                        <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={cmt} onChange={(e) => { setCmt(e.target.value); }}>
                                            {brElisaList.map((e) => (
                                                <MenuItem className="drop_value" key={e.id} value={e.Name} >
                                                    {e.Name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Col>
                                <Col sm="4">
                                    <FormControl variant="standard" style={{ border: "1px soild black" }} fullWidth>
                                        <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>EC</InputLabel>
                                        <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={ec} onChange={(e) => { setEc(e.target.value); }}>
                                            {ecList.map((e) => (
                                                <MenuItem className="drop_value" key={e.id} value={e.Name} >
                                                    {e.Name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Col>
                                <Col sm="4">
                                    <FormControl variant="standard" style={{ border: "1px soild black" }} fullWidth>
                                        <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>SSC Th/ml</InputLabel>
                                        <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={sscTh} onChange={(e) => { setSscTh(e.target.value); }}>
                                            {sscThList.map((e) => (
                                                <MenuItem className="drop_value" key={e.id} value={e.Name} >
                                                    {e.Name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Col>
                            </Row>
                        </Container>
                    </Modal.Body>
                    <Modal.Footer>
                        <Container>
                            <Row className="justify-content-end">
                                <Col xl="2">
                                    <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }} onClick={Save}>Save</button>
                                </Col>
                                <Col xl="2">
                                    <button className="btn-blue-Animal mt-1" onClick={() => setModalShow(false)} style={{ background: "#E16659" }}> Cancel </button>
                                </Col>
                            </Row>
                        </Container>
                    </Modal.Footer>
                </Modal>
            } */}
                {/* {
                index === 7 &&
                <Modal size="lg" aria-labelledby="contained-modal-title-vcenter" centered show={modalShow} onHide={() => setModalShow(false)}>
                    <Modal.Header style={{ background: "#DFDFDF" }}>
                        <Modal.Title id="contained-modal-title-vcenter">Add Antigram</Modal.Title>
                        <img src={closeButton} onClick={() => setModalShow(false)} />
                    </Modal.Header>
                    <Modal.Body>
                        <Container>
                            <Row>
                                <Col sm="4">
                                    <TextField label="ID" id="standard-basic" value={antigramId} onChange={(e) => { setAntigramId(e.target.value); }} fullWidth variant="standard" />
                                </Col>
                                <Col sm="4">
                                    <TextField label="Pat ID No" id="standard-basic" value={antigramPatId} onChange={(e) => { setAntigramPatId(e.target.value); }} fullWidth variant="standard" />
                                </Col>
                                <Col sm="4">
                                    <FormControl variant="standard" style={{ border: "1px soild black" }} fullWidth>
                                        <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>ABS-Sample</InputLabel>
                                        <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={absSample} onChange={(e) => { setAbsSample(e.target.value); }}>
                                            {absSampleList.map((e) => (
                                                <MenuItem className="drop_value" key={e.id} value={e.Name} >
                                                    {e.Name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Col>
                                <Col sm="4">
                                    <FormControl variant="standard" style={{ border: "1px soild black" }} fullWidth>
                                        <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>ABS smaple quality</InputLabel>
                                        <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={absSampleList} onChange={(e) => { setAbsSampleList(e.target.value); }}>
                                            {absSampleList.map((e) => (
                                                <MenuItem className="drop_value" key={e.id} value={e.Name} >
                                                    {e.Name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Col>
                                <Col sm="4">
                                    <FormControl variant="standard" style={{ border: "1px soild black" }} fullWidth>
                                        <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>Pathogen ID</InputLabel>
                                        <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={pathogenId} onChange={(e) => { setPathogenId(e.target.value); }}>
                                            {pathogenList.map((e) => (
                                                <MenuItem className="drop_value" key={e.id} value={e.Name} >
                                                    {e.Name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Col>
                                <Col sm="4">
                                    <FormControl variant="standard" style={{ border: "1px soild black" }} fullWidth>
                                        <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>Antibiotics</InputLabel>
                                        <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={antibiotics} onChange={(e) => { setAntibiotics(e.target.value); }}>
                                            {antibioticsList.map((e) => (
                                                <MenuItem className="drop_value" key={e.id} value={e.Name} >
                                                    {e.Name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Col>
                                <Col sm="4">
                                    <FormControl variant="standard" style={{ border: "1px soild black" }} fullWidth>
                                        <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>BK-DDMethod</InputLabel>
                                        <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={bkMethod} onChange={(e) => { setBkMethod(e.target.value); }}>
                                            {bkMethodList.map((e) => (
                                                <MenuItem className="drop_value" key={e.id} value={e.Name} >
                                                    {e.Name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Col>
                            </Row>
                        </Container>
                    </Modal.Body>
                    <Modal.Footer>
                        <Container>
                            <Row className="justify-content-end">
                                <Col xl="2">
                                    <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }} onClick={Save}>Save</button>
                                </Col>
                                <Col xl="2">
                                    <button className="btn-blue-Animal mt-1" onClick={() => setModalShow(false)} style={{ background: "#E16659" }}> Cancel </button>
                                </Col>
                            </Row>
                        </Container>
                    </Modal.Footer>
                </Modal>
            } */}
                {/* {
                index === 8 &&
                <Modal size="lg" aria-labelledby="contained-modal-title-vcenter" centered show={modalShow} onHide={() => setModalShow(false)}>
                    <Modal.Header style={{ background: "#DFDFDF" }}>
                        <Modal.Title id="contained-modal-title-vcenter">Add SP</Modal.Title>
                        <img src={closeButton} onClick={() => setModalShow(false)} />
                    </Modal.Header>
                    <Modal.Body>
                        <Container>
                            <Row>
                                <Col sm="4">
                                    <TextField label="ID" id="standard-basic" value={spId} onChange={(e) => { setSpId(e.target.value); }} fullWidth variant="standard" />
                                </Col>
                                <Col sm="4">
                                    <TextField label="Pat ID No" id="standard-basic" value={spPatIdNo} onChange={(e) => { setSpPatIdNo(e.target.value); }} fullWidth variant="standard" />
                                </Col>
                                <Col sm="4">
                                    <FormControl variant="standard" style={{ border: "1px soild black" }} fullWidth>
                                        <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>Special Path</InputLabel>
                                        <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={specialPath} onChange={(e) => { setSpecialPath(e.target.value); }}>
                                            {specialPathList.map((e) => (
                                                <MenuItem className="drop_value" key={e.id} value={e.Name} >
                                                    {e.Name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Col>
                                <Col sm="4">
                                    <FormControl variant="standard" style={{ border: "1px soild black" }} fullWidth>
                                        <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>SPS-2</InputLabel>
                                        <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={sps2} onChange={(e) => { setSps2(e.target.value); }}>
                                            {sps2List.map((e) => (
                                                <MenuItem className="drop_value" key={e.id} value={e.Name} >
                                                    {e.Name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Col>
                                <Col sm="4">
                                    <FormControl variant="standard" style={{ border: "1px soild black" }} fullWidth>
                                        <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>SPS-3</InputLabel>
                                        <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={sps3} onChange={(e) => { setSps3(e.target.value); }}>
                                            {sps3list.map((e) => (
                                                <MenuItem className="drop_value" key={e.id} value={e.Name} >
                                                    {e.Name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Col>
                                <Col sm="4">
                                    <FormControl variant="standard" style={{ border: "1px soild black" }} fullWidth>
                                        <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>SPS-4</InputLabel>
                                        <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={sps4} onChange={(e) => { setSps4(e.target.value); }}>
                                            {sps4List.map((e) => (
                                                <MenuItem className="drop_value" key={e.id} value={e.Name} >
                                                    {e.Name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Col>
                                <Col sm="4">
                                    <FormControl variant="standard" style={{ border: "1px soild black" }} fullWidth>
                                        <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>SPS-5</InputLabel>
                                        <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={sps5} onChange={(e) => { setSps5(e.target.value); }}>
                                            {sps5List.map((e) => (
                                                <MenuItem className="drop_value" key={e.id} value={e.Name} >
                                                    {e.Name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Col>
                                <Col sm="4">
                                    <FormControl variant="standard" style={{ border: "1px soild black" }} fullWidth>
                                        <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>SPS-6</InputLabel>
                                        <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={sps6} onChange={(e) => { setSps6(e.target.value); }}>
                                            {sps6List.map((e) => (
                                                <MenuItem className="drop_value" key={e.id} value={e.Name} >
                                                    {e.Name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Col>
                                <Col sm="4">
                                    <FormControl variant="standard" style={{ border: "1px soild black" }} fullWidth>
                                        <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>SP-Resu</InputLabel>
                                        <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={spResu} onChange={(e) => { setSpResu(e.target.value); }}>
                                            {spResuList.map((e) => (
                                                <MenuItem className="drop_value" key={e.id} value={e.Name} >
                                                    {e.Name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Col>
                                <Col sm="4">
                                    <FormControl variant="standard" style={{ border: "1px soild black" }} fullWidth>
                                        <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>SPR-2</InputLabel>
                                        <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={spr2} onChange={(e) => { setSpr2(e.target.value); }}>
                                            {spr2List.map((e) => (
                                                <MenuItem className="drop_value" key={e.id} value={e.Name} >
                                                    {e.Name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Col>
                                <Col sm="4">
                                    <FormControl variant="standard" style={{ border: "1px soild black" }} fullWidth>
                                        <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>SPR-3</InputLabel>
                                        <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={spr3} onChange={(e) => { setSpr3(e.target.value); }}>
                                            {spr3List.map((e) => (
                                                <MenuItem className="drop_value" key={e.id} value={e.Name} >
                                                    {e.Name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Col>
                                <Col sm="4">
                                    <FormControl variant="standard" style={{ border: "1px soild black" }} fullWidth>
                                        <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>SPR-4</InputLabel>
                                        <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={spr4} onChange={(e) => { setSpr4(e.target.value); }}>
                                            {spr4List.map((e) => (
                                                <MenuItem className="drop_value" key={e.id} value={e.Name} >
                                                    {e.Name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Col>
                                <Col sm="4">
                                    <FormControl variant="standard" style={{ border: "1px soild black" }} fullWidth>
                                        <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>SPR-5</InputLabel>
                                        <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={spr5} onChange={(e) => { setSpr5(e.target.value); }}>
                                            {spr5List.map((e) => (
                                                <MenuItem className="drop_value" key={e.id} value={e.Name} >
                                                    {e.Name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Col>
                                <Col sm="4">
                                    <FormControl variant="standard" style={{ border: "1px soild black" }} fullWidth>
                                        <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>SPR-6</InputLabel>
                                        <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={spr6} onChange={(e) => { setSpr6(e.target.value); }}>
                                            {spr6List.map((e) => (
                                                <MenuItem className="drop_value" key={e.id} value={e.Name} >
                                                    {e.Name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Col>
                            </Row>
                        </Container>
                    </Modal.Body>
                    <Modal.Footer>
                        <Container>
                            <Row className="justify-content-end">
                                <Col xl="2">
                                    <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }} onClick={Save}>Save</button>
                                </Col>
                                <Col xl="2">
                                    <button className="btn-blue-Animal mt-1" onClick={() => setModalShow(false)} style={{ background: "#E16659" }}> Cancel </button>
                                </Col>
                            </Row>
                        </Container>
                    </Modal.Footer>
                </Modal>
            } */}
                {/* {
                index === 9 &&
                <Modal size="lg" aria-labelledby="contained-modal-title-vcenter" centered show={modalShow} onHide={() => setModalShow(false)}>
                    <Modal.Header style={{ background: "#DFDFDF" }}>
                        <Modal.Title id="contained-modal-title-vcenter">Add Toxicology </Modal.Title>
                        <img src={closeButton} onClick={() => setModalShow(false)} />
                    </Modal.Header>
                    <Modal.Body>
                        <Container>
                            <Row>
                                <Col sm="4">
                                    <TextField label="ID" id="standard-basic" value={toxicologyId} onChange={(e) => { setToxicologyId(e.target.value); }} fullWidth variant="standard" />
                                </Col>
                                <Col sm="4">
                                    <TextField label="Pat ID No" id="standard-basic" value={toxicologyPatientId} onChange={(e) => { setToxicologyPatientId(e.target.value); }} fullWidth variant="standard" />
                                </Col>
                                <Col sm="4">
                                    <FormControl variant="standard" style={{ border: "1px soild black" }} fullWidth>
                                        <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>Tx-Sample-1</InputLabel>
                                        <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={txSample1} onChange={(e) => { setTxSample1(e.target.value); }}>
                                            {txSample1List.map((e) => (
                                                <MenuItem className="drop_value" key={e.id} value={e.Name} >
                                                    {e.Name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Col>
                                <Col sm="4">
                                    <FormControl variant="standard" style={{ border: "1px soild black" }} fullWidth>
                                        <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>Tx-Sample-2</InputLabel>
                                        <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={txSample2} onChange={(e) => { setTxSample2(e.target.value); }}>
                                            {txSample2List.map((e) => (
                                                <MenuItem className="drop_value" key={e.id} value={e.Name} >
                                                    {e.Name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Col>
                                <Col sm="4">
                                    <FormControl variant="standard" style={{ border: "1px soild black" }} fullWidth>
                                        <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>Tx-Test-1</InputLabel>
                                        <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={txTest1} onChange={(e) => { setTxTest1(e.target.value); }}>
                                            {txTest1List.map((e) => (
                                                <MenuItem className="drop_value" key={e.id} value={e.Name} >
                                                    {e.Name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Col>
                                <Col sm="4">
                                    <FormControl variant="standard" style={{ border: "1px soild black" }} fullWidth>
                                        <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>Tx-Test-2</InputLabel>
                                        <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={txTest2} onChange={(e) => { setTxTest2(e.target.value); }}>
                                            {txTest2List.map((e) => (
                                                <MenuItem className="drop_value" key={e.id} value={e.Name} >
                                                    {e.Name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Col>
                                <Col sm="4">
                                    <FormControl variant="standard" style={{ border: "1px soild black" }} fullWidth>
                                        <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>Tx-Result-1</InputLabel>
                                        <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={txResult1} onChange={(e) => { setTxResult1(e.target.value); }}>
                                            {txTestResult1List.map((e) => (
                                                <MenuItem className="drop_value" key={e.id} value={e.Name} >
                                                    {e.Name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Col>
                                <Col sm="4">
                                    <FormControl variant="standard" style={{ border: "1px soild black" }} fullWidth>
                                        <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>Tx-Result-2</InputLabel>
                                        <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={txResult2} onChange={(e) => { setTxResult2(e.target.value); }}>
                                            {txTestResult2list.map((e) => (
                                                <MenuItem className="drop_value" key={e.id} value={e.Name} >
                                                    {e.Name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Col>
                            </Row>
                        </Container>
                    </Modal.Body>
                    <Modal.Footer>
                        <Container>
                            <Row className="justify-content-end">
                                <Col xl="2">
                                    <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }} onClick={Save}>Save</button>
                                </Col>
                                <Col xl="2">
                                    <button className="btn-blue-Animal mt-1" onClick={() => setModalShow(false)} style={{ background: "#E16659" }}> Cancel </button>
                                </Col>
                            </Row>
                        </Container>
                    </Modal.Footer>
                </Modal>
            } */}
                {/* {
                index === 10 &&
                <Modal size="lg" aria-labelledby="contained-modal-title-vcenter" centered show={modalShow} onHide={() => setModalShow(false)}>
                    <Modal.Header style={{ background: "#DFDFDF" }}>
                        <Modal.Title id="contained-modal-title-vcenter">Add Milk Chemistry</Modal.Title>
                        <img src={closeButton} onClick={() => setModalShow(false)} />
                    </Modal.Header>
                    <Modal.Body>
                        <Container>
                            <Row>
                                <Col sm="4">
                                    <TextField label="ID" id="standard-basic" value={milkId} onChange={(e) => { setMilkId(e.target.value); }} fullWidth variant="standard" />
                                </Col>
                                <Col sm="4">
                                    <TextField label="Pat ID No" id="standard-basic" value={milkPatientId} onChange={(e) => { setMilkPatientId(e.target.value); }} fullWidth variant="standard" />
                                </Col>
                                <Col sm="4">
                                    <FormControl variant="standard" style={{ border: "1px soild black" }} fullWidth>
                                        <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>Milk sample type</InputLabel>
                                        <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={milkSampleType} onChange={(e) => { setMilkSampleType(e.target.value); }}>
                                            {milktypeList.map((e) => (
                                                <MenuItem className="drop_value" key={e.id} value={e.Name} >
                                                    {e.Name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Col>
                                <Col sm="4">
                                    <TextField label="Fat%" id="standard-basic" value={fat} onChange={(e) => { setFat(e.target.value); }} fullWidth variant="standard" />
                                </Col>
                                <Col sm="4">
                                    <TextField label="SNF%" id="standard-basic" value={snf} onChange={(e) => { setSnf(e.target.value); }} fullWidth variant="standard" />
                                </Col>
                                <Col sm="4">
                                    <FormControl variant="standard" style={{ border: "1px soild black" }} fullWidth>
                                        <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>Sample condition</InputLabel>
                                        <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={sampleCondition} onChange={(e) => { setSampleCondition(e.target.value); }}>
                                            {sampleConditionList.map((e) => (
                                                <MenuItem className="drop_value" key={e.id} value={e.Name} >
                                                    {e.Name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Col>
                                <Col sm="4">
                                    <TextField label="Density%" id="standard-basic" value={density} onChange={(e) => { setDensity(e.target.value); }} fullWidth variant="standard" />
                                </Col>
                                <Col sm="4">
                                    <TextField label="M-Sp Gravity" id="standard-basic" value={mSpGravity} onChange={(e) => { setDensity(e.target.value); }} fullWidth variant="standard" />
                                </Col>
                                <Col sm="4">
                                    <TextField label="Density%" id="standard-basic" value={density} onChange={(e) => { setDensity(e.target.value); }} fullWidth variant="standard" />
                                </Col>
                            </Row>
                        </Container>
                    </Modal.Body>
                    <Modal.Footer>
                        <Container>
                            <Row className="justify-content-end">
                                <Col xl="2">
                                    <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }} onClick={Save}>Save</button>
                                </Col>
                                <Col xl="2">
                                    <button className="btn-blue-Animal mt-1" onClick={() => setModalShow(false)} style={{ background: "#E16659" }}> Cancel </button>
                                </Col>
                            </Row>
                        </Container>
                    </Modal.Footer>
                </Modal>
            } */}
                {/* {
                index === 11 &&
                <Modal size="lg" aria-labelledby="contained-modal-title-vcenter" centered show={modalShow} onHide={() => setModalShow(false)}>
                    <Modal.Header style={{ background: "#DFDFDF" }}>
                        <Modal.Title id="contained-modal-title-vcenter">Add Mod Tech </Modal.Title>
                        <img src={closeButton} onClick={() => setModalShow(false)} />
                    </Modal.Header>
                    <Modal.Body>
                        <Container>
                            <Row>
                                <Col sm="4">
                                    <TextField label="ID" id="standard-basic" value={milkId} onChange={(e) => { setMilkId(e.target.value); }} fullWidth variant="standard" />
                                </Col>
                                <Col sm="4">
                                    <TextField label="Pat ID No" id="standard-basic" value={milkPatientId} onChange={(e) => { setMilkPatientId(e.target.value); }} fullWidth variant="standard" />
                                </Col>
                                <Col sm="4">
                                    <FormControl variant="standard" style={{ border: "1px soild black" }} fullWidth>
                                        <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>Milk sample type</InputLabel>
                                        <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={milkSampleType} onChange={(e) => { setMilkSampleType(e.target.value); }}>
                                            {milktypeList.map((e) => (
                                                <MenuItem className="drop_value" key={e.id} value={e.Name} >
                                                    {e.Name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Col>
                                <Col sm="4">
                                    <TextField label="Fat%" id="standard-basic" value={fat} onChange={(e) => { setFat(e.target.value); }} fullWidth variant="standard" />
                                </Col>
                                <Col sm="4">
                                    <TextField label="SNF%" id="standard-basic" value={snf} onChange={(e) => { setSnf(e.target.value); }} fullWidth variant="standard" />
                                </Col>
                                <Col sm="4">
                                    <FormControl variant="standard" style={{ border: "1px soild black" }} fullWidth>
                                        <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>Sample condition</InputLabel>
                                        <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={sampleCondition} onChange={(e) => { setSampleCondition(e.target.value); }}>
                                            {sampleConditionList.map((e) => (
                                                <MenuItem className="drop_value" key={e.id} value={e.Name} >
                                                    {e.Name}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Col>
                                <Col sm="4">
                                    <TextField label="Density%" id="standard-basic" value={density} onChange={(e) => { setDensity(e.target.value); }} fullWidth variant="standard" />
                                </Col>
                                <Col sm="4">
                                    <TextField label="M-Sp Gravity" id="standard-basic" value={mSpGravity} onChange={(e) => { setDensity(e.target.value); }} fullWidth variant="standard" />
                                </Col>
                                <Col sm="4">
                                    <TextField label="Density%" id="standard-basic" value={density} onChange={(e) => { setDensity(e.target.value); }} fullWidth variant="standard" />
                                </Col>
                            </Row>
                        </Container>
                    </Modal.Body>
                    <Modal.Footer>
                        <Container>
                            <Row className="justify-content-end">
                                <Col xl="2">
                                    <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }} onClick={Save}>Save</button>
                                </Col>
                                <Col xl="2">
                                    <button className="btn-blue-Animal mt-1" onClick={() => setModalShow(false)} style={{ background: "#E16659" }}> Cancel </button>
                                </Col>
                            </Row>
                        </Container>
                    </Modal.Footer>
                </Modal>
            }
            {
                index === 12 &&
                <Modal size="lg" aria-labelledby="contained-modal-title-vcenter" centered show={modalShow} onHide={() => setModalShow(false)}>
                    <Modal.Header style={{ background: "#DFDFDF" }}>
                        <Modal.Title id="contained-modal-title-vcenter">Add Remarks</Modal.Title>
                        <img src={closeButton} onClick={() => setModalShow(false)} />
                    </Modal.Header>
                    <Modal.Body>
                        <Container>
                            <Row>
                                <Col sm="4">
                                    <TextField label="ID" id="standard-basic" value={id} onChange={(e) => { setId(e.target.value); }} fullWidth variant="standard" />
                                </Col>
                            </Row>
                        </Container>
                    </Modal.Body>
                    <Modal.Footer>
                        <Container>
                            <Row className="justify-content-end">
                                <Col xl="2">
                                    <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }} onClick={Save}>Save</button>
                                </Col>
                                <Col xl="2">
                                    <button className="btn-blue-Animal mt-1" onClick={() => setModalShow(false)} style={{ background: "#E16659" }}> Cancel </button>
                                </Col>
                            </Row>
                        </Container>
                    </Modal.Footer>
                </Modal>
            } */}
            </div>


            {show && <Offcanvas className="hide_Navbar" show={show} onHide={handleClose}><Container><Row><Col><Sidenav /></Col></Row></Container></Offcanvas>}

            {isLoading ?
                <div className="circleText">
                    <div className="progressText">{progressData}</div>
                    <div className="">
                        <span className="loader-circle-13"></span>
                    </div>
                </div>
                : false
            }

            <ToastContainer
                position="top-center"
                autoClose={2000}
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
                style={{ fontSize: "14px" }}
            />

        </>
    )
};
