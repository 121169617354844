import React, { useEffect, useRef, useState } from "react";
import './alaram.css';
import { Col, Container, Row, Offcanvas } from "react-bootstrap";
import { FormControlLabel, Radio, RadioGroup, TextField, FormControl, InputLabel, Select, MenuItem } from '@mui/material';

import Sidenav from "../../Componet/Sidenav/Sidenav";
import menu from '../../Photo/menu.svg'
import profile from '../../Photo/profile.svg';

import Dropdown from "../../Componet/Dropdown/Dropdown";
import Table from "../../Componet/DataTable/Table";
import { STORAGE_KEY } from "../../constant/common";
import { MdOutlineVpnKey } from "react-icons/md";
import { GoSignOut } from "react-icons/go";

import { API_URLS, serviceUrl } from "../../API/Api";
import { borderRadius, margin } from '@mui/system';
import { IoPlayForwardSharp } from "react-icons/io5";
import { useReactToPrint } from "react-to-print";
import jsPDF from 'jspdf'
import autoTable from 'jspdf-autotable'
import { useNavigate } from "react-router-dom";
import { downloadExcel } from 'react-export-table-to-excel';
import Input from "../../Componet/InputFild/Input";
import Searcable from "../../Componet/Dropdown/Searcable";
import BackBtn from "../../Componet/Button/BackBtn";

const { ActionPlanApi } = API_URLS;

export default function DailyActionPlan() {
  const added_by = localStorage.getItem(STORAGE_KEY.jwtTOKEN);
  const navigate = useNavigate()
  const logOut = () => { navigate("/") }

  async function getTab(credentials) {
    return fetch(ActionPlanApi.GetTabs, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        "Accept": "application/json",
        "Authorization": `Bearer ${added_by}`,
      },
      body: JSON.stringify(credentials)
    })
      .then(res => res.json());
  }

  const API_KEY = serviceUrl;
  // const API_KEY = "https://hais.hap.in";
  const selectedToDate = new Date().toISOString().slice(0, 10);

  const [select, setSelect] = useState(null);
  const [date, setDate] = useState("")
  const [tab, setTab] = useState("")
  const [id, setId] = useState("")
  const [breed, setBreed] = useState("")
  const [search, setSearch] = useState("")
  const [progressData, setProgressData] = useState("0%");
  const [searchFilter, setSearchFilter] = useState("")

  const [heatCheck, setHeatCheck] = useState(true)
  const [firstCheck, setFirstCheck] = useState(true)
  const [nonCheck, setNonCheck] = useState(true)
  const [pd1, setPd1] = useState(true)
  const [pd2, setPd2] = useState(true)
  const [Milking, setMilking] = useState(true)
  const [dryCheck, setDryCheck] = useState(true)
  const [calvingCheck, setCalvingCheck] = useState(true)

  const [headerShow, setHeaderShow] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => { setShow(false); }
  const handleShow = () => { setHeaderShow(!headerShow); }
  const [modalShow, setModalShow] = useState(false);
  const [click, setClick] = useState(true)
  const [heat, setHeat] = useState(true);
  const [calving, setCalving] = useState(false);
  const [dry, setDry] = useState(false);
  const [aramlist, setaramlist] = useState(false);
  const [backBtn, setBackBtn] = useState(false)
  const [dataColum, setDataColum] = useState(false)
  const [expandColum, setExpandColum] = useState(false)

  const [tabList, setTabList] = useState([]);
  const [fatchTabList, setFatchTabList] = useState([]);
  const [subFatchList, setSubFatchList] = useState([])
  const [alramList, setAlramList] = useState([])
  const [rowsList, setRowsList] = useState([]);
  const [breedList, setBreedList] = useState([])

  const [heatCheckList, setHeatCheckList] = useState([])
  const [firstChecklist, setFirstChecklist] = useState([])
  const [nonCheckList, setNonCheckList] = useState([])
  const [pd1CheckList, setPd1CheckList] = useState([])
  const [pd2CheckList, setPd2CheckList] = useState([])
  const [calvingList, setCalvingList] = useState([])
  const [milkingList, setMilkingList] = useState([])
  const [dryList, setDryList] = useState([])

  const actionRef = useRef();

  // const handlePrint = useReactToPrint({
  //   content: () => actionRef.current,
  // });

  const handlePrint = () => {
    const doc = new jsPDF();
    const data = heatCheckList.map((e) => e["Heat Check"])
    const data1 = heatCheckList.map((e) => e["Heat Check"])
    const data2 = heatCheckList.map((e) => e["Heat Check"])

    // const data1 = alramList.map((e) => (Object.keys(e)))
    // console.log(data)
    autoTable(doc, {
      theme: "grid",
      columns: [data, data1, data2],
      head: [['Heat Check', 'First Heat', 'Non Return', "Check PD1", "Check PD2", "Expected Calving", "Expected Milking", "Expected Dry"]],
      body: [data],
      // body: data[0],
    })
    // doc.autoTable({
    //   columns:heatCheckList,
    //   body:heatCheckList
    // });
    doc.save('table.pdf');
  }

  const menuitm = ["Center", "Manager", "District", "Doctor/VO ", "Incharge", "Taluka", "Route", "Hamlet", "Village", "DCS/Lot", "EO", "Paravet", "Field Staff", "Project Wise"];

  const selectFn = (e) => {
    console.log(e);
    setSelect(e);
    if (e) {
      fetch(`${API_KEY}/reportUnion/fillTab`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          "Accept": "application/json",
          "Authorization": `Bearer ${added_by}`,
        },
        body: JSON.stringify({ Lot: 0, Route: 0, Zone: 0, tab: e })
      })
        .then(res => res.json())
        .then((result) => { setTabList(result); })

      fetch(`${API_KEY}/reportUnion/fillTab`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          "Accept": "application/json",
          "Authorization": `Bearer ${added_by}`,
        },
        body: JSON.stringify({ Lot: 0, Route: 0, Zone: 0, tab: "Supervisor" })
      })
        .then(res => res.json())
        .then((result) => { setSubFatchList(result); })
    }
  }

  const selectFn1 = (e) => {
    console.log(e)
    if (e) {
      fetch(`${API_KEY}/reportUnion/fillTab`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          "Accept": "application/json",
          "Authorization": `Bearer ${added_by}`,
        },
        body: JSON.stringify({ Lot: 0, Route: 0, Zone: e, tab: "Paravet" })
      })
        .then(res => res.json())
        .then((result) => { setTabList(result); })
    }
  }

  const SelectedRowFn = (rows) => {
    setRowsList(rows.selectedRows);
  }

  const getAlarmData = async () => {
    const res = await getTab()
    if (res) { setFatchTabList(res) }
  }

  useEffect(() => {
    getAlarmData()
  }, [])
  console.log(select);

  const View = () => {
    setIsLoading(true);
    let ids = (rowsList.map(i => i.ID)).join(',');
    console.log(ids)
    if (ids) {
      fetch(`${API_KEY}/action/action`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          "Accept": "application/json",
          "Authorization": `Bearer ${added_by}`
        },
        body: JSON.stringify({ copCom: "Com", tab: select, tabIds: ids })
      })
        .then(response => {
          const contentLength = response.headers.get('content-length');
          let loaded = 0;
          return new Response(
            new ReadableStream({
              start(controller) {
                const reader = response.body.getReader();
                read();
                function read() {
                  reader.read()
                    .then((progressEvent) => {
                      if (progressEvent.done) {
                        controller.close();
                        return;
                      }
                      loaded += progressEvent.value.byteLength;
                      const percentageComplete = Math.round(loaded / contentLength * 100) + '%';
                      setProgressData(percentageComplete)
                      controller.enqueue(progressEvent.value);
                      read();
                    })
                }
              }
            })
          );
        })
        .then(res => res.json())
        .then((result) => {
          // console.log(result[0][0]["Days from A.I"]);
          console.log(result)
          setHeatCheckList(result[0]);
          setFirstChecklist(result[1]);
          setNonCheckList(result[2]);
          setPd1CheckList(result[3]);
          setPd2CheckList(result[4]);
          setCalvingList(result[5]);
          setMilkingList(result[6]);
          setDryList(result[7]);
          setAlramList(result);
          setDataColum(true);
          setIsLoading(false);
          setProgressData("0%")
        },
          (err) => {
            setIsLoading(false);
          })
    }
  }

  const colums = [
    {
      name: "ID",
      selector: "ID",
      width: "80px",
    },
    {
      name: " Name",
      selector: "Center Name",
    },
  ];

  const doctorColms = [
    {
      name: "ID",
      selector: "ID",
      width: "80px",
    },
    {
      name: "Doctor Name",
      selector: "Doctor Name",
    },
  ];

  const Districtcolums = [
    {
      name: "ID",
      selector: "ID",
      width: "80px",
    },
    {
      name: "Taluka Name",
      selector: "Taluka Name",
    },
  ];
  const dsccolums = [
    {
      name: "Lot No",
      selector: "Lot No",
      width: "80px"
    },
    {
      name: "ID",
      selector: "ID",
      width: "80px",
    },
    {
      name: "Lot Name",
      selector: "Lot Name",
    },
  ];
  const parvetcolums = [
    {
      name: "AIT Code",
      selector: "code",
      width: "120px"
    },
    {
      name: "Paravet Name",
      selector: "Paravet Name",
    },
  ];
  const projectcolum = [
    {
      name: "ID",
      selector: "ID",
      width: "80px",
    },
    {
      name: "Project Name",
      selector: "Project Name",
    },
  ];

  const actionbutton = () => {
    setHeat(true);
    setCalving(false);
    setDry(false);
  }

  const Alarambutton = () => {
    setCalving(true);
    setHeat(false);
    setDry(false);
  }

  const herdbutton = () => {
    setDry(true);
    setHeat(false);
    setCalving(false);
  }

  const araparmeter = () => {
    setaramlist(true)
    setClick(false)
  }

  const expandMenu = () => {
    document.getElementById("expand").style.display = "none";
    setExpandColum(true)
    setBackBtn(true)
    setFirstCheck(false)
    setNonCheck(false)
    setPd1(false)
    setPd2(false)
    setMilking(false)
    setDryCheck(false)
    setCalvingCheck(false)
  }
  const expandMenu1 = () => {
    document.getElementById("expand1").style.display = "none";
    setExpandColum(true)
    setBackBtn(true)
    setHeatCheck(false)
    setNonCheck(false)
    setPd1(false)
    setPd2(false)
    setMilking(false)
    setDryCheck(false)
    setCalvingCheck(false)
  }
  const expandMenu2 = () => {
    document.getElementById("expand2").style.display = "none";
    setExpandColum(true)
    setBackBtn(true)
    setHeatCheck(false)
    setFirstCheck(false)
    setPd1(false)
    setPd2(false)
    setMilking(false)
    setDryCheck(false)
    setCalvingCheck(false)
  }
  const expandMenu3 = () => {
    document.getElementById("expand3").style.display = "none";
    setExpandColum(true)
    setBackBtn(true)
    setHeatCheck(false)
    setFirstCheck(false)
    setNonCheck(false)
    setPd2(false)
    setMilking(false)
    setDryCheck(false)
    setCalvingCheck(false)
  }
  const expandMenu4 = () => {
    document.getElementById("expand4").style.display = "none";
    setExpandColum(true)
    setBackBtn(true)
    setHeatCheck(false)
    setFirstCheck(false)
    setNonCheck(false)
    setPd1(false)
    setMilking(false)
    setDryCheck(false)
    setCalvingCheck(false)
  }
  const expandMenu5 = () => {
    document.getElementById("expand5").style.display = "none";
    setExpandColum(true)
    setBackBtn(true)
    setHeatCheck(false)
    setNonCheck(false)
    setFirstCheck(false)
    setPd1(false)
    setPd2(false)
    setDryCheck(false)
    setMilking(false)
  }
  const expandMenu6 = () => {
    document.getElementById("expand6").style.display = "none";
    setExpandColum(true)
    setBackBtn(true)
    setHeatCheck(false)
    setFirstCheck(false)
    setNonCheck(false)
    setPd1(false)
    setPd2(false)
    setCalvingCheck(false)
    setDryCheck(false)
  }
  const expandMenu7 = () => {
    document.getElementById("expand7").style.display = "none";
    setExpandColum(true)
    setBackBtn(true)
    setHeatCheck(false)
    setFirstCheck(false)
    setNonCheck(false)
    setPd1(false)
    setPd2(false)
    setCalvingCheck(false)
    setMilking(false)
  }

  const backHandler = () => {
    if (document.getElementById("expand")) {
      document.getElementById("expand").style.display = "block";
      setBackBtn(false)
      setExpandColum(false)
      setHeatCheck(true)
      setFirstCheck(true)
      setNonCheck(true)
      setPd1(true)
      setPd2(true)
      setMilking(true)
      setDryCheck(true)
      setCalvingCheck(true)
    }
    if (document.getElementById("expand1")) {
      document.getElementById("expand1").style.display = "block";
      setBackBtn(false)
      setExpandColum(false)
      setHeatCheck(true)
      setFirstCheck(true)
      setNonCheck(true)
      setPd1(true)
      setPd2(true)
      setMilking(true)
      setDryCheck(true)
      setCalvingCheck(true)
    }
    if (document.getElementById("expand2")) {
      document.getElementById("expand2").style.display = "block";
      setBackBtn(false)
      setExpandColum(false)
      setHeatCheck(true)
      setFirstCheck(true)
      setNonCheck(true)
      setPd1(true)
      setPd2(true)
      setMilking(true)
      setDryCheck(true)
      setCalvingCheck(true)
    }
    if (document.getElementById("expand3")) {
      document.getElementById("expand3").style.display = "block";
      setBackBtn(false)
      setExpandColum(false)
      setHeatCheck(true)
      setFirstCheck(true)
      setNonCheck(true)
      setPd1(true)
      setPd2(true)
      setMilking(true)
      setDryCheck(true)
      setCalvingCheck(true)
    }
    if (document.getElementById("expand4")) {
      document.getElementById("expand4").style.display = "block";
      setBackBtn(false)
      setExpandColum(false)
      setHeatCheck(true)
      setFirstCheck(true)
      setNonCheck(true)
      setPd1(true)
      setPd2(true)
      setMilking(true)
      setDryCheck(true)
      setCalvingCheck(true)
    }
    if (document.getElementById("expand5")) {
      document.getElementById("expand5").style.display = "block";
      setExpandColum(false)
      setBackBtn(false)
      setHeatCheck(true)
      setFirstCheck(true)
      setNonCheck(true)
      setPd1(true)
      setPd2(true)
      setMilking(true)
      setDryCheck(true)
      setCalvingCheck(true)
    }
    if (document.getElementById("expand6")) {
      document.getElementById("expand6").style.display = "block";
      setBackBtn(false)
      setExpandColum(false)
      setHeatCheck(true)
      setFirstCheck(true)
      setNonCheck(true)
      setPd1(true)
      setPd2(true)
      setMilking(true)
      setDryCheck(true)
      setCalvingCheck(true)
    }
    if (document.getElementById("expand7")) {
      document.getElementById("expand7").style.display = "block";
      setBackBtn(false)
      setExpandColum(false)
      setHeatCheck(true)
      setFirstCheck(true)
      setNonCheck(true)
      setPd1(true)
      setPd2(true)
      setMilking(true)
      setDryCheck(true)
      setCalvingCheck(true)
    }

  }

  const handleDownloadExcel = () => {
    // const header = []
    // const opt = `Heat Check ${date}`;
    // heatCheckColums.forEach((e) => {
    //   header.push(e.name)
    // })

    // const body = heatCheckList.map((item) => {
    //   const rowData = {};
    //   header.forEach((key) => {
    //     rowData[key] = item[key] || "";
    //   });
    //   return rowData;
    // });

    // downloadExcel({
    //   fileName: opt,
    //   sheet: "data",
    //   tablePayload: {
    //     header: header,
    //     body: body
    //   }
    // });
    const header = []
    const opt = `Heat Check ${date}`;
    heatCheckColums.map((e) => {
      header.push(e.name)
    })

    downloadExcel({
      fileName: opt,
      sheet: "data",
      tablePayload: {
        header,
        body: heatCheckList
      }
    });
  }

  const handleDownloadExcel1 = () => {
    const header = []
    const opt = `First Heat ${date}`;
    firstCheckColum.map((e) => {
      header.push(e.name)
    })

    downloadExcel({
      fileName: opt,
      sheet: "data",
      tablePayload: {
        header,
        body: firstChecklist
      }
    });
  }

  const handleDownloadExcel2 = () => {
    const header = []
    const opt = `Non Return ${date}`;
    heatCheckColums.map((e) => {
      header.push(e.name)
    })

    downloadExcel({
      fileName: opt,
      sheet: "data",
      tablePayload: {
        header,
        body: nonCheckColum
      }
    });
  }

  const handleDownloadExcel3 = () => {
    const header = []
    const opt = `Check PD1 ${date}`;
    pd1Colum.map((e) => {
      header.push(e.name)
    })

    downloadExcel({
      fileName: opt,
      sheet: "data",
      tablePayload: {
        header,
        body: pd1CheckList
      }
    });
  }

  const handleDownloadExcel4 = () => {
    const header = []
    const opt = `Check PD2 ${date}`;
    pd2Colum.map((e) => {
      header.push(e.name)
    })

    downloadExcel({
      fileName: opt,
      sheet: "data",
      tablePayload: {
        header,
        body: pd2CheckList
      }
    });
  }

  const handleDownloadExcel5 = () => {
    const header = []
    const opt = `Expected Calving ${date}`;
    calvingCheckColum.map((e) => {
      header.push(e.name)
    })

    downloadExcel({
      fileName: opt,
      sheet: "data",
      tablePayload: {
        header,
        body: calvingList
      }
    });
  }

  const handleDownloadExcel6 = () => {
    const header = []
    const opt = `Expected Milking ${date}`;
    Milkingcolum.map((e) => {
      header.push(e.name)
    })

    downloadExcel({
      fileName: opt,
      sheet: "data",
      tablePayload: {
        header,
        body: milkingList
      }
    });
  }

  const handleDownloadExcel7 = () => {
    const header = []
    const opt = `Expected Dry ${date}`;
    dryCheckColum.map((e) => {
      header.push(e.name)
    })

    downloadExcel({
      fileName: opt,
      sheet: "data",
      tablePayload: {
        header,
        body: dryList
      }
    });
  }

  const heatCheckColums = [
    {
      name: <div className="d-flex btn-blue-Animal align-items-center justify-content-start">
        <div className="ms-1 mainColHeading">Heat Check</div>
        <div><IoPlayForwardSharp className="me-3" id="expand" onClick={() => { expandMenu(); handleDownloadExcel() }} /></div>
      </div>,
      selector: "Heat Check"
    },
    {
      name: "Farmer Name",
      selector: "Farmer Name",
    },
    {
      name: "Mobile Number",
      selector: "Mobile Number",
    },
    {
      name: "Heat Date",
      selector: "Heat Date",
    },
    {
      name: "Days from A.I",
      selector: "",
    },
    {
      name: "Route Name",
      selector: "Route Name",
    },
    {
      name: "Lot Name",
      selector: "Lot Name",
    },
    {
      name: "Lot Code",
      selector: "Lot Code",
    },
  ]

  const firstCheckColum = [
    {
      name: <div className="d-flex btn-blue-Animal align-items-center justify-content-start">
        <div className="ms-1 mainColHeading">First Heat</div>
        <div><IoPlayForwardSharp className="me-3" id="expand1" onClick={() => { expandMenu1(); handleDownloadExcel1() }} /></div>
      </div>,
      selector: "First Heat",
    },
    {
      name: "Farmer Name",
      selector: "Farmer Name",
    },
    {
      name: "Mobile Number",
      selector: "Mobile Number",
    },
    {
      name: "Calving Date",
      selector: "Calving Date",
    },
    {
      name: "Calving Days",
      selector: "Calving Days",
    },
    {
      name: "First Heat Days",
      selector: "First Heat Days",
    },
    {
      name: "Route Name",
      selector: "Route Name",
    },
    {
      name: "Lot Name",
      selector: "Lot Name",
    },
    {
      name: "Lot Code",
      selector: "Lot Code",
    },
  ]

  const nonCheckColum = [
    {
      name: <div className="d-flex btn-blue-Animal align-items-center justify-content-start">
        <div className="ms-1 mainColHeading">Non Return</div>
        <div><IoPlayForwardSharp className="me-3" id="expand2" onClick={() => { expandMenu2(); handleDownloadExcel2() }} /></div>
      </div>,
      selector: "Non Return"
    },
    {
      name: "Farmer Name",
      selector: "Farmer Name",
    },
    {
      name: "Mobile Number",
      selector: "Mobile Number",
    },
    {
      name: "Heat Date",
      selector: "Heat Date",
    },
    {
      name: "Days from A.I",
      selector: "",
    },
    {
      name: "Route Name",
      selector: "Route Name",
    },
    {
      name: "Lot Name",
      selector: "Lot Name",
    },
    {
      name: "Lot Code",
      selector: "Lot Code",
    },
  ]

  const pd1Colum = [
    {
      name: <div className="d-flex btn-blue-Animal align-items-center justify-content-start">
        <div className="ms-1 mainColHeading">Check PD1</div>
        <div><IoPlayForwardSharp className="me-5" id="expand3" onClick={() => { expandMenu3(); handleDownloadExcel3() }} /></div>
      </div>,
      selector: "Check PD1"
    },
    {
      name: "Farmer Name",
      selector: "Farmer Name",
    },
    {
      name: "Mobile Number",
      selector: "Mobile Number",
    },
    {
      name: "Heat Date",
      selector: "Heat Date",
    },
    {
      name: "Days from A.I",
      selector: "",
    },
    {
      name: "Check PD1 Days",
      selector: "Check PD1 Days",
    },
    {
      name: "Route Name",
      selector: "Route Name",
    },
    {
      name: "Lot Name",
      selector: "Lot Name",
    },
    {
      name: "Lot Code",
      selector: "Lot Code",
    },
  ]

  const pd2Colum = [
    {
      name: <div className="d-flex btn-blue-Animal align-items-center justify-content-start">
        <div className="ms-1 mainColHeading">Check PD2</div>
        <div><IoPlayForwardSharp className="me-5" id="expand4" onClick={() => { expandMenu4(); handleDownloadExcel4() }} /></div>
      </div>,
      selector: "Check PD2"
    },
    {
      name: "Farmer Name",
      selector: "Farmer Name",
    },
    {
      name: "Mobile Number",
      selector: "Mobile Number",
    },
    {
      name: "Heat Date",
      selector: "Heat Date",
    },
    {
      name: "Days from A.I",
      selector: "",
    },
    {
      name: "Check PD2 Days",
      selector: "Check PD2 Days",
    },
    {
      name: "Route Name",
      selector: "Route Name",
    },
    {
      name: "Lot Name",
      selector: "Lot Name",
    },
    {
      name: "Lot Code",
      selector: "Lot Code",
    },
  ]

  const calvingCheckColum = [
    {
      name: <div className="d-flex btn-blue-Animal align-items-center justify-content-start">
        <div className="ms-1 mainColHeading">Expected Calving</div>
        <div><IoPlayForwardSharp className="me-5" id="expand5" onClick={() => { expandMenu5(); handleDownloadExcel5() }} /></div>
      </div>,
      selector: "Expected Calving"
    },
    {
      name: "Farmer Name",
      selector: "Farmer Name",
    },
    {
      name: "Mobile Number",
      selector: "Mobile Number",
    },
    {
      name: "Heat Date",
      selector: "Heat Date",
    },
    {
      name: "Days from A.I",
      selector: "",
    },
    {
      name: "Pregnancy Days",
      selector: "Pregnancy Days",
    },
    {
      name: "Route Name",
      selector: "Route Name",
    },
    {
      name: "Lot Name",
      selector: "Lot Name",
    },
    {
      name: "Lot Code",
      selector: "Lot Code",
    },
  ]

  const Milkingcolum = [
    {
      name: <div className="d-flex btn-blue-Animal align-items-center justify-content-start">
        <div className="ms-1 mainColHeading">Expected Milking</div>
        <div><IoPlayForwardSharp className="me-5" id="expand6" onClick={() => { expandMenu6(); handleDownloadExcel6() }} /></div>
      </div>,
      selector: "Expected Milking"
    },
    {
      name: "Farmer Name",
      selector: "Farmer Name",
    },
    {
      name: "Mobile Number",
      selector: "Mobile Number",
    },
    {
      name: "Milk Recording Date",
      selector: "Milk Recording Date",
    },
    {
      name: "Days from last Record",
      selector: "Days from last Record",
    },
    {
      name: "Milk Interval",
      selector: "Milk Interval",
    },
    {
      name: "Route Name",
      selector: "Route Name",
    },
    {
      name: "Lot Name",
      selector: "Lot Name",
    },
    {
      name: "Lot Code",
      selector: "Lot Code",
    },
  ]

  const dryCheckColum = [
    {
      name: <div className="d-flex btn-blue-Animal align-items-center justify-content-start">
        <div className="ms-1 mainColHeading">Expected Dry</div>
        <div><IoPlayForwardSharp className="me-4" id="expand7" onClick={() => { expandMenu7(); handleDownloadExcel7() }} /></div>
      </div>,
      selector: "Expected Dry"
    },
    {
      name: "Farmer Name",
      selector: "Farmer Name",
    },
    {
      name: "Mobile Number",
      selector: "Mobile Number",
    },
    {
      name: "Heat Date",
      selector: "Heat Date",
    },
    {
      name: "Pregnancy Days",
      selector: "Pregnancy Days",
    },
    {
      name: "Calving Date",
      selector: "Calving Date",
    },
    {
      name: "Route Name",
      selector: "Route Name",
    },
    {
      name: "Lot Name",
      selector: "Lot Name",
    },
    {
      name: "Lot Code",
      selector: "Lot Code",
    },
  ]

  const SearchFn = (e) => {
    const data = e.target.value;
    if (data !== '') {
      const results = tabList.filter((user) => {
        console.log(user)
        return user["Paravet Name"].toLowerCase().startsWith(data.toLowerCase()) || user["code"].toLowerCase().startsWith(data.toLowerCase())
      });
      setSearchFilter(results);
    } else {
      setSearchFilter(tabList);
    }
    setSearch(data);
  }

  return (
    <>

      <Container fluid>
        <Row>
          {headerShow && (<Col lg="2" className="hideNavbar"> <Sidenav /></Col>)}
          <Col className="header" lg={headerShow ? "10" : "12"}>
            <Container fluid>
              <Row className="pt-1 sub-header align-items-center justify-content-between">
                <Col md="10" sm="10" xs="10" className="py-2 d-flex align-items-center">
                  <button style={{ border: "none" }} className="hideNavbar1" onClick={handleShow}><img src={menu} width={20} /></button>
                  <button style={{ border: "none" }} className="hide_Navbar" onClick={() => setShow(true)}><img src={menu} width={20} /></button>
                  <h5 className="ms-4 mt-2">Listing / Daily Action Plan</h5>
                </Col>
                <Col md="1" sm="1" xs="10" className="py-2 d-flex align-items-center text-end ms-auto">
                  <BackBtn style={{ border: "none" }} className="hideNavbar1" lable="Back" onClick={() => { navigate('/') }} />
                </Col>
                <Col md="1" sm="1" xs="2" className="text-end profile_dropdown">
                  <img src={profile} width={40} />
                  <div className="dropdpwn_Open">
                    <Row className="text-center align-items-center">
                      <Col xs="12" className="menuHover mb-3">
                        <Row >
                          <Col className="" xs="3">
                            <span className=""><MdOutlineVpnKey size={25} />
                            </span>
                          </Col>
                          <Col className="text-start" xs="9">
                            Change Password
                          </Col>
                        </Row>
                      </Col>
                      <Col xs="12" className="menuHover" onClick={logOut}>
                        <Row>
                          <Col className="" xs="3">
                            <span className=""><GoSignOut size={25} />
                            </span>
                          </Col>
                          <Col className="text-start" xs="9">
                            Sign Out
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>

              {click &&
                <Row className="mt-3">
                  <Col xl="2" lg="3" md="3" sm="5">
                    <div className="blue_bg p-3">
                      <FormControl variant="standard" fullWidth>
                        {/* <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>Select Tab</InputLabel>
                        <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={select} onChange={(e) => { selectFn(e.target.value) }}>
                          {fatchTabList.map((e) => (
                            <MenuItem className="drop_value" key={e.id} value={e.Name}>{e.Name}</MenuItem>
                          ))}
                        </Select> */}
                        <Searcable
                          placeholder="Select Tab"
                          options={fatchTabList}
                          onChange={(selectedOption) => selectFn(selectedOption.value)}
                          // value={select}
                          labelKey="Name"
                          valueKey="Name"
                        />
                      </FormControl>
                      {
                        select == "Paravet" && <FormControl variant="standard" fullWidth className="mt-2">
                          <Searcable
                            placeholder="Select Tab"
                            options={subFatchList}
                            onChange={(selectedOption) => selectFn1(selectedOption.value)}
                            // value={select}
                            labelKey="Paravet Name"
                            valueKey="ID"
                          />
                        </FormControl>
                      }
                      <div className="mt-2">
                        <Input label="Search" value={search} onChange={e => SearchFn(e)} className="mt-2" />
                      </div>
                      <Row className="pt-3 justify-content-center">
                        <Col>
                          {select === "Center" || select === "Route" ?
                            <Table columns={colums} data={tabList} height="67vh" selectableRows onSelectedRowsChange={SelectedRowFn} />
                            :
                            <>
                              {select === "District" || select === "Taluka" || select === "Village" || select === "Hamlet" ?
                                <Table columns={Districtcolums} data={searchFilter ? searchFilter : tabList} height="67vh" selectableRows onSelectedRowsChange={SelectedRowFn} /> : ""}
                              {select === "Doctor/VO" ? <Table columns={doctorColms} data={searchFilter ? searchFilter : tabList} height="67vh" selectableRows onSelectedRowsChange={SelectedRowFn} /> : ""}
                              {select === "DCS/Lot" ? <Table columns={dsccolums} data={searchFilter ? searchFilter : tabList} height="67vh" selectableRows onSelectedRowsChange={SelectedRowFn} /> : ""}
                              {select === "Paravet" ? <Table columns={parvetcolums} data={searchFilter ? searchFilter : tabList} height="67vh" selectableRows onSelectedRowsChange={SelectedRowFn} /> : ""}
                              {select === "Project Wise" ? <Table columns={projectcolum} data={searchFilter ? searchFilter : tabList} height="67vh" selectableRows onSelectedRowsChange={SelectedRowFn} /> : ""}
                            </>
                          }
                        </Col>
                      </Row>
                    </div>
                  </Col>
                  <Col xl="10">
                    <Row className="mt-2 align-items-end justify-content-start">
                      <Col xl="2" lg="3" md="3" sm="6" xs="12">
                        <sub className="mb-0 pb-0">Date</sub>
                        <input type="date" className="dateSelect_bg" id="date-1" defaultValue={selectedToDate} />
                      </Col>
                      <Col xl="2" lg="3" md="3" sm="5" xs="6"><p className="mt-4 mb-0 text-center">{select !== "" && `${select}-Wise`}</p></Col>
                      {/* <Col xl="2" lg="3" md="3" sm="5" xs="6" className='align-items-center ms-auto'>
                        <Input label="Search" value={search} onChange={e => SearchFn(e)} />
                      </Col> */}
                      <Col xl="1" lg="3" md="3" sm="5"><button className="btn-blue-Animal mt-1" onClick={View}>View</button></Col>
                      <Col xl="3" lg="3" md="3" sm="6"><button className="btn-blue-Animal mt-2" onClick={araparmeter}>Go To Parameter</button></Col>
                      {
                        backBtn && <Col xl="1" lg="3" md="3" sm="6" className="justify-content-end ms-auto"><button className="btn-blue-Animal mt-2" id="back" onClick={backHandler}>Back</button></Col>
                      }
                      <Col xl="12" ref={actionRef}>
                        <div className="blue_bg_Animal1 p-2 align-items-center justify-content-center fw-3 mt-3">
                          {
                            dataColum ? <Row className="align-items-center justify-content-start mainActionColum p-1">
                              {
                                heatCheck && <Col xl={expandColum ? "12" : "2"} className="actionColum me-1">
                                  <Table columns={heatCheckColums} data={heatCheckList} height="74vh" />
                                </Col>
                              }
                              {
                                firstCheck && <Col xl={expandColum ? "12" : "2"} className="actionColum me-1">
                                  <Table columns={firstCheckColum} data={firstChecklist} height="74vh" />
                                </Col>
                              }
                              {
                                nonCheck && <Col xl={expandColum ? "12" : "2"} className="actionColum me-1">
                                  <Table columns={nonCheckColum} data={nonCheckList} height="74vh" />
                                </Col>
                              }
                              {
                                pd1 && <Col xl={expandColum ? "12" : "2"} className="actionColum me-1" >
                                  <Table columns={pd1Colum} data={pd1CheckList} height="74vh" />
                                </Col>
                              }
                              {
                                pd2 && <Col xl={expandColum ? "12" : "2"} className="actionColum me-1">
                                  <Table columns={pd2Colum} data={pd2CheckList} height="74vh" />
                                </Col>
                              }
                              {
                                calvingCheck && <Col className="actionColum me-1" xl={expandColum ? "12" : "2"}>
                                  <Table columns={calvingCheckColum} data={calvingList} height="74vh" />
                                </Col>
                              }
                              {
                                Milking &&
                                <Col xl={expandColum ? "12" : "2"} className="actionColum me-1" >
                                  <Table columns={Milkingcolum} data={milkingList} height="74vh" />
                                </Col>
                              }
                              {
                                dryCheck && <Col xl={expandColum ? "12" : "2"} className="actionColum">
                                  <Table columns={dryCheckColum} data={dryList} height="74vh" />
                                </Col>
                              }
                            </Row> : <Row className="noData">
                              <closed>
                                <h6>Please select a tab to view Daily Action Plan</h6>
                              </closed>
                            </Row>
                          }

                        </div>
                        <Row className="align-items-center justify-content-end">
                          {/* <Col xl="1" lg="3" md="2" sm="3"><button className="btn-blue-Animal mt-2" onClick={handlePrint}>Preview</button></Col> */}
                          {/* <Col xl="3" lg="3" md="2" sm="3"><button className="btn-blue-Animal mt-2" onClick={handlePrint}>Preview</button></Col> */}
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              }

              {aramlist &&
                <>
                  <Row>
                    <Col xl="2">
                      <FormControl variant="standard" fullWidth>
                        <InputLabel id="demo-simple-select-standard-label">Breed</InputLabel>
                        <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={breed} onChange={(e) => { setBreed(e.target.value) }}>
                          {breedList.map((e) =>
                            <MenuItem className='drop_value' key={e.id} value={e.Name}>{e.Name}</MenuItem>
                          )}
                        </Select>
                      </FormControl>
                    </Col>
                  </Row>
                  <Row>
                    <Col xl="1" md="2" xs="3" className="mt-5">
                      <button onClick={actionbutton} className="mt-2 btn-blue">Action Plan</button>
                    </Col>
                    <Col xl="1" md="3" xs="4" className="mt-5">
                      <button onClick={Alarambutton} className="mt-2 btn-yellow">Alaram</button>
                    </Col>
                    <Col xl="1" md="3" xs="4" className="mt-5">
                      <button onClick={herdbutton} className="mt-2 btn-perot">Herd indices</button>
                    </Col>
                  </Row>
                  <Row>
                    <Col xl="5" lg="4" md="4" className="mt-3" style={{ background: "#F1F1F1" }}>
                      <div className="blue_bg p-3 my-3">
                        <h6 className="mb-0"><b>Parameter1</b></h6>
                        <hr />
                        <textarea placeholder="Calving Note" rows="5" className="mt-2 text_area p-2" />
                        <Row className="justify-content-start">
                          <Col xl="2"><button type="button" className="mt-1 btn-blue" style={{ background: "#43668E" }}>Save</button></Col>
                        </Row>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col xl="5" lg="4" md="4" className="mt-3" style={{ background: "#F1F1F1" }}>
                      <div className="blue_bg p-3 my-3">
                        <h6 className="mb-0"><b>Parameter2</b></h6>
                        <hr />
                        <textarea placeholder="Calving Note" rows="5" className="mt-2 text_area p-2" />
                        <Row className="justify-content-start">
                          <Col xl="2"><button type="button" className="mt-1 btn-blue" style={{ background: "#43668E" }}>Save</button></Col>
                        </Row>
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col xl="5" lg="4" md="4" className="mt-3" style={{ background: "#F1F1F1" }}>
                      <div className="blue_bg p-3 my-3">
                        <h6 className="mb-0"><b>Parameter3</b></h6>
                        <hr />
                        <textarea placeholder="Calving Note" rows="5" className="mt-2 text_area p-2" />
                        <Row className="justify-content-start">
                          <Col xl="2"><button type="button" className="mt-1 btn-blue" style={{ background: "#43668E" }}>Save</button></Col>
                        </Row>
                      </div>
                    </Col>
                  </Row>
                </>
              }
            </Container>
          </Col>
        </Row >
      </Container >


      {isLoading ?
        <div className="circleText">
          <div className="progressText">{progressData}</div>
          <div className="">
            <span className="loader-circle-13"></span>
          </div>
        </div>
        : false}

      {show && <Offcanvas className="hide_Navbar" show={show} onHide={handleClose}><Container><Row><Col><Sidenav /></Col></Row></Container></Offcanvas>}
    </>
  )
};
