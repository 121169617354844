import React, { useState, useEffect } from 'react'
import { Col, Container, Row, Offcanvas, Modal, Accordion } from "react-bootstrap";
import { TextField } from "@mui/material";
import Select from 'react-select';
import makeAnimated from 'react-select/animated';
import { MdOutlineVpnKey } from "react-icons/md";
import { GoSignOut } from "react-icons/go";
import { useNavigate } from 'react-router-dom';

import menu from '../../Photo/menu.svg'
import profile1 from '../../Photo/profile.svg';
import closeButton from "../../Photo/milk-union/close button.svg";
import remove from "../../Photo/master/remove.svg";

import Sidenav from "../../Componet/Sidenav/Sidenav";
import Input from '../../Componet/InputFild/Input';
import Table from '../../Componet/DataTable/Table';

import moment from 'moment';
import { STORAGE_KEY } from "../../constant/common";
import { API_URLS, serviceUrl } from "../../API/Api";
import BackBtn from '../../Componet/Button/BackBtn';
const { FIPTreatment } = API_URLS;
const added_by = localStorage.getItem(STORAGE_KEY.jwtTOKEN);

async function getStaffData(credentials) {
    return fetch(FIPTreatment.GetSatff, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            "Accept": "application/json",
            "Authorization": `Bearer ${added_by}`,
        },
        body: JSON.stringify(credentials)
    })
        .then(res => res.json());
}
async function getCampData(credentials) {
    return fetch(FIPTreatment.GetCamp, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            "Accept": "application/json",
            "Authorization": `Bearer ${added_by}`,
        },
        body: JSON.stringify(credentials)
    })
        .then(res => res.json());
}
async function getYearData(credentials) {
    return fetch(FIPTreatment.GetYear, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            "Accept": "application/json",
            "Authorization": `Bearer ${added_by}`,
        },
        body: JSON.stringify(credentials)
    })
        .then(res => res.json());
}
async function getMilkUnion(credentials) {
    return fetch(FIPTreatment.GetMilkUnion, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            "Accept": "application/json",
            "Authorization": `Bearer ${added_by}`,
        },
        body: JSON.stringify(credentials)
    })
        .then(res => res.json());
}
async function getSocietyCode(credentials) {
    return fetch(FIPTreatment.GetSocietyCode, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            "Accept": "application/json",
            "Authorization": `Bearer ${added_by}`,
        },
        body: JSON.stringify(credentials)
    })
        .then(res => res.json());
}
async function getMedicineData(credentials) {
    return fetch(FIPTreatment.GetMedicineData, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            "Accept": "application/json",
            "Authorization": `Bearer ${added_by}`,
        },
        body: JSON.stringify(credentials)
    })
        .then(res => res.json());
}
async function SaveFip(credentials) {
    return fetch(FIPTreatment.FipTreatmentSave, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            "Accept": "application/json",
            "Authorization": `Bearer ${added_by}`,
        },
        body: JSON.stringify(credentials)
    })
        .then(res => res.json());
}
async function Save2Fip(credentials) {
    return fetch(FIPTreatment.Treatment2Save, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            "Accept": "application/json",
            "Authorization": `Bearer ${added_by}`,
        },
        body: JSON.stringify(credentials)
    })
        .then(res => res.json());
}
async function Getdiagnosis(credentials) {
    return fetch(FIPTreatment.GetDiagnosis, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            "Accept": "application/json",
            "Authorization": `Bearer ${added_by}`,
        },
        body: JSON.stringify(credentials)
    })
        .then(res => res.json());
}
// 

const FipTreatment = () => {

    const API_KEY = serviceUrl;
    // const API_KEY = "https://hais.hap.in";
    const selectedToDate = new Date().toISOString().slice(0, 10);
    const animatedComponents = makeAnimated();

    const navigate = useNavigate();
    const logOut = () => { navigate("/") }

    const [search, setSearch] = useState("");
    const [group, setGroup] = useState("");
    const [userName, setUserName] = useState("");
    const [contactPerson, setContactPerson] = useState("");
    const [mobile, setMobile] = useState("");
    const [tags, setTags] = useState("")
    const [appType, setAppType] = useState("");
    const [qr, setQr] = useState("");
    const [zone, setZone] = useState(0);
    const [editId, setEditId] = useState("");
    const [tableId, setTableId] = useState("");
    const [route, setRoute] = useState("");
    const [searchFilter, setSearchFilter] = useState("");
    const [progressData, setProgressData] = useState("0%");
    const [date, setDate] = useState(selectedToDate);
    const [staff, setStaff] = useState("");
    const [camp, setCamp] = useState("");
    const [modalcamp, setModalCamp] = useState("");
    const [year, setYear] = useState("");
    const [modalyear, setModalYear] = useState("");
    const [milkUnion, setMilkUnion] = useState("1");
    const [followUpTest, setFollowUpTest] = useState("")

    const [modalsociety, setModalSociety] = useState("");
    const [diseaseCode, setDiseaseCode] = useState("");
    const [modalDose, setModalDose] = useState("");
    const [modalfollowtest, setModalfollowtest] = useState("")
    const [modalmedicine, setModalMedicine] = useState("");
    const [modalRoute, setModalRoute] = useState("");
    const [modalName, setModalName] = useState("");
    const [modalCode, setModalCode] = useState("");
    const [prognosis, setPrognosis] = useState("");
    const [disease, setDisease] = useState("");

    const [heightOffSet, setHeight] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [headerShow, setHeaderShow] = useState(true);
    const [show, setShow] = useState(false);
    const handleClose = () => { setShow(false); }
    const handleShow = () => { setHeaderShow(!headerShow); }
    const [modalShow, setModalShow] = useState(false);

    const [staffList, setStaffList] = useState([]);
    const [campList, setCampList] = useState([]);
    const [yearList, setYearList] = useState([]);
    const [fipList, setFipList] = useState([]);
    const [milkUnionList, setMilkUnionList] = useState([]);
    const [societyList, setSocietyList] = useState([]);
    const [tagList, setTagList] = useState([]);
    const [medicineList, setMedicineList] = useState([]);
    const [tagDataList, setTagDataList] = useState([]);
    const [userDetails, setUserDetails] = useState([]);
    const [routeList, setRouteList] = useState([]);
    const [tagsList, setTagsList] = useState([]);
    const [addMedicineList, setAddMedicineList] = useState([]);
    const [diseaseList, setDiseaseList] = useState([]);

    const prognosisList = [{ value: 'Favorable' }, { value: 'UnFavorable' }];

    const fetchData = async () => {
        setIsLoading(true);
        const res = await getStaffData({ id: 5 });
        const res1 = await getCampData();
        const res2 = await getYearData();

        if (res) { setStaffList(res) }
        if (res1) { setCampList(res1) }
        if (res2) { setYearList(res2) }

        await fetch(FIPTreatment.GetFipData, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`,
            },
            body: JSON.stringify({ camp: null, inputDate: null, staff: null, year: null })
        })
            .then(response => {
                const contentLength = response.headers.get('content-length');
                let loaded = 0;
                return new Response(
                    new ReadableStream({
                        start(controller) {
                            const reader = response.body.getReader();
                            read();
                            function read() {
                                reader.read()
                                    .then((progressEvent) => {
                                        if (progressEvent.done) {
                                            controller.close();
                                            return;
                                        }
                                        loaded += progressEvent.value.byteLength;
                                        const percentageComplete = Math.round(loaded / contentLength * 100) + '%';
                                        setProgressData(percentageComplete)
                                        controller.enqueue(progressEvent.value);
                                        read();
                                    })
                            }
                        }
                    })
                );
            })
            .then((res) => res.json())
            .then((result) => { setIsLoading(false); setFipList(result); setProgressData("0%"); })
    }

    useEffect(() => {
        fetchData();
        let divHeight = document.getElementById("heightOffsetStock")?.offsetHeight;
        setHeight(divHeight);
    }, [milkUnion]);

    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
        PaperProps: {
            style: {
                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                width: 250,
            },
        },
    };

    const refreshFn = () => {
        setModalShow(false)
        setGroup("")
        setAppType("")
        setUserName("")
        setEditId("")
        setMobile("")
        setQr("")
        setZone("")
        setContactPerson("")
    }

    const AddData = async () => {

        const date1 = moment(selectedToDate).format("YYYY-MM-DD");
        const date2 = moment(selectedToDate).format("MM/DD/YYYY");

        const multiTag = tagsList.toString();

        // if (multiTag) {
        const res = await SaveFip({
            camp: modalcamp, createdAt: date1, details: "1", diagnosis: "", farmer: "", herd: "", id: "0", inputDate: date1, lat: "", lot: "", long: "",
            staff, tagId: tags, updatedAt: "", yearMaster: year, zone: "", followuptest: followUpTest
        })
        const res1 = await Save2Fip({
            betchNo: 0, createdAt: "", doseRate: modalDose, id: "0", medicineLedger: modalmedicine, medicineRoute: "0", totalDose: modalDose, treatmentId: 0, updatedAt: ""
        })

        if (res) { fetchData(); setModalShow(false) }
        if (res1) { fetchData(); setModalShow(false) }

        fetch(`${API_KEY}/v1/api/web/project/treatmentmaxid?tagId=${multiTag}&date=${date2}`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`,
            }
                .then((res) => res.json())
                .then((result) => { if (result) { console.log(result); } })
        });
    }
    // }

    const UpdateData = () => {
        fetch(`${API_KEY}/herd/edit`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`,
            },
            body: JSON.stringify({ AppType: appType, CountryCode: null, Email: null, Mobile: mobile, MobileDetail: null, NotificationId: null, Photo: null, QRCode: qr, SyancPendingData: 1, Username: userName, group, is_deleted: null, staff: null, zone: zone })
                .then((res) => res.json())
                .then((result) => { if (result) { refreshFn(); setUserDetails(result.data); } })
        });
    }

    const Add = async () => {
        setModalShow(true);
        setAddMedicineList([]);
        const res4 = await getMilkUnion();
        const res5 = await getSocietyCode();
        const res6 = await getMedicineData();

        if (res4) { setMilkUnionList(res4) }
        if (res5) { setSocietyList(res5); }
        if (res6) { setMedicineList(res6); }
    }

    const AddAnimal = () => {
        navigate("/resgistraion/add")
    }

    const ViewFn = async () => {
        setIsLoading(true);
        const camp1 = camp ? camp : null;

        await fetch(FIPTreatment.GetFipData, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`,
            },
            body: JSON.stringify({ camp: camp1, inputDate: date, staff, year })
        })
            .then(response => {
                const contentLength = response.headers.get('content-length');
                let loaded = 0;
                return new Response(
                    new ReadableStream({
                        start(controller) {
                            const reader = response.body.getReader();
                            read();
                            function read() {
                                reader.read().then((progressEvent) => {
                                    if (progressEvent.done) {
                                        controller.close();
                                        return;
                                    }
                                    loaded += progressEvent.value.byteLength;
                                    const percentageComplete = Math.round(loaded / contentLength * 100) + '%';
                                    setProgressData(percentageComplete)
                                    controller.enqueue(progressEvent.value);
                                    read();
                                })
                            }
                        }
                    })
                );
            })
            .then((res) => res.json())
            .then((result) => { setIsLoading(false); setFipList(result); setProgressData("0%"); })
            .catch((error) => setIsLoading(false))
        setIsLoading(false);
    }

    const selectTags = (e) => {
        setTags(e);
        fetchTagSubData(e)
        // setTagsList(Array.isArray(e) ? e.map(x => x.value) : []);
    }

    const fetchTagSubData = async (e) => {
        await fetch(`${API_KEY}/v1/api/web/animal/details-current-data`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`,
            },
            body: JSON.stringify({ tagId: e })
        })
            .then(res => res.json())
            .then((res) => { setTagDataList(res); })
    }

    const SearchFn = (e) => {
        const data = e.target.value;
        if (data !== '') {
            const results = fipList.filter((user) => {
                return user.TagId.toLowerCase().startsWith(data.toLowerCase());
            });
            setSearchFilter(results);
        } else {
            setSearchFilter(fipList);
        }
        setSearch(data);
    }

    const Save = () => {
        fetch(`${API_KEY}/user/assignment`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`,
            },
            body: JSON.stringify({ farmerOnly: false, herdId: route || null, herdOnly: false, lotIdAssigned: [], lotIdUnAssign: [], lotOnly: true, searchTerm: "", userId: tableId })
        })
            .then(response => {
                const contentLength = response.headers.get('content-length');
                let loaded = 0;
                return new Response(
                    new ReadableStream({
                        start(controller) {
                            const reader = response.body.getReader();
                            read();
                            function read() {
                                reader.read()
                                    .then((progressEvent) => {
                                        if (progressEvent.done) {
                                            controller.close();
                                            return;
                                        }
                                        loaded += progressEvent.value.byteLength;
                                        const percentageComplete = Math.round(loaded / contentLength * 100) + '%';
                                        setProgressData(percentageComplete)
                                        controller.enqueue(progressEvent.value);
                                        read();
                                    })
                            }
                        }
                    })
                );
            })
            .then((res) => res.json())
            .then((result) => { setIsLoading(false); setProgressData("0%"); })
    }

    const editDetails = (e) => {
        fetchData()
        setEditId(e.id)
        setModalShow(true)
        setAppType(e.AppType)
        setUserName(e.Username)
        setQr(e.QRCode)
        setMobile(e.Mobile)
        setGroup(e.group.id)
        setZone(e.zone.id)
    };

    const colum = [
        {
            name: "id",
            selector: "id",
            width: "70px",
            sortable: true,
        },
        {
            name: "FollowUpTest",
            selector: "followuptest",
            width: "120px",
            sortable: true
        },
        {
            name: "Camp",
            selector: "Camp",
            width: "90px",
            sortable: true,
        },
        {
            name: "TagId",
            selector: "TagId",
            width: "180px"
        },
        {
            name: "Date",
            selector: "Date",
            cell: (e) => (moment(e["Date"]).format("MM-DD-YYYY")),
            width: "120px"
        },
        {
            name: "Staff",
            selector: "Staff",
        },
        {
            name: "Center",
            selector: "Center",
        },
        // {
        //     name: "Route",
        //     selector: "Route",
        // },
        {
            name: "DCS",
            selector: "DCS",
        },
        {
            name: "Farmer",
            selector: "Farmer",
        },
    ]

    const columMedicine = [
        {
            name: "Action",
            cell: (e) => <img src={remove} onClick={() => medicineRemoveFN(e)} />,
            width: "80px"
        },
        {
            name: "Code",
            selector: "modalCode",
            sortable: true
        },
        {
            name: "Medicine Name",
            selector: "modalName",
            sortable: true
        },
        {
            name: "Route",
            selector: "modalRoute",
            sortable: true
        },
        {
            name: "Dose",
            selector: "modalDose",
            width: "200px",
            sortable: true
        },
    ]

    const fetchTagData = async (e) => {
        setModalSociety(e);
        if (e) {
            await fetch(`${API_KEY}/v1/api/web/animal/dcs-animal/${e}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    "Accept": "application/json",
                    "Authorization": `Bearer ${added_by}`,
                },
            })
                .then(res => res.json())
                .then((res) => { setTagList(res); })
        }
    }

    const medicineFn = (e) => {
        setModalMedicine(e.value);
        setModalRoute(e.Route);
        setModalName(e.Name);
        setModalCode(e.Code);
    }

    const Addanimal = async () => {
        if (modalCode && modalName && modalRoute && modalDose) {
            setAddMedicineList((prev) => {
                return [...prev, { modalCode, modalName, modalRoute, modalDose }]
            })
        }
        setModalMedicine("");
        setModalCode("");
        setModalName("");
        setModalRoute("");
        setModalDose("");
    }

    const medicineRemoveFN = (e) => {
        const updatedData = addMedicineList.filter((item) => item.modalCode !== e.modalCode);
        setAddMedicineList(updatedData);
    }

    const SelectDisease = async (e) => {
        setDiseaseCode(e);

        const res = await Getdiagnosis({ id: e });
        if (res) {
            setDiseaseList(res);
        }
    }
    // const defaultProjectId = 1;
    // const defaultProjectName = projectList.find(item => item.id === defaultProjectId)?.Name;
    // console.log(defaultProjectName);
    return (
        <>
            <Container fluid>
                <Row>
                    {headerShow && (<Col lg="2" className="hideNavbar"> <Sidenav /></Col>)}
                    <Col className="header" lg={headerShow ? "10" : "12"}>
                        <Container fluid>
                            <Row className="pt-1 sub-header align-items-center justify-content-between">
                                <Col md="10" sm="10" xs="10" className="py-2 d-flex align-items-center">
                                    <button style={{ border: "none" }} className="hideNavbar1" onClick={handleShow}><img src={menu} width={20} /></button>
                                    <button style={{ border: "none" }} className="hide_Navbar" onClick={() => setShow(true)}><img src={menu} width={20} /></button>
                                    <h5 className="ms-4 mt-2">Project / FIP / FIP Treatment</h5>
                                </Col>
                                <Col md="1" sm="1" xs="10" className="py-2 d-flex align-items-center text-end ms-auto">
                                    <BackBtn style={{ border: "none" }} className="hideNavbar1" lable="Back" onClick={() => { navigate('/') }} />
                                </Col>
                                <Col md="1" sm="1" xs="2" className="text-end profile_dropdown">
                                    <img src={profile1} width={40} />
                                    <div className="dropdpwn_Open">
                                        <Row className="text-center align-items-center">
                                            <Col xs="12" className="menuHover mb-3">
                                                <Row >
                                                    <Col xs="3">
                                                        <span><MdOutlineVpnKey size={25} />
                                                        </span>
                                                    </Col>
                                                    <Col className="text-start" xs="9">
                                                        Change Password
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col xs="12" className="menuHover" onClick={logOut}>
                                                <Row>
                                                    <Col xs="3">
                                                        <span><GoSignOut size={25} /></span>
                                                    </Col>
                                                    <Col className="text-start" xs="9">
                                                        Sign Out
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="align-items-end" id='heightOffsetStock'>
                                <Col xl="1" md="3" sm="2" xs="6" className="align-items-center justify-content-start">
                                    <button className="btn-blue-Animal mt-1 mb-1" onClick={Add}>Add Treatment</button>
                                </Col>
                                <Col xl="2" sm="2" xs="6" className="ms-auto">
                                    <sub className="mb-0 pb-0">Date</sub>
                                    <input type="date" className="dateSelect_bg" value={date} onChange={(e) => { setDate(e.target.value) }} />
                                </Col>
                                <Col xl="3" sm="2" xs="6">
                                    <Select
                                        placeholder="Staff"
                                        onChange={(selectedOption) => setStaff(selectedOption.value)}
                                        options={staffList.map((item) => ({ value: item.ID, label: item.name }))}
                                    />
                                </Col>
                                <Col xl="1" sm="2" xs="6">
                                    <Select
                                        placeholder="Camp"
                                        onChange={(selectedOption) => setCamp(selectedOption.value)}
                                        options={campList.map((item) => ({ value: item.ID, label: item.CampNo }))}
                                    />
                                </Col>
                                <Col xl="1" sm="2" xs="6">
                                    <Select
                                        placeholder="Year"
                                        onChange={(selectedOption) => setYear(selectedOption.value)}
                                        options={yearList.map((item) => ({ value: item.id, label: item.YearName }))}
                                    />
                                </Col>
                                <Col xl="1" sm="2" md="2" xs="6" className="mt-2 d-flex align-items-center justify-content-start ">
                                    <button className="btn-blue-Animal mt-1 mb-1" onClick={ViewFn}>View</button>
                                </Col>
                            </Row>
                            {/* <Row className="mt-2">
                                <Col>
                                    <Table columns={colum} data={searchFilter ? searchFilter : fipList} height={`calc(100vh - (${heightOffSet}px + 124px))`} pagination />
                                </Col>
                            </Row> */}
                            <Row className="mt-2" id='heightOffsetStock'>
                                {fipList.length > 0 ?
                                    <div className="blue_bg DataTableBorder mt-1">
                                        <Table columns={colum} data={searchFilter ? searchFilter : fipList} height={`calc(100vh - (${heightOffSet}px + 140px))`} pagination />
                                    </div>
                                    :
                                    <Row>
                                        <Col>
                                            <div className="NoTableBorder ms-2" >
                                                <div className="d-flex tabledata colmBreed">{
                                                    colum.map((i) => {
                                                        return (
                                                            <Col className={`blankColumns ${i.name.replace(' ', '_')}`} style={{ backgroundColor: "rgb(169,169,169)" }} height={`calc(100vh - (${heightOffSet}px + 20px))`} >{i.name}</Col>
                                                        )
                                                    })
                                                }
                                                </div>
                                                <h6 className="text-center align-items-center mt-5 noData ms-5">No Data</h6>
                                            </div>
                                        </Col>
                                    </Row>
                                }
                            </Row>
                        </Container>
                    </Col>
                </Row>
            </Container>

            <Modal size="xl" aria-labelledby="contained-modal-title-vcenter" centered show={modalShow}>
                <Modal.Header style={{ background: "#DFDFDF" }}>
                    <Modal.Title id="contained-modal-title-vcenter">Camp Visit Information</Modal.Title>
                    <img src={closeButton} onClick={() => setModalShow(false)} />
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Row className='justify-content-start mb-2'>
                            <Col xl="2">Date:-{date}</Col>
                            <Col xl="6">Staff:-{staff}</Col>
                        </Row>
                        <Row className='align-items-end'>
                            <Col xl="3" lg="3" sm="6" xs="6" className='mb-2'>
                                <Select
                                    placeholder="Milk Union"
                                    onChange={(selectedOption) => setMilkUnion(selectedOption.value)}
                                    options={milkUnionList.map((item) => ({ value: item.id, label: item.Name }))}
                                    value={{ value: milkUnion, label: milkUnionList.find(item => item.id == milkUnion)?.Name }}
                                />
                                {/* <Select
                                        placeholder="Project"
                                        onChange={(selectedOption) => { selectProject(selectedOption.value); }}
                                        options={projectList.map((item) => ({ value: item.id, label: item.Name }))}
                                        value={{ value: project, label: projectList.find(item => item.id == project)?.Name }}
                                    /> */}
                            </Col>
                            <Col xl="3" lg="3" sm="6" xs="6" className='mb-2'>
                                <Select
                                    placeholder="Camp"
                                    onChange={(selectedOption) => setModalCamp(selectedOption.value)}
                                    options={campList.map((item) => ({ value: item.ID, label: item.CampNo }))}
                                />
                            </Col>
                            <Col xl="3" lg="3" sm="4" xs="6" className='mb-2'>
                                <Select
                                    placeholder="Year"
                                    onChange={(selectedOption) => setModalYear(selectedOption.value)}
                                    options={yearList.map((item) => ({ value: item.id, label: item.YearName }))}
                                />
                            </Col>
                            <Col xl="3" lg="3" sm="8" xs="6" className='mb-2'>
                                <Select
                                    placeholder="Society Code"
                                    onChange={(selectedOption) => fetchTagData(selectedOption.value)}
                                    options={societyList.map((item) => ({ value: item.ID, label: item.name }))}
                                />
                            </Col>
                            <Col xl="6" lg="9" sm="8" className='mb-2'>
                                {/* <Select
                                    placeholder="Tags"
                                    components={animatedComponents}
                                    options={tagList.map((item) => ({ value: item.Tagid, label: item.Tagid }))}
                                    onChange={selectTags}
                                    isMulti
                                    isClearable
                                /> */}
                                <Select
                                    placeholder="Tags"
                                    onChange={(selectedOption) => selectTags(selectedOption.value)}
                                    options={tagList.map((item) => ({ value: item.Tagid, label: item.Tagid }))}
                                />
                            </Col>
                            <Col xl="4" lg="9" sm="8" className='mb-2'>
                                <Select
                                    placeholder="Staff"
                                    onChange={(selectedOption) => setStaff(selectedOption.value)}
                                    options={staffList.map((item) => ({ value: item.ID, label: item.name }))}
                                />
                            </Col>
                            <Col md="3" xl="2" sm="4" xs="6" className='mb-2'>
                                <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }} onClick={AddAnimal}>Add New Animal</button>
                            </Col>
                            {tagDataList !== null &&
                                tagDataList.map((e) => {
                                    return <Col xl="12"><p className='me-3'>{Object.values(e)}</p></Col>
                                })
                            }
                        </Row>
                        <Row className='mt-3 align-items-end'>
                            <Col xl="4" sm="4" xs="6">
                                <TextField label="Disease Code" fullWidth variant="standard" value={diseaseCode} onChange={e => SelectDisease(e.target.value)} />
                            </Col>
                            <Col xl="4" sm="4" xs="6">
                                <Select
                                    placeholder="Disease"
                                    disabled
                                    onChange={(selectedOption) => setDisease(selectedOption.value)}
                                    options={diseaseList.map((item) => ({ value: item.id, label: item.Name }))}
                                    value={diseaseList.map((item) => ({ value: item.id, label: item.Name }))}
                                />
                            </Col>
                            <Col xl="4" sm="4">
                                <Select
                                    placeholder="Prognosis"
                                    onChange={(selectedOption) => setPrognosis(selectedOption.value)}
                                    options={prognosisList.map((item) => ({ value: item.value, label: item.value }))}
                                    defaultValue={{ value: 'Favorable', label: 'Favorable' }}
                                />
                            </Col>
                        </Row>
                        <Row className='mt-3'>
                            <Accordion defaultActiveKey={['0']} flush>
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header><h6 className="align-items-center mt-2 accordHeading">Add Medicine</h6></Accordion.Header>
                                    <Accordion.Body className="p-2 pb-0">
                                        <Row className="mx-1 align-items-end justify-content-start">
                                            <Col xl="4" lg="5" sm="8">
                                                <Select
                                                    placeholder="Code/Medicine"
                                                    onChange={(selectedOption) => medicineFn(selectedOption)}
                                                    options={medicineList.map((item) => ({ value: item.id, label: (item.Code + " | " + item.Name), Route: item.Route, Name: item.Name, Code: item.Code }))}
                                                />
                                            </Col>
                                            <Col lg="2" sm="4" xs="6">
                                                <TextField label="Route" disabled fullWidth variant="standard" value={modalRoute} onChange={e => setModalRoute(e.target.value)} />
                                            </Col>
                                            <Col xl="2" sm="4" xs="6">
                                                <TextField label="Dose" fullWidth variant="standard" value={modalDose} onChange={e => setModalDose(e.target.value)} />
                                            </Col>
                                            <Col xl="2" sm="4" xs="6">
                                                <TextField label="FollwUp Test" value={followUpTest} onChange={(e) => setFollowUpTest(e.target.value)} fullWidth variant="standard" defaultValue={0} />
                                            </Col>
                                            <Col xl="2" sm="3" xs="6 ms-auto">
                                                <button className="btn-blue-Animal mt-2 mb-1" onClick={Addanimal}>Add Medicine</button>
                                            </Col>

                                        </Row>
                                        <Row className="mt-3">
                                            <Col className='mx-4'>
                                                <Table columns={columMedicine} data={addMedicineList} height={`calc(100vh - (${heightOffSet}px + 124px))`} pagination />
                                            </Col>
                                        </Row>
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Container>
                        <Row className="justify-content-end">
                            <Col xl="1" sm="2" xs="4">
                                <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }} onClick={AddData}>Save</button>
                            </Col>
                            <Col xl="1" sm="2" xs="4">
                                <button className="btn-blue-Animal mt-1" onClick={() => setModalShow(false)} style={{ background: "#E16659" }}> Cancel </button>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Footer>
            </Modal>

            {show && (<Offcanvas className="hide_Navbar" show={show} onHide={handleClose}><Container><Row><Col><Sidenav /></Col></Row></Container></Offcanvas>)}

            {isLoading ?
                <div className="circleText">
                    <div className="progressText">{progressData}</div>
                    <div className="">
                        <span className="loader-circle-13"></span>
                    </div>
                </div>
                : false
            }
        </>
    )
}
export default FipTreatment;
