import React, { useEffect, useState } from 'react'
import { Col, Container, Row, Offcanvas, Modal } from "react-bootstrap";
import Sidenav from "../../Componet/Sidenav/Sidenav";
import menu from '../../Photo/menu.svg'
import profile from '../../Photo/profile.svg';
import Input from '../../Componet/InputFild/Input';
import Table from '../../Componet/DataTable/Table';
import edit from "../../Photo/master/edit.svg";
import remove from "../../Photo/master/remove.svg";
import closeButton from "../../Photo/milk-union/close button.svg";
import { downloadExcel } from "react-export-table-to-excel";
import { MdOutlineVpnKey } from "react-icons/md";
import { GoSignOut } from "react-icons/go";
import Select from 'react-select';
import {
  TextField,
  FormControl,
  InputLabel,
  // Select,
  MenuItem,
  FormControlLabel,
  RadioGroup,
  Radio,
} from "@mui/material";
import './supplier.css';

import { STORAGE_KEY } from "../../constant/common";
import { API_URLS, serviceUrl } from "../../API/Api";
import { borderRadius, margin } from '@mui/system';
import { useNavigate } from 'react-router-dom';
// import Select from 'react-select/dist/declarations/src/Select';
const { AitStockApi } = API_URLS;

const AITStock = () => {
  const added_by = localStorage.getItem(STORAGE_KEY.jwtTOKEN);

  async function getZone(credentials) {
    return fetch(AitStockApi.GetZone, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        "Accept": "application/json",
        "Authorization": `Bearer ${added_by}`,
      },
      body: JSON.stringify(credentials)
    })
      .then(res => res.json());
  }


  async function getStaff(credentials) {
    return fetch(AitStockApi.GetStaff, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        "Accept": "application/json",
        "Authorization": `Bearer ${added_by}`,
      },
      body: JSON.stringify(credentials)
    })
      .then(res => res.json());
  }

  async function getSire(credentials) {
    return fetch(AitStockApi.GetSire, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        "Accept": "application/json",
        "Authorization": `Bearer ${added_by}`,
      },
      body: JSON.stringify(credentials)
    })
      .then(res => res.json());
  }


  const API_KEY = serviceUrl;
  const selectedToDate = new Date().toISOString().slice(0, 10);

  const [center, setCenter] = useState("")
  const [date, setDate] = useState("")
  const [sire, setSire] = useState("")
  const [role, setRole] = useState("1")
  const [staff, setStaff] = useState("")
  const [selectRole, setSelectRole] = useState('0')
  const [normal, setNormal] = useState("")
  const [sorted, setSorted] = useState("")
  const [both, setBoth] = useState("")
  const [id, setId] = useState("")
  const navigate = useNavigate()
  const logOut = () => { navigate("/") }
  const [date1, setDate1] = useState(selectedToDate)
  const [date2, setDate2] = useState(selectedToDate)
  const [progressData, setProgressData] = useState("0%");
  const [sortedSemen, setSortedSemen] = useState("")
  const [sirename, setSireName] = useState("")
  const [sireId, setSireId] = useState("")
  const [staffId, setStaffId] = useState("")
  const [search, setSearch] = useState("")
  const [searchFilter, setSearchFilter] = useState("")
  const [staffFilter, setStaffFilter] = useState("")
  const [searchStaff, setSearchStaff] = useState("")
  const [sireFilter, setSireFilter] = useState("")
  const [searchSire, setSearchSire] = useState("")

  const [show, setShow] = useState(false);
  const [headerShow, setHeaderShow] = useState(true);
  const handleClose = () => { setShow(false); };
  const handleShow = () => { setHeaderShow(!headerShow); }
  const [isLoading, setIsLoading] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [deleteShow, setDeleteShow] = useState(false)

  const [aitList, setAitList] = useState([])
  const [aitList1, setAitList1] = useState([])
  const [centerList, setCenterList] = useState([])
  const [sireList, setSireList] = useState([])

  const [staffList, setStaffList] = useState([])
  const [rowsList, setRowsList] = useState([]);
  const [rowsListStaff, setRowsListStaff] = useState([])


  // const headers = {
  //   'Authorization': `Bearer ${added_by}`
  // };

  // fetch('https://api.example.com/data', { headers })
  //   .then(response => {
  //     // Handle the response
  //     console.log(response)
  //   })
  //   .catch(error => {
  //     // Handle any errors
  //     console.log(error)
  //   });


  const getStockData = async () => {
    const res = await getZone();
    const res1 = await getStaff();
    const res2 = await getSire();

    if (res) { setCenterList(res) }
    if (res1) { setSireList(res1); }
    if (res2) { setStaffList(res2); }
  }

  const fetchData = async () => {
    setIsLoading(true)
    let ids = (rowsList.map(i => i.id)).join(',');
    let ids1 = (rowsListStaff.map(i => i.id)).join(',');
    fetch(`${API_KEY}/straw-management/getPeriodicSireStockReport?staff=${staff ? staff : sire}&sementype=${selectRole}&sire=${ids ? ids : ids1}&fromdate=${date1}&todate=${date2}&ait=1&reporttype=${role}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        "Accept": "application/json",
        "Authorization": `Bearer ${added_by}`,
      }
    })
      .then(response => {
        const contentLength = response.headers.get('content-length');
        let loaded = 0;
        return new Response(
          new ReadableStream({
            start(controller) {
              const reader = response.body.getReader();
              read();
              function read() {
                reader.read()
                  .then((progressEvent) => {
                    if (progressEvent.done) { controller.close(); return; }
                    loaded += progressEvent.value.byteLength;
                    const percentageComplete = Math.round(loaded / contentLength * 100) + '%';
                    setProgressData(percentageComplete)
                    controller.enqueue(progressEvent.value);
                    read();
                  })
              }
            }
          })
        );
      })
      .then(res => res.json())
      .then((result) => { setAitList(result); setAitList1(result); setIsLoading(false); setProgressData("0%") })
  }

  const selectStaff = (e) => {
    setStaff(e)
  }

  const selectSire = (e1) => {
    setSire(e1)
  }

  useEffect(() => {
    try {
      if (added_by) {
        console.log("Token was Found")
      }
    } catch (error) {
      console.log("Token was not Found")
    }
    getStockData()
  }, [staff])


  const nameColum = [
    {
      name: "Name",
      cell: (e) => (
        <>
          <label for="Name" className='ms-2'>{e.Name}</label>
        </>
      ),
    }
  ]

  const updateFn = (e) => {
    setSireId(e.sireid)
    setStaffId(e.staffid)
    setModalShow(true)
  }

  const deleteData = () => {
    setModalShow(false)
  }

  const handleChecked = (e) => {
    if (e === true) {
      fetchData()
    }
    else {
      return
    }
  }

  const colum = [
    {
      name: "Staffname",
      selector: "Staffname",
      width: "330px"
    },
    {
      name: "Opening Balane",
      selector: "OpeningBalane",
      cell: (e) => (<p className='ms-auto'>{e["Opening Balance"]}</p>),
      width: "80px"
    },
    {
      name: "Distribute",
      selector: "Distribute",
      cell: (e) => (<p className='ms-auto'>{e["Distribute"]}</p>),
      width: "80px"
    },
    {
      name: "Transfer From",
      selector: "Transfer From",
      cell: (e) => (<p className='ms-auto'>{e["Transfer From"]}</p>),
      width: "80px"
    },
    {
      name: "Semen Used",
      selector: "Semen Used",
      cell: (e) => (<p className='ms-auto'>{e["Semen Used"]}</p>),
      width: "110px"
    },
    {
      name: "A.I Done",
      selector: "A.I Done",
      cell: (e) => (<p className='ms-auto'>{e["A.I Done"]}</p>),
      width: "80px"
    },
    {
      name: "Wastage",
      selector: "Wastage",
      cell: (e) => (<p className='ms-auto'>{e["Wastage"]}</p>),
      width: "80px"
    },
    {
      name: "Missing",
      selector: "Missing",
      cell: (e) => (<p className='ms-auto'>{e["Missing"]}</p>),
      width: "80px"
    },
    {
      name: "Camp",
      selector: "Camp",
      cell: (e) => (<p className='ms-auto'>{e["Camp"]}</p>),
      width: "80px"
    },
    {
      name: "Manual",
      selector: "Manual",
      cell: (e) => (<p className='ms-auto'>{e["Manual"]}</p>),
      width: "80px"
    },
    {
      name: "Transfer To",
      selector: "Transfer To",
      cell: (e) => (<p className='ms-auto'>{e["Transfer To"]}</p>),
      width: "80px"
    },
    {
      name: "Recovery",
      selector: "Recovery",
      cell: (e) => (<p className='ms-auto'>{e["Recovery"]}</p>),
      width: "80px"
    },
    {
      name: "Closing Stock",
      selector: "Closing Stock",
      cell: (e) => (<p className='ms-auto'>{e["Closing Stock"]}</p>),
      width: "80px"
    },
    {
      name: "Sire Id",
      selector: "sireid",
      cell: (e) => (<p className='ms-auto'>{e["sireid"]}</p>),
      width: "80px"
    },
  ]

  const columSire = [
    {
      name: "Actions",
      cell: (e) => {
        const matchResult = e.Sirename && e.Sirename.match("Total");
        if (matchResult) {
          return <></>
        }
        else {
          return <img
            src={edit}
            onClick={() => updateFn(e)}
            style={{ width: "15px", margin: "10px" }}
          />
        }
      },
      width: "80px"
    },
    {
      name: "Sirename",
      selector: "Sirename",
      width: "170px"
    },
    {
      name: "Opening Balane",
      selector: "OpeningBalane",
      cell: (e) => (<p className='ms-auto'>{e["Opening Balance"]}</p>),
      width: "80px"
    },
    {
      name: "Distribute",
      selector: "Distribute",
      cell: (e) => (<p className='ms-auto'>{e["Distribute"]}</p>),
      width: "80px"
    },
    {
      name: "Transfer From",
      selector: "Transfer From",
      cell: (e) => (<p className='ms-auto'>{e["Transfer From"]}</p>),
      width: "80px"
    },
    {
      name: "Semen Used",
      selector: "Semen Used",
      cell: (e) => (<p className='ms-auto'>{e["Semen Used"]}</p>),
      width: "80px"
    },
    {
      name: "A.I Done",
      selector: "A.I Done",
      cell: (e) => (<p className='ms-auto'>{e["A.I Done"]}</p>),
      width: "80px"
    },
    {
      name: "Wastage",
      selector: "Wastage",
      cell: (e) => (<p className='ms-auto'>{e["Wastage"]}</p>),
      width: "80px"
    },
    {
      name: "Missing",
      selector: "Missing",
      cell: (e) => (<p className='ms-auto'>{e["Missing"]}</p>),
      width: "80px"
    },
    {
      name: "Camp",
      selector: "Camp",
      cell: (e) => (<p className='ms-auto'>{e["Camp"]}</p>),
      width: "80px"
    },
    {
      name: "Manual",
      selector: "Manual",
      cell: (e) => (<p className='ms-auto'>{e["Manual"]}</p>),
      width: "80px"
    },
    {
      name: "Transfer To",
      selector: "Transfer To",
      cell: (e) => (<p className='ms-auto'>{e["Transfer To"]}</p>),
      width: "80px"
    },
    {
      name: "Recovery",
      selector: "Recovery",
      cell: (e) => (<p className='ms-auto'>{e["Recovery"]}</p>),
      width: "80px"
    },
    {
      name: "Closing Stock",
      selector: "Closing Stock",
      cell: (e) => (<p className='ms-auto'>{e["Closing Stock"]}</p>),
      // width: "80px"
    },
  ]

  const blankData = [{}]
  const blankColmns = [
    {
      name: "Actions",
      width: "80px"
    },
    {
      name: "Sirename",
      width: "170px"
    },
    {
      name: "Opening Balane",
      width: "80px"
    },
    {
      name: "Distribute",
      width: "80px"
    },
    {
      name: "Transfer From",
      width: "80px"
    },
    {
      name: "Semen Used",
      width: "80px"
    },
    {
      name: "A.I Done",
      width: "80px"
    },
    {
      name: "Wastage",
      width: "80px"
    },
    {
      name: "Missing",
      width: "80px"
    },
    {
      name: "Camp",
      width: "80px"
    },
    {
      name: "Manual",
      width: "80px"
    },
    {
      name: "Transfer To",
      width: "80px"
    },
    {
      name: "Recovery",
      width: "80px"
    },
    {
      name: "Closing Stock",
      width: "80px"
    },
  ]

  const SelectedRowFn = (rows) => {
    setRowsList(rows.selectedRows);
  }

  const SelectedRowFn1 = (rows) => {
    setRowsListStaff(rows.selectedRows);
  }

  const ApplyFn = () => {
    fetchData()
  }

  const SaveFn = () => { }

  const handleDownloadExcel = () => {
    const opt = `Sement And Other Report ${date1} ${date2}`;
    const header = Object.keys(aitList).map((key, e) => (key));

    downloadExcel({
      fileName: opt,
      sheet: "data",
      tablePayload: {
        header,
        body: aitList
      }
    });
  }
  const AddData = async () => {
    setIsLoading(true)
    await fetch(AitStockApi.SireUpdate, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        "Accept": "application/json",
        "Authorization": `Bearer ${added_by}`,
      },
      body: JSON.stringify({ staffId: staffId, currentStock: sortedSemen, sire: sireId, sorted: "0" })
    })
      .then(response => {
        const contentLength = response.headers.get('content-length');
        let loaded = 0;
        return new Response(
          new ReadableStream({
            start(controller) {
              const reader = response.body.getReader();
              read();
              function read() {
                reader.read()
                  .then((progressEvent) => {
                    if (progressEvent.done) {
                      controller.close();
                      return;
                    }
                    loaded += progressEvent.value.byteLength;
                    const percentageComplete = Math.round(loaded / contentLength * 100) + '%';
                    setProgressData(percentageComplete)
                    controller.enqueue(progressEvent.value);
                    read();
                  })
              }
            }
          })
        );
      })
      .then((res) => res.json())
      .then((result) => { setModalShow(false); setIsLoading(false); setProgressData("0%") })
  }


  const SearchFn = (e) => {
    const data1 = e.target.value;
    if (data1 !== '') {
      const results = aitList.filter((user) => {
        return user && (user.Sirename ? user.Sirename : user.Staffname) && (user.Sirename ? user.Sirename : user.Staffname).toLowerCase().startsWith(data1.toLowerCase())
      });
      setSearchFilter(results);
    } else {
      setSearchFilter(aitList);
    }
    setSearch(data1);
  }

  const SearchStaffFn = (e) => {
    const data = e.target.value;
    if (data !== '') {
      const results = sireList.filter((user) => {
        return user && (user.Name ? user.Name : user.Name) && (user.Name ? user.Name : user.Name).toLowerCase().startsWith(data.toLowerCase())
      });
      setStaffFilter(results);
    } else {
      setStaffFilter(sireList);
    }
    setSearchStaff(data);
  }

  const SearchSireFn = (e) => {
    const data = e.target.value;
    if (data !== '') {
      const results = staffList.filter((user) => {
        return user && (user.Name ? user.Name : user.Name) && (user.Name ? user.Name : user.Name).toLowerCase().startsWith(data.toLowerCase())
      });
      setSireFilter(results);
    } else {
      setSireFilter(sireList);
    }
    setSearchSire(data);
  }

  const selectCenter = (e) => {
    setCenter(e.value)
  }

  return (
    <>
      <Container fluid>
        <Row>
          {headerShow && (<Col lg="2" className="hideNavbar"> <Sidenav /></Col>)}
          <Col className="header" lg={headerShow ? "10" : "12"}>
            <Container fluid>
              <Row className="pt-1 sub-header align-items-center justify-content-between">
                <Col md="11" sm="10" xs="10" className="py-2 d-flex align-items-center">
                  <button type="button" id="navToggle" class="navbar-toggler d-block" for="opensiderMenu" onClick={handleShow} style={{ border: "none", cursor: "pointer" }}><img src={menu} width={20} /></button>
                  <h5 className="ms-4 mt-2">Straw / AIT Stock</h5>
                </Col>
                <Col md="1" sm="1" xs="2" className="text-end profile_dropdown">
                  <img src={profile} width={40} />
                  <div className="dropdpwn_Open">
                    <Row className="text-center align-items-center">
                      <Col xs="12" className="menuHover mb-3">
                        <Row >
                          <Col className="" xs="3">
                            <span className=""><MdOutlineVpnKey size={25} />
                            </span>
                          </Col>
                          <Col className="text-start" xs="9">
                            Change Password
                          </Col>
                        </Row>
                      </Col>
                      <Col xs="12" className="menuHover" onClick={logOut}>
                        <Row>
                          <Col className="" xs="3">
                            <span className=""><GoSignOut size={25} />
                            </span>
                          </Col>
                          <Col className="text-start" xs="9">
                            Sign Out
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>

              <Row className="align-items-end justify-content-start p-2" id="aitStock">
                <Col md="4" xl="2" xxl="2" sm="2" className='justify-content-start'>
                  <Select
                    placeholder="Center"
                    onChange={(selectedOption) => {
                      selectCenter(selectedOption);
                    }}
                    options={centerList.map((item) => ({
                      value: item.id,
                      label: item.Name,
                    }))}
                  />
                </Col>
                <Col md="4" xl="2" xxl="2" sm="3" className="justify-content-center text-start">
                  <RadioGroup row aria-labelledby="demo-row-radio-buttons-group-label" value={role} name="row-radio-buttons-group">
                    <FormControlLabel value="2" control={<Radio className="p-1" />} onChange={e => setRole(e.target.value)} label="Sire" className="mt-1" />
                    <FormControlLabel value="1" control={<Radio className="p-1" />} onChange={e => setRole(e.target.value)} label="Staff" className="mt-1" />
                    <div className='mt-2 align-items-center'>
                      <input type="checkbox" id="All" value="All" className="me-2" onClick={(e) => handleChecked(e.target.checked)} />All
                    </div>
                  </RadioGroup>
                </Col>
                <Col md="4" xl="2" xxl="2" sm="2" className='justify-content-start'>
                  {
                    role === "2" &&
                    <Select
                      placeholder="Search Sire"
                      onChange={(selectedOption) => {
                        selectSire(selectedOption.value);
                      }}
                      options={sireList.map((item) => ({
                        value: item.id,
                        label: item.Name,
                      }))}
                    />
                  }
                  {
                    role === "1" &&
                    <Select
                      placeholder="Search Staff"
                      onChange={(selectedOption) => {
                        selectStaff(selectedOption.value);
                      }}
                      options={staffList.map((item) => ({
                        value: item.id,
                        label: `${item.code} - ${item.Name}`,
                      }))}
                    />
                  }
                </Col>
                <Col md="3" sm="2" xxl="1" xl="2" className='m-0'>
                  <sub className="mb-0 pb-0">From Date</sub>
                  <input type="date" className="dateSelect_bg" id="date-1" value={date1} onChange={(e) => { setDate1(e.target.value) }} />
                </Col>
                <Col md="3" sm="2" xxl="1" xl="2" className='m-0'>
                  <sub className="mb-0 pb-0">To Date</sub>
                  <input type="date" className="dateSelect_bg" id="date-2" value={date2} onChange={(e) => { setDate2(e.target.value) }} />
                </Col>
                <Col md="5" sm="5" xl="2" className="align-items-center me-0">
                  <RadioGroup row aria-labelledby="demo-row-radio-buttons-group-label" value={selectRole} name="row-radio-buttons-group">
                    <FormControlLabel value="0" control={<Radio className="p-1" />} onChange={e => setSelectRole(e.target.value)} label="Normal" className="mt-1" />
                    <FormControlLabel value="1" control={<Radio className="p-1" />} onChange={e => setSelectRole(e.target.value)} label="Sorted" className="mt-1" />
                    <FormControlLabel value="2" control={<Radio className="p-1" />} onChange={e => setSelectRole(e.target.value)} label="Both" className="mt-1" />
                  </RadioGroup>
                </Col>
                <Col md="2" sm="2" xl="1" className="ms-auto align-items-center justify-content-start">
                  <button className="btn-blue-Animal mt-1 mb-1" onClick={handleDownloadExcel}>Export</button>
                </Col>
                <Col md="2" sm="2" xl="1" className="align-items-center justify-content-start">
                  <button className="btn-blue-Animal mb-1" onClick={ApplyFn}>Apply</button>
                </Col>
              </Row>

              <Row className="align-items-start">
                <Col md="3" lg="3" xl="3" xxl="2" xs="12" className='mt-0'>
                  {
                    role === "2" && <Table columns={nameColum} data={sireFilter ? sireFilter : staffList} height="84vh" selectableRows onSelectedRowsChange={SelectedRowFn1} />
                  }
                  {
                    role === "1" && <Table columns={nameColum} data={staffFilter ? staffFilter : sireList} height="84vh" selectableRows onSelectedRowsChange={SelectedRowFn} />
                  }
                </Col>
                <Col md="8" lg="9" xl="9" xs="12" xxl="10">
                  {
                    role === "2" && <Table columns={colum} data={searchFilter ? searchFilter : aitList} height="75vh" pagination />
                  }
                  {
                    role === "1" &&
                    <>
                      {/* <Table columns={columSire} data={searchFilter ? searchFilter : aitList} height="75vh" pagination /> */}
                      <Row className='text-start me-2'>
                        <Col>
                          {/* <h6>Total: {aitList.length}</h6> */}
                          {
                            aitList.length > 0 ?
                              <Table columns={columSire} data={searchFilter ? searchFilter : aitList} height="75vh" pagination />
                              :
                              <Table columns={blankColmns} data={blankData} height="10vh" pagination />
                          }
                        </Col>
                      </Row>
                    </>
                  }
                </Col>
              </Row>

            </Container>
          </Col>
        </Row>
      </Container >

      <Modal aria-labelledby="contained-modal-title-vcenter" centered show={deleteShow} onHide={() => setModalShow(false)}>
        <Modal.Header style={{ background: "#DFDFDF" }}>
          <Modal.Title id="contained-modal-title-vcenter">Confirm Delete ?</Modal.Title>
          <img src={closeButton} onClick={() => setModalShow(false)} />
        </Modal.Header>
        <Modal.Body>
          <Container>
            Delete ?
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Container>
            <Row className="justify-content-end">
              <Col xl="3">
                <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }} onClick={(e) => SaveFn(e)}>Save</button>
              </Col>
              <Col xl="3">
                <button className="btn-blue-Animal mt-1" onClick={() => setDeleteShow(false)} style={{ background: "#E16659" }}> Cancel </button>
              </Col>
            </Row>
          </Container>
        </Modal.Footer>
      </Modal>

      <Modal size="lg" aria-labelledby="contained-modal-title-vcenter" centered show={modalShow} onHide={() => setModalShow(false)}>
        <Modal.Header style={{ background: "#DFDFDF" }}>
          <Modal.Title id="contained-modal-title-vcenter">Sire</Modal.Title>
          <img src={closeButton} onClick={() => setModalShow(false)} />
        </Modal.Header>
        <Modal.Body>
          <Container>
            <Row>
              <Col xl="6">
                <TextField label="Sorted Semen" id="standard-basic" value={sortedSemen} onChange={(e) => { setSortedSemen(e.target.value); }} fullWidth variant="standard" />
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Container>
            <Row className="justify-content-between">
              <Col xl="2">
                <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }} onClick={() => AddData()}>Save</button>
              </Col>
              <Col xl="2">
                <button className="btn-blue-Animal mt-1" onClick={() => setModalShow(false)} style={{ background: "#E16659" }}> Cancel </button>
              </Col>
            </Row>
          </Container>
        </Modal.Footer>
      </Modal>

      {show && <Offcanvas className="hide_Navbar" show={show} onHide={handleClose}><Container><Row><Col><Sidenav /></Col></Row></Container></Offcanvas>}

      {
        isLoading ?
          <div className="circleText">
            <div className="progressText">{progressData}</div>
            <div className="">
              <span className="loader-circle-13"></span>
            </div>
          </div>
          : false
      }
    </>
  )
}
export default AITStock    
