import { Switch } from '@mui/material'
import React, { useState } from 'react'
import {
    TextField,
    FormControl,
    InputLabel,
    // Select,
    MenuItem,
} from "@mui/material";
import {
    Col,
    Container,
    Row,
    Offcanvas,
    Accordion,
    Modal,
} from "react-bootstrap";
import Table from '../../Componet/DataTable/Table'
import Sidenav from '../../Componet/Sidenav/Sidenav';
import edit from "../../Photo/master/edit.svg";
import remove from "../../Photo/master/remove.svg";
import menu from "../../Photo/menu.svg";
import profile from "../../Photo/profile.svg";
import closeButton from "../../Photo/milk-union/close button.svg";
import Input from "../../Componet/InputFild/Input";
import { MdOutlineVpnKey } from "react-icons/md";
import { GoSignOut } from "react-icons/go";
import Select from 'react-select';
import { STORAGE_KEY } from "../../constant/common";
import { API_URLS, serviceUrl } from "../../API/Api";
import { useEffect } from 'react';
import { Label } from 'recharts';
import { useNavigate } from 'react-router-dom';
import { downloadExcel } from 'react-export-table-to-excel';
import BackBtn from '../../Componet/Button/BackBtn';
const { FarmerApi, SocietyApi, StaffApi, SmsApi, Herd } = API_URLS;


const label = { inputProps: { "aria-label": "Switch demo" } };

const OwnerFarmer = () => {
    const added_by = localStorage.getItem(STORAGE_KEY.jwtTOKEN);

    async function getData(credentials) {
        return fetch(FarmerApi.GetDetails, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`,
            },
            body: JSON.stringify(credentials)
        })
            .then(res => res.json());
    }
    async function addData(credentials) {
        return fetch(FarmerApi.AddDetails, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`,
            },
            body: JSON.stringify(credentials)
        })
            .then(res => res.json());
    }


    async function updateData(credentials) {
        return fetch(FarmerApi.UpdateDetails, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`,
            },
            body: JSON.stringify(credentials)
        })
            .then(res => res.json());
    }


    const API_KEY = serviceUrl;
    // const API_KEY = "https://hais.hap.in";
    const [route, setRoute] = useState("")
    const [Code, setCode] = useState("")
    const [lotCode, setLotCode] = useState("")
    const [name, setName] = useState("")
    const [producerCode, setProducerCode] = useState("")
    const [sapCode, setSapCode] = useState("")
    const [sms, setSms] = useState("")
    const [address, setAddress] = useState("")
    const [search, setSearch] = useState("")
    const [editId, setEditId] = useState("")
    const [user, setUser] = useState("")
    const [CFlag, setCFlag] = useState("")
    const [BFlag, setBFlag] = useState("")
    const [mobile, setMobile] = useState("")
    const [DCSCode, setDCSCode] = useState("")
    const [adultFemale, setAdultFemale] = useState("")
    const [adultMale, setAdultMale] = useState("")
    const [ai_staff, setAi_staff] = useState("")
    const [SAPcode, setSAPcode] = useState("")
    const [childrenFemale, setChildrenFemale] = useState("")
    const [childrenMale, setChildrenMale] = useState("")
    const [co4, setCo4] = useState("")
    const [cofs29, setCofs29] = useState("")
    const [company, setCompany] = useState("")
    const [cropGrown, setCropGrown] = useState("")
    const [education, setEducation] = useState("")
    const [fodderCropsGrown, setFodderCropsGrown] = useState("")
    const [irrigatedArea, setIrrigatedArea] = useState("")
    const [is_send_sms, setIs_send_sms] = useState(false)
    const [last_name, setLast_name] = useState("")
    const [lot, setLot] = useState("")
    const [manager_staff, setManager_Staff] = useState("")
    const [middle_name, setMiddle_name] = useState("")
    const [smsLanguage, setSmsLanguage] = useState("")
    const [inseminator, setInseminator] = useState("")
    const [searchFilter, setSearchFilter] = useState("");
    const [progressData, setProgressData] = useState("0%");
    const [deleteId, setDeleteId] = useState()
    const [farmer, setFarmer] = useState("")

    const [isLoading, setIsLoading] = useState(false);
    const [heightOffSet, setHeight] = useState(0);
    const [show, setShow] = useState(false);
    const [headerShow, setHeaderShow] = useState(true);
    const handleClose = () => { setShow(false); };
    const handleShow = () => { setHeaderShow(!headerShow); }
    const [modalShow, setModalShow] = useState(false);
    const [isSuspended, setIsSuspended] = useState(false)
    const [editToggle, setEditToggle] = useState(false);
    const [deleteShow, setDeleteShow] = useState(false)


    const [farmerList, setFarmerList] = useState([])
    const [lotList, setLotList] = useState([])
    const [managerList, setManagerList] = useState([])
    const [smsList, setSmsList] = useState([])
    const [inseminatorList, setInseminatorList] = useState([])
    const [herdList, setHerdList] = useState([]);
    const [farmerDropList, setFarmerDropList] = useState([])
    const [farmerAllList, setFarmerAllList] = useState([])
    const [searchQuery, setSearchQuery] = useState('');
    const navigate = useNavigate()
    const logOut = () => { navigate("/") }

    const LimitArr = [
        { id: 1, Name: 100 },
        { id: 1, Name: 200 },
        { id: 1, Name: 500 },
        { id: 1, Name: 1000 },
        { id: 1, Name: 2000 },
        { id: 1, Name: 5000 }
    ]

    const farmerGetData = async () => {
        setIsLoading(true)
        await fetch(`${FarmerApi.FarmerTabel}?offset=0&limit=15&column_name=Name&sort_order=asc&search_term&company_filter=10&user=7&lot_filter=0`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`,
            },
        })
            .then(response => {
                const contentLength = response.headers.get('content-length');
                let loaded = 0;
                return new Response(
                    new ReadableStream({
                        start(controller) {
                            const reader = response.body.getReader();
                            read();
                            function read() {
                                reader.read()
                                    .then((progressEvent) => {
                                        if (progressEvent.done) {
                                            controller.close();
                                            return;
                                        }
                                        loaded += progressEvent.value.byteLength;
                                        const percentageComplete = Math.round(loaded / contentLength * 100) + '%';
                                        setProgressData(percentageComplete)
                                        controller.enqueue(progressEvent.value);
                                        read();
                                    })
                            }
                        }
                    })
                );
            })
            .then((res) => res.json())
            .then((result) => { setFarmerList(result.data); setIsLoading(false); setProgressData("0%"); })
        // const res = await getData();
        // setIsLoading(true);
        // if (res) {
        //     setFarmerList(res.data);
        //     setIsLoading(false)
        // }
    }


    const getHerData = async () => {
        await fetch(`${Herd.HerdByName}?name=`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`,
            },
        })
            .then(res => res.json())
            .then((result) => { setHerdList(result) })
    }

    const getTabledata = async (e) => {
        // getLotData(e)
        setRoute(e)
        console.log(route)
        // await fetch(`${SocietyApi.GetDetails}?offset=0&limit=15&column_name=Name&sort_order=asc&search_term&company_filter=10&user=7&herd_filter=${e}`, {
        //     method: 'GET',
        //     headers: {
        //         'Content-Type': 'application/json',
        //         "Accept": "application/json",
        //         "Authorization": `Bearer ${added_by}`,
        //     },
        // })
        //     .then(res => res.json())
        //     .then((result) => { setFarmerList(result.data) })

        await fetch(`${SocietyApi.GetByHerdId}?name&herdId=${e}&company_filter=10&user=201`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`,
            },
        })
            .then(res => res.json())
            .then((result) => { setLotList(result) })
    }
    const getLotData = async (e) => {
        setLot(e)
        await fetch(`${FarmerApi.GetDetails}?offset=0&limit=1000000000000&column_name=Name&sort_order=asc&search_term&company_filter=10&user=7&lot_filter=${e}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`,
            },
        })
            .then(res => res.json())
            .then((result) => { setFarmerDropList(result.data) })
    }
    const getFarmerdata = async (e) => {
        await fetch(`${FarmerApi.GetDetails}?offset=0&limit=10000000000&column_name=Name&sort_order=asc&search_term&company_filter=10&user=7&lot_filter=${e}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`,
            },
        })
            .then(res => res.json())
            .then((result) => { setFarmerList(result.data) })
    }


    const AddData = async () => {
        if (editId) {
            // const res = await updateData({ id: editId, code: Code, name, company: "1", isSuspended, mobile, user: "201", lot, DCSCode, producerCode, SAPcode, is_send_sms, isSuspended });
            const res = await updateData({
                id: editId,
                BFlag: null,
                CFlag: null,
                DCSCode: lotCode,
                SAPcode: sapCode,
                address: address,
                adultFemale: "",
                adultMale: "",
                ai_staff: "",
                childrenFemale: "",
                childrenMale: "",
                co4: "",
                code: Code,
                cofS29: "",
                company: "1",
                cropGrown: "",
                education: "",
                fodderCropsGrown: "",
                irrigatedArea: "",
                isSuspended: isSuspended,
                is_send_sms: is_send_sms,
                landArea: "",
                landHolding: "",
                last_name: last_name,
                lot: { id: lot, Name: "LRS" },
                manager_staff: manager_staff,
                middle_name: middle_name,
                mobile: mobile,
                name: name,
                producerCode: producerCode,
                rainFedArea: "",
                smsLanguage: smsLanguage,
                user: "7",
                youngFemale: "",
                youngMale: ""
            });
            if (res) {
                // setFarmerList(res.data);
                setModalShow(false)
                farmerGetData()
            }
            farmerGetData()
        }
        else {
            const res = await addData({
                id: editId,
                BFlag: null,
                CFlag: null,
                DCSCode: lotCode,
                SAPcode: sapCode,
                address: address,
                adultFemale: "",
                adultMale: "",
                ai_staff: "",
                childrenFemale: "",
                childrenMale: "",
                co4: "",
                code: Code,
                cofS29: "",
                company: "1",
                cropGrown: "",
                education: "",
                fodderCropsGrown: "",
                irrigatedArea: "",
                isSuspended: isSuspended,
                is_send_sms: is_send_sms,
                landArea: "",
                landHolding: "",
                last_name: last_name,
                lot: { id: lot, Name: "LRS" },
                manager_staff: manager_staff,
                middle_name: middle_name,
                mobile: mobile,
                name: name,
                producerCode: producerCode,
                rainFedArea: "",
                smsLanguage: smsLanguage,
                user: "7",
                youngFemale: "",
                youngMale: ""
            });
            if (res) {
                setModalShow(false)
                farmerGetData()
            }
        }
    }

    const formDataAPi = async() => {

        //lotData:
        await fetch(`${SocietyApi.LotByName}?name=&company_filter=1&user=201`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorization: `Bearer ${added_by}`,
            },
        })
            .then((res) => res.json())
            .then((result) => {
                setLotList(result);
            });

        //inseminatorData:
        await fetch(`${StaffApi.StaffByInseminator}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorization: `Bearer ${added_by}`,
            },
        })
            .then((res) => res.json())
            .then((result) => {

                setInseminatorList(result);
            });

        //managerData:
        await fetch(`${StaffApi.StaffByManeger}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorization: `Bearer ${added_by}`,
            },
        })
            .then((res) => res.json())
            .then((result) => {
                setManagerList(result);
            });

        // smsData:
        await fetch(`${SmsApi.SmsAllData}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorization: `Bearer ${added_by}`,
            },
        })
            .then((res) => res.json())
            .then((result) => {

                setSmsList(result);
            });
    }

    const updateFn = (e) => {
        setEditId(e.id)
        setEditToggle(true);
        setEditId(e.id);
        setCode(e.code);
        setName(e.Name);
        setModalShow(true);
        setLotCode(e.DCSCode)
        setMobile(e.mobile)
        setAddress(e.Address)
        setLot(e.lot.Name)
        setSapCode(e.SAPcode)
        setMobile(e.Mobile)
        setIs_send_sms(e.IsSendSMS)
        setIsSuspended(e.IsSuspended)
        setSmsLanguage(e.smsLanguage.id)
        setManager_Staff(e.manager_staff.id)
        setProducerCode(e.ProducerCode)
        setMiddle_name(e.MiddleName)
        setLast_name(e.LastName)
    };


    const deleteData = (id) => {
        fetch(`${API_KEY}/farmer/delete?id=` + id, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`,
            },
        })
            .then((res) => res.json())
            .then((result) => {
                farmerGetData();
                setDeleteShow(false)
                setModalShow(false);
            });

    }
    const Add = () => {
        setModalShow(true);
        setEditId("")
        setEditToggle("");
        setEditId("");
        setCode("");
        setName("");
        setManager_Staff("")
        setLotCode("")
        setProducerCode("")
        setMobile("")
        setSmsLanguage("")
        setIs_send_sms("")
        setAddress("")
    }

    const farmernames = (val) => {
        setSearchQuery(val);
    }
    const filteredFarmers = farmerList.filter(item => {
        const farmerName = item.FarmerName || "";
        const query = searchQuery || "";
        return farmerName.toLowerCase().includes(query.toLowerCase());
    });

    const farmerAll = async () => {
        setIsLoading(true)
        await fetch(`${FarmerApi.GetDetails}?offset=0&limit=100000&column_name=Name&sort_order=asc&search_term&company_filter=10&user=201&lot_filter=0&herd_filter=0`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`,
            },
        })
            .then(response => {
                const contentLength = response.headers.get('content-length');
                let loaded = 0;
                return new Response(
                    new ReadableStream({
                        start(controller) {
                            const reader = response.body.getReader();
                            read();
                            function read() {
                                reader.read()
                                    .then((progressEvent) => {
                                        if (progressEvent.done) {
                                            controller.close();
                                            return;
                                        }
                                        loaded += progressEvent.value.byteLength;
                                        const percentageComplete = Math.round(loaded / contentLength * 100) + '%';
                                        setProgressData(percentageComplete)
                                        controller.enqueue(progressEvent.value);
                                        read();
                                    })
                            }
                        }
                    })
                );
            })
            .then(res => res.json())
            .then((result) => { setFarmerAllList(result.data); setIsLoading(false); setProgressData("%0") })
    }


    useEffect(async () => {
        let divHeight = document.getElementById("heightOffsetStock")?.offsetHeight;
        setHeight(divHeight);
        // farmerGetData()
        formDataAPi()
        getHerData()
        // farmerAll()
    }, [])


    const colum = [
        {
            name: "DCS Code No",
            selector: "DCSCodeNo",
        },
        {
            name: "DCS SAP Code",
            selector: "DCSSAPCode",
        },
        {
            name: "DCS Name",
            selector: "DCSName",
            width: "220px"
        },
        {
            name: "Farmer Code",
            selector: "FarmerCode",
        },
        {
            name: "Farmer Name",
            selector: "FarmerName",
            width: "240px"
        },
        {
            name: "Mobile No",
            selector: "MobileNo",
        },
        {
            name: "Cow",
            selector: "Cow",
        },
        {
            name: "Buffalo",
            selector: "Buffalo",
        },
        {
            name: "C&B",
            selector: "C&B",
        },
        {
            name: "Is Suspended",
            selector: "IsSuspended",
            cell: (d) => <Switch {...label} defaultChecked={d.IsSuspended} onChange={(e) => { setIsSuspended(e.target.checked) }} />,
        },
        {
            name: "Actions",
            cell: (e) => (
                <>
                    <img
                        src={edit}
                        onClick={() => updateFn(e)}
                        style={{ width: "15px", margin: "10px" }}
                    />
                    <img
                        src={remove}
                        onClick={() => { setDeleteShow(true); setDeleteId(e.id); setUser(e.DCSName) }}
                        style={{ width: "15px" }}
                    />
                </>
            ),
        },
    ]

    // const SearchFn = (e) => {
    //     const data = e.target.value;
    //     if (data !== '') {
    //         const dataString = data.toString(); // Convert the search input to a string
    //         const results = farmerList.filter((d) => {
    //             const codeAsString = d.DCSSAPCode.toString(); // Convert the number to a string
    //             return codeAsString.indexOf(dataString) === 0;
    //         });
    //         setSearchFilter(results);
    //     } else {
    //         setSearchFilter(farmerList);
    //     }
    //     setSearch(data);
    // }

    const SearchFn = async (e) => {
        setSearch(e.target.value)
        // await fetch(`${FarmerApi.GetDetails}?offset=0&limit=100000&column_name=Name&sort_order=asc&search_term=${e.target.value}&company_filter=10&user=201&lot_filter=0&herd_filter=0`, {
        //     method: 'GET',
        //     headers: {
        //         'Content-Type': 'application/json',
        //         "Accept": "application/json",
        //         "Authorization": `Bearer ${added_by}`,
        //     },
        // })
        //     .then(response => {
        //         const contentLength = response.headers.get('content-length');
        //         let loaded = 0;
        //         return new Response(
        //             new ReadableStream({
        //                 start(controller) {
        //                     const reader = response.body.getReader();
        //                     read();
        //                     function read() {
        //                         reader.read()
        //                             .then((progressEvent) => {
        //                                 if (progressEvent.done) {
        //                                     controller.close();
        //                                     return;
        //                                 }
        //                                 loaded += progressEvent.value.byteLength;
        //                                 const percentageComplete = Math.round(loaded / contentLength * 100) + '%';
        //                                 setProgressData(percentageComplete)
        //                                 controller.enqueue(progressEvent.value);
        //                                 read();
        //                             })
        //                     }
        //                 }
        //             })
        //         );
        //     })
        //     .then(res => res.json())
        //     .then((result) => { setFarmerList(result.data); setIsLoading(false); setProgressData("%0") })
    }

    const ViewFn = async () => {
        if (lot !== "" || search !== "" || route !== "")
            setIsLoading(true)
        await fetch(`${FarmerApi.GetDetails}?offset=0&limit=100000&column_name=Name&sort_order=asc&search_term=${search}&company_filter=10&user=201&lot_filter=${lot}&herd_filter=${route}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`,
            },
        })
            .then(response => {
                const contentLength = response.headers.get('content-length');
                let loaded = 0;
                return new Response(
                    new ReadableStream({
                        start(controller) {
                            const reader = response.body.getReader();
                            read();
                            function read() {
                                reader.read()
                                    .then((progressEvent) => {
                                        if (progressEvent.done) {
                                            controller.close();
                                            return;
                                        }
                                        loaded += progressEvent.value.byteLength;
                                        const percentageComplete = Math.round(loaded / contentLength * 100) + '%';
                                        setProgressData(percentageComplete)
                                        controller.enqueue(progressEvent.value);
                                        read();
                                    })
                            }
                        }
                    })
                );
            })
            .then(res => res.json())
            .then((result) => { setFarmerList(result.data); setIsLoading(false); setProgressData("0%") })
    }

    const handleDownloadExcel = () => {
        const opt = `Owner Farmer`;
        const header = Object.keys(farmerList).map((key, e) => (key));

        downloadExcel({
            fileName: opt,
            sheet: "data",
            tablePayload: {
                header,
                body: farmerList
            }
        });
    }

    return (
        <>
            <Container fluid>
                <Row> {headerShow && (<Col lg="2" className="hideNavbar"> <Sidenav /></Col>)}
                    <Col className="header" lg={headerShow ? "10" : "12"}>
                        <Container fluid>
                            <Row className="pt-1 sub-header align-items-center justify-content-between">
                                <Col md="10" sm="10" xs="10" className="py-2 d-flex align-items-center">
                                    <button style={{ border: "none" }} onClick={handleShow}> <img src={menu} width={20} /> </button>
                                    <h5 className="ms-4 mt-2">Master / Farmer</h5>
                                </Col>
                                <Col md="1" sm="1" xs="10" className="py-2 d-flex align-items-center text-end ms-auto">
                                    <BackBtn style={{ border: "none" }} className="hideNavbar1" lable="Back" onClick={() => { navigate('/') }} />
                                </Col>
                                <Col md="1" sm="1" xs="2" className="text-end profile_dropdown">
                                    <img src={profile} width={40} />
                                    <div className="dropdpwn_Open">
                                        <Row className="text-center align-items-center">
                                            <Col xs="12" className="menuHover mb-3">
                                                <Row >
                                                    <Col className="" xs="3">
                                                        <span className=""><MdOutlineVpnKey size={25} />
                                                        </span>
                                                    </Col>
                                                    <Col className="text-start" xs="9">
                                                        Change Password
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col xs="12" className="menuHover" onClick={logOut}>
                                                <Row>
                                                    <Col className="" xs="3">
                                                        <span className=""><GoSignOut size={25} />
                                                        </span>
                                                    </Col>
                                                    <Col className="text-start" xs="9">
                                                        Sign Out
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="align-items-end justify-content-start p-2 d-flex" id='heightOffsetStock'>
                                <Col sm="2" lg="2" xl="1" className="mt-1 align-items-center justify-content-start">
                                    <button className="btn-blue-Animal mt-1 mb-1" onClick={Add}>Add Farmer</button>
                                </Col>
                                {/* <Col sm="2" lg="2" xl="1" className="mt-1 align-items-center justify-content-start">
                                    <button className="btn-blue-Animal mt-1 mb-1" onClick={() => setModalShow(true)}>Import Farmers</button>
                                </Col> */}
                                <Col sm="2" lg="2" className='ms-auto'>
                                    <Select
                                        // value={route}
                                        defaultValue={route}
                                        placeholder="Herd/Route"
                                        onChange={(selectedOption) => getTabledata(selectedOption.value)}
                                        options={herdList.map((item) => ({ value: item.id, label: item.Name }))}
                                    />
                                </Col>
                                <Col sm="2" lg="2">
                                    <Select
                                        // value={lot}
                                        defaultValue={lot}
                                        placeholder="Lot/Society"
                                        onChange={(selectedOption) => getLotData(selectedOption.value)}
                                        options={lotList.map((item) => ({ value: item.id, label: item.Name }))}
                                    />
                                </Col>
                                <Col sm="1" lg="2">
                                    <Select
                                        // value={limit}
                                        placeholder="Farmer"
                                        onChange={(selectedOption) => {
                                            // setLimit(selectedOption.value);
                                            // farmernames(selectedOption.value);
                                            setFarmer(selectedOption.value)
                                        }}
                                        options={farmerDropList.map((item) => ({ value: item.id, label: item.FarmerName }))}
                                    />
                                </Col>
                                <Col xxl="2" lg="3" md="4" sm="5">
                                    <Input label="Search" value={search} onChange={e => SearchFn(e)} />
                                </Col>
                                <Col sm="2" lg="2" xl="1" className="mt-2 align-items-center justify-content-start">
                                    <button className="btn-blue-Animal mt-1 mb-1" onClick={ViewFn}>View</button>
                                </Col>
                                <Col sm="2" lg="2" xl="1" className="mt-2 align-items-center justify-content-start">
                                    <button className="btn-blue-Animal mt-1 mb-1" onClick={handleDownloadExcel}>Export</button>
                                </Col>
                            </Row>
                            {/* <Row className="mt-2">
                                    <Col>
                                        <Table
                                            columns={colum}
                                            data={filteredFarmers.length > 0 ? filteredFarmers : farmerList}
                                            height={`calc(100vh - (${heightOffSet}px + 124px))`}
                                            pagination
                                        />
                                    </Col>
                                </Row> */}
                            <Row className="mt-2" id='heightOffsetStock'>
                                {farmerList.length > 0 ?
                                    <div className="blue_bg DataTableBorder mt-1">
                                        <Table columns={colum} data={searchFilter ? searchFilter : farmerList} height={`calc(100vh - (${heightOffSet}px + 140px))`} pagination />
                                    </div>
                                    :
                                    <Row>
                                        <Col>
                                            <div className="NoTableBorder ms-2" >
                                                <div className="d-flex tabledata colmBreed">{
                                                    colum.map((i) => {
                                                        return (
                                                            <Col className={`blankColumns ${i.name.replace(' ', '_')}`} style={{ backgroundColor: "rgb(169,169,169)" }} height={`calc(100vh - (${heightOffSet}px + 20px))`} >{i.name}</Col>
                                                        )
                                                    })
                                                }
                                                </div>
                                                <h6 className="text-center align-items-center mt-5 noData ms-5">No Data</h6>
                                            </div>
                                        </Col>
                                    </Row>
                                }
                            </Row>
                        </Container>
                    </Col>
                </Row>
            </Container>

            <Modal aria-labelledby="contained-modal-title-vcenter" centered show={deleteShow} onHide={() => setModalShow(false)}>
                <Modal.Header style={{ background: "#DFDFDF" }}>
                    <Modal.Title id="contained-modal-title-vcenter">Confirm Delete ?</Modal.Title>
                    <img src={closeButton} onClick={() => setModalShow(false)} />
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        Delete {user} ?
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Container>
                        <Row className="justify-content-end">
                            <Col xl="3">
                                <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }} onClick={() => deleteData(deleteId)}>Delete</button>
                            </Col>
                            <Col xl="3">
                                <button className="btn-blue-Animal mt-1" onClick={() => setDeleteShow(false)} style={{ background: "#E16659" }}> Cancel </button>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Footer>
            </Modal>


            <Modal size="lg" aria-labelledby="contained-modal-title-vcenter" centered show={modalShow} onHide={() => setModalShow(false)}>
                <Modal.Header style={{ background: "#DFDFDF" }}>
                    <Modal.Title id="contained-modal-title-vcenter">{editId ? "Update Farmer" : "Add new Farmer"}</Modal.Title>
                    <img src={closeButton} onClick={() => setModalShow(false)} />
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Row className='align-items-end'>
                            {/* <Col xl="6">
                                <FormControl variant="standard" style={{ border: "1px soild black" }} fullWidth>
                                    <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }} > Lot/Society</InputLabel>
                                    <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={lot} onChange={(e) => { getLotData(e.target.value) }}>
                                        {lotList.map((e) => (
                                            <MenuItem className="drop_value" key={e.id} value={e.Name}>
                                                {e.Name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Col> */}
                            <Col xl="6">
                                <Select
                                    defaultValue={lot}
                                    placeholder="Lot/Society"
                                    onChange={(selectedOption) => getLotData(selectedOption.value)}
                                    options={lotList.map((item) => ({ value: item.id, label: item.Name }))}
                                />
                            </Col>
                            <Col xl="6">
                                <TextField label="Code" value={Code} onChange={(e) => { setCode(e.target.value); }} id="standard-basic" data={farmerList} fullWidth variant="standard" />
                            </Col>
                        </Row>
                        <Row className="mt-4">
                            <Col xl="6">
                                <TextField label="DCS Code" id="standard-basic" value={lotCode} onChange={(e) => { setLotCode(e.target.value); }} data={farmerList} fullWidth variant="standard" />
                            </Col>
                            <Col xl="6">
                                <TextField label="Name" value={name} onChange={(e) => { setName(e.target.value) }} id="standard-basic" data={farmerList} fullWidth variant="standard" />
                            </Col>
                        </Row>
                        <Row className="mt-4">
                            <Col xl="6">
                                <TextField label="Producer Code" id="standard-basic" value={producerCode} onChange={(e) => { setProducerCode(e.target.value); }} data={farmerList} fullWidth variant="standard" />
                            </Col>
                            <Col xl="6">
                                <TextField label="SAP Code" value={sapCode} onChange={(e) => { setSapCode(e.target.value) }} id="standard-basic" data={farmerList} fullWidth variant="standard" />
                            </Col>
                        </Row>
                        <Row className="mt-4">
                            <Col xl="6">
                                <TextField label="Middle Name" id="standard-basic" value={middle_name} onChange={(e) => { setMiddle_name(e.target.value); }} data={farmerList} fullWidth variant="standard" />
                            </Col>
                            <Col xl="6">
                                <TextField label="Last Name" value={last_name} onChange={(e) => { setLast_name(e.target.value) }} id="standard-basic" data={farmerList} fullWidth variant="standard" />
                            </Col>
                        </Row>
                        <Row className="mt-4">
                            <Col xl="6">
                                <TextField label="Mobile" id="standard-basic" value={mobile} onChange={(e) => { setMobile(e.target.value); }} data={farmerList} fullWidth variant="standard" />
                            </Col>
                            <Col xl="6" className='d-flex align-items-center mt-4'>
                                <Col className='d-flex align-items-center'>
                                    <input type="checkbox" className='mr-2' />
                                    <h6 className='m-0 mr-1'>Cattle</h6>
                                </Col>
                                <Col className='d-flex align-items-center'>
                                    <input type="checkbox" className='mr-2' />
                                    <h6 className='m-0 mr-1'>Buffalo</h6>
                                </Col>
                            </Col>
                        </Row>
                        <Row className="mt-4">
                            <Col xl="6" className='d-flex align-items-center'>
                                <input type="checkbox" className='mr-1' defaultChecked={is_send_sms} onChange={(e) => setIs_send_sms(e.target.checked)} />
                                <h6 className='m-0 mr-1' >Send Sms</h6>
                                <Switch {...label} defaultChecked={isSuspended} onChange={(e) => { setIsSuspended(e.target.checked) }} />
                                <h6 className='m-0 mr-1'>Is Suspended</h6>
                            </Col>
                            <Col xl="6">
                                <Select
                                    defaultValue={sms}
                                    placeholder="SMS Language"
                                    onChange={(selectedOption) => setSms(selectedOption.value)}
                                    options={smsList.map((item) => ({ value: item.id, label: item.Name }))}
                                />
                            </Col>
                        </Row>
                        <Row className="mt-4">
                            <Col xl="6">
                                <Select
                                    defaultValue={manager_staff}
                                    placeholder="Manager"
                                    onChange={(selectedOption) => setManager_Staff(selectedOption.value)}
                                    options={managerList.map((item) => ({ value: item.id, label: item.Name }))}
                                />
                            </Col>
                            <Col xl="6">
                                <Select
                                    defaultValue={inseminator}
                                    placeholder="Inseminator"
                                    onChange={(selectedOption) => setInseminator(selectedOption.value)}
                                    options={inseminatorList.map((item) => ({ value: item.id, label: item.Name }))}
                                />
                            </Col>
                        </Row>
                        <Row className="mt-4">
                            <Col>
                                <TextField label="Addrees" value={address} onChange={(e) => { setAddress(e.target.value) }} id="standard-basic" data={farmerList} fullWidth variant="standard" />
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Container>
                        <Row className="justify-content-end">
                            <Col xl="2">
                                <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }} onClick={() => AddData()}>{editId ? "Update" : "Save"}</button>
                            </Col>
                            <Col xl="2">
                                <button className="btn-blue-Animal mt-1" onClick={() => setModalShow(false)} style={{ background: "#E16659" }}> Cancel </button>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Footer>
            </Modal>
            {show && <Offcanvas className="hide_Navbar" show={show} onHide={handleClose}><Container><Row><Col><Sidenav /></Col></Row></Container></Offcanvas>}

            {
                isLoading ?
                    <div className="circleText">
                        <div className="progressText">{progressData}</div>
                        <div className="">
                            <span className="loader-circle-13"></span>
                        </div>
                    </div>
                    : false
            }
        </>

    )
}

export default OwnerFarmer;