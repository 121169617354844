import { Switch } from '@mui/material'
import React, { useState } from 'react'
import {
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
} from "@mui/material";
import {
    Col,
    Container,
    Row,
    Offcanvas,
    Accordion,
    Modal,
} from "react-bootstrap";
import Table from '../../Componet/DataTable/Table'
import Sidenav from '../../Componet/Sidenav/Sidenav';
import edit from "../../Photo/master/edit.svg";
import remove from "../../Photo/master/remove.svg";
import menu from "../../Photo/menu.svg";
import profile from "../../Photo/profile.svg";
import closeButton from "../../Photo/milk-union/close button.svg";
import Input from "../../Componet/InputFild/Input";
import { MdOutlineVpnKey } from "react-icons/md";
import { GoSignOut } from "react-icons/go";

import { STORAGE_KEY } from "../../constant/common";
import { API_URLS, serviceUrl } from "../../API/Api";
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Searcable from '../../Componet/Dropdown/Searcable';
import BackBtn from '../../Componet/Button/BackBtn';
const { ProjectApi, ZoneApi, CC } = API_URLS;

const label = { inputProps: { "aria-label": "Switch demo" } };

const Project = () => {
    const added_by = localStorage.getItem(STORAGE_KEY.jwtTOKEN);


    async function addData(credentials) {
        return fetch(ProjectApi.AddDetails, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`,
            },
            body: JSON.stringify(credentials)
        })
            .then(res => res.json());
    }


    async function updateData(credentials) {
        return fetch(ProjectApi.UpdateDetails, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`,
            },
            body: JSON.stringify(credentials)
        })
            .then(res => res.json());
    }


    const API_KEY = serviceUrl;
    // const API_KEY = "https://hais.hap.in";
    const [code, setCode] = useState("")
    const [name, setName] = useState("")
    const [lat, setLat] = useState("");
    const [lng, setLng] = useState("");
    const [manager, setManager] = useState("");
    const [search, setSearch] = useState("")
    const [proOfficer, setProOfficer] = useState("")
    const [extensionOfficer, setExtensionOfficer] = useState("");
    const [exOfficerStaffId, setExOfficerStaffId] = useState("");
    const [exOfficerStaffName, setExOfficerStaffName] = useState("");
    const [deleteId, setDeleteId] = useState();
    const [zone, setZone] = useState("");
    const [zoneId, setZoneId] = useState("");
    const [zoneName, setZoneName] = useState("");
    const [cc, setCc] = useState("");
    const [ccId, setCcId] = useState("");
    const [ccName, setCcName] = useState("");
    const [editId, setEditId] = useState("")
    const [user, setUser] = useState("")
    const [isLoading, setIsLoading] = useState(false);
    const [heightOffSet, setHeight] = useState(0);
    const [progressData, setProgressData] = useState("0%");
    const [searchFilter, setSearchFilter] = useState("");

    const [show, setShow] = useState(false);
    const [headerShow, setHeaderShow] = useState(true);
    const handleClose = () => { setShow(false); };
    const handleShow = () => { setHeaderShow(!headerShow); }
    const [modalShow, setModalShow] = useState(false);
    const [isSuspended, setIsSuspended] = useState(false)
    const [editToggle, setEditToggle] = useState(false);
    const [deleteShow, setDeleteShow] = useState(false)

    const [projectList, setProjectList] = useState([])
    const [ZoneList, setZoneList] = useState([]);
    const [CcList, setCcList] = useState([]);
    const navigate = useNavigate()
    const logOut = () => { navigate("/") }


    const projectGetData = async () => {
        setIsLoading(true)
        fetch(ProjectApi.GetDetails, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`,
            },
        })
            .then(response => {
                const contentLength = response.headers.get('content-length');
                let loaded = 0;
                return new Response(
                    new ReadableStream({
                        start(controller) {
                            const reader = response.body.getReader();
                            read();
                            function read() {
                                reader.read()
                                    .then((progressEvent) => {
                                        if (progressEvent.done) {
                                            controller.close();
                                            return;
                                        }
                                        loaded += progressEvent.value.byteLength;
                                        const percentageComplete = Math.round(loaded / contentLength * 100) + '%';
                                        setProgressData(percentageComplete)
                                        controller.enqueue(progressEvent.value);
                                        read();
                                    })
                            }
                        }
                    })
                );
            })
            .then((res) => res.json())
            .then((result) => { setIsLoading(false); setProjectList(result); setProgressData("0%"); })
        // const res = await getData();
        // if (res) {
        //     setProjectList(res);
        //     setIsLoading(false)
        // }
    }

    const formApi = () => {
        //Zone:

        fetch(`${ZoneApi.GetZoneByName}?name=`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                Accept: "application/json",
                Authorization: `Bearer ${added_by}`,
            },
        })
            .then((res) => res.json())
            .then((result) => {
                console.log(result);
                setZoneList(result);
            });

        //Cc:

        fetch(`${CC.GetCcByName}?name=`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`,
            },
        })
            .then((res) => res.json())
            .then((result) => {
                setCcList(result);
            });

    }
    const Add = () => {
        setModalShow(true);
        setEditId("")
        setEditToggle("");
        setEditId("");
        setCode("");
        setName("")
        setManager("")
        setProOfficer("")
        setZone("")
    }

    useEffect(() => {
        let divHeight = document.getElementById("heightOffsetStock")?.offsetHeight;
        setHeight(divHeight);
        projectGetData()
        formApi()
    }, [])




    const AddData = async () => {
        if (editId) {
            const res = await updateData({ id: editId, code, name, company: "1", lat, lng, isSuspended, extensionOfficer, user: "201" });
            if (res) {
                setProjectList(res.data);
                setModalShow(false)
                projectGetData()
            }
        }
        else {
            const res = await addData({ cc, id: 0, code, name, company: "1", isSuspended, manager, extensionOfficer, lat, lng, zone });
            if (res) {
                setProjectList(res.data);
                setModalShow(false)
                projectGetData()
            }
        }
    }

    const updateFn = (e) => {
        console.log(e.Mobile)
        setEditId(e.id)
        // setEditToggle(true);
        setEditId(e.id);
        setCode(e.Code);
        setName(e.Name);
        setLat(e.lat);
        setLng(e.lng);
        setManager(e.manager);

        setExtensionOfficer(e.extensionOfficer);
        // setExOfficerStaffId(e.extensionOfficerStaff.id)
        // setExOfficerStaffName(e.extensionOfficerStaff.Name)

        setZone(e.zone.Name);
        // setZoneId(e.zone.id);
        // setZoneName(e.zone.Name);

        setCc(e.cc.Name);
        // setCcId(e.cc.id);
        // setCcName(e.cc.Name);
        setModalShow(true);

    };


    const deleteData = (id) => {
        // setUser(e.Name)
        // setDeleteShow(true)
        // let id = e.id;
        fetch(`${ProjectApi.DelateData}?id=` + id, {
            method: "DELETE",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`,
            },
        })
            .then((res) => res.json())
            .then((result) => {
                projectGetData();
                setDeleteShow(false)
                setModalShow(false);
            });

    }


    const colum = [
        {
            name: "Name",
            selector: "Name",
        },
        {
            name: "Code",
            selector: "Code",
        },
        {
            name: "Manager",
            selector: "",
        },
        {
            name: "ExtensionOfficerStaff",
            selector: "",
        },
        {
            name: "Latitude",
            cell: (e) => <>{e.Lng}</>,
        },
        {
            name: "Longitude",
            cell: (e) => <>{e.Lat}</>,
        },
        {
            name: "Is Suspended",
            selector: "IsSuspended",
            cell: (e) => <Switch {...label} />,
        },
        {
            name: "Actions",
            cell: (e) => (
                <>
                    <img
                        src={edit}
                        onClick={() => updateFn(e)}
                        style={{ width: "15px", margin: "10px" }}
                    />
                    <img
                        src={remove}
                        onClick={() => { setDeleteShow(true); setDeleteId(e.id); setUser(e.DCSName) }}
                        style={{ width: "15px" }}
                    />
                </>
            ),
        },
    ]
    const SearchFn = (e) => {
        const data = e.target.value;
        if (data !== '') {
            const results = projectList.filter((d) => {
                return d.Name.toLowerCase().startsWith(data.toLowerCase());
            });
            setSearchFilter(results);
        } else {
            setSearchFilter(projectList);
        }
        setSearch(data);
    }
    return (
        <>
            <Container fluid>
                <Row> {headerShow && (<Col lg="2" className="hideNavbar"> <Sidenav /></Col>)}
                    <Col className="header" lg={headerShow ? "10" : "12"}>
                        <Container fluid>
                            <Row className="pt-1 sub-header align-items-center justify-content-between">
                                <Col md="11" sm="10" xs="10" className="py-2 d-flex align-items-center">
                                    <button style={{ border: "none" }} onClick={handleShow}> <img src={menu} width={20} /> </button>
                                    <h5 className="ms-4 mt-2">Master / virtual_lot</h5>
                                </Col>
                                <Col md="1" sm="1" xs="2" className="text-end">
                                    <img src={profile} width={40} />
                                </Col>
                            </Row>
                            <Col md="1" sm="1" xs="10" className="py-2 d-flex align-items-center text-end ms-auto">
                                <BackBtn style={{ border: "none" }} className="hideNavbar1" lable="Back" onClick={() => { navigate('/') }} />
                            </Col>
                            <Row className="align-items-center justify-content-between p-2 d-flex" id='heightOffsetStock'>
                                <Col sm="4" lg="2" xl="1" className="mt-2 d-flex align-items-center justify-content-start">
                                    <button className="btn-blue-Animal mt-1 mb-1" onClick={Add}>Add virtual lot</button>
                                </Col>
                                <Col xxl="2" lg="3" md="4" sm="5">
                                    <Input label="Search" value={search} onChange={e => SearchFn(e)} />
                                </Col>
                            </Row>
                            {/* <Row className="mt-2">
                                <Col>
                                    <Table columns={colum} data={searchFilter ? searchFilter : projectList} height="75vh" pagination />
                                </Col>
                            </Row> */}
                            <Row className="mt-2" id='heightOffsetStock'>
                                {projectList.length > 0 ?
                                    <div className="blue_bg DataTableBorder mt-1">
                                        <Table columns={colum} data={searchFilter ? searchFilter : projectList} height={`calc(100vh - (${heightOffSet}px + 140px))`} pagination />
                                    </div>
                                    :
                                    <Row>
                                        <Col>
                                            <div className="NoTableBorder ms-2" >
                                                <div className="d-flex tabledata colmBreed">{
                                                    colum.map((i) => {
                                                        return (
                                                            <Col className={`blankColumns ${i.name.replace(' ', '_')}`} style={{ backgroundColor: "rgb(169,169,169)" }} height={`calc(100vh - (${heightOffSet}px + 20px))`} >{i.name}</Col>
                                                        )
                                                    })
                                                }
                                                </div>
                                                <h6 className="text-center align-items-center mt-5 noData ms-5">No Data</h6>
                                            </div>
                                        </Col>
                                    </Row>
                                }
                            </Row>
                        </Container>
                    </Col>
                </Row>
            </Container>

            <Modal aria-labelledby="contained-modal-title-vcenter" centered show={deleteShow} onHide={() => setModalShow(false)}>
                <Modal.Header style={{ background: "#DFDFDF" }}>
                    <Modal.Title id="contained-modal-title-vcenter">Confirm Delete ?</Modal.Title>
                    <img src={closeButton} onClick={() => setModalShow(false)} />
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        Delete {user} ?
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Container>
                        <Row className="justify-content-end">
                            <Col xl="3">
                                <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }} onClick={(e) => deleteData(deleteId)}>delete</button>
                            </Col>
                            <Col xl="3">
                                <button className="btn-blue-Animal mt-1" onClick={() => setDeleteShow(false)} style={{ background: "#E16659" }}> Cancel </button>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Footer>
            </Modal>


            <Modal size="lg" aria-labelledby="contained-modal-title-vcenter" centered show={modalShow} onHide={() => setModalShow(false)}>
                <Modal.Header style={{ background: "#DFDFDF" }}>
                    <Modal.Title id="contained-modal-title-vcenter">{editId ? "Update Virtual Lot" : "Add new Virtual Lot"}</Modal.Title>
                    <img src={closeButton} onClick={() => setModalShow(false)} />
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Row>
                            <Col xl="6">
                                <TextField label="Code" id="standard-basic" value={code} onChange={(e) => { setCode(e.target.value); }} data={projectList} fullWidth variant="standard" />
                            </Col>
                            <Col xl="6">
                                <TextField label="Name" value={name} onChange={(e) => { setName(e.target.value); }} id="standard-basic" data={projectList} fullWidth variant="standard" />
                            </Col>
                        </Row>
                        <Row className="mt-4">
                            <Col xl="6">
                                <TextField label="Latitude" name={lat} onChange={(e) => { setLat(e.target.value) }} id="standard-basic" data={projectList} fullWidth variant="standard" />
                            </Col>
                            <Col xl="6">
                                <TextField label="Longitude" name={lng} onChange={(e) => { setLng(e.target.value); }} id="standard-basic" data={projectList} fullWidth variant="standard" />
                            </Col>
                        </Row>
                        <Row className="mt-4">
                            <Col xl="6">
                                <TextField label="Manager" name={manager} onChange={(e) => { setManager(e.target.value) }} id="standard-basic" data={projectList} fullWidth variant="standard" />
                            </Col>
                            <Col xl="6">
                                <TextField label="project Officer" name={proOfficer} onChange={(e) => { setProOfficer(e.target.value); }} id="standard-basic" data={projectList} fullWidth variant="standard" />
                            </Col>
                        </Row>
                        <Row className="mt-4">
                            <Col xl="6">
                                <FormControl variant="standard" style={{ border: "1px soild black" }} fullWidth>
                                    {/* <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>
                                        Zone
                                    </InputLabel>
                                    <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={zone} onChange={(e) => { setZone(e.target.value); }}>
                                        {ZoneList.map((e) => (
                                            <MenuItem className="drop_value" key={e.id} value={e.Name} >
                                                {e.Name}
                                            </MenuItem>
                                        ))}
                                    </Select> */}
                                    <Searcable
                                        placeholder="Zone"
                                        options={ZoneList}
                                        onChange={(selectedOption) => setZone(selectedOption)}
                                        value={zone}
                                        labelKey="Name"
                                        valueKey="id"
                                    />
                                </FormControl>
                            </Col>
                            <Col xl="6">
                                <FormControl variant="standard" fullWidth>
                                    {/* <InputLabel
                                        id="demo-simple-select-standard-label"
                                        style={{ fontSize: "18px" }}
                                    >
                                        CC
                                    </InputLabel>
                                    <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={cc} onChange={(e) => { setCc(e.target.value); }}>
                                        {CcList.map((e) => (
                                            <MenuItem className="drop_value" key={e.id} value={e.Name} >
                                                {e.Name}
                                            </MenuItem>
                                        ))}
                                    </Select> */}
                                    <Searcable
                                        placeholder="CC"
                                        options={CcList}
                                        onChange={(selectedOption) => setCc(selectedOption)}
                                        value={cc}
                                        labelKey="Name"
                                        valueKey="id"
                                    />
                                </FormControl>
                            </Col>
                        </Row>
                        <Row className="mt-4">
                            <Col>
                                <Switch {...label} />
                                Is Suspended : No
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Container>
                        <Row className="justify-content-between">
                            <Col xl="2">
                                <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }} onClick={(e) => AddData(e)}>{editId ? "Update" : "Save"}</button>
                            </Col>
                            <Col xl="2">
                                <button className="btn-blue-Animal mt-1" onClick={() => setModalShow(false)} style={{ background: "#E16659" }}> Cancel </button>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Footer>
            </Modal>
            {show && <Offcanvas className="hide_Navbar" show={show} onHide={handleClose}><Container><Row><Col><Sidenav /></Col></Row></Container></Offcanvas>}

            {
                isLoading ?
                    <div className="circleText">
                        <div className="progressText">{progressData}</div>
                        <div className="">
                            <span className="loader-circle-13"></span>
                        </div>
                    </div>
                    : false
            }
        </>

    )
}

export default Project;