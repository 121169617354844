import React, { useEffect, useState } from 'react'
import { Col, Container, Row, Offcanvas } from "react-bootstrap";
import Sidenav from "../../Componet/Sidenav/Sidenav";
import menu from '../../Photo/menu.svg'
import profile from '../../Photo/profile.svg';
import Input from '../../Componet/InputFild/Input';
import Table from '../../Componet/DataTable/Table';
import { MdOutlineVpnKey } from "react-icons/md";
import { GoSignOut } from "react-icons/go";


import { STORAGE_KEY } from "../../constant/common";
import { API_URLS, serviceUrl } from "../../API/Api";
import { useNavigate } from 'react-router-dom';
const { Cattle_Deworming } = API_URLS;


const Deworming = () => {
    const added_by = localStorage.getItem(STORAGE_KEY.jwtTOKEN);

    async function getData(credentials) {
        return fetch(Cattle_Deworming.GetDetails, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`,
            },
            body: JSON.stringify(credentials)
        })
            .then(res => res.json());
    }

    const API_KEY =serviceUrl;
    // const API_KEY = "https://hais.hap.in";
    const [search, setSearch] = useState("")
    const [searchFilter, setSearchFilter] = useState("")

    const navigate = useNavigate()

    const [heightOffSet, setHeight] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [show, setShow] = useState(false);
    const [headerShow, setHeaderShow] = useState(true);
    const handleClose = () => { setShow(false); };
    const handleShow = () => { setHeaderShow(!headerShow); }

    const [dewormingList, setDewormingList] = useState([])


    const dewormingGetData = async () => {
        setIsLoading(true)
        const res = await getData();
        if (res) {
            setDewormingList(res);
            setIsLoading(false)
        }
    }

    useEffect(() => {
        let divHeight = document.getElementById("heightOffsetStock")?.offsetHeight;
        setHeight(divHeight);
        dewormingGetData();
    }, [])

    const colum = [
        {
            name: "TagId",
            selector: "TagId",
        },
        {
            name: "Date",
            selector: "Date",
        },
        {
            name: "Batch No",
            selector: "BatchNo",
        },
        {
            name: "Dose",
            selector: "Dose",
            width: "100px"
        },
        {
            name: "Cost",
            selector: "Cost",
            width: "100px"
        },
        {
            name: "Deworming Type",
            selector: "dewormingType",
            width: "130px"
        },
        {
            name: "Deworming Medicine",
            selector: "dewormerMedicine",
        },
        {
            name: "Medicine Route",
            selector: "medicineRouteName",
        },
        {
            name: "Done By",
            selector: "staffName",
        },
    ]
    const SearchFn = (e) => {
        const data = e.target.value;
        if (data !== '') {
            const results = dewormingList.filter((user) => {
                return user.TagId.toLowerCase().startsWith(data.toLowerCase())
            });
            setSearchFilter(results);
        } else {
            setSearchFilter(dewormingList);
        }
        setSearch(data);
    }

    const logOut = () => {
        navigate("/")
    }
    return (
        <>
            <Container fluid>
                <Row>
                    {headerShow && (<Col lg="2" className="hideNavbar"><Sidenav /></Col>)}
                    <Col className="header" lg={headerShow ? "10" : "12"}>
                        <Container fluid>
                            <Row className="pt-1 sub-header align-items-center justify-content-between">
                                <Col md="11" sm="10" xs="10" className="py-2 d-flex align-items-center">
                                    <button style={{ border: "none" }} className="hideNavbar1" onClick={handleShow}><img src={menu} width={20} /></button>
                                    <button style={{ border: "none" }} className="hide_Navbar" onClick={() => setShow(true)}><img src={menu} width={20} /></button>
                                    <h5 className="ms-4 mt-2">Cattle Details / Deworming</h5>
                                </Col>
                                <Col md="1" sm="1" xs="2" className="text-end profile_dropdown">
                                    <img src={profile} width={40} />
                                    <div className="dropdpwn_Open">
                                        <Row className="text-center align-items-center">
                                            <Col xs="12" className="menuHover mb-3">
                                                <Row >
                                                    <Col className="" xs="3">
                                                        <span className=""><MdOutlineVpnKey size={25} />
                                                        </span>
                                                    </Col>
                                                    <Col className="text-start" xs="9">
                                                        Change Password
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col xs="12" className="menuHover" onClick={logOut}>
                                                <Row>
                                                    <Col className="" xs="3">
                                                        <span className=""><GoSignOut size={25} />
                                                        </span>
                                                    </Col>
                                                    <Col className="text-start" xs="9">
                                                        Sign Out
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="mt-2 align-items-center justify-content-end d-flex" id='heightOffsetStock'>
                                <Col xxl="2" md="3" sm="3" xs="6">
                                    <Input label="Search" value={search} onChange={e => SearchFn(e)} />
                                </Col>
                            </Row>
                            <Row className="mt-2">
                                <Col>
                                    {isLoading ?
                                        <div className="spinner">
                                            <div className="bounce1" />
                                            <div className="bounce2" />
                                            <div className="bounce3" />
                                        </div>
                                        :
                                        <Table columns={colum} data={searchFilter ? searchFilter : dewormingList} height={`calc(100vh - (${heightOffSet}px + 140px))`} pagination />
                                    }
                                </Col>
                            </Row>
                        </Container>
                    </Col>
                </Row>
            </Container>
            {show && <Offcanvas className="hide_Navbar" show={show} onHide={handleClose}><Container><Row><Col><Sidenav /></Col></Row></Container></Offcanvas>}
        </>
    )
}
export default Deworming    
