import React, { useState, useEffect } from "react";
import './overview.css';
import { Col, Container, Row, Offcanvas, Modal } from "react-bootstrap";
import { Badge, FormControlLabel, Radio, RadioGroup, colors } from '@mui/material';
import { ResponsiveContainer, XAxis, YAxis, Tooltip, Legend, CartesianGrid, BarChart, Bar, PieChart, Pie, Cell } from "recharts";
import { IoCloudDone } from "react-icons/io5";
import { HiDocumentAdd } from "react-icons/hi";

import menu from '../Photo/menu.svg'
import profile from '../Photo/profile.svg';
import breeding from '../Photo/breeding.svg';
import over1 from '../Photo/Overview/over1.svg';
import over2 from '../Photo/Overview/over2.svg';
import over3 from '../Photo/Overview/over3.svg';
import over4 from '../Photo/Overview/over4.svg';
import over5 from '../Photo/Overview/over5.svg';
import over6 from '../Photo/Overview/over6.svg';
import over7 from '../Photo/Overview/over7.svg';
import over8 from '../Photo/Overview/over8.svg';
import over9 from '../Photo/Overview/over9.svg';
import over10 from '../Photo/Overview/over10.svg';


import moment from "moment";
import Sidenav from "../Componet/Sidenav/Sidenav";
import LightBtn from "../Componet/Button/LightBtn";
import BackBtn from "../Componet/Button/BackBtn";
import Dropdown from "../Componet/Dropdown/Dropdown";
import Table from "../Componet/DataTable/Table";
import { MdOutlineVpnKey } from "react-icons/md";
import { GoSignOut } from "react-icons/go";
import { AiFillAlert } from "react-icons/ai";

import { STORAGE_KEY } from "../constant/common";
import { useNavigate } from "react-router-dom";
import { API_URLS, serviceUrl } from "../API/Api";
import ReactApexChart from "react-apexcharts";
import { ToastContainer, toast } from "react-toastify";
import { downloadExcel } from "react-export-table-to-excel";
import Input from "../Componet/InputFild/Input";
const { OverViewApi, LoginAPI } = API_URLS;


export default function Overview() {

    const navigate = useNavigate()

    const API_KEY = serviceUrl;
    // const API_KEY = "https://hais.hap.in";

    const added_by = localStorage.getItem(STORAGE_KEY.jwtTOKEN);

    const [selectDMY, setSelectDMY] = useState("Date");
    const [fromDate, setFromDate] = useState("");
    const [toDate, setToDate] = useState("");
    const [bene1, setBene1] = useState("");
    const [bene2, setBene2] = useState("");
    const [bene3, setBene3] = useState("");
    const [bene4, setBene4] = useState("");
    const [bene5, setBene5] = useState("");
    const [bene6, setBene6] = useState("");
    const [box1, setBox1] = useState("");
    const [box2, setBox2] = useState("");
    const [box3, setBox3] = useState("");
    const [box4, setBox4] = useState("");
    const [box5, setBox5] = useState("");
    const [box6, setBox6] = useState("");
    const [box7, setBox7] = useState("");
    const [box8, setBox8] = useState("");
    const [box9, setBox9] = useState("");
    const [box10, setBox10] = useState("");
    const [box11, setBox11] = useState("");
    const [box12, setBox12] = useState("");
    const [noVo, setNoVo] = useState("");
    const [ai1, setAI1] = useState("");
    const [ai2, setAI2] = useState("");
    const [ai3, setAI3] = useState("");
    const [ai4, setAI4] = useState("");
    const [noVehical, setNoVehical] = useState("");
    const [visitId, setVisitId] = useState("")
    const [requestDate, setRequestDate] = useState("")
    const [requestTime, setRequestTime] = useState("")
    const [dcsCode, setDCSCode] = useState("")
    const [dcsName, setDcsName] = useState("")
    const [farmerName, setFarmerName] = useState("")
    const [farmerMobileNumber, setFarmerMobileNumber] = useState("")
    const [centerName, setCenterName] = useState("")
    const [routeCode, setRouteCode] = useState("")
    const [routeName, setRouteName] = useState("")
    const [visitType, setVisitType] = useState("")
    const [voName, setVoName] = useState("")
    const [contactNo, setContactNo] = useState("")
    const [animalIdNo, setAnimalIdNo] = useState("")
    const [diseseseCode, setDiseseseCode] = useState("")
    const [diseseseName, setDiseseseName] = useState("")
    const [changePassword, setChangePassword] = useState("")
    const [remarks, setRemarks] = useState()
    const [remarksId, setRemarksId] = useState("")

    const [animalHeight, setAnimalHeight] = useState([]);
    const [barAvgTime, setBarAvgTime] = useState([]);
    const [barBenefi, setBarBenefi] = useState([]);
    const [piaHealth, setPiaHealth] = useState([]);
    const [barKmVo, setBarKmVo] = useState([]);
    const [barVisitVo, setBarVisitVo] = useState([]);
    const [barCattle, setBarCattle] = useState([]);
    const [animalRecordList, setAnimalRecordList] = useState([]);
    const [activityList, setActivityList] = useState([]);
    {/* Breeding */ }
    const [aiDetailsList, setAIDetailsList] = useState([]);
    const [graphByDate, setGraphByDate] = useState([])
    const [treatmentAlertList, setTreatmentAlertList] = useState([])
    const [chart12List, setChart12List] = useState([])
    const [chart13List, setChart13List] = useState([])
    const [chart14List, setChart14List] = useState([])
    const [chart15List, setChart15List] = useState([])
    const [chart16List, setChart16List] = useState([])
    const [chart17List, setChart17List] = useState([])

    const [headerShow, setHeaderShow] = useState(true);
    const [show, setShow] = useState(false);
    const handleClose = () => { setShow(false); }
    const handleShow = () => { setHeaderShow(!headerShow); }

    const [modalShow, setModalShow] = useState(false);
    const [healthBtn, setHealthBtn] = useState(true);
    const [breedingBtn, setBreedingBtn] = useState(false);
    const [projectBtn, setProjectBtn] = useState(false);
    const [alertModal, setAlertModal] = useState(false)
    const [modalPassword, setModalPassword] = useState(false)
    const [remarksModal, setRemarksModal] = useState(false)

    const COLORS = ["#F9D57A", '#DF3E52', '#CC6868', '#A9CF57', '#E0DA3A', '#55E1C0', '#577EE1', '#8A45E2', '#E77C71'];
    const VISITCOLOR = ["#8FA9F5", '#CC6868', "#F9D57A", '#A9CF57', '#DF3E52', '#55E1C0'];

    const selectedFromDate = new Date().toISOString().slice(0, 10);
    const selectedToDate = new Date().toISOString().slice(0, 10);
    const fDate = fromDate ? moment(fromDate).format("L") : moment(selectedFromDate).format("L");
    const tDate = toDate ? moment(toDate).format("L") : moment(selectedToDate).format("L");

    const fatchSelectFn = () => {

        fetch(`${OverViewApi.DeshboardGraphType}?type=8&fromDate='${fDate}'&toDate='${tDate}'`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`
            },
        })
            .then(res => res.json())
            .then((result) => { setNoVo(result[0][""][1]); setNoVehical(result[1][""][1]); })

        fetch(`${OverViewApi.DeshboardGraphType}?type=0&fromDate=%27` + fDate + `%27&toDate=%27` + tDate + `%27`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`
            },
        })
            .then(res => res.json())
            .then((result) => {
                setBox1(result[0]['User']);
                setBox2(result[1]['User']);
                setBox3(result[2]['User']);
                setBox4(result[3]['User']);
                setBox5(result[4]['User']);
                setBox6(result[5]['User']);
                setBox7(result[6]['User']);
                setBox8(result[7]['User']);
                setBox9(result[8]['User']);
                setBox10(result[9]['User']);
                setBox11(result[10]['User']);
                setBox12(result[11]['User']);
            })

        fetch(`${OverViewApi.DeshboardGraphType}?type=1&fromDate=%27` + fDate + `%27&toDate=%27` + tDate + `%27`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`
            },
        })
            .then(res => res.json())
            .then((result) => { setAnimalHeight(result); })

        fetch(`${OverViewApi.DeshboardGraphType}?type=4&fromDate=%27` + fDate + `%27&toDate=%27` + tDate + `%27`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`
            },
        })
            .then(res => res.json())
            .then((result) => { setBarAvgTime(result); })

        fetch(`${OverViewApi.DeshboardGraphType}?type=5&fromDate=%27` + fDate + `%27&toDate=%27` + tDate + `%27`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`
            },
        })
            .then(res => res.json())
            .then((result) => {
                setBarBenefi(result);
                setBene1(result[0]['AnimalsTreated']);
                setBene2(result[0]['PMRequest']);
                setBene3(result[0]['SCRequest']);
                setBene4(result[0]['AIRequest']);
                setBene5(result[0]['FarmerUnique']);
                setBene6(result[0]['DCSUnique']);
            })

        fetch(`${OverViewApi.DeshboardGraphType}?type=3&fromDate=%27` + fDate + `%27&toDate=%27` + tDate + `%27`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`
            },
        })
            .then(res => res.json())
            .then((result) => { setPiaHealth(result); })

        fetch(`${OverViewApi.DeshboardGraphType}?type=6&fromDate=%27` + fDate + `%27&toDate=%27` + tDate + `%27`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`
            },
        })
            .then(res => res.json())
            .then((result) => { setBarKmVo(result); })

        fetch(`${OverViewApi.DeshboardGraphType}?type=7&fromDate=%27` + fDate + `%27&toDate=%27` + tDate + `%27`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`
            },
        })
            .then(res => res.json())
            .then((result) => { setBarVisitVo(result); })

        fetch(`${OverViewApi.DeshboardGraphType}?date=` + fDate, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`
            },
        })
            .then(res => res.json())
            .then((result) => { setBarCattle(result); })

        fetch(`${OverViewApi.GetAnimalActivity}?date=` + fDate, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`
            },
        })
            .then(res => res.json())
            .then((result) => { setAnimalRecordList(result.data); })

        fetch(`${OverViewApi.UserActivity}?date=` + fDate, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`
            },
        })
            .then(res => res.json())
            .then((result) => { setActivityList(result.data); })

        fetch(`${OverViewApi.GetGraphByDate}?date=` + selectedToDate, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`
            },
        })
            .then(res => res.json())
            .then((result) => { setGraphByDate(result.data); })

        fetch(`${LoginAPI.changePassword}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`
            },
        })
            .then(res => res.json())
            .then((result) => { setGraphByDate(result.data); })
    }

    useEffect(() => {
        fatchSelectFn();
        treatmentAlert()
    }, []);

    const RADIAN = Math.PI / 180;
    const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
        const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);

        return (
            <text x={x} y={y} fill="white" textAnchor={x > cx ? "start" : "end"} dominantBaseline="central" style={{ fontSize: "10px" }}>
                {`${(percent * 100).toFixed(0)}%`}
            </text>
        );
    };

    const colums = [
        {
            name: "Username",
            selector: "Username",
        },
        {
            name: "Type",
            selector: "Type",
            sortable: true,
        },
        {
            name: "No Of Records",
            selector: "NoOfRecords",
            sortable: true,
        }
    ]

    const activitycolums = [
        {
            name: "User",
            selector: "User",
        },
        {
            name: "Data Send",
            selector: "DataSend",
        },
        {
            name: "Date",
            selector: "Date",
            sortable: true,
        }
    ]

    const data = [
        {
            name: 'AnimalsTreated',
            " ": `${bene1}`,
        },
        {
            name: 'PMRequest',
            " ": `${bene2}`,
        },
        {
            name: 'SCRequest',
            " ": `${bene3}`,
        },
        {
            name: 'AIRequest',
            " ": `${bene4}`,
        },
        {
            name: 'FarmerUnique',
            " ": `${bene5}`,
        },
        {
            name: 'DCSUnique',
            " ": `${bene6}`,
        }
    ];

    const AIdata = [
        {
            name: 'Cow Normal',
            Normal: `${ai1}`,
        },
        {
            name: 'Cow Sorted',
            Normal: `${ai2}`,
        },
        {
            name: 'Buffalo Normal',
            Normal: `${ai3}`,
        },
        {
            name: 'Buffalo Sorted',
            Normal: `${ai4}`,
        }
    ];

    const healthBtnFn = () => {
        setHealthBtn(true);
        setBreedingBtn(false);
        setProjectBtn(false);
    }


    const BreedingBtnFn = async () => {
        setBreedingBtn(true);
        setHealthBtn(false);
        setProjectBtn(false);

        await fetch(`${OverViewApi.DeshboardGraphType}?type=11&fromDate=%27` + fDate + `%27&toDate=%27` + tDate + `%27`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`
            },
        })
            .then(res => res.json())
            .then((result) => { setAIDetailsList(result); setAI1(result[0]["Normal"]); setAI2(result[1]["Normal"]); setAI3(result[2]["Normal"]); setAI4(result[3]["Normal"]); })

        await fetch(`${OverViewApi.DeshboardGraphType}?type=12&fromDate=%27` + fDate + `%27&toDate=%27` + tDate + `%27`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`
            },
        })
            .then(res => res.json())
            .then((result) => { setChart12List(result) })

        await fetch(`${OverViewApi.DeshboardGraphType}?type=13&fromDate=%27` + fDate + `%27&toDate=%27` + tDate + `%27`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`
            },
        })
            .then(res => res.json())
            .then((result) => { setChart13List(result) })

        await fetch(`${OverViewApi.DeshboardGraphType}?type=14&fromDate=%27` + fDate + `%27&toDate=%27` + tDate + `%27`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`
            },
        })
            .then(res => res.json())
            .then((result) => { setChart14List(result) })

        await fetch(`${OverViewApi.DeshboardGraphType}?type=15&fromDate=%27` + fDate + `%27&toDate=%27` + tDate + `%27`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`
            },
        })
            .then(res => res.json())
            .then((result) => { setChart15List(result) })

        await fetch(`${OverViewApi.DeshboardGraphType}?type=16&fromDate=%27` + fDate + `%27&toDate=%27` + tDate + `%27`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`
            },
        })
            .then(res => res.json())
            .then((result) => { setChart16List(result) })

        await fetch(`${OverViewApi.DeshboardGraphType}?type=17&fromDate=%27` + fDate + `%27&toDate=%27` + tDate + `%27`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`
            },
        })
            .then(res => res.json())
            .then((result) => { setChart17List(result) })
    }

    const ProjectBtnFn = () => {
        setProjectBtn(true);
        setHealthBtn(false);
        setBreedingBtn(false);
    }

    const ApplyFn = () => {
        setModalShow(false);
        fatchSelectFn();
    }

    const logOut = () => {
        navigate("/")
    }

    const treatmentAlert = async () => {
        await fetch(`${OverViewApi.DeshboardGraphType}?type=100&fromDate=%27` + fDate + `%27&toDate=%27` + tDate + `%27`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`
            },
        })
            .then(res => res.json())
            .then((res) => {
                setTreatmentAlertList(res)
                setVisitId(res[0]["Visit ID"])
                setRequestDate(res[0]["Request Date"])
                setRequestTime(res[0]["Request Time"])
                setDCSCode(res[0]["DCS Code"])
                setDcsName(res[0]["DCS Name"])
                setFarmerName(res[0]["Farmer Name"])
                setFarmerMobileNumber(res[0]["Farmer MobileNumber"])
                setCenterName(res[0]["Center Name"])
                setRouteCode(res[0]["Route Code"])
                setRouteName(res[0]["Route Name"])
                setVisitType(res[0]["visitType"])
                setVoName(res[0]["VO Name"])
                setContactNo(res[0]["Contact No"])
                setAnimalIdNo(res[0]["Animal IDno"])
                setDiseseseCode(res[0]["Disesese Code"])
                setDiseseseName(res[0]["Disesese Name"])
            })
    }

    const alertComplete = async (e) => {
        await fetch(`${OverViewApi.GetAlertData}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`
            },
            body: JSON.stringify({ Id: remarksId !== "" ? remarksId : e["Visit ID"], Status: 1, Remarks: remarks ? remarks : 0 })
        })
            .then(res => res.json())
            .then((res) => {
                setRemarksId()
                setRemarksModal(false)
                treatmentAlert()
                toast.success("Update Data Successful")
            })
    }
    const treatmentColum = [
        {
            name: 'Complete Action',
            cell: (e) => (<div className="m-auto"><IoCloudDone size="30" color="red" onClick={() => alertComplete(e)} /></div>),
            width: "100px",
        },
        {
            name: 'Add Remarks',
            cell: (e) => (<div className="m-auto"><HiDocumentAdd size="30" color="green" onClick={() => { setRemarksModal(true); setRemarksId(e["Visit ID"]) }} /></div>),
            width: "100px",
        },
        {
            name: 'Status',
            cell: (e) => (<div>{e["Status"]}</div>),
            width: "100px",
        },
        {
            name: 'Disesese Code',
            cell: (e) => (<div>{e["Disesese Code"]}</div>),
            width: "80px",
        },
        {
            name: 'Disesese Name',
            cell: (e) => (<div>{e["Disesese Name"]}</div>),
            width: "150px",
        },
        {
            name: 'Visit ID',
            cell: (e) => (<div>{e["Visit ID"]}</div>),
            width: "100px",
        },
        {
            name: 'DCS Code',
            cell: (e) => (<div>{e["DCS Code"]}</div>),
        },
        {
            name: 'DCS Name',
            cell: (e) => (<div>{e["DCS Name"]}</div>),
            width: "150px",
        },
        {
            name: 'Request Date',
            cell: (e) => (<div>{e["Request Date"]}</div>),
        },
        {
            name: 'Request Time',
            cell: (e) => (<div>{e["Request Time"]}</div>),
        },
        {
            name: 'Farmer Name',
            cell: (e) => (<div>{e["Farmer Name"]}</div>),
            width: "220px",
        },
        {
            name: 'Farmer MobileNumber',
            cell: (e) => (<div>{e["Farmer MobileNumber"]}</div>),
            width: "120px",
        },
        {
            name: 'Center Name',
            cell: (e) => (<div>{e["Center Name"]}</div>),
            width: "100px",
        },
        {
            name: 'Route Code',
            cell: (e) => (<div>{e["Route Code"]}</div>),
        },
        {
            name: 'Route Name',
            cell: (e) => (<div>{e["Route Name"]}</div>),
        },
        {
            name: 'VO Name',
            cell: (e) => (<div>{e["VO Name"]}</div>),
            width: "210px",
        },
        {
            name: 'Contact No',
            cell: (e) => (<div>{e["Contact No"]}</div>),
            width: "180px",
        },
        {
            name: 'Animal IDno',
            cell: (e) => (<div>{e["Animal IDno"]}</div>),
            width: "180px",
        },
    ]

    const handleKeyPress = (e) => {
        const isNumeric = /^[0-9\b]+$/.test(e.key);

        if (!isNumeric) {
            e.preventDefault();
        }
    };

    const handleDownloadExcel = () => {
        const header = []
        const opt = `Treatment Alert ${selectedToDate}`;
        treatmentColum.map((e) => {
            header.push(e.name)
        })

        downloadExcel({
            fileName: opt,
            sheet: "data",
            tablePayload: {
                header,
                body: treatmentAlertList
            }
        });
    }

    const modalOpenClick = () => { setModalPassword(true) }

    const backFn = () => {
        if (breedingBtn === true) {
            setHealthBtn(true);
            setBreedingBtn(false);
            setProjectBtn(false);
        }
        else if (healthBtn === true) {
            setHealthBtn(true);
            setBreedingBtn(false);
            setProjectBtn(false);
        }
        else if (projectBtn === true) {
            setHealthBtn(true);
            setBreedingBtn(false);
            setProjectBtn(false);
        }
    }

    return (
        <>

            <Container fluid>
                <Row className="d-flex">
                    {headerShow && (
                        <Col lg="2" className="hideNavbar">
                            <Sidenav />
                        </Col>
                    )}
                    <Col className="header" lg={headerShow ? "10" : "12"}>
                        <Container fluid>
                            <Row className="pt-1 sub-header align-items-center justify-content-between">
                                <Col md="8" sm="1" xs="10" className="py-2 d-flex align-items-center">
                                    <button style={{ border: "none" }} className="hideNavbar1" onClick={handleShow}><img src={menu} width={20} /></button>
                                    <button style={{ border: "none" }} className="hide_Navbar" onClick={() => setShow(true)}><img src={menu} width={20} /></button>
                                    <h5 className="ms-2 mt-2">Overview</h5>
                                </Col>
                                <Col md="1" sm="1" xs="10" className="py-2 d-flex align-items-center text-end ms-auto">
                                    <BackBtn style={{ border: "none" }} className="hideNavbar1" lable="Back" onClick={backFn} />
                                </Col>
                                <Col md="1" sm="1" xs="2" className="text-end profile_dropdown d-flex align-items-center justify-content-end">
                                    {/* <AiFillAlert className="me-3" size="30" color="red" onClick={treatmentAlert} /> */}
                                    {
                                        treatmentAlertList.length > 0 && <Badge badgeContent={treatmentAlertList.length > 0 ? treatmentAlertList.length : 0} color="primary">
                                            <AiFillAlert
                                                className={`me-3 animated-icon`}
                                                size="30"
                                                color="red"
                                                onClick={() => {
                                                    treatmentAlert();
                                                    setAlertModal(true);
                                                }}
                                            />
                                        </Badge>
                                    }
                                    <img src={profile} width={40} />
                                    <div className="dropdpwn_Open">
                                        <Row className="text-center align-items-center">
                                            <Col xs="12" className="menuHover mb-3">
                                                <Row >
                                                    <Col className="" xs="3">
                                                        <span className=""><MdOutlineVpnKey size={25} />
                                                        </span>
                                                    </Col>
                                                    <Col className="text-start" xs="9" onClick={modalOpenClick}>
                                                        Change Password
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col xs="12" className="menuHover" onClick={logOut}>
                                                <Row>
                                                    <Col className="" xs="3">
                                                        <span className=""><GoSignOut size={25} />
                                                        </span>
                                                    </Col>
                                                    <Col className="text-start" xs="9">
                                                        Sign Out
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="d-flex align-items-center justify-content-between">
                                <Col xl="2" xxl="1" lg="2" md="2" sm="3" xs="4" className="my-1 align-items-center justify-content-center">
                                    <div className='overview-card text-center align-items-center'>
                                        <img src={breeding} width={40} height={40} className="mt-2 mb-3" />
                                        <h5 className="m-0">{box1}</h5>
                                        <p>Total Registration</p>
                                    </div>
                                </Col>
                                <Col xl="2" xxl="1" lg="2" md="2" sm="3" xs="4" className="my-2 align-items-center justify-content-center">
                                    <div className='overview-card text-center align-items-center'>
                                        <img src={breeding} width={40} height={40} className="mt-2 mb-3" />
                                        <h5 className="mb-0">{box2}</h5>
                                        <p>Registration</p>
                                    </div>
                                </Col>
                                <Col xl="2" xxl="1" lg="2" md="2" sm="3" xs="4" className="my-2">
                                    <div className='overview-card text-center align-items-center'>
                                        <img src={over1} width={40} height={40} className="mt-2 mb-3" />
                                        <h5 className="mb-0">{box3}</h5>
                                        <p>Milk</p>
                                    </div>
                                </Col>
                                <Col xl="2" xxl="1" lg="2" md="2" sm="3" xs="4" className="my-2">
                                    <div className='overview-card text-center align-items-center'>
                                        <img src={over2} width={40} height={40} className="mt-2 mb-3" />
                                        <h5 className="mb-0">{box4}</h5>
                                        <p>A.I Done</p>
                                    </div>
                                </Col>
                                <Col xl="2" xxl="1" lg="2" md="2" sm="3" xs="4" className="my-2">
                                    <div className='overview-card text-center align-items-center'>
                                        <img src={over3} width={40} height={40} className="mt-2 mb-3" />
                                        <h5 className="mb-0">{box5}</h5>
                                        <p>PD Checked</p>
                                    </div>
                                </Col>
                                <Col xl="2" xxl="1" lg="2" md="2" sm="3" xs="4" className="my-2">
                                    <div className='overview-card text-center align-items-center'>
                                        <img src={over4} width={40} height={40} className="mt-2 mb-3" />
                                        <h5 className="mb-0">{box6}</h5>
                                        <p>Calving Done</p>
                                    </div>
                                </Col>
                                <Col xl="2" xxl="1" lg="2" md="2" sm="3" xs="4" className="my-2">
                                    <div className='overview-card text-center align-items-center'>
                                        <img src={over5} width={40} height={40} className="mt-2 mb-3" />
                                        <h5 className="mb-0">{box7}</h5>
                                        <p>Dry off Recorded</p>
                                    </div>
                                </Col>
                                <Col xl="2" xxl="1" lg="2" md="2" sm="3" xs="4" className="my-2">
                                    <div className='overview-card text-center align-items-center'>
                                        <img src={over6} width={40} height={40} className="mt-2 mb-3" />
                                        <h5 className="mb-0">{box8}</h5>
                                        <p>Treatment Done</p>
                                    </div>
                                </Col>
                                <Col xl="2" xxl="1" lg="2" md="2" sm="3" xs="4" className="my-2">
                                    <div className='overview-card text-center align-items-center'>
                                        <img src={over9} width={40} height={40} className="mt-2 mb-3" />
                                        <h5 className="mb-0">{box11}</h5>
                                        <p>Weight Recorder</p>
                                    </div>
                                </Col>
                                <Col xl="2" xxl="1" lg="2" md="2" sm="3" xs="6" className="my-2">
                                    <div className='overview-card text-center align-items-center'>
                                        <img src={over10} width={40} height={40} className="mt-2 mb-3" />
                                        <h5 className="mb-0">{box12 ? box12 : "0"}</h5>
                                        <p>Visit Recorded</p>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="align-items-end justify-content-between">
                                <Col xl="1" lg="2" md="2"><button className="btn-blue mt-2" onClick={healthBtnFn}>Health</button></Col>
                                <Col xl="1" lg="2" md="2"><button className="btn-yellow mt-2" onClick={BreedingBtnFn}>Breeding</button></Col>
                                <Col xl="1" lg="2" md="2"><button className="btn-perot mt-2" onClick={ProjectBtnFn}>Project</button></Col>
                                <Col></Col>
                                <Col xl="1" lg="2" md="2"><LightBtn lable="Filter" onClick={() => setModalShow(true)} className="mt-2" style={{ height: "35px" }} /></Col>
                            </Row>
                            {healthBtn && (
                                <>
                                    <Row className="align-items-start">
                                        <Col lg="6" md="6" className="mt-2">
                                            <div className="chartBorder p-2">
                                                <h5 className="ms-2">Animal Health</h5>
                                                {/* <hr /> */}
                                                <Row>
                                                    <Col className="mx-2 pt-2 pb-3" style={{ overflow: "auto" }}>
                                                        <table className="overTable">
                                                            <tr className="overTableheader">
                                                                <td className="text-center overTable-Total">Today</td>
                                                                <td className="text-center overTable-Total" colSpan={4}>Current Status</td>
                                                            </tr>
                                                            <tr className="overTableBody">
                                                                <td className="overTableH1 overTable-Total ps-3">Type of Visit</td>
                                                                <td className="overTableH2 overTable-Total text-center">Booked</td>
                                                                <td className="overTableH3 overTable-Total text-center">Completed</td>
                                                                <td className="overTableH4 overTable-Total text-center">Pending</td>
                                                                <td className="overTableH5 overTable-Total text-center">Canceled</td>
                                                            </tr>
                                                            {animalHeight.map((e) =>
                                                                < tr className="overTableBody" >
                                                                    <td className="ps-3 overTable-Total">{e.VisitType}</td>
                                                                    <td className="text-center overTable-Total">{e.Booked}</td>
                                                                    <td className="text-center overTable-Total">{e.Completed}</td>
                                                                    <td className="text-center overTable-Total">{e.Pending}</td>
                                                                    <td className="text-center overTable-Total">{e.Cancled}</td>
                                                                </tr>
                                                            )}
                                                        </table>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Col>
                                        <Col lg="6" md="6" className="mt-2">
                                            <div className="chartBorder p-2">
                                                <h5 className="ms-2">Visit Status</h5>
                                                <ResponsiveContainer aspect={3}>
                                                    <BarChart layout="vertical" data={animalHeight} margin={{ right: 0, left: 50, top: 0 }}>
                                                        <CartesianGrid />
                                                        <XAxis type="number" domain={[0, 1000]} />
                                                        <YAxis dataKey="VisitType" type="category" style={{ fontSize: "12px" }} />
                                                        <Bar dataKey="Booked" barSize={20}>
                                                            {animalHeight.map((entry, index) => (<Cell key={`cell-${index}`} fill={VISITCOLOR[index % 20]} />))}
                                                        </Bar>
                                                        <Tooltip contentStyle={{ fontSize: "12px", backgroundColor: "white" }} cursor={{ fill: 'transparent' }} />
                                                        <Legend />
                                                    </BarChart>
                                                </ResponsiveContainer>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className="align-items-start">
                                        <Col lg="6" md="6" className="mt-3">
                                            <div className="chartBorder p-2">
                                                <h5>Average Time In Attending Visits</h5>
                                                <ResponsiveContainer aspect={3} className="pb-2 pt-3">
                                                    <BarChart layout="vertical" data={barAvgTime} margin={{ right: 0, left: 50, top: 0 }}>
                                                        <CartesianGrid />
                                                        <XAxis />
                                                        <YAxis dataKey="VisitType" type="category" style={{ fontSize: "12px" }} />
                                                        <YAxis />
                                                        <Tooltip contentStyle={{ fontSize: "12px", backgroundColor: "white" }} cursor={{ fill: 'transparent' }} />
                                                        <Legend />
                                                        <Bar dataKey="AVG" fill="#8FA9F5">
                                                            {barAvgTime.map((entry, index) => (<Cell key={`cell-${index}`} fill={VISITCOLOR[index % 20]} />))}
                                                        </Bar>
                                                    </BarChart>
                                                </ResponsiveContainer>
                                            </div>
                                        </Col>
                                        <Col lg="6" md="6" className="mt-3">
                                            <div className="chartBorder p-2">
                                                <h5>No of Beneficiaries</h5>
                                                <ResponsiveContainer aspect={3} className="pb-2 pt-3">
                                                    <BarChart data={data} margin={{ right: 0, left: 0, top: 0 }}>
                                                        <CartesianGrid />
                                                        <XAxis dataKey="name" style={{ fontSize: "13px" }} />
                                                        <YAxis type="number" domain={[0, 1000]} />
                                                        <Tooltip contentStyle={{ fontSize: "12px", backgroundColor: "white" }} cursor={{ fill: 'transparent' }} />
                                                        <Bar dataKey={" "} fill="#8FA9F5">
                                                            {data.map((entry, index) => (<Cell key={`cell-${index}`} fill={VISITCOLOR[index % 20]} />))}
                                                        </Bar>
                                                    </BarChart>
                                                </ResponsiveContainer>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className="align-items-start">
                                        <Col lg="6" md="6" className="mt-3">
                                            <div className="chartBorder p-2">
                                                <h5>No of Total Visits</h5>
                                            </div>
                                        </Col>
                                        <Col lg="6" md="6" className="mt-3">
                                            <div className="chartBorder p-2">
                                                <h5>Animal Health</h5>
                                                <ResponsiveContainer aspect={3} className="pb-2 pt-3">
                                                    <PieChart>
                                                        <Legend />
                                                        <Pie outerRadius={80} label={renderCustomizedLabel} fill="#8884d8" labelLine={false} data={piaHealth} dataKey="Animals_Unique">
                                                            {piaHealth.map((entry, index) => (<Cell key={`cell-${index}`} fill={COLORS[index % 20]} />))}
                                                        </Pie>
                                                        <Tooltip contentStyle={{ fontSize: "14px", backgroundColor: "white" }} cursor={{ fill: 'transparent' }} />
                                                    </PieChart>
                                                </ResponsiveContainer>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className="align-items-start">
                                        <Col md="6" className="mt-3">
                                            <div className="chartBorder p-2">
                                                <Row className="align-items-start justify-content-between">
                                                    <Col xl="1" className="ms-2"><h5>Km/Vo</h5></Col>
                                                    <Col xl="3" className="ms-1"><h5>No of Vo :~ {noVo}</h5></Col>
                                                </Row>
                                                <ResponsiveContainer aspect={3} className="pb-2 pt-3">
                                                    <BarChart data={barKmVo} margin={{ right: 0, left: 0, top: 0 }}>
                                                        <CartesianGrid />
                                                        <XAxis dataKey="Center" style={{ fontSize: "10px" }} />
                                                        <YAxis />
                                                        <Tooltip contentStyle={{ fontSize: "12px", backgroundColor: "white" }} cursor={{ fill: 'transparent' }} />
                                                        <Legend />
                                                        <Bar dataKey="NoOfVisit" fill="#8FA9F5" />
                                                    </BarChart>
                                                </ResponsiveContainer>
                                            </div>
                                        </Col>
                                        <Col md="6" className="mt-3">
                                            <div className="chartBorder p-2">
                                                <Row className="align-items-start justify-content-between">
                                                    <Col xl="5" className="ms-2"><h5>Vist/Vo & KM/Visit</h5></Col>
                                                    <Col xl="3" className="ms-auto"><h5>No of Vehical :~ {noVehical}</h5></Col>
                                                </Row>
                                                <ResponsiveContainer aspect={3} className="pb-2 pt-3">
                                                    <BarChart data={barVisitVo} margin={{ right: 0, left: 0, top: 0 }}>
                                                        <CartesianGrid />
                                                        <XAxis dataKey="zone" style={{ fontSize: "10px" }} />
                                                        <YAxis />
                                                        <Tooltip contentStyle={{ fontSize: "12px", backgroundColor: "white" }} cursor={{ fill: 'transparent' }} />
                                                        <Legend />
                                                        <Bar dataKey="visit" fill="#8FA9F5" />
                                                        <Bar dataKey="km" fill="#8A45E2" />
                                                    </BarChart>
                                                </ResponsiveContainer>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className="align-items-center">
                                        <Col md="12" className="mt-3" style={{ width: "100%" }}>
                                            <div className="chartBorder p-2">
                                                <h5>Cattle Registration</h5>
                                                <ResponsiveContainer aspect={5} className="pb-2 pt-3">
                                                    <BarChart data={graphByDate} margin={{ right: 0, left: 0, top: 0 }}>
                                                        <CartesianGrid />
                                                        <XAxis dataKey="username" style={{ fontSize: "10px" }} />
                                                        <YAxis />
                                                        <Tooltip contentStyle={{ fontSize: "12px", backgroundColor: "white" }} cursor={{ fill: 'transparent' }} />
                                                        <Legend />
                                                        <Bar dataKey="animal_nos" fill="#8FA9F5" />
                                                    </BarChart>
                                                </ResponsiveContainer>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row className="align-items-start mb-3">
                                        <Col xs="12" sm="12" md="5" className="mt-3">
                                            <div className="chartBorder tableHight p-2">
                                                <h5>Animal Records</h5>
                                                <Table columns={colums} data={animalRecordList} height="28vh" pagination />
                                            </div>
                                        </Col>
                                        <Col xs="12" sm="12" md="7" className="mt-3">
                                            <div className="chartBorder tableHight p-2">
                                                <h5>User Activity</h5>
                                                <Table columns={activitycolums} data={activityList} height="28vh" pagination />
                                            </div>
                                        </Col>
                                    </Row>
                                </>
                            )}
                            {breedingBtn && (
                                <>
                                    <Row className="align-items-start">
                                        <Col lg="6" md="6" className="mt-2">
                                            <div className="chartBorder p-2">
                                                <h5 className="ms-2">AI Details</h5>
                                                {/* <hr /> */}
                                                <Row>
                                                    <Col className="mx-2 pt-2 pb-3" style={{ overflow: "auto" }}>
                                                        <table className="overTable">
                                                            <tr className="overTableheader">
                                                                <td className="text-center overTable-Total ms-2">Today</td>
                                                                <td className="text-center overTable-Total" colSpan={4}>Current Status</td>
                                                            </tr>
                                                            <tr className="overTableBody">
                                                                <td className="overTableH1 overTable-Total ps-3">Type of Visit</td>
                                                                <td className="overTableH2 overTable-Total text-center">Booked</td>
                                                                <td className="overTableH3 overTable-Total text-center">Completed</td>
                                                                <td className="overTableH4 overTable-Total text-center">Pending</td>
                                                                <td className="overTableH5 overTable-Total text-center">Canceled</td>
                                                            </tr>
                                                            {aiDetailsList.map((e) =>
                                                                < tr className="overTableBody" >
                                                                    <td className="ps-3 overTable-Total">{e.VisitType}</td>
                                                                    <td className="text-center overTable-Total">{e.Booked}</td>
                                                                    <td className="text-center overTable-Total">{e.Completed}</td>
                                                                    <td className="text-center overTable-Total">{e.Pending}</td>
                                                                    <td className="text-center overTable-Total">{e.Cancled}</td>
                                                                </tr>
                                                            )}
                                                        </table>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Col>
                                        {/* <Col lg="6" md="6" className="mt-2">
                                            <div className="bgForChart chartBorder p-2">
                                                <h5>AI Reg & Attending time</h5>
                                                <ReactApexChart
                                                    options={{
                                                        chart: {
                                                            type: 'bar',
                                                            height: 350,
                                                            stacked: true
                                                        },
                                                        plotOptions: {
                                                            bar: {
                                                                horizontal: false,
                                                                columnWidth: '35%',
                                                                endingShape: 'rounded'
                                                            },
                                                        },
                                                        dataLabels: {
                                                            enabled: false
                                                        },
                                                        stroke: {
                                                            show: true,
                                                            width: 2,
                                                            colors: ['transparent']
                                                        },
                                                        xaxis: {
                                                            categories: chart12List.map((e) => `${moment(e.Date).format('DD-MM-YYYY')}`),
                                                        },
                                                        yaxis: {
                                                            title: {
                                                                text: '% AI'
                                                            }
                                                        },
                                                        fill: {
                                                            opacity: 1
                                                        },
                                                        tooltip: {
                                                            y: {
                                                                formatter: function (val) {
                                                                    return val
                                                                }
                                                            }
                                                        }
                                                    }}
                                                    series={[
                                                        {
                                                            name: 'AI',
                                                            data: chart12List.map((e) => e.AI)
                                                        },
                                                        {
                                                            name: 'PD',
                                                            data: chart12List.map((e) => e.PD)
                                                        },
                                                    ]}
                                                    type="bar"
                                                    height={350}
                                                />
                                            </div>
                                        </Col> */}
                                        <Col lg="6" md="6" className="mt-2">
                                            <div className="bgForChart chartBorder p-2">
                                                <h4>Animal Breeding</h4>
                                                <ReactApexChart
                                                    options={{
                                                        chart: {
                                                            stacked: true,
                                                            // stackType: "100%"
                                                        },
                                                        plotOptions: {
                                                            bar: {
                                                                horizontal: true,
                                                                columnWidth: '35%',
                                                                endingShape: 'rounded'
                                                            },
                                                            // Set stacked option to true for stacked bar chart
                                                            stacked: true,
                                                        },
                                                        dataLabels: {
                                                            enabled: true
                                                        },
                                                        stroke: {
                                                            show: true,
                                                            width: 2,
                                                            colors: ['transparent']
                                                        },
                                                        xaxis: {
                                                            categories: aiDetailsList.map((e) => e.VisitType),
                                                        },
                                                        yaxis: {
                                                            title: {
                                                                text: 'VisitType (%)'
                                                            }
                                                        },
                                                        fill: {
                                                            opacity: 1
                                                        },
                                                        tooltip: {
                                                            y: {
                                                                formatter: function (val) {
                                                                    return val
                                                                }
                                                            }
                                                        },
                                                        colors: ['rgb(0, 143, 251)', '#FF5733', '#33FF57', '#FFFF00']
                                                    }}
                                                    series={[
                                                        {
                                                            name: 'Booked',
                                                            data: aiDetailsList.map((e) => e.Booked)
                                                        },
                                                        {
                                                            name: 'Cancled',
                                                            data: aiDetailsList.map((e) => e.Cancled)
                                                        },
                                                        {
                                                            name: 'Completed',
                                                            data: aiDetailsList.map((e) => e.Completed)
                                                        },
                                                        {
                                                            name: 'Pending',
                                                            data: aiDetailsList.map((e) => e.Pending)
                                                        },
                                                    ]}
                                                    type="bar"
                                                    height={350}
                                                />
                                            </div>
                                        </Col>


                                        <Col lg="6" md="6" className="mt-2">
                                            <div className="bgForChart chartBorder p-2">
                                                <h5>Nos. of AI & PD</h5>
                                                <ReactApexChart
                                                    options={{
                                                        chart: {
                                                            height: 350,
                                                            type: 'line',
                                                            dropShadow: {
                                                                enabled: true,
                                                                color: '#000',
                                                                top: 18,
                                                                left: 7,
                                                                blur: 10,
                                                                opacity: 0.2
                                                            },
                                                            toolbar: {
                                                                show: false
                                                            }
                                                        },
                                                        colors: ['#77B6EA', '#545454'],
                                                        dataLabels: {
                                                            enabled: true,
                                                        },
                                                        stroke: {
                                                            curve: 'smooth'
                                                        },
                                                        title: {
                                                            text: '',
                                                            align: 'left'
                                                        },
                                                        grid: {
                                                            borderColor: '#e7e7e7',
                                                            row: {
                                                                colors: ['#f3f3f3', 'transparent'],
                                                                opacity: 0.5
                                                            },
                                                        },
                                                        markers: {
                                                            size: 1
                                                        },
                                                        xaxis: {
                                                            categories: chart12List.map((entry) => moment(entry.Date).format("DD-MM-YYYY")),
                                                            title: {
                                                                text: 'Date'
                                                            }
                                                        },
                                                        yaxis: {
                                                            title: {
                                                                text: 'Temperature'
                                                            },
                                                        },
                                                        legend: {
                                                            position: 'top',
                                                            horizontalAlign: 'right',
                                                            floating: true,
                                                            offsetY: -25,
                                                            offsetX: -5
                                                        }
                                                    }}
                                                    series={[
                                                        {
                                                            name: "AI",
                                                            data: chart12List.map((entry) => entry.AI)
                                                        },
                                                        {
                                                            name: "PD",
                                                            data: chart12List.map((entry) => entry.PD)
                                                        }
                                                    ]}
                                                    type="line"
                                                    height={350}
                                                />
                                            </div>
                                        </Col>



                                        {/* <Col lg="6" md="6" className="mt-2">
                                            <div className="bgForChart chartBorder p-2">
                                                <h5>Type of Semen in AI</h5>
                                                <ReactApexChart
                                                    options={{
                                                        chart: {
                                                            type: 'bar',
                                                            height: 350,
                                                            stacked: true
                                                        },
                                                        plotOptions: {
                                                            bar: {
                                                                horizontal: false,
                                                                columnWidth: '35%', 
                                                                endingShape: 'rounded'
                                                            },
                                                        },
                                                        dataLabels: {
                                                            enabled: false
                                                        },
                                                        stroke: {
                                                            show: true,
                                                            width: 2,
                                                            colors: ['transparent']
                                                        },
                                                        xaxis: {
                                                            categories: chart14List.map(item => item.VisitType),
                                                        },
                                                        yaxis: {
                                                            title: {
                                                                text: '% AI'
                                                            }
                                                        },
                                                        fill: {
                                                            opacity: 1
                                                        },
                                                        tooltip: {
                                                            y: {
                                                                formatter: function (val) {
                                                                    return val
                                                                }
                                                            }
                                                        }
                                                    }}
                                                    series={[
                                                        {
                                                            name: 'Normal',
                                                            data: chart14List.map(item => item.AVG)
                                                        }
                                                    ]}
                                                    type="bar"
                                                    height={350}
                                                />
                                            </div>
                                        </Col> */}
                                        <Col lg="6" md="6" className="mt-2">
                                            <div className="bgForChart chartBorder p-2">
                                                <h5>Type of Semen in AI</h5>
                                                <ReactApexChart
                                                    options={{
                                                        chart: {
                                                            type: 'bar',
                                                            height: 350,
                                                            stacked: true,
                                                            // stackType: '100%',
                                                        },
                                                        plotOptions: {
                                                            bar: {
                                                                horizontal: false,
                                                                columnWidth: '35%',
                                                                endingShape: 'rounded',
                                                            },
                                                        },
                                                        dataLabels: {
                                                            enabled: true,
                                                        },
                                                        stroke: {
                                                            show: true,
                                                            width: 2,
                                                            colors: ['transparent'],
                                                        },
                                                        xaxis: {
                                                            categories: ['Cow', 'Buffalo'],
                                                        },
                                                        yaxis: {
                                                            title: {
                                                                text: '% AI',
                                                            },
                                                        },
                                                        fill: {
                                                            opacity: 1,
                                                        },
                                                        tooltip: {
                                                            y: {
                                                                formatter: function (val) {
                                                                    return val;
                                                                },
                                                            },
                                                        },
                                                        colors: ['#21aaff', '#ff0000']
                                                    }}
                                                    series={[
                                                        {
                                                            name: 'Cow',
                                                            data: [
                                                                {
                                                                    x: 'Cow',
                                                                    y: chart13List.find((e) => e[''] === 'Cow Normal')?.Normal,
                                                                },
                                                                {
                                                                    x: 'Buffalo',
                                                                    y: chart13List.find((e) => e[''] === 'Buffalo Normal')?.Normal,
                                                                },
                                                            ],
                                                        },
                                                        {
                                                            name: 'Buffalo',
                                                            data: [
                                                                {
                                                                    x: 'Cow',
                                                                    y: chart13List.find((e) => e[''] === 'Cow Sorted')?.Normal,
                                                                },
                                                                {
                                                                    x: 'Buffalo',
                                                                    y: chart13List.find((e) => e[''] === 'Buffalo Sorted ')?.Normal,
                                                                },
                                                            ],
                                                        },
                                                    ]}

                                                    type="bar"
                                                    height={350}
                                                />
                                            </div>
                                        </Col>



                                        <Col lg="6" md="6" className="mt-2">
                                            <div className="bgForChart chartBorder p-2">
                                                <h5>Al Request</h5>
                                                <ReactApexChart
                                                    options={{
                                                        chart: {
                                                            type: 'bar',
                                                            height: 350,
                                                        },
                                                        plotOptions: {
                                                            bar: {
                                                                horizontal: true,
                                                                columnWidth: '65%',
                                                                endingShape: 'rounded',
                                                                barHeight: '40%',

                                                            },
                                                        },
                                                        // colors:['#47c0c4'],
                                                        dataLabels: {
                                                            // enabled: false,
                                                        },
                                                        stroke: {
                                                            show: true,
                                                            width: 2,
                                                            colors: ['transparent'],
                                                        },
                                                        xaxis: {
                                                            categories: chart14List.map((e) => e.VisitType),
                                                        },
                                                        yaxis: {
                                                            title: {
                                                                text: 'VisitType (%)'
                                                            }
                                                        },
                                                        fill: {
                                                            opacity: 1,
                                                        },
                                                        tooltip: {
                                                            x: {
                                                                formatter: function (val) {
                                                                    return val;
                                                                },
                                                            },
                                                        },
                                                    }}
                                                    series={[
                                                        {
                                                            name: 'AVG',
                                                            data: chart14List.map(item => parseFloat(item.AVG)),
                                                        },
                                                    ]}
                                                    type="bar"
                                                    height={350}
                                                />
                                            </div>
                                        </Col>

                                        <Col lg="6" md="6" className="mt-2">
                                            <div className="bgForChart chartBorder p-2">
                                                <h5>Al Request</h5>
                                                <ReactApexChart
                                                    options={{
                                                        chart: {
                                                            type: 'bar',
                                                            height: 350,
                                                        },
                                                        plotOptions: {
                                                            bar: {
                                                                horizontal: true,
                                                                columnWidth: '65%',
                                                                endingShape: 'rounded',
                                                                barHeight: '100%',
                                                            },
                                                        },
                                                        // colors:['#3273a8'],
                                                        dataLabels: {
                                                            // enabled: false,
                                                        },
                                                        stroke: {
                                                            show: true,
                                                            width: 2,
                                                            colors: ['transparent'],
                                                        },
                                                        xaxis: {
                                                            categories: [chart15List.map((e) => e[""]),]
                                                        },
                                                        yaxis: {
                                                            title: {
                                                                text: ''
                                                            }
                                                        },
                                                        fill: {
                                                            opacity: 1,
                                                        },
                                                        tooltip: {
                                                            x: {
                                                                formatter: function (val) {
                                                                    return val;
                                                                },
                                                            },
                                                        },
                                                    }}
                                                    series={[
                                                        {
                                                            name: 'Animal A.I Done',
                                                            data: chart15List.map(item => parseFloat(item["Animal A.I Done"])),
                                                        },
                                                        {
                                                            name: 'Farmer Benifited',
                                                            data: chart15List.map(item => parseFloat(item["Farmer Benifited"])),
                                                        },
                                                        {
                                                            name: 'Farmer Total  Benifited',
                                                            data: chart15List.map(item => parseFloat(item["Farmer Total  Benifited"])),
                                                        },
                                                        {
                                                            name: 'DCS Benifited',
                                                            data: chart15List.map(item => parseFloat(item["DCS Benifited"])),
                                                        },
                                                        {
                                                            name: 'DCS Total  Benifited',
                                                            data: chart15List.map(item => parseFloat(item["DCS Total  Benifited"])),
                                                        },
                                                    ]}
                                                    type="bar"
                                                    height={350}
                                                />
                                            </div>
                                        </Col>


                                        {/* <Col lg="6" md="6" className="mt-2">
                                            <div className="bgForChart chartBorder p-2">
                                                <h6 className="ms-2">Al Distribution</h6>
                                                <ReactApexChart
                                                    series={[{
                                                        data: [0, 10, 30, 50, 70, 90]
                                                    }]}
                                                    options={{
                                                        chart: {
                                                            type: 'bar',
                                                            height: 250
                                                        },
                                                        plotOptions: {
                                                            bar: {
                                                                borderRadius: 4,
                                                                horizontal: true,
                                                            }
                                                        },
                                                        dataLabels: {
                                                            formatter: (val) => {
                                                                return val + '%'
                                                            }
                                                        },
                                                        xaxis: {
                                                            categories: ['Proprtion of AI in Primary DCS', 'Proportion of AI in allocated DCS', 'Proportion of AI in allocated DCS', 'Proportion of AI in allocated DCS', 'Proportion of AI in allocated DCS'],
                                                        }
                                                    }}
                                                    type="bar" height={350} />
                                            </div>
                                        </Col> */}
                                        {/* <Col lg="6" md="6" className="mt-2">
                                            <div className="bgForChart chartBorder p-2">
                                                <h6 className="ms-2">Beneficiaries</h6>
                                                <ReactApexChart
                                                    series={[{
                                                        data: [0, 10, 30, 50, 70, 90]
                                                    }]}
                                                    options={{
                                                        chart: {
                                                            type: 'bar',
                                                            height: 250
                                                        },
                                                        plotOptions: {
                                                            bar: {
                                                                borderRadius: 4,
                                                                horizontal: true,
                                                            }
                                                        },
                                                        dataLabels: {
                                                            formatter: (val) => {
                                                                return val + '%'
                                                            }
                                                        },
                                                        xaxis: {
                                                            categories: ['Proprtion of AI in Primary DCS', 'Proportion of AI in allocated DCS', 'Proportion of AI in allocated DCS', 'Proportion of AI in allocated DCS', 'Proportion of AI in allocated DCS'],
                                                        }
                                                    }}
                                                    type="bar" height={350} />
                                            </div>
                                        </Col> */}
                                    </Row>
                                </>
                            )}
                        </Container>
                    </Col>
                </Row>
            </Container>

            {show && (<Offcanvas className="hide_Navbar" show={show} onHide={handleClose}><Container><Row><Col><Sidenav /></Col></Row></Container></Offcanvas>)}

            <Modal size="xl" fullscreen aria-labelledby="contained-modal-title-vcenter" centered show={alertModal} onHide={() => setAlertModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">Treatment Alert (Total:{treatmentAlertList.length})</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container fluid>
                        <Row className="align-items-center justify-content-center">
                            <Col>
                                {treatmentAlertList.length > 25 ? <Table columns={treatmentColum} data={treatmentAlertList} pagination height="69vh" />
                                    :
                                    <Table columns={treatmentColum} data={treatmentAlertList} height="55vh" />}
                            </Col>
                            {/* <Col sm="6">
                                <sub>Visit ID</sub>
                                <input type="text" className="dateSelect_bg" disabled defaultValue={visitId} onChange={e => setVisitId(e.target.value)} />
                            </Col>
                            <Col sm="6">
                                <sub>Request Date</sub>
                                <input type="text" className="dateSelect_bg" disabled defaultValue={requestDate} onChange={e => setRequestDate(e.target.value)} />
                            </Col>
                            <Col sm="6">
                                <sub>Request Time</sub>
                                <input type="text" className="dateSelect_bg" disabled defaultValue={requestTime} onChange={e => setRequestTime(e.target.value)} />
                            </Col>
                            <Col sm="6">
                                <sub>DCS Code</sub>
                                <input type="text" className="dateSelect_bg" disabled defaultValue={dcsCode} onChange={e => setDCSCode(e.target.value)} />
                            </Col>
                            <Col sm="6">
                                <sub>DCS Name</sub>
                                <input type="text" className="dateSelect_bg" disabled defaultValue={dcsName} onChange={e => setDcsName(e.target.value)} />
                            </Col>
                            <Col sm="6">
                                <sub>Farmer Name</sub>
                                <input type="text" className="dateSelect_bg" disabled defaultValue={farmerName} onChange={e => setFarmerName(e.target.value)} />
                            </Col>
                            <Col sm="6">
                                <sub>Farmer MobileNumber</sub>
                                <input type="text" className="dateSelect_bg" defaultValue={farmerMobileNumber} onChange={e => setFarmerMobileNumber(e.target.value)} />
                            </Col>
                            <Col sm="6">
                                <sub>Center Name</sub>
                                <input type="text" className="dateSelect_bg" disabled defaultValue={centerName} value={centerName} onChange={e => setCenterName(e.target.value)} />
                            </Col>
                            <Col sm="6">
                                <sub>Route Code</sub>
                                <input type="text" className="dateSelect_bg" disabled defaultValue={routeCode} onChange={e => setRouteCode(e.target.value)} />
                            </Col>
                            <Col sm="6">
                                <sub>Route Name</sub>
                                <input type="text" className="dateSelect_bg" disabled defaultValue={routeName} onChange={e => setRouteName(e.target.value)} />
                            </Col>
                            <Col sm="6">
                                <sub>visitType</sub>
                                <input type="text" className="dateSelect_bg" disabled defaultValue={visitType} onChange={e => setVisitType(e.target.value)} />
                            </Col>
                            <Col sm="6">
                                <sub>VO Name</sub>
                                <input type="text" className="dateSelect_bg" disabled defaultValue={voName} onChange={e => setVoName(e.target.value)} />
                            </Col>
                            <Col sm="6">
                                <sub>Contact No</sub>
                                <input type="text" className="dateSelect_bg" disabled defaultValue={contactNo} onChange={e => setContactNo(e.target.value)} />
                            </Col>
                            <Col>
                                <sub>Animal IDno</sub>
                                <input type="text" className="dateSelect_bg" disabled defaultValue={animalIdNo} onChange={e => setAnimalIdNo(e.target.value)} />
                            </Col >
                            <Col sm="6">
                                <sub>Disesese Code</sub>
                                <input type="text" className="dateSelect_bg" disabled defaultValue={diseseseCode} onChange={e => setDiseseseCode(e.target.value)} />
                            </Col>
                            <Col sm="6">
                                <sub>Disesese Name</sub>
                                <input type="text" className="dateSelect_bg" disabled defaultValue={diseseseName} onChange={e => setDiseseseName(e.target.value)} />
                            </Col> */}
                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Container>
                        <Row className="justify-content-end">
                            <Col xs="6" xxl="1" sm="3" md="2" lg="1">
                                <button className="btn-blue-Animal mt-1 mb-1" onClick={handleDownloadExcel}>Export</button>
                            </Col>
                            <Col xs="6" xxl="1" sm="3" md="2" lg="1"><button className="btn-blue-Animal mt-1" onClick={() => setAlertModal(false)}>Close</button></Col>
                        </Row>
                    </Container>
                </Modal.Footer>
            </Modal>

            <Modal aria-labelledby="contained-modal-title-vcenter" centered show={modalShow} onHide={() => setModalShow(false)}>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">Filter</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Row className="align-items-center justify-content-center">
                            <Col>
                                <sub>From Date</sub>
                                <input type="date" className="dateSelect_bg" defaultValue={selectedFromDate} onChange={e => setFromDate(e.target.value)} />
                            </Col>
                            <Col>
                                <sub>To Date</sub>
                                <input type="date" className="dateSelect_bg" defaultValue={selectedToDate} onChange={e => setToDate(e.target.value)} />
                            </Col>
                        </Row>
                        <Row className="align-items-center justify-content-center">
                            <Col className="mt-2">
                                <RadioGroup row aria-labelledby="demo-row-radio-buttons-group-label" value={selectDMY} name="row-radio-buttons-group">
                                    <FormControlLabel value="Year" control={<Radio className="p-0 pe-2" />} label="Year" onChange={e => setSelectDMY(e.target.value)} className="mt-1" />
                                    <FormControlLabel value="Monthly" control={<Radio className="p-0 pe-2" />} label="Monthly" onChange={e => setSelectDMY(e.target.value)} className="mt-1" />
                                    <FormControlLabel value="Date" control={<Radio className="p-0 pe-2" />} label="Date" onChange={e => setSelectDMY(e.target.value)} className="mt-1" />
                                </RadioGroup>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Container>
                        <Row className="justify-content-end">
                            <Col xl="3"><button className="btn-blue-Animal mt-1" onClick={() => ApplyFn()}>Apply</button></Col>
                        </Row>
                    </Container>
                </Modal.Footer>
            </Modal>

            <Modal aria-labelledby="contained-modal-title-vcenter" centered show={remarksModal} onHide={() => setRemarksModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        <Container>
                            <Row className="align-items-center justify-content-center">
                                <Col className="justify-content-center">Add Remarks</Col>
                            </Row>
                        </Container></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Row className="align-items-center justify-content-center">
                            <Col className="passwordChange">
                                <input type="text" placeholder="Enter Remarks" value={remarks} onChange={e => setRemarks(e.target.value)} fullWidth variant="standard" />
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Container>
                        <Row className="justify-content-end">
                            <Col xl="3"><button className="btn-blue-Animal mt-1" onClick={alertComplete}>Add</button></Col>
                        </Row>
                    </Container>
                </Modal.Footer>
            </Modal>

            <Modal aria-labelledby="contained-modal-title-vcenter" centered show={modalPassword} onHide={() => setModalPassword(false)}>
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter" className="ms-auto">Change Password</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Row className="align-items-center justify-content-center">
                            <Col className="passwordChange">
                                <input type="text" placeholder="Enter Your Password" label="Enter Password" value={changePassword} onChange={e => setChangePassword(e.target.value)} fullWidth variant="standard" />
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Container>
                        <Row className="justify-content-center">
                            <Col xl="3"><button className="btn-blue-Animal m-auto" onClick={ApplyFn}>Confirm</button></Col>
                        </Row>
                    </Container>
                </Modal.Footer>
            </Modal>

            <ToastContainer
                position="top-center"
                autoClose={2000}
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
                style={{ fontSize: "14px" }}
            />
        </>
    )
};