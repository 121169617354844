import React, { useEffect, useState } from 'react'
import { Col, Container, Row, Offcanvas, Modal, Accordion } from "react-bootstrap";
import Sidenav from "../../Componet/Sidenav/Sidenav";
import menu from '../../Photo/menu.svg'
import profile from '../../Photo/profile.svg';
import Input from '../../Componet/InputFild/Input';
import Table from '../../Componet/DataTable/Table';
import {
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
} from "@mui/material";
import closeButton from "../../Photo/milk-union/close button.svg";
import { downloadExcel } from "react-export-table-to-excel";
import edit from "../../Photo/master/edit.svg";
import remove from "../../Photo/master/remove.svg";
import Dowunload from '../../Photo/Download.png'
import { MdOutlineVpnKey } from "react-icons/md";
import { GoSignOut } from "react-icons/go";

import { STORAGE_KEY } from "../../constant/common";
import { API_URLS, serviceUrl } from "../../API/Api";
import { borderRadius, margin } from '@mui/system';
import { useNavigate } from 'react-router-dom';
import Searcable from '../../Componet/Dropdown/Searcable';
const { DistributionAPi } = API_URLS;
const added_by = localStorage.getItem(STORAGE_KEY.jwtTOKEN);

async function getStaff(credentials) {
    return fetch(DistributionAPi.GetStaff, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            "Accept": "application/json",
            "Authorization": `Bearer ${added_by}`,
        },
        body: JSON.stringify(credentials)
    })
        .then(res => res.json());
}
async function getZone(credentials) {
    return fetch(DistributionAPi.GetZone, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            "Accept": "application/json",
            "Authorization": `Bearer ${added_by}`,
        },
        body: JSON.stringify(credentials)
    })
        .then(res => res.json());
}
async function getItem(credentials) {
    return fetch(DistributionAPi.GetItem, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            "Accept": "application/json",
            "Authorization": `Bearer ${added_by}`,
        },
        body: JSON.stringify(credentials)
    })
        .then(res => res.json());
}
async function getSupplier(credentials) {
    return fetch(DistributionAPi.GetSupplier, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            "Accept": "application/json",
            "Authorization": `Bearer ${added_by}`,
        },
        body: JSON.stringify(credentials)
    })
        .then(res => res.json());
}
async function editDetails(credentials) {
    return fetch(DistributionAPi.EditDetails, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            "Accept": "application/json",
            "Authorization": `Bearer ${added_by}`,
        },
        body: JSON.stringify(credentials)
    })
        .then(res => res.json());
}
async function VillageDataGet(credentials) {
    return fetch(DistributionAPi.VillageData, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            "Accept": "application/json",
            "Authorization": `Bearer ${added_by}`,
        },
        body: JSON.stringify(credentials)
    })
        .then(res => res.json());
}




const StrawDistribution = () => {
    const API_KEY = serviceUrl;
    const selectedToDate = new Date().toISOString().slice(0, 10);


    const [date, setDate] = useState(selectedToDate)
    const [staff, setStaff] = useState("")
    const [editId, setEditId] = useState("")
    const [bench, setBench] = useState("")
    const [qty, setQty] = useState("")
    const [sire, setSire] = useState("")
    const [semen, setSemen] = useState("")
    const [itemCode, setItemCode] = useState("")
    const [item, setItem] = useState("")
    const [supplied, setSupplied] = useState("")
    const [supplier, setSupplier] = useState()
    const [number, setNumber] = useState("")
    const [center, setCenter] = useState("")
    const [dcs, setDcs] = useState("")
    const [Code, setCode] = useState("")
    const navigate = useNavigate()
    const logOut = () => { navigate("/") }
    const [heightOffSet, setHeight] = useState(0);
    const [progressData, setProgressData] = useState("0%");
    const [isLoading, setIsLoading] = useState(false);

    const [headerShow, setHeaderShow] = useState(true);
    const [show, setShow] = useState(false);
    const handleClose = () => { setShow(false); }
    const handleShow = () => { setHeaderShow(!headerShow); }

    const [modalShow, setModalShow] = useState(false);

    const [distributionList, setDistributionList] = useState([])
    const [staffList, setStaffList] = useState([])
    const [sireList, setSireList] = useState([])
    const [semenList, setSemenList] = useState([])
    const [itemList, setItemList] = useState([])
    const [supplierList, setSupplierList] = useState([])
    const [centerList, setCenterList] = useState([])
    const [dcsList, setDcsList] = useState([])


    const SemenData = [
        { value: 'Normal Semen' },
        { value: 'Sex Semen' },
    ];

    const fetchData = async () => {
        setIsLoading(true)
        await fetch(`${API_KEY}/straw-management/getGridSemenSupply?staff=${staff}&billDate=${date}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`,
            },
        })
            .then((response) => {
                const contentLength = response.headers.get('content-length');
                let loaded = 0;
                return new Response(
                    new ReadableStream({
                        start(controller) {
                            const reader = response.body.getReader();
                            read();
                            function read() {
                                reader.read()
                                    .then((progressEvent) => {
                                        if (progressEvent.done) {
                                            controller.close();
                                            return;
                                        }
                                        loaded += progressEvent.value.byteLength;
                                        const percentageComplete = Math.round(loaded / contentLength * 100) + '%';
                                        setProgressData(percentageComplete)
                                        controller.enqueue(progressEvent.value);
                                        read();
                                    })
                            }
                        }
                    })
                );
            }
            )
            .then((res) => res.json())
            .then((result) => {
                setIsLoading(false)
                setDistributionList(result);
                setDcsList(result)
            })
            .catch((error) => { setIsLoading(false) })
    }


    const getStrawData = async () => {
        const res = await getStaff()
        const res1 = await getSupplier()
        const res2 = await getZone()
        const res3 = await getItem()


        if (res) { setStaffList(res) }
        if (res1) { setSupplierList(res1) }
        if (res2) { setCenterList(res2) }
        if (res3) { setItemList(res3) }


    }

    const editDetails = (e) => {
        console.log(e)
        getStrawData()
        setModalShow(true)
        setStaff(e["Staff.Code"])
    }
    useEffect(() => {
        let divHeight = document.getElementById("heightOffsetStock")?.offsetHeight;
    setHeight(divHeight);
        fetchData()
        getStrawData()
    }, [])



    const AddData = () => { }

    const Add = () => {
        getStrawData()
        setModalShow(true)
    }
    const Supplied = () => { }

    const colum = [
        {
            name: "Actions",
            cell: (e) => (
                <>
                    <div>
                        <img src={edit} onClick={() => { editDetails(e) }} />
                        <img src={remove} className='ms-1' /> {/* //  onClick={(e) => { deleteDetails(e) }} */}
                        <img src={Dowunload} className='ms-1' width="18" />{/* //  onClick={(e) => { saveDetails(e) }} */}
                    </div>
                </>
            ),
            width: "100px",
        },
        {
            name: "Request ID",
            selector: "Request ID",
            width: "90px",
        },
        {
            name: "Challan Number",
            selector: "Challan Number",
            width: "120px",
        },
        {
            name: "Staff.Code",
            cell: (e) => (<p className='p-0 m-0'>{e['Staff.Code']}</p>),
            width: "100px",
        },
        {
            name: "Staff Name",
            selector: "Staff Name",
        },
        {
            name: "Dcs Code",
            selector: "DCS Code",
            width: "100px",
        },
        {
            name: "Dcs Name",
            selector: "DCS Name",
        },
        {
            name: "Route",
            selector: "Route",
            width: "80px"
        },
        {
            name: "Semen",
            selector: "Semen"
        },
        {
            name: "Other Item",
            selector: "Other Item"
        },
        {
            name: "Supply",
            cell: (e) => (<button type="button" style={{ border: "none", boxShadow: "0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)", backgroundColor: "#43668E", color: "#FFFFFF", borderRadius: "5px", padding: "5px", margin: "5px" }} onClick={(e) => { Supplied() }}>Supplied</button>),
            width: "130px",
        },
    ]

    const handleDownloadExcel = () => {
        const opt = `Sement And Other Report ${date}`;
        const header = Object.keys(distributionList).map((key, e) => (key));

        downloadExcel({
            fileName: opt,
            sheet: "data",
            tablePayload: {
                header,
                body: distributionList
            }
        });
        
    }

    const filterStaff = async (e) => {
        await fetch(`${API_KEY}/straw-management/getGridSemenSupply?staff=${e}&billDate=${date}`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`,
            },
        })
            .then((response) => {
                const contentLength = response.headers.get('content-length');
                let loaded = 0;
                return new Response(
                    new ReadableStream({
                        start(controller) {
                            const reader = response.body.getReader();
                            read();
                            function read() {
                                reader.read()
                                    .then((progressEvent) => {
                                        if (progressEvent.done) {
                                            controller.close();
                                            return;
                                        }
                                        loaded += progressEvent.value.byteLength;
                                        const percentageComplete = Math.round(loaded / contentLength * 100) + '%';
                                        setProgressData(percentageComplete)
                                        controller.enqueue(progressEvent.value);
                                        read();
                                    })
                            }
                        }
                    })
                );
            }
            )
            .then((res) => res.json())
            .then((result) => {
                setDistributionList(result);
            });
    }

    const selectStaff = (e) => {
        console.log(e);
        setStaff(e)
        filterStaff(e)
    }

    return (
        <>
            <Container fluid>
                <Row>
                    {headerShow && (<Col lg="2" className="hideNavbar"> <Sidenav /></Col>)}
                    <Col className="header" lg={headerShow ? "10" : "12"}>
                        <Container fluid>
                            <Row className="pt-1 sub-header align-items-center justify-content-between">
                                <Col md="11" sm="10" xs="10" className="py-2 d-flex align-items-center">
                                    <button style={{ border: "none" }} className="hideNavbar1" onClick={handleShow}><img src={menu} width={20} /></button>
                                    <button style={{ border: "none" }} className="hide_Navbar" onClick={() => setShow(true)}><img src={menu} width={20} /></button>
                                    <h5 className="ms-4 mt-2">Straw / Straw Distribution</h5>
                                </Col>
                                <Col md="1" sm="1" xs="2" className="text-end profile_dropdown">
                                    <img src={profile} width={40} />
                                    <div className="dropdpwn_Open">
                                        <Row className="text-center align-items-center">
                                            <Col xs="12" className="menuHover mb-3">
                                                <Row >
                                                    <Col className="" xs="3">
                                                        <span className=""><MdOutlineVpnKey size={25} />
                                                        </span>
                                                    </Col>
                                                    <Col className="text-start" xs="9">
                                                        Change Password
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col xs="12" className="menuHover" onClick={logOut}>
                                                <Row>
                                                    <Col className="" xs="3">
                                                        <span className=""><GoSignOut size={25} />
                                                        </span>
                                                    </Col>
                                                    <Col className="text-start" xs="9">
                                                        Sign Out
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            </Row>

                            <Row className="align-items-end justify-content-between p-2" id='heightOffsetStock'>
                                <Col xs="2" sm="2" md="2" xl="1" className="mt-2 d-flex align-items-center justify-content-start">
                                    <button className="btn-blue-Animal mt-1 mb-1" onClick={Add}>Add</button>
                                </Col>
                                <Col className='d-flex align-items-end justify-content-end'>
                                    <Col md="2" sm="2" xs="3" className='justify-content-end'>
                                        <FormControl variant="standard" fullWidth>
                                            {/* <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>Supplier</InputLabel>
                                            <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={supplier} onChange={(e) => { setSupplier(e.target.value) }}>
                                                {supplierList.map((e) => (
                                                    <MenuItem className="drop_value" key={e.id} value={e.Name}>
                                                        {e.code} - {e.Name}
                                                    </MenuItem>
                                                ))}
                                            </Select> */}
                                            <Searcable
                                                placeholder="Supplier"
                                                options={supplierList.map((e) => ({ Name: `${e.code} - ${e.Name}`, value: e.Name }))}
                                                onChange={(selectedOption) => setSupplier(selectedOption.value)}
                                                // value={supplier ? { Name: `${supplier.code} - ${supplier.Name}`, value: supplier.Name } : null}
                                                labelKey="Name"
                                                valueKey="value"
                                            />
                                        </FormControl>
                                    </Col>
                                    <Col md="2" sm="2" xs="3" className='justify-content-end'>
                                        <FormControl variant="standard" fullWidth>
                                            {/* <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>Staff</InputLabel>
                                            <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={staff} onChange={(e) => { selectStaff(e.target.value) }}>
                                                {staffList.map((e) => (
                                                    <MenuItem className="drop_value" key={e.id} value={e.ID}>
                                                        {e.Code} - {e.Paravet_Name}
                                                    </MenuItem>
                                                ))}
                                            </Select> */}
                                            <Searcable
                                                placeholder="Staff"
                                                options={staffList.map((e) => ({ Name: `${e.Code} - ${e.Paravet_Name}`, value: e.ID }))}
                                                onChange={(selectedOption) => selectStaff(selectedOption.value)}
                                                // value={staff}
                                                labelKey="Name"
                                                valueKey="value"
                                            />
                                        </FormControl>
                                    </Col>
                                    <Col md="2" sm="2" xs="3" className='mt-3'>
                                        <TextField type="date" value={date} onChange={(e) => { setDate(e.target.value); }} id="standard-basic" data={distributionList} fullWidth variant="standard" />
                                    </Col>
                                    <Col xs="3" sm="2" md="2" xl="1" className="mt-2 d-flex align-items-center justify-content-end">
                                        <button className="btn-blue-Animal mt-1 mb-1" onClick={handleDownloadExcel}>Export</button>
                                    </Col>
                                </Col>
                            </Row>

                            {/* <Row className="mt-4">
                                <Col>
                                    <Table columns={colum} data={distributionList} height="78vh" pagination />
                                </Col>
                            </Row> */}

                            <Row className="mt-2" >
                                {colum.length > 0 ?
                                    <div className="blue_bg DataTableBorder mt-1">
                                        <Table columns={colum} data={distributionList} height={`calc(100vh - (${heightOffSet}px + 140px))`} pagination />
                                    </div>
                                    :
                                    <Row>
                                        <Col>
                                            <div className="NoTableBorder ms-2" >
                                                <div className="d-flex tabledata colmBreed">{
                                                    colum.map((i) => {
                                                        return (
                                                            <Col className={`blankColumns ${i.name.replace(' ', '_')}`} style={{ backgroundColor: "rgb(169,169,169)" }} height={`calc(100vh - (${heightOffSet}px + 20px))`} >{i.name}</Col>
                                                        )
                                                    })
                                                }
                                                </div>
                                                <h6 className="text-center align-items-center mt-5 noData ms-5">No Data</h6>
                                            </div>
                                        </Col>
                                    </Row>
                                }
                            </Row>

                        </Container>
                    </Col>
                </Row>
            </Container>

            <Modal size="lg" aria-labelledby="contained-modal-title-vcenter" centered show={modalShow} onHide={() => setModalShow(false)}>
                <Modal.Header style={{ background: "#DFDFDF" }}>
                    <Modal.Title id="contained-modal-title-vcenter">{editId ? "Update Straw Distribution" : "Straw Distribution"}</Modal.Title>
                    <img src={closeButton} onClick={() => setModalShow(false)} />
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Row>
                            <Col md="4">
                                <FormControl variant="standard" fullWidth>
                                    {/* <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>Supplier</InputLabel>
                                    <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={supplier} onChange={(e) => { setSupplier(e.target.value) }}>
                                        {supplierList.map((e) => (
                                            <MenuItem className="drop_value" key={e.id} value={e.Name}>
                                                {e.code} - {e.Name}
                                            </MenuItem>
                                        ))}
                                    </Select> */}
                                    <Searcable
                                        placeholder="Supplier"
                                        options={supplierList.map((e) => ({ Name: `${e.code} - ${e.Name}`, value: e.Name }))}
                                        onChange={(selectedOption) => setSupplier(selectedOption.value)}
                                        // value={supplier}
                                        labelKey="Name"
                                        valueKey="value"
                                    />
                                </FormControl>
                            </Col>
                            <Col md="4">
                                <FormControl variant="standard" fullWidth>
                                    {/* <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>Staff</InputLabel>
                                    <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={staff} onChange={(e) => { setStaff(e.target.value) }}>
                                        {staffList.map((e) => (
                                            <MenuItem className="drop_value" key={e.id} value={e.Paravet_Name}>
                                                {e.Code} - {e.Paravet_Name}
                                            </MenuItem>
                                        ))}
                                    </Select> */}
                                    <Searcable
                                        placeholder="Staff"
                                        options={staffList.map((e) => ({ Name: `${e.Code} - ${e.Paravet_Name}`, value: e.Paravet_Name }))}
                                        onChange={(selectedOption) => setStaff(selectedOption.value)}
                                        // value={supplier}
                                        labelKey="Name"
                                        valueKey="value"
                                    />
                                </FormControl>
                            </Col>
                            <Col md="4  align-items-end">
                                <FormControl variant="standard" fullWidth>
                                    {/* <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>Center</InputLabel>
                                    <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={center} onChange={(e) => { setCenter(e.target.value) }}>
                                        {centerList.map((e) => (
                                            <MenuItem className="drop_value" key={e.id} value={e.Name}>
                                                {e.Name}
                                            </MenuItem>
                                        ))}
                                    </Select> */}
                                    <Searcable
                                        placeholder="Center"
                                        options={centerList}
                                        onChange={(selectedOption) => setCenter(selectedOption)}
                                        value={center}
                                        labelKey="Name "
                                        valueKey="Name"
                                    />
                                </FormControl>
                            </Col>
                            <Col md="4" className='mt-4'>
                                <FormControl variant="standard" fullWidth>
                                    <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>Dcs</InputLabel>
                                    <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={dcs} onChange={(e) => { setDcs(e.target.value) }}>
                                        {dcsList.map((e) => (
                                            <MenuItem className="drop_value" key={e.id} value={e.Name}>
                                                {e.DCSCode}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    {/* <Searcable
                                        placeholder="Dcs"
                                        options={dcsList}
                                        onChange={(selectedOption) => setDcs(selectedOption)}
                                        value={dcs}
                                        labelKey="DCSCode "
                                        valueKey="Name"
                                    /> */}
                                </FormControl>
                            </Col>
                            <Col md="4" className='mt-4'>
                                <TextField label="Phonenumber" id="standard-basic" value={number} onChange={(e) => { setNumber(e.target.value); }} data={distributionList} fullWidth variant="standard" />
                            </Col>
                        </Row>
                        <Row className='mt-3'>
                            <Col>
                                <Accordion>
                                    <Accordion.Item eventKey="0">
                                        <Accordion.Header>Add Staw</Accordion.Header>
                                        <Accordion.Body>
                                            <Row className='align-tems-center justify-content-start'>
                                                <Col md="4">
                                                    <FormControl variant="standard" fullWidth>
                                                        {/* <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>Sire</InputLabel>
                                                        <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={sire} onChange={(e) => { setSire(e.target.value) }}>
                                                            {sireList.map((e) => (
                                                                <MenuItem className="drop_value" key={e.id} value={e.Name}>
                                                                    {e.Name}
                                                                </MenuItem>
                                                            ))}
                                                        </Select> */}
                                                        <Searcable
                                                            placeholder="Sire"
                                                            onChange={(selectedOption) => setSire(selectedOption.value)}
                                                            value={sire}
                                                            options={sireList}
                                                            labelKey="Name"
                                                            valueKey="Name"
                                                        />
                                                    </FormControl>
                                                </Col>
                                                <Col md="4">
                                                    <FormControl variant="standard" fullWidth>
                                                        {/* <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>Semen</InputLabel>
                                                        <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={semen} onChange={(e) => { setSemen(e.target.value) }}>
                                                            <MenuItem className="drop_value" value="Normal Semen">Normal Semen</MenuItem>
                                                            <MenuItem className="drop_value" value="Sex Semen">Sex Semen</MenuItem>
                                                        </Select> */}
                                                        <Searcable
                                                            placeholder="Semen"
                                                            onChange={(selectedOption) => setSemen(selectedOption.value)}
                                                            // value={semen}
                                                            options={SemenData}
                                                            labelKey="value"
                                                            valueKey="value"
                                                        />
                                                    </FormControl>
                                                </Col>
                                                <Col md="4">
                                                    <TextField label="Bench No" id="standard-basic" value={bench} onChange={(e) => { setBench(e.target.value); }} data={supplierList} fullWidth variant="standard" />
                                                </Col>
                                                <Col md="4">
                                                    <TextField label="Qty" id="standard-basic" value={qty} onChange={(e) => { setQty(e.target.value); }} data={supplierList} fullWidth variant="standard" />
                                                </Col>
                                                <Col md="4">
                                                </Col>
                                                <Col xs="4" sm="2" md="2" className="mt-2 d-flex align-items-center text-end justify-content-end">
                                                    <button className="btn-blue-Animal mt-1 mb-1">Add Straw</button>
                                                </Col>
                                            </Row>
                                            <Row className="mt-4">
                                                <Col>
                                                    {/* <Table columns={colum} data={distributionList} /> */}
                                                </Col>
                                            </Row>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="1">
                                        <Accordion.Header >Add Other Item</Accordion.Header>
                                        <Accordion.Body>
                                            <Row className='align-items-end justify-content-end'>
                                                <Col md="4">
                                                    <FormControl variant="standard" fullWidth>
                                                        {/* <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>Item Name</InputLabel>
                                                        <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={item} onChange={(e) => { setItem(e.target.value) }}>
                                                            {itemList.map((e) => (
                                                                <MenuItem className="drop_value" key={e.id} value={e.Name}>
                                                                    {e.Name}
                                                                </MenuItem>
                                                            ))}
                                                        </Select> */}
                                                        <Searcable
                                                            placeholder="Item Name"
                                                            options={itemList}
                                                            onChange={(selectedOption) => setItem(selectedOption)}
                                                            value={item}
                                                            labelKey="Name"
                                                            valueKey="Name"
                                                        />
                                                    </FormControl>
                                                </Col>
                                                <Col md="4">
                                                    <TextField label="Item Code" id="standard-basic" value={itemCode} onChange={(e) => { setItemCode(e.target.value); }} data={distributionList} fullWidth variant="standard" />
                                                </Col>
                                                <Col md="4">
                                                    <TextField label="Supplied" id="standard-basic" value={supplied} onChange={(e) => { setSupplied(e.target.value); }} data={distributionList} fullWidth variant="standard" />
                                                </Col>
                                                <Col xs="4" sm="2" md="2" className="mt-2 d-flex align-items-center text-end justify-content-end">
                                                    <button className="btn-blue-Animal mt-1 mb-1">Add Item</button>
                                                </Col>
                                            </Row>
                                            <Row className="mt-4">
                                                <Col>
                                                    {/* <Table columns={colum} data={distributionList} /> */}
                                                </Col>
                                            </Row>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Container>
                        <Row className="justify-content-end">
                            <Col sm="2">
                                <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }} onClick={(e) => AddData(e)}>{editId ? "Update" : "Save"}</button>
                            </Col>
                            <Col sm="2">
                                <button className="btn-blue-Animal mt-1" onClick={() => setModalShow(false)} style={{ background: "#E16659" }}> Cancel </button>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Footer>
            </Modal>
            {show && <Offcanvas className="hide_Navbar" show={show} onHide={handleClose}><Container><Row><Col><Sidenav /></Col></Row></Container></Offcanvas>}

            {show && (<Offcanvas className="hide_Navbar" show={show} onHide={handleClose}><Container><Row><Col><Sidenav /></Col></Row></Container></Offcanvas>)}

            {
                isLoading ?
                    <div className="circleText">
                        <div className="progressText">{progressData}</div>
                        <div className="">
                            <span className="loader-circle-13"></span>
                        </div>
                    </div>
                    : false
            }
        </>
    )
}
export default StrawDistribution    
