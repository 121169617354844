import { Switch } from '@mui/material'
import React, { useState } from 'react'
import {
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
} from "@mui/material";
import {
    Col,
    Container,
    Row,
    Offcanvas,
    Accordion,
    Modal,
} from "react-bootstrap";
import Input from "../../Componet/InputFild/Input";
import Table from '../../Componet/DataTable/Table'
import Sidenav from '../../Componet/Sidenav/Sidenav';
import edit from "../../Photo/master/edit.svg";
import remove from "../../Photo/master/remove.svg";
import menu from "../../Photo/menu.svg";
import profile from "../../Photo/profile.svg";
import closeButton from "../../Photo/milk-union/close button.svg";
import { STORAGE_KEY } from "../../constant/common";
import { API_URLS, serviceUrl } from "../../API/Api";
import { useEffect } from 'react';
import { MdOutlineVpnKey } from "react-icons/md";
import { GoSignOut } from "react-icons/go";
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import Searcable from '../../Componet/Dropdown/Searcable';
import BackBtn from '../../Componet/Button/BackBtn';

const { CalfApi } = API_URLS;

const label = { inputProps: { "aria-label": "Switch demo" } };

const ClafSchedule = () => {
    const added_by = localStorage.getItem(STORAGE_KEY.jwtTOKEN);
    const API_KEY = serviceUrl;

    async function getData(credentials) {
        return fetch(CalfApi.GetDetails, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`,
            },
            body: JSON.stringify(credentials)
        })
            .then(res => res.json());
    }

    async function addData(credentials) {
        return fetch(CalfApi.AddDetails, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`,
            },
            body: JSON.stringify(credentials)
        })
            .then(res => res.json());
    }


    async function updateData(credentials) {
        return fetch(CalfApi.UpdateDetails, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`,
            },
            body: JSON.stringify(credentials)
        })
            .then(res => res.json());
    }

    const [code, setCode] = useState("")
    const [name, setName] = useState("")
    const [mobile, setMobile] = useState("")
    const [staffMobile, setStaffMobile] = useState("")
    const [search, setSearch] = useState("")
    const [taskDate, setTaskDate] = useState("")
    const [breed, setBreed] = useState("")
    const [day, setDay] = useState("");
    const [task, setTask] = useState("");
    const [progressData, setProgressData] = useState("0%");
    const [searchFilter, setSearchFilter] = useState("");
    const [deleteId, setDeleteId] = useState();
    const navigate = useNavigate()
    const logOut = () => { navigate("/") }

    const [isLoading, setIsLoading] = useState(false);
    const [heightOffSet, setHeight] = useState(0);
    const [headerShow, setHeaderShow] = useState(true);
    const [show, setShow] = useState(false);
    const handleClose = () => { setShow(false); }
    const handleShow = () => { setHeaderShow(!headerShow); }
    const [modalShow, setModalShow] = useState(false);
    const [isSuspended, setIsSuspended] = useState(false)
    const [editToggle, setEditToggle] = useState(false);
    const [deleteShow, setDeleteShow] = useState(false)

    const [editId, setEditId] = useState("")
    const [user, setUser] = useState("")


    const [clafList, setCalfList] = useState([])
    const [breedList, setbreedList] = useState([])


    const clafGetData = async () => {
        setIsLoading(true)
        fetch(CalfApi.GetDetails, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`,
            },
        })
            .then(response => {
                setIsLoading(true)
            })
            .then((res) => res.json())
            .then((result) => { setIsLoading(false); setCalfList(result.data); setProgressData("0%"); })
            .catch((error) => { setIsLoading(false); setProgressData("0%"); })
    }

    useEffect(() => {
        let divHeight = document.getElementById("heightOffsetStock")?.offsetHeight;
        setHeight(divHeight);
        clafGetData()
    }, [])



    const AddData = async () => {
        if (editId) {
            const res = await updateData({ id: editId, Day: day, Task: task, TaskDate: taskDate, breed });
            if (res) {
                setCalfList(res.data);
                setModalShow(false)
                clafGetData()
            }
        }
        else {
            const res = await addData({ Day: day, Task: task, TaskDate: taskDate, breed });
            if (res) {
                setCalfList(res.data);
                setModalShow(false)
                clafGetData()
            }

        }
    }

    const updateFn = (e) => {
        console.log(e.Mobile)
        setEditId(e.id)
        // setEditToggle(true);
        // setEditId(e.id);
        // setCode(e.code);
        // setName(e.Name);
        // setMobile(e.Mobile);
        // setStaffMobile(e.satff);
        // setLatitude(e.Latitude);
        // setLongitude(e.Longitude);
        // setManager(e.Manager);

        // setExtensionOfficerStaff(e.extensionOfficerStaff);
        // setExOfficerStaffId(e.extensionOfficerStaff.id)
        // setExOfficerStaffName(e.extensionOfficerStaff.Name)

        // setZone(e.zone.Name);
        // setZoneId(e.zone.id);
        // setZoneName(e.zone.Name);

        // setCc(e.cc.Name);
        // setCcId(e.cc.id);
        // setCcName(e.cc.Name);
        setDay(e.Day);
        setTask(e.Task);
        setTaskDate(e.TaskDate);
        setBreed(e.breed.name);
        setModalShow(true);

    };


    const deleteData = (id) => {
        // setUser(e.Name)
        // setDeleteShow(true)
        // let id = e.id;
        fetch(`${API_KEY}/calf-schedule/delete?id=` + id, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`,
            },
        })
            .then((res) => res.json())
            .then((result) => {
                clafGetData();
                setDeleteShow(false)
            });

    }
    const apiforbreedData = () => {

        fetch(`${API_KEY}/breed/getall?company_filter=1`, {
            method: "GET",
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`,
            },
        })
            .then((res) => res.json())
            .then((result) => {
                console.log(result);
                setbreedList(result)

            });
    }



    const colum = [
        {
            name: "id",
            selector: "id",
        },
        {
            name: "Task",
            cell: (e) => <>{e.Task}</>
        },
        {
            name: "Day",
            cell: (e) => <>{e.Day}</>
        },
        {
            name: "Task Date",
            cell: (e) => <>{moment(e.TaskDate).format("YYYY-MM-DD")}</>
        },
        {
            name: "Breed Name",
            cell: (e) => <>{e.breed.Name}</>
        },
        // {
        //     name: "Is Suspended",
        //     // selector: "IsSuspended",
        //     cell: (e) => <Switch {...label} defaultChecked={e.IsSuspended}/>,
        // },
        {
            name: "Actions",
            cell: (e) => (
                <>
                    <img
                        src={edit}
                        onClick={() => updateFn(e)}
                        style={{ width: "15px", margin: "10px" }}
                    />
                    <img
                        src={remove}
                        onClick={() => { setDeleteShow(true); setDeleteId(e.id); setUser(e.Task) }}
                        style={{ width: "15px" }}
                    />
                </>
            ),
        },
    ]
    const SearchFn = (e) => {
        const data = e.target.value;
        if (data !== '') {
            const results = clafList.filter((d) => {
                return d.Task.toLowerCase().startsWith(data.toLowerCase());
            });
            setSearchFilter(results);
        } else {
            setSearchFilter(clafList);
        }
        setSearch(data);
    }


    return (
        <>
            <Container fluid>
                <Row> {headerShow && (<Col lg="2" className="hideNavbar"> <Sidenav /></Col>)}
                    <Col className="header" lg={headerShow ? "10" : "12"}>
                        <Container fluid>
                            <Row className="pt-1 sub-header align-items-center justify-content-between">
                                <Col md="10" sm="10" xs="10" className="py-2 d-flex align-items-center">
                                    <button style={{ border: "none" }} className="hideNavbar1" onClick={() => { handleShow() }}><img src={menu} width={20} /></button>
                                    <button style={{ border: "none" }} className="hide_Navbar" onClick={() => setShow(true)}><img src={menu} width={20} /></button>
                                    <h5 className="ms-4 mt-2">Master / Calf Schedule</h5>
                                </Col>
                                <Col md="1" sm="1" xs="10" className="py-2 d-flex align-items-center text-end ms-auto">
                                    <BackBtn style={{ border: "none" }} className="hideNavbar1" lable="Back" onClick={() => { navigate('/') }} />
                                </Col>
                                <Col md="1" sm="1" xs="2" className="text-end profile_dropdown">
                                    <img src={profile} width={40} />
                                    <div className="dropdpwn_Open">
                                        <Row className="text-center align-items-center">
                                            <Col xs="12" className="menuHover mb-3">
                                                <Row >
                                                    <Col className="" xs="3">
                                                        <span className=""><MdOutlineVpnKey size={25} />
                                                        </span>
                                                    </Col>
                                                    <Col className="text-start" xs="9">
                                                        Change Password
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col xs="12" className="menuHover" onClick={logOut}>
                                                <Row>
                                                    <Col className="" xs="3">
                                                        <span className=""><GoSignOut size={25} />
                                                        </span>
                                                    </Col>
                                                    <Col className="text-start" xs="9">
                                                        Sign Out
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="align-items-center justify-content-between p-2 d-flex" id='heightOffsetStock' >
                                <Col sm="4" lg="2" className="mt-2 d-flex align-items-center justify-content-start">
                                    <button className="btn-blue-Animal mt-1 mb-1" onClick={() => {
                                        setModalShow(true);
                                        apiforbreedData();
                                    }}>Add Calf Schedule</button>
                                </Col>
                                <Col xxl="2" lg="3" md="4" sm="5">
                                    <Input label="Search" value={search} onChange={e => SearchFn(e)} />
                                </Col>
                            </Row>
                            {/* <Row className="mt-2">
                                <Col>
                                    <Table columns={colum} data={searchFilter ? searchFilter : clafList} height={`calc(100vh - (${heightOffSet}px + 124px))`} pagination />
                                </Col>
                            </Row> */}
                            <Row className="mt-2" id='heightOffsetStock'>
                                {clafList.length > 0 ?
                                    <div className="blue_bg DataTableBorder mt-1">
                                        <Table columns={colum} data={searchFilter ? searchFilter : clafList} height={`calc(100vh - (${heightOffSet}px + 140px))`} pagination />
                                    </div>
                                    :
                                    <Row>
                                        <Col>
                                            <div className="NoTableBorder ms-2" >
                                                <div className="d-flex tabledata colmBreed">{
                                                    colum.map((i) => {
                                                        return (
                                                            <Col className={`blankColumns ${i.name.replace(' ', '_')}`} style={{ backgroundColor: "rgb(169,169,169)" }} height={`calc(100vh - (${heightOffSet}px + 20px))`} >{i.name}</Col>
                                                        )
                                                    })
                                                }
                                                </div>
                                                <h6 className="text-center align-items-center mt-5 noData ms-5">No Data</h6>
                                            </div>
                                        </Col>
                                    </Row>
                                }
                            </Row>
                        </Container>
                    </Col>
                </Row>
            </Container>


            <Modal aria-labelledby="contained-modal-title-vcenter" centered show={deleteShow} onHide={() => setModalShow(false)}>
                <Modal.Header style={{ background: "#DFDFDF" }}>
                    <Modal.Title id="contained-modal-title-vcenter">Confirm Delete ?</Modal.Title>
                    <img src={closeButton} onClick={() => setModalShow(false)} />
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        Delete {user} ?
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Container>
                        <Row className="justify-content-end">
                            <Col xl="3">
                                <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }} onClick={() => deleteData(deleteId)}>DeleteId</button>
                            </Col>
                            <Col xl="3">
                                <button className="btn-blue-Animal mt-1" onClick={() => setDeleteShow(false)} style={{ background: "#E16659" }}> Cancel </button>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Footer>
            </Modal>

            <Modal size="lg" aria-labelledby="contained-modal-title-vcenter" centered show={modalShow} onHide={() => setModalShow(false)}>
                <Modal.Header style={{ background: "#DFDFDF" }}>
                    <Modal.Title id="contained-modal-title-vcenter">{editId ? "Update Calf Schedule" : "Add new Calf Schedule"}</Modal.Title>
                    <img src={closeButton} onClick={() => setModalShow(false)} />
                </Modal.Header>
                <Modal.Body>
                    <Container>
                        <Row>
                            <Col xl="6">
                                <TextField label="Code" id="standard-basic" value={task} onChange={(e) => { setTask(e.target.value); }} data={clafList} fullWidth variant="standard" />
                            </Col>
                            <Col xl="6">
                                <TextField label="Day" value={day} onChange={(e) => { setDay(e.target.value); }} id="standard-basic" data={clafList} fullWidth variant="standard" />
                            </Col>
                        </Row>
                        <Row className="mt-4">
                            <Col xl="6" className='mt-3'>
                                <FormControl variant="standard" style={{ border: "1px soild black" }} fullWidth>
                                    {/* <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "18px" }}>
                                        Breed
                                    </InputLabel> */}
                                    {/* <Select labelId="demo-simple-select-standard-label" id="demo-simple-select-standard" value={breed} onChange={(e) => { setBreed(e.target.value); }}>
                                        {breedList.map((e) => (
                                            <MenuItem className="drop_value" key={e.id} value={e.Name} >
                                                {e.Name}
                                            </MenuItem>
                                        ))}
                                    </Select> */}
                                    <Searcable
                                        placeholder="Breed"
                                        options={breedList}
                                        onChange={(selectedOption) => setBreed(selectedOption)}
                                        value={breed}
                                        labelKey="Name"
                                        valueKey="id"
                                    />
                                </FormControl>
                            </Col>
                            <Col xl="6">
                                <InputLabel id="demo-simple-select-standard-label" style={{ fontSize: "14px" }}>
                                    Task Date
                                </InputLabel>
                                <TextField type="date" value={taskDate} onChange={(e) => { setTaskDate(e.target.value); }} id="standard-basic" data={clafList} fullWidth variant="standard" />
                            </Col>
                        </Row>
                        <Row className="mt-4">
                            <Col>
                                <Switch {...label} />
                                Is Suspended : No
                            </Col>
                        </Row>
                    </Container>
                </Modal.Body>
                <Modal.Footer>
                    <Container>
                        <Row className="justify-content-end">
                            <Col xl="2">
                                <button className="btn-blue-Animal mt-1" style={{ background: "#43668E" }} onClick={(e) => AddData(e)}>{editToggle ? "Update" : "Save"}</button>
                            </Col>
                            <Col xl="2">
                                <button className="btn-blue-Animal mt-1" onClick={() => setModalShow(false)} style={{ background: "#E16659" }}> Cancel </button>
                            </Col>
                        </Row>
                    </Container>
                </Modal.Footer>
            </Modal>
            {show && <Offcanvas className="hide_Navbar" show={show} onHide={handleClose}><Container><Row><Col><Sidenav /></Col></Row></Container></Offcanvas>}

            {
                isLoading ?
                    <div className="circleText">
                        <div className="progressText">{progressData}</div>
                        <div className="">
                            <span className="loader-circle-13"></span>
                        </div>
                    </div>
                    : false
            }
        </>

    )
}

export default ClafSchedule;