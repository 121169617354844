import React, { useState } from 'react'
import { Col, Container, Row, Offcanvas } from "react-bootstrap";
import Sidenav from "../../Componet/Sidenav/Sidenav";
import menu from '../../Photo/menu.svg'
import profile from '../../Photo/profile.svg';
import Input from '../../Componet/InputFild/Input';
import Table from '../../Componet/DataTable/Table';
import {
    FormControl, InputLabel,
    // Select,
    MenuItem
} from "@mui/material";
import { MdOutlineVpnKey } from "react-icons/md";
import Select from 'react-select';
import { GoSignOut } from "react-icons/go";

import { STORAGE_KEY } from "../../constant/common";
import { API_URLS, serviceUrl } from "../../API/Api";
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import BackBtn from '../../Componet/Button/BackBtn';
const { Farmer_Zone } = API_URLS;
const added_by = localStorage.getItem(STORAGE_KEY.jwtTOKEN);

async function getZone(credentials) {
    return fetch(Farmer_Zone.GetZone, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            "Accept": "application/json",
            "Authorization": `Bearer ${added_by}`,
        },
        body: JSON.stringify(credentials)
    })
        .then(res => res.json());
}

async function getVisitRoute(credentials) {
    return fetch(Farmer_Zone.GetVisitRoute, {
        method: 'GET',
        headers: {
            'Content-Type': 'application/json',
            "Accept": "application/json",
            "Authorization": `Bearer ${added_by}`,
        },
        body: JSON.stringify(credentials)
    })
        .then(res => res.json());
}

async function getData(credentials) {
    return fetch(Farmer_Zone.GetData, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            "Accept": "application/json",
            "Authorization": `Bearer ${added_by}`,
        },
        body: JSON.stringify(credentials)
    })
        .then(res => res.json());
}


async function transferData(credentials) {
    return fetch(Farmer_Zone.TransferData, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            "Accept": "application/json",
            "Authorization": `Bearer ${added_by}`,
        },
        body: JSON.stringify(credentials)
    })
        .then(res => res.json());
}

const Farmer = () => {
    
    const API_KEY = serviceUrl;
    const [heightOffSet, setHeight] = useState(0);

    const [search, setSearch] = useState("");
    const [center, setCenter] = useState("");
    const [route, setRoute] = useState("");
    const [dcs, setDcs] = useState("");
    const [lot, setLot] = useState("");
    const [routeVisit, setRouteVisit] = useState("");
    const [searchFilter, setSearchFilter] = useState("")
    const [progressData, setProgressData] = useState("0%");
    const navigate = useNavigate()
    const logOut = () => { navigate("/") }

    const [centerList, setCenterList] = useState([]);
    const [routeList, setRouteList] = useState([]);
    const [dcsList, setDcsList] = useState([]);
    const [farmerList, setFarmerList] = useState([]);
    const [routeVisitList, setRouteVisitList] = useState([]);
    const [lotList, setLotList] = useState([]);
    const [rowsList, setRowsList] = useState([]);

    const [isLoading, setIsLoading] = useState(false);
    const [show, setShow] = useState(false);
    const [headerShow, setHeaderShow] = useState(false);
    const handleClose = () => { setShow(false); }
    const handleShow = () => { setHeaderShow(!headerShow); }


    const fatchAnimalData = async () => {
        const res = await getZone();
        const res1 = await getVisitRoute();
        if (res) {
            setCenterList(res);
        }
        if (res1) {
            setRouteVisitList(res1);
        }
    }

    useEffect(() => {
        fatchAnimalData();
        let divHeight = document.getElementById("heightOffsetStock")?.offsetHeight;
        setHeight(divHeight);
    }, []);

    const selectCenter = (e) => {
        setCenter(e);
        fatchRouteData(e);
    }
    const fatchRouteData = async (e) => {
        if (e) {
            fetch(`${API_KEY}/cattle-detail/getVisitRoutes/` + e, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    "Accept": "application/json",
                    "Authorization": `Bearer ${added_by}`
                },
            })
                .then(res => res.json())
                .then((result) => { setRouteList(result) });
        }
    }

    const selectRoute = (e) => {
        setRoute(e);
        fatchDcsData(e);
    }
    const fatchDcsData = async (e) => {
        if (e) {
            fetch(`${API_KEY}/cattle-detail/getVisitLot/` + e, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    "Accept": "application/json",
                    "Authorization": `Bearer ${added_by}`
                },
            })
                .then(res => res.json())
                .then((result) => { setDcsList(result) })
        }
    }

    const selectRoute1 = (e) => {
        setRouteVisit(e);
        fatchLotDate(e);
    }

    const SelectedRowFn = (rows) => {
        setRowsList(rows.selectedRows);
    }
    const fatchLotDate = async (e) => {
        if (e) {
            fetch(`${API_KEY}/cattle-detail/getVisitLot/` + e, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    "Accept": "application/json",
                    "Authorization": `Bearer ${added_by}`
                },
            })
                .then(res => res.json())
                .then((result) => { setLotList(result) });

        }
    }
    let ids = (rowsList.map(i => i.ID));

    const Transfer = async () => {
        setIsLoading(true)
        const res = await transferData({ dataType: "Farmer", farmer: null, herd: routeVisit, id: ids, lot: dcs, project: null, uid: 1 })
        if (res) { toast.success(res.status); setIsLoading(false); setProgressData("0%") }
    }

    const View = async () => {
        setIsLoading(true);
        const res = await getData({ ahc: center, dataType: "Farmer", farmer: null, lot: dcs, project: null, route });
        if (res) {
            setFarmerList(res);
            setIsLoading(false);
            setProgressData("0%")
        }
        else {
            setIsLoading(false);
            setProgressData("0%")
        }
    }

    const colum = [
        {
            name: "ID",
            selector: "ID",
            width: "120px"
        },
        {
            name: "Lot Code",
            selector: "Lot Code",
            width: "120px"
        },
        {
            name: "DCS Name",
            selector: "DCS NAme",
        },
        {
            name: "Farmer Code",
            selector: "Farmer Code",
            width: "150px"
        },
        {
            name: "SAP Code",
            selector: "SAP Code",
            width: "150px"
        },
        {
            name: "Farmer Name",
            selector: "Farmer Name",
        },
        {
            name: "Farmer MobileNumber",
            selector: "Farmer MobileNumber",
        },
        {
            name: "Suspended",
            selector: "Suspended",
            width: "120px"
        },
    ]

    const SearchFn = () => {
        setIsLoading(true)
        fetch(`${API_KEY}/cattle-detail/get-transfer-search`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`
            },
            body: JSON.stringify({ dataType: "Farmer", search: search })
        })
            .then(response => {
                const contentLength = response.headers.get('content-length');
                let loaded = 0;
                return new Response(
                    new ReadableStream({
                        start(controller) {
                            const reader = response.body.getReader();
                            read();
                            function read() {
                                reader.read()
                                    .then((progressEvent) => {
                                        if (progressEvent.done) {
                                            controller.close();
                                            return;
                                        }
                                        loaded += progressEvent.value.byteLength;
                                        const percentageComplete = Math.round(loaded / contentLength * 100) + '%';
                                        setProgressData(percentageComplete)
                                        controller.enqueue(progressEvent.value);
                                        read();
                                    })
                            }
                        }
                    })
                );
            })
            .then((res) => res.json())
            .then((result) => { setFarmerList(result); setIsLoading(false); setProgressData("0%") })
    }

    return (
        <>
            <Container fluid>
                <Row>
                    {headerShow && (<Col lg="2" className="hideNavbar"> <Sidenav /></Col>)}
                    <Col className="header" lg={headerShow ? "10" : "12"}>
                        <Container fluid>
                            <Row className="pt-1 sub-header align-items-center justify-content-between">
                                <Col md="10" sm="10" xs="10" className="py-2 d-flex align-items-center">
                                    <button style={{ border: "none" }} className="hideNavbar1" onClick={handleShow}><img src={menu} width={20} /></button>
                                    <button style={{ border: "none" }} className="hide_Navbar" onClick={() => setShow(true)}><img src={menu} width={20} /></button>
                                    <h5 className="ms-4 mt-2">Transfer / Farmer&nbsp;Transfer</h5>
                                </Col>
                                <Col md="1" sm="1" xs="10" className="py-2 d-flex align-items-center text-end ms-auto">
                                    <BackBtn style={{ border: "none" }} className="hideNavbar1" lable="Back" onClick={() => { navigate('/') }} />
                                </Col>
                                <Col md="1" sm="1" xs="2" className="text-end">
                                    <img src={profile} width={40} />
                                </Col>
                            </Row>

                            <Row className="align-items-center justify-content-end" id='heightOffsetStock'>
                                <Col xs="12" sm="12" lg="12" md="12" className='justify-content-start'>
                                    <Row className='justify-content-start align-items-end mt-1'>
                                        <Col lg="2" sm="4" xs="4" md="2" className='justify-content-start ms-2'>
                                            <Select
                                                placeholder="Center"
                                                onChange={(selectedOption) => selectCenter(selectedOption.value)}
                                                options={centerList.map((e) => ({ value: e.Id, label: e.name }))}
                                            />
                                        </Col>
                                        <Col lg="2" sm="4" xs="3" md="2" className='justify-content-start'>
                                            <Select
                                                placeholder="Route"
                                                onChange={(selectedOption) => selectRoute(selectedOption.value)}
                                                options={routeList.map((e) => ({ value: e.Id, label: e.name }))}
                                            />
                                        </Col>
                                        <Col lg="2" sm="3" xs="4" md="2" className='justify-content-start'>
                                            <Select
                                                placeholder="Dcs"
                                                onChange={(selectedOption) => setDcs(selectedOption.value)}
                                                options={dcsList.map((e) => ({ value: e.Id, label: e.name }))}
                                            />
                                        </Col>
                                        <Col lg="1" xl="1" md="2" sm="2" xs="4" className="align-items-center justify-content-end">
                                            <button className="btn-blue-Animal mt-1 mb-1" onClick={(e) => View(e)}>View</button>
                                        </Col>
                                        <Col lg="2" md="2" sm="3" xs="6" className="mt-2 ms-auto align-items-center justify-content-end">
                                            <Input label="Search" value={search} onChange={e => setSearch(e.target.value)} />
                                        </Col>
                                        <Col lg="1" xl="1" md="2" sm="2" xs="4" className="align-items-center justify-content-end">
                                            <button className="btn-blue-Animal mt-1 mb-1" onClick={SearchFn}>Search</button>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>

                            <Row className="mt-3">
                                {
                                    farmerList.length > 0 ?
                                        <Col xl="9" xxl="9" lg="9" md="8" sm="12">
                                            <Table columns={colum} data={searchFilter ? searchFilter : farmerList} height={`calc(82vh - (${heightOffSet}px + 14px))`} pagination selectableRows onSelectedRowsChange={SelectedRowFn} />
                                        </Col>
                                        :
                                        <Col xl="9" xxl="9" lg="9" md="8" sm="12" className="data-container">
                                            <div className="no-data-message">No Data</div>
                                        </Col>
                                }
                                <Col xxl="3" xl="3" lg="3" md="3" sm="12">
                                    <div className='mainCard'>
                                        <Row className='p-2 align-items-center justify-content-center'>
                                            <Col xl="12" xxl="10" md="12" sm="6" className='align-items-center justify-content-center cardHeading'>
                                                <Col className='mt-2'>
                                                    <div className='boxBg text-center p-2 align-items-center'>
                                                        <h6 className='fw-bold m-0'>Select the Destination Herd</h6>
                                                    </div>
                                                </Col>
                                                <Col className='mt-1'>
                                                    <Select
                                                        placeholder="Route"
                                                        onChange={(selectedOption) => selectRoute1(selectedOption.value)}
                                                        options={routeList.map((e) => ({ value: e.Id, label: e.name }))}
                                                    />
                                                </Col>
                                            </Col>
                                            <Col xl="12" xxl="10" md="12" sm="6" className='align-items-center justify-content-center cardHeading'>
                                                <Col className='mt-2'>
                                                    <div className='boxBg text-center p-2 align-items-center'>
                                                        <h6 className='fw-bold m-0'>Select the Destination Lot</h6>
                                                    </div>
                                                </Col>
                                                <Col className='mt-1'>
                                                    <Select
                                                        placeholder="Lot"
                                                        onChange={(selectedOption) => setLot(selectedOption.value)}
                                                        options={lotList.map((e) => ({ value: e.Id, label: e.name }))}
                                                    />
                                                </Col>
                                            </Col>
                                        </Row>
                                        <Row className='align-items-center mt-4 text-center'>
                                            <Col>
                                                Click on the below to make changes
                                            </Col>
                                        </Row>
                                        <Row className='align-items-center justify-content-center'>
                                            <Col xl="4" md="4" sm="3" xs="6" className="mt-2 d-flex align-items-center">
                                                <button className="btn-blue-Animal mt-1 mb-3" onClick={Transfer}>Transfer</button>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            </Row>


                        </Container>
                    </Col >
                </Row >
            </Container >
            {show && <Offcanvas className="hide_Navbar" show={show} onHide={handleClose}><Container><Row><Col><Sidenav /></Col></Row></Container></Offcanvas>}

            {
                isLoading ?
                    <div className="circleText">
                        <div className="progressText">{progressData}</div>
                        <div className="">
                            <span className="loader-circle-13"></span>
                        </div>
                    </div>
                    : false
            }

            <ToastContainer
                position="top-center"
                autoClose={2000}
                hideProgressBar={true}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
                style={{ fontSize: "14px" }}
            />

        </>
    )
}
export default Farmer    
