import { Checkbox, Switch } from '@mui/material'
import React, { useEffect, useState } from 'react'
import './visit_registration.css';
import { TextField, FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { Col, Container, Row, Offcanvas, Accordion, Modal } from "react-bootstrap";
import Input from "../../Componet/InputFild/Input";
import Sidenav from '../../Componet/Sidenav/Sidenav';
import edit from "../../Photo/master/edit.svg";
import remove from "../../Photo/master/remove.svg";
import menu from "../../Photo/menu.svg";
import profile from "../../Photo/profile.svg";
import closeButton from "../../Photo/milk-union/close button.svg";
import Table from '../../Componet/DataTable/Table';
import { MdOutlineVpnKey } from "react-icons/md";
import { GoSignOut } from "react-icons/go";
import { IoMdArrowDropdown } from "react-icons/io";
import { STORAGE_KEY } from "../../constant/common";
import { API_URLS } from "../../API/Api";
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import moment from 'moment/moment';
import { AiFillCloseCircle } from "react-icons/ai";
import { IoMdDownload } from "react-icons/io";
import { MdModeEditOutline } from "react-icons/md";
import { RxCountdownTimer } from "react-icons/rx";

// import { saveAs } from 'file-saver';
// import ExcelJS from 'exceljs';
import { downloadExcel } from 'react-export-table-to-excel';
import Searcable from '../../Componet/Dropdown/Searcable';
const { CC } = API_URLS;
const { visitCancellation } = API_URLS;


const Visit_Cancellation = () => {

  const [headerShow, setHeaderShow] = useState(true);
  const [show, setShow] = useState(false);
  const handleClose = () => { setShow(false); }
  const handleShow = () => { setHeaderShow(!headerShow); }

  const added_by = localStorage.getItem(STORAGE_KEY.jwtTOKEN);
  const navigate = useNavigate()
  const logOut = () => { navigate("/") }

  async function getCancelTData(credentials) {
    setIsLoading(true);

    try {

      const response = await fetch(visitCancellation.GetCancellation, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json',
          'Authorization': `Bearer ${added_by}`,
        },
        body: JSON.stringify(credentials),
      });

      const contentLength = response.headers.get('content-length');
      let loaded = 0;

      const result = await new Response(
        new ReadableStream({
          async start(controller) {
            const reader = response.body.getReader();
            await read();

            async function read() {
              const { done, value } = await reader.read();
              if (done) {
                controller.close();
                return;
              }
              loaded += value.byteLength;
              const percentageComplete = Math.round((loaded / contentLength) * 100) + '%';
              setProgressData(percentageComplete);
              controller.enqueue(value);
              await read();
            }
          },
        })
      ).json();

      setIsLoading(false);
      setCancellationData(result);
      setProgressData('0%');

    } catch (error) {
      console.error('Error fetching data:', error);
      setIsLoading(false);
    }

  }


  async function getAllData(credentials) {

    return fetch(visitCancellation.GetAllDataUser, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        "Accept": "application/json",
        "Authorization": `Bearer ${added_by}`,
      },
      body: JSON.stringify(credentials)
    })
      .then(data => data.json())


  }



  // const handleDownloadExcel = () => {


  //   const header = []
  //   const opt = `BulkWise MilkEntry ${date}`;
  //   colum.forEach((e) => {
  //     header.push(e.name)
  //   })


  //   const body = cancellationData.map((item) => {
  //     // console.log(header)
  //     // console.log(item)
  //     const rowData = {};
  //     const data = header.forEach((key) => {
  //       rowData[key] = item[key] || "";
  //     });

  //     return rowData;
  //   });

  //   downloadExcel({
  //     fileName: opt,
  //     sheet: "data",
  //     tablePayload: {
  //       header: header,
  //       body: body
  //     }
  //   });
  //   console.log(body);
  // }
  const handleDownloadExcel = () => {
    const header = [];
    const opt = `BulkWise MilkEntry ${date}`;

    colum.forEach((e) => {
      header.push(e.selector);
    });

    const body = cancellationData.map((item) => {
      const rowData = {};
      header.forEach((key) => {
        rowData[key] = item[key] || "";
      });
      return rowData;
    });

    downloadExcel({
      fileName: opt,
      sheet: "data",
      tablePayload: {
        header: header,
        body: body,
      },
    });
    console.log(body);
  };


  const fatchAllData = async () => {

    const response = await getAllData()

    if (response) {
      setSelectedCenter(response);
    }

  }

  const fetchdata = async (e, stval) => {


    if (e === "All") {
      const response = await getAllData();
      if (response) {
        setCancellationData(response);
      }
    } else {
      const response = await getCancelTData({
        columnNameSort: "",
        filterString: selectSearchData ? selectSearchData.toString() : "",
        inputDate: date,
        limit: selectvid ? selectvid.toString() : "",
        offset: e ? e.toString() : "18",
        searchString: search ? search.toString() : "",
        sortOrder: stval ? stval.toString() : "",
        sql: "",
      });
      if (response) {

        setCancellationData(response);

      }
    }
  };



  const fetchdatafordate = async (selectedDate) => {
    const requestData = {
      columnNameSort: "",
      filterString: selectSearchData ? selectSearchData.toString() : "",
      inputDate: selectedDate,
      limit: selectvid ? selectvid.toString() : "",
      offset: selectchangeid ? selectchangeid.toString() : "18",
      searchString: search ? search.toString() : "",
      sortOrder: selectedStatus ? selectedStatus.toString() : "",
      sql: "",
    };

    try {
      const response = await getCancelTData(requestData);


      const formattedSelectedDate = new Date(selectedDate).toISOString().split('T')[0];


      const filteredData = response.filter((item) => {
        const apiDate = new Date(item.date).toISOString().split('T')[0];
        return apiDate === formattedSelectedDate;
      });

      setCancellationData(filteredData);


    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };


  const handleSelectChangeStatus = (stval) => {

    setSelectedStatus(stval);

    if (selectedSubCenter === 'All') {
      fetchdata('All', stval);
    } else {
      fetchdata(selectedSubCenter, stval);
    }
  };

  const handleSelectChangeCenter = async (value) => {
    console.log(value);
    setSelectchangeid(value)
    handelVoName(value)

    if (value === 'All') {
      setSelectedSubCenter('All');
      setSelectedRoute('');
      await fetchdata('All', selectedStatus);
    } else {
      setSelectedSubCenter(value);
      setSelectedRoute(value);
      await fetchdata(value, selectedStatus);
    }

    await fetch(`${visitCancellation.GetFilterUser}?company_filter=1&zone=${value === 'All' ? '' : value}`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        "Accept": "application/json",
        "Authorization": `Bearer ${added_by}`,
      },
    })
      .then(response => response.json())

      .then(data => {
        setFilteredRoutes(data);
      })

  };

  const handelVoName = async (value) => {

    // filter:{"VOFlag" : 1,"zone":value}
    setIsLoading(true)

    const response = await fetch(`${visitCancellation.GetVONameFilter}?filter=%7B%22VOFlag%22:1,%22zone%22:%22${value}%22%7D`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': `Bearer ${added_by}`,
      },
    })
      .then(response => {
        const contentLength = response.headers.get('content-length');
        let loaded = 0;
        return new Response(
          new ReadableStream({
            start(controller) {
              const reader = response.body.getReader();
              read();
              function read() {
                reader.read()
                  .then((progressEvent) => {
                    if (progressEvent.done) {
                      controller.close();
                      return;
                    }
                    loaded += progressEvent.value.byteLength;
                    const percentageComplete = Math.round(loaded / contentLength * 100) + '%';
                    setProgressData(percentageComplete)
                    controller.enqueue(progressEvent.value);
                    read();
                  })
              }
            }
          })
        );
      })

      .then(response => response.json())


    if (response) {
      setSelectedVoNames(response.data);
    }


  };
  const handleSelectChangeRoute = async (selectedRouteValue) => {
    console.log(selectedRouteValue);
    setSelectedRoute(selectedRouteValue);

    if (selectedRouteValue === "All") {

      await fetchdata(selectedSubCenter, selectedStatus, "All");
    } else {

      await fetchdata(selectedSubCenter, selectedStatus, selectedRouteValue);
    }

  };


  const handleSearchData = (val) => {
    console.log(val);
    setSelectSearchData(val)

  }

  const SearchFn = async (inputValue) => {


    const event = inputValue.target.value
    setSearch(event);
    const response = await getCancelTData({
      columnNameSort: "",
      filterString: selectSearchData ? selectSearchData.toString() : "",
      inputDate: date,
      limit: selectvid ? selectvid.toString() : "",
      offset: "18",
      searchString: event,
      sortOrder: selectedStatus ? selectedStatus.toString() : "",
      sql: "",
    });
    console.log(response)
    if (response) {
      console.log(response)
      setCancellationData(response)
    }
  };

  const handleDate = (selectedDate) => {

    setDate(selectedDate);
    fetchdatafordate(selectedDate);

  };

  const fetchDataForVoname = async (vid) => {
    setSelectvid(vid)
    const response = await getCancelTData({
      columnNameSort: "",
      filterString: selectSearchData ? selectSearchData.toString() : "",
      inputDate: date,
      offset: selectchangeid.toString(),
      limit: vid.toString(),
      searchString: search ? search.toString() : "",
      sortOrder: selectedStatus ? selectedStatus.toString() : "",
      sql: "",
    });
    if (response) {
      setCancellationData(response)
    }

  }
  const handelselectVoName = (e) => {
    console.log(e);
    setVoName(e)
    fetchDataForVoname(e.value)

    // let id = selectedVoNames.map((i) => {
    //   if (i.localName == val) {
    //     let cc = i.id
    //     console.log(cc);
    //     fetchDataForVoname(cc)
    //   }
    // })

  }



  const handelpdf = async (row) => {

    if (search == 1) {
      try {

        const apiUrl = `${visitCancellation.GetComplaintCodeOne}/${row}`;

        const response = await fetch(apiUrl, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/pdf',
            'Authorization': `Bearer ${added_by}`,
          },
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const pdfBlob = await response.blob();

        const pdfUrl = URL.createObjectURL(pdfBlob);

        const link = document.createElement('a');
        link.href = pdfUrl;
        link.target = '_blank';
        link.download = 'document.pdf';

        document.body.appendChild(link);

        link.click();

        URL.revokeObjectURL(pdfUrl);
      } catch (error) {
        console.error('API request failed:', error);

      }
    } else if (search == 24) {
      try {
        const apiUrl = `${visitCancellation.GetComplaintCodetwoFour}/${row}?type=sc`;

        const response = await fetch(apiUrl, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/pdf',
            'Authorization': `Bearer ${added_by}`,
          },
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const pdfBlob = await response.blob();

        const pdfUrl = URL.createObjectURL(pdfBlob);

        const link = document.createElement('a');
        link.href = pdfUrl;
        link.target = '_blank';
        link.download = 'document.pdf';

        document.body.appendChild(link);

        link.click();

        URL.revokeObjectURL(pdfUrl);
      } catch (error) {
        console.error('API request failed:', error);

      }
    } else if (search == 25) {
      try {
        const apiUrl = `${visitCancellation.GetComplaintCodetwoFour}/${row}?type=false`;

        const response = await fetch(apiUrl, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/pdf',
            'Authorization': `Bearer ${added_by}`,
          },
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const pdfBlob = await response.blob();

        const pdfUrl = URL.createObjectURL(pdfBlob);

        const link = document.createElement('a');
        link.href = pdfUrl;
        link.target = '_blank';
        link.download = 'document.pdf';

        document.body.appendChild(link);

        link.click();

        URL.revokeObjectURL(pdfUrl);
      } catch (error) {
        console.error('API request failed:', error);

      }
    }

  };



  const handleRefresh = async () => {
    const response = await getCancelTData({
      columnNameSort: "",
      filterString: "",
      inputDate: date,
      limit: "",
      offset: "18",
      searchString: "",
      sortOrder: "",
      sql: "",
    });
    if (response) {

      setCancellationData(response);

    }
  }



  useEffect(() => {
    fetchdata();
    fatchAllData();

  }, [])



  const selectedToDate = new Date().toISOString().slice(0, 10);
  const [date, setDate] = useState(selectedToDate);
  const [cancellationData, setCancellationData] = useState([]);
  const [selectedCenter, setSelectedCenter] = useState([]);
  const [selectedSubCenter, setSelectedSubCenter] = useState('');
  const [heightOffSet, setHeight] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [progressData, setProgressData] = useState("0%");
  const [selectedStatus, setSelectedStatus] = useState('');
  const [selectedRoute, setSelectedRoute] = useState('');
  const [filteredRoutes, setFilteredRoutes] = useState([]);
  const [selectedVoNames, setSelectedVoNames] = useState([]);
  const [voName, setVoName] = useState('');
  const [selectSearchData, setSelectSearchData] = useState('');
  const [search, setSearch] = useState("");
  const [searchFilter, setSearchFilter] = useState(cancellationData);
  const [selectchangeid, setSelectchangeid] = useState("");
  const [selectvid, setSelectvid] = useState("");

  const StatusData = [
    { value: 'All' },
    { value: 'Pending' },
    { value: 'Completed' },
    { value: 'Reallocated' },
    { value: 'Canclelled' },

  ];

  const SearchOnData = [
    { value: 'All' },
    { value: 'visitID' },
    { value: 'complaintCode' },
    { value: 'contactNo' },
    { value: 'farmerMobile' },
    { value: 'farmerName' },
    { value: 'routeCode' },
    { value: 'routeName' },
    { value: 'dscCode' },
    { value: 'dscName' },
    { value: 'centerName' },

  ];


  const colum = [
    {
      name: "Cancle",
      selector: "Status",
      cell: (row) => {

        const status = row.Status;


        if (status === "Pending") {
          return (
            <div className={`${status}`}>
              <AiFillCloseCircle className='style-icon' />
            </div>
          );
        } else if (status === "Completed") {
          return (
            <div className={`${status}`}>
              <IoMdDownload className='style-icon' onClick={() => handelpdf(row.visitID)} /> <MdModeEditOutline className='style-icon' />
            </div>
          );
        }
      },
    },

    {
      name: "Status ",
      selector: "Status",

      cell: (row) => {

        const status = row.Status;


        if (status === "Pending") {
          return (
            <div className={`status-CancelCell ${status}`}>
              <span style={{
                textAlign: 'center',
                width: '98px'
              }}> {status}</span>
            </div>
          );
        } else if (status === "Completed") {
          return (
            <div className={`status-CancelCell ${status}`}>
              <span style={{
                textAlign: 'center',
                width: '98px'
              }}> {status}</span>
            </div>
          );
        } else if (status === "Reallocated") {
          return (
            <div className={`status-CancelCell ${status}`}>
              <span style={{
                textAlign: 'center',
                width: '98px'
              }}> {status}</span>
            </div>
          );
        } else if (status === "Cancelled") {
          return (
            <div className={`status-CancelCell  ${status}`}>
              <span style={{
                textAlign: 'center',
                width: '98px'
              }}>{status}</span>
            </div>
          );
        }
      },

    },

    {
      name: "VisitID",
      selector: "visitID",
    },
    {
      name: "ComplaintCode",
      selector: "complaintCode",
    },


    {
      name: "ContactNo",
      selector: "contactNo",
    },
    {
      name: "FarmerCode",
      selector: "farmerCode",
    },
    {
      name: "FarmerName",
      selector: "farmerName",
    },
    {
      name: "FarmerMobileNumber",
      selector: "farmerMobileNumber",
    },
    {
      name: "RouteCode",
      selector: "routeCode",
    },

    {
      name: "DcsCode",
      selector: "dcsCode",
    },
    {
      name: "CenterName",
      selector: "centerName",
    },
    {
      name: "RouteName",
      selector: "routeName",
    },
    {
      name: "DcsName",
      selector: "dcsName",
    },
    {
      name: "VoName",
      selector: "voName",
    },
    {
      name: "VisitTime",
      selector: "visitTime",
    },
    {
      name: "DeliveryTime",
      selector: "deliveryTime",
      cell: (row) => {
        const originalDate = new Date(row.deliveryTime);
        const formattedDate = `${originalDate.getDate()}/${originalDate.getMonth() + 1}/${originalDate.getFullYear().toString().slice(-2)} ${originalDate.getHours()}:${originalDate.getMinutes()}`;
        return (
          <div>
            {formattedDate}
          </div>
        );
      },
    },
    {
      name: "WaitingTime",
      selector: "waitingTime",
    },
    {
      name: "CreatedBy",
      selector: "createdBy",
    },
    {
      name: "LastUpdatedBy",
      selector: "lastUpdatedBy",
    },
    {
      name: "LastUpdatedTime",
      selector: "lastUpdatedTime",
    },
    {
      name: "VisitTypeName",
      selector: "visitTypeName",
      width: "150px"
    },

    {
      name: "VisitTypeId",
      selector: "visitTypeId",
    },

  ];

  return (
    <>

      <Container fluid>
        <Row> {headerShow && (<Col lg="2" className="hideNavbar"> <Sidenav /></Col>)}
          <Col className="header" lg={headerShow ? "10" : "12"}>
            <Container fluid>
              <Row className="pt-1 sub-header align-items-center justify-content-between">
                <Col md="11" sm="10" xs="10" className="py-2 d-flex align-items-center">
                  <button style={{ border: "none" }} className="hideNavbar1" onClick={handleShow}><img src={menu} width={20} /></button>
                  <button style={{ border: "none" }} className="hide_Navbar" onClick={() => setShow(true)}><img src={menu} width={20} /></button>
                  <h5 className="ms-4 mt-2">Visit Management / Visit Cancellation</h5>
                </Col>
                <Col md="1" sm="1" xs="2" className="text-end profile_dropdown">
                  <img src={profile} width={40} />
                  <div className="dropdpwn_Open">
                    <Row className="text-center align-items-center">
                      <Col xs="12" className="menuHover mb-3">
                        <Row >
                          <Col className="" xs="3">
                            <span className=""><MdOutlineVpnKey size={25} />
                            </span>
                          </Col>
                          <Col className="text-start" xs="9">
                            Change Password
                          </Col>
                        </Row>
                      </Col>
                      <Col xs="12" className="menuHover" onClick={logOut}>
                        <Row>
                          <Col className="" xs="3">
                            <span className=""><GoSignOut size={25} />
                            </span>
                          </Col>
                          <Col className="text-start" xs="9">
                            Sign Out
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </div>
                </Col>

              </Row>
              <Row className="align-items-end p-2"  >

                <Col xl="1" md="5" className='d-flex'>
                  <button className="btn-blue-Animal addBtn me-2" style={{ cursor: "pointer" }} onClick={() => handleRefresh()}><RxCountdownTimer style={{ color: "white", fontSize: "25px" }} /></button>
                </Col>

                <Col xl="1" md="5" className='d-flex'>
                  <button className="btn-blue-Animal addAIBtn me-2" onClick={handleDownloadExcel}>Export</button>
                </Col>
                <Col xxl="1" xs="6" sm="2" md="2" className='justify-content-start'>
                  <FormControl variant="standard" fullWidth>
                    {/* <InputLabel>Status</InputLabel>
                    <Select value={selectedStatus} onChange={(e) => handleSelectChangeStatus(e.target.value)}>

                      <MenuItem className="drop_value" value="All">
                        All
                      </MenuItem>
                      <MenuItem className="drop_value" value="Pending">
                        Pending
                      </MenuItem>
                      <MenuItem className="drop_value" value="Completed">
                        Completed
                      </MenuItem>
                      <MenuItem className="drop_value" value="Reallocated">
                        Reallocated
                      </MenuItem>
                      <MenuItem className="drop_value" value="Cancelled">
                        Cancelled
                      </MenuItem>

                    </Select> */}
                    <Searcable
                      placeholder="Status"
                      onChange={(selectedOption) => handleSelectChangeStatus(selectedOption.value)}
                      options={StatusData}
                      labelKey="value"
                      valueKey="value"
                    />
                  </FormControl>
                </Col>
                <Col xxl="2" xs="6" sm="2" md="2" className='justify-content-start'>

                  <FormControl variant="standard" fullWidth>
                    <InputLabel>Sub Center</InputLabel>
                    <Select
                      value={selectedSubCenter}
                      onChange={(e) => handleSelectChangeCenter(e.target.value)}>
                      <MenuItem value="All">All</MenuItem>
                      {
                        selectedCenter.map((v) => (
                          <MenuItem key={v.id} value={v.id} className="drop_value">
                            {v.Name}
                          </MenuItem>
                        ))
                      }
                    </Select>
                    {/* <Searcable
                      placeholder="Sub Center"
                      onChange={(selectedOption) => handleSelectChangeCenter(selectedOption.value)}
                      value={selectedSubCenter}
                      options={selectedCenter}
                      labelKey="Name"
                      valueKey="id"
                    /> */}

                  </FormControl>

                </Col>
                <Col xxl="1" xs="6" sm="2" md="2" className='justify-content-start'>
                  <FormControl variant="standard" fullWidth>
                    {/* <InputLabel>Route</InputLabel>
                    <Select value={selectedRoute} onChange={(e) => handleSelectChangeRoute(e.target.value)}>
                      {filteredRoutes.map((v) => (
                        <MenuItem key={v.id} value={v.id} className="drop_value">
                          {v.Name}
                        </MenuItem>
                      ))}

                    </Select> */}
                    <Searcable
                      placeholder="Route"
                      onChange={(selectedOption) => handleSelectChangeRoute(selectedOption)}
                      value={selectedRoute}
                      options={filteredRoutes}
                      labelKey="Name"
                      valueKey="id"
                    />
                  </FormControl>
                </Col>

                <Col xxl="1" xs="6" sm="2" md="2" className='justify-content-start'>
                  <FormControl variant="standard" fullWidth>
                    {/* <InputLabel>VO Name</InputLabel>
                    <Select value={voName} onChange={(e) => handelselectVoName(e.target.value)}>

                      {
                        selectedVoNames.map((v) =>
                          <MenuItem key={v.Name} value={v.Name} className="drop_value">
                            {v.Name}
                          </MenuItem>
                        )}
                    </Select> */}
                    <Searcable
                      placeholder="VO Name"
                      onChange={(selectedOption) => handelselectVoName(selectedOption)}
                      value={voName}
                      options={selectedVoNames}
                      labelKey="Name"
                      valueKey="id"
                    />
                  </FormControl>
                </Col>

                <Col xxl="1" xs="6" sm="2" md="2" className='justify-content-start'>
                  <FormControl variant="standard" fullWidth>
                    {/* <InputLabel>Search On:</InputLabel>
                    <Select value={selectSearchData} onChange={(e) => handleSearchData(e.target.value)}>
                      <MenuItem value={'All'}>All</MenuItem>
                      <MenuItem value={'visitID'}>visitID</MenuItem>
                      <MenuItem value={'complaintCode'}>complaintCode</MenuItem>
                      <MenuItem value={'contactNo'}>contactNo</MenuItem>
                      <MenuItem value={'farmerMobile'}>farmerMobile</MenuItem>
                      <MenuItem value={'farmerName'}>farmerName</MenuItem>
                      <MenuItem value={'routeCode'}>routeCode</MenuItem>
                      <MenuItem value={'routeName'}>routeName</MenuItem>
                      <MenuItem value={'dscCode'}>dscCode</MenuItem>
                      <MenuItem value={'dscName'}>dscName</MenuItem>
                      <MenuItem value={'centerName'}>centerName</MenuItem>

                    </Select> */}
                    <Searcable
                      placeholder="Search On:"
                      onChange={(selectedOption) => handleSearchData(selectedOption.value)}
                      options={SearchOnData}
                      labelKey="value"
                      valueKey="value"
                    />
                  </FormControl>
                </Col>
                <Col xl="2">
                  <TextField
                    label="Search"
                    value={search}
                    id="standard-basic"
                    fullWidth
                    variant="standard"
                    onChange={e => SearchFn(e)}
                  />
                </Col>




                <Col md="3" xxl="2" xl="2" className='justify-content-end'>
                  <div>
                    <sub className="mb-0 pb-0">Date</sub>
                    <input
                      type="date"
                      className="dateSelect_bg"
                      value={date}
                      onChange={(e) => {
                        setDate(e.target.value);
                        handleDate(e.target.value);
                      }}
                    />
                  </div>
                </Col>


              </Row>
{/* 
              <Row className="mt-2">
                <Col>
                    <Table columns={colum} data={searchFilter.length > 0 ? searchFilter : cancellationData} height={`calc(100vh - (${heightOffSet}px + 215px))`} pagination />

                </Col>
              </Row> */}
              <Row className="mt-2" id='heightOffsetStock'>
                                {cancellationData.length > 0 ?
                                    <div className="blue_bg DataTableBorder mt-1">
                                        <Table columns={colum} data={cancellationData} height={`calc(100vh - (${heightOffSet}px + 140px))`} pagination />
                                    </div>
                                    :
                                    <Row>
                                        <Col>
                                            <div className="NoTableBorder ms-2" >
                                                <div className="d-flex tabledata colmBreed">{
                                                    colum.map((i) => {
                                                        return (
                                                            <Col className={`blankColumns ${i.name.replace(' ', '_')}`} style={{ backgroundColor: "rgb(169,169,169)" }} height={`calc(100vh - (${heightOffSet}px + 20px))`} >{i.name}</Col>
                                                        )
                                                    })
                                                }
                                                </div>
                                                <h6 className="text-center align-items-center mt-5 noData ms-5">No Data</h6>
                                            </div>
                                        </Col>
                                    </Row>
                                }
                            </Row>
            </Container>
          </Col>
        </Row>
      </Container >




      {show && <Offcanvas className="hide_Navbar" show={show} onHide={handleClose}><Container><Row><Col><Sidenav /></Col></Row></Container></Offcanvas>
      }

      {
        isLoading ?
          <div className="circleText">
            <div className="progressText">{progressData}</div>
            <div className="">
              <span className="loader-circle-13"></span>
            </div>
          </div>
          : false
      }
    </>

  )
}

export default Visit_Cancellation




