import "react-toastify/dist/ReactToastify.css";
import Select from 'react-select';
// import { Autocomplete, TextField } from '@mui/material';

// const Searcable = (props) => {

//     const { placeholder, options, onChange, value, labelKey, valueKey, otherKey } = props;
//     // console.log(props)
//     const formattedOptions = options && options?.map(option => ({
//         value: option[valueKey],
//         label: `${option[labelKey]}  ${option[otherKey] ? option[otherKey] : ""}`,
//     }));
   
//     // console.log(formattedOptions);
//     return (
//         <Select
//             value={value}
//             onChange={onChange}
//             options={formattedOptions}
//             placeholder={placeholder}
//             isSearchable={true}
//         />

//     )
// }

const Searcable = (props) => {
    const { placeholder, options, onChange, value, labelKey, valueKey, otherKey } = props;
    
    // Check if options is an array before mapping
    const formattedOptions = Array.isArray(options) && options.map(option => ({
        value: option[valueKey],
        label: `${option[labelKey]}  ${option[otherKey] ? option[otherKey] : ""}`,
    }));
   
    return (
        <Select
            value={value}
            onChange={onChange}
            options={formattedOptions}
            placeholder={placeholder}
            isSearchable={true}
        />
    );
}


export default Searcable

//  <Autocomplete
// id="combo-box-demo"
// options={props.options}
// getOptionLabel={(option) => option.Name} // Adjust this based on your data structure
// sx={{ width: 300 }}
// onChange={(event, newValue) => {
//     props.onChange(newValue);
// }}
// value={props.value}
// renderInput={(params) => <TextField {...params} label={props.placeholder} />}
// /> 

{/* <Select
value={props.value}
onChange={props.onChange}
options={formattedOptions}
placeholder={props.placeholder}
isSearchable={true}
/> */}