import React, { useEffect, useRef, useState } from "react";
import { Col, Container, Offcanvas, Row } from "react-bootstrap";
import './AnimalQr.css';
import Sidenav from "../Componet/Sidenav/Sidenav";
import menu from '../Photo/menu.svg';
import profile from '../Photo/profile.svg';
import { MdOutlineVpnKey } from "react-icons/md";
import { GoSignOut } from "react-icons/go";
import { useNavigate } from 'react-router-dom';
import { Html5QrcodeScanner } from "html5-qrcode";
import { STORAGE_KEY } from "../constant/common";
const added_by = localStorage.getItem(STORAGE_KEY.jwtTOKEN);

async function getScan(credentials) {
    try {
        const response = await fetch(`https://dudhsagar.herdman.in/api/sp/Animal/FarmerAnimalData`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                "Accept": "application/json",
                "Authorization": `Bearer ${added_by}`,
            },
        });

        if (!response.ok) {
            throw new Error('Network response was not ok');
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Fetch error:', error);
        throw error;
    }
}


function AnimalQr() {
    const [result, setResult] = useState("no data");
    const [data, setData] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const initializeScanner = () => {
            const scanner = new Html5QrcodeScanner('reader', {
                qrbox: {
                    width: '100%',
                    height: '100%'
                },
                fps: 5,
                fileEnabled: false,
                facingMode: 'environment'
            });

            scanner.render(success, handleError);

            function success(qrCodeText) {
                console.log('Scanned QR Code:', qrCodeText);
                

                getScan({ QrCode: qrCodeText })
                    .then(apiResponse => {
                        console.log('API Response:', apiResponse);
                        setData(apiResponse.data);
                    })
                    .catch(apiError => {
                        console.error('API Error:', apiError);
                    });
            }


            function handleError(err) {
                console.warn(err);
            }

            const button = document.getElementById("html5-qrcode-button-camera-permission");

            if (button) {
                button.innerText = "Scan";
                button.style.backgroundColor = "#43668E";
                button.style.border = "1px solid #43668E";
                button.style.color = "#FFFFFF";
                button.style.width = "150px";
                button.style.height = "40px";
            }

        };

        initializeScanner();
    }, [])

    const [show, setShow] = useState(false);
    const [headerShow, setHeaderShow] = useState(false);
    const handleClose = () => { setShow(false); }
    const handleShow = () => { setHeaderShow(!headerShow); }

    const logOut = () => { navigate("/") }

    return (
        <>
            <Container fluid>
                <Row>
                    {headerShow && (<Col lg="2" className="hideNavbar"> <Sidenav /></Col>)}
                    <Col className="header" lg={headerShow ? "10" : "12"}>
                        <Container fluid>

                            <Row className="pt-1 sub-header align-items-center justify-content-between">
                                <Col md="11" sm="10" xs="10" className="py-2 d-flex align-items-center">
                                    <button style={{ border: "none" }} className="hideNavbar1" onClick={handleShow}><img src={menu} width={20} /></button>
                                    <button style={{ border: "none" }} className="hide_Navbar" onClick={() => setShow(true)}><img src={menu} width={20} /></button>
                                    <h5 className="ms-4 mt-2">Animal Qr</h5>
                                </Col>
                                <Col md="1" sm="1" xs="2" className="text-end profile_dropdown">
                                    <img src={profile} width={40} />
                                    <div className="dropdpwn_Open">
                                        <Row className="text-center align-items-center">
                                            <Col xs="12" className="menuHover mb-3">
                                                <Row>
                                                    <Col className="" xs="3">
                                                        <span className=""><MdOutlineVpnKey size={25} /></span>
                                                    </Col>
                                                    <Col className="text-start" xs="9">
                                                        Change Password
                                                    </Col>
                                                </Row>
                                            </Col>
                                            <Col xs="12" className="menuHover" onClick={logOut}>
                                                <Row>
                                                    <Col className="" xs="3">
                                                        <span className=""><GoSignOut size={25} /></span>
                                                    </Col>
                                                    <Col className="text-start" xs="9">
                                                        Sign Out
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            </Row>

                            <Row>
                                <Col>
                                    {data ?
                                        <div id="reader">
                                            <table>
                                                {
                                                    data.map((i) => {
                                                        return (
                                                            <tr>
                                                                <td>{i.Soceity}</td>
                                                                <td>{i.Route}</td>
                                                                <td>{i.farmerid}</td>
                                                                <td>{i['Farmer Name']}</td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </table>
                                        </div>
                                        :
                                        <div id="reader"></div>
                                    }
                                </Col>
                            </Row>

                        </Container>
                    </Col>
                </Row>
            </Container>
            {show && <Offcanvas className="hide_Navbar" show={show} onHide={handleClose}><Container><Row><Col><Sidenav /></Col></Row></Container></Offcanvas>}
        </>
    )
}

export default AnimalQr;
